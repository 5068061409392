import {
  SET_ROSTER_DATE_RANGE,
  SET_ROSTER_LOADING,
  SET_ROSTER_WEEK,
} from "../../Roster/components/ducks/types";
import * as action_types from "./constants";
var _ = require("lodash");

const initialState: any = {
  techData: [],
  zonesData: [],
  techProperty: [],
  categoriesList: [],
  categoriesNewList: [],
  techniciansList: [],
  allTechnicianRoster: [],
  dateRange: "",
  roasterLoaderState: null,
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.GET_ZONES_LIST:
      return { ...state, zonesData: data };
    case action_types.GET_TECH_LIST:
      return { ...state, techData: data };
    case action_types.GET_TECH_PROPERTY:
      return { ...state, techProperty: data };
    case action_types.GET_MAINTANANCE_CATEGORIES:
      return {
        ...state,
        categoriesList: {
          residential: _.map(data?.residential, (item) => ({
            value: item.categoryId,
            label: item.name,
          })),
          commercial: _.map(data?.commercial, (item) => ({
            value: item.categoryId,
            label: item.name,
          })),
          internal: _.map(data?.internal, (item) => ({
            value: item.categoryId,
            label: item.name,
          })),
        },
      };
    case action_types.GET_NEW_MAINTANANCE_CATEGORIES:
      return {
        ...state,
        categoriesNewList: {
          residential: _.map(data?.residentialCategories, (item) => ({
            value: item.categoryId,
            label: item.categoryName,
          })),
          commercial: _.map(data?.commercialCategories, (item) => ({
            value: item.categoryId,
            label: item.categoryName,
          })),
          internal: _.map(data?.internalCategories, (item) => ({
            value: item.categoryId,
            label: item.categoryName,
          })),
        },
      };
    case action_types.GET_SP_TECHNICIANS:
      return {
        ...state,
        techniciansList: _.map(data, (item) => ({
          value: item.userId,
          label: item.name,
        })),
      };
    case action_types.GET_ALL_RECORDS_TECHNICIAN: {
      return {
        ...state,
        allTechnicianRoster: data,
      };
    }
    case SET_ROSTER_DATE_RANGE: {
      return {
        ...state,
        dateRange: data,
      };
    }
    case SET_ROSTER_WEEK: {
      return {
        ...state,
        weekSelected: data,
      };
    }
    case SET_ROSTER_LOADING: {
      return {
        ...state,
        roasterLoaderState: data,
      };
    }
    case action_types.GET_ALL_RECORDS_TECHNICIAN_SHIFTS: {
      return {
        ...state,
        technicianShifts: data,
      };
    }
    default:
      return state;
  }
};
