import React, { useState, useEffect, Fragment } from "react";
import { Col, Row, Button, Typography } from "antd";
import { DateField, InputField, InputNumberField, ReactSelectField } from "../../../atoms/FormElement";
import { DeleteOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useWatch } from "react-hook-form";
import { amount_type, changeFieldFormatter, changeFieldParser, payment_modes } from "./utils";
import { DeleteIcon } from "../../../../assets/svg";
import dayjs from "dayjs";

var _ = require("lodash");

let securityInst = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
];

export default (props) => {
  const {
    control,
    errors,
    billingType,
    remove,
    setValue,
    getValues,
    enableClone,
    fields,
    totalAnnualRent,
    depositPayments
  } = props;

  let rcInst = [{ label: 1, value: 1 }];
  const [enable, setEnable] = useState(true);
  const [disable, setDisable] = useState(false);
  const [instali, setInstalli] = useState([]);
  const [rcDisable, setRCDisable] = useState(false);
  const [typeM, setTypeM] = useState("");
  const configData = {
    rental_commission_percentage: 0.05
  }

  const { Text, Paragraph } = Typography;

  const othercharges = useWatch({ control, name: "othercharges" });

  const onChange = (e, index) => {
    if (e.value == "rental_commission") {
      setValue(`othercharges[${index}].intallments`, { label: "1", value: 1 });
      setValue(
        `othercharges[${index}].amount`,
        getValues("annualBaseRent") * configData?.rental_commission_percentage,
      );
      setRCDisable(true);
    } else {
      setRCDisable(false);
      setValue(
        `othercharges[${index}].amount`,
        0
      );
      setValue(
        `othercharges[${index}].vat`,
        depositPayments.find(depositType => depositType.type === e.value)?.vatInPercentage || 0
      );
    }
    e.value == "PARKING" ? setEnable(true) : setEnable(false);
    // setTypeM(e.value);
    // disableClone(e.value);
  };

  const resetAmounts = (index) => {
    setValue(`othercharges[${index}].actualAmount`, 0);
    setValue(`othercharges[${index}].vat`, 0);
    setValue(`othercharges[${index}].total`, 0);
  }

  const onAmountChange = (e, index) => {
    let total = 0;
    const rent = totalAnnualRent || null;  //TODO: ANNUALRENT FINAL AND ADD HERE
    const type = getValues(`othercharges[${index}].amountType`)?.value;
    const amount = parseFloat(getValues(`othercharges[${index}].actualAmount`) || 0);
    const vatPercent = parseFloat(getValues(`othercharges[${index}].vat`) || 0);

    if (type) {
      switch (type) {
        case "percent":
          const rentAmt = (amount * 0.01 * rent);
          const vat = (vatPercent * 0.01 * rentAmt);
          total = rentAmt + vat;
          break;
        case "amount":
          let fixVat = vatPercent * 0.01 * (amount);  // + rent : removed for bug: 8056
          total = (amount) + fixVat; //  + rent : removed for bug: 8056
        default:
          break;
      }
    } else
      setValue(`othercharges[${index}].amountType`, amount_type.find(i => i?.default));
    setValue(`othercharges[${index}].total`, total)
  };

  useEffect(() => {
    othercharges?.map((e,i)=>onAmountChange(null,i));
  }, [totalAnnualRent]);


  return (
    <>
      <Row gutter={[24, 24]} style={{ letterSpacing: "1.2px" }}>
        <Col span={24} className="ft-divider" />
        <Col span={4}>
          <Text className="fontWeight600 ag-default mb-1">TYPE</Text>
        </Col>
        <Col span={3}>
          <Text className="fontWeight600 ag-default mb-2">MODE</Text>
        </Col>
        <Col span={3}>
          <Text className="fontWeight600 ag-default mb-2">DUE DATE</Text>
        </Col>
        <Col span={4}>
          <Text className="fontWeight600 ag-default mb-2">AMOUNT TYPE</Text>
        </Col>
        <Col span={3}>
          <Text className="fontWeight600 ag-default mb-2">AMOUNT/%</Text>
        </Col>
        <Col span={3}>
          <Text className="fontWeight600 ag-default mb-2">TAX %</Text>
        </Col>
        <Col span={3}>
          <Text className="fontWeight600 ag-default mb-2">TOTAL AMOUNT</Text>
        </Col>
        {/* <Col span={24} className="ft-divider" /> */}
        <Col span={24} style={{ height: "16px" }} />
      </Row>
      {fields.map((item, index) => (
        <Fragment key={item.id}>
          <Row gutter={24} style={{ position: "relative" }}>
            <Col span={4}>
              <InputField
                classes="d-none"
                label="id"
                fieldname={`othercharges[${index}].itemId`}
                iProps={{ type: "number" }}
                control={control}
                initValue={item?.itemId || null}
              />
              <ReactSelectField
                isRequired={true}
                // label="Type"
                fieldname={`othercharges[${index}].billingChargesType`}
                control={control}
                iProps={{
                  placeholder: "Select",
                  isDisabled: props.disableAll ? true : disable,
                }}
                initValue={item?.billingChargesType}
                selectOption={billingType}
                // selectOption={[{label:"Base Rent",value:"baseRent"}]}
                onChange={(e) => onChange(e, index)}
                rules={{ required: "Please select type" }}
                validate={
                  Object.entries(errors).length > 0 &&
                  errors?.othercharges?.length > 0 &&
                  errors?.othercharges[index]?.billingChargesType &&
                  "error"
                }
                validMessage={
                  Object.entries(errors).length > 0 &&
                  errors?.othercharges?.length > 0 &&
                  errors?.othercharges[index]?.billingChargesType &&
                  errors?.othercharges[index]?.billingChargesType?.message
                }
              />
            </Col>
            <Col span={3}>
              <ReactSelectField
                isRequired={true}
                // label="Payment Mode"
                fieldname={`othercharges[${index}].modeOfPayment`}
                control={control}
                iProps={{
                  placeholder: "Select",
                  isDisabled: props.disableAll ? true : false,
                }}
                // initValue={item?.mode ? { label: item?.mode, value: item?.mode } : { value: "cheque", label: "Cheque" }}
                selectOption={payment_modes}
                // selectOption={[{label:"Base Rent",value:"baseRent"}]}
                // onChange={(e) => onChange(e, index)}
                rules={{ required: "Please select mode" }}
                validate={
                  Object.entries(errors).length > 0 &&
                  errors?.othercharges?.length > 0 &&
                  errors?.othercharges[index]?.modeOfPayment &&
                  "error"
                }
                validMessage={
                  Object.entries(errors).length > 0 &&
                  errors?.othercharges?.length > 0 &&
                  errors?.othercharges[index]?.modeOfPayment &&
                  errors?.othercharges[index]?.modeOfPayment?.message
                }
              />

            </Col>
            <Col span={3}>
              <DateField
                label=""
                fieldname={`othercharges[${index}].dueDate`}
                control={control}
                iProps={{
                  placeholder: "DD-MM-YYYY",
                  format: "DD-MM-YYYY",
                  disabledDate: (current) => (current && dayjs(current).format("YYYY-MM-DD") < dayjs().format("YYYY-MM-DD")),
                  // disabled: (item?.isPaid === true || disableAll)
                  //   ? true
                  //   : false
                }}
                initValue={item.dueDate}
                setValue={setValue}
                rules={{ required: "Please select date" }}
                validate={
                  Object.entries(errors).length > 0 &&
                  errors?.othercharges?.length > 0 &&
                  errors?.othercharges[index]?.dueDate &&
                  "error"
                }
                validMessage={
                  Object.entries(errors).length > 0 &&
                  errors?.othercharges?.length > 0 &&
                  errors?.othercharges[index]?.dueDate &&
                  errors?.othercharges[index]?.dueDate?.message
                }
              />
            </Col>
            <Col span={4}>
              <ReactSelectField
                isRequired={true}
                // label="Type"
                fieldname={`othercharges[${index}].amountType`}
                control={control}
                iProps={{
                  placeholder: "Select",
                  isDisabled: props.disableAll ? true : disable,
                }}
                initValue={item?.amountType}
                selectOption={amount_type}
                // selectOption={[{label:"Base Rent",value:"baseRent"}]}
                onChange={(e) => { 
                  // resetAmounts(index); 
                  onAmountChange(e, index) }}
                rules={{ required: "Please select amount type" }}
                validate={
                  Object.entries(errors).length > 0 &&
                  errors?.othercharges?.length > 0 &&
                  errors?.othercharges[index]?.amountType &&
                  "error"
                }
                validMessage={
                  Object.entries(errors).length > 0 &&
                  errors?.othercharges?.length > 0 &&
                  errors?.othercharges[index]?.amountType &&
                  errors?.othercharges[index]?.amountType?.message
                }
              />
            </Col>
            <Col span={3}>
              <InputNumberField
                isRequired={true}
                // label="Amount"
                fieldname={`othercharges[${index}].actualAmount`}
                disabled={props.disableAll
                  ? true
                  : false
                }
                iProps={{
                  placeholder: "Amount",
                  // type: "number",
                  // parser: (value: any) =>
                  //   changeFieldParser(value, getValues(`othercharges[${index}].amountType`)?.value),
                  // formatter: (value: string): any =>
                  //   changeFieldFormatter(value, getValues(`othercharges[${index}].amountType`)?.value),

                }}
                onChange={(e) => onAmountChange(e, index)}
                control={control}
                initValue={item?.actualAmount}
                rules={{
                  required: "Please enter amount",
                  setValueAs: (value) => parseFloat(value?.toFixed(2)),
                }}
                validate={
                  Object.entries(errors).length > 0 &&
                  errors?.othercharges?.length > 0 &&
                  errors?.othercharges[index]?.actualAmount &&
                  "error"
                }
                validMessage={
                  Object.entries(errors).length > 0 &&
                  errors?.othercharges?.length > 0 &&
                  errors?.othercharges[index]?.actualAmount &&
                  errors?.othercharges[index]?.actualAmount?.message
                }
              />
            </Col>
            <Col span={3}>
              <InputNumberField
                isRequired={true}
                // label="Amount"
                fieldname={`othercharges[${index}].vat`}
                disabled={props.disableAll
                  ? true
                  : false
                }
                iProps={{
                  placeholder: "Tax",
                  // type: "number",
                  // parser: (value: any) =>
                  //   changeFieldParser(value, "percent"),
                  // formatter: (value: string): any =>
                  //   changeFieldFormatter(value, "percent"),
                }}
                onChange={(e) => onAmountChange(e, index)}
                control={control}
                initValue={item?.vat}
                rules={{
                  required: "Please enter amount",
                  setValueAs: (value) => parseFloat(value?.toFixed(2)),
                }}
                validate={
                  Object.entries(errors).length > 0 &&
                  errors?.othercharges?.length > 0 &&
                  errors?.othercharges[index]?.vat &&
                  "error"
                }
                validMessage={
                  Object.entries(errors).length > 0 &&
                  errors?.othercharges?.length > 0 &&
                  errors?.othercharges[index]?.vat &&
                  errors?.othercharges[index]?.vat?.message
                }
              />
            </Col>
            <Col span={3}>
              <div title={othercharges[index]?.amountType?.value == "percent" && !totalAnnualRent ?
                "Will be calculated when the payment plan is selected by tenant" : ""}>
                <InputField
                  isRequired={true}
                  // label="Amount"
                  fieldname={`othercharges[${index}].total`}
                  disabled={props.disableAll || (othercharges[index]?.amountType?.value == "percent" && !totalAnnualRent)  //FIX FOR BUG IN PERCENT CASE
                    ? true
                    : false
                  }
                  iProps={{
                    placeholder: "Total Amount",
                    type: "number",
                  }}
                  // onInput={onAmountChange}
                  control={control}
                  initValue={item?.total}
                  rules={{
                    required: "Please enter amount",
                    min: { value: 0, message: "Please enter correct amount" }, //made min as 0 becuase of percent case
                    setValueAs: (value) => parseFloat(value?.toFixed(2)),
                  }}
                  validate={
                    Object.entries(errors).length > 0 &&
                    errors?.othercharges?.length > 0 &&
                    errors?.othercharges[index]?.total &&
                    "error"
                  }
                  validMessage={
                    Object.entries(errors).length > 0 &&
                    errors?.othercharges?.length > 0 &&
                    errors?.othercharges[index]?.total &&
                    errors?.othercharges[index]?.total?.message
                  }
                />
              </div>
            </Col>
            {!props.disableAll && (//index > 0 && ( //(deleteIndex ?? 2)
              <Button
                // className="bf-discard-button"
                type="link"
                icon={<DeleteIcon />}
                onClick={() => {
                  remove(index);
                  enableClone(typeM);
                }}

              />
            )}

          </Row>
        </Fragment>

      ))}
    </>
  );
};
