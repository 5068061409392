import React, { useState, useEffect } from "react";

import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import axios from "../../../../../utils/axiosInceptor";
import { getAllMaintenanceTypes, getMaintenanceTypes } from "../../ducks/actions";
import { DateField, InputField, ReactSelectField } from "../../../../atoms/FormElement";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { maintenanceService } from "../../../../../configs/constants";
import { BsPlusCircle, BsArrowCounterclockwise } from "react-icons/bs";
import { Row, Col, Button, Form, Typography, message, Space, Spin } from "antd";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

var _ = require("lodash");

// const defaultValues: any = {
//   name: "",
// };

const { Paragraph } = Typography;

const AddPublicHoliday = (props) => {
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const categoryList = useSelector(
    (state: any) => state.configuration.allMaintenanceTypes
  );

  const orgId = JSON.parse(window.localStorage.getItem("login-response"))?.orgId;



  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue
  } = useForm();

  const dispatch: any = useDispatch();

  useEffect(() => {

    // if (props?.selected.length > 0) {
    //   const selectedCategories = _.map(props.selected, (item) => { return { label: item.categoryName, value: item.categoryId } })
    //   setValue("services", selectedCategories);
    // } else {
    //   // console.log("SELLL",selectedCategories);
    //   reset();
    // }

    // return () => {
    //   reset();
    // };
  }, [props]);


  const onFinish = async (values) => {
    setLoader(true);
    setSubmited(true);
    reset();

    const postJson = {
        "holidays": [
            {
                "title": values.name ?? "",
                "date": dayjs(values.date).format("YYYY-MM-DD") ?? ""
            }
        ]
    };

    try {
      let url = `${maintenanceService}/maintenance/setHoliday`;
      await axios.post(
        url,
        postJson,
      );
      setTimeout(() => {
        setLoader(false);
        setSubmited(false);
        message.success("Public holiday added successfully");
        props.onSubmit();
      }, 800);
    } catch (e) {
      setLoader(false);
      // setisError(true);
      setSubmited(false);
      const { response } = e;
      message.error(response?.data?.error);
    }
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
          <Row gutter={24}>
            <Col span={12}>
              <InputField
                isRequired={true}
                fieldname="name"
                label="Holiday Title"
                control={control}
                rules={{
                  required: "Please enter holiday title",
                }}
                iProps={{ placeholder: "Diwali" }}
                initValue=""
                validate={errors.name && "error"}
                validMessage={errors.name && errors.name.message}
              />
            </Col>
            <Col span={12}>
            <DateField
                isRequired={true}
                fieldname="date"
                label="Holiday Date"
                control={control}
                validate={errors.date && "error"}
                validMessage={errors.date && errors.date.message}
                // onChange={(e) => {
                //   setEndDate(e);
                //   setValue("endDate", e);
                // }}
                rules={{
                  required: "Please select date",
                }}
                iProps={{
                  format: "DD-MM-YYYY",
                  placeholder: "DD-MM-YYYY",
                  // disabledDate: (current) =>
                  //   current <= dayjs(startDate).subtract(1, "days"),
                  // value: endDate,
                }}
                // initValue={endDate}
              />
            </Col>
          </Row>
          <Row gutter={24} justify="space-between">
            <Col>
              <Button
                className="ag-error theme_grey_btn"
                htmlType="button"
                onClick={() => {
                  reset();
                  props.onCancel();
                }}
              >
                {<BsArrowCounterclockwise />} Back
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button theme_btn" htmlType="submit">
                {<BsPlusCircle />} {props?.selected?.length > 0 ? "Update" : "Add"}
              </Button>
            </Col>
          </Row>
        </>
  ) : (
    isError && (
      <>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space
              size={24}
              className="w-100 text-center"
              direction="vertical"
            >
              <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
              <Paragraph className="ag-error fontWeight600 mb-0 sentanceCase">
                {errorMessage}
              </Paragraph>
            </Space>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Button
              className="ag-gray-button-outline"
              htmlType="button"
              onClick={() => {
                setSubmited(false);
                props.onCancel();
              }}
            >
              Back
            </Button>
          </Col>
        </Row>
      </>
    )
  )
}
    </Form >
  );
};

export default AddPublicHoliday;
