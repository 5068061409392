import React, { useState, useEffect } from "react";
import { Col,  Button } from "antd";
import {
  InputField,
  ReactSelectField,
  DateField,
} from "../../../atoms/FormElement";

import { MinusCircleOutlined } from "@ant-design/icons";

export default (props) => {
  const {
    paymentModes,
    paymentTypes,
    control,
    errors,
    setValue,
    register,
    getValues,
    remove,
    index,
    item,
  } = props;

  const configData = {
    rental_commission_percentage: 0.05
  }

  const [disable, setDisable] = useState([]);

  const setMode = (paymentMode, index) => {
    if (paymentMode === "cash") {
      setDisable(prevState => [...prevState, index]);
    } else {
      setDisable(prevState => prevState.filter((e) => e !== index));
    }
  };


  const onChange = (e, index) => {
    if (e.value == "rental_commission") {
      setValue(
        `addpayment[${index}].amount`,
        getValues("annualBaseRent") * configData?.rental_commission_percentage,
      );
    } else {
      setValue(
        `addpayment[${index}].amount`,
        0
      );
    }
    calculateTotal(index);
  };

  
  // Calculate total based on amount and vat
  const calculateTotal = (index) => {
    const amount = parseFloat(getValues(`addpayment[${index}].amount`)) || 0;
    const vat = parseFloat(getValues(`addpayment[${index}].vat`)) || 0;
    const total = amount + vat;

    setValue(`addpayment[${index}].total`, total.toFixed(2));
  };


  return (
    <>
          {/* <Col span={1}>
              <InputFieldInputField
                label=""
                fieldname={`payment[${index}].instno`}
                setValue={setValue}
                valueGot={item.instno}
                control={control}
                initValue=""
                iProps={{
                  readOnly: true,
                  disabled: props.disableAll
                    ? true
                    : item.type == "ejari_fee" ||
                      item.type == "security_deposit"
                    ? true
                    : false,
                }}
              />
            </Col> */}
          <Col span={4}>
            <Button
              className="removebtn"
              style={{ position: "absolute", top:0,left:"-24px" }}
              type="link"
              icon={<MinusCircleOutlined />}
              onClick={() => {
                remove(index);
              }}
              danger
            />
            <ReactSelectField
              label=""
              fieldname={`addpayment[${index}].type`}
              control={control}
              initValue={item.type || null}
              setValue={setValue}
              // valueGot={item.mode}
              selectOption={paymentTypes}
              onChange={(e) => onChange(e, index)}
              iProps={{
                // isDisabled: props.disableAll
                //   ? true
                // : item.type == "ejari_fee"
                //   //  ||
                //   //   item.type == "security_deposit"
                //   ? true
                // : false,
              }}
              // setMode={(paymentMode) => setMode(paymentMode, index)}
              isRequired={true}
              validate={errors?.addpayment?.[index]?.type && "error"}
              validMessage={
                errors?.addpayment?.[index]?.type && errors?.addpayment?.[index]?.type.message
              }
              rules={{ required: "Please select type" }}
            />
          </Col>
          <Col span={3}>
            <InputField
              label=""
              fieldname={`addpayment[${index}].amount`}
              setValue={setValue}
              // valueGot={item.amount}
              control={control}
              onChange={(e) => {
                calculateTotal(index);
              }}
              initValue={item.amount || 0}
              iProps={{
                // readOnly: true,
                type: "number",
                // disabled: props.disableAll
                //   ? true
                //   : item.type == "ejari_fee" ||
                //     item.type == "security_deposit"
                //     ? true
                //     : false,
              }}
              isRequired={true}
              rules={{ required: "Please enter amount" }}
              validate={errors?.addpayment?.[index]?.amount && "error"}
              validMessage={
                errors?.addpayment?.[index]?.amount && errors?.addpayment?.[index]?.amount.message
              }
            />

            {/* control={control}
          initValue={item?.amount}
          validate={
            Object.entries(errors).length > 0 &&
            errors?.othercharges?.length > 0 &&
            errors?.othercharges[index]?.amount &&
            "error"
          }
          validMessage={
            Object.entries(errors).length > 0 &&
            errors?.othercharges?.length > 0 &&
            errors?.othercharges[index]?.amount &&
            errors?.othercharges[index]?.amount?.message
          }
          rules={{
            required: "Enter Amount",
            min: { value: 1, message: "Enter amount" },
            setValueAs: (value) => parseFloat(value?.toFixed(2)),
          }} */}

          </Col>
          <Col span={2}>
            <InputField
              label=""
              fieldname={`addpayment[${index}].vat`}
              setValue={setValue}
              // valueGot={item.vat}
              control={control}
              onChange={(e) => {
                calculateTotal(index);
              }}
              initValue={item.vat || 0}
              iProps={{
                // readOnly: true,
                type: "number",
                // disabled: props.disableAll
                //   ? true
                //   : item.type == "ejari_fee" ||
                //     item.type == "security_deposit"
                //     ? true
                //     : false,
              }}
              isRequired={true}
              rules={{ required: "Please enter vat" }}
              validate={errors?.addpayment?.[index]?.vat && "error"}
              validMessage={
                errors?.addpayment?.[index]?.vat && errors?.addpayment?.[index]?.vat.message
              }
            />
          </Col>
          <Col span={3}>
            <InputField
              label=""
              fieldname={`addpayment[${index}].total`}
              setValue={setValue}
              // valueGot={item.total}
              control={control}
              initValue={item.total || 0}
              iProps={{
                readOnly: true,
                type: "number",
                // disabled: props.disableAll
                //   ? true
                //   : item.type == "ejari_fee" ||
                //     item.type == "security_deposit"
                //     ? true
                //     : false,
              }}
              isRequired={true}
              rules={{ required: "Please enter total amount" }}
              validate={errors?.addpayment?.[index]?.total && "error"}
              validMessage={
                errors?.addpayment?.[index]?.total && errors?.addpayment?.[index]?.total.message
              }
            />
          </Col>

          <Col span={2}>
            <ReactSelectField
              label=""
              fieldname={`addpayment[${index}].mode`}
              control={control}
              initValue={item.mode || null}
              setValue={setValue}
              // valueGot={item.mode}
              selectOption={paymentModes?.map((item, index) => {
                return { label: item?.name, value: item?.id };
              })}
              iProps={{
                // isDisabled: props.disableAll
                //   ? true
                // : item.type == "ejari_fee"
                //   //  ||
                //   //   item.type == "security_deposit"
                //   ? true
                // : false,
              }}
              setMode={(paymentMode) => setMode(paymentMode, index)}
              isRequired={true}
              validate={errors?.addpayment?.[index]?.mode && "error"}
              validMessage={
                errors?.addpayment?.[index]?.mode && errors?.addpayment?.[index]?.mode.message
              }
              rules={{ required: "Please select one" }}
            />
          </Col>
          <Col span={3}>
            <InputField
              label=""
              fieldname={`addpayment[${index}].docNo`}
              // setValue={setValue}
              control={control}
              initValue={item?.docNo || ""}
              setValue={setValue}
              register={register}
              resetField={props?.resetField}
              // valueGot={!!item.docNo ? item.docNo : "empty"}
              disabled={disable.includes(index)
                ? true
                : false}
            // isRequired={true}
            // rules={{ required: "Please enter docNo" }}
            // validate={errors?.addpayment?.[index]?.docNo && "error"}
            // validMessage={
            //   errors?.addpayment?.[index]?.docNo && errors?.addpayment?.[index]?.docNo.message
            // }
            />
          </Col>
          <Col span={3}>
            <InputField
              label=""
              fieldname={`addpayment[${index}].bankName`}
              control={control}
              initValue={item?.bankName || ""}
              setValue={setValue}
              // valueGot={!!item.bankName ? item.bankName : "empty"}
              register={register}
              resetField={props?.resetField}
              disabled={disable.includes(index)
                ? true
                : false}
            // isRequired={true}
            // rules={{ required: "Please enter bank name" }}
            // validate={errors?.addpayment?.[index]?.bankName && "error"}
            // validMessage={
            //   errors?.addpayment?.[index]?.bankName && errors?.addpayment?.[index]?.bankName.message
            // }
            />
          </Col>
          {/* <Col span={2}>
              <InputField
                label=""
                fieldname={`payment[${index}].entity`}
                // setValue={setValue}
                control={control}
                initValue={props?.building?.termsAndConditionsEntity}
                // setValue={setValue}
                // valueGot={!!item.docNo ? item.docNo : "empty"}
                iProps={{ disabled: true }}
              />
            </Col> */}
          <Col span={2}>
            <DateField
              label=""
              fieldname={`addpayment[${index}].dueDate`}
              setValue={setValue}
              // valueGot={item.dueDate}
              control={control}
              initValue={item?.dueDate ?? null}
              iProps={{
                placeholder: "DD-MM-YYYY",
                format: "DD-MM-YYYY",
                // readOnly: true,
                // disabled: props.disableAll
                //   ? true
                //   : item.type == "ejari_fee"
                //     // ||
                //     //   item.type == "security_deposit"
                //     ? true
                //     : false,
              }}
              isRequired={true}
              rules={{ required: "Please enter date" }}
              validate={errors?.addpayment?.[index]?.dueDate && "error"}
              validMessage={
                errors?.addpayment?.[index]?.dueDate && errors?.addpayment?.[index]?.dueDate.message
              }
            />
          </Col>
          <Col span={2}>
            <DateField
              label=""
              fieldname={`addpayment[${index}].chequeDate`}
              control={control}
              iProps={{
                placeholder: "DD-MM-YYYY",
                format: "DD-MM-YYYY",
                // disabledDate: (current) => disableChequeDate(current, index),
                // disabled: props.disableAll
                //   ? true
                //   : item.type == "ejari_fee" ||
                //     item.type == "security_deposit"
                //     ? true
                //     : false,
              }}
              initValue={item.chequeDate || null}
              setValue={setValue}
            // valueGot={
            //   !!item.chequeDate
            //     ? item.chequeDate
            //     : dayjs(item.dueDate, "DD-MM-YYYY")
            // }
            />
          </Col>
    </>
  );
};
