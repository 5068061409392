import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../molecules/DashboardLayout";
import { Row, Col, Descriptions, Card, Typography } from "antd";
import SectionHeader from "../../../molecules/SectionHeader";
// import { useHistory, useParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import Task from "../../../molecules/Task";
import { getLeadDetails, getLeadStatus } from "./ducks/actions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const { Title } = Typography;

const LeadsDetail = (props) => {
  // const history = useHistory();
  const dispatch:any = useDispatch();
  const { id } = useParams();
  const [tab1, setTab1] = useState([]);
  const [tab2, setTab2] = useState([]);

  const details = useSelector((state: any) => state.leadDetails.getLeadDetails);
  const status = useSelector((state: any) => state.leadDetails.status);

  useEffect(() => {
    dispatch(getLeadDetails(id));
    // dispatch(getLeadStatus(id));
  }, []);

  useEffect(() => {
    if (details) {
      let opDate = "N.A";
      if (details && details.opportunityDate != null) {
        let temp: any = new Date(details?.opportunityDate);
        if (temp != "Invalid Date") {
          opDate = moment(details?.opportunityDate).format("YYYY-MM-DD");
        }
      }
      setTab1([
        { label: "Offer ID", val: details?.offerId || "N.A" },
        { label: "Tenant Type", val: details?.tenantType || "N.A" },
        { label: "Name", val: details?.name || "N.A" },
        { label: "Email", val: details?.email || "N.A" },
        { label: "Created By", val: details?.createdBy || "N.A" },
        { label: "Contract To", val: details?.contractTo || "N.A" },
        { label: "Country", val: details?.country || "N.A" },
        { label: "Fitout start Date", val: details?.fitOutStartDate || "N.A" },
        { label: "Nationality", val: details?.nationality || "N.A" },
        { label: "Validity Date", val: details?.validityDate || "N.A" },
        { label: "Modified On", val: details?.modifiedOn || "N.A" },
        { label: "Salutation", val: details?.salutation || "N.A" },
        { label: "State Code", val: details?.stateCode || "N.A" },
        { label: "Opportunity Owner", val: details?.opportunityOwner || "N.A" },
      ]);

      setTab2([
        { label: "Unit ID", val: details?.unitId || "N.A" },
        { label: "Mobile Number", val: details?.mobileNumber || "N.A" },
        { label: "Opportunity Date", val: opDate },
        { label: "Building", val: details?.building || "N.A" },
        { label: "Contract From", val: details?.contractFrom || "N.A" },
        { label: "Contract Type", val: details?.contractType || "N.A" },
        { label: "Deal Confirmed On", val: details?.dealConfirmedOn || "N.A" },
        { label: "Name", val: details?.name || "N.A" },
        { label: "Transaction Type", val: details?.transactionType || "N.A" },
        { label: "Modified By", val: details?.modifiedBy || "N.A" },
        { label: "Offer Guide", val: details?.offerGuid || "N.A" },
        { label: "Lead Number", val: details?.leadNumber || "N.A" },
        { label: "Offer Owner", val: details?.offerOwner || "N.A" },
        { label: "Lead Owner", val: details?.leadOwner || "N.A" },
      ]);
    }
  }, [details]);

  // const taskArray = [
  //   {
  //     id: "confirmTenantInformation",
  //     title: "Confirm Tenant Information",
  //     route: () => history.push(`/detail-tenant/${id}`),
  //   },
  //   {
  //     id: "reviewBookingForm",
  //     title: "Review Booking Form",
  //     route: () => history.push(`/leads/${id}/booking-form/${details.assetId}`),
  //   },
  // ];

  return (
    <DashboardLayout>
      <Row gutter={24}>
        <Col span={24}>
          <SectionHeader heading="Lead Detail" noBorder={true} />
        </Col>
        <Col span={24}>
          <Row gutter={[1.5, 24]}>
            <Col span={12}>
              <Card bordered={false}>
                <Descriptions bordered column={1} className="customTable">
                  {tab1.map((value, key) => {
                    return (
                      <Descriptions.Item key={key} label={value.label}>
                        {value.val}
                      </Descriptions.Item>
                    );
                  })}
                </Descriptions>
              </Card>
            </Col>
            <Col span={12}>
              <Card bordered={false}>
                <Descriptions bordered column={1} className="customTable">
                  {tab2.map((value, key) => {
                    return (
                      <Descriptions.Item key={key} label={value.label}>
                        {value.val}
                      </Descriptions.Item>
                    );
                  })}
                </Descriptions>
              </Card>
            </Col>
          </Row>
        </Col>
        {/* <Col span={24}>
          <Row gutter={[24, 24]} justify="space-between">
            <Col span={24}>
              <Title>Tasks:</Title>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            {taskArray.map((val, key) => {
              return (
                <Col key={key} span={24}>
                  <Task
                    taskNo={key + 1}
                    statusKey={val.id}
                    title={val.title}
                    route={val.route}
                    status={status}
                  />
                </Col>
              );
            })}
          </Row>
        </Col> */}
      </Row>
    </DashboardLayout>
  );
};

export default LeadsDetail;
