import dayjs from "dayjs";

export const dateByformat = (dateParam, formatParam?) => {
  let finalDate = "";
  const date = dayjs(dateParam);
  const format = formatParam ?? "YYYY-MM-DD";

  if (date.isValid())
    finalDate = date.format(format);

  return finalDate;
};