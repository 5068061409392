import React, { useState, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  Typography,
  Button,
  message,
  Switch,
  Spin,
  Tag,
} from "antd";

import Grid from "../../../atoms/Grid/index";

import axios from "axios";
import type { UploadFile } from "antd/es/upload/interface";
import {
  DeleteOutlined,
  InboxOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import { Upload } from "antd";
import { RcFile } from "antd/lib/upload/interface";
import { useLocation, useNavigate } from "react-router-dom";
import { getOnePDC, updatePDCStatus } from "../../Contracts/ducks/actions";
import { dateByformat } from "../../../../utils/dateHelper";
import { currencyFormat, statusLabel } from "../../../../utils/media";
import { leaseService } from "../../../../configs/constants";

const { Dragger } = Upload;
const { Content } = Layout;

const { Title } = Typography;
const gridProps = {
  sortable: true,
  filter: true,
  resizable: true,
  minWidth: 200,
};

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default ({
  showAll,
  getAllBookRequests,
  getAllLeaseRequests,
  getAllPlanRequests,
}) => {
  let query = useQuery();

  const navigate = useNavigate();

  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   if(query.get("pdcId") && !showAll) {
  //     ;(async () => {
  //       setLoading(true)

  //       const pdc = await getOnePDC(query.get("pdcId"))

  //       if(pdc?.status === 200 && pdc?.data?.result?.creditCardRequest === "pending") {

  //         setPdcList([pdc?.data?.result]);
  //         setLoading(false)

  //       } else {
  //         getPendingCreditCardRequests()
  //       }

  //     })()
  //   } else {
  //     getPendingCreditCardRequests()
  //   }

  //   return () => {
  //       setPdcList([])
  //   }

  // }, [])

  useEffect(() => {
    if (
      getAllBookRequests?.data ||
      getAllLeaseRequests?.data ||
      getAllPlanRequests?.data
    )
      setDataList([
        ...getAllBookRequests?.data,
        ...getAllLeaseRequests?.data,
        ...getAllPlanRequests?.data,
      ]);
  }, [getAllBookRequests, getAllLeaseRequests, getAllPlanRequests]);

  const convertToNormalCase = (input = "Document") => {
    // Check if the input is in camel case
    if (input.indexOf("_") === -1 && input !== input.toLowerCase()) {
      return input.replace(/([a-z])([A-Z])/g, "$1 $2");
    }

    // Check if the input is in snake case
    if (input.indexOf("_") !== -1) {
      return input
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());
    }

    return input.charAt(0).toUpperCase() + input.slice(1); // Already in normal case
  };

  const dataCol = [
    {
      headerName: "Status",
      field: "leaseStatus",
      minWidth: 220,
      cellRendererFramework: ({ data }) => (
        <Tag className="tag-sm" color="warning">
          {/* //icon={<ExclamationCircleOutlined />} */}
          {statusLabel(data?.leaseStatus)}
        </Tag>
      ),
    },
    {
      headerName: "Property",
      field: "propertyDetails.propertyName",
    },
    {
      headerName: "Unit Number",
      field: "propertyDetails.unitCode",
      // minWidth: 320,
    },
    {
      headerName: "Tenant",
      field: "tenantDetails.tenantNameEN",
    },
    {
      headerName: "Annual Rent",
      field: "bookingTerms.annualBaseRent",
      cellRendererFramework: ({ data }) => {
        return (
          <div className="annualRent">
            {currencyFormat(
              data?.bookingTerms?.renewalAmount ||
                data?.bookingTerms?.annualBaseRent
            )}
          </div>
        );
      },
    },
    {
      headerName: "Payments",
      field: "bookingTerms.numberOfInstallments",
    },
    {
      headerName: "Start Date",
      field: "leaseTerms.leaseStartDate",
      // minWidth: 150,
      cellRendererFramework: ({ data }) => {
        return dateByformat(data?.leaseTerms?.leaseStartDate, "DD-MM-YYYY");
      },
    },
    {
      headerName: "End Date",
      field: "leaseTerms.leaseEndDate",
      // minWidth: 150,
      cellRendererFramework: ({ data }) => {
        return dateByformat(data?.leaseTerms?.leaseEndDate, "DD-MM-YYYY");
      },
    },
    {
      headerName: "Contract Type",
      field: "leaseType",
      cellRendererFramework: ({ data }) => {
        return statusLabel(data?.leaseType);
      },
    },
    {
      headerName: "AWB No",
      field: "shipmentNumber",
    },
    {
      headerName: "AWB No",
      field: "shipmentNumber",
    },
    {
      headerName: "Action",
      cellRendererFramework: viewRender,
      cellStyle: { textAlign: "center" },
      maxWidth: 240,
      filter: false,
    },
  ];

  function viewRender({ data }) {
    return (
      <>
        {/* <Row> */}
        {/* <Col span={12}> */}
        <Button
          type="primary"
          onClick={() => data?.leaseStatus === "draft" ? navigate(`/newbookingform/${data?.leaseId}`) : navigate(`/viewbookingform/${data?.leaseId}`)} 
        >
          View
        </Button>
        {/* </Col> */}
        {/* <Col span={12}>
                        <Button danger style={{borderColor: 'red'}} type="text" onClick={() => onUpdateCheckStatus('rejected', data)}>
                            Reject
                        </Button>
                    </Col> */}
        {/* </Row> */}
      </>
    );
  }

  // const getPendingCreditCardRequests = async () => {
  //   try {
  //     const url = `${leaseService}/booking/pdclist?creditCardRequest=pending`;

  //     const response = await axios.get(url);
  //     if(response.status === 200) {
  //       setPdcList(response?.data?.result?.data)
  //       setLoading(false)
  //     }
  //   } catch (error) {

  //   }
  // }

  // const onUpdateCheckStatus = async (status, record) => {
  //     try {
  //       setLoading(true)
  //         const res = await updatePDCStatus(
  //             {
  //             leaseId: record.leaseId,
  //             pdcId: record._id,
  //             },
  //             { creditCardRequest: status }
  //         );
  //         getAllRequests()
  //         if(pdcList.length === 1) {
  //           // onUpdate()
  //         } else {
  //           getPendingCreditCardRequests()
  //         }
  //     } catch (error) {
  //         console.log(error);
  //     }
  // };

  return (
    <Layout className="layout-space prop_popup">
      <Content className="main-content">
        {!loading && (
          <Row gutter={[32, 32]}>
            <Col span={24}>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Row gutter={[24, 24]} justify="end">
                    <Col span={24}>
                      <Grid
                        // ref={gridRef}
                        data={dataList}
                        columns={dataCol}
                        pagination={false}
                        defaultSettings={gridProps}
                        noRowlabel="Payments"
                        fullPage={true}
                        overlayNoRowsTemplate={"Payments data cannot be found."}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {loading && (
          <div
            className="justify-center"
            style={{ textAlign: "center", minHeight: "255px" }}
          >
            <Spin />
          </div>
        )}
      </Content>
    </Layout>
  );
};
