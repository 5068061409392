import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Typography, Modal, message, Button, Form } from "antd";
import { useForm } from "react-hook-form";

import { InputField, InputSwitch } from "../../../atoms/FormElement";
import { PopupConfirm } from "../../../atoms/Popup";
import { payment_plans } from "./utils";
import { leaseService } from "../../../../configs/constants";
import axios from "axios";
import { useSelector } from "react-redux";


const { Text, Paragraph } = Typography;

const AddPaymentPlan = (props) => {
    const {
        plansData,
        onClose,
        setPlansValue,
        sendPlanApi,
    } = props;

    // redux states
    const { bookingDetails, paymentPlans } = useSelector(
        (state: any) => state.newBooking
    );

    const {
        control,
        formState: { errors, isDirty },
        setValue,
        getValues,
    } = useForm();


    const [addPlans, setAddPlans] = useState([]);

    useEffect(() => {
        // console.log("dirty or not", isDirty);
    }, [isDirty]);


    useEffect(() => {
        setAddPlans(plansData);
    }, [plansData]);

    const savePaymentPlan = () => {
        const addPayments = getValues("addpayment");
        PopupConfirm({
            title: `Are you sure you want to update the plans?`,
            content: "",
            onOk: () => {
                if (addPayments?.length > 0) {
                    const finalPayments = addPayments.filter(p => p?.annualRent && p?.annualRent > 0);
                    setPlansValue(finalPayments);
                    onClose();
                    message.success("Saved")
                }
            },
            okText: "Confirm",
        });

    }


    const sendPaymentPlan = () => {
        const addPayments = getValues("addpayment");
        PopupConfirm({
            title: `Are you sure you want to send plans for approval?`,
            content: "",
            onOk: () => {
                if (addPayments?.length > 0) {
                    const finalPayments = addPayments.filter(p => p?.annualRent && p?.annualRent > 0);

                    // console.log("finalPay", finalPayments, addPlans, plansData);

                    sendPlanApi([...finalPayments]);

                    // onClose();
                    // message.success("Saved")
                }
            },
            okText: "Confirm",
        });
    }


    return (
        <>
            <Form
                layout="vertical"
                name="addplansForm"
                className="bf-main"
                scrollToFirstError
            // onFinish={handleSubmit(onSubmit)}
            >
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Text className="fontWeight600 ag-default mb-1 ft-main-label">Payment Plans</Text>
                    </Col>
                    <Col span={24}>
                        {/* <Paragraph className="m-10 smallFont13 fp-para-line">
                            You can add a new payment plan, edit, or disable an existing payment plan. If you add or edit a payment plan, you need to send an approval request
                        </Paragraph> */}
                    </Col>

                    <Col span={12}>
                        <Text className="fontWeight600 ag-default mb-1">NO OF PAYMENTS</Text>
                    </Col>
                    <Col span={4}>
                        <Text className="fontWeight600 ag-default mb-2">ANNUAL RENT</Text>
                    </Col>
                    <Col span={4}>
                        <Text className="fontWeight600 ag-default mb-2">NUMBER OF YEARS</Text>
                    </Col>
                    <Col span={4}>
                        <Text className="fontWeight600 ag-default mb-2">SECURITY DEPOSIT</Text>
                    </Col>
                </Row>
                <Row gutter={[24, 24]} className="bf-main">
                    <Col span={24} className="ft-divider" />
                    {[...addPlans]?.map((item, index) => (
                        <Fragment key={index}>
                            <Col span={12}>
                                <Row>
                                    <Col span={6}>
                                        <Text
                                            className="ag-dark fontWeight600"
                                            style={{ fontSize: "14px" }}
                                        >
                                            {item.installments} Cheques
                                        </Text>
                                    </Col>
                                    <Col span={6}>
                                        <InputField
                                            classes="fm-input-hidden"
                                            fieldname={`addpayment[${index}]`}
                                            control={control}
                                            initValue={item}
                                            iProps={{ readOnly: true, type: "hidden" }}
                                        />
                                        {(item?.annualRent > 0) && (  //item?.newPlan != true
                                            <InputSwitch
                                                isRequired={true}
                                                fieldname={`addpayment[${index}].checked`}
                                                control={control}
                                                valueGot={!!item?.checked}
                                                setValue={setValue}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={4}>
                                <InputField
                                    label=""
                                    fieldname={`addpayment[${index}].annualRent`}
                                    setValue={setValue}
                                    // valueGot={item.vat}
                                    control={control}
                                    onChange={(e) => {
                                        let tempPlan = [...addPlans]
                                        tempPlan[index] = item
                                        setAddPlans(tempPlan);
                                        // calculateTotal(index);
                                    }}
                                    initValue={item.annualRent || 0}
                                    iProps={{
                                        // readOnly: true,
                                        type: "number",
                                        // disabled: props.disableAll
                                        //   ? true
                                        //   : item.type == "ejari_fee" ||
                                        //     item.type == "security_deposit"
                                        //     ? true
                                        //     : false,
                                    }}
                                    isRequired={true}
                                    rules={{ required: "Please enter annual rent" }}
                                // validate={errors?.addpayment?.[index]?.vat && "error"}
                                // validMessage={
                                //     errors?.addpayment?.[index]?.vat && errors?.addpayment?.[index]?.vat.message
                                // }
                                />
                            </Col>
                            <Col span={4}>
                                <InputField
                                    label=""
                                    fieldname={`addpayment[${index}].noOfYear`}
                                    setValue={setValue}
                                    // valueGot={item.total}
                                    control={control}
                                    initValue={item?.noOfYear || 1}
                                    iProps={{
                                        // readOnly: true,
                                        type: "number",
                                        // disabled: props.disableAll
                                        //   ? true
                                        //   : item.type == "ejari_fee" ||
                                        //     item.type == "security_deposit"
                                        //     ? true
                                        //     : false,
                                    }}
                                    isRequired={true}
                                    rules={{ required: "Please enter total amount" }}
                                // validate={errors?.addpayment?.[index]?.total && "error"}
                                // validMessage={
                                //     errors?.addpayment?.[index]?.total && errors?.addpayment?.[index]?.total.message
                                // }
                                />
                            </Col>
                            <Col span={4}>
                                <InputField
                                    label=""
                                    fieldname={`addpayment[${index}].securityDeposit`}
                                    setValue={setValue}
                                    // valueGot={item.total}
                                    control={control}
                                    initValue={item?.securityDeposit || 0}
                                    iProps={{
                                        // readOnly: true,
                                        type: "number",
                                        // disabled: props.disableAll
                                        //   ? true
                                        //   : item.type == "ejari_fee" ||
                                        //     item.type == "security_deposit"
                                        //     ? true
                                        //     : false,
                                    }}
                                    isRequired={true}
                                    rules={{ required: "Please enter total amount" }}
                                // validate={errors?.addpayment?.[index]?.total && "error"}
                                // validMessage={
                                //     errors?.addpayment?.[index]?.total && errors?.addpayment?.[index]?.total.message
                                // }
                                />
                            </Col>
                        </Fragment>
                    ))}
                </Row>
                <Row gutter={24} justify="end">
                    <Col>
                        <Button size="large" className="cc-fotter_back-button" onClick={() => onClose()}>
                            Cancel
                        </Button>
                    </Col>
                    {(bookingDetails?.paymentPlanApproval != "disable") && (
                        <Col>
                            <Button size="large" disabled={!isDirty || bookingDetails?.paymentPlanApproval == "sent"} type="primary" className="cc-fotter_next-button" htmlType="button" onClick={sendPaymentPlan}>
                                {bookingDetails?.paymentPlanApproval == "sent" ? "Sent" : "Send"} for approval
                            </Button>
                        </Col>
                    )}
                    <Col>
                        <Button disabled={(isDirty && bookingDetails?.paymentPlanApproval != "disable") || bookingDetails?.paymentPlanApproval == "sent"} size="large" type="primary" className="cc-fotter_next-button" htmlType="button" onClick={savePaymentPlan}>
                            Save
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
};
export default AddPaymentPlan;
