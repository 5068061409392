import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button, message } from "antd";
import {
  InputField,
  ReactSelectField,
  TextAreaField,
} from "../../atoms/FormElement";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getTenantRejectionReason } from "../../modules/TenantDetail/ducks/actions";
import { baseContractService } from "../../../configs/constants";
import axios from "../../../utils/axiosInceptor";

const RejectForm = (props) => {
  const { onReject, onCancel, field } = props;
  const dispatch:any = useDispatch();
  const { control, formState: { errors }, reset, handleSubmit } = useForm();

  const reasonsData = useSelector(
    (state: any) => state.tenantDetails.getRejectReasons,
  );

  useEffect(() => {
    dispatch(getTenantRejectionReason());
  }, []);

  const handleReject = (data) => {
    reset();
    onReject(data.reason.value);
  };
  return (
    <Form layout="vertical" onFinish={handleSubmit(handleReject)}>
      <Row gutter={24}>
        <Col span={12}>
          <ReactSelectField
            label="Select a reason"
            fieldname="reason"
            iProps={{ placeholder: "Select reasons" }}
            initValue=""
            isRequired={true}
            control={control}
            rules={{ required: "Required" }}
            validate={errors.reason && "error"}
            validMessage={errors.reason && errors.reason.message}
            selectOptions={[{ label: "yes", value: "yes" }]}
            selectOption={reasonsData.map((e) => {
              return {
                label: e.name,
                value: e.id,
              };
            })}
          />
        </Col>
        <Col span={24}>
          <TextAreaField
            label="Enter a comment for rejection:"
            fieldname="rejectReason"
            iProps={{
              placeholder: "Some reason....",
            }}
            initValue=""
            control={control}
          />
        </Col>
      </Row>
      <Row gutter={24} justify="space-between">
        <Col>
          <Button size="large" onClick={() => onCancel(field)}>
            Back
          </Button>
        </Col>
        <Col>
          <Button size="large" className="ag-red-button" htmlType="submit">
            Reject
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default RejectForm;
