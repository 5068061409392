import { Modal } from "antd";
import React, { useState, useEffect } from "react";


const PdfView = (props) => {

    const { openFileView, setOpenFileView, previewFile, pdfData } = props;

    return (

        <Modal
            open={openFileView}
            onCancel={() => setOpenFileView(false)}
            cancelText="Close"
            okButtonProps={{ style: { display: "none" } }}
            closable={false}
            cancelButtonProps={{ style: { position: "absolute", top: "44px", left: "44px" } }}
            style={{ top: 20 }}
            width={"80%"}
            className="pdf-width"
        >

            <div style={{ marginTop: 50 }}>
                {(!previewFile && pdfData) && (<>
                    <embed src={pdfData} title=""
                        width="100%" height="2100px" />
                </>
                )}
                {(previewFile) && (<>
                    <embed src={previewFile} title=""
                        width="100%" height="2100px" />
                </>
                )}
            </div>

        </Modal>
    );
};
export default PdfView;
