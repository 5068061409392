import * as React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import Root from "./routing/Root";
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from "./redux/store";
import "./styles/antd.less";
import "./styles/styles.scss";

const rootEl = ReactDOM.createRoot(document.getElementById('root'));

rootEl.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Root />
    </PersistGate>
  </Provider>,
);
