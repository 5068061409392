import React, { useState, useEffect } from "react";
import DashboardLayout from "../../molecules/DashboardLayout";
import {
  Row,
  Col,
  Input,
  Pagination,
  Card,
  Form,
  Space,
  Button,
  Select,
  Dropdown,
  Menu,
  message,
  Typography,
} from "antd";
import SectionHeader from "../../molecules/SectionHeader";
import { useDispatch, useSelector } from "react-redux";
import Grid from "../../atoms/Grid";
import { SearchOutlined, DownOutlined } from "@ant-design/icons";
import { getEjariList } from "./ducks/actions";
import axios from "../../../utils/axiosInceptor";
import { baseContractService } from "../../../configs/constants";
import { Popup } from "../../atoms/Popup";
import Rejection from "./Rejection";

const { Option } = Select;
const { Title } = Typography;

const gridProps = {
  sortable: true,
  filter: true,
  resizable: true,
};
// lease_activated - contact number exist
const dropDown = [
  { id: "Active", name: "Activate", status: "#1C5D3B" },
  { id: "Send", name: "Send to prospect", status: "" },
  { id: "Download", name: "Download Contract", status: "" },
  { id: "Cancel", name: "Cancel", status: "#FB7171" },
];
// lease_activated - contact number not exist
const dropDown3 = [
  { id: "Active", name: "Activate", status: "#1C5D3B" },
  { id: "Send", name: "Send to prospect", status: "" },
  { id: "Cancel", name: "Cancel", status: "#FB7171" },
];
// lead converted
const dropDown2 = [
  { id: "Download", name: "Download Contract", status: "" },
  { id: "Signed", name: "Download Signed Contract", status: "" },
  { id: "Certificate", name: "Download Certificate", status: "" },
  { id: "Receipt", name: "Download Receipt", status: "" },
  { id: "Terminate", name: "Terminate", status: "#FB7171" },
];

export default (props) => {
  const dispatch:any = useDispatch();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [visible, setVisible] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [searchByT, setSearchBy] = useState("tenant_name");
  const [search, setSearch] = useState("");
  const ejariApi = useSelector((state: any) => state.ejari.ejariList);
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    dispatch(getEjariList(limit, currentPage, "", ""));
  }, []);

  useEffect(() => {
    if (ejariApi.results) {
      let d = [];
      ejariApi.results.map((item) => {
        d.push({
          id: item.lease_id,
          tenant_name: item.name,
          unit_code: item.asset_unit_identifier || "",
          base_rent: item.lease_items?.total_rent?.amount,
          contract_type: item.contract_type || "",
          status: item.status || "",
          contract_number: item.contract_number || "",
        });
      });
      setData(d);
    }
  }, [ejariApi.results]);

  const onAction = async (action, id) => {
    let url = "";
    const body = {
      lease_id: id,
    };
    if (action != "Terminate") {
      setLoading(true);
      if (action === "Download") {
        url = `${baseContractService}/download-ejari-contract?lease_id=${id}`;
        try {
          const res = await axios.get(url);
          if (
            res.data.result.imageUrl != "https://s3.ap-south-1.amazonaws.com/"
          ) {
            const win = window.open(res.data.result.imageUrl, "_blank");
            win.focus();
            setLoading(false);
          } else {
            setLoading(false);
            onAction(action, id);
          }
        } catch (e) {
          setLoading(false);
          const { response } = e;
          message.error(response?.statusText);
        }
      } else if (action === "Certificate") {
        url = `${baseContractService}/download-ejari-certificate`;
        try {
          const res: any = await axios.post(url, body);
          if (
            res.data.result.imageUrl != "https://s3.ap-south-1.amazonaws.com/"
          ) {
            const win = window.open(res.data.result.imageUrl, "_blank");
            win.focus();
            setLoading(false);
          } else {
            setLoading(false);
            onAction(action, id);
          }
        } catch (e) {
          setLoading(false);
          const { response } = e;
          message.error(response?.statusText);
        }
      } else if (action === "Receipt") {
        url = `${baseContractService}/download-ejari-receipt`;
        try {
          const res = await axios.post(url, body);
          if (
            res.data.result.imageUrl != "https://s3.ap-south-1.amazonaws.com/"
          ) {
            const win = window.open(res.data.result.imageUrl, "_blank");
            win.focus();
            setLoading(false);
          } else {
            setLoading(false);
            onAction(action, id);
          }
        } catch (e) {
          setLoading(false);
          const { response } = e;
          message.error(response?.statusText);
        }
      } else if (action === "Signed") {
        url = `${baseContractService}/get-signed-contract-url?lease_id=${id}&type=signed_ejari_contract`;
        try {
          const res = await axios.get(url);
          if (
            res.data.result.imageUrl != "https://s3.ap-south-1.amazonaws.com/"
          ) {
            const win = window.open(res.data.result.imageUrl, "_blank");
            win.focus();
            setLoading(false);
          } else {
            setLoading(false);
            onAction(action, id);
          }
        } catch (e) {
          setLoading(false);
          const { response } = e;
          message.error(response?.statusText);
        }
      } else {
        if (action === "Active") {
          url = `${baseContractService}/activate-ejari-contract`;
        } else if (action === "Send") {
          url = `${baseContractService}/send-ejari-contract-to-prospect`;
        } else if (action == "Cancel") {
          url = `${baseContractService}/cancel-ejari-contract`;
        } else if (action == "Create") {
          url = `${baseContractService}/create-ejari-contract`;
        } else if (action == "Terminate") {
          url = `${baseContractService}/terminate-ejari-contract`;
        }
        try {
          await axios.post(url, body);
          message.success(`${action} successfully`);
          setTimeout(() => {
            dispatch(getEjariList(limit, currentPage, search, searchByT));
            setLoading(false);
          }, 1000);
        } catch (e) {
          setLoading(false);
          const { response } = e;
          message.error(response?.data.message);
        }
      }
    } else {
      setSelectedLead(id);
      setVisible(true);
    }
  };

  const checkDisable = (item, stat) => {
    if (item.name == "Activate" && stat != "ejari_contract_downloaded") {
      return true;
    } else if (item.name == "Send to prospect" && stat == "lease_activated") {
      return true;
    } else {
      return false;
    }
  };

  const menu = (id, stat) => (
    <Menu onClick={(e) => onAction(e.key, id)}>
      {dropDown.map((item, index) => (
        <Menu.Item
          key={item.id}
          disabled={checkDisable(item, stat)}
          style={{ color: `${item.status}` }}
        >
          {item.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  const menu1 = (id, stat) => (
    <Menu onClick={(e) => onAction(e.key, id)}>
      {dropDown2.map((item, index) => (
        <Menu.Item key={item.id} style={{ color: `${item.status}` }}>
          {item.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  const menu3 = (id, stat) => (
    <Menu onClick={(e) => onAction(e.key, id)}>
      {dropDown3.map((item, index) => (
        <Menu.Item key={item.id} style={{ color: `${item.status}` }}>
          {item.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  const menu2 = (id) => (
    <Menu onClick={(e) => onAction(e.key, id)}>
      <Menu.Item key={"Create"}>Create Ejari</Menu.Item>
    </Menu>
  );

  const MoreAction = (param) => {
    return (
      <Dropdown
        overlay={
          param.data?.status != "created" &&
          param.data?.status != "lead_converted" ? (
            param.data?.status != "lease_activated" ? (
              param.data?.contract_number ? (
                menu(param.data.id, param.data.status)
              ) : (
                menu3(param.data.id, param.data.status)
              )
            ) : param.data?.contract_number ? (
              menu1(param.data.id, param.data.status)
            ) : (
              <Menu>
                <Menu.Item>Not Available</Menu.Item>
              </Menu>
            )
          ) : (
            menu2(param.data.id)
          )
        }
        trigger={["click"]}
      >
        <Button>
          Select an Action
          <DownOutlined />
        </Button>
      </Dropdown>
    );
  };

  const leadCol = [
    {
      headerName: "ID",
      field: "id",
      maxWidth: 120,
    },
    {
      headerName: "Tenant Name",
      field: "tenant_name",
    },
    {
      headerName: "Unit Code",
      field: "unit_code",
    },
    {
      headerName: "Contract Type",
      field: "contract_type",
    },
    {
      headerName: "Annual Base Rent",
      field: "base_rent",
    },
    {
      headerName: "Contract Number",
      field: "contract_number",
    },
    {
      headerName: "More Action",
      field: "m_action",
      cellRendererFramework: MoreAction,
      maxWidth: 250,
    },
  ];

  const handlePagination = (page, pageSize) => {
    setCurrentPage(page - 1);
    setLimit(pageSize);
    dispatch(getEjariList(pageSize, page - 1, search, searchByT));
  };

  const onSearch = (val) => {
    setCurrentPage(0);
    if (val.searchText) {
      setSearch(val.searchText);
      setSearchBy(val.searchBy);
      dispatch(getEjariList(limit, 0, val.searchText, val.searchBy));
    } else {
      setSearch("");
      dispatch(getEjariList(limit, 0, "", ""));
    }
  };

  const onReject = async (reason) => {
    setLoading(true);
    let url = `${baseContractService}/terminate-ejari-contract`;
    const body = {
      leaseId: selectedLead,
      terminationReason: reason,
    };
    try {
      await axios.post(url, body);
      message.success(`Reject successfully`);
      setVisible(false);
      setTimeout(() => {
        dispatch(getEjariList(limit, currentPage, search, searchByT));
        setLoading(false);
      }, 1000);
      setSelectedLead(null);
    } catch (e) {
      setLoading(false);
      const { response } = e;
      message.error(response?.statusText);
    }
  };

  const popup = [
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Reject Reason
        </Title>
      ),
      width: 650,
    },
  ];

  const popupContent = {
    title: popup[0].title,
    closable: true,
    visibility: visible,
    content: (
      <Rejection onReject={onReject} onClose={() => setVisible(false)} />
    ),
    width: popup[0].width,
    onCancel: () => setVisible(false),
  };

  const SearchFelds = () => {
    return (
      <Form onFinish={onSearch}>
        <Space>
          <Form.Item name="searchText" className="mb-0" initialValue={search}>
            <Input placeholder="Tenant Name" value={search} />
          </Form.Item>
          <Form.Item name="searchBy" className="mb-0" initialValue={searchByT}>
            <Select placeholder="Search By" value={searchByT}>
              <Option value="tenant_name">Tenant Name</Option>
            </Select>
          </Form.Item>
          <Form.Item className="mb-0">
            <Button
              htmlType="submit"
              icon={<SearchOutlined />}
              className="ag-gray-button"
              type="link"
            />
          </Form.Item>
        </Space>
      </Form>
    );
  };

  return (
    <>
      <DashboardLayout load={loading}>
        <Row gutter={[32, 32]}>
          <Col span={24}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <SectionHeader heading="Ejari" noBorder={true} />
              </Col>
              <Col span={24}>
                <Pagination
                  className="ag-property-text-pagination"
                  total={ejariApi?.count || 0}
                  showTotal={(total, range) =>
                    `Showing ${range[0]}-${range[1]} of ${total} Ejari`
                  }
                  showSizeChanger
                  pageSize={limit}
                  defaultCurrent={1}
                  current={currentPage + 1}
                  onChange={handlePagination}
                />
              </Col>
              <Col span={24}>
                <Card
                  bordered={false}
                  className="ag-nospace-body"
                  extra={<SearchFelds />}
                >
                  <Grid
                    refCol="status"
                    data={data}
                    columns={leadCol}
                    pagination={false}
                    defaultSettings={gridProps}
                    noRowlabel="Ejari"
                    fullPage={true}
                  />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </DashboardLayout>
      <Popup {...popupContent} />
    </>
  );
};
