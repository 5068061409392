import { useForm } from "react-hook-form";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import {
  Row,
  Col,
  Button,
  Typography,
  Form,
  Collapse,
  message,
  Card,
  Radio,
  Space,
  UploadFile,
  Upload,
} from "antd";
import { leaseService } from "../../../configs/constants";
import TenantDetails from "./components/TenantDetails";
import axios from "../../../utils/axiosInceptor";
import { Popup, PopupConfirm } from "../../atoms/Popup";
import DashboardLayout from "../../molecules/DashboardLayout";
import {
  getParkingSlots,
  getLeaseFormDetails,
  getLeaseAdditionalDocumentList,
  emptyLead,
  getPaymentModes,
} from "./ducks/actions";
import UnitDetails from "./components/UnitDetails";
import LeaseTerms from "./components/LeaseTerms";
import {
  uniquiFileName,
  getContractSingleUpload,
  getSingleUpload,
} from "../../../utils/fileUpload";
import BookingTerms from "./components/BookingTerms";
import RenewalTerms from "./components/RenewalTerms";
import BillingSchedule from "./components/BillingSchedule";
import DocumentsList from "./components/DocumentsList";
import AdditionalDocument from "./components/AdditionalDocs";
import ApprovalHistory from "./components/ApprovalHistory";
import Rejectform from "./components/RejectFrom";
import PaymentSchedule from "./components/PaymentSchedule";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { getPaymentConfig } from "../Properties/AddProperty/ducks/actions/paymentAction";
import { cancelLeaseForm } from "../../../utils/fileDownload";
import {
  ActivateEjari,
  CreateEjariContract,
  DownloadEjariContract,
  PrintEjariCertificateContract,
  sendTenantInvite,
  updateBookingStatus,
  UploadSignedEjariContract,
} from "../BookingListing/ducks/actions";
import { getConfigData } from "../Properties/ducks/actions";
import { RcFile } from "antd/lib/upload/interface";
import { UploadOutlined } from "@ant-design/icons";
import { getPresignedImage } from "../../../utils/media";
import dayjs from "dayjs";
import { dateByformat } from "../../../utils/dateHelper";
import { getBookingUnitDetail } from "../BookingForm/ducks/actions";

var _ = require("lodash");

const { Title } = Typography;
const { Panel } = Collapse;

const fileObjectinit = {
  passportFile: {
    uniqueName: "",
    fileData: "",
  },
  visaFile: {
    uniqueName: "",
    fileData: "",
  },
  emiratesFile: {
    uniqueName: "",
    fileData: "",
  },
  financialSupportFile: {
    uniqueName: "",
    fileData: "",
  },
  signedBookFile: {
    uniqueName: "",
    fileData: "",
  },
  otherDocsFile: {
    uniqueName: "",
    fileData: "",
  },
};

export default (props) => {
  const dispatch: any = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    resetField,
    register,
  } = useForm();

  const { LEASEID } = useParams();
  const { state } = useLocation();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isClearTriggered, setIsClearTriggered] = useState(false);
  const [visible, setVisible] = useState(false);
  const [disableAll, setDisableAll] = useState(false);
  const [disableApproveReject, setDisableApproveReject] = useState(false);
  const [disableCancelButton, setDisableCancelButton] = useState(false);
  const [disableLeasebtn, setDisableLeasebtn] = useState(false);
  const [deletedAdditionalDocs, setDeletedAdditionalDocs] = useState([]);
  const [disabletenant, setDisableTenant] = useState(false);

  //Booking create to booking approved

  const [cancelBtn, setCancelBtn] = useState(true);
  const [saveBtn, setSaveBtn] = useState(true);
  const [updatePdcBtn, setUpdatePdcBtn] = useState(false);
  const [sendBtn, setSendBtn] = useState(false);
  const [reSendBtn, setReSendBtn] = useState(false);
  const [approveBtn, setApproveBtn] = useState(false);
  const [rejectBtn, setRejectBtn] = useState(false);
  const [confirmBtn, setConfirmBtn] = useState(false);

  //Booking approved to Ejari/Lease
  const [createEjariBtn, setCreateEjariBtn] = useState(false);
  const [downloadEjariBtn, setDownloadEjariBtn] = useState(false);
  const [uploadEjariBtn, setUploadEjariBtn] = useState(false);
  const [activateEjariBtn, setActivateEjariBtn] = useState(false);
  const [downloadCertificateBtn, setDownloadCertificateBtn] = useState(false);

  const [ejariDownloadUrl, setEjariDownloadUrl] = useState("");
  const [signedEjariDownloadUrl, setSignedEjariDownloadUrl] = useState("");
  const [ejariCertificateDownloadUrl, setEjariCertificateDownloadUrl] =
    useState("");

  const [requiredDocuments, setRequiredDocuments] = useState([]);

  const [finalRequiredDocuments, setFinalRequiredDocuments] =
    useState(fileObjectinit);
  const [sendEmptyDoc, setSendEmptyDoc] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState({});
  const [rejectionStatus, setRejectionStatus] = useState("");
  const [rejectionDocument, setRejectionDocument] = useState("");
  const [legalEntity, setLegalEntity] = useState("");
  const [unitDetail, setUnitDetail] = useState(null);

  const [signedEjariFile, setSignedEjariFile] = useState<UploadFile[]>([]);

  const leaseFormData = useSelector(
    (state: any) => state.leasingForm.leaseFormData
  );
console.log(leaseFormData);

  const [isCSVUploaded, setIsCSVUploaded] = useState(false);

  // const leaseFormData = state?.leaseFormData;

  const configKeys = ["nationalityList", "requiredBookingDocumentsList"];
  const configData = useSelector((state: any) => state.dashboard.configData);

  useEffect(() => {
    dispatch(getConfigData(configKeys.join(",")));
  }, []);

  const parkingSlotsDataFromApi = useSelector(
    (state: any) => state.leasingForm.parkingSlots
  );
  const loginMode = JSON.parse(window.localStorage.getItem("login-response"))
    ?.data?.role_name;

  useEffect(() => {
    if (state?.selectedProperty && state?.selectedUnit) {
      const unit = {
        propertyName: state?.selectedProperty?.property_name,
        unitCode: state?.selectedUnit?.unit?.unitCode,
        usagesType: state?.selectedUnit?.unit?.usagesType,
        unitType: state?.selectedUnit?.unit?.unitType,
        unitSubType: state?.selectedUnit?.unit?.unitSubType,
        areaSqft:
          state?.selectedUnit?.unit?.areaSqft ??
          state?.selectedUnit?.unit?.suiteSize,
      };
      setSelectedUnit(unit);
      const businessUnit = state?.selectedUnit?.businessUnitName;
      if (businessUnit) setLegalEntity(businessUnit);
    }
  }, [state]);

  useEffect(() => {
    if (leaseFormData?.propertyDetails)
      setUnitDetail({ ...leaseFormData?.propertyDetails, "propertyId": leaseFormData?.propertyId })
    else if (selectedUnit)
      setUnitDetail(selectedUnit)
  }, [leaseFormData?.propertyDetails, selectedUnit]);

  useEffect(() => {
    // dispatch(getParkingSlots(LEASEID));
    LEASEID && dispatch(getLeaseFormDetails(LEASEID, "renewal"));
    // dispatch(getPaymentModes());
    // dispatch(getLeaseAdditionalDocumentList());
    // dispatch(getPaymentConfig());
    // loginMode === "Lease_Manager" && setDisableAll(true);
    return (): any => dispatch(emptyLead());
  }, []);

  useEffect(() => {
    if (leaseFormData?.unitId)
      dispatch(getBookingUnitDetail(leaseFormData?.unitId));
  }, [leaseFormData?.unitId]);

  const clearData = () => {
    return (
      <Button type="link" onClick={() => setIsClearTriggered(true)}>
        Clear All Data
      </Button>
    );
  };

  // const resetButtons = () => {
  //   setSaveBtn(true);
  //   setUpdatePdcBtn(false);
  //   setCancelBtn(true);
  //   setSendBtn(false);

  //   setApproveBtn(false);
  //   setRejectBtn(false);
  //   setConfirmBtn(false);

  //   setCreateEjariBtn(false);
  //   setDownloadEjariBtn(false);
  //   setUploadEjariBtn(false);
  //   setActivateEjariBtn(false);
  //   setDownloadCertificateBtn(false);
  // }

  // useEffect(() => {
  //   resetButtons();
  //   const status = leaseFormData?.leaseStatus;
  //   var documentsPending = 0;

  //   if (leaseFormData?.requiredDocuments?.length > 0) {
  //     documentsPending = leaseFormData?.requiredDocuments.filter(item => {
  //       return item.status !== "approved"
  //     }).length;
  //   }

  //   const check = {
  //     save: true,
  //     cancel: true,
  //     send: false,
  //     approve: false,
  //     reject: false,
  //     updatepdc: false,
  //     confirm: false,
  //     createEjari: false,
  //     downloadEjari: false,
  //     uploadEjari: false,
  //     activateEjari: false,
  //     downloadEjariCertificate: false
  //   };

  //   if (status) {
  //     if (status === "created") {
  //       check.send = true;
  //       check.cancel = true;
  //       // setSendBtn(true)
  //       // setCancelBtn(true)
  //     }
  //     if (status !== "tenant_information_approved" && status !== "tenant_information_incomplete" && documentsPending === 0) {
  //       check.approve = true;
  //       check.reject = true;
  //       check.cancel = true;
  //       // setApproveBtn(true)  //booking_confirmed
  //       // setRejectBtn(true)
  //       // setCancelBtn(true)
  //     }
  //     if (status === "tenant_information_approved") {
  //       check.updatepdc = true;
  //       check.save = false;
  //       check.cancel = true;
  //       // setUpdatePdcBtn(true);
  //       // setSaveBtn(false);
  //       // setCancelBtn(true)

  //     }
  //     if (status === "landlord_pdc_updated") {
  //       check.confirm = true;
  //       check.updatepdc = true;
  //       check.save = false;
  //       check.cancel = true;
  //       check.approve = false;
  //       check.reject = false;
  //       // setConfirmBtn(true);
  //       // setUpdatePdcBtn(true);
  //       // setSaveBtn(false);
  //       // setApproveBtn(false)
  //       // setRejectBtn(false)
  //       // setCancelBtn(true)
  //     }
  //     if (status === "booking_approved") {
  //       check.createEjari = true;
  //       check.updatepdc = false;
  //       check.save = false;
  //       check.cancel = true;
  //       check.approve = false;
  //       check.reject = false;

  //       // setCreateEjariBtn(true);
  //       // setUpdatePdcBtn(false);
  //       // setSaveBtn(false);
  //       // setApproveBtn(false)
  //       // setRejectBtn(false)
  //       // setCancelBtn(true)
  //     }
  //     if (status === "draft_ejari" || status === "tenant_signed_ejari_contract"
  //       || status === "landlord_signed_ejari_contract" || status === "ejari_contract_downloaded"
  //       || status === "tenant_signed_ejari_contract_downloaded"
  //       || status === "ejari_contract_activated") {

  //       check.downloadEjari = true;
  //       check.save = false;
  //       check.cancel = false;
  //       check.approve = false;
  //       check.reject = false;

  //       // setDownloadEjariBtn(true);
  //       // setSaveBtn(false);
  //       // setApproveBtn(false)
  //       // setRejectBtn(false)
  //       // setCancelBtn(false)
  //     }
  //     // if ((status === "draft_ejari" || status === "tenant_signed_ejari_contract" || status === "ejari_contract_downloaded") && (status != "ejari_contract_activated" && status != "ejari_certificate_downloaded")) {
  //     if (status === "tenant_signed_ejari_contract" || status === "tenant_signed_ejari_contract_downloaded") {

  //       check.uploadEjari = true;
  //       check.save = false;
  //       check.cancel = false;
  //       check.approve = false;
  //       check.reject = false;

  //       // setUploadEjariBtn(true);
  //       // setSaveBtn(false);
  //       // setApproveBtn(false)
  //       // setRejectBtn(false)
  //       // setCancelBtn(false)
  //     }
  //     if (status === "landlord_signed_ejari_contract") {
  //       check.activateEjari = true;
  //       check.save = false;
  //       check.cancel = false;
  //       check.approve = false;
  //       check.reject = false;

  //       // setActivateEjariBtn(true);
  //       // setSaveBtn(false);
  //       // setApproveBtn(false)
  //       // setRejectBtn(false)
  //       // setCancelBtn(false)

  //       // setDisableAll(true);
  //     }
  //     if (status === "ejari_contract_activated" || status === "ejari_certificate_downloaded" || status === "active") {
  //       check.downloadEjariCertificate = true;
  //       check.activateEjari = true;
  //       check.save = false;
  //       check.cancel = false;
  //       check.approve = false;
  //       check.reject = false;
  //       // setDownloadCertificateBtn(true);
  //       // setActivateEjariBtn(false);
  //       // setSaveBtn(false);
  //       // setApproveBtn(false)
  //       // setRejectBtn(false)
  //       // setCancelBtn(false)

  //       // setDisableAll(true);
  //     }

  //     //TODO:: REVERSE ABOVE CONDITIONS ORDER FOR CODE OPTIMIZATION

  //     setSaveBtn(check.save);
  //     setUpdatePdcBtn(check.updatepdc);
  //     setCancelBtn(check.cancel);
  //     setSendBtn(check.send);

  //     setApproveBtn(check.approve);
  //     setRejectBtn(check.reject);
  //     setConfirmBtn(check.confirm);

  //     setCreateEjariBtn(check.createEjari);
  //     setDownloadEjariBtn(check.downloadEjari);
  //     setUploadEjariBtn(check.uploadEjari);
  //     setActivateEjariBtn(check.activateEjari);
  //     setDownloadCertificateBtn(check.downloadEjariCertificate);

  //   }
  // }, [leaseFormData]);

  useEffect(() => {
    if (leaseFormData?.leaseStatusHistory?.length > 0) {
      let csvUploaded = !!leaseFormData?.leaseStatusHistory.find(
        (e) => e.status === "uploaded"
      );
      setIsCSVUploaded(csvUploaded);
    }
  }, [leaseFormData]);

  // displayOnly

  const fileProps = {
    className: "file-upload",
    name: "file",
    accept: ".pdf",
    multiple: false,
    // showUploadList: false,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} Please upload only CSV file`);
      }
    },
    onRemove: (file) => {
      setSignedEjariFile([]);
    },
    beforeUpload: (file) => {
      setSignedEjariFile([file]);

      return false;
    },
    signedEjariFile,
  };

  //TODO: make common bw both forms
  useEffect(() => {
    let finalDocs = [];
    if (configData?.requiredBookingDocumentsList) {
      const docs = _.map(
        configData?.requiredBookingDocumentsList,
        (value, key) => {
          return { value: key, label: value };
        }
      );
      finalDocs = docs;
      if (leaseFormData?.requiredDocuments?.length > 0) {
        let existDocs = leaseFormData?.requiredDocuments.map((item) => {
          return item?.type;
        });
        finalDocs = docs.filter((item) => !existDocs.includes(item.value));
      } else {
        finalDocs = docs.map((item) => {
          if (
            item.value == "passport" ||
            item.value == "emiratesIdFront" ||
            item.value == "emiratesIdBack"
          )
            item.isDisabled = true;
          else item.isDisabled = false;
          return item;
        });
      }
      setRequiredDocuments(finalDocs);
    }
  }, [
    configData?.requiredBookingDocumentsList,
    leaseFormData?.requiredDocuments,
  ]);

  const disableClone = (type) => {
    let arr = [];
    arr = getValues().additionalDocs;

    // if (leaseFormData?.requiredDocuments?.length > 0)
    //   arr = [...arr, ...leaseFormData?.requiredDocuments?.map((item) => ({ value: item.type }))]

    let check = arr.filter((item) => item.value == type);
    // let check2 = arr.filter((item) => item.value == type); // add for leasedata

    if (check.length > 0) {
      let newArr = [];
      newArr = [...requiredDocuments];
      let findType = null;
      findType = newArr.findIndex((item) => item.value == type);
      if (findType != null) {
        requiredDocuments.map((item, index) => {
          let b = [];
          b = arr.filter((y) => item.value === y.value);

          if (b.length > 0) {
            newArr[index].isDisabled = true;
          }
          // else {
          //   newArr[index].isDisabled = false;
          // }
        });
        setRequiredDocuments(Object.assign([], newArr));
      }
    }
    // else if()
  };

  // const sendToTenant = async () => {

  //   ;
  //   // setLoading(true);
  //   // const payload = {
  //   //   leaseId: leaseId,
  //   // };
  //   // try {
  //   //   const url = `${leaseService}/send-tenant-payment-schedule`;
  //   //   await axios.post(url, payload);
  //   //   setLoading(false);
  //   //   message.success("Lease Form has been sent to tenant");
  //   //   setTimeout(() => {
  //   //     navigate("/lease");
  //   //   }, 1000);
  //   // } catch (e) {
  //   //   setLoading(false);
  //   //   const { response } = e;
  //   //   message.error(response?.statustext);
  //   // }
  // };
  const onUpdate = () => {
    resetForm();
    LEASEID && dispatch(getLeaseFormDetails(LEASEID));
  };

  const resetForm = () => {
    reset({
      OtherDocs: [],
      othercharges: [],
    });
  };

  //Functions for Booking approval to Ejari/Lease below
  const createEjari = async () => {
    setLoading(true);
    try {
      const res = await CreateEjariContract(LEASEID);
      message.success(res?.message);
      setLoading(false);
      onUpdate();
    } catch ({ response }) {
      message.error(response?.data?.error);
      setLoading(false);
    }
  };

  const downloadEjariContract = async () => {
    // setLoading(true);
    const ejari = leaseFormData?.ejari;
    if (ejari && ejari?.ejariDocuments?.length > 0) {
      const document = ejari?.ejariDocuments.find(
        (e) => e.documentName === "unSignedEjariContract"
      );
      const document1 = ejari?.ejariDocuments.find(
        (e) => e.documentName === "LTV_tenantSignedEjariContract"
      ); //tenantSignedEjariContract
      const document2 = ejari?.ejariDocuments.find(
        (e) => e.documentName === "landlordSignedEjariContract"
      );
      const document3 = ejari?.ejariDocuments.find(
        (e) => e.documentName === "ejariCertificateDocument"
      );

      if (document?.documentId) {
        const url = await getPresignedImage([document?.documentId]);
        setEjariDownloadUrl(url[0]);
      }
      if (document2?.documentId) {
        const url = await getPresignedImage([document2?.documentId]);
        setSignedEjariDownloadUrl(url[0]);
      } else if (document1?.documentId) {
        const url = await getPresignedImage([document1?.documentId]);
        setSignedEjariDownloadUrl(url[0]);
      }
      if (document3?.documentId) {
        const url = await getPresignedImage([document3?.documentId]);
        setEjariCertificateDownloadUrl(url[0]);
      }
    }
    // try {
    //   const res = await DownloadEjariContract(LEASEID);
    //   message.success(res?.message);
    //   setLoading(false);
    //   onUpdate();
    // } catch ({ response }) {
    //   message.error(response?.data?.error);
    //   setLoading(false);
    // }
  };

  useEffect(() => {
    downloadEjariContract();
  }, [leaseFormData?.ejari]);

  useEffect(() => {
    if (signedEjariFile.length > 0 && LEASEID) uploadSignedEjariContract();
  }, [signedEjariFile]);

  const uploadSignedEjariContract = async () => {
    const signedFile = signedEjariFile[0] as RcFile;
    if (signedFile) {
      const payload = { leaseId: LEASEID, file_asset: signedFile };
      try {
        const res = await UploadSignedEjariContract(payload);
        message.success("successfully uploaded Ejari");
        setLoading(false);
        onUpdate();
      } catch ({ response }) {
        message.error(response?.data?.error);
        setLoading(false);
      }
    }
  };

  const activateEjari = async () => {
    setLoading(true);
    try {
      const res = await ActivateEjari(LEASEID);
      message.success(res?.data?.message);
      setLoading(false);
      onUpdate();
    } catch ({ response }) {
      message.error(response?.data?.error);
      setLoading(false);
    }
  };

  const downloadEjariCertificate = async () => {
    setLoading(true);
    try {
      const res = await PrintEjariCertificateContract(LEASEID);
      message.success(res?.message);
      setLoading(false);
      onUpdate();
    } catch ({ response }) {
      message.error(response?.data?.error);
      setLoading(false);
    }
  };

  //Functions for Booking create to approval below
  const sendToTenant = async () => {
    const email = leaseFormData?.tenantDetails?.email;
    if (email && LEASEID) {
      setLoading(true);
      let payload = {
        emails: [email],
        usertype: "tenant",
        inviteAll: false,
      };
      let successMessage = "Invite sent to tenant successfully";
      const status = await sendTenantInvite(payload, successMessage);
      setLoading(false);
      if (status === 200) {
        const res = await updateBookingStatus(LEASEID, {
          status: "tenant_invitation_sent",
        });
        if (res === 200) {
          message.success(successMessage);
          onUpdate();
        }
      }
    } else {
      message.error("No email id present");
    }
  };

  const ApproveTenantInfo = async () => {
    setLoading(true);
    try {
      const res = await updateBookingStatus(LEASEID, {
        status: "tenant_information_approved",
      });
      if (res === 200) {
        message.success("successfully approved tenant information");
        setLoading(false);
        onUpdate();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const RejectTenantInfo = () => {
    setRejectionStatus("tenant_information_incomplete");
    setVisible(true);
  };

  const ApproveDocument = async (data) => {
    setLoading(true);
    try {
      const res = await updateBookingStatus(LEASEID, {
        status: "tenant_documents_approved",
        documentType: data.docName ?? "",
        comments: data.docName,
      });
      if (res === 200) {
        message.success("successfully approved document");
        setLoading(false);
        onUpdate();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const RejectDocument = (data) => {
    setRejectionStatus("tenant_documents_partial_reject");
    data.docName && setRejectionDocument(data.docName);
    setVisible(true);
  };

  const onRejection = () => {
    setVisible(false);
    setRejectionStatus("");
    setRejectionDocument("");
    onUpdate();
  };

  const sendForApproval = async () => {
    setLoading(true);
    const payload = {
      leaseId: LEASEID,
    };
    try {
      const url = `${leaseService}/send-lease-for-approval`;
      await axios.post(url, payload);
      setLoading(false);
      message.success("Lease Form has been sent for approval");
      setTimeout(() => {
        navigate("/lease");
      }, 1000);
    } catch (e) {
      setLoading(false);
      const { response } = e;
      message.error(response?.statustext);
    }
  };

  const acceptForm = async () => {
    setLoading(true);
    const payLoad = {
      leaseId: LEASEID,
    };
    const url = `${leaseService}/approve-lease`;
    try {
      await axios.post(url, payLoad);
      message.success(`Lease Form Approved successfully`);
      setLoading(false);
      setTimeout(() => {
        navigate("/lease");
      }, 1000);
    } catch (e) {
      setLoading(false);
      const { response } = e;
      message.error(response?.data?.result);
    }
  };

  const renewalActions = async (action) => {
    setLoading(true);
    const payLoad = {
      ...action
    };
    const url = `${leaseService}/booking/${LEASEID}`;

    try {
      await axios.patch(url, payLoad);
      message.success(`Updated`);
      setLoading(false);
      setTimeout(() => {
        navigate("/contracts")
      }, 1000);
    } catch (e) {
      setLoading(false);
      const { response } = e;
      message.error(response?.data?.result);
    }
  };

  const approveForm = async () => {
    setLoading(true);
    try {
      const res = await updateBookingStatus(LEASEID, {
        status: "booking_approved",
      });
      if (res === 200) {
        message.success("successfully approved booking");
        setLoading(false);
        onUpdate();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const updatePaymentSchedule = async (val) => {
    setLoading(true);

    const values = getValues();

    const payments = values?.payment?.length > 0 ? values?.payment : [];

    const paymentSchedule = payments.map((item) => {
      return {
        description: item?.type,
        actualAmount: item?.amount,
        vat: item?.vat,
        totalAmount: item?.total,
        modeOfPayment: item?.mode?.value,
        documentNo: item?.docNo,
        bankName: item?.bankName,
        entity: values?.legalEntity,
        dueDate: item?.dueDate,
        chequeDate: item?.chequeDate,
      };
    });

    if (paymentSchedule?.length > 0) {
      const payload = {
        paymentSchedule: paymentSchedule,
        status: "landlord_pdc_updated",
      };
      const url = `${leaseService}/booking/${LEASEID}`;
      axios
        .patch(url, payload)
        .then((response) => {
          let { data } = response;
          setLoading(false);
          message.success(data?.message);
          onUpdate();
        })
        .catch((error) => {
          message.error(error.response.data.error);
          setLoading(false);
        });
    } else {
      message.error("Payment details not filled");
    }

    //STATUS FOR PDC UPDATED
    // landlord_pdc_updated

    // try {
    //   const res = await updateBookingStatus(LEASEID, { status: 'tenant_information_approved' });
    //   if (res === 200) {
    //     message.success("successfully approved tenant information");
    //     setLoading(false);
    //     onUpdate();
    //   }
    // } catch (error) {
    //   setLoading(false);
    // }
  };

  const onSubmit = async (val) => {
    setLoading(true);
    const billingChargesArray = [];
    const existingDocuments =
      leaseFormData?.requiredDocuments?.length > 0
        ? leaseFormData?.requiredDocuments
        : [];
    const documentsArray = [...existingDocuments];
    const billingSchedule = [];
    const parentLeaseId = leaseFormData?.parentleaseId ?? "";

    if (val?.othercharges?.length > 0) {
      val?.othercharges?.map((item, index) => {
        if (
          item?.type?.label !== "EJARI FEE" &&
          item?.type?.label !== "SECURITY DEPOSIT"
        ) {
          const amount: any = item?.amount;
          billingChargesArray.push({
            billingChargesType: item?.type?.value,
            total: parseFloat(amount),
            // type: item?.type?.value,
            // amount: parseFloat(amount),
            numberOfInstallments: item?.intallments?.value,
          });
        }
      });
    }
    if (val?.additionalDocs?.length > 0) {
      val.additionalDocs.map((item) => {
        item?.value &&
          documentsArray.push({ type: item?.value, status: "pending" });
      });
    }

    if (val?.billings?.length > 0) {
      val?.billings.flatMap((bill) =>
        bill.instaArray.map((inst) => {
          billingSchedule.push({
            billingChargesType: bill.billtype,
            yearNumber: 1,
            installments: inst.installmentno,
            installmentStartDate:
              dateByformat(
                dayjs(inst.start_date, "DD-MM-YYYY"),
                "YYYY-MM-DD"
              ) ?? inst.start_date,
            installmentEndDate:
              dateByformat(dayjs(inst.end_date, "DD-MM-YYYY"), "YYYY-MM-DD") ??
              inst.end_date,
            actualAmount: inst.amount,
            vat: inst.vat,
            total: inst.total,
          });
        })
      );
    }

    let postJson = {
      name: val.name,
      arabicName: val.arabicName,
      email: val.email,
      mobile: val.mobile,
      nationality: val.nationality.value,
      maritalStatus: val.maritalStatus.value,
      numberOfOcupants: val.numberOfOcupants,
      poBox: val.poBox,
      unitCode: val.unitCode,
      leaseManager: val?.leaseManager?.value,
      bookingTerms: {
        // annualBaseRent: val.annualBaseRent,
        annualBaseRent: val.rentAmount,
        renewalAmount: val.annualBaseRent,
        changeValue: val.changeValue,
        amountType: val.amountType,
        amountChange: val.amountChange,
        numberOfInstallments: val.noOfInstallments.value,
        nonRefundableAmount: val.nonRefundableAmount,
        bookingStartDate: dateByformat(val.bookingStartDate),
        bookingEndDate: dateByformat(val.bookingEndDate),
        legalEntity: val.legalEntity,
        // "poBox": val.poBox,
        // "moveInDate": dateByformat(val.moveInDate),
        billingCharges: billingChargesArray,
      },
      leaseTerms: {
        ejariProcessing: val.ejariProcessing.value,
        leaseNature: val.leaseNature.value,
        leaseType: "renewal",
        // "moveInDate": dateByformat(val.moveInDate),
        leaseStartDate: dateByformat(val.leaseStartDate),
        leaseEndDate: dateByformat(val.leaseEndDate),
        // "rentFreeStartDate": dateByformat(val.rentFreeStartDate),
        // "rentFreeEndDate": dateByformat(val.rentFreeEndDate),
        noOfCheques: val.noOfInstallments.value,
        contractAmount: val.annualBaseRent,
        securityDeposit: billingChargesArray[0]?.billingChargesType,
        // "noOfPaidParkingSlots": val.noOfPaidParkingSlots,
        // "noOfFreeParkingSlots": val.noOfFreeParkingSlots
      },
      requiredDocuments: documentsArray,
      billingSchedule: billingSchedule,
    };

    // if (LEASEID && parentLeaseId === true) {
    //   const url = `${leaseService}/booking/details/${LEASEID}`;
    //   axios.patch(url, postJson).then((response) => {
    //     let { data } = response;
    //     setLoading(false);
    //     message.success(data?.message);
    //     onUpdate();
    //   }).catch((error) => {
    //     message.error(error.response.data.error)
    //     setLoading(false);;
    //   })
    // } else

    if (LEASEID) {
      const url = `${leaseService}/booking/renewal/${LEASEID}`;
      axios
        .post(url, postJson)
        .then((response) => {
          let { data } = response;

          setLoading(false);
          message.success(data?.message);
          // onUpdate();
          const lease = data?.result?.leaseId;
          setTimeout(
            () =>
              navigate(`/bookings/form/${lease}`, {
                state: { isDisabledAll: false },
              }),
            1000
          );
        })
        .catch((error) => {
          message.error(error.response.data.error);
          setLoading(false);
        });
    }
  };
  const onCancelBook = () => {
    PopupConfirm({
      title: `Are you sure you want to cancel`,
      onOk: () => onCancleBookingForm(LEASEID),
      okText: "Yes",
    });
  };

  const onCancleBookingForm = (leadID) => {
    setLoading(true);
    cancelLeaseForm(leadID)
      .then((response) => {
        if (response?.data?.message === "Success") {
          message.success(response?.data?.result);
          setTimeout(() => navigate("/lease"), 1000);
          setLoading(false);
        }
      })
      .catch((error) => {
        message.error("Previous Lead Status not found !");
        setLoading(false);
      });
  };

  const popup = [
    {
      title: (
        <Title level={3} className="mb-0">
          Reject Reason
        </Title>
      ),
      width: 650,
    },
  ];

  const popupContent = {
    title: popup[0].title,
    closable: true,
    visibility: visible,
    content: (
      <Rejectform
        leaseId={LEASEID}
        rejectionStatus={rejectionStatus}
        rejectionDocument={rejectionDocument}
        onSubmit={onRejection}
        onClose={() => setVisible(false)}
      />
    ),
    width: popup[0].width,
    onCancel: () => setVisible(false),
  };

  // console.log("---------->"getValues("othercharges0?.type"))

  return (
    <DashboardLayout load={loading}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Title level={2} className="mb-0 ag-fontSize32">
                {/* Leasing Form - Residential Leasing */}
                Renewal Form
              </Title>
            </Col>
            <Col span={24}>
              <Radio.Group
                defaultValue="SER"
                buttonStyle="solid"
                value={unitDetail?.usagesType}
                // onChange={(e) => setType(e.target.value)}
              >
                <Space size={12}>
                  {/* <Radio.Button value="NF">Notifications</Radio.Button> */}
                  <Radio.Button value="Residential">Residential</Radio.Button>
                  <Radio.Button value="Commercial">Commercial</Radio.Button>
                </Space>
              </Radio.Group>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Form
            layout="vertical"
            name="bookingform"
            onFinish={handleSubmit(onSubmit)}
            scrollToFirstError
          >
            <Collapse
              defaultActiveKey={["1", "2", "3", "4"]}
              expandIconPosition="right"
              ghost
              className="ag-collapse ag-border-card"
            >
              <Panel header="1. Tenant Details" key="1" forceRender={true}>
                <TenantDetails
                  setValue={setValue}
                  control={control}
                  errors={errors}
                  getValues={getValues}
                  disableAll={disableAll}
                  nationalityList={configData?.nationalityList}
                  detailsApi={leaseFormData?.tenantDetails}
                />
              </Panel>

              <Panel header="2. Unit Details" key="2" forceRender={true}>
                <Row gutter={24}>
                  <UnitDetails
                    disableAll={disableAll}
                    setValue={setValue}
                    control={control}
                    errors={errors}
                    unitDetails={unitDetail}
                  />
                </Row>
              </Panel>
              {/* {disableAll != true && ( */}
              <Panel header="3. Renewal Terms" key="3" forceRender={true}>
                <Row gutter={24}>
                  <RenewalTerms
                    reset={reset}
                    saveBtn={true}
                    errors={errors}
                    isDisable={false}
                    control={control}
                    leaseId={LEASEID}
                    setValue={setValue}
                    billingsApi={leaseFormData?.billingCharges}
                    propertyId={unitDetail?.propertyId}
                    // paid={leaseFormData?.leaseTerms}
                    legalEntity={legalEntity}
                    data={leaseFormData}
                    getValues={getValues}
                    disableAll={disableAll}
                    parking={parkingSlotsDataFromApi}
                  />
                </Row>
              </Panel>
              {/* )} */}
              <Panel
                header="4. Lease Terms"
                forceRender={true}
                key="4"
                // extra={clearData()}
              >
                <LeaseTerms
                  disableAll={disableAll}
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  errors={errors}
                  isRenewal={true}
                  data={leaseFormData?.leaseTerms}
                  leaseType={leaseFormData?.leaseType}
                  parking={parkingSlotsDataFromApi}
                  isClearTriggered={isClearTriggered}
                  details={leaseFormData?.bookingTerms}
                />
              </Panel>
            </Collapse>
            <Row gutter={24}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between">
                  <Col span={24}>
                    <Title level={3} style={{ color: "#000" }} className="mb-0">
                      5. Required Documents:
                    </Title>
                  </Col>
                  <DocumentsList
                    control={control}
                    setValue={setValue}
                    resetField={resetField}
                    leaseData={leaseFormData}
                    popup={popup}
                    isAdd={saveBtn}
                    isRenewal={true}
                    disableClone={disableClone}
                    requiredBookingDocumentsList={requiredDocuments}
                    RejectDocument={RejectDocument}
                    ApproveDocument={ApproveDocument}
                    setFinalRequiredDocuments={setFinalRequiredDocuments}
                    setSendEmptyDoc={setSendEmptyDoc}
                  />
                </Row>
              </Col>
            </Row>
            <Row gutter={[24, 24]} style={{ marginTop: "90px" }}>
              <Col span={24}>
                <Collapse
                  expandIconPosition="right"
                  ghost
                  className="ag-collapse ag-border-card"
                  defaultActiveKey={["6", "7"]}
                >
                  {/* <Panel
                    header="6. Billing Schedule"
                    key="6"
                    forceRender={true}
                  > */}
                  <BillingSchedule
                    control={control}
                    errors={errors}
                    getValues={getValues}
                    setValue={setValue}
                    register={register}
                  />
                  {/* </Panel> */}
                  <Panel
                    header="6. Payment Schedule"
                    className="payment-schedule"
                    key="7"
                    forceRender={true}
                  >
                    <PaymentSchedule
                      disableAll={true}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      data={leaseFormData?.bookingTerms}
                      isCSVUploaded={isCSVUploaded}
                      getValues={getValues}
                      register={register}
                      paymentSchedule={[]} //send empty cuz not needed
                      ejariCharges={leaseFormData?.securityAndEjariFeePayment}
                      // statusForm={leaseFormData?.status}
                    />
                  </Panel>
                </Collapse>
              </Col>
            </Row>

            {/* <Row gutter={[24, 24]} justify="space-between">
              <Col span={24}>
                <Title level={3} style={{color:"#000"}}>8. Additional Documents:</Title>
              </Col>
              <Col span={24}>
                <AdditionalDocument
                  disableAll={disableAll}
                  setValue={setValue}
                  control={control}
                  errors={errors}
                  getValues={getValues}
                  leaseData={leaseFormData}
                  deletedAdditionalDocs={deletedAdditionalDocs}
                  setDeletedAdditionalDocs={setDeletedAdditionalDocs}
                />
              </Col>
            </Row> */}

            {/* <Row gutter={[24, 24]}>
              <Col span={24}>
                <Collapse
                  defaultActiveKey={["9"]}
                  expandIconPosition="right"
                  ghost
                  className="ag-collapse ag-border-card"
                >
                  <Panel
                    header="9. Approval History"
                    key="9"
                    className="approval-history"
                    forceRender={true}
                  >
                    <ApprovalHistory
                      control={control}
                      errors={errors}
                      approvalData={leaseFormData?.leaseStatusHistory}
                    />
                  </Panel>
                </Collapse>
              </Col>
            </Row> */}

            <Row gutter={[24, 24]}>
              <Col span={24}>
                <div className="steps-bookingForm">
                  <Button onClick={() => navigate("/contracts")}>Back</Button>
                  {loginMode === "Lease_Manager" ? (
                    <div className="buttonSteps">
                      {
                        leaseFormData?.renewalStatus === 'sent_for_approval' && (
                          <Button
                            type="primary"
                            onClick={() => renewalActions({"renewalSendForApproval" : "approved"})}
                          >
                            Approve
                          </Button>
                        )
                      }
                      {
                        leaseFormData?.renewalStatus === 'sent_for_approval' && (
                          <Button
                            type="primary"
                            danger
                            onClick={() => renewalActions({"renewalSendForApproval" : "rejected"})}
                          >
                            Reject
                          </Button>
                        )
                      }
                      <Button
                        type="primary"
                        danger
                        disabled={disableApproveReject}
                        onClick={() => setVisible(true)}
                      >
                        Send back to LE
                      </Button>
                      <Button
                        type="primary"
                        className="btn-blue"
                        onClick={() => acceptForm()}
                        disabled={disableApproveReject}
                      >
                        Approve
                      </Button>
                      <Button
                        type="primary"
                        className="btn-blue"
                        // danger
                        disabled={disableCancelButton}
                        onClick={onCancelBook}
                      >
                        Cancel Lease form
                      </Button>
                    </div>
                  ) : (
                    // (
                    // (loginMode === "Lease_Executive" ||
                    //   loginMode === "propertyExecutive")
                    // (disableAll != true)
                    // &&
                    <div className="buttonSteps">
                      {/* {(cancelBtn && LEASEID) && (
                          <Button
                            type="primary"
                            htmlType="button"
                            className="ag-red-button"
                            disabled={disableAll || disabletenant}
                            //  || checkButton?.length > 0}
                            onClick={onCancelBook}
                          >
                            Cancel
                          </Button>
                        )} */}
                      {/* {(saveBtn) && ( */}

                      { leaseFormData?.renewalStatus === 'renewal_accepted' && (
                        <Button
                          type="primary"
                          className="btn-green"
                          onClick={() => renewalActions({renewalToTenant:  'renewal_accepted'})}
                        >
                          Renewal Accepted
                        </Button>
                        )
                      }

                      { leaseFormData?.renewalStatus === 'renewal_declined' && (
                        <Button
                          type="primary"
                          className="btn-green"
                          danger
                          onClick={() => renewalActions({renewalToTenant : 'vacate'})}
                        >
                          Confirm Vacating
                        </Button>
                        )
                      }

                      { leaseFormData?.renewalStatus === 'renewal_declined' && leaseFormData?.managerApproval === "disable" && (
                        <Button
                          type="primary"
                          className="btn-green"
                          onClick={() => renewalActions({"renewalSendForApproval" : "approved"})}
                        >
                          Resend The Renewal Request
                        </Button>
                        )
                      }

                      { leaseFormData?.renewalStatus === 'renewal_declined' && leaseFormData?.managerApproval === "enable" && (
                        <Button
                          type="primary"
                          className="btn-green"
                          onClick={() => renewalActions({"renewalSendForApproval" : "yes"})}
                        >
                          Resend The Renewal Request
                        </Button>
                        )
                      }

                      { leaseFormData?.renewalStatus === 'to_review' && leaseFormData?.managerApproval !== "disable" && (
                        <Button
                          type="primary"
                          className="btn-green"
                          onClick={() => renewalActions({"renewalSendForApproval" : "yes"})}
                        >
                          Send for Approval
                        </Button>
                        )
                      }

                      { 
                        ( 
                          ( leaseFormData?.renewalStatus === 'to_review' && leaseFormData?.managerApproval === "disable" ) || 
                          (leaseFormData?.renewalStatus === 'ready_to_send' && leaseFormData?.managerApproval === "enable")
                        ) && (
                        <Button
                          type="primary"
                          className="btn-green"
                          onClick={() => renewalActions({"renewalSendForApproval" : "approved"})}
                        >
                          Ready to Send
                        </Button>
                        )
                      }

                      { leaseFormData?.leaseStatus === 'active' && (
                        <Button
                          type="primary"
                          className="btn-green"
                          htmlType="submit"
                          // disabled={disableAll}
                        >
                          Create Renewal
                        </Button>
                      )} 
                      {/* )} */}
                      {/* {(updatePdcBtn) && ( //updatePdcBtn
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="btn-green"
                            disabled={disableAll || disabletenant}
                            //  || checkButton?.length > 0}
                            onClick={(e) => { e.preventDefault(); updatePaymentSchedule(e) }}
                          >
                            Update Payment Schedule
                          </Button>
                        )}
                        {(sendBtn) && (
                          <Button
                            type="primary"
                            htmlType="button"
                            className="btn-blue"
                            disabled={disableAll || disabletenant}
                            //  || checkButton?.length > 0}
                            onClick={sendToTenant}
                          >
                            Send to tenant
                          </Button>
                        )}
                        {(reSendBtn) && (
                          <Button
                            type="primary"
                            htmlType="button"
                            className="btn-blue"
                            disabled={disableAll || disabletenant}
                            //  || checkButton?.length > 0}
                            onClick={sendToTenant}
                          >
                            Re-Send to tenant
                          </Button>
                        )}
                         <Button
                          type="primary"
                          className="btn-blue"
                          htmlType="button"
                          disabled={disableAll || disableLeasebtn}
                          onClick={sendForApproval}
                        >
                          Send for approval
                        </Button> 
                        {(approveBtn) && (
                          <Button
                            type="primary"
                            className="btn-blue"
                            htmlType="button"
                            disabled={disableAll || disableLeasebtn}
                            onClick={ApproveTenantInfo}
                          >
                            Approve Tenant Information
                          </Button>
                        )}
                        {(rejectBtn) && (
                          <Button
                            type="primary"
                            className="btn-blue"
                            htmlType="button"
                            disabled={disableAll || disableLeasebtn}
                            onClick={RejectTenantInfo}
                          >
                            Reject Tenant Information
                          </Button>
                        )}
                        {(confirmBtn) && (
                          <Button
                            type="primary"
                            className="btn-blue"
                            onClick={() => approveForm()}
                            disabled={PaymentType.length > 0 ? true : false || disableAll}
                          >
                            Approve Booking
                          </Button>
                        )}

                        {(createEjariBtn) && (
                          <Button
                            type="primary"
                            className="btn-blue"
                            onClick={() => createEjari()}
                            disabled={PaymentType.length > 0 ? true : false || disableAll}
                          >Create Ejari</Button>
                        )}
                        {(downloadEjariBtn && ejariDownloadUrl) && (
                          <Button
                            type="primary"
                            href={ejariDownloadUrl}
                            target="_blank"
                            className="btn-blue"
                            // onClick={() => downloadEjariContract()}
                            disabled={PaymentType.length > 0 ? true : false || disableAll}
                          >Download Ejari Contract</Button>
                        )}
                        {(downloadEjariBtn && signedEjariDownloadUrl) && (
                          <Button
                            type="primary"
                            href={signedEjariDownloadUrl}
                            target="_blank"
                            className="btn-blue"
                            // onClick={() => downloadEjariContract()}
                            disabled={PaymentType.length > 0 ? true : false || disableAll}
                          >Download Signed Ejari Contract</Button>
                        )}
                        {(uploadEjariBtn && signedEjariDownloadUrl) && (
                          <Upload {...fileProps}>
                            <Button
                              icon={<UploadOutlined />}
                              type="primary"
                              className="btn-blue"
                              // onClick={() => uploadSignedEjariContract()}
                              disabled={PaymentType.length > 0 ? true : false || disableAll}
                            >Upload Signed Ejari</Button>
                          </Upload>
                        )}
                        {(activateEjariBtn) && (
                          <Button
                            type="primary"
                            className="btn-blue"
                            onClick={() => activateEjari()}
                            disabled={PaymentType.length > 0 ? true : false || disableAll}
                          >Activate Ejari</Button>
                        )}

                      */}
                    </div>
                  )}

                  {/*
                  
                  {(downloadCertificateBtn && !ejariCertificateDownloadUrl) && (
                    <Button
                      type="primary"
                      className="btn-blue"
                      onClick={() => downloadEjariCertificate()}
                    // disabled={PaymentType.length > 0 ? true : false || disableAll}
                    >Generate Ejari Certificate</Button>
                  )}
                  {(downloadCertificateBtn && ejariCertificateDownloadUrl) && (
                    <Button
                      type="primary"
                      href={ejariCertificateDownloadUrl}
                      target="_blank"
                      className="btn-blue"
                    // disabled={PaymentType.length > 0 ? true : false || disableAll}
                    >Download Ejari Certificate</Button>
                  )} */}
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Popup destroyOnClose={true} {...popupContent} />
    </DashboardLayout>
  );
};
