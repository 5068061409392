import * as React from "react";
const index = (props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.52734 6.27051C3.21094 6.27051 2.96484 6.55176 2.96484 6.83301V15.833C2.96484 16.1494 3.21094 16.3955 3.52734 16.3955H13.5117C13.3008 16.6768 13.1602 16.958 13.0898 17.2744L12.8789 18.083H3.52734C2.26172 18.083 1.27734 17.0986 1.27734 15.833V6.83301C1.27734 5.60254 2.26172 4.58301 3.52734 4.58301H19.2773C20.5078 4.58301 21.5273 5.60254 21.5273 6.83301V9.11816C20.9648 9.18848 20.4727 9.43457 20.0508 9.82129L19.8398 10.0674V6.83301C19.8398 6.55176 19.5586 6.27051 19.2773 6.27051H3.52734ZM5.49609 11.8955H12.8086C13.2656 11.8955 13.6523 12.2822 13.6523 12.7393C13.6523 13.2314 13.2656 13.583 12.8086 13.583H5.49609C5.00391 13.583 4.65234 13.2314 4.65234 12.7393C4.65234 12.2822 5.00391 11.8955 5.49609 11.8955ZM4.65234 9.36426C4.65234 8.90723 5.00391 8.52051 5.49609 8.52051H16.1836C16.6406 8.52051 17.0273 8.90723 17.0273 9.36426C17.0273 9.85645 16.6406 10.208 16.1836 10.208H5.49609C5.00391 10.208 4.65234 9.85645 4.65234 9.36426ZM22.8281 10.6299L23.3555 11.1572C23.8828 11.6846 23.8828 12.5986 23.3555 13.126L22.3008 14.1807L19.8047 11.6846L20.8594 10.6299C21.3867 10.1025 22.3008 10.1025 22.8281 10.6299ZM14.4609 16.9932L19.0312 12.458L21.5273 14.9541L16.957 19.4893C16.8164 19.665 16.6406 19.7354 16.4648 19.8057L14.3203 20.333C14.1445 20.3682 13.9336 20.333 13.793 20.1924C13.6523 20.0518 13.6172 19.8408 13.6523 19.6299L14.1797 17.5205C14.2148 17.3447 14.3203 17.1689 14.4609 16.9932Z"
      fill="#24435D"
    />
  </svg>
);
export default index;
