import React, { useEffect, useState } from "react";
import DashboardLayout from "../../molecules/DashboardLayout";
import {
  Row,
  Col,
  Pagination,
  Card,
  Button,
  Select,
  message,
  Typography,
  Tag,
} from "antd";
import SectionHeader from "../../molecules/SectionHeader";
import Grid from "../../atoms/Grid";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import {
  getBookingRenealForms,
  getBookingPendingReq,
  getLeasePendingReq,
  getPlanPendingReq,
  sendTenantInvite,
  updateBookingStatus,
  getRenewalPendingReq,
} from "./ducks/actions";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../../utils/axiosInceptor";
import { baseContractService } from "../../../configs/constants";
import { getBookingUnitList } from "../Properties/PropertyDetail/ducks/actions";
import { getPropertiesDrop } from "../Properties/ducks/actions";
import { Popup, PopupConfirm } from "../../atoms/Popup";
import { dateByformat } from "../../../utils/dateHelper";
import { currencyFormat, statusLabel } from "../../../utils/media";
import { useSearchHistory } from "../../../utils/filterHelper";
import { setFilterBooking } from "../App/SearchFilters/ducks/actions";
import Title from "antd/es/typography/Title";
import BookingRenewalRequests from "./BookingRenewalRequests/BookingRenewalRequests";
import {
  clearBookingForm,
  emptyCreateBooking,
} from "../BookingForm/ducks/actions";

var _ = require("lodash");
const gridProps = {
  resizable: true,
};

const dropDown = [
  { id: 2, name: "Edit", status: "" },
  { id: 3, name: "Send To Tenant", status: "" },
  { id: 4, name: "Cancel", status: "#FB7171" },
];

const dropDownRenewal = [
  { id: 2, name: "Edit", status: "" },
  { id: 4, name: "Cancel", status: "#FB7171" },
];

const dropDown1 = [{ id: 2, name: "Edit", status: "" }];
const dropDown2 = [{ id: 2, name: "View Confirmed Form", status: "" }];
const dropDown3 = [{ id: 4, name: "View Approved Form", status: "" }];

const limit = 50;

const searchKeys = [
  { value: "name", label: "Name" },
  { value: "unitCode", label: "Unit Number", default: true },
  { value: "property", label: "Property" },
  { value: "propertyAdmin", label: "Property Admin" },

];

/////////////////////////////////////////////////////////

const BookingRenewalListing = (props) => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const { state } = useLocation();
  const listBookingRenewal = useSelector(
    (state: any) => state.bookingRenewals.bookingRenewalList
  );

  const units = useSelector((state: any) => state.propertyDetails.bookingUnits);

  const pendingRenewalRequests = useSelector(
    (state: any) => state.bookingRenewals.pendingRenewalRequests
  );
  // const pendingLeaseRequests = useSelector(
  //   (state: any) => state.booking.leasePendingRequests
  // );
  // const pendingPlanRequests = useSelector(
  //   (state: any) => state.booking.planPendingRequests
  // );
  // console.log("PENDING REW",pendingRenewalRequests);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [updateValue, setUpdateValue] = useState({});

  const [visible, setVisible] = useState({ status: false, index: 0 });

  const filter = useSelector((state: any) => state.filters.bookingsData);

  const [searchBy, setSearchBy] = useState(
    Object.keys(filter)[0] ?? "unitCode"
  );
  const [search, setSearch] = useState(
    filter[Object.keys(filter)[0]] || state?.unitId || ""
  );

  const { searchHistory, addToSearchHistory } =
    useSearchHistory(setFilterBooking);

  useEffect(() => {
    if (selectedProperty?.propertyId)
      dispatch(getBookingUnitList(selectedProperty?.propertyId));
  }, [selectedProperty]);

  useEffect(() => {
    dispatch(clearBookingForm());
    dispatch(getPropertiesDrop());
    dispatch(getRenewalPendingReq());
    // dispatch(getBookingPendingReq());
    // dispatch(getLeasePendingReq());
    // dispatch(getPlanPendingReq());
  }, []);

  function handlePropertyChange(property, label) {
    if (label === "property" && property?.value) {
      setSelectedProperty({
        propertyId: property?.value,
        property_name: property?.label,
      });
      setUpdateValue({ label: "unit", value: null });
      setSelectedUnit({});
    } else if (label === "unit" && property?.value != null) {
      // console.log("UNITTI", units,units[property?.value])
      setSelectedUnit(units[property?.value]);
    }
  }

  const sendToTenant = async (email, leaseId) => {
    if (email && leaseId) {
      // setLoading(true);
      let payload = {
        emails: [email],
        usertype: "tenant",
        inviteAll: false,
        leaseId: leaseId,
      };
      let successMessage = "Invite sent to tenant successfully";
      const status = await sendTenantInvite(payload, successMessage);
      // setLoading(false);
      if (status === 200) {
        const res = await updateBookingStatus(leaseId, {
          status: "tenant_invitation_sent",
        });
        if (res === 200) {
          message.success(successMessage);
          dispatch(getBookingRenealForms(limit, currentPage, "", ""));
        }
      }
    } else {
      message.error("No email id present");
    }
  };

  const BookingForm = () => {
    dispatch(emptyCreateBooking());

    if (selectedUnit?.unitId && selectedProperty)
      navigate("/newbookingform", {
        state: {
          selectedUnit: selectedUnit,
          selectedProperty: selectedProperty,
        },
      });
    else message.error("Please select property and unit");
  };

  const handlePagination = (pg) => {
    setCurrentPage(pg);
    dispatch(getBookingRenealForms(limit, pg, search, searchBy));
  };

  function onTopSearch(e) {
    if (e?.length === 0) setSearch("");
    else if (e?.length < 2)
      message.error("Please enter atleast two characters");
    else {
      setSearch(e);
    }
  }

  function onTopSearchBy(e) {
    setSearchBy(e?.value);
  }

  useEffect(() => {
    setCurrentPage(1);
    addToSearchHistory(searchBy, search); //adding search history and filter store
    setTimeout(() => {
      dispatch(getBookingRenealForms(limit, 1, search, searchBy));
    }, 500);
    // }
  }, [search, searchBy]);

  useEffect(() => {
    let key = Object.keys(searchHistory)[0];
    if (searchKeys.length > 0)
      searchKeys.map((item) => {
        if (key == item.value) item["default"] = true;
        else item["default"] = false;
        return item;
      });
  }, [searchHistory]);

  const popup = [
    {
      title: (
        <Title level={3} className=" mb-0">
          Renewal Booking Request(s)
        </Title>
      ),
      content: (
        <BookingRenewalRequests
          showAll={true}
          getAllBookRequests={pendingRenewalRequests}
          getAllLeaseRequests={{ data: [] }}
          getAllPlanRequests={{ data: [] }}
        />
      ),
      width: 1650,
    },
  ];

  const popupProps = {
    closable: true,
    visibility: visible.status,
    title: popup[visible.index].title,
    content: popup[visible.index].content,
    width: popup[visible.index].width,
    onCancel: () => {
      setVisible({ status: false, index: 0 });
    },
  };

  const MoreAction = (row) => {
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const { Text } = Typography;
    const [selected, setSelected] = useState([]);
    const onChnageHanldler = async (e) => {
      if (e === "Cancel") {
        onStatus(row.data.leaseId);
      } else if (e === "Add Unit") {
        // dispatch(visiblePopup());
        // dispatch(getLeadID(row.data.id));
      } else if (e === "Download booking form") {
      } else if (e === "Send To Tenant") {
        if (row.data?.tenantDetails?.email && row.data?.leaseId)
          sendToTenant(row.data.tenantDetails.email, row.data.leaseId);
      } else if (e === "View Confirmed Form") {
        navigate(`/viewbookingform/${row.data.leaseId}`);
      } else if (e === "View Approved Form") {
        navigate(`/viewbookingform/${row.data.leaseId}`);
      } else if (e === "Edit") {
        if (row?.data?.leaseStatus === "draft")
          navigate(`/newbookingform/${row.data.leaseId}`);
        else navigate(`/viewbookingform/${row.data.leaseId}`);
      } else if (e === "Send for tenant's signature") {
        try {
          let body = {
            leadId: row.data.id,
          };
          await axios.post(
            `${baseContractService}/booking-form-send-to-prospect`,
            body
          );
          message.success("Successfully Sent");
        } catch (e) {
          const { response } = e;
          message.error(response?.data?.message);
        }
      }
    };
    const onStatus = (leaseId) => {
      PopupConfirm({
        title: `Are you sure you want to cancel`,
        onOk: () => onCancleBookingForm(leaseId),
        okText: "Yes",
      });
    };
    const onCancleBookingForm = async (leaseId) => {
      // console.log("cancel:",leaseId)
      const res = await updateBookingStatus(leaseId, {
        status: "booking_reject",
      });
      if (res === 200) {
        message.success("Booking has been cancelled");
        dispatch(getBookingRenealForms(limit, currentPage, "", ""));
      }
    };

    return (
      <Select
        onChange={onChnageHanldler}
        className="Sentence"
        placeholder="Select an action"
        style={{ width: 150 }}
        allowClear
      >
        <>
          {row.data.leaseStatus === "booking_created" &&
            (row.data.leaseType == "renewal"
              ? dropDownRenewal.map((value, key) => (
                  <React.Fragment key={key}>
                    <Select.Option value={value.name}>
                      <Text style={{ color: `${value.status}` }}>
                        {value.name}
                      </Text>
                    </Select.Option>
                  </React.Fragment>
                ))
              : dropDown.map((value, key) => (
                  <React.Fragment key={key}>
                    <Select.Option value={value.name}>
                      <Text style={{ color: `${value.status}` }}>
                        {value.name}
                      </Text>
                    </Select.Option>
                  </React.Fragment>
                )))}
          {row.data.leaseStatus !== "booking_created" &&
            row.data.leaseStatus !== "booking_confirmed" &&
            row.data.leaseStatus !== "booking_approved" &&
            dropDown1.map((value, key) => (
              <React.Fragment key={key}>
                <Select.Option value={value.name}>
                  <Text style={{ color: `${value.status}` }}>{value.name}</Text>
                </Select.Option>
              </React.Fragment>
            ))}
          {row.data.leaseStatus === "booking_confirmed" &&
            dropDown2.map((value, key) => (
              <React.Fragment key={key}>
                <Select.Option value={value.name}>
                  <Text style={{ color: `${value.status}` }}>{value.name}</Text>
                </Select.Option>
              </React.Fragment>
            ))}
          {row.data.leaseStatus === "booking_approved" &&
            dropDown3.map((value, key) => (
              <React.Fragment key={key}>
                <Select.Option value={value.name}>
                  <Text style={{ color: `${value.status}` }}>{value.name}</Text>
                </Select.Option>
              </React.Fragment>
            ))}
        </>
      </Select>
    );
  };

  const leadCol = [
    {
      headerName: "Status",
      field: "leaseStatus",
      minWidth: 220,
      cellRendererFramework: ({ data }) => (
        <Tag className="tag-sm" color="warning">
          {/* //icon={<ExclamationCircleOutlined />} */}
          {statusLabel(data.leaseStatus,data?.leaseStatusHistory)}
        </Tag>
      ),
    },
    {
      headerName: "Property",
      field: "propertyDetails.propertyName",
    },
    {
      headerName: "Unit Number",
      field: "propertyDetails.unitCode",
      // minWidth: 320,
    },
    {
      headerName: "Tenant",
      field: "tenantDetails.tenantNameEN",
    },
    {
      headerName: "Property Admin",
      field: "adminManager.name",
    },
    {
      headerName: "Annual Rent",
      field: "bookingTerms.annualBaseRent",
      minWidth: 220,
      align: "right",
      cellRendererFramework: ({ data }) => {
        return data.bookingTerms?.annualBaseRent ? (
          <div className="annualRent">
            {currencyFormat(
              data.bookingTerms?.renewalAmount ||
                data.bookingTerms?.annualBaseRent
            )}
          </div>
        ) : (
          ""
        );
      },
    },
    {
      headerName: "Payments",
      field: "bookingTerms.numberOfInstallments",
    },
    {
      headerName: "Start Date",
      field: "leaseTerms.leaseStartDate",
      // minWidth: 150,
      cellRendererFramework: ({ data }) => {
        return dateByformat(data?.leaseTerms?.leaseStartDate, "DD-MM-YYYY");
      },
    },
    {
      headerName: "End Date",
      field: "leaseTerms.leaseEndDate",
      // minWidth: 150,
      cellRendererFramework: ({ data }) => {
        return dateByformat(data?.leaseTerms?.leaseEndDate, "DD-MM-YYYY");
      },
    },
    {
      headerName: "Contract Type",
      field: "leaseType",
      cellRendererFramework: ({ data }) => {
        return statusLabel(data?.leaseType);
      },
    },
    {
      headerName: "AWB No",
      field: "shipmentNumber",
    },
    {
      headerName: "Actions",
      field: "manage",
      maxWidth: 180,
      cellRendererFramework: (row) => MoreAction(row),
      pinned:'right'
    },
  ];

  return (
    <DashboardLayout>
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <SectionHeader
                heading="Renewals in Progress"
                total={listBookingRenewal?.totalCount || 0}
                // viewOption={propertyOptions} //REMOVED THIS PART AS PER: 8076
                // viewOptionIcon={<FilterOutlined />}

                headTopLeftText={
                  pendingRenewalRequests?.totalCount ? (
                    <Button
                      danger
                      type="text"
                      onClick={() => setVisible({ status: true, index: 0 })}
                    >
                      <InfoCircleOutlined />{" "}
                      {pendingRenewalRequests?.totalCount} pending requests
                    </Button>
                  ) : null
                }
                // viewOptionLabel="Select a unit to create booking"
                search={(e) => onTopSearch(e)}
                searchBy={(e) => onTopSearchBy(e)}
                defaultSearch={search}
                searchOptions={searchKeys}
                justifiedItems={true}
                justifyEnd={true}
                handleView={handlePropertyChange}
                updateValue={updateValue}
                viewOptionAction={BookingForm}
              />
            </Col>
            <Col span={24}>
              <Pagination
                className="ag-property-text-pagination"
                total={listBookingRenewal?.totalCount || 0}
                showTotal={(total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} bookings`
                }
                defaultPageSize={50}
                defaultCurrent={1}
                showSizeChanger={false}
                current={currentPage}
                onChange={(e) => handlePagination(e)}
              />
            </Col>
            <Col span={24}>
              <Row gutter={[24, 24]} justify="end">
                <Col span={24}>
                  <Card bordered={false} className="ag-nospace-body">
                    <Grid
                      refCol="status"
                      data={listBookingRenewal?.data ?? []}
                      columns={leadCol}
                      autoSizeColumns={true}
                      pagination={false}
                      defaultSettings={gridProps}
                      noRowlabel="Bookings"
                      fullPage={true}
                    />
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Popup destroyOnClose={true} {...popupProps} />
    </DashboardLayout>
  );
};

export default BookingRenewalListing;
