import React from "react";

// others
import { Row, Space } from "antd";
import Button from "../../../molecules/Button";
import { Up, Add, Edit, Down, Change } from "../../../../assets/svg";
import { useSelector } from "react-redux";
import { disablePaymentSchedule } from "./utils";

interface IProps {
  iKey: string;
  disable?: boolean;
  isActive: boolean;
  renewal?: boolean;
  handleEditPress: (key: string) => void;
}

const index = (props: IProps) => {
  // redux states
  let { bookingDetails } = useSelector((state: any) => state.newBooking);
  const changePlan = disablePaymentSchedule(bookingDetails,true);
  const disableStyle = {
    opacity: !props.disable ? 1 : 0.5,
    cursor: !props.disable ? "inherit" : "not-allowed",
  };
  const disableChangePlanStyle = {
    opacity: !changePlan ? 1 : 0.5,
    cursor: !changePlan ? "inherit" : "not-allowed",
  };

  return (
    <Row style={{ marginTop: "12px" }}>
      <Space size="large">
        {props.iKey === "3" && (
          <>
            <Button
              label="Add Payment"
              style={disableStyle}
              disabled={props.disable}
              className="change-button"
              onClick={(event) => {
                event.stopPropagation()
                props.handleEditPress("5")
              }
              }
              icon={
                <Add
                  width="18px"
                  height="18px"
                  style={{ marginRight: "8px" }}
                />
              }
            />

            {!props.renewal && (
              <Button
                style={disableChangePlanStyle}
                disabled={changePlan}
                className="change-button"
                label="Change Payment Plan"
                onClick={(event) => {
                  event.stopPropagation()
                  props.handleEditPress("8")
                }
                }
                icon={
                  <Change
                    width="18px"
                    height="18px"
                    style={{ marginRight: "8px" }}
                  />
                }
              />
            )}
          </>
        )}

        {props.handleEditPress && (
          <div
            style={disableStyle}
            className="edit-icon"
            onClick={(event) => {
              event.stopPropagation()
              !props.disable && props.handleEditPress(props.iKey)
            }
            }
          >
            <Edit />
          </div>
        )}

        {!props.isActive ? <Up /> : <Down />}
      </Space>
    </Row>
  );
};

export default index;
