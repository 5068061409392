import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Typography, Select, DatePicker } from "antd";
import {
  InputField,
  ReactSelectField,
  DateField,
} from "../../../atoms/FormElement";
import dayjs from "dayjs";

import { useFieldArray, useWatch } from "react-hook-form";
import Item from "antd/lib/list/Item";
import { paymentLabel } from "../../../../utils/media";
const { Text, Title } = Typography;

export default (props) => {
  const {
    paymentModes,
    control,
    errors,
    setValue,
    data,
    index,
    parkingTotalAmount,
    parkingTotalAmountVat,
    register,
    getValues,
    disableAll,
    building,
    displayOnly,
    statusForm,
    depositsPaid
  } = props;

  const [disable, setDisable] = useState([]);

  const setMode = (paymentMode, index) => {
    if (paymentMode === "cash") {
      setDisable((prevState) => [...prevState, index]);
    } else {
      setDisable((prevState) => prevState.filter((e) => e !== index));
    }
  };

  // useEffect(() => {
  //   console.log("New disable state:", disable);
  // }, [disable]);

  const { fields, append } = useFieldArray({
    control,
    name: "payment",
  });

  const [a, setA] = useState([]);
  const [modifyFormData, setModifyFormData] = useState([]);
  const [payAmount, setPayAmount] = useState([]);
  const [payVat, setPayVat] = useState([]);

  useEffect(() => {
    setModifyFormData([]);
    setA(data);
    setPayAmount(parkingTotalAmount);
    setPayVat(parkingTotalAmountVat);
    // console.log("DataDit", parkingTotalAmount, parkingTotalAmountVat);

    return () => {
      setPayAmount([]);
      setPayVat([]);
    };
  }, [data]);

  // item.type.value === "rent_installment" || //rent_installment
  // item.type.value == "security_deposit" ||
  // item.type.value == "rental_commission" ||
  // item.type.value == "ejari_fee"
  // console.log("PAYCHARGE>>", disable);

  useEffect(() => {
    const modifyData = [];

    // console.log("PAYDATA11:", a);

    if (modifyFormData.length === 0) {
      a.map((item, index) => {
        let actualAmount = item.amount;
        let vat = item.vat;
        let total = item.total;

        if (item.type === "rent_installment") {
          // console.log("item.vat,", item.vat,)
          if (payAmount?.[index]) {
            actualAmount = payAmount[index] + item.amount;
            vat = item.vat + payVat[index];
            //vat = item.vat;
            total = actualAmount + vat;
          } else {
            actualAmount = item.amount;
            vat = item.vat;
            //vat = item.vat;
            total = actualAmount + vat;
          }
        }

        item.vat = +vat?.toFixed(2);
        item.amount = +actualAmount?.toFixed(2);
        item.total = +total?.toFixed(2);

        if (item?.chequeStatus && item?.chequeStatus === "paid") {
          item["isPaid"] = true;
        }
        if (item.type === "security_deposit" && depositsPaid) {
          item["isPaid"] = true;
        }

        

        // console.log("item:::12",item);

        modifyData.push(item);
      });

      const totalAmountFixes = [];
      modifyData.map((_item, index) => {
        if (
          _item.type === "rent_installment" &&
          _item?.total % 1 !== 0 &&
          index !== 0
        ) {
          totalAmountFixes.push(_item?.total % 1);
        }
      });

      const totalPointsValues = totalAmountFixes.reduce((a, b) => a + b, 0);
      let i = 0;
      modifyData.map((_item, index) => {
        // if (_item.type === "rent_installment" && index == 0) {
        //   const innerTotal = _item.total + totalPointsValues;
        //   _item.total = innerTotal.toFixed(2);
        // } else
        if (_item.type === "rent_installment") {
          _item.total = _item?.total?.toFixed(2); //Math.floor(_item.total)?.toFixed(2);
          _item.amount = _item?.amount?.toFixed(2);
          _item.label = "Rent Instalment " + (i + 1);
          i++;
        } else {
          _item.total = _item?.total?.toFixed(2);
          _item.amount = _item?.amount?.toFixed(2);
          _item.label = paymentLabel(_item.type);
        }

        return _item;
      });

      modifyData.map((_item, index) => {
        if (_item?.mode?.value) setMode(_item.mode.value, index);
      });

      // console.log("LOG2", modifyData);

      register("modifyData");
      setValue("modifyData", modifyData);
      // console.log("modifyData", modifyData);
      setModifyFormData(modifyData);
    }
  }, [a]);

  const disableChequeDate = (current, index) => {
    const dueDate = getValues(`payment.${index}.dueDate`);
    let checkStart = dayjs(dueDate, "DD-MM-YYYY").subtract(14, "day");
    return (
      (current && current.valueOf() < checkStart.valueOf()) ||
      (current && current.valueOf() > dayjs(dueDate, "DD-MM-YYYY").valueOf())
    );
  };

  // Calculate total based on amount and vat
  const calculateTotal = (index) => {
    const amount = parseFloat(getValues(`payment[${index}].amount`)) || 0;
    const vat = parseFloat(getValues(`payment[${index}].vat`)) || 0;
    const total = amount + vat;

    setValue(`payment[${index}].total`, total.toFixed(2));
  };

  return (
    <>
      {modifyFormData?.map((item, index) => (
        <Fragment key={index}>
          {/* <Col span={1}>
              <InputFieldInputField
                label=""
                fieldname={`payment[${index}].instno`}
                setValue={setValue}
                valueGot={item.instno}
                control={control}
                initValue=""
                iProps={{
                  readOnly: true,
                  disabled: props.disableAll
                    ? true
                    : item.type == "ejari_fee" ||
                      item.type == "security_deposit"
                    ? true
                    : false,
                }}
              />
            </Col> */}
          {/* HIDDEN INPUT FIELDS FOR PAYLOAD */}
          <InputField
            fieldname={`payment[${index}]`}
            setValue={setValue}
            valueGot={item ?? "empty"}
            control={control}
            initValue={item}
            iProps={{ readOnly: true, type: "hidden" }}
          />
          <InputField
            fieldname={`payment[${index}].type`}
            setValue={setValue}
            valueGot={item?.type ?? "empty"}
            control={control}
            initValue={item?.type}
            iProps={{ readOnly: true, type: "hidden" }}
          />
          <InputField
            fieldname={`payment[${index}].chequeStatus`}
            setValue={setValue}
            valueGot={item?.chequeStatus ?? "empty"}
            control={control}
            initValue={item?.chequeStatus}
            iProps={{ readOnly: true, type: "hidden" }}
          />
          <InputField
            fieldname={`payment[${index}].creditCardRequest`}
            setValue={setValue}
            valueGot={item?.creditCardRequest ?? "empty"}
            control={control}
            initValue={item?.creditCardRequest}
            iProps={{ readOnly: true, type: "hidden" }}
          />
          <InputField
            fieldname={`payment[${index}].paymentStatus`}
            setValue={setValue}
            valueGot={item?.paymentStatus ?? "empty"}
            control={control}
            initValue={item?.paymentStatus}
            iProps={{ readOnly: true, type: "hidden" }}
          />
          <Col span={4}>
            <InputField
              label=""
              fieldname={`payment[${index}].label`}
              setValue={setValue}
              valueGot={item?.label ?? "empty"}
              control={control}
              initValue={item?.label}
              disabled={
                item?.isPaid === true || disableAll //displayOnly &&
                  ? true
                  : false
              }
              iProps={{ readOnly: true }}
            />
          </Col>
          <Col span={3}>
            <InputField
              label=""
              fieldname={`payment[${index}].amount`}
              setValue={setValue}
              valueGot={item.amount}
              control={control}
              initValue={item.amount ?? ""}
              onChange={(e) => {
                calculateTotal(index);
              }}
              iProps={{
                // readOnly: true,
                type: "number",
              }}
              disabled={item?.isPaid === true || disableAll ? true : false}
            />
          </Col>
          <Col span={2}>
            <InputField
              label=""
              fieldname={`payment[${index}].vat`}
              setValue={setValue}
              valueGot={item.vat}
              control={control}
              initValue={item.vat ?? ""}
              onChange={(e) => {
                calculateTotal(index);
              }}
              iProps={{
                // readOnly: true,
                type: "number",
              }}
              disabled={item?.isPaid === true || disableAll ? true : false}
            />
          </Col>
          <Col span={3}>
            <InputField
              label=""
              fieldname={`payment[${index}].total`}
              setValue={setValue}
              valueGot={item.total}
              control={control}
              initValue={item.total ?? 0}
              iProps={{
                type: "number",
                readOnly: true,
              }}
              disabled={item?.isPaid === true || disableAll ? true : false}
            />
          </Col>

          <Col span={2}>
            <ReactSelectField
              label=""
              fieldname={`payment[${index}].mode`}
              control={control}
              initValue={item.mode?.value && item.mode}
              setValue={setValue}
              valueGot={item.mode?.value && item.mode}
              selectOption={paymentModes?.map((item, index) => {
                return { label: item?.name, value: item?.id };
              })}
              iProps={{
                isDisabled: item?.isPaid === true || disableAll ? true : false,
              }}
              setMode={(paymentMode) => setMode(paymentMode, index)}
              register={register}
              resetField={props?.resetField}
              isRequired={true}
              validate={errors?.payment?.[index]?.mode && "error"}
              validMessage={
                errors?.payment?.[index]?.mode &&
                errors?.payment?.[index]?.mode.message
              }
              rules={{ required: "Please select one" }}
            />
          </Col>
          <Col span={3}>
            <InputField
              label=""
              fieldname={`payment[${index}].docNo`}
              // setValue={setValue}
              control={control}
              initValue={item?.docNo ?? ""}
              setValue={setValue}
              register={register}
              resetField={props?.resetField}
              valueGot={!!item.docNo ? item.docNo : "empty"}
              disabled={
                disable.includes(index) || item?.isPaid === true || disableAll
                  ? true
                  : false
              }
            // isRequired={true}
            // validate={errors?.payment?.[index]?.docNo && "error"}
            // validMessage={
            //   errors?.payment?.[index]?.docNo && errors?.payment?.[index]?.docNo.message
            // }
            // rules={{ required: "Please enter document no." }}
            />
          </Col>
          <Col span={3}>
            <InputField
              label=""
              fieldname={`payment[${index}].bankName`}
              control={control}
              initValue={item?.bankName ?? ""}
              setValue={setValue}
              valueGot={!!item.bankName ? item.bankName : "empty"}
              register={register}
              resetField={props?.resetField}
              disabled={
                disable.includes(index) || item?.isPaid === true || disableAll
                  ? true
                  : false
              }
            // isRequired={true}
            // validate={errors?.payment?.[index]?.bankName && "error"}
            // validMessage={
            //   errors?.payment?.[index]?.bankName && errors?.payment?.[index]?.bankName.message
            // }
            // rules={{ required: "Please enter bank name" }}
            />
          </Col>
          {/* <Col span={2}>
              <InputField
                label=""
                fieldname={`payment[${index}].entity`}
                // setValue={setValue}
                control={control}
                initValue={props?.building?.termsAndConditionsEntity}
                // setValue={setValue}
                // valueGot={!!item.docNo ? item.docNo : "empty"}
                iProps={{ disabled: true }}
              />
            </Col> */}
          <Col span={2}>
            <DateField
              label=""
              fieldname={`payment[${index}].dueDate`}
              setValue={setValue}
              control={control}
              initValue={item?.dueDate ?? ""}
              iProps={{
                placeholder: "DD-MM-YYYY",
                format: "DD-MM-YYYY",
                // readOnly: true,
                disabled: item?.isPaid === true || disableAll ? true : false,
              }}
              valueGot={
                !!item.dueDate
                  ? item.dueDate
                  : dayjs(item.dueDate, "DD-MM-YYYY")
              }
            />
          </Col>
          <Col span={2}>
            <DateField
              label=""
              fieldname={`payment[${index}].chequeDate`}
              control={control}
              iProps={{
                placeholder: "DD-MM-YYYY",
                format: "DD-MM-YYYY",
                disabledDate: (current) => disableChequeDate(current, index),
                disabled: item?.isPaid === true || disableAll ? true : false,
              }}
              initValue={item.chequeDate}
              setValue={setValue}
              valueGot={
                !!item.chequeDate
                  ? item.chequeDate
                  : dayjs(item.dueDate, "DD-MM-YYYY")
              }
            />
          </Col>
        </Fragment>
      ))}
    </>
  );
};
