import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Tabs,
  Input,
  Typography,
  Button,
  message,
  Tag,
  Select,
  Pagination,
  Table,
  Space,
  Switch,
  Spin
} from "antd";
import { MailOutlined, SearchOutlined, DeleteOutlined } from "@ant-design/icons";

import Grid from "../../../atoms/Grid/";
import { Popup } from "../../../atoms/Popup";
import SectionHeader from "../../../molecules/SectionHeader";
import { maintenanceService } from "../../../../configs/constants";
import axios from "../../../../utils/axiosInceptor";
import { useSelector, useDispatch } from "react-redux";

import AddShift from "./components/AddShift";
import Warning from "./components/Warning"

import { getAllShifts, assignDefault, deleteShifts } from "./ducks/actions";
import DashboardLayout from "../../../molecules/DashboardLayout";
import AddOperatingHours from "./components/WeekeDays/AddOperatingHours";

var _ = require("lodash");
const { Title } = Typography;
const { Option } = Select;

const limit = 50;

export default () => {
  const dispatch: any = useDispatch();
  const shiftsApi = useSelector((state: any) => state.shifts.shiftData);
  const [visible, setVisible] = useState(false);
  const [tid, setTID] = useState(null);
  const [trigger, setTrigger] = useState(0);
  const [searchText, setSearchText] = useState();
  const [page, setPage] = useState(1);
  const [shiftData, setshiftData] = useState(null);
  const [special, setSpecial] = useState([]);
  const [shiftsAssigned, setshiftsAssigned] = useState([]);
  const [loader, setLoader] = useState(false);
  const [warnVisible, setWarnVisible] = useState(false);
  const [assignedObj, setAssignedObj] = useState(null);
  const [loading, setLoading] = useState(false);

  const assignTechCol = [
    {
      title: "Default",
      dataIndex: "default",
      key: "default",
      // width: 220,
      render: (status, record) => {
        
        return (
          <Space size={4}>
            <div className="Simple-tooltip" style={{fontSize: 20, paddingTop: 3}}>
              <Switch
                className="switch"
                size='small'
                onChange={(e) => {
                    console.log(e, record);
                    if(e) {
                      setAssignedObj(record.item)
                      setWarnVisible(true);
                    }
                  }
                }
                checked={record.default}
              />
            </div>
          </Space>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      // width: 220,
    },
    {
      title: "From",
      dataIndex: "from",
      key: "from",
      // width: 220,
    },
    {
      title: "To",
      dataIndex: "to",
      key: "to",
      // width: 220,
    },
    {
      title: "Break Duration",
      dataIndex: "breakDuration",
      key: "breakDuration",
      // width: 220,
      render: (text, data ) => { 
        return data.breakDuration.includes('NaN') ? '' : data.breakDuration
      }
    },
    {
      title: "Break Start Time",
      dataIndex: "break",
      key: "break",
      // width: 220,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      // width: 220,
      render: (text, record) => {
        return (
          <Space size={16}>

            <Button
              type="link"
              onClick={() => {
                deleteShift(record.item)
              }}
              className="p-0 ag-success"
              disabled={record.default}
            >
              <div className="Simple-tooltip">
                <DeleteOutlined />
                <section className="tt-content">{record.default ? 'Cannot delete the default Shift' : 'Delete Shift'}</section>
              </div>
            </Button>

          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getAllShifts(1, limit, ""));
  }, []);

  function formatTime(timeObject) {
    const { hours, minutes } = timeObject;
  
    // Ensure that hours, minutes, and seconds are two digits
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
  
    // Create the formatted time string
    const formattedTime = `${formattedHours}:${formattedMinutes}`;
  
    return formattedTime;
  }

  function timeDifference(startTime, endTime) {
    // Parse the input strings as Date objects
    const startDate = new Date(`1970-01-01T${startTime}`).getTime();
    const endDate = new Date(`1970-01-01T${endTime}`).getTime();

    // Calculate the time difference in milliseconds
    const timeDiff = endDate - startDate;

    // Convert the time difference to hours, minutes, and seconds
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

    // Return the time difference as an object
    
    return formatTime({
      hours: hours,
      minutes: minutes
  })
}

  useEffect(() => {
    
    shiftsApi &&
    shiftsApi?.length &&
    setshiftsAssigned(
      shiftsApi.map((item) => ({
        name: item?.name,
        description: item?.description,
        from: item?.shifts[0]?.from,
        break: item?.shifts?.length > 1 ? item?.shifts[0]?.to : null,
        breakDuration: `${timeDifference(item?.shifts[0]?.to, item?.shifts[1]?.from)} `,
        to: item?.shifts[1]?.to || item?.shifts[0]?.to,
        profileId: item?.profileId,
        default: item?.default,
        item: item
      }))
    );
  }, [shiftsApi]);

  const onSubmit = () => {
    dispatch(getAllShifts(1, limit, ""));
    setPage(1);
    setVisible(false);
    setTID(null);
    setSpecial([]);
  };

  const onPageChange = (e) => {
    setPage(e);
    dispatch(getAllShifts(e, limit, searchText));
  };

  
  const apiCallFunction = async () => {

    const shiftId = assignedObj._id
    const payload = {
      "schedules": [
        {
          "shift": shiftId,
          "day": 0
        },
        {
          "shift": shiftId,
          "day": 1
        },
        {
          "shift": shiftId,
          "day": 2
        },
        {
          "shift": shiftId,
          "day": 3
        },
        {
          "shift": shiftId,
          "day": 4
        },
        {
          "shift": shiftId,
          "day": 5
        },
        {
          "shift": shiftId,
          "day": 6
        },

      ]
    }
    try {
      setLoading(true)
      const assignedResponse =  await assignDefault(payload)
      location.reload()
      // setLoading(false)
      // dispatch(getAllShifts(1, limit, ""));
      message.success(assignedResponse);
    } catch (error) {
      setLoading(false)
    }
    
  }

  const popupWarn =
  {
    title: (
      <Title level={3} className="mb-0">
        Warning
      </Title>
    ),
    content: (
      <Warning
        title={`This shift is associated with one or more technicians. Changes will amend the roster assignment for these technicians. Would you like to continue?`}
        onCancel={() => {
          setWarnVisible(false);
        }}
        onConfirm={() => {
          apiCallFunction()
          setWarnVisible(false);
        }}
      />
    ),
    width: 1050,
  };

  const popupWarnProps = {
    closable: true,
    visibility: warnVisible,
    title: popupWarn.title,
    content: popupWarn.content,
    width: popupWarn.width,
    onCancel: () => {
      setWarnVisible(false)
    }
  };

  const popup = [
    {
      title: (
        <Title level={3} className=" mb-0">
          Add New Shift
        </Title>
      ),
      content: (
        <AddShift
          // onAddTech={onAddTech}
          // onAddShift={onAddShift}
          data={shiftData}
          onCancel={() => setVisible(false)}
          onSubmit={onSubmit}
        />
      ),
      width: 1250,
    },
  ];

  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => setVisible(false),
  };

  const AddNewShift = () => {
    setshiftData({});
    setVisible(true);
    setTrigger(0);
  };

  const deleteShift = async (item) => {
    setLoading(true)
    try {
      const res = await deleteShifts(item)
      dispatch(getAllShifts(1, limit, ""));
      setLoading(false)
    } catch (error) {
      setLoading(false)
      message.error(error.error);
    }
  };

  useEffect(() => {
    if(shiftData) {
      setVisible(true);
    }
  }, [shiftData])

  return (
    <>
      <DashboardLayout load={loading}>
      {loader && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
              {/* <Paragraph className="ag-default fontWeight600 mb-0">Loading</Paragraph> */}
            </Space>
          </Col>
        </Row>
      )}

        {/* <Row gutter={24}>
          <Col span={24}>
            <SectionHeader
              heading="Days"
            />
            <Row gutter={[24, 24]}>
            <AddOperatingHours editMode={false} onCancel={() => setVisible(false)} onSubmit={onSubmit}  />
              <Col span={24}>
                <Row gutter={[20, 20]} justify="end">
                  <AddOperatingHours editMode={false} onCancel={() => setVisible(false)} onSubmit={onSubmit}  />
                </Row>
              </Col>
            </Row>
          </Col>
        </Row> */}

        <Row gutter={24}>
          <Col span={24}>
            <SectionHeader
              heading="Days"
            />
            <Row>
              <Col span={24}>
                {/* <Row gutter={[20, 20]} justify="end"> */}
                  <AddOperatingHours editMode={false} onCancel={() => setVisible(false)} onSubmit={onSubmit}  />
                {/* </Row> */}
              </Col>
            </Row>
          </Col>
          
          <Col span={24}>
            <SectionHeader
              heading="Shifts"
              isDisabled={!(shiftsAssigned?.length > 0)}
              addNewAction={AddNewShift}
            />
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row gutter={[20, 20]} justify="end">
                  <Col span={24}>
                    {/* <Pagination
                      style={{ justifyContent: "flex-end" }}
                      className="ag-property-text-pagination"
                      total={shiftsApi?.totalCount}
                      defaultPageSize={shiftsApi?.perPage}
                      current={page}
                      showTotal={(total, range) =>
                        `Showing ${range[0]}-${range[1]} of ${total} shifts`
                      }
                      onChange={(e) => onPageChange(e)}
                      showSizeChanger={false}
                    /> */}
                  </Col>
                  <Col span={24}>
                    <Table
                      columns={assignTechCol}
                      scroll={{ y: 500 }}
                      // rowKey={(record, index) => index}
                      dataSource={shiftsAssigned}
                      // rowSelection={rowSelection}
                      className="custom-table org_table_wrap"
                      pagination={false}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </DashboardLayout>
      <Popup destroyOnClose={true} {...popupProps} />
      <Popup destroyOnClose={true} {...popupWarnProps} />
    </>
  );
};
