import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Typography, Button } from "antd";
import { useFieldArray, useWatch } from "react-hook-form";
import PayCharge from "./PayCharge";
import AddPayCharge from "./AddPayCharge";

import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { PlusOutlined } from "@ant-design/icons";

const initArry = {
  instno: "",
  type: "",
  amount: "",
  vat: "",
  total: "",
  mode: "",
  docNo: "",
  bankName: "",
  dueDate: "",
  chequeDate: null,
};

const { Text, Title } = Typography;

export default (props) => {
  const {
    control,
    setValue,
    errors,
    data,
    getValues,
    register,
    unregister,
    clearErrors,
    paymentSchedule,
    isCSVUploaded,
    ejariCharges,
    statusForm,
    set,
    updatePdcBtn,
    depositsPaid,
    leaseType
  } = props;

  const billingsData: any = useWatch({
    control,
    name: "billings",
  });

  const payment_Modes = [
    {
      id: "cheque",
      name: "cheque",
      from_date: "2021-02-24",
      to_date: null,
      createdAt: "2021-02-24T10:52:39.855Z",
      updatedAt: "2021-02-24T10:52:39.855Z",
    },
    {
      id: "cash",
      name: "cash",
      from_date: "2021-02-24",
      to_date: null,
      createdAt: "2021-02-24T10:52:39.855Z",
      updatedAt: "2021-02-24T10:52:39.855Z",
    },
    {
      id: "bank_transfer",
      name: "bank_transfer",
      from_date: "2021-02-24",
      to_date: null,
      createdAt: "2021-02-24T10:52:39.855Z",
      updatedAt: "2021-02-24T10:52:39.855Z",
    },

    {
      id: "credit_card",
      name: "credit_card",
      from_date: "2021-02-24",
      to_date: null,
      createdAt: "2021-02-24T10:52:39.855Z",
      updatedAt: "2021-02-24T10:52:39.855Z",
    },
  ];

  const payment_types = [
    // { label: "Rent Installment", key: "rent_installment" },
    { label: "Ejari fee", value: "ejari_fee" },
    { label: "Security Deposit", value: "security_deposit" },
    { label: "Rental Commission", value: "rental_commission" },
    { label: "Administration Charges", value: "administration_charges" },
    { label: "Furniture Charges", value: "furniture_charges" },
    { label: "Parking Charges", value: "parking_charges" },
    { label: "Service Charges", value: "service_charges" },
  ];

  // useSelector(
  //   (state: any) => state.leasingForm.getPaymentModes,
  // );

  const fieldArray = getValues().billings || [];
  const charges = getValues().othercharges || [];
  const usageType = getValues("usagesType")?.value;

  const [billingScheduleTotal, setBillingScheduleTotal] = useState(0);

  const [displayOnly, setDisplayOnly] = useState(false);

  const watchf = useWatch({
    control,
    name: "othercharges",
  });

  const [paymentData, setPaymentData] = useState([]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "addpayment",
  });

  useEffect(() => {
    console.log("fieldss", fields);
  }, [fields]);

  const [parkTotalAmount, setParkTotalAmount] = useState([]);
  const [parkTotalAmountVat, setParkTotalAmountVat] = useState([]);

  let amount_parking_charges_data_childs = [];
  let a_charges_data = [];
  let paymentSchedule_totalPayment = 0;

  useEffect(() => {
    console.log("isCSVUploaded", isCSVUploaded);
    setDisplayOnly(isCSVUploaded);
  }, [isCSVUploaded]);

  // useEffect(() => {

  //   const paymentData2 = [];
  //   let amount_parking_charges_data = [];
  //   let parkingTotalAmount = [];
  //   let parkingTotalAmountVat = [];

  //   if (!(paymentSchedule?.length > 0 && displayOnly === true) && fieldArray?.length > 0) {

  //     // console.log("PAYMENYCJATE",fieldArray,!(paymentSchedule?.length > 0 && displayOnly === true))

  //     // console.log("ins:2", charges)
  //     charges.map((item, index) => {
  //       let amount = Number(item?.amount) / Number(item?.intallments?.value);
  //       let vat = 0;
  //       let amount_parking_charges = [];
  //       let amount_parking_vat = [];
  //       let a__inner_charges = [];
  //       if (
  //         item.type.value === "rent_installment" ||
  //         item.type.value === "security_deposit"
  //       ) {
  //         vat = 0;//usageType == 1 ? amount * 0.05 : 0;
  //       } else {
  //         vat = amount * 0.05;
  //       }
  //       let total = amount + vat;
  //       // console.log("ins:2", charges)
  //       // console.log("index",index,total);

  //       // console.log("ins:3", billingsData)
  //       for (let i = 0; i < item.intallments.value; i++) {
  //         let y = billingsData?.find((x) => x.billtype == item.type.value);
  //         let dated = null;
  //         // console.log("ITSWORK",fieldArray, charges, billingsData, paymentSchedule)

  //         if (y && y.instaArray) {
  //           dated = dayjs(y?.instaArray[i]?.start_date, "DD-MM-YYYY").isValid() ?
  //             dayjs(y?.instaArray[i]?.start_date, "DD-MM-YYYY")
  //             : null;

  //           console.log("DATTEED", y?.instaArray[i]?.start_date, dated);
  //           if (item.type.value
  //             // item.type.value === "rent_installment" || //BASE RENT
  //             // item.type.value == "security_deposit" ||
  //             // item.type.value == "rental_commission" ||
  //             // item.type.value == "ejari_fee"
  //           ) {
  //             let charge_dara = {};
  //             // console.log("paymentSchedule:", paymentSchedule);
  //             if (item.type.value === "rent_installment" && paymentSchedule?.length > 0) {
  //               let x = paymentSchedule[i];
  //               // let md = { name: null, id: null };
  //               // md = payment_Modes.find((a) => a.name == x?.modeOfPayment);
  //               charge_dara = {
  //                 instno: i + 1,
  //                 type: item.type.value,
  //                 typeName: item.type,
  //                 amount: +amount.toFixed(2),
  //                 vat: vat,
  //                 total: total,
  //                 mode: {
  //                   label: x?.modeOfPayment,
  //                   value: x?.modeOfPayment,
  //                 },
  //                 bankName: x?.bankName,
  //                 docNo: x?.documentNo,
  //                 dueDate: dated,
  //                 chequeDate: x?.chequeDate
  //                   ? dayjs(x?.chequeDate, "YYYY-MM-DD")
  //                   : null,
  //                 instnoTotal: item.intallments.value,
  //                 creditCardRequest: x?.creditCardRequest,
  //                 chequeStatus: x?.chequeStatus,
  //                 paymentStatus: x?.paymentStatus
  //               };
  //             } else if (item.type.value == "rental_commission" && paymentSchedule?.length > 0) {
  //               // let x =
  //               //   paymentSchedule[
  //               //   paymentSchedule.length - 1
  //               //   ];
  //               // let md = { name: null, id: null };
  //               // md = payment_Modes.find((a) => a.name == x?.PaymentType);
  //               let x = paymentSchedule.find((a) => a.description === item.type.value);
  //               charge_dara = {
  //                 instno: i + 1,
  //                 type: item.type.value,
  //                 typeName: item.type,
  //                 amount: +amount.toFixed(2),
  //                 vat: vat,
  //                 total: total,
  //                 mode: {
  //                   label: x?.modeOfPayment,
  //                   value: x?.modeOfPayment,
  //                 },
  //                 docNo: x?.documentNo,
  //                 bankName: x?.bankName,
  //                 dueDate: dated,
  //                 chequeDate: x?.chequeDate
  //                   ? dayjs(x?.chequeDate, "YYYY-MM-DD")
  //                   : null,
  //                 instnoTotal: item.intallments.value,
  //                 creditCardRequest: x?.creditCardRequest,
  //                 chequeStatus: x?.chequeStatus,
  //                 paymentStatus: x?.paymentStatus
  //               };
  //             }
  //             else if (item.type.value == "ejari_fee" && paymentSchedule?.length > 0) {
  //               // console.log("ejari_fee...");
  //               // let m = ejariCharges?.find((x) => x.type == "ejari_fee");
  //               let x = paymentSchedule.find((a) => a.description === item.type.value);
  //               // console.log("iii", paymentSchedule[i]);
  //               charge_dara = {
  //                 instno: i + 1,
  //                 type: item.type.value,
  //                 typeName: item.type,
  //                 amount: +amount.toFixed(2),
  //                 vat: vat,
  //                 total: total,
  //                 mode: {
  //                   label: x?.modeOfPayment,
  //                   value: x?.modeOfPayment,
  //                   // label: m?.PaymentType,
  //                   // value: m?.PaymentType,
  //                 },
  //                 docNo: x?.documentNo,
  //                 bankName: x?.bankName,
  //                 dueDate: dated,
  //                 chequeDate: x?.chequeDate
  //                   ? dayjs(x?.chequeDate, "YYYY-MM-DD")
  //                   : null,
  //                 instnoTotal: item.intallments.value,
  //                 creditCardRequest: x?.creditCardRequest,
  //                 chequeStatus: x?.chequeStatus,
  //                 paymentStatus: x?.paymentStatus
  //               };
  //             }
  //             else if (item.type.value == "security_deposit" && paymentSchedule?.length > 0) {
  //               // let s = ejariCharges?.find((x) => x.type == "security_deposit");
  //               let x = paymentSchedule.find((a) => a.description === item.type.value);
  //               // console.log("SECURIY",paymentSchedule,i);
  //               charge_dara = {
  //                 instno: i + 1,
  //                 type: item.type.value,
  //                 typeName: item.type,
  //                 amount: +amount.toFixed(2),
  //                 vat: vat,
  //                 total: total,
  //                 mode: {
  //                   label: x?.modeOfPayment,
  //                   value: x?.modeOfPayment,
  //                 },
  //                 docNo: x?.documentNo,
  //                 bankName: x?.bankName,
  //                 dueDate: dated,
  //                 chequeDate: x?.chequeDate
  //                   ? dayjs(x?.chequeDate, "YYYY-MM-DD")
  //                   : null,
  //                 instnoTotal: item.intallments.value,
  //                 creditCardRequest: x?.creditCardRequest,
  //                 chequeStatus: x?.chequeStatus,
  //                 paymentStatus: x?.paymentStatus
  //               };
  //             }
  //             else if (item.type.value && paymentSchedule?.length > 0) {
  //               // let s = ejariCharges?.find((x) => x.type == "security_deposit");
  //               let x = paymentSchedule.find((a) => a.description === item.type.value);
  //               // console.log("SECURIY",paymentSchedule,i);
  //               charge_dara = {
  //                 instno: i + 1,
  //                 type: item.type.value,
  //                 typeName: item.type,
  //                 amount: +amount.toFixed(2),
  //                 vat: vat,
  //                 total: total,
  //                 mode: {
  //                   label: x?.modeOfPayment,
  //                   value: x?.modeOfPayment,
  //                 },
  //                 docNo: x?.documentNo,
  //                 bankName: x?.bankName,
  //                 dueDate: dated,
  //                 chequeDate: x?.chequeDate
  //                   ? dayjs(x?.chequeDate, "YYYY-MM-DD")
  //                   : null,
  //                 instnoTotal: item.intallments.value,
  //                 creditCardRequest: x?.creditCardRequest,
  //                 chequeStatus: x?.chequeStatus,
  //                 paymentStatus: x?.paymentStatus
  //               };
  //             }
  //             else {

  //               let mode = {};
  //               //THIS PART WAS COMMENTED AS PER TASKS
  //               // if (item.type.value == "ejari_fee") {
  //               //   mode = {
  //               //     label: "credit_card",
  //               //     value: "credit_card"
  //               //   }
  //               // }
  //               charge_dara = {
  //                 instno: i + 1,
  //                 type: item.type.value,
  //                 typeName: item.type,
  //                 amount: +amount.toFixed(2),
  //                 vat: vat,
  //                 total: +total.toFixed(2),
  //                 mode: item?.mode ?? mode,
  //                 docNo: "",
  //                 bankName: "",
  //                 dueDate: dated,
  //                 chequeDate: null,
  //                 instnoTotal: item.intallments.value,
  //                 creditCardRequest: "",
  //                 chequeStatus: "",
  //                 paymentStatus: ""
  //               };
  //             }
  //             // console.log("ins:4", charge_dara)
  //             // const finalArray = [...paymentData, charge_dara];
  //             paymentData2.push(charge_dara);
  //             // console.log("finalArray..", charge_dara, amount, total.toFixed(2));

  //             console.log("DATAAAA", paymentData2)
  //             setPaymentData(paymentData2);

  //             if (item.type.value === "rent_installment") {
  //               a__inner_charges.push({
  //                 instno: i + 1,
  //                 amount: y?.instaArray[i]?.amount,
  //                 vat: y?.instaArray[i]?.vat,
  //                 total: y?.instaArray[i]?.total,
  //                 type: item.type.value,
  //                 typeKey: item.type.value,
  //                 instnoTotal: item.intallments.value,
  //                 installmentStartDate: y?.instaArray?.[i]?.start_date,
  //                 installmentEndDate: y?.instaArray?.[i]?.end_date,
  //               });
  //             }
  //           } else if (item.type.value != "PARKING") {
  //             amount_parking_charges.push({
  //               instno: i + 1,
  //               type: item.type.value,
  //               amount: +amount.toFixed(2),
  //               vat: vat,
  //               total: total,
  //               mode: "",
  //               docNo: "",
  //               bankName: "",
  //               dueDate: dated,
  //               chequeDate: null,
  //               typeKey: item.type.value,
  //               instnoTotal: item.intallments.value,
  //               installmentStartDate: y?.instaArray?.[i]?.start_date,
  //               installmentEndDate: y?.instaArray?.[i]?.end_date,
  //               parkingSlot: item?.slot?.value,
  //             });
  //           }
  //         }
  //       }
  //       if (amount_parking_charges.length > 0) {
  //         amount_parking_charges_data.push(amount_parking_charges);
  //       }
  //       // console.log("result::",amount_parking_charges_data);
  //       if (paymentData.length > 0) {
  //         a_charges_data.push(a__inner_charges);
  //       }
  //     });

  //     let park = charges.filter((x) => x.type.label == "PARKING");
  //     park.map((item, index) => {
  //       let amount = Number(item.amount) / Number(item.intallments.value);
  //       let vat = amount * 0.05;
  //       let amount_parking_charges = [];
  //       let total = amount + vat;
  //       for (let i = 0; i < item.intallments.value; i++) {
  //         let y = billingsData?.filter((x) => x.billtype == item.type.value)[
  //           index
  //         ];
  //         let dated = "";
  //         if (y && y.instaArray) {
  //           dated = y?.instaArray[i]?.start_date;
  //           amount_parking_charges.push({
  //             instno: i + 1,
  //             type: item.type.value,
  //             amount: +amount.toFixed(2),
  //             vat: vat,
  //             total: total,
  //             mode: "",
  //             docNo: "",
  //             bankName: "",
  //             dueDate: dated,
  //             chequeDate: null,
  //             typeKey: item.type.value,
  //             instnoTotal: item.intallments.value,
  //             installmentStartDate: y?.instaArray?.[i]?.start_date,
  //             installmentEndDate: y?.instaArray?.[i]?.end_date,
  //             parkingSlot: item?.slot?.value,
  //           });
  //         }
  //       }

  //       if (amount_parking_charges.length > 0) {
  //         amount_parking_charges_data.push(amount_parking_charges);
  //       }
  //     });

  //     const n = amount_parking_charges_data.reduce(
  //       (max, xs) => Math.max(max, xs.length),
  //       0,
  //     );

  //     // console.log("result::n",n);

  //     const result = Array.from({ length: n });

  //     result.map((_, i) => {
  //       parkingTotalAmount.push(
  //         amount_parking_charges_data
  //           .map((xs) => { return (xs[i]?.amount || 0) })
  //           .reduce((sum, x) => sum + x, 0),
  //       );
  //     });

  //     // console.log("result::FINAL",parkingTotalAmount);
  //     if (parkingTotalAmount?.length > 0)
  //       setParkTotalAmount(parkingTotalAmount);

  //     const nV = amount_parking_charges_data.reduce(
  //       (max, xs) => Math.max(max, xs.length),
  //       0,
  //     );

  //     const resultV = Array.from({ length: nV });
  //     resultV.map((_, i) => {
  //       parkingTotalAmountVat.push(
  //         amount_parking_charges_data
  //           .map((xs) => xs[i]?.vat || 0)
  //           .reduce((sum, x) => sum + x, 0),
  //       );
  //     });
  //     if (parkingTotalAmountVat?.length > 0)
  //       setParkTotalAmountVat(parkingTotalAmountVat);

  //     const charge_merge = a_charges_data
  //       .concat(amount_parking_charges_data)
  //       .filter((el) => el.length > 0);
  //     const nVA = charge_merge.reduce((max, xs) => Math.max(max, xs.length), 0);
  //     const resultVA = Array.from({ length: nVA });
  //     resultVA.map((_, i) => {
  //       amount_parking_charges_data_childs.push(
  //         charge_merge
  //           .map((xs, _i) => {
  //             return xs[i] || null;
  //           })
  //           .filter((el) => el != null),
  //       );
  //     });

  //     register("amount_parking_charges_data");
  //     register("amount_parking_charges_data_childs");
  //     setValue(
  //       "amount_parking_charges_data",
  //       amount_parking_charges_data_childs,
  //     );
  //     setValue(
  //       "amount_parking_charges_data_childs",
  //       amount_parking_charges_data_childs,
  //     );
  //     // console.log("getValues().billingScheduleTotal",getValues().billingScheduleTotal)
  //     // setBillingScheduleTotal(getValues().billingScheduleTotal); // UPDATED SINCE THIS WAS SHOWING MINOR DEVIATION
  //   }

  //   // return () => {
  //   //   setPaymentData([]);
  //   //   // setParkTotalAmount([]);
  //   //   // setParkTotalAmountVat([]);
  //   // };

  // }, [billingsData, watchf]);

  // function convertToNormalText(text) {
  //   return text.replace(/_/g, ' ').replace(/\b\w/g, function (char) {
  //     return char.toUpperCase();
  //   });
  // }
  
  // function convertIfSnakeCase(text) {
  //   if (text?.includes('_')) {
  //     return convertToNormalText(text);
  //   } else {
  //     return text
  //   }
  // }

  useEffect(() => {
    const paymentData2 = [];
    // console.log("paymentScheduleLL",paymentSchedule,displayOnly,updatePdcBtn)
    if (paymentSchedule?.length > 0) {
      // removed for till active : updatePdcBtn
      let data = paymentSchedule;
      // console.log("datadata", data);

      paymentSchedule.forEach((item, index) => {
        let charge_dara = {
          instno: index + 1,
          type: item.description,//convertIfSnakeCase(item.description),
          typeName: item?.type,
          amount: +item?.actualAmount.toFixed(2),
          vat: item?.vat,
          total: item?.totalAmount,
          mode: {
            label: item?.modeOfPayment,
            value: item?.modeOfPayment,
          },
          bankName: item?.bankName,
          docNo: item?.documentNo,
          dueDate: item?.dueDate ? dayjs(item?.dueDate, "YYYY-MM-DD") : null,
          chequeDate: item?.chequeDate
            ? dayjs(item?.chequeDate, "YYYY-MM-DD")
            : null,
          instnoTotal: 5,
          creditCardRequest: item?.creditCardRequest,
          chequeStatus: item?.chequeStatus,
          paymentStatus: item?.paymentStatus,
        };
        //ADDED TO FIX PDC DATA ISSUES WD BE/FE
        if(item?.pdcId)
        charge_dara["pdcId"] = item?.pdcId;
        if(item?.installments)
        charge_dara["installments"] = item?.installments;

        paymentData2.push(charge_dara);
      });

      setPaymentData(paymentData2);
    }
  }, [paymentSchedule]);

  const appendPayment = () => {
    let charge_dara = {
      instno: 1,
      type: "",
      typeName: "item.type",
      amount: 0,
      vat: 0,
      total: "",
      mode: "",
      docNo: "",
      bankName: "",
      dueDate: "",
      chequeDate: null,
      instnoTotal: "",
    };

    // let data = paymentData;
    // console.log("LOOGED",data)

    // data.push(charge_dara);

    append(charge_dara);
  };

  useEffect(() => {
    // console.log("paymentData:updated", paymentData)
    if (paymentData?.length > 0) {
      const total = paymentData.reduce(
        (acc, obj) => acc + parseFloat(obj.total),
        0
      );
      setBillingScheduleTotal(+total?.toFixed(2));
    } else setBillingScheduleTotal(0);
  }, [paymentData]);

  return (
    <>
      <Row gutter={[24, 24]}>
        {/* <Col span={1}>
          <Text className="fontWeight600 ag-default">#</Text>
        </Col> */}
        <Col span={4}>
          <Text className="fontWeight600 ag-default mb-1">Description</Text>
        </Col>
        <Col span={3}>
          <Text className="fontWeight600 ag-default mb-2">Actual Amount</Text>
        </Col>
        <Col span={2}>
       <Text className="fontWeight600 ag-default mb-2">VAT</Text>  {/* REMOVED AGAIN */}   {/* added % for fixing confusion issue since new UI/keys changed */}
        </Col>
        <Col span={3}>
          <Text className="fontWeight600 ag-default mb-2">Total Amount</Text>
        </Col>
        <Col span={2}>
          <Text className="fontWeight600 ag-default mb-2">Payment Mode</Text>
        </Col>
        <Col span={3}>
          <Text className="fontWeight600 ag-default mb-2">Document No.</Text>
        </Col>
        <Col span={3}>
          <Text className="fontWeight600 ag-default mb-2">Bank Name</Text>
        </Col>
        {/* <Col span={2}>
          <Text className="fontWeight600 ag-default mb-2">Entity</Text>
        </Col> */}
        <Col span={2}>
          <Text className="fontWeight600 ag-default mb-2">Due Date</Text>
        </Col>
        <Col span={2}>
          <Text className="fontWeight600 ag-default mb-2">Cheque Date</Text>
        </Col>
      </Row>

      <Row gutter={24}>
        <PayCharge
          control={control}
          setValue={setValue}
          register={register}
          resetField={props?.resetField}
          errors={errors}
          disableAll={props.disableAll}
          data={paymentData}
          displayOnly={displayOnly}
          depositsPaid={depositsPaid}
          building={data}
          parkingTotalAmount={parkTotalAmount}
          parkingTotalAmountVat={parkTotalAmountVat}
          paymentModes={payment_Modes}
          getValues={getValues}
          statusForm={statusForm}
        />
        {fields?.map((item, index) => (
          <Fragment key={item.id}>
            <AddPayCharge
              control={control}
              setValue={setValue}
              register={register}
              resetField={props?.resetField}
              errors={errors}
              disableAll={props.disableAll}
              item={item}
              index={index}
              paymentTypes={payment_types}
              building={data}
              remove={remove}
              paymentModes={payment_Modes}
              getValues={getValues}
              statusForm={statusForm}
            />
          </Fragment>
        ))}
      </Row>
      {(updatePdcBtn || leaseType == "renewal") && (
        <Row gutter={[24, 24]} justify="start">
          <Col span={24}>
            <Button
              className="add-more"
              icon={<PlusOutlined style={{ fontSize: "initial" }} />}
              type="link"
              onClick={appendPayment}
            >
              Add a Payment
            </Button>
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24}>
          <Title level={4} style={{ textAlign: "end" }}>
            Total: {+billingScheduleTotal?.toFixed(2)}
          </Title>
        </Col>
      </Row>
    </>
  );
};
