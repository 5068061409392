import React, { useState, useEffect, Fragment } from "react";
import { Popup } from "../../../atoms/Popup";
import {
  Card,
  Row,
  Col,
  Typography,
  Button,
  Image,
  Upload,
  message,
  Space,
  Form,
  Input,
} from "antd";
import {
  FileTextOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import PdfViewer from "../../../molecules/PDFViewer/pdfView";

import { useFieldArray, Controller } from "react-hook-form";
import { dummyRequest } from "../../Properties/utils/utility";
import { InputField, ReactSelectField } from "../../../atoms/FormElement";
import { getPresignedImage } from "../../../../utils/media";

var _ = require("lodash");

export default (props) => {
  const [visible, setVisible] = useState(false);
  const {
    control,
    disableAll,
    data,
    page,
    resetField,
    deletedAdditionalDocs,
    setDeletedAdditionalDocs,
    requiredBookingDocumentsList,
    isAdd,
    disableClone,
    setValue,
    requiredDocArray
  } = props;
  const { Title } = Typography;
  const [viewOrDownloadUrl, setViewOrDownloadUrl] = useState([]);
  const [showPdf, setShowPdf] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [viewImage, setViewImage] = useState(null);
  const [isAddMore, setIsAddMore] = useState(true);

  const [isOtherDoc, setIsOtherDoc] = useState(null);




  // const [additionalDocuments, setAdditionalDocuments] = useState([]);

  const [existingDocuments, setExistingDocuments] = useState([]);

  useEffect(() => {
    setIsAddMore(isAdd);
  }, [isAdd]);

  const onClosePopUp = () => {
    setViewImage(null);
    setVisible(false);
  };

  const initDocBar = {
    id: "",
    file: "",
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "OtherDocs",
  });



  // useEffect(()=>{

  //   if(requiredDocArray?.length>0)


  // },[requiredDocArray]);


  const onOpenPopUpHandler = async (key, i) => {
    if (key?.includes(".pdf")) {
      setPdfFile(key);
      setShowPdf(!showPdf);
    } else {
      setViewImage(key);
      setVisible(true);
    }
  };

  const getUrl = async (d) => {
    let dlink = [];
    let filing = [];
    await Promise.all(
      d.map(async (e) => {
        let naming = page == "booking" ? e.object_key : e.other_document_key;
        const url = await getPresignedImage(naming);
        dlink.push(url);
        if (page == "booking") {
          filing.push({
            doc_order: e.doc_order,
            id: e.tenant_object_id,
            file: {
              file: {
                uid: e.tenant_object_id,
                name: e.object_key,
                status: "done",
                url: url,
              },
            },
          });
        } else {
          filing.push({
            doc_order: e.doc_order,
            id: e.tenant_object_id,
            file: {
              file: {
                uid: e.tenant_object_id,
                name: e.other_document_key,
                status: "done",
                url: url,
              },
            },
          });
        }
      }),
    );
    filing.sort((a, b) => a.doc_order - b.doc_order);
    setValue("OtherDocs", filing);
    setViewOrDownloadUrl(filing);
  };

  const popupContent = {
    closable: true,
    visibility: visible,
    content: (
      <Image
        preview={false}
        width="100%"
        style={{ padding: "20px" }}
        src={viewImage}
      />
    ),
    width: 650,
    onCancel: onClosePopUp,
  };


  const onChange = (e, index) => {
    // if (e.value == "rental_commission") {
    //   setValue(`othercharges[${index}].intallments`, { label: "1", value: 1 });
    //   setValue(
    //     `othercharges[${index}].amount`,
    //     getValues("annualBaseRent") * configData?.rental_commission_percentage,
    //   );
    //   setRCDisable(true);
    // } else {
    //   setRCDisable(false);
    // }
    // e.value == "PARKING" ? setEnable(true) : setEnable(false);
    // setTypeM(e.value);
    // console.log("BOOKING>>>", e);
    if (e?.value === "Other")
      setIsOtherDoc(index);
    else if (e?.value != "Other" && index === isOtherDoc) {
      setIsOtherDoc(null);
    }
    disableClone(e.value);
  };



  // const handleExistingDocDelete = (id) => {
  //   // const filteredArray = existingDocuments.filter(
  //   //   (e) => e.lead_object_id !== id,
  //   // );
  //   // setExistingDocuments(filteredArray);
  //   const delArray = [...deletedAdditionalDocs];
  //   delArray.push(id);
  //   setDeletedAdditionalDocs(delArray);
  // };

  const onUpChange = (e, i, s) => {
    // let v = [...viewOrDownloadUrl];
    // v[i] = e.file.originFileObj.name;
    // console.log("checking", v);
    // setViewOrDownloadUrl(v);
  };

  return (
    <>
      {/* {existingDocuments?.map((e, i) => {
        return (
          <Card
            bordered={false}
            key={e.tenant_object_id}
            className="h-auto mb-2"
          >
            <Row gutter={24} justify="space-between" align="middle">
              <Col span={12} style={{ display: "flex" }}>
                <FileTextOutlined className="require-doc" />
                <Title level={5}>{`Other Document ${i + 1}`} </Title>
              </Col>
              <Col>
                <Space>
                  <Button
                    type="link"
                    href={viewOrDownloadUrl[i]}
                    target="_blank"
                  >
                    Download
                  </Button>
                  {!disableAll && (
                    <Button
                      danger
                      type="link"
                      htmlType="button"
                      icon={<DeleteOutlined />}
                      onClick={() => handleExistingDocDelete(e.lead_object_id)}
                    />
                  )}
                </Space>
              </Col>
            </Row>
          </Card>
        );
      })} */}
      <PdfViewer
        pdf={pdfFile}
        onCancel={() => setShowPdf(false)}
        visible={showPdf}
      />
      {fields.map((item, index) => {
        return (
          <Card bordered={false} key={item.id} className="h-auto" style={{ marginBottom: '0.6rem' }}>
            <Row gutter={24} justify="space-between" align="middle">
              <Col flex="500px" style={{ display: "flex", height: 35 }}>
                <FileTextOutlined className="require-doc" />
                <Col span={12}>
                  {/* <Title level={5}>{`Other Document ${index + 1}`}</Title>
                   */}
                  <ReactSelectField
                    label=""
                    classes="mb-0"
                    fieldname={`additionalDocs[${index + 1}]`}
                    control={props.control}
                    iProps={{ placeholder: "Document Type" }}
                    // initValue={}
                    onChange={(e) => onChange(e, index)}
                    selectOption={requiredBookingDocumentsList}
                  // selectOption={[
                  //   { label: "Passport", value: "passport",isDisabled:true },
                  //   { label: "UAE Visa", value: "uaeVisa" },
                  //   { label: "Emirates Id", value: "emiratesID" },
                  //   { label: "Other Document", value: "other" },
                  // ]}
                  />

                </Col>
                <Col span={12}>
                  {(isOtherDoc != null && isOtherDoc == index) && (
                    <Form.Item>
                      <InputField
                        isRequired={false}
                        fieldname={`additionalDocs[${index + 1}].documentLabel`}
                        iProps={{ placeholder: "Document Name" }}
                        control={control}
                      // classes="d-none"
                      />
                    </Form.Item>
                  )}
                </Col>
              </Col>
              <Col>
                {existingDocuments[index] && (
                  <Button
                    type="link"
                    href={viewOrDownloadUrl[index]?.file?.file?.url}
                    target="_blank"
                  >
                    Download
                  </Button>
                )}
                <Space>
                  <Form.Item className="mb-0">
                    <InputField
                      isRequired={false}
                      fieldname={`OtherDocs[${index}].id`}
                      label=""
                      control={control}
                      classes="d-none"
                      initValue={item.id}
                    />
                  </Form.Item>

                  {/* <Form.Item className="mb-0">
                    <Controller
                      name={`OtherDocs[${index}].file`}
                      control={control}
                      defaultValue={item['file']}
                      render={(props: any) => (
                        <Upload
                          className="uploadWithbtn"
                          showUploadList={false}
                          // accept="image/*"
                          maxCount={1}
                          customRequest={dummyRequest}
                          onChange={(e) => {
                            props.onChange(e);
                            onUpChange(e, index, props);
                          }}
                        >
                          <Space size={8}>
                            <Button type="link" className="p-0 ag-primary">
                              {existingDocuments[index] ? "Change" : "Upload"}
                            </Button>
                            {props.value?.file?.size && (
                              <Input
                                bordered={false}
                                size="middle"
                                className="ag-upload-btn ag-ad-btn"
                                value={props.value.file.name}
                              />
                            )}
                          </Space>
                        </Upload>
                      )}
                    />
                  </Form.Item> */}
                  {/* {!disableAll && (
                    // <Button
                    //   danger
                    //   type="link"
                    //   htmlType="button"
                    //   icon={<DeleteOutlined />}
                    //   onClick={() => remove(index)}
                    // />
                  )} */}
                </Space>
                {existingDocuments[index] && (
                  <Button
                    type="link"
                    onClick={() =>
                      onOpenPopUpHandler(
                        viewOrDownloadUrl[index]?.file?.file?.url,
                        index,
                      )
                    }
                  >
                    View
                  </Button>
                )}
              </Col>
            </Row>
          </Card>
        );
      })}

      {isAddMore && !disableAll && fields.length != 5 && (
        <Row gutter={[24, 24]} justify="end">
          <Col span={24}>
            <Button
              className="add-more"
              icon={<PlusOutlined style={{ fontSize: "initial" }} />}
              type="link"
              onClick={() => {
                append(initDocBar);
              }}
            >
              Add document
            </Button>
          </Col>
        </Row>
      )}
      <Popup {...popupContent} />
    </>
  );
};
