import React, { useEffect } from "react";
import { Form, Row, Col, Button, message } from "antd";
import { ReactSelectField, TextAreaField } from "../../../atoms/FormElement";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getTenantRejectionReason } from "../../../modules/TenantDetail/ducks/actions";
import { baseContractService } from "../../../../configs/constants";
import axios from "../../../../utils/axiosInceptor";
import { useNavigate } from "react-router-dom";
import { updateBookingStatus } from "../../BookingListing/ducks/actions";

const RejectForm = (props) => {
  const navigate = useNavigate();
  const { onClose, leaseId, rejectionStatus, rejectionDocument } = props;
  // const dispatch: any = useDispatch();
  const { control, formState: { errors }, setValue, handleSubmit } = useForm();

  const reasonsData = [
    { value: "incorrect_information", label: "Incorrect Information" },
    { value: "incomplete_documents", label: "Incomplete Documents" },
    { value: "incomplete_amounts", label: "Incomplete Amounts" },
  ];
  
  // useSelector(
  //   (state: any) => state.tenantDetails.getRejectReasons,
  // );





  // useEffect(() => {
  //   dispatch(getTenantRejectionReason());
  // }, []);

  const handleReject = async (data) => {
    let success = "Rejected tenant information";
    const payload = {
      "status": rejectionStatus ?? "tenant_information_incomplete",
      // "documentType": "uaeVisa",
      "reason": data.reason.value,
      "comments": data.rejectReason
    };

    if (rejectionDocument) {
      payload['documentType'] = rejectionDocument;
      success = "Rejected document";
    }

    try {
      const res = await updateBookingStatus(leaseId, payload);
      if (res === 200) {
        message.success(success);
        props.onSubmit();
      }
    } catch (error) {

    }
  };
  return (
    <Form layout="vertical" onFinish={handleSubmit(handleReject)}>
      <Row gutter={24}>
        <Col span={12}>
          <ReactSelectField
            label="Select a reason"
            fieldname="reason"
            iProps={{ placeholder: "Select reasons" }}
            initValue=""
            setValue={setValue}
            isRequired={true}
            control={control}
            rules={{ required: "Required" }}
            validate={errors.reason && "error"}
            validMessage={errors.reason && errors.reason.message}
            selectOption={reasonsData}
          />
        </Col>
        <Col span={24}>
          <TextAreaField
            label="Enter a comment for rejection:"
            fieldname="rejectReason"
            iProps={{ placeholder: "Some reason...." }}
            initValue=""
            setValue={setValue}
            control={control}
          />
        </Col>
      </Row>
      <Row gutter={24} justify="space-between">
        <Col>
          <Button size="large" onClick={onClose}>
            Back
          </Button>
        </Col>
        <Col>
          <Button size="large" className="ag-red-button" htmlType="submit">
            Reject
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default RejectForm;
