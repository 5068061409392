import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Breadcrumb,
  Pagination,
  Card,
  Menu,
  Space,
  Dropdown,
  Button,
  Form,
  Select,
} from "antd";
import { SearchOutlined, RightOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Grid from "../../../atoms/Grid";
import SectionHeader from "../../../molecules/SectionHeader";
import { getLeadesLists } from "../../Properties/AddProperty/ducks/actions/addInfoAction";
import DashboardLayout from "../../../molecules/DashboardLayout";
import { DownOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { getLeads } from "./ducks/actions";
import BreadCrumb from "../../../atoms/BreadCrumb";

const { Option } = Select;

const gridProps = {
  sortable: true,
  filter: true,
};
const breadcrumbList: any = [
  {
    label: "Lead Managment",
    route: "/leads",
  },
  {
    label: "All Leads",
    route: "/leads",
  },
];

const searchBy = [
  { label: "Tenant Name", value: "tenant_name" },
  // { label: "Mobile Number", value: "mobile_number" },
  // { label: "Email", value: "email" },
];

const breadcrumbListProperties: any = [];

const limit = 10;

function viewRender() {
  return (
    <Button type="link">
      <ArrowRightOutlined style={{ fontSize: 25 }} />
    </Button>
  );
}

const Leads = (props) => {
  const dispatch:any = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchByT, setSearchBy] = useState("");
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const leadsData = useSelector((state: any) => state.leads.getLeads);

  const leadCol = [
    {
      headerName: "ID",
      field: "id",
    },
    {
      headerName: "Lead Name",
      field: "tenant_name",
    },
    {
      headerName: "Email",
      field: "email",
    },
    {
      headerName: "Mobile Number",
      field: "mobile_number",
    },
    {
      headerName: "Unit Number",
      field: "unit_name",
    },
    {
      headerName: "Visited Property",
      field: "is_visited",
    },
    {
      headerName: "Visit Date and Time",
      field: "visit_datetime",
    },
    {
      headerName: "View Details",
      field: "detail",
      maxWidth: 150,
      onCellClicked: (row) => {
        window.localStorage.setItem("selectedPartyId", row.data.party_id);
        window.localStorage.setItem("assetId", row.data.asset_id);
        navigate(`/lead-detail/${row.data.id}`);
      },
      cellRendererFramework: viewRender,
    },
  ];

  const onSearch = (val) => {
    setCurrentPage(0);
    if (val.searchText) {
      setSearch(val.searchText);
      setSearchBy(val.searchBy);
      dispatch(getLeads(limit, 0, val.searchText, val.searchBy));
    } else {
      setSearch("");
      dispatch(getLeads(limit, 0, "", ""));
    }
  };

  const SearchFelds = () => {
    return (
      <Form onFinish={onSearch}>
        <Space wrap>
          <Form.Item name="searchText" className="mb-0" initialValue={search}>
            <Input type="text" placeholder="Search" value={search} />
          </Form.Item>
          <Form.Item
            name="searchBy"
            className="mb-0"
            initialValue={"tenant_name"}
          >
            <Select placeholder="Select">
              {searchBy.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item className="mb-0 noborder-btn">
            <Button
              htmlType="submit"
              className="ag-gray-button"
              icon={<SearchOutlined />}
            />
          </Form.Item>
        </Space>
      </Form>
    );
  };

  useEffect(() => {
    dispatch(getLeads(limit, 0, "", ""));
  }, []);

  const handlePagination = (pg) => {
    setCurrentPage(pg);
    dispatch(getLeads(limit, pg, "", ""));
  };

  return (
    <DashboardLayout>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <BreadCrumb
            list={
              location?.state?.from ? breadcrumbListProperties : breadcrumbList
            }
          />
        </Col>
        <Col span={24}>
          <SectionHeader heading="All Leads" total={leadsData?.count || 0} />
        </Col>
        <Col span={24}>
          <Pagination
            className="ag-property-text-pagination"
            total={leadsData?.count || 0}
            showTotal={(total, range) =>
              `Showing ${range[0]}-${range[1]} of ${total} Leads`
            }
            showSizeChanger={false}
            defaultPageSize={10}
            defaultCurrent={1}
            current={currentPage + 1}
            onChange={(e) => handlePagination(e - 1)}
          />
        </Col>
        <Col span={24}>
          <Row gutter={[24, 24]} justify="end">
            <Col span={24}>
              <Card extra={<SearchFelds />} bodyStyle={{ padding: "0px" }}>
                <Grid
                  data={leadsData?.rows}
                  columns={leadCol}
                  pagination={false}
                  defaultSettings={gridProps}
                  noRowlabel="Leads"
                  fullPage={true}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </DashboardLayout>
  );
};

export default Leads;
