import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import rootReducer from "../reducers/root.reducer";

const middleware = [thunk];
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['dashboard','propertyDetails','userScreens'] 
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middleware)),
);
const persistor = persistStore(store);
export { store, persistor };
