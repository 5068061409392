import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Checkbox, Spin, Radio } from "antd";

import {
  InputField,
  ReactSelectField,
  InputRadio,
} from "../../../atoms/FormElement";
import { useSelector, useDispatch } from "react-redux";
import {
  getMaritalStatus,
  getTenantNationalities,
} from "../../ContractTenantDetails/ducks/actions";
import { getConfigData } from "../../Properties/ducks/actions";
import { getDetailsFromEmail } from "../../Maintenance/Technicians/ducks/services";
import { validateEmail } from "../../../../utils/helper";

var _ = require("lodash");

export default (props) => {
  const {
    control,
    errors,
    register,
    nationalityList,
    disableAll,
    setValue,
    emailDetailsLoading,
    signatoryEmailWatcher,
    getValues,
    onEmailChange,
  } = props;
  const detailsApi = props.detailsApi?.tenantDetails;

  const signatoryUserApiDetails = props?.detailsApi?.signatoryUser;
  const showAuthorizedSignatoryApiDetails = (
    props?.detailsApi?.isSignatory || "YES"
  ).toUpperCase();

  const { Paragraph } = Typography;
  const dispatch: any = useDispatch();
  const maritalStatusApi = useSelector(
    (state: any) => state.tenantDetails.getMarital
  );

  // useEffect(() => {
  //   dispatch(getTenantNationalities());
  //   dispatch(getMaritalStatus());
  // }, []);

  useEffect(() => {
    if (detailsApi) {
      setValue("name", detailsApi.tenantNameEN);
      detailsApi?.tenantNameAR &&
        setValue("arabicName", detailsApi?.tenantNameAR);
      if (detailsApi.mobileNumber) {
        let str: any = detailsApi.mobileNumber;
        setValue("mobile", str.substring(str.length - 9));
      }
      setValue("numberOfOcupants", detailsApi?.numberOfOcupants ?? 1);
      setValue("email", detailsApi?.email);
      setValue("poBox", detailsApi.poBox);
      setValue("maritalStatus", {
        label: detailsApi.maritalStatus,
        value: detailsApi.maritalStatus,
      });
      if (detailsApi.nationality) {
        setValue("nationality", {
          label: detailsApi.nationality,
          value: detailsApi.nationality,
        });
      }

      if (detailsApi.designationAsPerVisa) {
        setValue("designationAsPerVisa", {
          label: detailsApi.designationAsPerVisa,
          value: detailsApi.designationAsPerVisa,
        });
      }
      if (detailsApi.employmentSector) {
        setValue("employmentSector", {
          label: detailsApi.employmentSector,
          value: detailsApi.employmentSector,
        });
      }
      if (detailsApi.income) {
        setValue("income", {
          label: detailsApi.income,
          value: detailsApi.income,
        });
      }

      setValue("showAuthorizedSignatory", showAuthorizedSignatoryApiDetails);
      setValue("signatoryName", signatoryUserApiDetails?.name);
      setValue("signatoryEmail", signatoryUserApiDetails?.email);
      setValue("signatoryNameArabic", signatoryUserApiDetails?.arabicName);
      setValue("signatoryMobileNumber", signatoryUserApiDetails?.mobile);
      setValue("signatoryDesignation", signatoryUserApiDetails?.designation);
      setValue("signatoryCompany", signatoryUserApiDetails?.company);
      // if (detailsApi?.contacts?.length) {
      //   const selValue = detailsApi?.contacts?.find(
      //     (e) => e?.party_id === detailsApi?.selectedContact,
      //   );
      //   if (selValue) {
      //     setValue("contacts", {
      //       label: selValue.first_name,
      //       value: selValue.party_id,
      //     });
      //   }
      // }
    }
  }, [detailsApi]);

  return (
    <Row gutter={24}>
      <Col span={8}>
        <InputField
          label="Tenant Name"
          fieldname="name"
          control={control}
          initValue=""
          iProps={{ placeholder: "Tenant Name" }}
          isRequired={true}
          validate={errors.name && "error"}
          validMessage={errors.name && errors.name.message}
          disabled={disableAll}
          rules={{ required: "Please enter tenant name" }}
        />
      </Col>
      <Col span={8}>
        <InputField
          label="Tenant Name Arabic"
          fieldname="arabicName"
          control={control}
          initValue=""
          setValue={setValue}
          register={register}
          resetField={props?.resetField}
          valueGot={
            !!detailsApi?.tenantNameAR ? detailsApi?.tenantNameAR : "empty"
          }
          iProps={{ placeholder: "Tenant Name Arabic" }}
          isRequired={true}
          validate={errors.arabicName && "error"}
          validMessage={errors.arabicName && errors.arabicName.message}
          disabled={disableAll} //true -> no  // false->yes
          rules={{ required: "Please enter tenant arabic name " }}
        />
      </Col>
      <Col span={8}>
        <ReactSelectField
          label="Nationality"
          fieldname="nationality"
          control={control}
          initValue=""
          iProps={{ placeholder: "Select Nationality", isDisabled: disableAll }}
          selectOption={_.map(nationalityList, (item) => ({
            value: item,
            label: item,
          }))}
          isRequired={true}
          validate={errors.nationality && "error"}
          validMessage={errors.nationality && errors.nationality.message}
          rules={{ required: "Please select nationality" }}
        />
      </Col>
      <Col span={8}>
        <InputField
          label="Mobile number"
          fieldname="mobile"
          control={control}
          initValue=""
          iProps={{ addonBefore: "+971" }}
          isRequired={true}
          validate={errors.mobile && "error"}
          validMessage={errors.mobile && errors.mobile.message}
          disabled={disableAll || detailsApi?.mobileNumber}
          rules={{
            minLength: {
              value: 9,
              message: "Please enter valid mobile number, 9 digits required",
            },
            maxLength: {
              value: 9,
              message: "Please enter valid mobile number, 9 digits required",
            },
            required: "Please enter Mobile No.",
          }}
        />
      </Col>
      <Col span={8}>
        <ReactSelectField
          label="Marital Status"
          fieldname="maritalStatus"
          control={control}
          initValue=""
          iProps={{
            placeholder: "Select Marital Status",
            isDisabled: disableAll,
          }}
          selectOption={[
            { label: "Single", value: "Single" },
            { label: "Married", value: "Married" },
            { label: "Widowed", value: "widowed" },
            { label: "Divorced", value: "divorced" },
          ]}
          // selectOption={_.map(maritalStatusApi, (item) => ({
          //   value: item.id,
          //   label: item.name,
          // }))}
          isRequired={true}
          validate={errors.maritalStatus && "error"}
          validMessage={errors.maritalStatus && errors.maritalStatus.message}
          rules={{ required: "Please select marital status" }}
        />
      </Col>
      <Col span={8}>
        <InputField
          label="Email Address"
          fieldname="email"
          control={control}
          iProps={{ placeholder: "Enter Email Address" }}
          initValue=""
          isRequired={true}
          validate={errors.email && "error"}
          validMessage={errors.email && errors.email.message}
          disabled={disableAll || detailsApi?.email}
          rules={{
            required: "Email Address Required",
            pattern: {
              value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
              message: "Enter valid Email Address",
            },
          }}
        />
      </Col>
      <Col span={8}>
        <InputField
          isRequired={true}
          label="No. of occupants"
          fieldname="numberOfOcupants"
          control={control}
          validate={errors.numberOfOcupants && "error"}
          validMessage={
            errors.numberOfOcupants && errors.numberOfOcupants.message
          }
          initValue={1}
          disabled={disableAll}
          iProps={{ type: "number", min: 1, disabled: props.disableAll }}
          rules={{ required: "Please enter occupants" }}
        />
      </Col>

      <Col span={8}>
        <InputField
          label="P.O Box"
          fieldname="poBox"
          control={control}
          initValue=""
          iProps={{
            placeholder: "Enter P.O. Box",
            type: "number",
          }}
          disabled={disableAll}
          // isRequired={true}
          // validate={errors.poBox && "error"}
          // validMessage={
          //   errors.poBox && errors.poBox.message
          // }
          // rules={{ required: "Please enter P.O box number" }}
        />
      </Col>

      {/* REMOVED for bug 7475  */}
      {/* {(detailsApi?.designationAsPerVisa ||
        detailsApi?.employmentSector ||
        detailsApi?.employmentSector) && (
        <Col span={24}>
          <Row gutter={24}>
            <Col span={8}>
              <ReactSelectField
                label="Industry"
                fieldname="employmentSector"
                control={control}
                initValue=""
                iProps={{ placeholder: "Select Industry", isDisabled: true }}
                // selectOption={[{ label: "Single", value: "Single" }, { label: "Married", value: "Married" }, { label: "Widowed", value: "widowed" }, { label: "Divorced", value: "divorced" }]}
              />
            </Col>
            <Col span={8}>
              <ReactSelectField
                label="Job Title"
                fieldname="designationAsPerVisa"
                control={control}
                initValue=""
                iProps={{ placeholder: "Select Job Title", isDisabled: true }}
                // selectOption={[{ label: "Single", value: "Single" }, { label: "Married", value: "Married" }, { label: "Widowed", value: "widowed" }, { label: "Divorced", value: "divorced" }]}
              />
            </Col>

            <Col span={8}>
              <ReactSelectField
                label="Monthly Income"
                fieldname="income"
                control={control}
                initValue=""
                iProps={{
                  placeholder: "Select Income Range",
                  isDisabled: true,
                }}
                // selectOption={[{ label: "Single", value: "Single" }, { label: "Married", value: "Married" }, { label: "Widowed", value: "widowed" }, { label: "Divorced", value: "divorced" }]}
              />
            </Col>
          </Row>
        </Col>
      )} */}

      <Col span={24}>
        <Paragraph className="fontWeight600 mb-0 flex">
          {"Is the tenant the authorized signatory"}
        </Paragraph>
        {props?.detailsApi?.email === signatoryEmailWatcher ||
          (getValues("email") === signatoryEmailWatcher && (
            <Paragraph className="color-error font-12">
              {"The tenant email and authorized signatory email are the same."}
            </Paragraph>
          ))}
        <Col span={12} className="mt-1">
          <InputRadio
            isRequired={true}
            label=""
            fieldname="showAuthorizedSignatory"
            // noStyle={true}
            control={control}
            initValue="YES"
            options={
              <Row gutter={[16, 16]} className="mb-0">
                <Col span={6}>
                  <Radio value="YES">Yes</Radio>
                </Col>
                <Col span={6}>
                  <Radio value="NO">No</Radio>
                </Col>
              </Row>
            }
          />
        </Col>

        {!(
          (getValues("showAuthorizedSignatory") || "")?.toUpperCase(0) === "YES"
        ) && (
          <Row gutter={24} justify="space-between" className="mt-0">
            <Col span={8}>
              <InputField
                label={
                  <>
                    {"Email "}
                    {emailDetailsLoading && (
                      <Spin className="mr-2" size="small" />
                    )}
                  </>
                }
                onChange={(e) => {
                  onEmailChange(e);
                }}
                fieldname="signatoryEmail"
                control={control}
                initValue=""
                isRequired={true}
                rules={{
                  pattern: {
                    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    message: "Enter valid email",
                  },
                  required: "Please enter email",
                }}
                iProps={{ placeholder: "Signatory email" }}
                validate={errors.signatoryEmail && "error"}
                validMessage={
                  errors.signatoryEmail && errors.signatoryEmail.message
                }
              />
            </Col>
            <Col span={8}>
              <InputField
                label="Signatory Name"
                fieldname="signatoryName"
                control={control}
                initValue=""
                isRequired={true}
                rules={{
                  required: "Please enter name",
                }}
                iProps={{ placeholder: "Signatory Name" }}
                validate={errors.signatoryName && "error"}
                validMessage={
                  errors.signatoryName && errors.signatoryName.message
                }
              />
            </Col>
            <Col span={8}>
              <InputField
                label="Signatory Arabic Name"
                fieldname="signatoryNameArabic"
                control={control}
                initValue=""
                isRequired={true}
                rules={{
                  required: "Please enter name",
                }}
                iProps={{ placeholder: "Signatory name" }}
                validate={errors.signatoryNameArabic && "error"}
                validMessage={
                  errors.signatoryNameArabic &&
                  errors.signatoryNameArabic.message
                }
              />
            </Col>
            <Col span={8}>
              <InputField
                label="Mobile number"
                fieldname="signatoryMobileNumber"
                control={control}
                initValue=""
                iProps={{ addonBefore: "+971" }}
                isRequired={true}
                validate={errors.mobile && "error"}
                validMessage={errors.mobile && errors.mobile.message}
                // disabled={disableAll || detailsApi?.mobileNumber}
                rules={{
                  minLength: {
                    value: 9,
                    message:
                      "Please enter valid mobile number, 9 digits required",
                  },
                  maxLength: {
                    value: 9,
                    message:
                      "Please enter valid mobile number, 9 digits required",
                  },
                  required: "Please enter Mobile No.",
                }}
              />
            </Col>
            <Col span={8}>
              <InputField
                label="Designation"
                fieldname="signatoryDesignation"
                control={control}
                initValue=""
                isRequired={true}
                rules={{
                  required: "Enter signatory designation",
                }}
                iProps={{ placeholder: "Signatory designation" }}
                validate={errors.signatoryDesignation && "error"}
                validMessage={
                  errors.signatoryDesignation &&
                  errors.signatoryDesignation.message
                }
              />
            </Col>
            <Col span={8}>
              <InputField
                label="Company"
                fieldname="signatoryCompany"
                control={control}
                initValue=""
                isRequired={true}
                rules={{
                  required: "Please enter Company",
                }}
                iProps={{ placeholder: "Signatory company" }}
                validate={errors.signatoryCompany && "error"}
                validMessage={
                  errors.signatoryCompany && errors.signatoryCompany.message
                }
              />
            </Col>
          </Row>
        )}
      </Col>

      {/* <Col span={8}>
        <ReactSelectField
          label="Contacts Name"
          fieldname="contacts"
          control={control}
          iProps={{
            placeholder: "Select contacts",
            isDisabled: props.disableAll,
          }}
          selectOption={_.map(detailsApi?.contacts, (e) => {
            return { label: e.first_name, value: e.party_id };
          })}
        />
      </Col> */}
    </Row>
  );
};
