import React from 'react';
import { Button, Result } from 'antd';
import { Link, useLocation, useNavigate } from "react-router-dom";
const Unauthorized: React.FC = () => {

    const navigate = useNavigate();
    return (
        <Result
          status="403"
          title="403"
          subTitle="Sorry, you are not authorized to access this page."
          extra={<Button type="primary" onClick={()=>navigate('/dashboard')} >Back Home</Button>}
        />
      )
};

export default Unauthorized;