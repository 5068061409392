import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Upload,
  Space,
  Typography,
  message,
  Spin,
} from "antd";
import { useForm, useWatch, Controller } from "react-hook-form";
import {
  InputField,
  SelectField,
  DateField,
  ReactSelectField,
} from "../../../../../atoms/FormElement";
import { PlusCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getTicketType,
  getTicketSubType,
  getPropertyName,
  getTechnicians,
  resetTechnician,
  getPropertyUnits,
  getAvailablity,
  getOrgByID,
} from "../../ducks/actions";
import { maintenanceService } from "../../../../../../configs/constants";
import axios from "../../../../../../utils/axiosInceptor";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  uniquiFileName,
  getSingleUpload,
  getSingleTicketMediaUpload,
} from "../../../../../../utils/fileUpload";
import { getMaintenanceTypes } from "../../../../Configurations/ducks/actions";
import { getServiceProviders } from "../../../../ServiceProvider/ducks/actions";
import dayjs from "dayjs";
import { defaultMaintenancePreferredDateDays } from "../../../../BookingForm/components/utils";

var _ = require("lodash");
const { Text } = Typography;

const defaultVal = {
  tickettype: "",
  requesttype: "regular",
  ticketsubtype: "",
  date: "",
  timeslot: "",
  desc: "",
  media: "",
  propertytype: "",
  mobile: "",
  unit_code: "",
};

const { Paragraph } = Typography;

const AddTicket = (props) => {
  const dispatch: any = useDispatch();

  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [runVisible, setRunVisible] = useState(false);
  const ticketTypeApi = useSelector((state: any) => state.tickets.ticketsType);
  const ticketSubTypeApi = []//useSelector((state) => state.tickets.ticketsSubType);
  const propertyNameApi = useSelector((state: any) => state.tickets.propertyname);
  const [subTypes, setSubTypes] = useState([]);
  const organization = useSelector((state: any) => state.tickets.orgData);

  const technicianApi = []
  // useSelector(
  //   (state) => state.tickets.eligibletechnicians,
  // );
  const availableSlots = useSelector(
    (state: any) => state.tickets.availableSlots,
  );

  const fetchPropertyUnitsApi = useSelector(
    (state: any) => state.tickets.fetchPropertyUnits,
  );

  const [ticketsType, setTicketType] = useState([]);
  const [ticketsSubType, setTicketSubType] = useState([]);
  const [propertiesList, setProperties] = useState([]);
  const [uploads, setUploads] = useState([]);

  const [slots, setSlots] = useState([]);

  // const [technician, setTechnician] = useState({});
  const [imageStatus, setImageStatus] = useState(Boolean);

  const { control, handleSubmit, formState: { errors }, reset, setValue } = useForm({
    defaultValues: defaultVal,
  });

  const { dropDownServiceProviders, serviceProviders, properties } = useSelector(
    (state: any) => state.service
  );

  const categories = serviceProviders && serviceProviders[0]

  const propertyRes = useSelector((state: any) => state.tickets.propertyname)

  const usertype = JSON.parse(window.localStorage.getItem("login-response"))?.usertype

  useEffect(() => {
    if (usertype.length > 0) {
      if (!usertype.includes("serviceProvider")) {
        setProperties(properties)
      }
      else {
        setProperties(propertyRes)
      }
    }
  }, [usertype])

  let loggedInPartyId = "";
  // JSON.parse(
  //   window.localStorage.getItem("login-response"),
  // )?.data.userId;

  const handleChange = (e) => {
    setUploads(e.fileList);
  };

  const dummyRequest = (response) => {
    setTimeout(() => {
      response.onSuccess("ok");
    }, 0);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const onBack = () => {
    reset();
    setRunVisible(false);
    // dispatch(resetTechnician());
    props.onCancel();
  };

  useEffect(() => {
    if (usertype.includes("serviceProvider")) {
      propertiesList.length === 0 && dispatch(getPropertyName());
    }
  }, [usertype]);


  const checktype: any = useWatch({ control, name: "tickettype" });
  const checksubtype: any = useWatch({ control, name: "ticketsubtype" });
  const datecheck: any = useWatch({ control, name: "date", defaultValue: "" });
  const propertyCheck: any = useWatch({ control, name: "propertytype" });
  const unit_code: any = useWatch({ control, name: "unit_code" });
  const requesttype: any = useWatch({ control, name: "requesttype" });


  const categoryRoot = useSelector(
    (state: any) => state.configuration.maintenanceTypes
  );


  useEffect(() => {
    dispatch(getMaintenanceTypes());
    dispatch(getServiceProviders());
    // setSubTicketList(filterSubData());
  }, []);


  useEffect(() => {

    setValue("ticketsubtype", "");
    setValue("tickettype", "");

    if (usertype.includes("serviceProvider")) {

      const unit = fetchPropertyUnitsApi?.find(unit => unit.unitId === unit_code.value)

      if (unit?.unit?.usagesType === 'Commercial') {
        ticketTypeApi && setTicketType(ticketTypeApi?.commercialCategories)
        if (ticketTypeApi?.commercialCategories?.length == 0) setTicketType([]);
      }
      if (unit?.unit?.usagesType === 'Residential') {
        ticketTypeApi && setTicketType(ticketTypeApi?.residentialCategories)
        if (ticketTypeApi?.residentialCategories?.length == 0) setTicketType([]);
      }
    } else {
      if (requesttype?.value === 'internal') {
        ticketTypeApi && setTicketType(ticketTypeApi?.internalCategories)
        if (ticketTypeApi?.internalCategories?.length == 0) setTicketType([]);
      }
      if (requesttype?.value === 'regular') {
        const unit = fetchPropertyUnitsApi?.find(unit => unit.unitId === unit_code.value)

        if (unit?.unit?.usagesType === 'Commercial') {
          ticketTypeApi && setTicketType(ticketTypeApi?.commercialCategories)
          if (ticketTypeApi?.commercialCategories?.length == 0) setTicketType([]);
        }
        if (unit?.unit?.usagesType === 'Residential') {
          ticketTypeApi && setTicketType(ticketTypeApi?.residentialCategories)
          if (ticketTypeApi?.residentialCategories?.length == 0) setTicketType([]);
        }
      }
    }
  }, [requesttype, unit_code, ticketTypeApi]);

  // useEffect(() => {
  //   ticketSubTypeApi && setTicketSubType(ticketSubTypeApi);
  // }, [ticketSubTypeApi]);

  useEffect(() => {
    if (checktype?.value) {
      setValue("ticketsubtype", "");
      const subCategories = ticketsType.find(item => item.categoryId === checktype?.value)?.subCategory || ticketsType.find(item => item.categoryId === checktype?.value)?.subCategories
      subCategories?.length > 0 && setSubTypes(subCategories);
    }
  }, [checktype]);

  useEffect(() => {
    if (checktype && moment(datecheck).isValid() && propertyCheck && checksubtype) {
      setValue("timeslot", "");

      const subType = subTypes.find(item => item?.subCategoryId == checksubtype.value);
      const unit = fetchPropertyUnitsApi?.find(unit => unit.unitId === unit_code.value)
      let ticketType;
      if (usertype.includes("serviceProvider")) {
        ticketType = 'regular'
      } else {
        ticketType = requesttype?.value
      }
      // maintenance/getAvailablity?propertyId=propertyId-f9a5a80b-e9bc-4938-be14-1ccb5c9f0f3e
      // &categoryId=categoryId-552f6758-c9db-43e7-ac29-905158859d53
      // &timeInMin=30
      // &startDate=2023-04-07'

      let body = {
        categoryId: checktype.value,
        startDate: dayjs(datecheck).format("YYYY-MM-DD"),
        propertyId: propertyCheck.value,
        timeInMin: subType?.timeInMin,
        unitType: unit?.unit?.usagesType?.toLowerCase(),
        ticketType: ticketType
      };

      dispatch(getAvailablity(body));

    } else {
      setValue("timeslot", "");
      setSlots([]);
    }
  }, [checktype, datecheck, propertyCheck, checksubtype]);


  useEffect(() => {
    if (Object.keys(availableSlots)?.length > 0 && datecheck) {
      let date = dayjs(datecheck).format("YYYY-MM-DD")
      if (availableSlots[date])
        setSlots(availableSlots[date]);
      else {
        setSlots([]); message.error("Unavailable, Please try further dates")
      }
    } else {
      setSlots([]);
    }
  }, [availableSlots]);


  useEffect(() => {
    if (propertyCheck) {

      setValue("ticketsubtype", "");
      setValue("tickettype", "");

      dispatch(getPropertyUnits(propertyCheck.value));

      const orgId = JSON.parse(
        window.localStorage.getItem("login-response")
      )?.orgId;

      if (orgId) {
        const param = {
          serviceProviderId: orgId,
          property: propertyCheck?.value
        }
        dispatch(getTicketType(param));
      }


      if (propertyCheck?.value) {

        const propty = propertiesList?.find(e => e?.propertyId == propertyCheck?.value);
        if (propty && propty?.orgId)
          dispatch(getOrgByID(propty?.orgId));
        else if (orgId && !organization?.maintenancePreferredDateDays) dispatch(getOrgByID(orgId));
      }
    }
  }, [propertyCheck]);


  // useEffect(() => {
  //   if (runVisible) {
  //     technicianApi.length < 1 && message.error("No Technician Available");
  //   } else {
  //     setRunVisible(true);
  //   }
  // }, [technicianApi]);

  const onFinish = async (values) => {
    setLoader(true);
    setSubmited(true);

    const normalizedUploads = [];
    const mediaAsset = [];

    uploads.forEach((e) =>
      normalizedUploads.push({
        ...e,
        modifiedName: uniquiFileName(e?.originFileObj.name),
      }),
    );

    let noupload = false;

    await Promise.all(
      normalizedUploads.map(async (e, index) => {
        let res = null;
        res = await getSingleTicketMediaUpload(e.modifiedName, e.originFileObj);

        const doc = res?.result;
        if (doc)
          doc?.documentId && mediaAsset.push({ "documentId": doc?.documentId, "type": doc?.document?.type && doc?.document?.type?.toLowerCase() });
        if (res == false) {
          noupload = true;
        }
      }),
    );

    if (noupload) { //|| mediaAsset.length === 0
      setLoader(false);
      setisError(true);
      setErrorMessage("Media not Uploaded. Please Try again");
      return false;
    } else {

      let ticketType;
      if (usertype.includes("serviceProvider")) {
        ticketType = 'regular' //regular
      } else {
        ticketType = requesttype?.value //
      }
      const subType = subTypes.find(item => item?.subCategoryId == values?.ticketsubtype?.value);
      // let selectedCategory = usertype.includes("serviceProvider") ? categoryRoot : ticketsType
      // const ticketName = selectedCategory.find(item => item?.categoryId == values?.tickettype?.value);
      const unit = fetchPropertyUnitsApi?.find(unit => unit.unitId === unit_code.value)

      const mainCategory = ticketsType?.find(item => item?.categoryId === values?.tickettype?.value)
     
      const [startTime, endTime] = values?.timeslot?.value.split("-");

      const postJson = {
        "ticketType": ticketType,
        "unitType": unit?.unit?.usagesType?.toLowerCase(),
        "preferredDate": dayjs(values.date).format("YYYY-MM-DD"), //convert date to string
        "unitId": values.unit_code.value,
        "recurrenceId": false,
        "preferredTimeStart": startTime,
        "preferredTimeEnd": endTime,
        "category": {
          "categoryId": values.tickettype.value,
          // "name": ticketName?.categoryName || mainCategory?.name || mainCategory?.categoryName,
          "name": mainCategory?.name || mainCategory?.categoryName,
        },
        "subCategory": {
          "subCategoryId": values.ticketsubtype.value,
          "name": subType?.name,
        },
        "documents": mediaAsset,
        "parentCaseId": "",
        "timeInMin": subType?.timeInMin,
        "ticketUserType": "ServiceProvider"
      };

      if (values?.desc)
        postJson["description"] = values?.desc;
      if (values?.mobile)
        postJson["alternateNumber"] = values?.mobile;

      const url = `${maintenanceService}/maintenance/portalCreateTicket`;

      try {
        await axios.post(url, postJson);
        setTimeout(() => {
          reset();
          setLoader(false);
          message.success("Ticket added successfully");
          setSubmited(false);
          props.onSubmit();
        }, 3000);
      } catch (e) {
        setLoader(false);
        setisError(true);
        const { response } = e;
        setErrorMessage(response?.data?.error);
      }
    }
  };

  function disabledDate(current) {
    let today = dayjs().format("YYYY-MM-DD");
    return (
      (current && dayjs(current).format("YYYY-MM-DD") < today)
      || dayjs(today).add(organization?.maintenancePreferredDateDays || defaultMaintenancePreferredDateDays, "day").isBefore(current));
  }


  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {/* <Button
        className="p-0 h-auto w-auto ag-default mb-1 modal_button"
        type="link"
        size="large"
        onClick={onBack}
        icon={<CloseOutlined />}
      /> */}
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
              {/* <Paragraph className="ag-default fontWeight600 mb-0">Loading</Paragraph> */}
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
          <Row gutter={24}>
            <Col span={12}>
              <ReactSelectField
                isRequired={true}
                fieldname="propertytype"
                label="Property"
                control={control}
                iProps={{ placeholder: "Select Property" }}
                rules={{
                  required: "Please select Property",
                }}
                selectOption={
                  propertiesList?.length > 0 &&
                  propertiesList?.map((item) => ({
                    value: item?.propertyId || item?.value,
                    label: item?.buildingName || item?.label,
                  }))
                }
                validate={errors.propertytype && "error"}
                validMessage={
                  errors.propertytype && errors.propertytype.message
                }
              />
            </Col>
            <Col span={12}>
              <ReactSelectField
                isRequired={true}
                fieldname="unit_code"
                label="Unit"
                control={control}
                iProps={{ placeholder: "Select unit code", isDisabled: !fetchPropertyUnitsApi?.length, }}
                rules={{
                  required: "Please select unit code",
                }}
                selectOption={_.map(fetchPropertyUnitsApi, (item) => ({
                  value: item?.unitId,
                  label: item?.unit?.unitCode,
                }))}
                validate={errors.unit_code && "error"}
                validMessage={errors.unit_code && errors.unit_code.message}
              />
            </Col>
          </Row>
          {
            !usertype.includes("serviceProvider") && (
              <Row gutter={24}>
                <Col span={12}>
                  <ReactSelectField
                    isRequired={true}
                    fieldname="requesttype"
                    label="Request Type"
                    control={control}
                    rules={{ required: "Please select Request Type" }}
                    selectOption={
                      [
                        {
                          value: 'internal',
                          label: 'Internal',
                        },
                        {
                          value: 'regular',
                          label: 'Regular',
                        }
                      ]
                    }
                    iProps={{ placeholder: "Select type", isDisabled: !unit_code?.value }}
                    validate={errors.requesttype && "error"}
                    validMessage={errors.requesttype && errors.requesttype.message}
                    initValue=""
                  />
                </Col>
              </Row>
            )
          }
          <Row gutter={24}>
            <Col span={12}>
              <ReactSelectField
                isRequired={true}
                fieldname="tickettype"
                label="Maintenance Type"
                control={control}
                rules={{ required: "Please select Maintenance Type" }}
                selectOption={
                  ticketsType && ticketsType?.map((item) => ({
                    value: item?.categoryId,
                    label: item?.name || item?.categoryName,
                  })) || []
                }
                iProps={{ placeholder: "Select type", isDisabled: !ticketsType?.length }}
                validate={errors.tickettype && "error"}
                validMessage={errors.tickettype && errors.tickettype.message}
                initValue=""
              />
            </Col>
            <Col span={12}>
              <ReactSelectField
                isRequired={true}
                fieldname="ticketsubtype"
                label="Sub-Type"
                control={control}
                iProps={{
                  placeholder: "Select Sub-Type",
                  isDisabled: !subTypes.length,
                }}
                rules={{
                  required: "Please select ticket sub-type",
                }}
                initValue=""
                selectOption={
                  subTypes?.map((item) => ({
                    value: item?.subCategoryId,
                    label: item?.name,
                  }))
                }
                validate={errors.ticketsubtype && "error"}
                validMessage={
                  errors.ticketsubtype && errors.ticketsubtype.message
                }
              />
            </Col>
            <Col span={12}>
              <DateField
                isRequired={true}
                fieldname="date"
                label="Visit Date"
                control={control}
                iProps={{
                  placeholder: "DD-MM-YYYY",
                  format: "DD-MM-YYYY",
                  disabledDate: disabledDate,
                }}
                rules={{
                  required: "Please enter date",
                  setValueAs: (value) => moment(value).format("YYYY-MM-DD"),
                }}
                initValue={null}
                validate={errors.date && "error"}
                validMessage={errors.date && errors.date.message}
              />
            </Col>
            <Col span={12}>
              <ReactSelectField
                isRequired={true}
                fieldname="timeslot"
                label={`Time Slot ${slots.length > 0 ? "(available)" : ""}`}
                control={control}
                iProps={{ placeholder: "Select time" }}
                rules={{
                  required: "Please select time",
                }}
                selectOption={_.map(slots, (item) => ({
                  value: item,
                  label: item,
                }))}
                validate={errors.timeslot && "error"}
                validMessage={errors.timeslot && errors.timeslot.message}
              />
            </Col>
            
            <Col span={12}>
              <InputField
                fieldname="desc"
                label="Additional Details"
                control={control}
                iProps={{ placeholder: "Enter Additional Details" }}
                initValue=""
              />
            </Col>
            <Col span={12}>
              <InputField
                label="Contact Number"
                fieldname="mobile"
                control={control}
                initValue=""
                iProps={{ addonBefore: "+971", }}
                // isRequired={true}
                validate={errors.mobile && "error"}
                validMessage={
                  errors.mobile && errors.mobile.message
                }
                // disabled={disableAll}
                rules={{
                  minLength: {
                    value: 9,
                    message: "Please enter valid contact number, 9 digits required",
                  },
                  maxLength: {
                    value: 9,
                    message: "Please enter valid contact number, 9 digits required",
                  },
                  // required: "Please enter Mobile No.",
                }}
              />
            </Col>

            <Col span={24}>
              <Form.Item
                className="mb-0"
                validateStatus={errors.media && "error"}
                help={errors.media && errors.media.message}
              >
                <Controller
                  name="media"
                  control={control}
                  // rules={{ required: "Please select attachment" }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Upload
                        listType="picture-card"
                        // fileList={value?.fileList}
                        onChange={(e) => {
                          onChange(e);
                          handleChange(e);
                        }}
                        multiple={true}
                        accept="image/*, video/*"
                        customRequest={dummyRequest}
                        onPreview={onPreview}
                        className="custom-upload mb-2"
                      >
                        <Space className="ag-success fontSize24" align="center">
                          <PlusCircleOutlined />
                          <Text className="ag-success RegularFont">Add Photo/Video</Text>
                        </Space>
                      </Upload>
                    );
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24} justify="space-between">
            <Col>
              <Button className="ag-gray-button-outline" onClick={onBack}>
                Back
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button" htmlType="submit">
                Add
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    setSubmited(false);
                    setisError(false);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
    </Form>
  );
};

export default AddTicket;