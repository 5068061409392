import React, { useState, useEffect } from "react";
import DashboardLayout from "../../molecules/DashboardLayout";
import {
  Row,
  Col,
  Pagination,
  Card,
  Button,
  Form,
  Space,
  Select,
  Input,
  MenuProps,
  message,
} from "antd";
import SectionHeader from "../../molecules/SectionHeader";
import Grid from "../../atoms/Grid";
import { useDispatch, useSelector } from "react-redux";
import { emptyTenants, getTenants, sendInvite } from "./ducks/actions";
import { RightArrow } from "../../atoms/Icons";
import { useNavigate } from "react-router-dom";
import { CloseOutlined, FilterOutlined, MailOutlined, SearchOutlined } from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import { Popup } from "../../atoms/Popup";
import AddLease from "./components/AddLease/AddLease";
import Modal from "./components/Modal/Modal";
import AddBilling from "./components/AddBilling/AddBilling";
import AddPayment from "./components/AddPayment/AddPayment";
import { BsBuilding } from "react-icons/bs";
import e from "express";
import { getPropertiesDrop } from "../Properties/ducks/actions";
import { emptyPropertyUnits, getPropertyUnits } from "../Properties/PropertyDetail/ducks/actions";
import { useSearchHistory } from "../../../utils/filterHelper";
import { setFilterTenant } from "../App/SearchFilters/ducks/actions";

const gridProps = {
  sortable: true,
  filter: true,
  resizable: true,
  minWidth: 200,
};

const limit = 50;

const searchKeys = [{ value: "name", label: "Name", default: true },
{ value: "phone", label: "Phone" },
{ value: "email", label: "Email" }];

export default (props) => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const id = location.pathname?.split("/")[2];
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const tenantApi = useSelector((state: any) => state.tenants.tenantList);
  const properties = useSelector((state: any) => state.dashboard.propertiesDrop);
  const units = useSelector((state: any) => state.propertyDetails.unitsList);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [updateValue, setUpdateValue] = useState({});


  const Filter = useSelector((state: any) => state.filters.tenantsData);

  const { searchHistory, addToSearchHistory } = useSearchHistory(setFilterTenant);
  const [searchBy, setSearchBy] = useState(Object.keys(Filter)[0] ?? "name")
  const [search, setSearch] = useState(Filter[Object.keys(Filter)[0]] ?? "");


  const [visible, setVisible] = useState(false);
  const [singleInvite, setSingleInvite] = useState(null);
  const [trigger, setTrigger] = useState(0);
  const [selectedTenants, setSelectedTenants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deselectAll, setDeselectAll] = useState(false);

  const MenuItems: MenuProps["items"] = [
    {
      label: "Active Tenancy",
      key: 0,
    },
   
    {
      label: "Payment Schedule",
      key: 2,
    },
  ];


  const [propertyOptions, setPropertyOptions] = useState([
    {
      name: "property",
      label: "property",
      options: [],
      search: true
    },
    {
      name: "unit",
      label: "Unit",
      options: [],
      search: true
    }
  ]);


  useEffect(() => {
    if (properties.length === 0) {
      dispatch(getPropertiesDrop());
    } else {
      setPropertyOptions([
        {
          name: "property",
          label: "property",
          options: properties,
          search: true
        },
        {
          name: "unit",
          label: "Unit",
          options: [],
          search: true
        }
      ]);
    }
  }, [properties]);


  useEffect(() => {
    if (selectedProperty?.propertyId)
      dispatch(getPropertyUnits(selectedProperty?.propertyId));

    return () => dispatch(emptyPropertyUnits());
  }, [selectedProperty]);

  useEffect(() => {
    if (units?.length > 0) {
      const unitOptions = units.map((item, index) => ({
        value: index,
        label: item.unit?.unitCode,
      }));
      setPropertyOptions([
        {
          name: "property",
          label: "property",
          options: properties,
          search: true
        },
        {
          name: "unit",
          label: "Unit",
          options: unitOptions,
          search: true
        }
      ]);
    }


  }, [units]);


  function viewRender(record) {
    return (
      record.data._id && (
        <>
          <Button
            icon={<RightArrow />}
            onClick={() => navigate(`/tenants-detail/${record.data._id}`)}
            type="link"
            className="ag-primary"
          />
          {!record.data.isSignUp && (
            <Button
              icon={<MailOutlined />}
              onClick={() => {
                  setVisible(true);
                  setTrigger(4);
                  setSingleInvite(record.data)
                }
              }
              type="link"
              className="ag-primary"
              disabled={record.data.isSignUp}
            />
          )}
        </>
      )
    );
  }

  const onDropClick: MenuProps["onClick"] = ({ key }) => {
    setVisible(true);
    setTrigger(+key);
  };

  const tenantCol = [
    {
      headerName: "Tenant Name",
      field: "name",
      checkboxSelection: (params) => {
        return !params.data.isSignUp;
      },
      headerCheckboxSelection: true,
      minWidth: 380
    },
    {
      headerName: "Mobile Number",
      field: "mobile",
      minWidth: 280
    },
    {
      headerName: "Email",
      field: "email",
      minWidth: 380
    },
    {
      headerName: "Registered",
      field: "isSignUp",
      minWidth: 180,
      cellRenderer: (params) => (params.data.isSignUp ? "Yes" : "No"),
    },
    {
      headerName: "View Details",
      cellRendererFramework: viewRender,
      cellStyle: { textAlign: "center" },
      filter: false,
      minWidth: 180
    },
  ];

  const onAdd = () => {
    dispatch(getTenants(id, limit, currentPage, "", ""));
  };

  const onSelectionChanged = (event) => {
    const tenants = event.api.getSelectedNodes();
    if (tenants.length > 0) {
      const tenantsIds = tenants.filter((t) => !t.data.isSignUp).map((t) => t.data.email);
      setSelectedTenants(tenantsIds);
    } else {
      setSelectedTenants([]);
    }
  };

  const onSendInvite = async ({ email }) => {
    setVisible(false);
    let successMessage = "";
    setLoading(true);
    let payload = {
      emails: [],
      usertype: "tenant",
      inviteAll: false,
    };

    if (email) {
      payload.emails.push(email);
      successMessage = "Invite sent to tenant successfully";
    } else if (selectedTenants.length > 0) {
      payload.emails = selectedTenants;
      successMessage = "Invite sent to selected tenants successfully";
    } else {
      payload.inviteAll = true;
      successMessage = "Invite sent to all tenants successfully";
    }

    const status = await sendInvite(payload, successMessage);
    setLoading(false);
    if (status === 200) {
      message.success(successMessage);
      setDeselectAll(true);
      //Can be placed at better re-rendering location
      setTimeout(() => {
        setDeselectAll(false);
      }, 1500);
    }
  };



  const handlePagination = (pg) => {
    setCurrentPage(pg);
    dispatch(getTenants(id, limit, pg, "", ""));
  };



  useEffect(() => {
    if (tenantApi?.data) {
      let temp = [];
      tenantApi?.data?.map((item) => {
        temp.push({
          _id: item.userId, 
          name: item.name, 
          mobile: item.mobile, 
          email: item.email,
          isSignUp: item.isSignUp,
        });
      });
      setData(temp);
    } else {
      setData([]);
    }
  }, [tenantApi]);





  useEffect(() => {
    setCurrentPage(1)
    addToSearchHistory(searchBy, search);  //adding search history and filter store
    let filter = {}
    if (selectedProperty?.propertyId && selectedUnit?.unitId) {
      filter = { propertyId: selectedProperty?.propertyId, unitId: selectedUnit?.unitId }
    } else if (selectedProperty?.propertyId) {
      filter = { propertyId: selectedProperty?.propertyId }
    } else if (selectedUnit?.unitId) {
      filter = { unitId: selectedUnit?.unitId }
    }
    setTimeout(() => {
      dispatch(getTenants(id, limit, 1, search, searchBy, filter));
    }, 500);
  }, [search, searchBy,selectedProperty, selectedUnit]);

  useEffect(() => {

    let key = Object.keys(searchHistory)[0];
    if (searchKeys.length > 0)
      searchKeys.map((item) => {
        if (key == item.value)
          item["default"] = true;
        else item["default"] = false;
        return item;
      })
  }, [searchHistory]);


  function onTopSearch(e) {
    if (e?.length === 0)
      setSearch("");
    else if (e?.length < 2)
      message.error("Please enter atleast two characters");
    else
      setSearch(e);
  }

  function onTopSearchBy(e) {
    setSearchBy(e?.value);
  }

  function handlePropertyChange(property, label) {
    console.log(property, label);

    let unit = selectedUnit;
    if (label === "property" && property?.value) {
      setUpdateValue({ label: 'unit', value: null });
      setSelectedUnit(null);
      setSelectedProperty({
        propertyId: property?.value,
        property_name: property?.label,
      });
    } else if (label === "unit" && property?.value !== null) {
      unit = units[property?.value];
      setSelectedUnit(units[property?.value]);
    }
  }


  const popup = [
    {
      title: (
        <Title level={3} className="m-0">
          Add New Active Tenancy
        </Title>
      ),
      content: (
        <AddLease
          onSubmit={onAdd}
       
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="m-0">
          Add New Billing Schedule
        </Title>
      ),
      content: (
      
        <AddBilling
        
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="m-0">
          Add New Payment Schedule
        </Title>
      ),
      content: (
       
        <AddPayment
        
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="m-0">
          Send Invite ?
        </Title>
      ),
      content: (
        <Modal
          title='Are you sure you would like to send invitations to the selected tenants ?'
          onSubmit={() => onSendInvite({email: ''})}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="m-0">
          Send Invite ?
        </Title>
      ),
      content: (
        <Modal
          title='Are you sure you would like to send invitation to the selected tenant ?'
          onSubmit={() => onSendInvite({email: singleInvite.email})}
        />
      ),
      width: 650,
    },
  ];

  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => setVisible(false),
  };

  return (
    <>
      <DashboardLayout load={loading}>
        <Row gutter={[32, 32]}>
          <Col span={24}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <SectionHeader
                  heading="Tenants"
                  noBorder={true}
                  total={tenantApi?.totalCount || 0}
                  dropNewAction={onDropClick}
                  dropItems={MenuItems}
                  dropTrigger={["click"]}

                  search={(e) => onTopSearch(e)}
                  searchBy={(e) => onTopSearchBy(e)}
                  searchOptions={searchKeys}
                  defaultSearch={search}

                  viewOption={propertyOptions}
                  viewOptionIcon={<FilterOutlined />}
                  viewOptionLabel="Filter by : property/unit"
                  justifiedItems={true}
                  updateValue={updateValue}
                  justifyEnd={true}
                  handleView={handlePropertyChange}
             
                />
              </Col>
              <Col span={24}>
                <Pagination
                  pageSize={limit}
                  className="ag-property-text-pagination"
                  total={tenantApi?.totalCount || 0}
                  showTotal={(total, range) =>
                    `Showing ${range[0]}-${range[1]} of ${total} Tenants`
                  }
                  defaultPageSize={tenantApi?.perPage}
                  defaultCurrent={1}
                  showSizeChanger={false}
                  current={currentPage}
                  onChange={(e) => handlePagination(e)}
                />
              </Col>
              <Col span={24} className="managers_tenants_detail">
                <Card
                  bordered={false}
                  className="ag-nospace-body"
            
                >
                  <div className="send-invite">
                    <Button
                      type="primary"
                      icon={<MailOutlined />}
                      onClick={ () => {
                          setVisible(true);
                          setTrigger(3);
                        }
                      }
                      disabled={!(selectedTenants.length > 0)}
                    >
                      {selectedTenants.length > 0
                        ? "Invite Selected Tentants"
                        : "Invite All"}
                    </Button>
                  </div>
                  <Grid
                    data={data}
                    columns={tenantCol}
                    pagination={false}
                    defaultSettings={gridProps}
                    noRowlabel="Tenants"
                    rowSelection="multiple"
                    onSelectionChanged={onSelectionChanged}
                    isRowSelectable={false}
                    fullPage={true}
                    deselectAll={deselectAll}
                  />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </DashboardLayout>
      <Popup destroyOnClose={true} {...popupProps} />
    </>
  );
};