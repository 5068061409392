import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Typography, message, Space, Spin } from "antd";
import { useForm, useFieldArray, useWatch } from "react-hook-form";
import {
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { InputField, ReactSelectField, DateField } from "../../../../../atoms/FormElement";
import { propertyService } from "../../../../../../configs/constants";
import axios from "../../../../../../utils/axiosInceptor";
import { getPropertiesDrop } from "../../../../Properties/ducks/actions";
import {
  getConfigData,
} from "../../ducks/actions";

const { Paragraph } = Typography;

var _ = require("lodash");

const defaultValues = {
  name: "",
  email: "",
  mobile: "",
  organisation: "",
  businessUnit: [],
  roles: [],
  status: 'enable'

};

const countries = [
  {'label': 'United Arab Emirates', 'value': 'United Arab Emirates'},
  {'label': 'Saudi Arabia', 'value': 'Saudi Arabia'},
  {'label': 'Qatar', 'value': 'Qatar'},
  {'label': 'Bahrain', 'value': 'Bahrain'},
  {'label': 'Kuwait', 'value': 'Kuwait'},
  {'label': 'Oman', 'value': 'Oman'},
  {'label': 'Pakistan', 'value': 'Pakistan'},
  {'label': 'United Kingdom', 'value': 'United Kingdom'},
  {'label': 'United States', 'value': 'United States'},
  {'label': 'India', 'value': 'India'},
  {'label': 'Australia', 'value': 'Australia'},
  {'label': 'Canada', 'value': 'Canada'},
  {'label': 'Greece', 'value': 'Greece'},
  {'label': 'France', 'value': 'France'},
  {'label': 'Germany', 'value': 'Germany'},
  {'label': 'Italy', 'value': 'Italy'}
]

const EditTenant = (props) => {

  const { onSubmit, editType, onCancel, info, docs, userDetail, tenantDetail } = props;

  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [emiratesList, setEmiratesList] = useState([]);
  const [nationality, setNationality] = useState([]);
  
  const {
    control, formState: { errors },
    reset,
    handleSubmit,
    setValue,
    setError
  } = useForm(); //{ defaultValues }

  const { fields, append, remove } = useFieldArray({
    control,
    name: "user",
  });

  useEffect(() => {
    ;(async () => {
      const configKeys = ['nationalityList', 'emiratesList'];
      const configData = await getConfigData(configKeys.join(","))

      let nationalities, emiratesList;

      configData.forEach(data => {
        if(data['nationalityList']) {
          nationalities = data['nationalityList']
          nationalities = _.map(nationalities,
                    (item) => ({
                      value: item,
                      label: item,
                    }),
                  )
          setNationality(nationalities);
        }

        if(data['emiratesList']) {
          emiratesList = data['emiratesList']
          emiratesList = _.map(emiratesList,
                    (item) => ({
                      value: item,
                      label: item,
                    }),
                  )
          setEmiratesList(emiratesList);
        }

      })
    })()
  }, [])

  useEffect(() => {
 
    let updateArray = info.concat(docs);
    
    for (const [key, value] of Object.entries(tenantDetail || {}) ) {
      value && updateArray.push({key: key, value: value})
    }
    
    updateArray.forEach(ele => {
      let keyValue = ele.key.replace("Reg.", "").split(" ").join("")
      let keyNew = ele.value === 'N/A' ? '' : ele.value
      
      if(keyValue === 'Emirates' || keyValue === 'Nationality' || keyValue === 'country') {
        
        setValue(
          keyValue,
          keyNew && {
            value: keyNew,
            label: keyNew,
          } || "",
          { shouldValidate: true, }
        );
      } else if(keyValue === 'PassportExpiryDate' || keyValue === 'VisaExpiryDate' || keyValue === 'TradeLicenseExpiryDate' || keyValue === 'EmiratesIDExpiryDate') {
        
        setValue(
          keyValue,
          keyNew && dayjs(keyNew, "DD-MM-YYYY"),
          { shouldValidate: true, }
        );
      } else {
        setValue(keyValue, keyNew, { shouldValidate: true, });
      }
    })

    if (editType === 'Tenant Details' || editType === 'Documents Summary') {
    } else {
      reset();
    }
  }, [props]);

  const onFinish = async (values) => {

    const emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const numberReg = /^[0-9]+$/;
    const numberCharacterReg = /^[A-Za-z0-9]*$/;

    if(editType === 'Tenant Details' && !emailPattern.test(values.Email)) {
      setError('Email', {type: 'manual', message: 'Please enter valid Email' })
      return
    }

    if(editType === 'Documents Summary' && !numberCharacterReg.test(values.PassportNumber)) {
      setError('PassportNumber', {type: 'manual', message: 'Please enter valid Passport Number' })
      return
    }

    if(editType === 'Documents Summary' && !numberCharacterReg.test(values.VisaNumber)) {
      setError('VisaNumber', {type: 'manual', message: 'Please enter valid Visa Number' })
      return
    }

    if(editType === 'Documents Summary' && !numberReg.test(values.EmiratesIDNumber)) {
      setError('EmiratesIDNumber', {type: 'manual', message: 'Please enter valid Emirates Number' })
      return
    }

    if(values.numberOfOcupants && !(/^\d+$/.test(values.numberOfOcupants))) {
      setError('numberOfOcupants', {type: 'manual', message: 'Please enter valid number of ocupants' })
      return
    }

    props.onSubmit();
    setLoader(true);
    setSubmited(true);

    let postJson = {
      "name": values.FullName,
      "email": values.Email,
      "countryCode" : "+971",
      "maritalStatus": values.maritalStatus,
      "numberOfOcupants": values.numberOfOcupants,
      "poBox": values.poBox,
      "tenantNameAR" : values.tenantNameAR,
      "mobile" : values.PhoneNumber,
      "nationality": values.Nationality.value,
      "emirates": values.Emirates.value,
      "country": values.country.value,
      "address": values.address,
      "passport": values.PassportNumber,
      "passportExpiry": values.PassportExpiryDate && dayjs(values.PassportExpiryDate).format("YYYY-MM-DD"),
      "emiratesId" : values.EmiratesIDNumber,
      "emirateIdExpiry": values.EmiratesIDExpiryDate,
      "visaNumber" : values.VisaNumber,
      "visaExpiry": values.VisaExpiryDate && dayjs(values.VisaExpiryDate).format("YYYY-MM-DD"),
      "tradeLicense": values.TradeLicenseNumber,
      "tradeLicenseExpiry": values.TradeLicenseExpiryDate && dayjs(values.TradeLicenseExpiryDate).format("YYYY-MM-DD"),
      "leaseUpdate" : "yes"
    };
    

    const url = `${propertyService}/tenant/user/${userDetail.userId}`;

    axios.patch(url, postJson).then((response) => {
      let { data } = response;
      setLoader(false);
      setSubmited(false);
      reset();
      message.success(data?.message);
      props.onSubmit();
    }).catch((error) => {
      message.error(error.response.data.error)
      setLoader(false);
      setSubmited(false);
      reset();
    })
    
  };

  return (
    <>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        {loader && !isError && (
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Space
                size={24}
                className="w-100 text-center"
                direction="vertical"
              >
                <Spin size="large" className="ag-fontSize32" />
              </Space>
            </Col>
          </Row>
        )}
        {!submited ? (
          <>
          {
            editType === 'Tenant Details' && (
              <Row gutter={24}>
                <Col span={12}>
                  <InputField
                    fieldname="FullName"
                    label="Full Name"
                    control={control}
                    rules={{
                      required: "Please enter full name",
                    }}
                    iProps={{ placeholder: "Enter Full Name" }}
                    initValue=""
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    fieldname="Email"
                    label="Email"
                    control={control}
                    rules={{
                      pattern: {
                        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                        message: "Enter valid Email Address",
                      }
                    }}
                    validate={errors.Email && "error"}
                    validMessage={errors.Email && errors.Email.message}
                    iProps={{ placeholder: "user@gmail.com" }}
                    disabled={props.editMode}
                    initValue=""
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    label="Phone"
                    fieldname="PhoneNumber"
                    control={control}
                    initValue=""
                    rules={{
                      minLength: {
                        value: 9,
                        message: "Please enter valid mobile number, 9 digits required",
                      },
                      maxLength: {
                        value: 9,
                        message: "Please enter valid mobile number, 9 digits required",
                      },
                      setValueAs: (value) => `${value}`,
                    }}
                    disabled={props.editMode}
                    iProps={{
                      placeholder: "Add Mobile Number",
                      addonBefore: "+971",
                      type: "number",
                      className: "apperance",
                    }}
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    fieldname="address"
                    label="Address"
                    control={control}
                    iProps={{ placeholder: "Enter Address" }}
                    initValue=""
                  />
                </Col>
                <Col span={12}>
                  <ReactSelectField
                    label="Country"
                    fieldname="country"
                    control={control}
                    initValue=""
                    iProps={{ placeholder: "Select Country"}}
                    selectOption={nationality}
                  />
                </Col>
                <Col span={12}>
                  <ReactSelectField
                    label="Nationality"
                    fieldname="Nationality"
                    control={control}
                    initValue=""
                    iProps={{ placeholder: "Select Nationality"}}
                    selectOption={nationality}
                  />
                </Col>
                <Col span={12}>
                  <ReactSelectField
                    label="Emirates"
                    fieldname="Emirates"
                    control={control}
                    initValue=""
                    iProps={{ placeholder: "Select Emirates"}}
                    selectOption={emiratesList}
                  />
                </Col>
              </Row>
            )
          }

          {
            editType === 'Documents Summary' && (
              <Row gutter={24}>
                <Col span={12}>
                  <InputField
                    fieldname="PassportNumber"
                    label="Passport Number"
                    control={control}
                    iProps={{ placeholder: "Enter Passport Number" }}
                    initValue=""
                    validate={errors.PassportNumber && "error"}
                    validMessage={errors.PassportNumber && errors.PassportNumber.message}
                  />
                </Col>
                <Col span={12}>
                  <DateField
                    label="Passport Expiry Date"
                    fieldname="PassportExpiryDate"
                    control={control}
                    initValue=""
                    iProps={{
                      placeholder: "DD-MM-YYYY",
                      format: "DD-MM-YYYY",
                    }}
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    fieldname="VisaNumber"
                    label="Visa Number"
                    control={control}
                    iProps={{ placeholder: "Enter Visa Number" }}
                    initValue=""
                    validate={errors.VisaNumber && "error"}
                    validMessage={errors.VisaNumber && errors.VisaNumber.message}
                  />
                </Col>
                <Col span={12}>
                  <DateField
                    label="Visa Expiry Date"
                    fieldname="VisaExpiryDate"
                    control={control}
                    initValue=""
                    iProps={{
                      placeholder: "DD-MM-YYYY",
                      format: "DD-MM-YYYY",
                    }}
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    fieldname="TradeLicenseNumber"
                    label="Trade License Number"
                    control={control}
                    iProps={{ placeholder: "Enter Trade License Number" }}
                    initValue=""
                    validate={errors.TradeLicenseNumber && "error"}
                    validMessage={errors.TradeLicenseNumber && errors.TradeLicenseNumber.message}
                  />
                </Col>
                <Col span={12}>
                  <DateField
                    label="Trade License ExpiryDate"
                    fieldname="TradeLicenseExpiryDate"
                    control={control}
                    initValue=""
                    iProps={{
                      placeholder: "DD-MM-YYYY",
                      format: "DD-MM-YYYY",
                    }}
                  />
                </Col>
                <Col span={12}>
                  <InputField
                    fieldname="EmiratesIDNumber"
                    label="Emirates Id Number"
                    control={control}
                    iProps={{ placeholder: "Enter Emirates Id Number" }}
                    validate={errors.EmiratesIDNumber && "error"}
                    validMessage={errors.EmiratesIDNumber && errors.EmiratesIDNumber.message}
                  />
                </Col>
                <Col span={12}>
                  <DateField
                    label="Emirates Expiry Date"
                    fieldname="EmiratesIDExpiryDate"
                    control={control}
                    initValue=""
                    iProps={{
                      placeholder: "DD-MM-YYYY",
                      format: "DD-MM-YYYY",
                    }}
                  />
                </Col>
              </Row>
            )
          }

            <Row gutter={24} justify="space-between">
              <Col>
                <Button
                  className="ag-gray-button-outline theme_grey_btn"
                  htmlType="button"
                  onClick={() => {
                    props.onCancel();
                  }}
                >
                  Back
                </Button>
              </Col>
              <Col>
                <Button className="ag-green-button theme_btn" htmlType="submit">
                  {"Update"}
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          isError && (
            <>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Space
                    size={24}
                    className="w-100 text-center"
                    direction="vertical"
                  >
                    <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                    <Paragraph className="ag-error fontWeight600 mb-0 sentanceCase">
                      {errorMessage}
                    </Paragraph>
                  </Space>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Button
                    className="ag-gray-button-outline theme_grey_btn"
                    htmlType="button"
                    onClick={() => {
                      reset();
                      setSubmited(false);
                      setisError(false);
                    }}
                  >
                    Back
                  </Button>
                </Col>
              </Row>
            </>
          )
        )}
      </Form>
    </>
  );
};

export default EditTenant;
