import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Upload,
  message,
  Space,
  Form,
} from "antd";
import {
  FileTextOutlined,
  UploadOutlined,
} from "@ant-design/icons";


import { useForm } from "react-hook-form";
import { InputField } from "../../../../atoms/FormElement";
import { getPresignedImage, uploadDocumentMedia } from "../../../../../utils/media";
import { useSelector } from "react-redux";
import { getBookingDetail } from "../../ducks/actions";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { leaseService } from "../../../../../configs/constants";
import axios from "axios";
import { Loading } from ".";

let initialState = {
  visible: false,
};


export default (props) => {
  const {
    data,
  } = props;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // states
  const [{ visible }, setState] = useState(initialState);

  const updateState = (state: {}) =>
    setState((prevState: any) => ({ ...prevState, ...state }));

  const [pdfFile, setPdfFile] = useState(null);

  const { configData, bookingDetails } = useSelector(
    (state: any) => state.newBooking
  );

  const dispatch: any = useDispatch();
  const { leaseId = "" } = useParams();

  const [pdfData, setPdfData] = useState(null);

  const [previewFile, setPreviewFile] = useState(null);

  const getUrl = async (data) => {
    const url = await getPresignedImage([data]);
    setPdfData(url[0]);
  }

  useEffect(() => {
    data?.documentId && getUrl(data?.documentId)
  }, [data]);

  const onSubmit = async (val: any) => {
    const file = pdfFile?.[0];
    // message.loading("");
    updateState({ visible: true });
    const payload = {
      type: "pdf",
      documentName: val?.documentName,
    };
    if (data?._id) payload['_id'] = data?._id;
    if (data?.documentId) payload['documentId'] = data?.documentId;
    if (file) {
      const { data } = await uploadDocumentMedia({ file_asset: file?.originFileObj });
      if (data?.result?.documentId) {
        payload['documentId'] = data?.result?.documentId;
        await updateDocumentAPI(payload);
        updateState({ visible: false });
      } else updateState({ visible: false });
    } else {
      await updateDocumentAPI(payload);
      updateState({ visible: false });
    }
    props.parentState({ modalPayload: { open: false } })
  };

  const updateDocumentAPI = async (payload) => {
    try {
      const url = `${leaseService}/leasing/updatePortalDocument/${leaseId}`;
      await axios.post(url, payload);
      message.success("Successfully updated document");
      await dispatch(getBookingDetail(leaseId));
    } catch (e) {
      const { response } = e;
      message.error(response?.data?.error);
    }
  };

  const setPreviewUrl = (file) => {
    if (file?.originFileObj) {
      let url = URL.createObjectURL(file.originFileObj)
      setPreviewFile(url);
    }
  }

  useEffect(() => {
    if (pdfFile && pdfFile?.length > 0)
      setPreviewUrl(pdfFile[0])
  }, [pdfFile]);

  const fileProps = {
    className: "file-upload",
    name: "file",
    accept: '.pdf',
    multiple: false,
    fileList: pdfFile?.length > 0 ? pdfFile : [],
    onChange(info) {
      const { status } = info.file;
      const { fileList } = info;
      if (fileList && fileList?.length > 0) {
        // const files = fileList.map(file => ({ ...file, preview: URL.createObjectURL(file.originFileObj) })) ?? [];
        setPdfFile([fileList[0]]);
      }
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} Please upload pdf file`);
      }
    },
    onRemove: () => {
      setPdfFile(null);
    },
    beforeUpload: (file) => {
      setPdfFile([file]);
      return false;
    },
    pdfFile,
  };

  return (
    <Form
      layout="vertical"
      name="bookingform"
      scrollToFirstError
      className="bf-main"
      onFinish={handleSubmit(onSubmit)}
    >
      <Loading visible={visible} />
      <Row
        gutter={24}
        // key={item.id}
        className="h-auto"
        style={{ marginBottom: "0.6rem", }}
        justify="space-between"
        align="middle"
      >
        <Col
          span={12}
        // style={{ display: "flex", justifyContent: "end", padding: 0 }}
        >
          <Upload {...fileProps}>
            <div style={{ height: "330px", width: "238px", background: "#e3e3e3", display: "flex", alignItems: 'center', justifyContent: "center" }}>
              {(!previewFile && pdfData) && (
                <embed id="myvideo" src={pdfData && (pdfData + "#toolbar=0&navpanes=0&scrollbar=0")} title=""
                  width="100%" height="330px" />
              )}
              {previewFile && (
                <object id="myvideo" data={previewFile + "#toolbar=0&navpanes=0&scrollbar=0"} title=""
                  width="100%" height="330px" />
              )}
              {(!previewFile && !pdfData) && (
                <span><UploadOutlined /> &nbsp; Click to Upload</span>
              )}
            </div>
          </Upload>
        </Col>

        <Col span={1}>
          <FileTextOutlined className="require-doc" />
        </Col>
        <Col span={10} >
          {/* //style={{ display: "flex", justifyContent: "end", padding: 0 }} */}
          <Form.Item className="mb-0">
            <InputField
              isRequired={true}
              classes="mb-0 sm-input"
              fieldname={'documentName'}
              iProps={{ placeholder: "Document Name" }}
              control={control}
              initValue={data?.documentName || ""}
              rules={{ required: "Please enter document name" }}
              validate={errors.documentName && "error"}
              validMessage={errors.documentName && errors.documentName.message}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[24, 24]} justify="end" className="mt-1">
        <Col>
          <Space>
            <Button
              // type="submit"
              // label="Update"
              className="ef-discard-btn"
              onClick={() =>
                props.parentState({ modalPayload: { open: false } })
              }
            >
              Cancel
            </Button>
            <Button
              // label="Cancel"
              type="primary"
              className="ef-approve-btn"
              htmlType="submit"
            >
              Save
            </Button>
          </Space>
        </Col>
      </Row>

      {/* <Popup {...popupContent} /> */}
    </Form>
  );
};
