import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Form,
} from "antd";
import Text from "antd/es/typography/Text";
import { useForm, useWatch, Controller } from "react-hook-form";

const modifyNotice = (props) => {

  const { control, handleSubmit, formState: { errors }, setError } = useForm();


  const onFinish = async (values) => {
    props.onSubmit({})
  };

  return (
    <>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        <Row gutter={24}>
          <Col span={24}>
            <Text style={{fontSize: 20, fontWeight: 'bold', marginBottom: 30}}>This notice will add to the schedule queue for sending</Text>
          </Col>
        </Row> 

        <Row gutter={24}>
          <Col>
            <Button className="ag-gray-button-outline" htmlType="submit">
              Schedule
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default modifyNotice;