import {
  Button,
  Col,
  List,
  Pagination,
  Row,
  Select,
  Space,
  Form,
  Typography,
} from "antd";
import React, { useState, useEffect } from "react";
import { leaseService } from "../../../configs/constants";
import axios from "../../../utils/axiosInceptor";
import DashboardLayout from "../../molecules/DashboardLayout";
import { useNavigate, useLocation } from "react-router-dom";
import {
  NOTIFICATION_TYPE,
  TYPE_NOTIFY,
  TYPE_CONTRACT,
} from "../../molecules/Header/duck/constants";
import Link from "antd/lib/typography/Link";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications, getNewNotifications } from "./ducks/action";
import moment from "moment";

const { Title, Text } = Typography;
const { Option } = Select;

const Notification = (props) => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let url = `${leaseService}/notification/`;
  const [data, setData] = useState({
    currentPage: 1,
    perPage: 10,
    pageCount: 0,
    totalCount: 0,
    data: [],
  });
  const [page, setPage] = useState(1);
  const [optionSelected, setOptionSelected] = useState("new");
  const notificationsData = useSelector(
    (state: any) => state.addInfoReducer.getNotifications
  );

  const newNotificationsData = useSelector(
    (state: any) => state.addInfoReducer.getNewNotifications
  );

  useEffect(() => {
    dispatch(getNotifications(page));
    dispatch(getNewNotifications(page));
  }, []);

  useEffect(() => {
    if (optionSelected !== "new") {
      notificationsData && setData(notificationsData);
    }
  }, [notificationsData]);

  useEffect(() => {
    if (optionSelected === "new") {
      notificationsData && setData(newNotificationsData);
    }
  }, [newNotificationsData]);

  //TODO : update accordingly
  const updateStatus = async (id) => {
    let patchData = {
      isRead: true,
    };

    url = url + `${id}`;
    await axios.patch(url, patchData);
    if (optionSelected === "new") {
      dispatch(getNewNotifications(page));
    } else {
      dispatch(getNotifications(page));
    }
  };

  const gotoLease = (item) => {
    navigate(`/lease/${item.identifier_value}`);
  };

  const gotoBooking = (item) => {
    const { assetId, leadId, partyId } = JSON.parse(item.identifier_value);
    navigate(`/leads/${leadId}/booking-form/${assetId}/${partyId}`);
  };

  const gotoOpportunity = (item) => {
    const { assetId, leadId, partyId } = JSON.parse(item.identifier_value);

    navigate(`/opportunity-detail/${leadId}/${assetId}/${partyId}`);
  };

  const gotoTenant = (item) => {
    const { assetId, leadId, partyId } = JSON.parse(item.identifier_value);

    navigate(`/detail-tenant/${leadId}/${partyId}`);
  };

  const gotoContract = (item) => {
    navigate(`/contract-detail/${item.identifier_value}`);
  };

  // const handleLinkClick = async (item) => {
  //   window.localStorage.setItem(
  //     "selectedOffer",
  //     JSON.stringify({
  //       identifier: item.identifier_value,
  //       id: item.id,
  //     })
  //   );

  //   let postData = {
  //     notification_ids: [item.id],
  //   };
  //   await axios.post(url, postData);

  //   switch (item.type) {
  //     case NOTIFICATION_TYPE.ASSET_ID: {
  //       navigate(`/visit-scheduling/${item.identifier_value}`);
  //       break;
  //     }
  //     case NOTIFICATION_TYPE.VISIT_ID: {
  //       navigate(`/visit-scheduling/${item.identifier_value}`, {
  //         state: { code: item.identifier_value },
  //       });
  //       // history.push(`/visit-scheduling/${item.identifier_value}`);
  //       break;
  //     }
  //     case NOTIFICATION_TYPE.LEAD_ITEM_ID: {
  //       navigate(`/offer-by-tenant/${item.identifier_value}`);
  //       break;
  //     }
  //     case NOTIFICATION_TYPE.LEASE_ID: {
  //       switch (item.notification_type) {
  //         case TYPE_CONTRACT.CONTRACT_SIGNED: {
  //           gotoContract(item);
  //           break;
  //         }
  //         case TYPE_CONTRACT.CONTRACT_RENEWED: {
  //           gotoContract(item);
  //           break;
  //         }
  //         case TYPE_CONTRACT.CONTRACT_RENEWED1: {
  //           gotoContract(item);
  //           break;
  //         }
  //         case TYPE_CONTRACT.RENEWAL_FEEDBACK: {
  //           gotoContract(item);
  //           break;
  //         }
  //         default:
  //           navigate(`/ejari`);
  //           break;
  //       }
  //       break;
  //     }
  //     case NOTIFICATION_TYPE.MAINTENANCE_ITEM_ID: {
  //       navigate(`/maintenance/${item.identifier_value}`);
  //       break;
  //     }
  //     case NOTIFICATION_TYPE.LEAD_ID: {
  //       switch (item.notification_type) {
  //         case TYPE_NOTIFY.LEASE_FORM_SENT_FOR_APPROVAL: {
  //           gotoLease(item);
  //           break;
  //         }
  //         case TYPE_NOTIFY.LEASE_FORM_APPROVED: {
  //           gotoLease(item);
  //           break;
  //         }
  //         case TYPE_NOTIFY.LEASE_FORM_REJECTED: {
  //           gotoLease(item);
  //           break;
  //         }
  //         case TYPE_NOTIFY.SENT_TO_TENANT_FOR_CHEQUES: {
  //           gotoLease(item);
  //           break;
  //         }
  //         case TYPE_NOTIFY.TENANT_CHEQUES_SCHEDULED_DROPOFF: {
  //           gotoLease(item);
  //           break;
  //         }
  //         case TYPE_NOTIFY.TENANT_CHEQUES_SCHEDULED_PICKUP: {
  //           gotoLease(item);
  //           break;
  //         }
  //         case TYPE_NOTIFY.BOOKING_FORM_SIGNED: {
  //           gotoLease(item);
  //           break;
  //         }

  //         case TYPE_NOTIFY.BOOKING_OPPORTUNITY: {
  //           gotoBooking(item);
  //           break;
  //         }
  //         case TYPE_NOTIFY.BOOKING_APPROVED: {
  //           gotoBooking(item);
  //           break;
  //         }
  //         case TYPE_NOTIFY.BOOKING_REJECTED: {
  //           gotoBooking(item);
  //           break;
  //         }

  //         case TYPE_NOTIFY.BOOKING_RECEIVED: {
  //           gotoOpportunity(item);
  //           break;
  //         }
  //         case TYPE_NOTIFY.DOCUMENT_SUBMITED: {
  //           gotoTenant(item);
  //           break;
  //         }
  //       }
  //       break;
  //     }
  //     default:
  //       navigate(`/dashboard`);
  //   }
  // };


  const handleLinkClick = async (item) => {

    if(item.title === 'Comment on ticket') {
      navigate(`/tickets?ticketId=${item?.identifier}`)
    } else if (item.notificationtype === 'click_to_download') {
      window.open(item.identifier)
    } else if(item?.identifier){
      navigate(item?.identifier);
    }
  }

  useEffect(() => {
    optionSelected === "new"
      ? setData(newNotificationsData)
      : setData(notificationsData);
  }, [optionSelected]);

  const onSelectChange = (e) => {
    setPage(1);
    setOptionSelected(e);
  };

  const pageChange = (pg) => {
    setPage(pg);
    if (optionSelected === "new") {
      dispatch(getNewNotifications(pg));
    } else {
      dispatch(getNotifications(pg));
    }
  };

  return (
    <DashboardLayout>
      <Row gutter={24}>
        <Col span={24}>
          <Title level={3} style={{color:'#184461'}}>Notifications</Title>
        </Col>
        <Col span={24}>
          <Form.Item>
            <Select
              style={{ width: 250 }}
              value={optionSelected}
              onChange={onSelectChange}
            >
              <Option value="new">New</Option>
              <Option value="all">All</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <List>
            {data?.data?.map((item, index) => (
              <List.Item
                key={index}
                className={`notificationlist ${item.isRead ? "notify-read" : ""
                  }`}
                actions={[
                  !item.isRead && (
                    <Button
                      className="ag-success"
                      type="link"
                      onClick={() => updateStatus(item?.notificationId)}
                    >
                      Mark as Read
                    </Button>
                  ),
                ]}
              >
                <Space direction="vertical" size={4}>
                  <Text className="ag-primary breakText">
                    <strong> {item?.title || ""}</strong>
                  </Text>
                  <Text className="ag-primary breakText">
                    {item?.body || ""}
                  </Text>
                  {(item?.identifier && item?.identifier != "") && (
                    <Text className="smallFont12">
                      <Link
                        className="ag-secondary"
                        onClick={() => handleLinkClick(item)}
                      >
                        {"Click here"}
                      </Link>
                    </Text>
                  )}
                  <Text className="smallFont12">
                    {item.createdAt
                      ? moment(item.createdAt).format("MMMM Do YYYY, h:mm:ss a")
                      : ""}
                  </Text>
                </Space>
              </List.Item>
            ))}
          </List>
        </Col>
        <Col span={24}>
          <Pagination
            className="ag-property-text-pagination"
            total={data?.totalCount}
            showTotal={(total, range) =>
              `Showing ${range[0]}-${range[1]} Notification`
            }
            defaultPageSize={10}
            showSizeChanger={false}
            current={page + 1}
            onChange={(e) => pageChange(e - 1)}
          />
        </Col>
      </Row>
    </DashboardLayout>
  );
};

export default Notification;
