import * as React from "react";

const index = (props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.0122 18.25C6.77783 18.25 3.82471 16.5625 2.20752 13.75C0.590332 10.9727 0.590332 7.5625 2.20752 4.75C3.82471 1.97266 6.77783 0.25 10.0122 0.25C13.2114 0.25 16.1646 1.97266 17.7817 4.75C19.3989 7.5625 19.3989 10.9727 17.7817 13.75C16.1646 16.5625 13.2114 18.25 10.0122 18.25ZM6.95361 6.08594V6.12109C6.81299 6.54297 7.02393 7.03516 7.48096 7.21094C7.90283 7.35156 8.39502 7.14062 8.53564 6.68359L8.5708 6.64844C8.60596 6.54297 8.71143 6.47266 8.81689 6.47266H10.856C11.1724 6.47266 11.4185 6.68359 11.4185 7C11.4185 7.17578 11.313 7.35156 11.1372 7.45703L9.59033 8.33594C9.30908 8.47656 9.16846 8.75781 9.16846 9.07422V9.53125C9.16846 10.0234 9.52002 10.375 10.0122 10.375C10.4692 10.375 10.8208 10.0234 10.856 9.56641L11.981 8.89844C12.6489 8.51172 13.106 7.77344 13.106 7C13.106 5.76953 12.0864 4.75 10.856 4.75H8.81689C7.97314 4.75 7.23486 5.27734 6.95361 6.08594ZM8.88721 12.625C8.88721 13.2578 9.37939 13.75 10.0122 13.75C10.6099 13.75 11.1372 13.2578 11.1372 12.625C11.1372 12.0273 10.6099 11.5 10.0122 11.5C9.37939 11.5 8.88721 12.0273 8.88721 12.625Z"
      fill="#24435D"
    />
  </svg>
);
export default index;
