import React from "react";

const index = (props: any) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      className="cc-fotter_next-icon"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.26172 9H14.7617"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2617 13.5L14.7617 9"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2617 4.5L14.7617 9"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default index;
