import React, { Fragment } from "react";
import { Col, Row, Button, Typography } from "antd";
import { InputField, InputNumberField, ReactSelectField } from "../../../../atoms/FormElement";
import { OldAmount, changeFieldFormatter, changeFieldParser, no_of_installments } from "../utils";
import { DeleteIcon } from "../../../../../assets/svg";
import { currencyFormat } from "../../../../../utils/media";

var _ = require("lodash");

export default (props) => {
    const {
        control,
        errors,
        remove,
        fields,
        viewOnly,
    } = props;

    const { Text } = Typography;

    return (
        <>
            <Row gutter={[24, 24]} style={{ letterSpacing: "1.2px" }}>
                <Col span={2}>
                    <Text className="fontWeight600 ag-default mb-1"></Text>
                </Col>
                <Col span={7}>
                    <Text className="fontWeight600 ag-default mb-2">NUMBER OF PAYMENTS</Text>
                </Col>
                <Col span={7}>
                    <Text className="fontWeight600 ag-default mb-2">RENT PER YEAR</Text>
                </Col>
                <Col span={7}>
                    <Text className="fontWeight600 ag-default mb-2">SECURITY DEPOSITS</Text>
                </Col>
                <Col span={24} style={{ height: "16px" }} />
            </Row>
            {fields.map((item, index) => (
                <Fragment key={item.id}>
                    <Row gutter={24} style={{ position: "relative", marginBottom: (viewOnly?"15px":"0")}}>
                        <Col span={2}>
                            <div>
                                <Text className="fontWeight600 ag-default">Year {index + 1}</Text>
                                <InputField
                                    classes="fm-input-hidden"
                                    fieldname={`plans[${index}].noOfYear`}
                                    control={control}
                                    initValue={index + 1}
                                    iProps={{ readOnly: true, type: "hidden" }}
                                />
                            </div>
                        </Col>
                        <Col span={7}>
                            {!viewOnly ? (
                                <ReactSelectField
                                    isRequired={true}
                                    fieldname={`plans[${index}].installments`}
                                    control={control}
                                    iProps={{
                                        placeholder: "Select",
                                        isDisabled: props.disableAll ? true : false,
                                    }}
                                    selectOption={no_of_installments}
                                    rules={{ required: "Please select no. of payments" }}
                                    validate={
                                        Object.entries(errors).length > 0 &&
                                        errors?.plans?.length > 0 &&
                                        errors?.plans[index]?.installments &&
                                        "error"
                                    }
                                    validMessage={
                                        Object.entries(errors).length > 0 &&
                                        errors?.plans?.length > 0 &&
                                        errors?.plans[index]?.installments &&
                                        errors?.plans[index]?.installments?.message
                                    }
                                />
                            ) : (<div className="ag-primary"><OldAmount amount={item?.oldInstallments} /> {item?.installments?.value}</div>)}

                        </Col>
                        <Col span={7}>
                            {!viewOnly ? (
                                <InputNumberField
                                    isRequired={true}
                                    fieldname={`plans[${index}].annualRent`}
                                    iProps={{
                                        placeholder: "Amount",
                                        // type: "number",
                                        parser: (value: any) =>
                                            changeFieldParser(value, "amount"),
                                        formatter: (value: string): any =>
                                            changeFieldFormatter(value, "amount"),
                                    }}
                                    control={control}
                                    initValue={item?.annualRent}
                                    rules={{
                                        required: "Please enter amount",
                                        min: { value: 1, message: "Please enter amount greater than 0" },
                                        setValueAs: (value) => parseFloat(value?.toFixed(2)),
                                    }}
                                    validate={
                                        Object.entries(errors).length > 0 &&
                                        errors?.plans?.length > 0 &&
                                        errors?.plans[index]?.annualRent &&
                                        "error"
                                    }
                                    validMessage={
                                        Object.entries(errors).length > 0 &&
                                        errors?.plans?.length > 0 &&
                                        errors?.plans[index]?.annualRent &&
                                        errors?.plans[index]?.annualRent?.message
                                    }
                                />
                            ) : (<div className="ag-primary"><OldAmount amount={item?.oldAnnualRent} /> {currencyFormat(+item?.annualRent)}</div>)}
                        </Col>
                        <Col span={7}>
                            {index < 1 && (
                                !viewOnly ? (
                                <InputNumberField
                                    isRequired={true}
                                    fieldname={`plans[${index}].securityDeposit`}
                                    iProps={{
                                        placeholder: "Amount",
                                        // type: "number",
                                        parser: (value: any) =>
                                            changeFieldParser(value, "amount"),
                                        formatter: (value: string): any =>
                                            changeFieldFormatter(value, "amount"),
                                    }}
                                    control={control}
                                    initValue={item?.securityDeposit}
                                    rules={{
                                        required: "Please enter amount",
                                        min: { value: 1, message: "Please enter amount greater than 0" },
                                        setValueAs: (value) => parseFloat(value?.toFixed(2)),
                                    }}
                                    validate={
                                        Object.entries(errors).length > 0 &&
                                        errors?.plans?.length > 0 &&
                                        errors?.plans[index]?.securityDeposit &&
                                        "error"
                                    }
                                    validMessage={
                                        Object.entries(errors).length > 0 &&
                                        errors?.plans?.length > 0 &&
                                        errors?.plans[index]?.securityDeposit &&
                                        errors?.plans[index]?.securityDeposit?.message
                                    }
                                />
                                ) : (<div className="ag-primary"><OldAmount amount={item?.oldSecurityDeposit} /> {currencyFormat(+item?.securityDeposit)}</div>)
                            )
                            }
                        </Col>
                        {!props.disableAll && !viewOnly ? (//index > 0 && ( //(deleteIndex ?? 2)
                            <Button
                                type="link"
                                icon={<DeleteIcon />}
                                onClick={() => {
                                    remove(index);
                                    //   enableClone(typeM);
                                }}

                            />
                        ) : <Col></Col>}

                    </Row>
                </Fragment>

            ))}
        </>
    );
};
