import React, { useEffect, useState } from "react";
import { Col } from "antd";
import { InputField, ReactSelectField } from "../../../atoms/FormElement";

import { bedroomCategory } from "../../Properties/ducks/constants";

export default (props) => {
  const { control, unitDetails, disableAll, errors, setValue, parkingSlots } = props;

  // console.log("parkingSlots", parkingSlots)

  useEffect(() => {
    if (unitDetails) {
      // console.log("UNITDE",unitDetails)
      setValue("propertyName", unitDetails?.propertyName);
      setValue("dewa", unitDetails?.dewa);
      setValue("unitCode", unitDetails?.unitCode);
      if (unitDetails?.usagesType) {
        setValue("usagesType", {
          value: unitDetails?.usagesType,
          label: unitDetails?.usagesType,
        });
      }
      if (unitDetails?.unitType) {
        setValue("unitType", {
          value: unitDetails?.unitType,
          label: unitDetails?.unitType,
        });
      }
      if (unitDetails?.unitSubType) {
        setValue("unitSubType", {
          value: unitDetails?.unitSubType,
          label: unitDetails?.unitSubType,
        });
      }
      setValue("areaSqft", unitDetails?.areaSqft);
    }
  }, [unitDetails]);

  return (
    <>
      <Col span={8}>
        <InputField
          label="Building Name"
          fieldname="propertyName"
          control={control}
          initValue=""
          iProps={{ placeholder: "Al-Ghurair Residence", }}
          isRequired={true}
          validate={errors.propertyName && "error"}
          validMessage={
            errors.propertyName && errors.propertyName.message
          }
          disabled={true}
          rules={{ required: "Please enter building name" }}
        />
      </Col>
      <Col span={8}>
        <InputField
          label="Unit Code"
          fieldname="unitCode"
          control={control}
          initValue=""
          iProps={{ placeholder: "Unit Code", }}
          isRequired={true}
          validate={errors.unitCode && "error"}
          validMessage={
            errors.unitCode && errors.unitCode.message
          }
          disabled={true}
          rules={{ required: "Please enter unit code" }}
        />
      </Col>
      <Col span={8}>
        <ReactSelectField
          label="Usage Type"
          fieldname="usagesType"
          control={control}
          initValue=""
          selectOption={[{ label: "Commercial", value: "commercial" }, { label: "Residential", value: "residential" }]}
          iProps={{ placeholder: "", isDisabled: true }}
          isRequired={true}
          validate={errors.usagesType && "error"}
          validMessage={
            errors.usagesType && errors.usagesType.message
          }
          rules={{ required: "Please select usage type" }}
        />
      </Col>
      <Col span={8}>
        <InputField
          label="Size, Sqft"
          fieldname="areaSqft"
          control={control}
          initValue={""}
          iProps={{ placeholder: "2000", }}
          isRequired={true}
          validate={errors.areaSqft && "error"}
          validMessage={
            errors.areaSqft && errors.areaSqft.message
          }
          disabled={true}
          rules={{ required: "Please enter size" }}
        />
      </Col>
      <Col span={8}>
        <ReactSelectField
          label="Unit Type"
          fieldname="unitType"
          control={control}
          initValue=""
          selectOption={[{ label: "Apartment", value: "apartment" }]}
          iProps={{ placeholder: "Select one", isDisabled: true }}
          isRequired={true}
          validate={errors.unitType && "error"}
          validMessage={
            errors.unitType && errors.unitType.message
          }
          rules={{ required: "Please select unit type" }}
        />
      </Col>
      <Col span={8}>
        <ReactSelectField
          label="Sub-Type"
          fieldname="unitSubType"
          control={control}
          initValue=""
          selectOption={[{ label: "2BR", value: "2BR" }]}
          iProps={{
            placeholder: "Select one",
            isDisabled: true
          }}
          isRequired={true}
          validate={errors.unitSubType && "error"}
          validMessage={
            errors.unitSubType && errors.unitSubType.message
          }
          rules={{ required: "Please select sub-type" }}
        />
      </Col>
      <Col span={8}>
        <InputField
          label="DEWA"
          fieldname={`dewa`}
          control={control}
          initValue=""
          iProps={{
            placeholder: "Enter details",
            disabled: disableAll,
          }}
          disabled={disableAll}
        />
      </Col>
      <Col span={8}>
        <ReactSelectField
          label="Paid Parking Slots"
          fieldname="paidParkingSlots"
          control={control}
          // valueGot={{
          //   label: bookingFormDetails?.freeParkingSlots,
          //   value: bookingFormDetails?.freeParkingSlots,
          // }}
          setValue={setValue}
          // isRequired={true}
          // validate={errors.freeparkingslots && "error"}
          // validMessage={
          //   errors.freeparkingslots && errors.freeparkingslots.message
          // }
          // rules={{ required: "Select free parking slots" }}
          // initValue={{label:"No Selection",value: null}}
          initValue=""
          iProps={{ placeholder: "Select",   isMulti: true, }}
          selectOption={parkingSlots?.map((e) => {
            return { label: e?.unitCode, value: e?.unitId };
          })}
        />
      </Col>
      <Col span={8}>
        <ReactSelectField
          label="Unpaid Parking Slots"
          fieldname="unpaidParkingSlots"
          control={control}
          // valueGot={{
          //   label: bookingFormDetails?.freeParkingSlots,
          //   value: bookingFormDetails?.freeParkingSlots,
          // }}
          setValue={setValue}
          // isRequired={true}
          // validate={errors.freeparkingslots && "error"}
          // validMessage={
          //   errors.freeparkingslots && errors.freeparkingslots.message
          // }
          // rules={{ required: "Select free parking slots" }}
          // initValue={{label:"No Selection",value: null}}
          initValue=""
          iProps={{ placeholder: "Select", isMulti: true, }}
          selectOption={parkingSlots?.map((e) => {
            return { label: e?.unitCode, value: e?.unitId };
          })}
        // selectOption={parkingSlotsData.map((e) => {
        //   return { label: e, value: e };
        // })}
        />
      </Col>
    </>
  );
};
