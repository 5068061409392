import React, { useState } from "react";
import { Select, Input, Button ,Checkbox} from "antd";
interface IProps {}

/**
* @author
* @function @
**/

 function BookingStatusFilter ({bookingStatusFilter,
    bookingStatusValue,
    cancelledbookingCheckBox,
    setCancelledBookingCheckBox
}
)

{
    const { Option } = Select 
    const [open, setOpen] = useState(false);
 
  

    const bookingStatuses =[
      {
        "label": "All",
        "value": "all"
      },
        {
          "label": "Draft",
          "value": "draft"
        },
        {
          "label": "Draft-Submitted",
          "value": "booking_created"
        },
        {
          "label": "Tenant Invitation Sent",
          "value": "tenant_invitation_sent"
        },
        // {
        //   "label": "Awaiting Kyc Information",
        //   "value": "awaiting_kyc_information"
        // },
        // {
        //   "label": "Kyc Submitted",
        //   "value": "kyc_submitted"
        // },
        // {
        //   "label": "Documents Submitted",
        //   "value": "documents_submitted"
        // },
        {
          "label": "Documents Approved",
          "value": "documents_approved"
        },
        {
          "label": "Kyc Approved",
          "value": "kyc_approved"
        },
        {
          "label": "Sent For Booking Approval",
          "value": "sent_for_booking_approval"
        },
        // {
        //   "label": "Booking Approved",
        //   "value": "booking_approved"
        // },
        {
          "label": "Booking Form Sent",
          "value": "booking_form_sent"
        },
        {
          "label": "Awaiting Deposit",
          "value": "awaiting_deposit"
        },
        {
          "label": "Deposit Paid",
          "value": "deposit_paid"
        },
        
        {
          "label": "Rent Payments Received",
          "value": "rent_payments_received"
        },
        {
          "label": "Sent For Lease Approval",
          "value": "sent_for_lease_approval"
        },
        {
          "label": "Contract Created",
          "value": "contract_created"
        },
        {
          "label": "Awaiting Tenant Signature",
          "value": "awaiting_tenant_signature"
        },
        {
          "label": "Awaiting Landlord Signature",
          "value": "awaiting_landlord_signature"
        },
        {
          "label": "Contract Signed",
          "value": "contract_signed"
        },
        
      ];

   const getCheckBoxValue = (checkBoxValue)=>{
    setCancelledBookingCheckBox(checkBoxValue);
    setOpen(false)
   }
       
  return ( 

   <div style={{display:'flex',flexDirection:'column'}}>
    <span style={{color:'gray'}}>Filter By Booking Status</span>
    <Select
      defaultValue="All"
      bordered={true}
      labelInValue
      suffixIcon={null} 
      notFoundContent={
        <div style={{ textAlign: 'center', color: '#888',position:'absolute',zIndex:'-1px' }}>
        No data found
      </div>
      } // Custom "No Data" placeholder with reduced height
      showSearch
      allowClear
      filterOption={(input:any, option:any) => {
      
          return (option?.children ?? '')?.toLowerCase().includes(input.toLowerCase())
      }}
      size="large"
      onChange={(e)=>bookingStatusFilter(e)}
      open={open}
      onDropdownVisibleChange={(visible) => setOpen(visible)}
      dropdownRender={(menu) => (
        <div>
          {menu}
         {
         ( bookingStatusValue?.value === 'all' || !bookingStatusValue) &&
            <div style={{padding:'10px'}}>
           <Checkbox checked={cancelledbookingCheckBox} onChange={(e)=> getCheckBoxValue(e.target?.checked)}>Show Cancelled Bookings</Checkbox>
           </div>
         }
        </div>
      )}
    >
        {
        bookingStatuses.map((item,index)=>  <Option key={item?.value+ index} value={item.value}>{item.label}</Option>)
     }
    </Select>
   </div>
   )
 }
export default React.memo(BookingStatusFilter);