import React, { useState, useEffect, Fragment } from "react";
import {
  Col,
  Row,
  Form,
  Card,
  Typography,
  Button,
  Space,
  message,
  Alert,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumb from "../../atoms/BreadCrumb";
import DashboardLayout from "../../molecules/DashboardLayout";
import SectionHeader from "../../molecules/SectionHeader";
import { useForm } from "react-hook-form";
import axios from "../../../utils/axiosInceptor";
import {
  getTenantIncomeRanges,
  getTenantCountires,
  getTenantCities,
  getTenantEmploymentSector,
  getTenantNationalities,
  getTenantDetails,
  getTenantType,
  getMaritalStatus,
  getContactRoles,
  getApprovalHistory,
} from "./ducks/actions";
import TenantForm from "./components/TenantForm";
// import ContactArrayForm from "./components/ContactArrayForm";
import RequireDoc from "../../molecules/RequireDoc";
import { Popup, PopupConfirm } from "../../atoms/Popup";
import Rejectform from "../../molecules/RejectForm/RejectForm";
import { getSingleUpload, uniquiFileName } from "../../../utils/fileUpload";
import { baseContractService } from "../../../configs/constants";
import ApprovalHistory from "./components/ApprovalHistory";

const { Title } = Typography;
const breadcrumbList: any = [
  {
    label: "Tenant Detail",
    route: "/tenant-detail",
  },
];

export default (props) => {
  const navigate = useNavigate();
  const dispatch:any = useDispatch();
  const { control, formState: { errors }, setValue, handleSubmit, formState } = useForm();
  const { isValid } = formState;
  const { id, partyId } = useParams();
  const [loading, setLoading] = useState(false);
  const [disableAll, setdisableAll] = useState(false);
  const [visible, setVisible] = useState(false);
  const [objectId, setDocObjectId] = useState({
    id: null,
    field: null,
  });
  const [update, setUpdate] = useState(false);
  const [fromContact, setFromContact] = useState(false);
  const [rejectDocs, setRejectDocs] = useState([]);
  const [rejectContactDocs, setRejectContactDocs] = useState([]);
  const [contactID, setContactID] = useState();
  const [contactDisable, setContactDisable] = useState(false);
  const [disableFront, setDisableFront] = useState(false);
  const [updateDone, setUpdateDone] = useState(false);
  const [otDocs, setOTDocs] = useState([]);

  const loginMode = JSON.parse(window.localStorage.getItem("login-response"))
    ?.data?.role_name;

  const usageTypeApi = useSelector(
    (state: any) => state.tenantDetails.getTenantType,
  );
  const approvalData = useSelector(
    (state: any) => state.tenantDetails.docHistory,
  );
  const maritalStatusApi = useSelector(
    (state: any) => state.tenantDetails.getMarital,
  );
  const employSectorApi = useSelector(
    (state: any) => state.tenantDetails.getEmploymentSector,
  );
  const incomeApi = useSelector(
    (state: any) => state.tenantDetails.getIncomRanges,
  );
  const nationalityApi = useSelector(
    (state: any) => state.tenantDetails.getNationalities,
  );
  const countryApi = useSelector(
    (state: any) => state.tenantDetails.getCountries,
  );
  const cityApi = useSelector((state: any) => state.tenantDetails.getCities);

  const tenantData = useSelector(
    (state: any) => state.tenantDetails.getTenantDetils,
  );

  const contactRoleApi = useSelector(
    (state: any) => state.tenantDetails.getContactRoles,
  );
  //@ts-ignore
  useEffect(() => {
    dispatch(getTenantCountires());
    dispatch(getTenantCities());
    dispatch(getTenantDetails(partyId, false));
    dispatch(getTenantEmploymentSector());
    dispatch(getTenantIncomeRanges());
    dispatch(getTenantNationalities());
    dispatch(getTenantType());
    dispatch(getMaritalStatus());
    dispatch(getContactRoles());
    dispatch(getApprovalHistory(id));
    if (loginMode != "Lease_Executive") {
      setdisableAll(true);
      setUpdateDone(true);
      setContactDisable(true);
    }
    return () => dispatch(getTenantDetails(partyId, true));
  }, []);

  useEffect(() => {
    if (Object.keys(tenantData).length) {
      setValue("first_name", tenantData?.first_name);
      setValue("last_name", tenantData?.last_name);
      setValue("marital_status", {
        label: tenantData.marital_status?.name,
        value: tenantData.marital_status?.id,
      });
      if (tenantData.type) {
        setValue("type", {
          label: tenantData.type?.name,
          value: tenantData.type?.id,
        });
      } else {
        setValue("type", {
          label: "Residential",
          value: 2,
        });
      }

      if (tenantData.income_range) {
        setValue("income", {
          label: tenantData.income_range?.name,
          value: tenantData.income_range?.id,
        });
      }
      if (tenantData.employment_sector) {
        setValue("employment_sector", {
          label: tenantData.employment_sector?.name,
          value: tenantData.employment_sector?.id,
        });
      }
      if (tenantData.contact_info?.city) {
        setValue("city", {
          label: tenantData.contact_info?.city?.name,
          value: tenantData.contact_info?.city?.id,
        });
      }
      if (tenantData.contact_info?.country) {
        setValue("country", {
          label: tenantData.contact_info?.country?.name,
          value: tenantData.contact_info?.country?.id,
        });
      }
      if (tenantData.contact_info?.nationality) {
        setValue("nationality", {
          label: tenantData.contact_info?.nationality?.name,
          value: tenantData.contact_info?.nationality?.id,
        });
      }
      setValue("visa_designation", tenantData.visa_designation);
      if (tenantData.contact_info?.contact_number) {
        let str: any = tenantData.contact_info?.contact_number;
        setValue("contact_number", str.substring(str.length - 9));
      }

      setValue("email", tenantData.contact_info?.email);
      setValue("address", tenantData.contact_info?.street_address);
      setValue("zipcode", tenantData.contact_info?.zipcode);
      // setValue("contacts", tenantData.contacts);
      let disablebtn = false;
      if (tenantData.documents) {
        if (!tenantData.documents.passport) {
          setdisableAll(true);
        } else if (tenantData?.documents?.passport.tenant_object_id) {
          if (tenantData?.documents?.passport.status.name == "approved") {
            setValue("passport_1", "accept");
          }
        } else {
          disablebtn = true;
          setValue("passport_1", "reject");
        }
        if (!tenantData.documents.visa) {
          setdisableAll(true);
        } else if (tenantData?.documents?.visa.tenant_object_id) {
          if (tenantData?.documents?.visa.status.name == "approved") {
            setValue("visa_1", "accept");
          }
        } else {
          disablebtn = true;
          setValue("visa_1", "reject");
        }

        if (!tenantData.documents.emirates_id) {
          setdisableAll(true);
        } else if (tenantData?.documents?.emirates_id.tenant_object_id) {
          if (tenantData?.documents?.emirates_id.status.name == "approved") {
            setValue("emirates_1", "accept");
          }
        } else {
          disablebtn = true;
          setValue("emirates_1", "reject");
        }
      }
      if (tenantData?.tenant_status == "approved") {
        // setContactDisable(true);
        setdisableAll(true);
        setUpdateDone(true);
      } else {
        setUpdate(disablebtn);
      }
      if (
        tenantData.documents?.other_document &&
        tenantData.documents?.other_document.length > 0
      ) {
        let temp = [];
        tenantData.documents?.other_document.map((x) => {
          temp.push(x);
        });
        temp.sort((a, b) => a.doc_order - b.doc_order);
        setOTDocs(temp);
      }
    }
  }, [tenantData]);

  // const onReject = (e) => {
  //   let rejectedDocs = [];
  //   if (!fromContact) {
  //     rejectedDocs = [...rejectDocs];
  //     rejectedDocs.push({
  //       lead_id: Number(id),
  //       tenant_object_id: objectId,
  //       tenant_object_status_reason_type_id: e,
  //     });
  //     setRejectDocs(rejectedDocs);
  //     setdisableAll(true);
  //     setFromContact(false);
  //   } else {
  //     rejectedDocs = [...rejectContactDocs];
  //     rejectedDocs.push({
  //       tenant_object_id: objectId,
  //       tenant_object_status_reason_type_id: e,
  //       lead_id: Number(id),
  //       tenant_contact_id: contactID,
  //     });
  //     setRejectContactDocs(rejectedDocs);
  //   }
  //   setDocObjectId(null);
  //   setVisible(false);
  //   setUpdate(true);
  // };
  const onRejected = async (e) => {
    let rejectedDocs = [
      {
        lead_id: Number(id),
        tenant_object_id: objectId?.id,
        tenant_object_status_reason_type_id: e,
      },
    ];

    const rejectUrl = `${baseContractService}/documents/reject`;
    try {
      await axios.post(rejectUrl, rejectedDocs);
      setLoading(false);
      message.success("Tenant Detials updated succesfully");
      setDocObjectId({
        id: null,
        field: null,
      });
      dispatch(getTenantDetails(partyId, false));
      dispatch(getApprovalHistory(id));
      setVisible(false);
    } catch (e) {
      setLoading(false);
      const { response } = e;
      message.error(response?.statusText);
    }
  };

  const onAccept = async (objid) => {
    const rejectUrl = `${baseContractService}/documents/${objid}/approve`;
    try {
      await axios.post(rejectUrl, { lead_id: Number(id) });
      setLoading(false);
      message.success("Tenant Detials updated succesfully");
      setDocObjectId({
        id: null,
        field: null,
      });
      dispatch(getTenantDetails(partyId, false));
      dispatch(getApprovalHistory(id));
    } catch (e) {
      setLoading(false);
      const { response } = e;
      message.error(response?.statusText);
    }
  };

  const onCancel = (field) => {
    dispatch(getTenantDetails(partyId, false));
    if (field) {
      setValue(field, "");
    } else {
      setValue(objectId.field, "");
    }
    setDocObjectId({
      id: null,
      field: null,
    });
    setVisible(false);
  };

  // const reqUpdate = async () => {
  //   setLoading(true);
  //   const rejectUrl = `${baseContractService}/documents/reject`;
  //   const rejectUrl1 = `${baseContractService}/contact-documents-reject`;
  //   try {
  //     if (rejectDocs.length > 0) {
  //       await axios.post(rejectUrl, rejectDocs);
  //     }
  //     if (rejectContactDocs.length > 0) {
  //       await axios.post(rejectUrl1, rejectContactDocs);
  //     }
  //     setLoading(false);
  //     message.success("Tenant Detials updated succesfully");
  //     setTimeout(() => history.push(`/opportunities`), 1500);
  //   } catch (e) {
  //     setLoading(false);
  //     const { response } = e;
  //     message.error(response?.statusText);
  //   }
  // };

  const popup = [
    {
      title: "Reject Reason",
      content: (
        <Rejectform
          onReject={onRejected}
          onCancel={onCancel}
          dield={objectId.field}
        />
      ),
      width: 650,
    },
  ];

  const popupProps = {
    closable: false,
    visibility: visible,
    title: popup[0].title,
    content: popup[0].content,
    width: popup[0].width,
    // onCancel: () => setVisible(false),
  };

  const onAceptReject = (e, objectId, contact, contactid, field) => {
    setDocObjectId({ id: objectId, field: field });
    if (e === "reject") {
      setVisible(true);
    } else {
      setVisible(false);
      PopupConfirm({
        title: `Are you sure you want to Accept the document`,
        content: "",
        onOk: () => onAccept(objectId),
        okText: "Confirm",
        onCancel: () => onCancel(field),
      });
    }
  };

  const onSubmit = async (val) => {
    setLoading(true);
    // let delContacts = [];
    // let newContacts = [];
    // let oldContacts = [];

    // if (tenantData.contacts?.length > 0) {
    //   tenantData.contacts?.forEach((item) => {
    //     let x = val.contacts?.find((y) => y.tenant_id == item.tenant_id);
    //     if (x) {
    //       oldContacts.push({
    //         tenant_id: x.id,
    //         first_name: x.first_name,
    //         contact_role: x.contact_role.value,
    //         nationality: x.nationality.value,
    //         contact_number: "+971" + x.contact_number,
    //         email: x.email,
    //         passport_no: x.passport_no,
    //         passport_expiry: x.passport_expiry,
    //         passport: item.passport,
    //         emirates_no: x.emirates_no,
    //         emirates_no_expiry: x.emirates_no_expiry,
    //         emirates_id: item.emirates_id,
    //         visa_no: x.visa_no,
    //         visa_no_expiry: x.visa_no_expiry,
    //         visa: item.visa,
    //       });
    //     } else {
    //       delContacts.push(item.tenant_id);
    //     }
    //   });
    // }
    // if (val.contacts?.length > 0) {
    //   await Promise.all(
    //     val.contacts.map(async (item) => {
    //       let x = tenantData.contacts?.find(
    //         (y) => y.tenant_id == item.tenant_id,
    //       );
    //       if (!x) {
    //         let visa_media = {};
    //         let passport_media = {};
    //         let emirates_no_media = {};
    //         let passPath = item.passport?.fileList[0]?.originFileObj;
    //         let pass_key = uniquiFileName(passPath.name);
    //         await getSingleUpload(pass_key, passPath);
    //         passport_media = {
    //           key: pass_key,
    //           url: "http://s3LikeURL.aws.amazon.com",
    //           mime_type: passPath.name.substr(
    //             passPath.name.lastIndexOf(".") + 1,
    //           ),
    //         };
    //         let visaPath = item.visa?.fileList[0]?.originFileObj;
    //         let visa_key = uniquiFileName(visaPath.name);
    //         await getSingleUpload(visa_key, visaPath);
    //         visa_media = {
    //           key: visa_key,
    //           url: "http://s3LikeURL.aws.amazon.com",
    //           mime_type: visaPath.name.substr(
    //             visaPath.name.lastIndexOf(".") + 1,
    //           ),
    //         };
    //         let emPath = item.emirates_id?.fileList[0]?.originFileObj;
    //         let em_key = uniquiFileName(emPath.name);
    //         await getSingleUpload(em_key, emPath);
    //         emirates_no_media = {
    //           key: em_key,
    //           url: "http://s3LikeURL.aws.amazon.com",
    //           mime_type: emPath.name.substr(emPath.name.lastIndexOf(".") + 1),
    //         };
    //         newContacts.push({
    //           first_name: item.first_name,
    //           nationality: item.nationality.label,
    //           contact_info: {
    //             contact_number: item.contact_number,
    //             country_code: "+971",
    //             email: item.email,
    //           },
    //           passport_no: item.passport_no,
    //           passport_expiry: item.passport_expiry,
    //           visa_no: item.visa_no.replace(/\//g, ""),
    //           visa_expiry: item.visa_no_expiry,
    //           emirates_no: item.emirates_no.replace(/\-/g, ""),
    //           emirates_no_expiry: item.emirates_no_expiry,
    //           contact_role: item.contact_role.apiValue,
    //           media: {
    //             visa_media: visa_media,
    //             passport_media: passport_media,
    //             emirates_no_media: emirates_no_media,
    //           },
    //         });
    //       }
    //     }),
    //   );
    // }

    const postJson = {
      tenant_usage_type_id: val.type.value,
      first_name: val.first_name,
      last_name: val.last_name,
      marital_status_id: val.marital_status.value,
      employment_sector_id: val.employment_sector.value,
      visa_designation: val.visa_designation,
      income_range_id: val.income.value,
      address: val.address,
      nationality_id: val.nationality.value,
      country_id: val.country.value,
      city_id: val.city.value,
      contact_number: "+971" + val.contact_number,
      email: val.email,
      contacts: {
        addContacts: [],
        deleteContacts: [],
      },
    };
    const urlpost = `${baseContractService}/${partyId}/tenant-details`;
    try {
      await axios.post(urlpost, postJson);
      setLoading(false);
      message.success("Tenant Details Updated");
      setTimeout(() => navigate(`/opportunities`), 2000);
    } catch (e) {
      setLoading(false);
      const { response } = e;
      message.error(response?.message);
    }
  };

  return (
    <DashboardLayout load={loading}>
      <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <BreadCrumb list={breadcrumbList} />
          </Col>
          <Col span={24}>
            <SectionHeader heading="Tenant Details" noBorder={true} />
          </Col>
          <Col span={24}>
            <TenantForm
              errors={errors}
              control={control}
              usageTypeApi={usageTypeApi}
              maritalStatusApi={maritalStatusApi}
              employSectorApi={employSectorApi}
              incomeApi={incomeApi}
              nationalityApi={nationalityApi}
              countryApi={countryApi}
              cityApi={cityApi}
            />
          </Col>
          {/* <Col span={24}>
            <Card
              title={<Title level={4}>Contact Information (Optional)</Title>}
              bordered={false}
            >
              <ContactArrayForm
                errors={errors}
                control={control}
                setValue={setValue}
                nationalityApi={nationalityApi}
                contactRoleApi={contactRoleApi}
                onAceptReject={onAceptReject}
                setContactDisable={setContactDisable}
                updateDone={updateDone}
              />
            </Card>
          </Col> */}
          <Col span={24}>
            <Title level={3} className="mb-0">
              Required Documents:
            </Title>
          </Col>
          <Col span={24}>
            <Row gutter={24}>
              <Col span={24}>
                <RequireDoc
                  popup={onAceptReject}
                  title={"Passport"}
                  docsData={tenantData.documents?.passport}
                  disableThis={disableAll}
                  isRejected={false}
                  isContact={false}
                  control={control}
                  errors={errors}
                  fieldName="passport_1"
                  // setDisableFront={setDisableFront}
                  // accept={disableFront}
                  noUpdate={updateDone}
                  optional={false}
                />
              </Col>
              <Col span={24}>
                <RequireDoc
                  control={control}
                  errors={errors}
                  popup={onAceptReject}
                  title={"Visa"}
                  docsData={tenantData.documents?.visa}
                  disableThis={disableAll}
                  isContact={false}
                  fieldName={"visa_1"}
                  // setDisableFront={setDisableFront}
                  // accept={disableFront}
                  noUpdate={updateDone}
                  optional={false}
                />
              </Col>

              <Col span={24}>
                <RequireDoc
                  errors={errors}
                  control={control}
                  popup={onAceptReject}
                  title={"Emirates ID"}
                  docsData={tenantData.documents?.emirates_id}
                  disableThis={disableAll}
                  // setDisableFront={setDisableFront}
                  isContact={false}
                  fieldName={"emirates_1"}
                  // accept={disableFront}
                  noUpdate={updateDone}
                  optional={false}
                />
              </Col>

              <Col span={24}>
                <RequireDoc
                  errors={errors}
                  control={control}
                  popup={onAceptReject}
                  title={"Financial Supporting Document"}
                  docsData={tenantData.documents?.financial_document}
                  disableThis={disableAll}
                  isContact={false}
                  fieldName={"finance_1"}
                  // setDisableFront={setDisableFront}
                  // accept={disableFront}
                  noUpdate={updateDone}
                  optional={true}
                />
              </Col>
              <Col span={24}>
                {otDocs.map((x, i) => (
                  <Fragment key={i}>
                    <RequireDoc
                      errors={errors}
                      control={control}
                      popup={onAceptReject}
                      title={`Other documents ${i + 1}`}
                      docsData={x}
                      disableThis={disableAll}
                      isContact={false}
                      // setDisableFront={setDisableFront}
                      // accept={disableFront}
                      fieldName={`other_${i + 1}`}
                      noUpdate={updateDone}
                      optional={true}
                    />
                  </Fragment>
                ))}
              </Col>
            </Row>
          </Col>
          <Col>
            {(disableAll || contactDisable) &&
              !update &&
              !updateDone &&
              loginMode == "Lease_Executive" && (
                <Alert
                  message="Waiting for tenant to upload their updated documents"
                  type="info"
                  showIcon
                />
              )}
          </Col>
          <Col span={24}>
            <Title level={3} className="mb-0">
              Approval History :
            </Title>
          </Col>

          <Col span={24}>
            <ApprovalHistory approvalData={approvalData} />
          </Col>
        </Row>

        <Row gutter={24}>
          <Col flex="auto">
            <Button htmlType="button" onClick={() => navigate(-1)}>
              Back
            </Button>
          </Col>
          <Col>
            <Space>
              <Button
                type="primary"
                htmlType="submit"
                disabled={update || disableAll}
              >
                Confirm Tenant Information
              </Button>
              {/* <Button
                className="btn-blue"
                type="primary"
                htmlType="button"
                disabled={!update}
                onClick={reqUpdate}
              >
                Request Update
              </Button> */}
            </Space>
          </Col>
        </Row>
      </Form>
      <Popup {...popupProps} />
    </DashboardLayout>
  );
};
