import React, { useState, useEffect } from "react";
import { Card, Typography, List, Button, Image, Row, Col } from "antd";
import { Popup } from "../../atoms/Popup";
import PdfViewer from "../../molecules/PDFViewer/pdfView";
import { getPresignedImage } from "../../../utils/media";
import { EditOutlined } from "@ant-design/icons";

const { Title } = Typography;

const ListPanel = (props) => {
  const [visible, setVisible] = useState(false);
  const [viewOrDownloadUrl, setViewOrDownloadUrl] = useState("");
  const [showPdf, setShowPdf] = useState(false);
  const { data, title, listHeight, clickPanel, leaseStatus } = props;

  const onClosePopUp = () => {
    setVisible(false);
  };

  const onOpenPopUpHandler = (object_key) => {
    if (object_key?.split(".")[1] === "pdf") {
      setShowPdf(!showPdf);
    } else {
      setVisible(true);
    }
  };

  const getUrl = async (data) => {

    let payload = typeof data !== 'string' ? data : [data]

    const url = await getPresignedImage(payload);
    setViewOrDownloadUrl(url[0]);
  };

  const popupContent = {
    closable: true,
    visibility: visible,
    content: (
      <Image
        preview={false}
        width="100%"
        style={{ padding: "20px" }}
        src={viewOrDownloadUrl}
      />
    ),
    width: 650,
    onCancel: onClosePopUp,
  };

  return (
    <>
      <PdfViewer
        pdf={viewOrDownloadUrl}
        onCancel={() => setShowPdf(false)}
        visible={showPdf}
      />
      <Row gutter={16} justify={'space-between'}>
        <Col span={8}>
          <Title level={5}>{title}</Title>
        </Col>
        <Col span={6}>
          {/* <Title level={5}>{title}</Title> */}
        </Col>
        <Col span={8} style={{textAlign: 'end'}}>
          <EditOutlined style={{cursor: 'pointer', color: '#3B3B3B'}} onClick={() => clickPanel(title)}/>
        </Col>
      </Row>
      
      <Card
        bordered={false}
        className="ag-nospace-body panel-card"
        style={{ height: listHeight }}
      >
        <List
          dataSource={data}
          bordered
          renderItem={(item, index) => {
            let _item: any = item;
            _item.name && getUrl(_item.name);
            return (
              <List.Item key={index}>
                <List.Item.Meta
                  title={_item?.key}
                  description={
                    _item.name ? (
                      <Button
                        className="p-0"
                        type="link"
                        onClick={() => onOpenPopUpHandler(_item.name)}
                      >
                        {_item?.value}
                      </Button>
                    ) : (
                      _item?.value
                    )
                  }
                />
              </List.Item>
            );
          }}
        />
      </Card>
      <Popup {...popupContent} />
    </>
  );
};

export default ListPanel;
