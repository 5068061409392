import React, { useState } from "react";

import {
    Col,
    Row,
    Button,
    Typography,
    Table,
    message,

} from "antd";

import { PlusOutlined } from "@ant-design/icons";

const { Text, } = Typography;

import { Popup } from "../../../atoms/Popup";
import AddParkings from "./modalContent/AddParkings";
import { currencyFormat } from "../../../../utils/media";
import { Loading } from "./modalContent";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { getBookingDetail, updateBooking } from "../ducks/actions";
import { parkingPayload } from "./utils";

export default (props) => {

    const { data, setParkingValue, update, disable } = props;

    const dispatch: any = useDispatch();
    const { leaseId = "" } = useParams();

    // const navigate = useNavigate();
    const [modalvisible, setModalvisible] = useState(false);
    const [modalTrigger, setModalTrigger] = useState(0);
    const [loader, setLoader] = useState(false);

    const updateParkings = async (data) => {
        setLoader(true);
        try {
            const response = await dispatch(updateBooking({ leaseId, body: { parkings: parkingPayload(data) } }));
            dispatch(getBookingDetail(leaseId));
            message.success(response.data.message);
            setLoader(false);
        } catch (error) {
            setLoader(false);
            message.error(
                error.response?.data?.error || error.response?.data?.message
            );
        }
    }

    const handleSave = async (data) => {
        setParkingValue && setParkingValue(data)
        update == true && await updateParkings(data);
    }

    const columns = [
        {
            title: "PARKING",
            dataIndex: "parking",
            render: (text, record, index) => (
                <Row>
                    <Col span={4}>
                        <Text
                            className="ag-dark fontWeight600"
                            style={{ fontSize: "14px" }}
                        >
                            {text}
                        </Text>
                    </Col>
                </Row>
            ),
        },
        {
            title: "PARKING TYPE",
            dataIndex: "parkingType",
            // width: 180,
            align: "left" as const,
            render: (text, item) => (
                // <div className="ag-primary"><OldAmount amount={item?.oldAnnualRent} /> {currencyFormat(+text)}</div>
                <span style={{ textTransform: "capitalize" }}>{text}</span>
            ),
        },
        {
            title: "BAY NO.",
            dataIndex: "bayNumber",
            // width: 150,
            align: "left" as const,
            render: (text, item) => (
                // <div className="ag-primary"><OldAmount amount={item?.oldAnnualRent} /> {currencyFormat(+text)}</div>
                text
            ),
        },
        {
            title: "AMOUNT",
            dataIndex: "actualAmount",
            // width: 180,
            align: "right" as const,
            render: (text, item) => (
                // <div className="ag-primary"><OldAmount amount={item?.oldAnnualRent} /> {currencyFormat(+text)}</div>
                +text > 0 ? currencyFormat((+text)) : "AED 0"
            ),
        },
    ];

    const popup = [
        {
            title: "",
            content: (
                <AddParkings
                    data={data}
                    handleSave={handleSave}
                    onClose={() => setModalvisible(false)} />
            ),
            closable: false,
            width: "60%",
        },
    ];

    const popupProps = {
        closable: true,
        visibility: modalvisible,
        title: popup[modalTrigger]?.title,
        content: popup[modalTrigger].content,
        width: popup[modalTrigger].width,
        onCancel: () => setModalvisible(false),
    };


    return (
        <>
            <Col span={24}>
                <Row gutter={24}>
                    <Loading visible={loader} />
                    {data?.length > 0 ? (<>
                        <Col span={24} className="ft-divider" />
                        <Col span={24}>

                            <Table
                                columns={columns}
                                dataSource={data.map((e, i) => ({ ...e, key: i }))} //nested copy
                                pagination={false}
                                bordered={false}
                                // showHeader={false}
                                className="sideTable"
                                size="small"
                            />
                        </Col>
                    </>) : <Col span={24} className="ft-spacer" />}

                    <Col span={24}>
                        <Button
                            type="dashed"
                            // className="p-0"
                            htmlType="button"
                            icon={<PlusOutlined />}
                            block={true}
                            disabled={disable}
                            onClick={() => { setModalTrigger(0); setModalvisible(true) }}
                        >
                            <Text
                                className="ag-primary fontWeight600"
                                style={{ fontSize: "14px" }}
                            >
                                Add or Edit Parking
                            </Text>
                        </Button>
                    </Col>
                </Row>
            </Col>
            <Popup destroyOnClose={true} {...popupProps} />
        </>
    );
};
