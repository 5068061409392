import React, { useState, useEffect } from "react";

import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import axios from "../../../../../utils/axiosInceptor";
import { getAllMaintenanceTypes, getMaintenanceTypes } from "../../ducks/actions";
import { InputField, ReactSelectField } from "../../../../atoms/FormElement";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { maintenanceService } from "../../../../../configs/constants";
import { BsPlusCircle, BsArrowCounterclockwise } from "react-icons/bs";
import { Row, Col, Button, Form, Typography, message, Space, Spin } from "antd";
import { useSelector } from "react-redux";

var _ = require("lodash");

// const defaultValues: any = {
//   name: "",
// };

const { Paragraph } = Typography;

const AddMaintanence = (props) => {
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const { residential, commercial, internal } = useSelector(
    (state: any) => state.configuration.allMaintenanceTypes
  );

  const { selected } = props;

  const orgId = JSON.parse(window.localStorage.getItem("login-response"))?.orgId;

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue
  } = useForm();

  const dispatch: any = useDispatch();


  useEffect(() => {
    dispatch(getAllMaintenanceTypes());
  }, []);

  useEffect(() => {
    reset();
    props?.setOnClose(false);
  }, [props?.onClose]);


  useEffect(() => {

    if (selected) {
      const residentialCategories = _.map(selected?.residentialCategories, (item) => { return { label: item.categoryName, value: item.categoryId } })
      const commercialCategories = _.map(selected?.commercialCategories, (item) => { return { label: item.categoryName, value: item.categoryId } })
      const internalCategories = _.map(selected?.internalCategories, (item) => { return { label: item.categoryName, value: item.categoryId } })
   
      setValue("residentialCategories", residentialCategories);
      setValue("commercialCategories", commercialCategories);
      setValue("internalCategories", internalCategories);

    } else {
      // console.log("SELLL",selectedCategories);
      reset();
    }

    // return () => {
    //   reset();
    // };
  }, [selected]);


  const onFinish = async (values) => {
    setLoader(true);
    setSubmited(true);

    const postJson = {
      residentialCategories: _.map(values?.residentialCategories, (item) => { return { "categoryId": item.value } }),
      commercialCategories: _.map(values?.commercialCategories, (item) => { return { "categoryId": item.value } }),
      internalCategories: _.map(values?.internalCategories, (item) => { return { "categoryId": item.value } }),
    };
    // console.log("POSTJSON",postJson);
    try {
      let url = `${maintenanceService}/serviceprovider/category/${orgId}`; ///maintenance/category`;
      await axios.patch(
        url,
        postJson,
        // { headers: { application: 4 } }
      );
      setTimeout(() => {
        setLoader(false);
        setSubmited(false);
        message.success("Maintenance Type updated successfully");
        dispatch(getMaintenanceTypes());
        props.setVisible(false);
      }, 800);
    } catch (e) {
      setLoader(false);
      setisError(true);
      const { response } = e;
      setErrorMessage(response?.data?.error);
    }
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
          <Row gutter={24}>
            {/* <Col span={12}>
              <InputField
                isRequired={true}
                fieldname="name"
                label="Add Category Title"
                control={control}
                rules={{
                  required: "Please enter category title",
                }}
                iProps={{ placeholder: "Plumbing" }}
                initValue=""
                validate={errors.name && "error"}
                validMessage={errors.name && errors.name.message}
              />
            </Col> */}
            <Col span={24}>
              <ReactSelectField
                isRequired={true}
                fieldname="residentialCategories"
                label="Residential Services"
                control={control}
                validate={errors.services && "error"}
                validMessage={
                  errors.services && errors.services.message
                }
                // onChange={(e) => onChangeOrganzation(e)}
                iProps={{ placeholder: "Select Services", isMulti: true }}
                rules={{ required: "Please select services" }}
                initValue=""
                selectOption={(residential && residential?.length) &&
                  residential?.map((item) => ({
                    value: item.categoryId,
                    label: item.name
                  }))
                }

              />
            </Col>
            <Col span={24}>
              <ReactSelectField
                isRequired={true}
                fieldname="commercialCategories"
                label="Commercial Services"
                control={control}
                validate={errors.services && "error"}
                validMessage={
                  errors.services && errors.services.message
                }
                // onChange={(e) => onChangeOrganzation(e)}
                iProps={{ placeholder: "Select Services", isMulti: true }}
                rules={{ required: "Please select services" }}
                initValue=""
                selectOption={(commercial && commercial?.length) &&
                  commercial?.map((item) => ({
                    value: item.categoryId,
                    label: item.name
                  }))
                }

              />
            </Col>
            <Col span={24}>
              <ReactSelectField
                isRequired={true}
                fieldname="internalCategories"
                label="Internal Services"
                control={control}
                validate={errors.services && "error"}
                validMessage={
                  errors.services && errors.services.message
                }
                // onChange={(e) => onChangeOrganzation(e)}
                iProps={{ placeholder: "Select Services", isMulti: true }}
                rules={{ required: "Please select services" }}
                initValue=""
                selectOption={(internal && internal?.length) &&
                  internal?.map((item) => ({
                    value: item.categoryId,
                    label: item.name
                  }))
                }

              />
            </Col>
          </Row>
          <Row gutter={24} justify="space-between">
            <Col>
              <Button
                className="ag-error theme_grey_btn"
                htmlType="button"
                onClick={() => {
                  reset();
                  props.setVisible(false);
                }}
              >
                {/* {<BsArrowCounterclockwise />} */}
                Back
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button theme_btn" htmlType="submit">
                {/* {<BsPlusCircle />} */}
                {props.selected.length > 0 ? " Update" : " Add"}
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0 sentanceCase">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    setSubmited(false);
                    setisError(false);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
    </Form>
  );
};

export default AddMaintanence;
