import axios from "../../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import {
  baseContractService,
  propertyService,
  leaseService,
} from "../../../../../configs/constants";

const cityUrl = `${propertyService}/property/cities`;
const stateUrl = `${propertyService}/property/regions`;
const countryUrl = `${propertyService}/property/countries`;
const configUrl = `${propertyService}/configData`;

const headers = {
  Authorization:
    "Bearer" +
    " " +
    JSON.parse(window.localStorage.getItem("login-response"))?.token,
};

export const getTenantDetails = (id) => {
  return async (dispatch) => { //${id}
    const url = `${leaseService}/tenant/user/${id}`;   ///leasing/getById?_id=${id}`;
    const {
      data: { result },
    } = await axios.get(url);
    dispatch({
      type: action_types.GET_TENANT_DETAILS,
      data: result,
    });
  };
};

export const getGeoData = async () => {
  const cityData = await axios.get(cityUrl);
  const stateData = await axios.get(stateUrl);
  const countryData = await axios.get(countryUrl);
  return {
      cityData,
      stateData,
      countryData,
    }
};

export const getConfigData = async (keys) => {
    let url = `${configUrl}?key=${keys}`;

    const {
      data: { result },
    } = await axios.get(url);

    return result
};

export const getTenantNationalities = async () => {
  return await axios.get(`${baseContractService}/nationalities`)
};

export const getTenantCities = async () => {
  return await axios.get(`${propertyService}/property/cities`);
};

export const getTenantCountires = async () => {
  return await axios.get(`${propertyService}/property/countries`);

};

export const getLease = (id) => {
  return async (dispatch) => {
    const url = `${leaseService}/leads/lease/${id}/list`;
    const {
      data: { result },
    } = await axios.get(url);
    dispatch({
      type: action_types.GET_TENANT_LEASE,
      data: result,
    });
  };
};


export const getDependants = (id, lease_id) => {
  return async (dispatch) => {
    // landlord/tenant/cooccupant/userId-fc018787-dc3e-4fe3-bf0e-63c8d0ba854b
    const url = `${leaseService}/landlord/tenant/cooccupant/${id}`; ///dependants/${lease_id}`;
    const {
      data: { result },
    } = await axios.get(url);
    dispatch({
      type: action_types.GET_DEPENDENTS,
      data: result,
    })
  }
}
  

export const emptyTenantDetail = () => {
  return (dispatch) => {
    dispatch({
      type: action_types.GET_EMPTY,
      data: {},
    });
  };
};
