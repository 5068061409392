import * as React from "react";
const index = (props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.5391 2.28125V7.90625H17.1641C17.6211 7.90625 18.0078 8.29297 18.0078 8.75C18.0078 9.24219 17.6211 9.59375 17.1641 9.59375H11.5391V15.2188C11.5391 15.7109 11.1523 16.0625 10.6953 16.0625C10.2031 16.0625 9.85156 15.7109 9.85156 15.2188V9.59375H4.22656C3.73438 9.59375 3.38281 9.24219 3.38281 8.75C3.38281 8.29297 3.73438 7.90625 4.22656 7.90625H9.85156V2.28125C9.85156 1.82422 10.2031 1.4375 10.6953 1.4375C11.1523 1.4375 11.5391 1.82422 11.5391 2.28125Z"
      fill="#24435D"
    />
  </svg>
);
export default index;
