import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Tabs,
  Input,
  Typography,
  Button,
  message,
  Tag,
  Select,
  Pagination,
  Table,
  Space,
  Switch,
  Spin,
} from "antd";
import { MailOutlined, SearchOutlined, EditOutlined } from "@ant-design/icons";

import Grid from "../../../atoms/Grid/";
import { Popup } from "../../../atoms/Popup";
import SectionHeader from "../../../molecules/SectionHeader";
import { maintenanceService } from "../../../../configs/constants";
import axios from "../../../../utils/axiosInceptor";
import { useSelector, useDispatch } from "react-redux";

import AddTechnician from "./components/AddTechnician";
import ApproveTechnician from "./components/ApproveTechnician";
import RejectTechnician from "./components/RejectTechnician";
import WarnInactiveTechnicial from "./components/SendInviteToTenant";

import {
  getAllTechnicians,
  invite,
  getMaintananceCategories,
} from "./ducks/actions";
import DashboardLayout from "../../../molecules/DashboardLayout";
import BreadCrumb from "../../../atoms/BreadCrumb";
import { getTechnicains } from "../iRoster/components/TechnicianSchedule/ducks/actions";
import AddRemoveSpecialzation from "./components/AddRemoveSpecialzation";
import EditTechnician from "./components/EditTechnician";

var _ = require("lodash");
const { Title } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;

const gridProps = {
  sortable: true,
  filter: true,
  resizable: true,
};

const breadcrumbList: any = [
  {
    label: "Maintenance",
    route: "/technicians",
  },
  {
    label: "Technicians",
    route: "/technicians",
  },
];

const limit = 20;

export default () => {
  const dispatch: any = useDispatch();
  const techlistApi = useSelector((state: any) => state.technicians.techData);
  const [visible, setVisible] = useState(false);
  const [warnVisible, setWarnVisible] = useState(false);
  const [assignmentUser, setAssignmentUser] = useState(null);
  const [tid, setTID] = useState(null);
  const [trigger, setTrigger] = useState(0);
  const [tabKey, setTabkey] = useState("1");
  const [searchText, setSearchText] = useState();
  const [page, setPage] = useState(1);
  const [techData, setTechData] = useState({});
  const [special, setSpecial] = useState([]);
  const [technicians, setTechnicians] = useState([]);
  const [techniciansAssigned, setTechniciansAssigned] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [invites, setInvites] = useState<React.Key[]>([]);
  const [loader, setLoader] = useState(false);

  const [loading, setLoading] = useState(false);
  // useSelector(
  //   (state) => state.rosterReducer.getTechnicians,
  // );

  const searchField = (
    <Input
      type="text"
      onChange={(e) => onSearch(e)}
      placeholder=""
      suffix={<SearchOutlined />}
    />
  );

  const technicianCol = [
    {
      headerName: "#",
      valueGetter: "node.rowIndex + 1",
      maxWidth: 100,
    },
    {
      headerName: "Technician Name",
      field: "first_name",
    },
    {
      headerName: "Email Address",
      field: "email",
    },
    {
      headerName: "Mobile Number",
      field: "number",
    },
    {
      headerName: "Area of Specialization",
      field: "speciality",
      cellRendererFramework: specialityRender,
    },
    {
      headerName: "Assigned Building",
      field: "property_name",
    },
    {
      headerName: "Status",
      field: "status",
      cellRendererFramework: statusRender,
    },
  ];

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setInvites(selectedRows.map((row) => row.number));
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record?.isSignUp === "Yes",
    }),
  };

  const updateTechStatus = async (assignStatus?) => {
    setLoader(true);

    if (assignmentUser || assignStatus) {
      const postJson = {
        ticketAssignment: assignStatus ? "yes" : "no",
      };
      const userId = assignmentUser?.userId || assignStatus.userId;
      const url = `${maintenanceService}/technician/user/${userId}`;
      try {
        await axios.patch(url, postJson);
        setLoader(false);
        message.success("Technician updated successfully");
        setAssignmentUser(null);
        dispatch(getAllTechnicians(page, limit, "")); //PAGE
      } catch (e) {
        setLoader(false);
        setAssignmentUser(null);
      }
    }
  };

  const popupWarn = {
    title: (
      <Title level={3} className="mb-0">
        Warning
      </Title>
    ),
    content: (
      <WarnInactiveTechnicial
        title={`This technician will not be able to receive tickets. Are you sure?`}
        onCancel={() => {
          // setFormValues(null)
          setWarnVisible(false);
          setAssignmentUser(null);
        }}
        onConfirm={() => {
          setWarnVisible(false);
          updateTechStatus();
        }}
      />
    ),
    width: 650,
  };

  const popupWarnProps = {
    closable: true,
    visibility: warnVisible,
    title: popupWarn.title,
    content: popupWarn.content,
    width: popupWarn.width,
    onCancel: () => {
      setWarnVisible(false);
      setAssignmentUser(null);
    },
  };

  const assignTechCol = [
    {
      title: "Technician Name",
      dataIndex: "first_name",
      key: "name",
      width: 220,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 220,
    },
    {
      title: "Mobile",
      dataIndex: "number",
      key: "number",
      width: 220,
    },
    {
      title: "Internal Categories",
      dataIndex: "internalCategories",
      key: "internalCategories",
      width: 220,
      render: (_, { internalCategories }) => {
        return (
          <>
            <span />
            {internalCategories?.map((tag) => {
              return (
                <Tag className="tag-sm" key={tag?._id}>
                  {tag?.name}
                </Tag>
              );
            })}
          </>
        );
      },
    },
    {
      title: "Residential Categories",
      dataIndex: "residentialCategories",
      key: "residentialCategories",
      width: 220,
      render: (_, { residentialCategories }) => {
        return (
          <>
            <span />
            {residentialCategories?.map((tag) => {
              return (
                <Tag className="tag-sm" key={tag?._id}>
                  {tag?.name}
                </Tag>
              );
            })}
          </>
        );
      },
    },
    {
      title: "Commercial Categories",
      dataIndex: "commercialCategories",
      key: "commercialCategories",
      width: 220,
      render: (_, { commercialCategories }) => {
        return (
          <>
            <span />
            {commercialCategories?.map((tag) => {
              return (
                <Tag className="tag-sm" key={tag?._id}>
                  {tag?.name}
                </Tag>
              );
            })}
          </>
        );
      },
    },
    {
      title: "Ticket Assignment",
      dataIndex: "ticketAssignment",
      key: "ticketAssignment",
      width: 220,
      render: (status, record) => {
        return (
          <Space size={4}>
            <div
              className="Simple-tooltip"
              style={{ fontSize: 20, paddingTop: 3 }}
            >
              <Switch
                className="switch"
                size="small"
                onChange={(e) => {
                  if (!e) {
                    setWarnVisible(true);
                    setAssignmentUser(record);
                  } else {
                    updateTechStatus(record);
                  }
                }}
                checked={status === "yes"}
              />
              <section className="tt-content">
                {status === "yes" ? `Yes` : "No"}
              </section>
            </div>
          </Space>
        );
      },
    },
    {
      title: "Registered",
      dataIndex: "isSignUp",
      key: "isSignUp",
      width: 220,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 220,
      render: (text, record) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => {
                if (record.isSignUp === "No") return sendInvite(record);
              }}
              className="p-0 ag-success"
            >
              {record.isSignUp === "No" ? (
                <div className="Simple-tooltip">
                  <MailOutlined />
                  <section className="tt-content">Send Invite</section>
                </div>
              ) : (
                <div>
                  <div className="Simple-tooltip">
                    <MailOutlined style={{ color: "grey" }} />
                    <section className="tt-content">Already Registered</section>
                  </div>
                </div>
              )}
            </Button>

            <Button
              type="link"
              onClick={() => {
                editTechnician(record);
              }}
              className="p-0 ag-success"
            >
              <div className="Simple-tooltip">
                <EditOutlined />
                <section className="tt-content">Edit Details</section>
              </div>
            </Button>
          </Space>
        );
      },
    },
  ];

  function statusRender(params) {
    if (params.value === "pending_approval") {
      return (
        <Button
          type="link"
          className="p-0 fontWeight600"
          onClick={() => approveTechnician(params)}
        >
          Pending Approval
        </Button>
      );
    } else if (params.value === "revoked") {
      return <span className="ag-error fontWeight600">Rejected</span>;
    } else if (params.value === "approved") {
      return <span className="ag-success fontWeight600">Approved</span>;
    } else {
      return (
        <span className="ag-success fontWeight600 Sentence">
          {params.value}
        </span>
      );
    }
  }

  function specialityRender2(params) {
    return (
      <Button
        type="link"
        // onClick={() => updateSpecialization(params.value, params.data.techid)}
        className="p-0 ag-success"
      >
        {params.value &&
          params.value.map((item, index) => (
            <Tag className="" key={index}>
              {item.name}
            </Tag>
          ))}
      </Button>
    );
  }

  function specialityRender(params) {
    return (
      <>
        {params.value &&
          params.value.map((item, index) => (
            <Tag className="tag-sm" key={index}>
              {item.name}
            </Tag>
          ))}
      </>
    );
  }

  function propertyRender(params) {
    const assignBuild = []; //useSelector((state) => state.technicians.techProperty);

    return (
      <Select
        className="Sentence"
        style={{ width: 150 }}
        placeholder="See Buildings"
        value={null}
        // onDropdownVisibleChange={() =>
        //   // dispatch(getTechProperties(params.data.id))
        // }
      >
        {_.map(assignBuild, (d, index) => (
          <Option key={index} value={d.id}>
            {d.name}
          </Option>
        ))}
      </Select>
    );
  }

  const onSearch = (e) => {
    if (e.target.value) {
      setPage(1);
      setSearchText(e.target.value);
      dispatch(getAllTechnicians(1, limit, searchText));
    } else {
      setSearchText(undefined);
    }
  };

  function handleShow(value) {}
  function onTopSearch(value) {}

  useEffect(() => {
    dispatch(getAllTechnicians(1, limit, ""));
    dispatch(getMaintananceCategories());
    // dispatch(getTechnicains(1, limit, ""));
  }, []);

  useEffect(() => {
    techlistApi && setTechnicians(techlistApi.data);
  }, [techlistApi]);

  useEffect(() => {
    technicians &&
      technicians?.length &&
      setTechniciansAssigned(
        technicians.map((item) => ({
          id: item?.userData[0]?._id,
          first_name: item?.userData[0]?.name,
          email: item?.userData[0]?.email,
          number: item?.userData[0]?.mobile,
          residentialCategories: item?.residentialCategories,
          internalCategories: item?.internalCategories,
          commercialCategories: item?.commercialCategories,
          userId: item?.userId,
          ticketAssignment: item?.ticketAssignment,
          isSignUp: item?.userData[0]?.isSignUp ? "Yes" : "No",
        }))
      );
  }, [technicians]);

  const onSubmit = () => {
    dispatch(getAllTechnicians(1, limit, ""));
    setPage(1);
    setVisible(false);
    setTID(null);
    setSpecial([]);
  };

  // const onAddTech = () => {
  //   // dispatch(getTechnicains(1, limit, ""));
  //   setPage(1);
  //   setVisible(false);
  // };

  const onPageChange = (e) => {
    setPage(e);
    dispatch(getAllTechnicians(e, limit, searchText));
  };

  const onAddTechnician = () => {
    setTechData({});
    setTrigger(4);
  };

  const popup = [
    {
      title: (
        <Title level={3} className=" mb-0">
          Add New Technician
        </Title>
      ),
      content: (
        <AddTechnician
          // onAddTech={onAddTech}
          onAddTechnician={onAddTechnician}
          onCancel={() => setVisible(false)}
          onSubmit={onSubmit}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="mb-0">
          New Technician Request Approval
        </Title>
      ),
      content: (
        <ApproveTechnician
          data={techData}
          onReject={rejectTechnician}
          onCancel={() => setVisible(false)}
          onApprove={onSubmit}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className=" mb-0">
          Reason for Rejection
        </Title>
      ),
      content: (
        <RejectTechnician
          data={techData}
          onReject={onSubmit}
          onBack={() => setTrigger(1)}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="mb-0">
          Add/Remove Specialization
        </Title>
      ),
      content: (
        <AddRemoveSpecialzation
          // onAddTech={onAddTech}
          data={special}
          id={tid}
          onCancel={() => {
            setVisible(false);
            setSpecial([]);
          }}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="mb-0">
          {techData &&
          Object.keys(techData).length === 0 &&
          techData.constructor === Object
            ? "Add Technician"
            : "Edit technician details and assign shifts"}
        </Title>
      ),
      content: (
        <EditTechnician
          onUpdateTech={onSubmit}
          data={techData}
          onCancel={() => {
            setVisible(false);
            setTechData(null);
          }}
        />
      ),
      width: 950,
    },
  ];

  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => setVisible(false),
  };

  function rejectTechnician() {
    setTrigger(2);
  }

  const onClickFilter = () => {};

  const addTechnician = () => {
    setVisible(true);
    setTrigger(0);
  };

  const updateSpecialization = (item, id) => {
    setVisible(true);
    setTrigger(3);
    setSpecial(item);
    setTID(id);
  };

  const approveTechnician = (item) => {
    setVisible(true);
    setTechData(item);
    setTrigger(1);
  };

  const editTechnician = (item) => {
    setVisible(true);
    setTechData(item);
    setTrigger(4);
  };

  const sendInvite = async ({ number }) => {
    let successMessage = "";
    setLoading(true);
    let payload = {
      contacts: [],
      usertype: "technician",
      inviteAll: false,
    };

    if (number) {
      payload.contacts.push(number);
      successMessage = "Invite sent to technician successfully";
    } else if (selectedRowKeys.length > 0) {
      payload.contacts = invites;
      setSelectedRowKeys([]);
      successMessage = "Invite sent to selected technician successfully";
    } else {
      payload.inviteAll = true;
      successMessage = "Invite sent to all technician successfully";
    }

    const status = await invite(payload, successMessage);
    setLoading(false);
    if (status) {
      message.success(successMessage);
      setSelectedRowKeys([]);
    } else {
      message.error("Error in sending Invite.");
    }
  };

  return (
    <>
      <DashboardLayout load={loading}>
        {loader && (
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Space
                size={24}
                className="w-100 text-center"
                direction="vertical"
              >
                <Spin size="large" className="ag-fontSize32" />
                {/* <Paragraph className="ag-default fontWeight600 mb-0">Loading</Paragraph> */}
              </Space>
            </Col>
          </Row>
        )}
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <BreadCrumb list={breadcrumbList} />
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <SectionHeader
              heading="Technicians"
              isDisabled={!(techniciansAssigned?.length > 0)}
              // total={
              //   tabKey == "1" ? technicians.length : techniciansData?.count
              // }
              //showOption={showOption}
              //handleShow={handleShow}
              // search={onTopSearch}
              // filterAction={onClickFilter}
              addNewAction={addTechnician}
              addMoreButton={sendInvite}
              addMoreText={
                selectedRowKeys.length > 0 ? "Invite Selected" : "Invite All"
              }
            />
            <Row gutter={[24, 24]}>
              <Col span={24}>
                {/* <Tabs
                  defaultActiveKey={tabKey}
                  onChange={(key) => setTabkey(key)}
                  tabBarExtraContent={searchField}
                  tabBarGutter={48}
                  className="ag-property-status"
                >
                  <TabPane tab="UnAssgined" key="1"> 
                    <Grid
                      data={[]}
                      columns={technicianCol}
                      pagination={false}
                      defaultSettings={gridProps}
                      // filterGrid={searchText}
                      noRowlabel="Technicians"
                      noRowbtnAction={addTechnician}
                    />
                  </TabPane>
                  <TabPane tab="Assigned" key="2">*/}
                <Row gutter={[20, 20]} justify="end">
                  <Col span={24}>
                    <Pagination
                      pageSize={20}
                      current={page}
                      defaultCurrent={1}
                      showSizeChanger={false}
                      total={techlistApi?.totalCount}
                      onChange={(e) => onPageChange(e)}
                      className="ag-property-text-pagination"
                      showTotal={(total, range) =>
                        `Showing ${range[0]}-${range[1]} of ${total} Service Providers`
                      }
                    />
                  </Col>
                  <Col span={24}>
                    <Table
                      columns={assignTechCol}
                      scroll={{ y: 500 }}
                      rowKey={(record, index) => index}
                      dataSource={techniciansAssigned}
                      rowSelection={rowSelection}
                      className="custom-table org_table_wrap"
                      pagination={false}
                    />

                    {/* <Grid
                      data={techniciansAssigned}
                      columns={assignTechCol}
                      pagination={false}
                      defaultSettings={gridProps}
                      filterGrid={searchText}
                      noRowlabel="Technicians"
                      noRowbtnAction={addTechnician}
                    /> */}
                  </Col>
                </Row>
                {/* </TabPane>
                </Tabs> */}
              </Col>
            </Row>
          </Col>
        </Row>
      </DashboardLayout>
      <Popup destroyOnClose={true} {...popupProps} />
      <Popup destroyOnClose={true} {...popupWarnProps} />
    </>
  );
};
