import React, { useState, useEffect } from "react";
import RequireDocument from "../../../molecules/RequirDoc";
import { Col, Form, Row, Typography, message } from "antd";
import { uniquiFileName } from "../../../../utils/fileUpload";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import OtherDocs from "../../ContractFlow/ContractBookingForm/OtherDocs";
import GeneratedDoc from "../../../molecules/GeneratedDoc";
import { PopupConfirm } from "../../../atoms/Popup";
import axios from "axios";
import { leaseService } from "../../../../configs/constants";
import { CommonCard } from "../components";
import { useDispatch } from "react-redux";
import {
  createBooking,
  deleteBooking,
  getBookingDetail,
  getConfigData,
  setBookingForm,
  setFormState,
  updateBooking,
} from "../ducks/actions";
import { useSelector } from "react-redux";
import AddDocs from "./AddDocs";
import RequiredDocs from "./RequiredDocs";
import { useNavigate, useParams } from "react-router";
import { title } from "process";

const fileObjectinit = {
  passportFile: {
    uniqueName: "",
    fileData: "",
  },
  visaFile: {
    uniqueName: "",
    fileData: "",
  },
  emiratesFile: {
    uniqueName: "",
    fileData: "",
  },
  financialSupportFile: {
    uniqueName: "",
    fileData: "",
  },
  signedBookFile: {
    uniqueName: "",
    fileData: "",
  },
  otherDocsFile: {
    uniqueName: "",
    fileData: "",
  },
};

export default (props) => {
  const {
    LEASEID,
    leaseData,
    RejectDocument,
    ApproveDocument,
    resetField,
    // isAdd,
    disableClone,
    isRenewal,
    tenantAccepted,
    popup,
    generatedDocs,
    onUpdate,
    sendForEsignature,
    contractSent,
    sendSignatureBtn,
    defaultDocumentsList
  } = props;

  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const {
    fields: requiredDocuments,
    append: append,
    remove: remove,
  } = useFieldArray({
    control,
    name: "requiredDocuments",
  });


  const isAdd = true;
  const { Paragraph } = Typography;
  // redux states
  const { configData, bookingForm, bookingDetails } = useSelector(
    (state: any) => state.newBooking
  );

  const navigate = useNavigate();
  const { leaseId = bookingDetails.leaseId } = useParams();

  const isBooking = Object.keys(bookingDetails).length > 0;

  const dispatch: any = useDispatch();

  const onRemoveDocument = async (key) => {
    PopupConfirm({
      title: `Are you sure you want to remove`,
      okText: "Yes",
      onOk: async () => {
        // api/v1/leasing/removeDocument/leaseId-13a34c9d-863f-46fb-98ed-7594b5cf5501/ADDITIONAL_DOCUMENT'
        const url = `${leaseService}/leasing/removeDocument/${LEASEID}/ADDITIONAL_DOCUMENT`;
        await axios
          .get(url, {})
          .then((response) => {
            message.success("Removed document");
            onUpdate();
          })
          .catch((error) => {
            message.error(error.response.data.error);
          });
      },
    });
  };

  useEffect(() => {
  
    setValue("requiredDocuments", []);
    if (bookingDetails && bookingDetails?.requiredDocuments?.length > 0) {
      const arr = [];
      let requiredDocumentsDetail = [...bookingDetails?.requiredDocuments];
      requiredDocumentsDetail.map((data) => {
        if(data?.documentLabel &&  data?.documentId && data?.isExpired)
          {
          arr.push({
            type: data?.type,
            documentLabel: data?.documentLabel,
            status: data?.status,
            documentId: data?.documentId,
            isExpired: data?.isExpired,
            checked : data?.checked,
            isExpiry:data?.isExpiry
          });
        }else {
          arr.push({
            type: data?.type,
            status: data?.status,
            title:data.title,
            checked : data?.checked,
            isExpiry:data?.isExpiry
          });
        }
        
      });
      console.log("datacopy", arr);
      append(arr);
      // setValue("requiredDocuments", arr);
      // setRequiredDocArray(arr);
    } else {
      configData?.configuredDocuments?.length > 0 && append(configData?.configuredDocuments);
      // setRequiredDocArray(arr);
    }
  
  }, [bookingDetails]);

  const documentHandler = (data) => {
    switch (data.action) {
      case "reject":
        RejectDocument(data);
        break;
      case "accept":
        ApproveDocument(data);
        break;
      default:
        break;
    }
  };

  const onSubmit = async (data: any, type) => {
    let dataCopy = { ...(data || getValues()) };
    // console.log("datacopy", dataCopy);

    // console.log(dataCopy?.requiredDocuments,'Testing')
    const selectedDocs =
      dataCopy?.requiredDocuments?.length > 0
        ? dataCopy?.requiredDocuments
          ?.filter((d) => d?.checked === true)
          // .map((i) => ({ ...i, type: i.type, status: "pending" }))
        : [];
        console.log("datacopy====", selectedDocs);
        //  return false
    const additionalDocs =
      dataCopy?.additionalDocs?.length > 0
        ? dataCopy?.additionalDocs?.map((i) => ({
          type: i?.type?.value,
          status: "pending",
          documentLabel: i?.documentLabel
        }))
        : [];

    let body = {
      requiredDocuments: [...selectedDocs, ...additionalDocs],
    };

    let valid = true;

    //   for (let key in dataCopy) {
    //     if (!excludedLeaseTermsKeys.includes(key) && !dataCopy[key]) {
    //       valid = false;
    //       await trigger("", { shouldFocus: true });
    //       break;
    //     }
    //   }

    if (valid) {
      // dispatch(setFormState());
      setValue("requiredDocuments", []);
      body = { ...bookingForm, ...body };
      dispatch(setBookingForm(body));
      props.parentState({ load: true });

      try {
        //removed as per bug: 8167 ----> ADDED AGAIN TO REGAIN THE FLOW LOGIC
        if (type === "done") body["createBooking"] = "yes"; //moved this condition down to not store this in redux

        const response = await dispatch(
          !isBooking ? createBooking(body) : updateBooking({ leaseId, body }) //removed: onlyBooking: type != "done" as discussed with ajay/vish //ADDED To fix /details in endpoint for done case
        );

        !isBooking && (await dispatch(getBookingDetail(response.data?.result)));

        setValue("additionalDocs", []);
        props.parentState({ load: false });
        message.success(response.data.message);
      if (type === "done") navigate("/bookings", { state: { clear: true } });
      } catch (error) {
        props.parentState({ load: false });
        message.error(
          error.response?.data?.error || error.response?.data?.message
        );
      }
    }
  };

  let mapedDocuments = bookingDetails?.requiredDocuments?.length > 0 ? bookingDetails?.requiredDocuments :configData?.configuredDocuments 
// console.log(configData,'configData')
  return (
    <Form
      layout="vertical"
      name="bookingform"
      scrollToFirstError
      className="bf-main"
      onFinish={handleSubmit(onSubmit)}
    >
      <CommonCard
        label="Documents"
        leftBtnLabel="Back"
        rightBtnLabel="Done"
        handleDone={onSubmit}
        handleBack={() => dispatch(setFormState("back"))}
        additionalLabel="Select documents required by the tenant during the booking process"
        parentState={props.parentState}
      >
        <Col span={24}>
          <Row gutter={24}>
            {mapedDocuments?.map((val, index) => {
              return (
                <React.Fragment key={val?.title+index}>
                  <Col span={24}>
                    <RequiredDocs
                      setValue={setValue}
                      control={control}
                      index={index}
                      document={val}
                      isRenewal={isRenewal}
                      tenantAccepted={tenantAccepted}
                      documentHandler={documentHandler}
                      // uploadFiles={uploadFiles}
                      docLabel={
                        configData["requiredBookingDocumentsList"]
                      }
                    />
                  </Col>
                </React.Fragment>
              );
            })}
            {generatedDocs?.map((item, index) => {
              return (
                item?.addToLease === true &&
                item?.documentId && (
                  <React.Fragment key={index}>
                    <Col span={24}>
                      <GeneratedDoc
                        LEASEID={LEASEID}
                        title={item.label}
                        setValue={setValue}
                        onUpdate={onUpdate}
                        control={control}
                        sendForEsignature={sendForEsignature}
                        contractSent={contractSent}
                        sendSignatureBtn={sendSignatureBtn}
                        data={item}
                        documentId={item.documentId}
                        onRemoveDocument={() =>
                          onRemoveDocument(item?.documentName)
                        }
                      />
                    </Col>
                  </React.Fragment>
                )
              );
            })}
            <Col span={24} className="other-docs">
              {/* {requiredBookingDocumentsList?.length > 0 && ( */}
              <>
                <AddDocs
                  setValue={setValue}
                  resetField={resetField}
                  errors={errors}
                  control={control}
                  isAdd={isAdd}
                  data={leaseData}
                  getValues={getValues}
                  requiredDocArray={requiredDocuments}
                  disableClone={disableClone}
                  requiredBookingDocumentsList={
                    configData["requiredBookingDocumentsList"]
                  }
                  page={"lease"}
                />
              </>
              {/* )} */}
            </Col>
          </Row>
        </Col>
      </CommonCard>
    </Form>
  );
};
