// others
import axios from "../../../../utils/axiosInceptor";
import {
  leaseService,
  contractService,
  propertyService,
  TRANSLATION_URL,
  EQARI_GOOGLE_KEY_TRANSLATION,
  getUserService
} from "../../../../configs/constants";

// get config data service
export const getConfigDataService = async (keys: Array<string>) => {
  const config: any = {
    method: "GET",
    url: `${propertyService}/configData?key=${keys.join(",")}`,
  };

  try {
    const response = await axios(config);

    if (response.status === 200) return response.data?.result;
  } catch (error) {
    return error.response;
  }
};

// get users only lease executive and lease manager data service
export const getUsers = async () => {
  const config: any = {
    method: "GET",
    url: `${getUserService}`,
  };

  try {
    const response = await axios(config);

    if (response.status === 200) return response.data?.result;
  } catch (error) {
    return error.response;
  }
};

// get booking detail service
export const updateDocumentExpiry = async (leaseId:string ,payload: any) => {




  const config: any = {
    method: "PATCH",
    url: `${contractService}/booking/documentUpdate/${leaseId}`,
    data:payload,
  };

  try {
    const response = await axios(config);
    return response;
  } catch (error) {
    return error.response;
  }
};

// get booking detail service
export const getBookingDetailService = async (leaseId: string) => {
  const config: any = {
    method: "GET",
    url: `${contractService}/booking/${leaseId}`,
  };

  try {
    const response = await axios(config);
    
    
    if (response.status === 200) return response.data?.result;
  } catch (error) {
    return error.response;
  }
};

// get booking unit detail service
export const getBookingUnitDetailService = async (unitId: string) => {
  const config: any = {
    method: "GET",
    url: `${contractService}/unit/bookingUnitDetail/${unitId}`,
  };

  try {
    const response = await axios(config);

    if (response.status === 200) return response.data?.result;
  } catch (error) {
    return error.response;
  }
};

// create booking service
export const creatreBookingService = async (data: object) => {
  const config: any = {
    data,
    method: "POST",
    url: `${leaseService}/booking`,
  };

  return await axios(config);
};

// update booking service
export const updateBookingService = async (data: any) => { 
  
  let url:any = '' ;

  if(data?.onlyBooking){
 
      url = `${leaseService}/booking/${data.leaseId}`;
  }else if(data['body']?.onlyBooking){
    delete data?.body?.onlyBooking;
    url = `${leaseService}/booking/${data.leaseId}`;
  }else{
    url =  `${leaseService}/booking/details/${data.leaseId}`
  }

  const config: any = {
    url,
    data: data.body,
    method: "PATCH",
  };
 
  return await axios(config);
};

export const getTermsService = async (propertyId: any, usagesType: any) => {
  const config: any = {
    method: "GET",
    url: `${leaseService}/booking/termsAndConditions/${propertyId}/${usagesType}`,
  };

  const {
    data: { result },
  } = await axios(config);

  return result;
};

// generate template-document service
export const generateDocumentService = async (data: any) => {

  let refreshDoc = data?.regenerate ? true : false;
  const config: any = {
    data: data.body,
    method: "GET",

    url: `${leaseService}/leasing/createContract/${data.leaseId}?type=${data?.document}&regenerate=${refreshDoc}`,
  };

  return await axios(config);
};
// update booking service
export const createRenewalService = async (data: any) => {
  const config: any = {
    data: data.body,
    method: "POST",
    url: `${leaseService}/booking/renewal/${data.leaseId}`,
  };

  return await axios(config);
  return null;
};

// update pdc service
export const updatePdcService = async (data: any) => {
  const config: any = {
    data: data,
    method: "POST",
    url: `${leaseService}/booking/pdc`,
  };

  return await axios(config);
};

// get tenant detail by email service
export const getTenantDetailByEmailService = async (email: string) => {
  const config: any = {
    method: "POST",
    data: { email },
    url: `${leaseService}/booking/tenant/lease/email`,
  };

  try {
    const response = await axios(config);

    if (response.status === 200) return response.data?.result;
  } catch (error) {
    return error.response;
  }
};

// get landlord users service
export const getLandlordUsersService = async (propertyId: string) => {
  const config: any = {
    method: "GET",
    url: `${leaseService}/masterlandlord/user?propertyId=${propertyId}`,
  };

  try {
    const response = await axios(config);

    if (response.status === 200) return response.data?.result;
  } catch (error) {
    return error.response;
  }
};

// delete booking service
export const deleteBookingService = async (leaseId: string) => {
  const config: any = {
    method: "DELETE",
    url: `${leaseService}/booking/discard/${leaseId}`,
  };

  try {
    const response = await axios(config);

    if (response.status == 200) {
      return {
        data: {},
        status: response.status,
      };
    }
  } catch (error) {
    return error.response;
  }
};

// payment plans service
export const getPaymentPlansService = async (
  unit: string,
  furnished: string
) => {
  const config: any = {
    method: "GET",
    url: `${leaseService}/unit/paymentPlan?unitCode=${unit}&furnished=${furnished}`,
  };

  try {
    const response = await axios(config);

    if (response.status === 200) return response.data?.result;
  } catch (error) {
    return error.response;
  }
};

// get translation
export const translateService = async (text: string) => {
  const params = new URLSearchParams({
    q: text,
    source: "en",
    target: "ar",
    key: EQARI_GOOGLE_KEY_TRANSLATION,
  });

  const config: any = {
    method: "GET",
    url: `${TRANSLATION_URL}?${params.toString()}`,
  };

  try {
    const response = await axios(config);

    if (response.status === 200) return response?.data?.data?.translations[0];
  } catch (error) {
    return error.response;
  }
};

// save generated-document to lease service
export const saveDocumentToLeaseService = async (data: any) => {
  const config: any = {
    data: data.body,
    method: "PATCH",
    url: `${leaseService}/leasing/${data.leaseId}`,
  };

  return await axios(config);
};

// update booking status service
export const updateBookingStatusService = async (data: any) => {

  const config: any = {
    data: data.body,
    method: "PATCH",
    url: `${leaseService}/booking/${data.leaseId}`,
  };

  return await axios(config);
};

// get template documents service
export const getTemplateDocumentService = async (data?: any) => {
  const config: any = {
    data: data?.body,
    method: "GET",
    url: `${leaseService}/leasing/orgDocumentsList`,
  };

  try {
    const response = await axios(config);
    if (response.status === 200) return response.data?.result;
  } catch (error) {
    return error.response;
  }
};
// get kyc details service
export const getKycDetailsService = async (unitId: string) => {
  const config: any = {
    method: "GET",
    url: `${leaseService}/booking/kycform/data?unitId=${unitId}`,
  };

  try {
    const response = await axios(config);

    if (response.status === 200) return response.data?.result;
  } catch (error) {
    return error.response;
  }
};

// send Contract For tenant Sign service
export const sendContractForSignService = async (data: any) => {
  const config: any = {
    method: "GET",
    url: `${leaseService}/leasing/sendContractForSign/${data.leaseId}?type=${data.document}`,
  };

  return await axios(config);
};

// get parking bays service
export const getParkingBayService = async (data?: any) => {
  // unit?orgId=${orgId}&perPage=10000&status=enable&parking=yes
  //"orgId-742db758-d499-401b-b360-c20aecefcc1b"
  const config: any = {
    data: data?.body,
    method: "GET", ///parkingList/:propertyId
    url: `${leaseService}/unit/parkingList/${data.propertyId}?unitStatus=vacant`,
  };

  try {
    const response = await axios(config);
    if (response.status === 200) return response.data?.result;
  } catch (error) {
    return error.response;
  }
};

export const CreateEjariContract = async (leaseId) => {
  let body = { "leaseId": leaseId };
  const config: any = {
    method: "POST",
    url: `${leaseService}/ejari/createEjariContract`,
    data: body
  };
  return await axios(config);
};
