import React from "react";

// others
import { Row, Col } from "antd";
import { useSelector } from "react-redux";
import { CommonCard } from "../components";
import { Forward } from "../../../../assets/svg";

interface IProps {
  label: string;
}

const index = (props: IProps) => {
  // redux states
  const { tracker } = useSelector((state: any) => state.newBooking);

  return (
    <CommonCard label={props.label}>
      <Row>
        {tracker.map((item, index) => {
          return (
            <Col key={`${index}`} span={6} className="ft-item-container">
              <div
                className={`ft-active-container ${
                  item.active && "ft-active"
                }  ${item.complete && "ft-complete"}`}
              >
                <p
                  className={`ft-count-label ${
                    item.active && "ft-active-label"
                  }  ${item.complete && "ft-active-label-complete"}`}
                >
                  {index + 1}
                </p>
              </div>

              <div>
                <p className="ft-title">{item.title}</p>
                <p className="ft-label">{item.label}</p>
              </div>

              {index !== tracker.length - 1 && <Forward />}
            </Col>
          );
        })}
      </Row>
    </CommonCard>
  );
};

export default index;
