import React from "react";

// others
import { Col, Button } from "antd";
import Grid from "../../../../atoms/Grid";
import { useNavigate } from "react-router-dom";
import { apiDateFormat, findLeaseStatus, numberWithCommas } from "../utils";

interface IProps {
  renewals: any;
}

const index = (props: IProps) => {
  // hooks
  const navigate = useNavigate();

  // variables
  const COLUMN_WIDTH: number = 180;

  const columns = [
    {
      colId: 1,
      width: COLUMN_WIDTH,
      field: "renewalStatus ||leaseStatus",
      headerName: "Status",
      cellRenderer: ({ data }) =>
        findLeaseStatus[data?.renewalStatus || data?.leaseStatus],
    },
    {
      colId: 2,
      width: COLUMN_WIDTH,
      headerName: "Property",
      field: "propertyDetails.propertyName",
    },
    {
      colId: 3,
      width: COLUMN_WIDTH,
      headerName: "Unit Number",
      field: "propertyDetails.unitCode",
    },
    {
      colId: 4,
      width: COLUMN_WIDTH,
      headerName: "Tenant",
      field: "tenantDetails.tenantNameEN",
    },
    {
      colId: 5,
      width: COLUMN_WIDTH,
      headerName: "Annual Rent",
      field: "bookingTerms.renewalAmount",
      cellRenderer: ({ data }) =>
        numberWithCommas(data?.bookingTerms?.renewalAmount),
    },
    {
      colId: 6,
      width: COLUMN_WIDTH,
      headerName: "Payments",
      field: "bookingTerms.numberOfInstallments",
    },
    {
      colId: 7,
      width: COLUMN_WIDTH,
      headerName: "Start Date",
      field: "leaseTerms.leaseStartDate",
      cellRenderer: ({ data }) =>
        apiDateFormat(data?.leaseTerms?.leaseStartDate, "DD-MM-YYYY"),
    },
    {
      colId: 8,
      width: COLUMN_WIDTH,
      headerName: "End Date",
      field: "leaseTerms.leaseEndDate",
      cellRenderer: ({ data }) =>
        apiDateFormat(data?.leaseTerms?.leaseEndDate, "DD-MM-YYYY"),
    },
    {
      colId: 9,
      field: "leaseType",
      width: COLUMN_WIDTH,
      headerName: "Contract Type",
    },
    {
      colId: 10,
      width: COLUMN_WIDTH,
      headerName: "AWB No",
      field: "shipmentNumber",
    },
    {
      colId: 11,
      filter: false,
      headerName: "Action",
      maxWidth: COLUMN_WIDTH,
      cellStyle: { textAlign: "center" },
      cellRendererFramework: ({ data }) => {
        const { leaseId } = data;

        return (
          <Button
            type="primary"
            onClick={() =>
              navigate(`/viewbookingform/${leaseId}`, {
                state: { renewal: true },
              })
            }
          >
            View
          </Button>
        );
      },
    },
  ];

  return (
    <Col span={24} className="view-booking">
      <Grid columns={columns} data={props.renewals} />
    </Col>
  );
};

export default index;
