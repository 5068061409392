import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "antd";
import { InputField, ReactSelectField } from "../../../atoms/FormElement";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getBookingRejectionReason } from "./ducks/actions";

var _ = require("lodash");
const RejectForm = (props) => {
  const { closePopup, setRejectionReason, setRejectionComment, rejectionStatus } = props;
  const dispatch:any = useDispatch();
  const [status, setStatus] = useState(0);
  const { control, formState: { errors }, setValue, handleSubmit } = useForm();

  const reasonsData = useSelector(
    (state: any) => state.contractBookingForm.getBookingRejectReason,
  );

  useEffect(() => {
    setStatus(rejectionStatus)
    dispatch(getBookingRejectionReason());
  }, [rejectionStatus]);

  const handleReject = (data) => {
   if(status>0){
    setRejectionReason(data?.reason?.value);
   }else{
    setRejectionReason(4)
   }
    setRejectionComment(data?.rejectReason);
    closePopup();
  };
  return (
    <Form layout="vertical" onFinish={handleSubmit(handleReject)}>
      <Row gutter={24}>
      {status > 0 &&
        <Col span={12}>
          <ReactSelectField
            label="Select a reason"
            fieldname="reason"
            iProps={{ placeholder: "Select reasons" }}
            initValue=""
            setValue={setValue}
            isRequired={false}
            control={control}
            rules={{ required: "Required" }}
            validate={errors.reason && "error"}
            validMessage={errors.reason && errors.reason.message}
            selectOptions={[{ label: "yes", value: "yes" }]}
            selectOption={_.map(reasonsData, (e) => {
               return e.id!=4 ? {
                  label: e.name,
                  value: e.id,
                }:null
            }).filter(elem => elem != null)}
          />
        </Col>
        }
        <Col span={24}> 
          <InputField
            label='Enter your comments:'
            fieldname="rejectReason"
            iProps={{
              placeholder: "Some reason....",
              style: { height: "70px" },
            }}
            initValue=""
            setValue={setValue}
            control={control}
            isRequired={true}
            rules={{ required: "Reason Required" }}
            validate={errors.rejectReason && "error"}
            validMessage={errors.rejectReason && errors.rejectReason.message}
          />
        </Col>
      </Row>
      <Row gutter={24} justify="space-between">
        <Col>
          <Button size="large" onClick={closePopup}>
            Back
          </Button>
        </Col>
        <Col>
          <Button size="large" className="ag-red-button" htmlType="submit">
            {status>0?'Reject':'Return'}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default RejectForm;
