import React from "react";
import { Layout } from "antd";
import Header from "../../molecules/Header";
import Sidebar from "../../molecules/Sidebar";

import { dashboard } from "../../../configs/constants";
const { Content } = Layout;

type IProps = {};
type IState = { tabKey: string };

class Dashboard extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      tabKey: "1",
    };
  }

  url = () => {
    let loginDetails = JSON.parse(window.localStorage.getItem("login-response"));
    const type = (loginDetails?.usertype?.length > 0 && loginDetails?.usertype?.find(item => item === "serviceProvider"))
      ? 'fm' : 'landlord';
    return dashboard + `?token=${loginDetails?.token}&type=${type}`;
  }

  render() {
    return (
      <Layout className="main-layout">
        <Header />
        <Layout>
          <Sidebar />
          <Layout className="layout-space">
            <Content className="main-content">
              <iframe
                style={{
                  flex: 1,
                  width: "100%",
                  height: "100%",
                  borderWidth: 0,
                  display: "flex",
                }}
                allowFullScreen={true}
                title="Agi-Properties-ORION-BO"
                src={this.url()}
              ></iframe>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    );
  }
}
export default Dashboard;
