
import React, { useState, useEffect, useRef } from "react";
import {
  Layout,
  Row,
  Col,
  Breadcrumb,
  Steps,
  Button,
  message,
  Spin,
  Card,
  Form,
} from "antd";

import { LoadingOutlined } from "@ant-design/icons";

import axios from 'axios';

import { Controller, useForm } from "react-hook-form";
import {
  leaseService, serverUrl
} from "../../../../../configs/constants";
import type { UploadFile } from 'antd/es/upload/interface';
import { InboxOutlined } from '@ant-design/icons';


import { CSVLink } from "react-csv";
import { BsXCircleFill, BsDownload, BsCloudUpload, BsFillCheckCircleFill } from "react-icons/bs";
import { Upload } from 'antd';
import { RcFile } from "antd/lib/upload/interface";
const { Dragger } = Upload;
const { Content } = Layout;

var _ = require("lodash");



// CreateLease;

export default (props) => {
  // const [uploadProgress, setUploadprogress] = useState(0);

  const [failedData, setFailed] = useState([]);
  const [successData, setSuccess] = useState(0);
  const [loading, setLoading] = useState(false);

  const { control, formState: { errors }, setValue, handleSubmit } = useForm();

  const headers = [
    { label: "Tenant Name", key: "tenantname" },
    { label: "Ejari Contract Number", key: "ejaricontractnumber" },
    { label: "Email", key: "email" },
    { label: "Mobile", key: "mobile" },
    { label: "Reason", key: "reason" }
  ];

  const csvReport = {
    data: failedData,
    headers: headers,
    filename: 'Lease_Failure_log.csv'
  };



  // csv
  const [file, setFile] = useState();
  const [array, setArray] = useState([]);

  // const [filelist, setfilelist] = useState([]);
  const [leaseFile, setLease] = useState<UploadFile[]>([]);

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };


  const resetModal = () => {
    setLease([]);
    setFailed([]);
    setSuccess(0);
  }

  // DUMMY SUCCESS RESP
  //   var x = {
  //     "success": true,
  //     "code": 200,
  //     "message": "Success",
  //     "result": {
  //         "tenantBulkData": [
  //             {
  //                 "name": "Prashant",
  //                 "email": "prashant@yopmail.com",
  //                 "mobile": "00971501234555",
  //                 "maritalStatus": "Married",
  //                 "numberOfOcupants": "2",
  //                 "poBox": "23431",
  //                 "nationality": "India",
  //                 "passport": "12345",
  //                 "passportExpiry": "2022-12-25",
  //                 "emiratesId": "78419879254457",
  //                 "emirateIdExpiry": "2025-12-25",
  //                 "visaNumber": "5478455",
  //                 "visaExpiry": "2025-12-25",
  //                 "tenantType": "Residential",
  //                 "tradeLicenseExpiry": "",
  //                 "trnExpiry": "",
  //                 "emirates": "",
  //                 "businessUnit": "businessUnitId-0a0bc7ec-3a20-4a33-baf0-51ebba13daa7",
  //                 "landlord": "userId-b047efec-2aa5-4286-8510-65402ac1f54e"
  //             }
  //         ],
  //         "bulkDataToCreateLease": [
  //             {
  //                 "orgId": "orgId-176b2134-42b1-4bd9-af42-56a743c60793",
  //                 "businessUnitId": "businessUnitId-0a0bc7ec-3a20-4a33-baf0-51ebba13daa7",
  //                 "unitId": "unitId-79ef789c-d89d-4aaa-868e-6a07d11891df",
  //                 "propertyId": "propertyId-396eb413-7f72-466b-bcdd-fb4a7c72c93b",
  //                 "tenantId": "userId-8bb21e74-1a48-40a1-9b63-035a92591333",
  //                 "landlordId": "userId-b047efec-2aa5-4286-8510-65402ac1f54e",
  //                 "unitStatus": "occupied",
  //                 "leaseStatus": "active",
  //                 "leaseType": "new",
  //                 "shippingStatus": "",
  //                 "ejari": {
  //                     "contractNumber": "123342789",
  //                     "version": null,
  //                     "registrationDate": "",
  //                     "startDate": "2022-12-25",
  //                     "endDate": "2024-01-24",
  //                     "tenantNumber": "34442878"
  //                 },
  //                 "propertyDetails": {
  //                     "propertyName": "Manazil Al Barsha Second 08",
  //                     "dewa": "621179392",
  //                     "unitCode": "197897-G-01",
  //                     "usagesType": "Residential",
  //                     "unitType": "Townhouse",
  //                     "unitSubType": "1BR",
  //                     "unitNumber": "01",
  //                     "areaSqft": 1772,
  //                     "address": {
  //                         "addressLine1": "Manazil Al Barsha Second 08",
  //                         "addressLine2": "Al Barsha First, Bur Dubai,",
  //                         "community": "Al Baraha",
  //                         "emirates": "Dubai",
  //                         "country": "United Arab Emirates",
  //                         "longitude": "55.3309",
  //                         "createdAt": "2023-01-12T07:49:45.162Z",
  //                         "updatedAt": "2023-01-12T07:49:45.162Z"
  //                     }
  //                 },
  //                 "tenantDetails": {
  //                     "tenantNameEN": "Prashant",
  //                     "tenantNameAR": "",
  //                     "email": "prashant@yopmail.com",
  //                     "mobileNumber": "00971501234555",
  //                     "tenantType": "Residential",
  //                     "nationality": "India",
  //                     "passportNumber": "12345",
  //                     "passportExpiry": "2022-12-25",
  //                     "emiratesID": "78419879254457",
  //                     "visaNumber": "5478455",
  //                     "visaExpiry": "2025-12-25",
  //                     "tradeLicense": "",
  //                     "tradeLicenseExpiry": "",
  //                     "trnNumber": "",
  //                     "trnExpiry": ""
  //                 },
  //                 "leaseTerms": {
  //                     "leaseType": "New",
  //                     "leaseNature": "Family",
  //                     "noOfCheques": 4,
  //                     "contractAmount": 60000,
  //                     "securityDeposit": 6000,
  //                     "movingDate": "",
  //                     "leaseStartDate": "2022-12-25",
  //                     "leaseEndDate": "2024-01-24",
  //                     "rentFreeStartDate": "2023-12-25",
  //                     "rentFreeEndDate": "2024-01-24"
  //                 },
  //                 "billingSchedule": [],
  //                 "paymentSchedule": [],
  //                 "requiredDocuments": [],
  //                 "documents": [],
  //                 "shippingDetails": {},
  //                 "leaseStatusHistory": [
  //                     {
  //                         "status": "uploaded",
  //                         "updatedBy": "userId-b047efec-2aa5-4286-8510-65402ac1f54e"
  //                     }
  //                 ],
  //                 "createdBy": "userId-b047efec-2aa5-4286-8510-65402ac1f54e",
  //                 "updatedBy": "userId-b047efec-2aa5-4286-8510-65402ac1f54e"
  //             }
  //         ],
  //         "leasingRes": [
  //             {
  //                 "leaseId": "leaseId-a3829764-9663-4e98-ba3a-04b751eaf5c1",
  //                 "orgId": "orgId-176b2134-42b1-4bd9-af42-56a743c60793",
  //                 "businessUnitId": "businessUnitId-0a0bc7ec-3a20-4a33-baf0-51ebba13daa7",
  //                 "unitId": "unitId-79ef789c-d89d-4aaa-868e-6a07d11891df",
  //                 "propertyId": "propertyId-396eb413-7f72-466b-bcdd-fb4a7c72c93b",
  //                 "tenantId": "userId-8bb21e74-1a48-40a1-9b63-035a92591333",
  //                 "landlordId": "userId-b047efec-2aa5-4286-8510-65402ac1f54e",
  //                 "unitStatus": "occupied",
  //                 "leaseStatus": "active",
  //                 "leaseType": "new",
  //                 "currency": "AED",
  //                 "shippingStatus": "",
  //                 "ejariProcessed": false,
  //                 "shippingProcessed": false,
  //                 "uaePassSignedTenant": false,
  //                 "uaePassSignedLandlord": false,
  //                 "ejari": {
  //                     "contractNumber": "123342789",
  //                     "tenantNumber": "34442878",
  //                     "registrationDate": null,
  //                     "startDate": "2022-12-25T00:00:00.000Z",
  //                     "endDate": "2024-01-24T00:00:00.000Z",
  //                     "version": null,
  //                     "createdAt": "2023-01-12T07:58:56.240Z",
  //                     "updatedAt": "2023-01-12T07:58:56.240Z"
  //                 },
  //                 "propertyDetails": {
  //                     "propertyName": "Manazil Al Barsha Second 08",
  //                     "unitCode": "197897-G-01",
  //                     "dewa": "621179392",
  //                     "usagesType": "Residential",
  //                     "unitType": "Townhouse",
  //                     "unitSubType": "1BR",
  //                     "unitNumber": "01",
  //                     "areaSqft": "1772",
  //                     "address": {
  //                         "addressLine1": "Manazil Al Barsha Second 08",
  //                         "addressLine2": "Al Barsha First, Bur Dubai,",
  //                         "community": "Al Baraha",
  //                         "emirates": "Dubai",
  //                         "country": "United Arab Emirates",
  //                         "longitude": "55.3309",
  //                         "createdAt": "2023-01-12T07:49:45.162Z",
  //                         "updatedAt": "2023-01-12T07:49:45.162Z"
  //                     },
  //                     "createdAt": "2023-01-12T07:58:56.240Z",
  //                     "updatedAt": "2023-01-12T07:58:56.240Z"
  //                 },
  //                 "tenantDetails": {
  //                     "tenantNameEN": "Prashant",
  //                     "tenantNameAR": "",
  //                     "email": "prashant@yopmail.com",
  //                     "mobileNumber": "00971501234555",
  //                     "tenantType": "Residential",
  //                     "nationality": "India",
  //                     "passportNumber": "12345",
  //                     "passportExpiry": "2022-12-25",
  //                     "emiratesID": "78419879254457",
  //                     "visaNumber": "5478455",
  //                     "visaExpiry": "2025-12-25",
  //                     "tradeLicense": "",
  //                     "tradeLicenseExpiry": "",
  //                     "trnNumber": "",
  //                     "trnExpiry": "",
  //                     "createdAt": "2023-01-12T07:58:56.240Z",
  //                     "updatedAt": "2023-01-12T07:58:56.240Z"
  //                 },
  //                 "leaseTerms": {
  //                     "leaseNature": "Family",
  //                     "leaseType": "New",
  //                     "movingDate": null,
  //                     "leaseStartDate": "2022-12-25T00:00:00.000Z",
  //                     "leaseEndDate": "2024-01-24T00:00:00.000Z",
  //                     "rentFreeStartDate": "2023-12-25T00:00:00.000Z",
  //                     "rentFreeEndDate": "2024-01-24T00:00:00.000Z",
  //                     "noOfCheques": 4,
  //                     "contractAmount": 60000,
  //                     "securityDeposit": 6000,
  //                     "createdAt": "2023-01-12T07:58:56.240Z",
  //                     "updatedAt": "2023-01-12T07:58:56.240Z"
  //                 },
  //                 "billingSchedule": [],
  //                 "paymentSchedule": [],
  //                 "requiredDocuments": [],
  //                 "documents": [],
  //                 "shippingDetails": [
  //                     {}
  //                 ],
  //                 "leaseStatusHistory": [
  //                     {
  //                         "status": "uploaded",
  //                         "updatedBy": "userId-b047efec-2aa5-4286-8510-65402ac1f54e",
  //                         "createdAt": "2023-01-12T07:58:56.240Z",
  //                         "updatedAt": "2023-01-12T07:58:56.240Z"
  //                     }
  //                 ],
  //                 "status": "enable",
  //                 "isDeleted": false,
  //                 "updatedBy": "userId-b047efec-2aa5-4286-8510-65402ac1f54e",
  //                 "createdBy": "userId-b047efec-2aa5-4286-8510-65402ac1f54e",
  //                 "_id": "63bfbdc0d39ca73533a2ebf9",
  //                 "__v": 0,
  //                 "createdAt": "2023-01-12T07:58:56.240Z",
  //                 "updatedAt": "2023-01-12T07:58:56.240Z"
  //             }
  //         ],
  //         "tenantResData": [
  //             {
  //                 "email": "prashant@yopmail.com",
  //                 "name": "Prashant",
  //                 "usertype": [
  //                     "tenant"
  //                 ],
  //                 "userId": "userId-8bb21e74-1a48-40a1-9b63-035a92591333"
  //             }
  //         ],
  //         "failedData": []
  //     },
  //     "error": ""
  // }

  const onFinish = async (values) => {

    const postJson = {
      file_asset: leaseFile[0],
      // orgId: "orgId-83a0fecf-a07a-46fc-98ec-dc49359abed1",
      // orgId:"orgId-83a0fecf-a07a-46fc-98ec-dc49359abed1"

    };

    resetModal();
    setLoading(true);



    if (leaseFile[0]) {
      const url = `${leaseService}/leasing/UploadTenantFile`;


      let fd = new FormData();
      fd.append('file_asset', leaseFile[0] as RcFile);
      // const config = { headers: fd.getHeaders ? fd.getHeaders() : { 'Content-Type': 'multipart/form-data' } };



      await axios.post(url, fd, { headers: { 'Accept': '*/*', 'Content-Type': 'multipart/form-data' } }).then((response) => {

        const { data } = response;



        if (data?.result?.failedData?.length > 0) {
          // console.log("sorry failed by: ", data?.result?.failedData?.length);
          const failedArry = _.map(data.result.failedData, (e) => {
            return {
              "tenantname": e.tenantname,
              "ejaricontractnumber": e.ejaricontractnumber,
              "email": e.email,
              "mobile": e.mobile,
              "reason": e.reason
            }
          });
          setFailed(failedArry);
        }
        if (data?.result?.leasingRes?.length > 0) {
          setSuccess(data.result.leasingRes.length);
          message.success(response.data.message);
          props.onSubmit();
        }
        setLoading(false);
        setLease([]);

      }).catch((error) => {

        setLoading(false);
        message.error(error.response.data.error)

      })

    } else {
      setLoading(false);
      message.error("Please select a file to upload");
    }

  };
  const headerKeys = Object.keys(Object.assign({}, ...array));

  const leaseFileProps = {
    className: "file-upload",
    name: "file",
    accept: 'csv',
    multiple: false,
    // showUploadList: false,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {

      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} Please upload only CSV file`);
      }
    },
    onDrop(e) {

      // setLease(e.dataTransfer.files);
    },
    onRemove: (file) => {
      setLease([]);
    },
    beforeUpload: (file) => {
      setLease([file]);
      return false;
    },
    leaseFile,
  };


  return (

    <Layout className="layout-space prop_popup">
      <Content className="main-content">

        <Row gutter={24}>

          <Col flex="1 1 500px">
            {/* File Uploader */}
            <div style={{ textAlign: "center" }}>
              {/* <h1 className="csvheading">Upload your data through CSV file</h1> */}

              <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
                <Row gutter={24}>


                  <Dragger {...leaseFileProps}
                    fileList={leaseFile}

                    className="drag_drop_csv">
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                      Download the sample template and fill the data as per the template, convert master file into CSV and upload it.
                      <br></br><br></br>
                      <span>Note: </span>Only CSV files are accepted.                    </p>
                  </Dragger>
                  <div className="csvmodel">


                    <a className="downloadcsv" href={`${serverUrl}/templates/residential/ResidentialActiveTenancyContractTemplate_v1.xlsx`} download>
                      {<BsDownload />} Download residential template file
                    </a>
                    <a className="downloadcsv" href={`${serverUrl}/templates/commercial/CommercialActiveTenancyContractTemplate_v1.xlsx`} download>
                      {<BsDownload />} Download commercial template file
                    </a>
                  </div>

                  <Col span={24} className="csv_error_wrap">
                    {loading ? (
                      <Spin />
                    ) :

                      (<>


                        {successData > 0 ?
                          (

                            <p className="success_csv">{<BsFillCheckCircleFill />}
                              <>Successfully Added {successData} Records</>
                            </p>

                          )
                          : ""}

                        {failedData?.length > 0 ? (
                          <div className="csv_error_log">
                            <span className="csv_error"> {<BsXCircleFill color='red' />} {failedData?.length} - Records Failed <CSVLink className="csv_log" {...csvReport}>{<BsDownload />} Download Failure Log</CSVLink></span><br />
                            {/* <span>{failedData?.length} Records Failed</span><br /> */}
                            {/* <span><CSVLink {...csvReport}>{<BsDownload/>} Download Failure Log</CSVLink></span> */}
                          </div>
                        ) : ""}
                        <Button htmlType="submit" disabled={loading} type="primary" size="large" className="csv_publish">
                          {<BsCloudUpload />}Publish
                        </Button>

                      </>)}
                  </Col>
                  {/* <div className="csvmodel">
                    <Button htmlType="submit" disabled={loading} type="primary" size="large" className="csv_publish">
                    {<BsCloudUpload/>}Publish
                    </Button>
                   
                    <a className="downloadcsv" href="../../Desktop/sample-test-csv.csv" download>
                    {<BsDownload/>} Download active tenancy template file
                    </a>
                  </div> */}

                </Row>
              </Form>

              <br />
            </div>
          </Col>

        </Row>
      </Content>
    </Layout>
  );
};
