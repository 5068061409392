import React, { useState, useEffect } from "react";

// others
import dayjs from "dayjs";
import Grid from "../../../atoms/Grid";
import { Row, Col, Table, DatePickerProps, Select } from "antd";
import { useDispatch } from "react-redux";
import { useForm, useWatch } from "react-hook-form";
import { Calendar } from "../../../../assets/svg";
import DashboardLayout from "../../../molecules/DashboardLayout";
import { NonShift, ShiftItem, CalendarFooter } from "./components";
import { ActionHeader, CommonCard } from "../../BookingForm/components";
import Search from "antd/es/input/Search";

import {
  format,
  weekOptions,
  COLUMN_WIDTH,
  apiDateFormat,
  getDatesInRange,
  dateRangeValues,
  filterDataSource,
  columns as COLUMNS,
  extendedDates,
} from "./components/utils";
import {
  InputField,
  InputCheckbox,
  RangePickerField,
  ReactSelectField,
  DateField,
} from "../../../atoms/FormElement";
import {
  getAllTechnicianRecords,
  getAllTechnicianShifts,
  rosterDateRange,
  rosterLoader,
  setRosterWeek,
} from "./components/ducks/actions";
import { useSelector } from "react-redux";
import { Loading } from "../../BookingForm/components/modalContent";
import { DatePicker, Form } from "antd";
import {
  datePlaceholderddmmyyFormat,
  disableMoveInDate,
} from "../../BookingForm/components/utils";
const { RangePicker } = DatePicker;
let initialState = {
  load: false,
  columns: COLUMNS,
};

function Roster() {
  // hooks initialization
  const dispatch: any = useDispatch();

  // states
  const [{ load, columns }, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [directCustomChangeFromCalendar, setDirectCustomChangeFromCalendar] =
    useState(false);
  const [searchValue, setSearchValue] = useState("");

  const updateState = (state: {}) =>
    setState((prevState: any) => ({ ...prevState, ...state }));

  const {
    trigger,
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dateRange = useWatch({ control, name: "dateRange" });
  const selectWeek = useWatch({ control, name: "selectWeek" });
  const dateRange1 = useWatch({ control, name: "dateRange[1]" });
  const search = useWatch({ control, name: "search" });
  const allTechnicianRoster =
    useSelector((state: any) => state?.technicians?.allTechnicianRoster) || [];
  const loaderRosterState = useSelector(
    (state: any) => state?.technicians?.roasterLoaderState
  );
  const [value, setValue1] = useState(null);
  const allTechnicianShifts = useSelector(
    (state: any) => state?.technicians?.technicianShifts
  );

  useEffect(() => {
    getAllTechnicianRec();
  }, []);

  useEffect(() => {
    if (selectWeek) {
      const range = dateRangeValues(selectWeek?.value);
      setValue("dateRange", range);
      if (selectWeek?.value === "current_week") {
        if (dateRange?.[0]) {
          setValue("dateRange[0]", dayjs().startOf("weeks"));
          setValue("dateRange[1]", dayjs().endOf("weeks"));
          updateState({ columns: [...COLUMNS] });
        } else {
          setValue("dateRange[0]", dayjs().startOf("weeks"));
          setValue("dateRange[1]", dayjs().endOf("weeks"));
        }
      } else if (selectWeek?.value === "next_week") {
        if (selectWeek?.value === "current_week") {
          if (dateRange?.[0]) {
            setValue("dateRange[0]", dayjs().startOf("weeks"));
            setValue("dateRange[1]", dayjs().endOf("weeks"));
          }
        } else if (selectWeek?.value === "next_week") {
          if (dateRange?.[0]) {
            setValue("dateRange[0]", dayjs().startOf("weeks").add(7, "days"));
            setValue("dateRange[1]", dayjs().endOf("weeks").add(7, "days"));
          }
        }
        updateState({ columns: COLUMNS });
      } else if (selectWeek?.value === "two_weeks") {
        if (dateRange?.[0]) {
          updateState({ columns: [...COLUMNS, ...extendedDates] });
          setValue("dateRange[0]", dayjs().startOf("weeks"));
          setValue("dateRange[1]", dayjs().endOf("weeks").add(7, "days"));
        }
      } else if (selectWeek?.value === "custom_weeks") {
        updateState({ columns: [...COLUMNS, ...extendedDates] });
        console.log(
          "directCustomChangeFromCalendar",
          directCustomChangeFromCalendar
        );

        if (directCustomChangeFromCalendar) {
          setValue("dateRange[0]", dayjs(dateRange[0]));
          setValue("dateRange[1]", dayjs(dateRange[0].add(13, "days")));
        } else {
          setValue("dateRange[0]", dayjs());
          setValue("dateRange[1]", dayjs().add(13, "days"));
        }
        setDirectCustomChangeFromCalendar(false);
      } else if (selectWeek?.value === undefined) {
        updateState({ columns: [...COLUMNS] });
      }
    }
    dispatch(setRosterWeek(selectWeek));
  }, [selectWeek]);

  useEffect(() => {
    if (dateRange?.[0] && dateRange?.[1]) {
      const returnCurrentDate = (startDate) => {
        if (dayjs(startDate).isBefore(dayjs())) {
          return dayjs().format("YYYY-MM-DD");
        } else return startDate.format("YYYY-MM-DD");
      };
      const apiPayload = {
        startDate: returnCurrentDate(dateRange?.[0]),
        endDate: dateRange?.[1]?.format("YYYY-MM-DD"),
        search: search || "",
      };
      dispatch(getAllTechnicianRecords(apiPayload));
      dispatch(rosterDateRange(apiPayload));
    }
  }, [dateRange?.[0]]);

  const getAllTechnicianRec = async () => {
    dispatch(getAllTechnicianShifts());
    setInitialValueForWeek();
  };

  const setInitialValueForWeek = () => {
    setValue("selectWeek", { label: "Current Week", value: "current_week" });
  };

  useEffect(() => {
    if (search && search.length > 3) {
      const returnCurrentDate = (startDate) => {
        if (dayjs(startDate).isBefore(dayjs())) {
          return dayjs().format("YYYY-MM-DD");
        } else return startDate.format("YYYY-MM-DD");
      };
      const apiPayload = {
        startDate: returnCurrentDate(dateRange?.[0]),
        endDate: dateRange?.[1]?.format("YYYY-MM-DD"),
        search: search,
      };
      dispatch(getAllTechnicianRecords(apiPayload));
    }
  }, [search]);

  let endDate: any = "";
  const dataSource = filterDataSource(
    allTechnicianShifts,
    allTechnicianRoster,
    dateRange,
    selectWeek
  );

  const placeholder = dayjs().format("MMM DD, YYYY");
  const otherPlaceholder = dayjs(placeholder)
    .add(1, "week")
    .format("MMM DD, YYYY");

  return (
    <DashboardLayout load={load} className="bf-main-content">
      <ActionHeader label="Roster" />
      <Loading visible={loaderRosterState} />
      <CommonCard>
        <>
          <Row gutter={24} className="bf-main">
            <Col span={4}>
              <Search
                placeholder="Search.."
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  const returnCurrentDate = (startDate) => {
                    if (dayjs(startDate).isBefore(dayjs())) {
                      return dayjs().format("YYYY-MM-DD");
                    } else return startDate.format("YYYY-MM-DD");
                  };
                  if (e.target.value === "") {
                    const apiPayload = {
                      startDate: returnCurrentDate(dateRange?.[0]),
                      endDate: dateRange?.[1]?.format("YYYY-MM-DD"),
                      search: "",
                    };
                    dispatch(getAllTechnicianRecords(apiPayload));
                  }
                }}
                onSearch={(e) => {
                  if (e && e.length > 3) {
                    const returnCurrentDate = (startDate) => {
                      if (dayjs(startDate).isBefore(dayjs())) {
                        return dayjs().format("YYYY-MM-DD");
                      } else return startDate.format("YYYY-MM-DD");
                    };
                    const apiPayload = {
                      startDate: returnCurrentDate(dateRange?.[0]),
                      endDate: dateRange?.[1]?.format("YYYY-MM-DD"),
                      search: e,
                    };
                    dispatch(getAllTechnicianRecords(apiPayload));
                  }
                }}
                enterButton
              />
            </Col>
            <Col
              span={3}
              style={{
                display: "flex",
                // alignContent: "flex-end",
                // justifyContent: "flex-end",
                alignItems: "flex-end",
                width: "100%",
              }}
            >
              <ReactSelectField
                classes={"w-100"}
                control={control}
                setValue={setValue}
                fieldname="selectWeek"
                selectOption={weekOptions}
                iProps={{ placeholder: "Select" }}
                validate={errors.selectWeek && "error"}
                validMessage={errors.selectWeek && errors.selectWeek.message}
              />
            </Col>
            <Col span={3} style={{ display: "flex" }}>
              <DateField
                classes={"w-100"}
                control={control}
                isRequired={true}
                // setValue={setValue1}
                onChange={(e) => {
                  setDirectCustomChangeFromCalendar(true);
                  setValue("selectWeek", {
                    label: "Custom Weeks",
                    value: "custom_weeks",
                  });
                }}
                label=""
                value={value}
                fieldname="dateRange[0]"
                rules={{ required: "Enter Date" }}
                validate={errors?.moveInDate && "error"}
                validMessage={errors?.moveInDate && errors?.moveInDate.message}
                iProps={{
                  format: datePlaceholderddmmyyFormat,
                  placeholder: datePlaceholderddmmyyFormat,
                  disabledDate: (date) => {
                    return date.valueOf() < dayjs().subtract(1, "day");
                  },
                }}
              />
            </Col>
            <Col span={3} style={{ display: "flex" }}>
              <DateField
                classes={"w-100"}
                disabled={true}
                initValue={dayjs()}
                control={control}
                isRequired={true}
                setValue={setValue1}
                label=""
                value={dateRange1}
                fieldname="dateRange[1]"
                rules={{ required: "Enter Date" }}
                validate={errors?.moveInDate && "error"}
                validMessage={errors?.moveInDate && errors?.moveInDate.message}
                iProps={{
                  format: datePlaceholderddmmyyFormat,
                  placeholder: datePlaceholderddmmyyFormat,
                  disabledDate: (date) => {},
                }}
              />
            </Col>
            <Col span={10} className="main-checkbox">
              <InputCheckbox
                label="Available"
                control={control}
                fieldname="available"
                iProps={{ disabled: true }}
                validate={errors.available && "error"}
                validMessage={errors.available && errors.available.message}
              />

              <InputCheckbox
                label="Dayoff"
                control={control}
                fieldname="dayoff"
                iProps={{ disabled: true }}
                validate={errors.dayoff && "error"}
                validMessage={errors.dayoff && errors.dayoff.message}
              />
            </Col>
          </Row>

          <div className="roster-divider" />

          <Grid
            fullPage={true}
            columns={columns}
            selectWeek={selectWeek}
            data={dataSource}
            dateRange={dateRange}
            className="bf-main roster-grid"
            gridClassName="no-header"
          />
        </>
      </CommonCard>
    </DashboardLayout>
  );
}

export default Roster;
