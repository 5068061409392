import React from "react";

const index = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.55599 6.91699H5.80599C4.79347 6.91699 3.97266 7.7378 3.97266 8.75033V17.0003C3.97266 18.0128 4.79347 18.8337 5.80599 18.8337H14.056C15.0685 18.8337 15.8893 18.0128 15.8893 17.0003V14.2503"
        stroke="#2F2F37"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.55469 14.2499H11.3047L19.0964 6.45822C19.8557 5.69882 19.8557 4.46761 19.0964 3.70822C18.337 2.94882 17.1057 2.94882 16.3464 3.70822L8.55469 11.4999V14.2499"
        stroke="#2F2F37"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9727 5.08301L17.7227 7.83301"
        stroke="#2F2F37"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default index;
