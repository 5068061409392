import React, { useEffect } from "react";
import { Menu, Space, Typography, Tabs, Card, Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../../utils/axiosInceptor";
import { leaseService } from "../../../configs/constants";
import {
  NOTIFICATION_TYPE,
  TYPE_NOTIFY,
  TYPE_CONTRACT,
} from "../Header/duck/constants";
import moment from "moment";
import { useDispatch } from "react-redux";
import { getNewNotifications, getNotifications } from "../../modules/Notification/ducks/action";

const { Text } = Typography;
const { TabPane } = Tabs;

const NotificationPanel = ({ notificationsData, newNotificationsData }) => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  const gotoLease = (item) => {
    navigate(`/lease/${item.identifier_value}`);
  };

  const gotoBooking = (item) => {
    const { assetId, leadId, partyId } = JSON.parse(item.identifier_value);
    navigate(`/leads/${leadId}/booking-form/${assetId}/${partyId}`);
  };

  const gotoOpportunity = (item) => {
    const { assetId, leadId, partyId } = JSON.parse(item.identifier_value);

    navigate(`/opportunity-detail/${leadId}/${assetId}/${partyId}`);
  };
  const gotoTenant = (item) => {
    const { assetId, leadId, partyId } = JSON.parse(item.identifier_value);

    navigate(`/detail-tenant/${leadId}/${partyId}`);
  };

  const gotoContract = (item) => {
    navigate(`/contract-detail/${item.identifier_value}`);
  };

  // const handleLinkClick = async (item) => {
  //   window.localStorage.setItem(
  //     "selectedOffer",
  //     JSON.stringify({
  //       identifier: item.identifier_value,
  //       id: item.id,
  //     })
  //   );
  //   const url = `${leaseService}/leads/notifications/update/status`;
  //   const postData = {
  //     notification_ids: [item.id],
  //   };
  //   await axios.post(url, postData);

  //   switch (item.type) {
  //     case NOTIFICATION_TYPE.CONTRACT_ID: {
  //       navigate("/contracts");
  //       break;
  //     }

  //     case NOTIFICATION_TYPE.ASSET_ID: {
  //       navigate(`/visit-scheduling/${item.identifier_value}`);
  //       break;
  //     }
  //     case NOTIFICATION_TYPE.VISIT_ID: {
  //       navigate(`/visit-scheduling/${item.identifier_value}`, {
  //         state: { code: item.identifier_value },
  //       });
  //       break;
  //     }
  //     case NOTIFICATION_TYPE.LEAD_ITEM_ID: {
  //       navigate(`/opportunity-detail/${item.identifier_value}`);
  //       break;
  //     }

  //     case NOTIFICATION_TYPE.LEASE_ID: {
  //       switch (item.notification_type) {
  //         case TYPE_CONTRACT.CONTRACT_SIGNED: {
  //           gotoContract(item);
  //           break;
  //         }
  //         case TYPE_CONTRACT.CONTRACT_RENEWED: {
  //           gotoContract(item);
  //           break;
  //         }
  //         case TYPE_CONTRACT.CONTRACT_RENEWED1: {
  //           gotoContract(item);
  //           break;
  //         }
  //         case TYPE_CONTRACT.RENEWAL_FEEDBACK: {
  //           navigate(`/contracts`);
  //           break;
  //         }
  //         default:
  //           navigate(`/ejari`);
  //           break;
  //       }
  //       break;
  //     }

  //     case NOTIFICATION_TYPE.MAINTENANCE_ITEM_ID: {
  //       navigate(`/maintenance/${item.identifier_value}`, {
  //         state: {
  //           from: "notification",
  //         },
  //       });
  //       break;
  //     }
  //     case NOTIFICATION_TYPE.LEAD_ID: {
  //       switch (item.notification_type) {
  //         case TYPE_NOTIFY.LEASE_FORM_SENT_FOR_APPROVAL: {
  //           gotoLease(item);
  //           break;
  //         }
  //         case TYPE_NOTIFY.LEASE_FORM_APPROVED: {
  //           gotoLease(item);
  //           break;
  //         }
  //         case TYPE_NOTIFY.LEASE_FORM_REJECTED: {
  //           gotoLease(item);
  //           break;
  //         }
  //         case TYPE_NOTIFY.SENT_TO_TENANT_FOR_CHEQUES: {
  //           gotoLease(item);
  //           break;
  //         }
  //         case TYPE_NOTIFY.TENANT_CHEQUES_SCHEDULED_DROPOFF: {
  //           gotoLease(item);
  //           break;
  //         }
  //         case TYPE_NOTIFY.TENANT_CHEQUES_SCHEDULED_PICKUP: {
  //           gotoLease(item);
  //           break;
  //         }
  //         case TYPE_NOTIFY.BOOKING_FORM_SIGNED: {
  //           gotoLease(item);
  //           break;
  //         }
  //         case TYPE_NOTIFY.BOOKING_OPPORTUNITY: {
  //           gotoBooking(item);
  //           break;
  //         }
  //         case TYPE_NOTIFY.BOOKING_APPROVED: {
  //           gotoBooking(item);
  //           break;
  //         }
  //         case TYPE_NOTIFY.BOOKING_REJECTED: {
  //           gotoBooking(item);
  //           break;
  //         }
  //         case TYPE_NOTIFY.BOOKING_RECEIVED: {
  //           gotoOpportunity(item);
  //           break;
  //         }
  //         case TYPE_NOTIFY.DOCUMENT_SUBMITED: {
  //           gotoTenant(item);
  //           break;
  //         }
  //       }
  //       break;
  //     }
  //     default:
  //       navigate(`/dashboard`);
  //   }
  // };

  // const handleLinkClick = async (item) => {

  //   if (item?.identifier)
  //     navigate(item?.identifier);

  //     console.log("item?.identifier",item?.identifier)
  // }
  const handleClickSeen = async (data) => {
    if (data?.notificationId) {
      let patchData = {
        isRead: true,
      };
      const url = `${leaseService}/notification/${data?.notificationId}`;
      await axios.patch(url, patchData);
      setTimeout(() => {
        dispatch(getNewNotifications(1));
        dispatch(getNotifications(1));
      }, 800);
    }
  }

  return (
    <Card bordered={false} className="notification-panel"> 
    {/* className="ag-nospace-body"// */}
      <Tabs defaultActiveKey="1" tabBarGutter={24}>
        <TabPane tab={`New (${newNotificationsData?.totalCount || 0})`} key="1">
          <Menu className="notif-panel" selectable={false}>
            {newNotificationsData?.data?.map((item, index) => {
              return (
                <a
                  href={
                    item?.identifier
                      ? item.title === "Credit card Request"
                        ? `/payments?pdcId=${item?.identifier.split("|")[2]}`
                        : item.title ===
                          "your maintenance request notification for landlord"
                          ? `/tickets`
                          : item.title === "Comment on ticket"
                            ? `/tickets?ticketId=${item?.identifier}` :
                            item?.identifier
                      : "#"
                  }
                  onClick={() => handleClickSeen(item)}
                  className="ag-secondary breakText"
                >
                  <Menu.Item
                    key={index}
                    className={`notif-item ${item.isRead ? "notify-read" : ""}`}
                  >
                    <Space direction="vertical" size={4}>
                      <Text className="ag-primary breakText">
                        <strong>
                          {item?.title[0]?.toUpperCase() +
                            item?.title?.slice(1) || ""}
                        </strong>
                      </Text>
                      <Text className="ag-primary breakText">{item?.body}</Text>
                      {item?.identifier && (
                        <Text className="smallFont12">{"Click here"}</Text>
                      )}
                      <Text className="smallFont12">
                        {item.createdAt
                          ? moment(item.createdAt).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )
                          : ""}
                      </Text>
                    </Space>
                  </Menu.Item>
                </a>
              );
            })}
            {notificationsData?.totalCount > 5 && (
              <Menu.Item className="notif-item text-right">
                <Button
                  type="primary"
                  onClick={() =>
                    navigate(`/notifications`, {
                      state: {
                        selected: "new",
                      },
                    })
                  }
                >
                  View All
                </Button>
              </Menu.Item>
            )}
          </Menu>
        </TabPane>
        <TabPane tab={`All (${notificationsData?.totalCount || 0})`} key="2">
          <Menu className="notif-panel" selectable={false}>
            {notificationsData?.data?.map((item, index) => (
              <a
                href={item?.identifier ? item?.identifier : "#"}
                className="ag-secondary breakText"
              >
                <Menu.Item
                  key={index}
                  className={`notif-item ${item.isRead ? "notify-read" : ""}`}
                >
                  <Space direction="vertical" size={4}>
                    <Text className="ag-primary breakText">
                      <strong>{item?.title || ""}</strong>
                    </Text>
                    <Text className="ag-primary breakText">{item?.body}</Text>
                    {item?.identifier && (
                      <Text className="smallFont12">{"Click here"}</Text>
                    )}
                    <Text className="smallFont12">
                      {item.createdAt
                        ? moment(item.createdAt).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )
                        : ""}
                    </Text>
                  </Space>
                </Menu.Item>
              </a>
            ))}
            {notificationsData?.totalCount > 5 && (
              <Menu.Item className="notif-item text-right">
                <Button
                  type="primary"
                  onClick={() =>
                    navigate(`/notifications`, {
                      state: {
                        selected: "all",
                      },
                    })
                  }
                >
                  View All
                </Button>
              </Menu.Item>
            )}
          </Menu>
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default NotificationPanel;
