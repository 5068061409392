import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Upload,
  Space,
  Typography,
  message,
  Spin,
  Radio,
} from "antd";
import Title from "antd/es/typography/Title";
import { useForm, useWatch } from "react-hook-form";
import { RcFile } from "antd/lib/upload/interface";
import {
  InputField,
  TimeField,
  DateField,
  ReactSelectField,
} from "../../../../atoms/FormElement";
import { getPresignedImage, uploadDocumentMedia } from "../../../../../utils/media";
import { useDispatch, useSelector } from "react-redux";
import {
  // getTicketType,
  // getTicketSubType,
  // getPropertyName,
  // getTechnicians,
  // resetTechnician,
  // getPropertyUnits,
  // getAvailablity,
} from "../../ducks/actions";
import { maintenanceService } from "../../../../../configs/constants";
import axios from "../../../../../utils/axiosInceptor";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { dateByformat } from "../../../../../utils/dateHelper";
import { getMaintenanceTypes } from "../../../Configurations/ducks/actions";
import { getServiceProviders } from "../../../ServiceProvider/ducks/actions";
import { getPropertiesDrop } from "../../../Properties/ducks/actions";
import dayjs from "dayjs";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Popup } from "../../../../atoms/Popup";
import ScheduleNotice from "../ScheduleNotice"
import TestNotice from "../TestNotice"

var _ = require("lodash");
const { Text } = Typography;

const defaultVal = {
  title: "",
  subject: "",
  description: "",
  property: "",
  deliveryDate: "",
  deliveryTime: "",
  unitCode: "",
  audienceType: 'all',
  appAknowledgement: "yes"
};

const { Paragraph } = Typography;

const modifyNotice = (props) => {
  const dispatch: any = useDispatch();

  const { notice, action } = props

  const isDisable = (action === 'view' || notice?.status === 'sent')
  const isNoticeEmpty = Object.keys(notice || {}).length === 0 && (notice || {}).constructor === Object

  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [deliveryMethod, setDeliveryMethod] = useState("all");
  const [appAknowledgement, setAppAknowledgement] = useState("yes");
  const [audinece, setAudinece] = useState("all");
  const [loader, setLoader] = useState(false);
  const [deliveryTime, setDeliveryTime] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [submitType, setSubmitType] = useState(null);
  const [formValues, setFormValues] = useState(null)
  const [property, setProperty] = useState(null)
  const [testParams, setTestParams] = useState(null)
  const [noticeDescription, setDescription] = useState(null)
  const [isCurrentDayHour, setIsCurrentDayHour] = useState(null)
  
  const CurrentTime = moment();

  const { control, handleSubmit, formState: { errors }, reset, setValue, setError } = useForm();

  const properties = useSelector((state: any) => state.dashboard.propertiesDrop);

  const setApiValuesToForm = (key, value) => {
    switch (key) {
      case 'deliveryDate':
        {
          if(action !== 'copy') {
            setDeliveryDate(value)
          }
        }
        break;
      case 'deliveryTime':
        {
          if(action !== 'copy') {
            setDeliveryTime(value)
            setValue('deliveryShiftTime', moment(value).format("HH:mm"), { shouldValidate: true, })
          }
        }
        break;

      case 'deliveryMethod':
        {
          const dm = value[0]['app'] && value[0]['email'] ? 'all' : value[0]['app'] ? 'app' : 'email'
          setDeliveryMethod(dm)
          setValue('deliveryMethod', dm , { shouldValidate: true, })
        }
        break;
      case 'appAknowledgement':
        {
          setAppAknowledgement(value)
          setValue('appAknowledgement', value , { shouldValidate: true, })
        }
        break;
        
      case 'audience':
        {
          const type = value[0]['type']
          const audineceType = value[0]['data']

          setAudinece(type)
          setValue('audienceType', type , { shouldValidate: true, })
          if(type === 'propertyResidence') {

            console.log('audineceType', audineceType);
            console.log('properties', properties);
            
            const propertiesOptions = _.map(audineceType, (item) => { return { label: properties.find(prop => prop.value === audineceType[0])?.label, value: audineceType[0] } })
            console.log('propertiesOptions', propertiesOptions);
            setValue('property', propertiesOptions , { shouldValidate: true, })
          }
          if(type === 'tenants') setValue('unitCode', audineceType[0] , { shouldValidate: true, })
        }
        break;

      case 'description':
        {
          setDescription(value)
          setValue('description', value , { shouldValidate: true, })
        }
        break;
        
      default:
        setValue(key, value);
        break;
    }
  }

  useEffect(() => {
    if(notice) {
      Object.entries(notice).forEach(([key, value]) => {
        setApiValuesToForm(key, value);
      })
    }
  }, [notice])

  useEffect(() => {
    if (properties.length === 0) {
      dispatch(getPropertiesDrop());
    }
  }, [properties]);


  const audienceType: any = useWatch({ control, name: "audienceType" });
  const description: any = useWatch({ control, name: "description" });

  const onFinish = async (values) => {

    if(submitType === 'cancelScheduled') {
      cancelNoticeApi()
      return
    }

    if(values.audienceType === "propertyResidence" && values?.property?.length === 0) {
      setError('property', {type: 'manual', message: 'Please select the property name' })
      return
    }

    if(values.audienceType === "tenants" && !values.unitCode) {
      setError('unitCode', {type: 'manual', message: 'Please select the unit code' })
      return
    }

    if(!deliveryTime) {
      setError('deliveryShiftTime', {type: 'manual', message: 'Please select the shift time' })
      return
    }

    if(!deliveryDate) {
      setError('deliveryDate', {type: 'manual', message: 'Please select the shift date' })
      return
    }

    if(!values.description) {
      message.error("please enter the description!")
      return
    }
    
    const property = values?.property?.length > 0 ? values.property.map(prop => prop.value) : []
    
    setFormValues(values)
    setProperty(property)

    if(submitType === 'test') {
      setTrigger(0)
      setVisible(true)
      return
    }

    if(submitType === 'schedule') {
      setTrigger(1)
      setVisible(true)
      return
    }

    if(submitType === 'draft') {
      callNoticeApi(values, null)
      return
    }

    if(action === 'copy') {
      setFormValues(values)
      setProperty(property)
      callNoticeApi(values, null)
    }
  };

  function disabledDate(current) {
    let today = dayjs().format("YYYY-MM-DD");
    return (
      (current && dayjs(current).format("YYYY-MM-DD") < today));
  }

  const cancelNoticeApi = async  () => {

    setLoader(true);
    setSubmited(true);

    let url, method, payload;

    url = `${maintenanceService}/user/notices/${notice?.noticeId}`; 
    method = 'PATCH';
    payload= {
      cancelled : 'yes'
    }

    try {
      const res = await axios({
        method: method,
        url: url,
        data: payload
      })

      reset();
      setLoader(false);
      message.success("Success");
      setSubmited(false);
      props.onSubmit();

    } catch (error) {
      setLoader(false);
      setisError(true);
      const { response } = error;
      setErrorMessage(response?.data?.error);
    }
  }

  const callNoticeApi = async (values, linkValues) => {

    let noticeValues = formValues || values

    setLoader(true);
    setSubmited(true);
    
    const postJson = {
      "title": noticeValues.title,
      "subject": noticeValues.subject,
      "description": noticeValues.description || noticeDescription,
      "deliveryMethod": {
          "app": noticeValues.deliveryMethod ? ((noticeValues.deliveryMethod === 'app' || noticeValues.deliveryMethod === 'all') ? true : false) : true,
          "email": noticeValues.deliveryMethod ? ((noticeValues.deliveryMethod === 'email' || noticeValues.deliveryMethod === 'all') ? true : false) : true
      },
      "deliveryDate": deliveryDate ?? "",
      "deliveryTime": deliveryTime,
      "appAknowledgement": noticeValues.appAknowledgement ? noticeValues.appAknowledgement : "yes",
      "audience": {
          "type": noticeValues.audienceType ? noticeValues.audienceType : "all",
          "data": noticeValues.audienceType === "all" ? [] : noticeValues.audienceType === "tenants" ? [noticeValues.unitCode] : property
      },
      "isDraft": submitType === 'draft',
      "isTest": submitType === 'test',
      "isSchedule": submitType === 'schedule',
      "testType": linkValues?.deliveryMedium === 'email' ? 'email' : 'link',
      "testEmailPayload": {
          "email": linkValues?.email
      },
      "testLinkPayload": {
          "link": linkValues?.link
      },
    }

    let url = `${maintenanceService}/user/notices`, method = 'POST';

    if(!isNoticeEmpty && action !== 'copy') {
      url = `${maintenanceService}/user/notices/${notice?.noticeId}`
      method = 'PATCH'
    }

    try {
      const res = await axios({
        method: method,
        url: url,
        data: postJson
      })
      
      setTimeout(() => {
        reset();
        setLoader(false);
        message.success("Success");
        setSubmited(false);
        props.onSubmit();
      }, 3000);
    } catch (e) {
      setLoader(false);
      setisError(true);
      const { response } = e;
      setErrorMessage(response?.data?.error);
    }
  }

  const onSchedule = (linkValues) => {
    setTestParams(linkValues)
    callNoticeApi(null, linkValues)
    setVisible(false)
  }

  const popup = [
    {
      title: (
        <Title level={3} className="m-0">
          Test Notice
        </Title>
      ),
      content: (
        <ScheduleNotice
          onSubmit={onSchedule}
          onCancel={() => setVisible(false)}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="m-0">
          Schedule Notice
        </Title>
      ),
      content: (
        <TestNotice
          onSubmit={onSchedule}
          onCancel={() => setVisible(false)}
        />
      ),
      width: 650,
    },
  ];

  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => setVisible(false),
  };

  const [disabledHours, setDisabledHours] = useState(() => {
    const currentHour = CurrentTime.hours();
    const disabledHoursArray = [];

    for (let i = 0; i < currentHour; i++) {
      disabledHoursArray.push(i);
    }

    return disabledHoursArray;
  });

  const [disabledMinutes, setDisabledMinutes] = useState(() => {
    const currentMinutes = CurrentTime.minutes();
    const disabledMinutesArray = [];

    for (let i = 0; i < currentMinutes; i++) {
      disabledMinutesArray.push(i);
    }

    return disabledMinutesArray;
  });
  
  const isCurrentDate = () => {
    const currentDate = moment().startOf('day'); // Get the current date at the start of the day
    const providedDate = moment(deliveryDate).startOf('day'); // Get the provided date at the start of the day
  
    return currentDate.isSame(providedDate, 'day'); // Check if both dates are the same day
  };

  const getUrl = async (data) => {
    return await getPresignedImage([data]);
  };

  function uploadAdapter(loader){
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then( async (file) => {
            try {
              const { data } = await uploadDocumentMedia({ file_asset: file as RcFile });
              const documentId = data?.result?.documentId;
              const finalImg = await getUrl(documentId);
              
              resolve({default: finalImg[0]})
            } catch (error) {
              reject(error)
            }
          })
        })
      }
    }
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      console.log(uploadAdapter(loader));
      
      return uploadAdapter(loader);
    }
  }

  return (
    <>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        {/* <Button
          className="p-0 h-auto w-auto ag-default mb-1 modal_button"
          type="link"
          size="large"
          onClick={onBack}
          icon={<CloseOutlined />}
        /> */}
        {loader && !isError && (
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Space size={24} className="w-100 text-center" direction="vertical">
                <Spin size="large" className="ag-fontSize32" />
                {/* <Paragraph className="ag-default fontWeight600 mb-0">Loading</Paragraph> */}
              </Space>
            </Col>
          </Row>
        )}
        {!submited ? (
          <>
            <Row gutter={24}>
              <Col span={24}>
                <InputField
                  isRequired={true}
                  fieldname="title"
                  label="Title"
                  disabled={isDisable}
                  control={control}
                  iProps={{ placeholder: "Enter Title" }}
                  initValue=""
                  validate={errors.title && "error"}
                  validMessage={errors.title && errors.title.message}
                  rules={{
                    required: "Please enter title",
                  }}
                />
              </Col>
              <Col span={24}>
                <InputField
                  isRequired={true}
                  fieldname="subject"
                  label="Subject"
                  disabled={isDisable}
                  control={control}
                  iProps={{ placeholder: "Enter Subject" }}
                  initValue=""
                  validate={errors.subject && "error"}
                  validMessage={errors.subject && errors.subject.message}
                  rules={{
                    required: "Please enter subject",
                  }}
                />
                
                <CKEditor
                  editor={ ClassicEditor }
                  disabled={isDisable}
                  data={description || noticeDescription || "<p>Please add the description</p>"}
                  config={{
                    extraPlugins: [uploadPlugin]
                  }}
                  onReady={ editor => {
                      // You can store the "editor" and use when it is needed.
                      // console.log( 'Editor is ready to use!', editor );
                  } }
                  onChange={ ( event, editor ) => {
                      const data = editor.getData();
                      setValue('description', data)
                  } }
                  onBlur={ ( event, editor ) => {
                      // console.log( 'Blur.', editor );
                  } }
                  onFocus={ ( event, editor ) => {
                      // console.log( 'Focus.', editor );
                  } }
                />
              </Col>

              <Col span={12} style={{marginTop: 25}}>
                <Text>Delivery medium</Text>
                <Radio.Group
                  defaultValue={"all"}
                  value={deliveryMethod}
                  disabled={isDisable}
                  // buttonStyle="solid"
                  onChange={(e) => {
                    setDeliveryMethod(e.target.value)
                    setValue("deliveryMethod", e.target.value)
                    }
                  }
                >
                  <Space size={6}>
                    <Radio value="all">App & Email</Radio>
                    <Radio value="email">Email</Radio>
                    <Radio value="app">App</Radio>
                  </Space>
                </Radio.Group>
              </Col>

              <Col span={24} style={{marginTop: 25}}>
                <Text>Request Resident acknowledgement on app?</Text>
                <Radio.Group
                  defaultValue="yes"
                  disabled={isDisable}
                  value={appAknowledgement}
                  onChange={(e) => {
                    setAppAknowledgement(e.target.value)
                    setValue("appAknowledgement", e.target.value)
                    }
                  }
                >
                  <Space size={2}>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Space>
                </Radio.Group>
              </Col>

              <Col span={24} style={{marginTop: 15}}>
                <Text>Select audience</Text>
                <Radio.Group
                  defaultValue="all"
                  disabled={isDisable}
                  value={audinece}
                  onChange={(e) => {
                    setAudinece(e.target.value)
                    setValue("audienceType", e.target.value)
                    } 
                  }
                >
                  <Space size={2}>
                    <Radio value="all">Entire Portfolio</Radio>
                    <Radio value="propertyResidence">Residents of a property</Radio>
                    <Radio value="tenants">Specific tenant</Radio>
                  </Space>
                </Radio.Group>
              </Col>
              {
                (audienceType === 'propertyResidence' || audinece === 'propertyResidence') &&
                <Col span={24}>
                  <ReactSelectField
                    fieldname="property"
                    label="Property"
                    control={control}
                    disabled={isDisable}
                    iProps={{ placeholder: "Select property", isDisabled: !properties?.length, isMulti: true}}
                    selectOption={properties}
                    validate={errors.property && "error"}
                    validMessage={errors.property && errors.property.message}
                  />
                </Col>
              }
              {
                (audienceType === 'tenants' || audinece === 'tenants') &&
                <Col span={24}>
                  <InputField
                    fieldname="unitCode"
                    label="Unit Code"
                    control={control}
                    disabled={isDisable}
                    iProps={{ placeholder: "Unit Code" }}
                    initValue=""
                    validate={errors.unitCode && "error"}
                    validMessage={errors.unitCode && errors.unitCode.message}
                  />
                </Col>
              }
            </Row> 
            <Row gutter={24} style={{marginTop: 15}}>
              <Col span={12}>
                <DateField
                  fieldname="deliveryDate"
                  label="Shift Delivery Date"
                  control={control}
                  iProps={{
                    placeholder: "YYYY-MM-DD",
                    format: "YYYY-MM-DD",
                    disabled: isDisable,
                    disabledDate: disabledDate,
                    value: deliveryDate ? dayjs(deliveryDate) : "", 
                    onChange: (timeChange, dateString) => {
                      const abc = dayjs(dateString).format("YYYY-MM-DD")
                      setDeliveryDate(abc)
                      setValue("deliveryDate", abc)
                    },
                  }}
                  rules={{
                    setValueAs: (value) => moment(value, "YYYY-MM-DD"),
                  }}
                  validate={errors.deliveryDate && "error"}
                  validMessage={errors.deliveryDate && errors.deliveryDate.message}
                />
              </Col>
              <Col span={12}>
                <TimeField
                  fieldname="deliveryShiftTime"
                  label="Shift starting Time"
                  control={control}
                  disabled={isDisable}
                  rules={{
                    setValueAs: (value) => { moment(value).format("HH:mm") },
                  }}
                  iProps={{ 
                    format: "HH:mm", 
                    value: deliveryTime && dayjs(deliveryTime, "HH:mm") || "", 
                    onChange: (timeChange, timeString) => {
                      setDeliveryTime(timeString)
                      setValue("deliveryShiftTime", timeString)
                    },
                    onSelect: (abc) => { 
                      const isDisable = new Date(abc).getHours() === new Date().getHours()
                      setIsCurrentDayHour(isDisable)
                    },
                    disabled: isDisable,
                    disabledHours: () => isCurrentDate() ? disabledHours : null,
                    disabledMinutes: () => isCurrentDate() && isCurrentDayHour ? disabledMinutes : null, 
                  }}
                  validate={errors.deliveryShiftTime && "error"}
                  validMessage={
                    errors.deliveryShiftTime && errors.deliveryShiftTime.message
                  }
                />
              </Col>
            </Row>

            {
              !isDisable && action !== 'copy' && notice?.status !== 'scheduled' && 
              <Row gutter={24}>
                <Col>
                  <Button className="ag-gray-button-outline" onClick={() => setSubmitType('schedule')} htmlType="submit">
                    Schedule
                  </Button>
                </Col>
                <Col>
                  <Button className="ag-gray-button-outline" onClick={() => setSubmitType('test')} htmlType="submit">
                    Sent a test
                  </Button>
                </Col>
                <Col>
                  <Button className="ag-green-button" onClick={() => setSubmitType('draft')} htmlType="submit">
                    Save as draft
                  </Button>
                </Col>
              </Row>
            }

            {
              notice?.status === 'scheduled' && !moment(`${notice.deliveryDate} ${notice.deliveryTime}`, 'YYYY-MM-DD HH:mm').isBefore(moment())  && 
              <Row gutter={24}>
                <Col>
                  <Button className="ag-gray-button-outline" onClick={() => setSubmitType('cancelScheduled')} htmlType="submit">
                    Cancel send
                  </Button>
                </Col>
              </Row>
            }

            {
              action === 'copy' && <Col>
                <Button className="ag-gray-button-outline" onClick={() => setSubmitType('copy')} htmlType="submit">
                  Create duplicate
                </Button>
              </Col>
            }

          </>
        ) : (
          isError && (
            <>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Space
                    size={24}
                    className="w-100 text-center"
                    direction="vertical"
                  >
                    <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                    <Paragraph className="ag-error fontWeight600 mb-0">
                      {errorMessage}
                    </Paragraph>
                  </Space>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Button
                    className="ag-gray-button-outline"
                    htmlType="button"
                    onClick={() => {
                      setSubmited(false);
                      setisError(false);
                    }}
                  >
                    Back
                  </Button>
                </Col>
              </Row>
            </>
          )
        )}
      </Form>
      <Popup destroyOnClose={true} {...popupProps} />
    </>
  );
};

export default modifyNotice;