import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { InputField } from "../../../atoms/FormElement";
import { useFieldArray, useWatch } from "react-hook-form";
import moment from "moment";

export default (props) => {
  const { control, setValue, getValues, nestIndex, data, setBillingScheduleTotal } = props;

  /**
   * usage type 2 = Residential
   * usage type 1 = Commercial
   */
  const usageType = getValues("usagesType")?.value;

  const [chargeArrayData, setChargeArrayData] = useState(null);
  const [chargeArrayFormData, setChargeArrayFormData] = useState(null);

  const checkbooking: any = useWatch({
    control,
    name: "othercharges",
  });
  useEffect(() => {
    setChargeArrayData(data);
  }, [checkbooking]);

  // console.log("cehcking_data",data);

  // useEffect(() => {
  //   if (chargeArrayData) {
  //     let mrate = parseInt(chargeArrayData?.amount);
  //     generateInstallment(chargeArrayData?.intallments?.value, mrate);
  //   }
  // }, [chargeArrayData]);

  return (
    <>
      {data?.map((item, index) => (
        // <Row gutter={24} key={index}>
        <React.Fragment key={index}>
          {/* <Col span={4}> */}
          < InputField
            label=""
            fieldname={`billings[${nestIndex}].instaArray[${index}].installmentno`}
            control={control}
            initValue={item.installmentno}
            iProps={{ readOnly: true, type: "hidden" }}
          />
          {/* </Col> */}
          {/* <Col span={4}> */}
          <InputField
            label=""
            fieldname={`billings[${nestIndex}].instaArray[${index}].start_date`}
            control={control}
            initValue={item.start_date}
            iProps={{ readOnly: true, type: "hidden" }}
            valueGot={item.start_date}
            setValue={setValue}

          />
          {/* </Col> */}
          {/* <Col span={4}> */}
          <InputField
            label=""
            fieldname={`billings[${nestIndex}].instaArray[${index}].end_date`}
            control={control}
            initValue={item.end_date}
            iProps={{ readOnly: true, type: "hidden" }}
            valueGot={item.end_date}
            setValue={setValue}
          />
          {/* </Col> */}
          {/* <Col span={4}> */}
          <InputField
            label=""
            fieldname={`billings[${nestIndex}].instaArray[${index}].amount`}
            control={control}
            initValue={item.amount}
            iProps={{ readOnly: true, type: "hidden" }}
            valueGot={Number(item.amount).toFixed(2)}
            setValue={setValue}
          />
          {/* </Col> */}
          {/* <Col span={4}> */}
          <InputField
            label=""
            fieldname={`billings[${nestIndex}].instaArray[${index}].vat`}
            control={control}
            initValue={item.vat}
            iProps={{ readOnly: true, type: "hidden" }}
            valueGot={Number(item?.vat).toFixed(2) ?? ""}
            setValue={setValue}
          />
          {/* </Col> */}
          {/* <Col span={4}> */}
          <InputField
            label=""
            fieldname={`billings[${nestIndex}].instaArray[${index}].total`}
            control={control}
            initValue={(Number(item.vat) + Number(item.amount)).toFixed(2)}
            iProps={{ readOnly: true, type: "hidden" }}
            valueGot={(Number(item.vat) + Number(item.amount)).toFixed(2)}
            setValue={setValue}
          />
          {/* </Col> */}
        </React.Fragment>
        // </Row>
      ))
      }
    </>
  );
};
