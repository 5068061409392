import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import axios from "../../../../../utils/axiosInceptor";
import { InputField, ReactSelectField, SelectField } from "../../../../atoms/FormElement";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { maintenanceService } from "../../../../../configs/constants";
import { BsPlusCircle, BsArrowCounterclockwise } from "react-icons/bs";
import { Row, Col, Button, Form, Typography, message, Space, Spin } from "antd";
// import { configTimeSlot } from "../../../../../utils/helper";
import {
  getMaintenanceTypes,
  getMaintenanceSubTypes,
} from "../../ducks/actions";

var _ = require("lodash");

const defaultValues: any = {
  name: "",
};

const configTimeSlot = [
  { value: 15, label: "15" },
  { value: 30, label: "30" },
  { value: 45, label: "45" },
  { value: 60, label: "60" },
  { value: 90, label: "90" },
];

const { Paragraph } = Typography;

const orgId = JSON.parse(window.localStorage.getItem("login-response"))?.orgId; 
                                                                                // parentID, childData,
const UpdateServices = ({ categoryRoot,onUpdate, parentKey, childKey, childDetail, setVisible, type, categoryType }) => {

  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues,
  });
  const condition = type === "category";
  const dispatch: any = useDispatch();

  // console.log("PROPS",props);
  // console.log("childKey", childKey)
  // console.log("parentKey", parentKey)
  // console.log("categoryRoot", categoryRoot)
  // console.log("childDetail", childDetail)
  // console.log("childDetail",childDetail)



  // if(parentID && categoryRoot.length>0){
  //  let category = categoryRoot.find((x) => x.categoryId == parentID);

  //  if(category.subCategories?.length>0 && childDetail?.subCategoryId){
  //   let subCategory = category.subCategories.find((x) => x.subCategoryId == childDetail?.subCategoryId);

  //   console.log("subCategory",subCategory);
  //  }

  //   // _.map(categoryRoot, (item) => { return { "categoryId": item.value } })
  // }

  const updateTimeSlot = (newTimeSlot) => {

    if (parentKey != null && categoryRoot?.length > 0) {
      // console.log("updatedL", categoryRoot);
      if (categoryRoot[parentKey]?.subCategories?.length > 0 && childKey != null) {
        categoryRoot[parentKey].subCategories[childKey].timeInMin = newTimeSlot;
        // console.log("xc", xc);
        return categoryRoot;
      }
    }
    return false;
  };




  useEffect(() => {
    if (childDetail) {
      setValue(
        "timeInMin",
        configTimeSlot.filter(
          (item) => item.value === childDetail.timeInMin
        )
      );
    }
  }, [childDetail]);

  const onFinish = async (values) => {
    setLoader(true);
    setSubmited(true);
    // console.log("VALUS", values)
    const finalData = await updateTimeSlot(values?.timeInMin?.value);
    if (finalData && orgId) {
      try {
        let url = `${maintenanceService}/serviceprovider/category/${orgId}`;

        let postData = {
          [categoryType === 'RES' ? 'residentialCategories' : categoryType === 'COM' ? 'commercialCategories' : 'internalCategories']: finalData
        };
        // console.log("postData", postData)
        await axios.patch(url, postData);
        setTimeout(() => {
          setLoader(false);
          setSubmited(false);
          message.success("Updated successfully");
          // condition
           onUpdate();
          //   : dispatch(getMaintenanceSubTypes(parentID));
          setVisible(false);
        }, 800);
      } catch (e) {
        setLoader(false);
        setisError(true);
        const { response } = e;
        setErrorMessage(response?.data?.message);
      }

    } else {
      message.success("Update Failed. Organization-Id not present");
    }

  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
          <Row gutter={24}>
            <Col span={12}>
              <InputField
                isRequired={true}
                fieldname="name"
                label={
                  condition
                    ? "Category"
                    : "Sub-Category"
                }
                control={control}
                rules={{
                  required: condition
                    ? "Please enter category title"
                    : "Please enter Sub-Category title",
                }}
                iProps={{
                  placeholder: condition ? "Plumbing" : "Water Leakage",
                }}
                disabled={true}
                setValue={setValue}
                valueGot={childDetail.title}
                initValue=""
                validate={errors.name && "error"}
                validMessage={errors.name && errors.name.message}
              />
            </Col>

            {!condition && (
              <Col span={12}>
                <ReactSelectField
                  control={control}
                  isRequired={true}
                  fieldname="timeInMin"
                  selectOption={configTimeSlot}
                  label="Time (minutes)"
                  validate={errors.timeInMin && "error"}
                  iProps={{ placeholder: "Choose time slot" }}
                  rules={{ required: "Please select the time slot" }}
                  validMessage={errors.timeInMin && errors.timeInMin.message}
                />
              </Col>
            )}
          </Row>
          <Row gutter={24} justify="space-between">
            <Col>
              <Button
                className="ag-error theme_grey_btn"
                htmlType="button"
                onClick={() => setVisible(false)}
              >
                {<BsArrowCounterclockwise />} Back
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button theme_btn" htmlType="submit">
                {<BsPlusCircle />} Update
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0 sentanceCase">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    setSubmited(false);
                    setisError(false);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
    </Form>
  );
};

export default UpdateServices;
