
import React, { useState, useEffect, useRef } from "react";
import {
  Layout,
  Row,
  Col,
  Breadcrumb,
  Steps,
  Button,
  message,
  Spin,
  Card,
  Form,
} from "antd";
import { BsXCircleFill, BsDownload, BsCloudUpload, BsFillCheckCircleFill } from "react-icons/bs";

import axios from 'axios';

import { useForm } from "react-hook-form";
import {
  propertyService, serverUrl
} from "../../../../configs/constants";
import type { UploadFile } from 'antd/es/upload/interface';
import { InboxOutlined } from '@ant-design/icons';


import { CSVLink } from "react-csv";

import { Upload } from 'antd';
import { RcFile } from "antd/lib/upload/interface";
const { Dragger } = Upload;
const { Content } = Layout;

var _ = require("lodash");


export default (props) => {
  // const initials = { unit:0, building:0 };
  const [failedData, setFailed] = useState([]);
  const [successData, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  const { control, formState: { errors }, setValue, handleSubmit } = useForm();

  const headers = [
    { label: "Business Unit Name", key: "businessunit" },
    { label: "Building Name", key: "buildingname" },
    { label: "Property Reference Number", key: "propertyreferencenumber" },
    { label: "Unit Code", key: "unitcode" },
    { label: "Floor Number", key: "floornumber" },
    { label: "Parking Slot Number", key: "parkingslotnumber" },
    { label: "Reason", key: "reason" }
  ];

  const csvReport = {
    data: failedData,
    headers: headers,
    filename: 'Parking_Failure_log.csv'
  };



  // csv
  const [file, setFile] = useState();
  const [array, setArray] = useState([]);

  const [parkingFile, setParking] = useState<UploadFile[]>([]);

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const status = () => {
    let text = "";
    if (successData)
      text = ` Successfully Added  ${successData} Parking Units`;
    else
      text = "";
    return text;
  }

  const resetModal = () => {
    setParking([]);
    setFailed([]);
    setSuccess(null);
  }

  const onFinish = async (values) => {

    resetModal();
    setLoading(true);

    if (parkingFile[0]) {

      let fd = new FormData();
      fd.append('file_asset', parkingFile[0] as RcFile);
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };

      const url = `${propertyService}/property/UploadParkingFile`;

      await axios.post(url, fd, config).then((response) => {

        const { data } = response;

        console.log("RESP,", response);

        if (data?.result?.failedresult?.length > 0) {
          const failedArry = _.map(data.result.failedresult, (e) => {
            return {
              "businessunit": e.businessunit,
              "buildingname": e.buildingname,
              "floornumber": e.floornumber,
              "unitcode": e.unitcode,
              "parkingslotnumber": e?.parkingslotnumber,
              "propertyreferencenumber": e?.propertyreferencenumber,
              "reason": e.reason
            }
          });
          setFailed(failedArry);
        }
        if (data?.result?.successResultCount) {
          setSuccess(data?.result?.successResultCount?.bulkDataToCreateUnitCount);
          message.success(response.data.message);
          props.onSubmit();
        }
        setLoading(false);
        setParking([]);

      }).catch((error) => {

        setLoading(false);
        message.error(error.response.data.error)

      })

    } else {
      setLoading(false);
      message.error("Please select a file to upload");
    }

  };
  const headerKeys = Object.keys(Object.assign({}, ...array));

  const parkingFileProps = {
    className: "file-upload",
    name: "file",
    accept: '.csv',
    multiple: false,
    // showUploadList: false,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {

      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} Please upload only CSV file`);
      }
    },
    onDrop(e) {

      // setParking(e.dataTransfer.files);
    },
    onRemove: (file) => {
      setParking([]);
    },
    beforeUpload: (file) => {
      setParking([file]);
      return false;
    },
    parkingFile,
  };


  return (

    <Layout className="layout-space prop_popup">
      <Content className="main-content">

        <Row gutter={24}>

          <Col flex="1 1 500px">
            {/* File Uploader */}
            <div style={{ textAlign: "center" }}>

              <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
                <Row gutter={24}>


                  <Dragger {...parkingFileProps}
                    fileList={parkingFile}

                    className="drag_drop_csv">
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                      Download the sample template and fill the data as per the template, convert master file into CSV and upload it.
                      <br></br><br></br>
                      <span>Note: </span>Only CSV files are accepted.
                    </p>
                  </Dragger>
                  <div className="csvmodel">
                    <a className="downloadcsv" href={`${serverUrl}/templates/ParkingTemplate.xlsx`} download>
                      {<BsDownload />} Download parking template file
                    </a>

                  </div>
                  <Col span={24} className="csv_error_wrap">
                    {loading ? (
                      <Spin />
                    ) :

                      (<>

                        {successData ?
                          (
                            <p className="success_csv">{<BsFillCheckCircleFill />}
                              {status()}
                            </p>
                          )
                          : ""}

                        {failedData?.length > 0 ? (
                          <div className="csv_error_log">
                            <span className="csv_error"> {<BsXCircleFill color='red' />} {failedData?.length} - Records Failed <CSVLink className="csv_log" {...csvReport}>{<BsDownload />} Download Failure Log</CSVLink></span><br />
                            {/* <span className="csv_log"><CSVLink {...csvReport}>Download Failure Log</CSVLink></span> */}
                          </div>
                        ) : ""}

                      </>)}
                    <Button htmlType="submit" disabled={loading} type="primary" size="large" className="csv_publish">
                      {<BsCloudUpload />}Publish
                    </Button>
                  </Col>


                </Row>
              </Form>

              <br />
            </div>
          </Col>

        </Row>
      </Content>
    </Layout>
  );
};
