import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardLayout from "../../../molecules/DashboardLayout";
import {
  Row,
  Col,
  Button,
  Typography,
  Calendar,
  Space,
  DatePicker,
  ConfigProvider,
} from "antd";
import BreadCrumb from "../../../atoms/BreadCrumb";
import SectionHeader from "../../../molecules/SectionHeader";
import CalendarCell from "./CalendarCell";
import dayjs from "dayjs";
import en_GB from "antd/lib/locale-provider/en_GB";
import { getNotificationByCalendar } from "../ducks/action";

const { Title } = Typography;

const breadcrumbList: any = [
  {
    label: "Notification",
    route: "/notifications",
  },
  {
    label: "Calendar",
    route: `/notifications/calendar`,
  },
];

export default (props) => {
  const dispatch:any = useDispatch();
  // states
  const [datePicker, setdatePicker] = useState(dayjs(new Date(Date.now())));
  const [scheduledVisits, setScheduledVisits] = useState([]);
  let [startDateOfMonth, setStartDateOfMonth] = useState<string>(
    dayjs(new Date(Date.now())).startOf("month").format("YYYY-MM-DD"),
  );
  let [endDateOfMonth, setEndDateOfMonth] = useState<string>(
    dayjs(new Date(Date.now())).endOf("month").format("YYYY-MM-DD"),
  );
  let [selectedMonth, setSelectedMonth] = useState<string>(
    dayjs(new Date(Date.now())).format("MMMM"),
  );
  let [selectedYear, setSelectedYear] = useState<string>(
    dayjs(new Date(Date.now())).format("YYYY"),
  );
  let [selectedDate, setSelectedDate] = useState<Object>(
    dayjs(new Date(Date.now())),
  );

  // redux
  const scheduledVisitsAPI = useSelector(
    (state: any) => state.notification.notifyBydate,
  );

  useEffect(() => {
    dispatch(getNotificationByCalendar(startDateOfMonth, endDateOfMonth));
  }, []);

  useEffect(() => {
    if (scheduledVisitsAPI) {
      setScheduledVisits(scheduledVisitsAPI);
    }
  }, [scheduledVisitsAPI]);

  const onDateSelect = (date) => {
    setSelectedMonth(dayjs(date).startOf("month").format("MMMM"));
    selectedMonth = dayjs(date).startOf("month").format("MMMM");
    setSelectedYear(dayjs(date).startOf("month").format("YYYY"));
    selectedYear = dayjs(date).startOf("month").format("YYYY");

    setSelectedDate(
      dayjs()
        .month(+selectedMonth)
        .year(parseInt(selectedYear))
        .startOf("month"),
    );
    selectedDate = dayjs()
      .month(+selectedMonth)
      .year(parseInt(selectedYear))
      .startOf("month");
    setStartDateOfMonth(
      dayjs(+selectedDate).startOf("month").format("YYYY-MM-DD"),
    );
    startDateOfMonth = dayjs(+selectedDate)
      .startOf("month")
      .format("YYYY-MM-DD");
    setEndDateOfMonth(dayjs(+selectedDate).endOf("month").format("YYYY-MM-DD"));
    endDateOfMonth = dayjs(+selectedDate).endOf("month").format("YYYY-MM-DD");

    dispatch(getNotificationByCalendar(startDateOfMonth, endDateOfMonth));
  };

  const handleDateRange = (date) => {
    if (date) {
      setdatePicker(dayjs(date));
      onDateSelect(date);
    }
  };

  const handleOnCelanderChange = (date) => {
    if (date) {
      setdatePicker(date);
      onDateSelect(date);
    }
  };

  const toDate = () => {
    setdatePicker(dayjs(new Date(Date.now())));
    onDateSelect(new Date(Date.now()));
  };

  const renderDataCell = (value) => {
    return <CalendarCell value={value} scheduledVisits={scheduledVisits} />;
  };

  const onSelectDate = () => {};

  return (
    <>
      <DashboardLayout>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <BreadCrumb list={breadcrumbList} />
          </Col>
          <Col span={24}>
            <SectionHeader noBorder={true} heading="Notifiction Calendar" />
          </Col>
          <Col span={24}>
            <Space size={24}>
              <DatePicker
                defaultValue={datePicker}
                value={datePicker}
                size="large"
                picker="month"
                onChange={handleDateRange}
              />
              <Button type="primary" size="large" onClick={toDate}>
                Jump to Today
              </Button>
            </Space>
          </Col>
          <Col span={24}>
            <ConfigProvider locale={en_GB}>
              <Calendar
                headerRender={() => {
                  return (
                    <>
                      <Row
                        gutter={8}
                        style={{
                          padding: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col>
                          <Title level={4}>
                            {`${selectedMonth ? selectedMonth : ""} ${
                              selectedYear ? selectedYear : ""
                            }`}
                          </Title>
                        </Col>
                      </Row>
                    </>
                  );
                }}
                defaultValue={dayjs(datePicker)}
                className="ag-calender visitSchedule"
                onPanelChange={onSelectDate}
                dateCellRender={renderDataCell}
                value={dayjs(+selectedDate).startOf("month")}
                onSelect={handleOnCelanderChange}
              />
            </ConfigProvider>
          </Col>
        </Row>
      </DashboardLayout>
    </>
  );
};
