import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import agEdit from "../../../assets/img/ag-edit.svg";

import SectionHeader from "../../../../molecules/SectionHeader";
import PropertyList from "../../../../molecules/PropertyList";
import {
  downloadPropertyData,
  getCountProperties,
  getPinProperties,
  getPropertiesList,
} from "../../ducks/actions";
import { propertyService } from "../../../../../configs/constants";
import axios from "axios";
import message from "antd/lib/message";
import { Popup, PopupConfirm } from "../../../../atoms/Popup";
import Title from "antd/es/typography/Title";
import CreateProperty from "../../AddProperty/CreateProperty";
import PaymentPlans from "../../AddProperty/PaymentPlans";
import { MenuProps } from "antd";
import CreateParkings from "../../AddParkings/CreateParkings";

const list = {
  search: "",
  sortby: "",
  sort: "",
  page: 1,
  limit: 10,
};

const showOption = [
  {
    value: "All",
    label: "All Properties",
  },
];

export default () => {
  const navigate = useNavigate();
  const dispatch:any = useDispatch();
  const [propertyCard, setPropertyCard] = useState(list);
  const [propertyData, setpropertyData] = useState([]);

  const propertiesCardApi = useSelector((state: any) => state.dashboard.propertiesCard);

  
  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(0);
  



  const role = JSON.parse(window.localStorage.getItem("login-response"))?.data
    ?.role_name;
  const fileData = useSelector((state: any) => state.dashboard.downloadFile);


  const MenuItems: MenuProps["items"] = [
    {
      label: "Property",
      key: 0,
    },
    {
      label: "Parkings",
      key: 1,
    },
    {
      label: "Payment Plans",
      key: 2,
    },
  ];


  const onDropClick: MenuProps["onClick"] = ({ key }) => {
    setVisible(true);
    setTrigger(+key);
  };



  
  const onAdd = () => {
    // setVisible(false);
    listCard();
    // dispatch(getSubscriptions(1, search))
  };

  
  const popup = [
    {
      title: (
        <Title level={3} className="m-0">
          Upload Properties details through CSV file
        </Title>
      ),
      content: (
        <CreateProperty
        onSubmit={onAdd}
        //  onCancel={() => setVisible(false)} 
        //  onUpdate={setUpates}
        //   onchangeUpdate={updates} 
          />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="m-0">
          Upload Parkings details through CSV file
        </Title>
      ),
      content: (
        <CreateParkings
        // onSubmit={onAdd}
        //  onCancel={() => setVisible(false)} 
        //  onUpdate={setUpates}
        //   onchangeUpdate={updates} 
          />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="m-0">
          Upload Payment plans through CSV file
        </Title>
      ),
      content: (
        <PaymentPlans
          onSubmit={onAdd}
        />
      ),
      width: 850,
    },
  ];
  
  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger]?.title,
    content: popup[trigger]?.content,
    width: popup[trigger]?.width,
    onCancel: () => setVisible(false),
  };



  const handleShow = () => {};

  function listCard() {
    dispatch(
      getPropertiesList(
        propertyCard.search,
        propertyCard.page,
        propertyCard.limit,
        true,
        "",
        "",
      ),
    );
  }

  useEffect(() => {
    fileData && setpropertyData(fileData);
  }, [fileData]);

  const onStatus = (id, status) => {
    PopupConfirm({
      title: `Are you sure you want to ${
        status === "disabled" ? "unpublish" : "publish"
      } this property?`,
      content: "This will change the property status",
      onOk: () => onStatusChanged(id, status),
      okText: "Confirm",
    });
  };

  const onStatusChanged = async (id, status) => {
    const url = `${propertyService}/property-landlord/change-property-status/${id}`;
    try {
      const res = await axios.put(url, { property_status: status });
      message.success(res?.data?.message || "Status Updated");
      listCard();
      dispatch(getPinProperties());
      dispatch(getCountProperties());
      dispatch(getPropertiesList(undefined, 1, 10, false, "published", true));
      dispatch(getPropertiesList(undefined, 1, 10, false, "rented", true));
    } catch (e) {
      const { response } = e;
      message.error(response?.data?.message);
    }
  };

  useEffect(() => {
    listCard();
  }, [propertyCard]);

  const onExport = (assetID) => {
    // dispatch(downloadPropertyData(assetID));
  };

  return (
    <>
      <SectionHeader
        heading="Properties"
        total={propertiesCardApi?.totalCount || 0}
        showOption={showOption}
        handleShow={handleShow}
        search={(e) =>
          setPropertyCard({
            ...propertyCard,
            search: e,
            page: 1,
            limit: propertiesCardApi?.perPage
          })
        }
        justifiedItems={true}
        justifyEnd={true}
        searchLabel="Search by property name"
        // addNewAction={role == "institutionalLandlord" ? addProperty : null}
        // addNewAction={addProperty}
        // addText="Property"
        dropNewAction={onDropClick}
        dropItems={MenuItems}
        dropTrigger={["click"]}

      />
    
      <PropertyList
        data={propertiesCardApi?.data}
        count={propertiesCardApi?.totalCount}
        type="p"
        pagination={true}
        perPage={propertiesCardApi?.perPage}
        currentPage={propertyCard.page}
        onpageChange={(e) => setPropertyCard({ ...propertyCard, page: e })}
        onStatusChanged={onStatus}
        onExport={onExport}
        propertyData={propertyData}
      />
        <Popup destroyOnClose={true} {...popupProps} />
    </>
  );
};
