import React, { Fragment, useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  Col,
  Row,
  Button,
  Typography,
  Radio,
  Form,
  Table,
  message,
} from "antd";
import {
  InputField,
  DateField,
  ReactSelectField,
  InputRadio,
} from "../../../atoms/FormElement";
import { useFieldArray, useWatch } from "react-hook-form";
import { CheckCircleFilled, PlusOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { getLandlordUsers, getTermsConditions } from "../ducks/actions";
import Billings from "./Billings";
import { installmentsList } from "../../Properties/ducks/constants";
import { getBillingCharges } from "../../LeasingForm/ducks/actions";
import { leaseService } from "../../../../configs/constants";
import axios from "axios";
import PaymentPlans from "./PaymentPlans";

var _ = require("lodash");
const { Text, Paragraph } = Typography;

// const billingSelection = [
//   { label: "Base Rent", value: 1,  },
//   { label: "EJARI FEE", value: 2,  },
//   { label: "Security Deposit", value: 3,  },
//   { label: "Parking Charges", value: 4, isDisabled: false },
//   { label: "Management Charges", value: 5, isDisabled: false },
//   { label: "Rental Comission", value: 6, isDisabled: false },
//   { label: "Housekeeping Services", value: 7, isDisabled: false },
//   { label: "Gen Services Charges", value: 8, isDisabled: false },
//   { label: "Furniture Charges", value: 9, isDisabled: false },
//   { label: "Utilities", value: 10, isDisabled: false },
// ];

const initcharge = {
  itemId: null,
  type: "",
  intallments: { label: 1, value: 1 },
  mode: {
    label: "Cheque",
    value: "cheque",
  },
  amount: 0,
};
const initPark = {
  itemId: null,
  type: { label: "PARKING", value: "parking_charges" },
  intallments: { label: 1, value: 1 },
  amount: 0,
  slot: null,
};

export default (props) => {
  const dispatch: any = useDispatch();
  const {
    setValue,
    register,
    control,
    data,
    errors,
    getValues,
    parking,
    billingsApi,
    paid,
    leaseId,
    legalEntity,
    leasestatus,
    disableAll,
    saveBtn,
    isLeaseActive,
    onUpdateLeaseManager,
    bookingPeriod,
    propertyId,
    plansData,
    isFurnished,
    // setSelectedPaymentPlans
  } = props;
  const [noIstallment, setNoInstallment] = useState([]);
  const [billingType, setBillingType] = useState([]);
  const [deleteIndex, setDeleteIndex] = useState(2); //startIndex for delete in billings

  const landlordUsers = useSelector(
    (state: any) => state.leasingForm.landlordUsers
  );

  // redux states
  const { bookingUnitDetails } = useSelector((state: any) => state.newBooking);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [selectedPlans, setselectedPlans] = useState([]);

  const details = data?.bookingTerms;

  const [landlordUserOptions, setlandlordUserOptions] = useState([]);

  const get_billing_charges = [
    { name: "Rent Installment", key: "rent_installment" },
    { name: "Ejari fee", key: "ejari_fee" },
    { name: "Security Deposit", key: "security_deposit" },
    { name: "Rental Commission", key: "rental_commission" },
    { name: "Administration Charges", key: "administration_charges" },
    { name: "Furniture Charges", key: "furniture_charges" },
    { name: "Parking Charges", key: "parking_charges" },
    { name: "Service Charges", key: "service_charges" },
  ];

  const { fields, prepend, append, remove, insert } = useFieldArray({
    control,
    name: "othercharges",
  });

  const billing = details?.billingCharges ?? [];

  const bookingStart = useWatch({
    control,
    name: "bookingStartDate",
  });

  useEffect(() => {
    if (data?.paymentPlan?.length > 0) {
      const payIds = data.paymentPlan?.map((item) => item?.paymentPlanId);
      setselectedPlans(payIds);
    } else {
      setselectedPlans([]);
    }
  }, [data?.paymentPlan]);

  useEffect(() => {
    if (plansData.length > 0) {
      if (
        selectedPlans?.length == 0 ||
        isFurnished != plansData[0]?.furnished
      ) {
        let array = Array.from(Array(plansData.length).keys()); // [...Array(plansData.length).keys()];
        setSelectedRowKeys(array);
        array?.length > 0 && onSelectChange(array);
      }
    }
  }, [plansData, isFurnished]);

  useEffect(() => {
    if (landlordUsers?.length > 0) {
      const LLOptions = landlordUsers.map((user) => ({
        label: user.name,
        value: user.userId,
      }));
      setlandlordUserOptions(LLOptions);
    } else {
      propertyId && dispatch(getLandlordUsers(propertyId));
    }
  }, [landlordUsers, propertyId]);

  // useEffect(() => {
  //   dispatch(getLandlordUsers(propertyId));
  // }, []);

  const ejariProcessing = useWatch({
    control,
    name: "ejariProcessing",
  });

  useEffect(() => {
    if (ejariProcessing?.value == "manual") {
      if (fields[1]?.["type"]?.value == "ejari_fee") remove(1);
      enableClone("ejari_fee");
      setDeleteIndex(1);
    } else if (ejariProcessing?.value == "auto") {
      if (fields[1]?.["type"]?.value != "ejari_fee") {
        insert(1, {
          type: { label: "ejari_fee", value: "ejari_fee", disabled: true }, //get_billing_charges.find((e) => e.key == "ejari_fee"),
          intallments: {
            label: 1,
            value: 1,
          },
          mode: {
            label: "Cheque",
            value: "cheque",
          },
          amount: 0,
        });
      }
      // disableClone("ejari_fee");
      // disableClone("rent_installment");
      // disableClone("security_deposit");
      setDeleteIndex(2);
    }
  }, [ejariProcessing]);

  useEffect(() => {
    let billingTypeTemp = [];
    if (get_billing_charges.length > 0) {
      get_billing_charges?.map((item, index) => {
        let isDisabledTemp = false;
        if (
          item.key == "rent_installment" ||
          item.key == "ejari_fee" ||
          item.key == "security_deposit"
        ) {
          isDisabledTemp = true;
        }
        if (item.key != "base_rent_ic") {
          billingTypeTemp.push({
            label: item.name,
            value: item.key,
            isDisabled: isDisabledTemp,
          });
        }
      });
      setBillingType(billingTypeTemp);
    }
  }, []);

  const parkingLimit = () => {
    append(initcharge);
    // disableClone("new");
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    let filterData = [newSelectedRowKeys.length];
    // console.log("JOKE", newSelectedRowKeys)
    if (newSelectedRowKeys.length > 0 && newSelectedRowKeys.length < 6) {
      filterData = plansData.filter((val, index) =>
        newSelectedRowKeys.includes(index)
      );
      // console.log('selectedRowKeys changed: ', plansData, newSelectedRowKeys, data);
      setSelectedRowKeys(newSelectedRowKeys);
    } else {
      filterData = [];
      message.info("Choose 1-5 plans, select at least 1");
    }
    // setSelectedPaymentPlans(filterData);
    filterData.length > 0 && setValue("selectedPaymentPlans", filterData);
  };

  const rowSelection = {
    selectedRowKeys,
    type: "check",
    onChange: onSelectChange,
    getCheckboxProps: (record) => {
      return {
        disabled: data?.leaseType === "new" && data?.tenantDetails?.paymentPlanId
      };
    }
  };

  // useEffect(()=>{
  //   onSelectChange(selectedRowKeys);
  // },[]);

  const disableClone = (type) => {
    let arr = [];
    arr = getValues().othercharges;
    let check = arr.filter((item) => item.type.value == type);
    if (
      (type == "PARKING" && check.length > 4) ||
      (type != "PARKING" && check.length > 0)
    ) {
      // console.log("checkcheck",check)
      let newArr = [];
      newArr = [...billingType];
      let findType = null;
      findType = newArr.findIndex((item) => item.value == type);
      // console.log("findType",findType)
      if (findType) {
        billingType.map((item, index) => {
          let b = [];
          b = arr.filter((y) => item.value === y.type.value);

          if (b.length > 0) {
            if (item.value === "PARKING") {
              if (b.length > 4) {
                newArr[index].isDisabled = true;
              } else {
                newArr[index].isDisabled = false;
              }
            } else {
              newArr[index].isDisabled = true;
            }
          } else {
            newArr[index].isDisabled = false;
          }
        });
        // console.log("findType", newArr)
        setBillingType(Object.assign([], newArr));
      }
    } else {
      let newArr = [];
      newArr = [...billingType];
      let check2 = arr.filter((item) => item.type.value == "PARKING");
      arr.map((x) => {
        let a = newArr?.findIndex((item) => item.value == x.type.value);
        if (a) {
          if (x.type.value != "PARKING") {
            newArr[a].isDisabled = true;
          } else {
            if (check2.length > 3) {
              newArr[a].isDisabled = true;
            }
          }
        }
      });
      setBillingType(Object.assign([], newArr));
    }
  };

  const enableClone = (type) => {
    let newArr = [];
    newArr = [...billingType];
    let findType = null;
    findType = newArr.findIndex((item) => item.value == type);
    newArr[findType].isDisabled = false;
    setBillingType(Object.assign([], newArr));
  };

  const disableBookingstartDate = (current) => {
    let today = dayjs().format("YYYY-MM-DD");
    return current && dayjs(current).format("YYYY-MM-DD") < today;
  };

  const disableBookingendDate = (current) => {
    // let bookingStart = dayjs().format("YYYY-MM-DD") ;
    let start = bookingStart && dayjs(bookingStart).format("YYYY-MM-DD");
    let end =
      bookingStart &&
      dayjs(bookingStart).add(bookingPeriod, "day").format("YYYY-MM-DD");
    // console.log("today",today,current.valueOf())
    return (
      (current && dayjs(current).format("YYYY-MM-DD") < start) ||
      (current && dayjs(current).format("YYYY-MM-DD") > end)
    );
  };

  const setBookingEndDate = (start) => {
    if (start) {
      console.log("bookingPeriod:ad", bookingPeriod);
      var end = dayjs(start).add(bookingPeriod, "day");
      setValue("bookingEndDate", end);
    } else setValue("bookingEndDate", "");
  };

  useEffect(() => {
    if (!details?.bookingStartDate) {
      const today = dayjs();
      setValue("bookingStartDate", today);
      setBookingEndDate(today);
    }
  }, [bookingPeriod]);

  useEffect(() => {
    if (details) {
      let installmentNo = details?.numberOfInstallments || 1;
      setValue("annualBaseRent", details.annualBaseRent);
      setValue("externalAgent", data.externalAgent);
      setValue("noOfInstallments", {
        label: installmentNo,
        value: installmentNo,
      });

      // console.log("ISFURNISHED",isFurnished);
      isFurnished && setValue("furnished", isFurnished?.toUpperCase());

      data?.leaseManager &&
        setValue("leaseManager", {
          value: data?.leaseManager?.userId,
          label: data?.leaseManager?.name,
        });

      // details?.legalEntity && setValue("legalEntity", details?.legalEntity);

      setValue("nonRefundableAmount", details?.nonRefundableAmount); //security deposit
      // setValue("poBox", details.poBox);
      if (details?.bookingStartDate) {
        setValue(
          "bookingStartDate",
          dayjs(details?.bookingStartDate, "YYYY-MM-DD")
        );
      }
      if (details?.bookingEndDate) {
        setValue(
          "bookingEndDate",
          dayjs(details?.bookingEndDate, "YYYY-MM-DD")
        );
      }

      //CHANGES MADE DUE TO CHANGES IN NEW CREATE BOOKING UI: ref bug: 7433
      if (billing?.length > 0 && billingType.length > 0) {
        billing.map((item) => {
          // console.log("billitem", item)
          if (item.billingChargesType == "rent_installment") {
            append({
              // itemId: item.itemId,
              type: billingType.find((e) => e.value == item.billingChargesType),
              intallments: {
                label: item?.numberOfInstallments || 1,
                value: item?.numberOfInstallments || 1,
              },
              mode: {
                label: item?.modeOfPayment || "Cheque",
                value: item?.modeOfPayment || "cheque",
              },
              amount: item.total,
              ...item,
              // slot: item.parkingSlot,
            });
          } else if (item.billingChargesType == "ejari_fee") {
            append({
              // itemId: item.itemId,
              type: billingType.find((e) => e.value == item.billingChargesType),
              intallments: {
                label: item?.numberOfInstallments || 1,
                value: item?.numberOfInstallments || 1,
              },
              mode: {
                label: item?.modeOfPayment || "Cheque",
                value: item?.modeOfPayment || "cheque",
              },
              amount: item.total,
              ...item,
              // slot: item.parkingSlot,
            });
          } else if (item.billingChargesType == "security_deposit") {
            append({
              // itemId: item.itemId,
              type: billingType.find((e) => e.value == item.billingChargesType),
              intallments: {
                label: item?.numberOfInstallments || 1,
                value: item?.numberOfInstallments || 1,
              },
              mode: {
                label: item?.modeOfPayment || "Cheque",
                value: item?.modeOfPayment || "cheque",
              },
              amount: item.total,
              ...item,
              // slot: item.parkingSlot,
            });
          } else {
            if (item.billingChargesType) {
              let type = billingType.find((e) => e.value == item.billingChargesType);
              // let type = { label: item.type, value: item.type };
              if (type) {
                append({
                  // itemId: item.itemId,
                  type: { ...type, isDisabled: true },
                  intallments: {
                    label: item?.numberOfInstallments || 1,
                    value: item?.numberOfInstallments || 1,
                  },
                  mode: {
                    label: item?.modeOfPayment || "Cheque",
                    value: item?.modeOfPayment || "cheque",
                  },
                  amount: item.total,
                  ...item,
                  // slot: item.parkingSlot,
                });
              }
            }
          }
        });
        // if (leasestatus == "lease" && paid && paid?.no_of_paid_parking_slots) {
        //   let x = billingsApi?.filter((item) => item.type == "parking_charges");
        //   for (
        //     let i = 0;
        //     i < Number(paid?.no_of_paid_parking_slots) - x.length;
        //     i++
        //   )
        //     append(initPark);
        // }
      }
    }
  }, [details, data]);

  // useEffect(() => {
  //   if (!leaseId && billing?.length == 0) {
  //     prepend([{
  //       type: { label: "rent_installment", value: "rent_installment" }, //get_billing_charges.find((e) => e.key == ""),
  //       intallments: {
  //         label: 1,
  //         value: 1,
  //       },
  //       amount: 0,
  //     },
  //     {
  //       // itemId: item.itemId,
  //       type: { label: "ejari_fee", value: "ejari_fee", disabled: true }, //get_billing_charges.find((e) => e.key == "ejari_fee"),
  //       intallments: {
  //         label: 1,
  //         value: 1,
  //       },
  //       amount: 0,
  //     },
  //     {
  //       // itemId: item.itemId,
  //       type: { label: "security_deposit", value: "security_deposit" },//get_billing_charges.find((e) => e.key == "security_deposit"),
  //       intallments: {
  //         label: 1,
  //         value: 1,
  //       },
  //       amount: 0,
  //     }]);
  //   }
  // }, [leaseId]);

  useEffect(() => {
    if (bookingUnitDetails?.businessUnitName)
      setValue("legalEntity", bookingUnitDetails?.businessUnitName);
  }, [bookingUnitDetails]);

  // useEffect(() => {
  //   if (details?.termsAndConditionsEntity && termsConditions.length) {
  //     let y = termsConditions.find(
  //       (x) => x.value == details?.termsAndConditionsEntity,
  //     );
  //     if (y) {
  //       setValue("legalEntity", y);
  //     }
  //   }
  // }, [details, termsConditions]);

  return (
    <>
      <Col span={24} style={{ padding: 0 }}>
        {/* <Row> */}
        <Col span={8}>
          <ReactSelectField
            label="Lease Executive"
            fieldname="leaseManager"
            control={control}
            valueGot={
              data?.leaseManager
                ? {
                    value: data?.leaseManager?.userId,
                    label: data?.leaseManager?.name,
                  }
                : null
            }
            iProps={{ placeholder: "Select one", isDisabled: isLeaseActive }} // disable when lease active
            selectOption={landlordUserOptions}
            isRequired={true}
            setValue={setValue}
            register={register}
            resetField={props?.resetField}
            validate={errors.leaseManager && "error"}
            validMessage={errors.leaseManager && errors.leaseManager.message}
            rules={{ required: "Please select one" }}
          />
          {/* hide when lease active or saveButton  */}
          {!isLeaseActive && !saveBtn && (
            <Button
              type="link"
              style={{
                position: "absolute",
                top: 0,
                right: "15px",
              }}
              onClick={onUpdateLeaseManager}
              className={`p-0 no-border no-shadow`}
              icon={<CheckCircleFilled />}
              htmlType="button"
            >
              Save
            </Button>
          )}
        </Col>
        <Col span={8}>
          <InputField
            label="External Agent"
            fieldname="externalAgent"
            control={control}
            initValue=""
            setValue={setValue}
            iProps={{ placeholder: "Enter External Agent" }}
          />
        </Col>
        {/* </Row> */}
      </Col>
      {/* <Col span={8}>
        <InputField
          label="Annual base rent"
          fieldname="annualBaseRent"
          control={control}
          initValue=""
          iProps={{ placeholder: "0", }}
          isRequired={true}
          validate={errors.annualBaseRent && "error"}
          validMessage={
            errors.annualBaseRent && errors.annualBaseRent.message
          }
          disabled={true}
          rules={{ required: "Please enter amount" }}
        />
      </Col>
      <Col span={8}>
        <ReactSelectField
          label="No. of Instalments"
          fieldname="noOfInstallments"
          control={control}
          initValue={{label: "1", value: 1 }}
          iProps={{ placeholder: "Select one", isDisabled: true }}
          selectOption={installmentsList}
          isRequired={true}
          validate={errors.noOfInstallments && "error"}
          validMessage={
            errors.noOfInstallments && errors.noOfInstallments.message
          }
          rules={{ required: "Please select no. of installments" }}
        />
      </Col>
      <Col span={8}>
        <InputField
          label="Non-Refundable booking amount"
          fieldname="nonRefundableAmount"
          control={control}
          initValue={0}
          iProps={{ placeholder: "Enter rent amount", }}
          isRequired={true}
          validate={errors.nonRefundableAmount && "error"}
          validMessage={
            errors.nonRefundableAmount && errors.nonRefundableAmount.message
          }
          disabled={true}
          rules={{ required: "Please enter amount" }}
        />
      </Col> */}
      <Col span={8}>
        <DateField
          label="Booking start date"
          fieldname="bookingStartDate"
          control={control}
          initValue=""
          onChange={(e) => setBookingEndDate(e)}
          iProps={{
            placeholder: "DD-MM-YYYY",
            format: "DD-MM-YYYY",
            disabledDate: disableBookingstartDate,
            disabled: disableAll,
          }}
          isRequired={true}
          validate={errors.bookingStartDate && "error"}
          validMessage={
            errors.bookingStartDate && errors.bookingStartDate.message
          }
          rules={{ required: "Please select date" }}
        />
      </Col>
      <Col span={8}>
        <DateField
          label="Booking End date"
          fieldname="bookingEndDate"
          control={control}
          setValue={setValue}
          initValue=""
          iProps={{
            placeholder: "DD-MM-YYYY",
            format: "DD-MM-YYYY",
            disabledDate: disableBookingendDate,
            disabled: disableAll,
          }}
          isRequired={true}
          validate={errors.bookingEndDate && "error"}
          validMessage={errors.bookingEndDate && errors.bookingEndDate.message}
          rules={{ required: "Please select date" }}
        />
      </Col>
      <Col span={8}>
        <InputField
          label="Legal Entity"
          fieldname="legalEntity"
          control={control}
          iProps={{ placeholder: "Enter legal entity" }}
          // selectOption={termsConditions}
          isRequired={true}
          validate={errors.legalEntity && "error"}
          validMessage={errors.legalEntity && errors.legalEntity.message}
          disabled={bookingUnitDetails?.businessUnitName ? true : false}
          rules={{ required: "Please enter legal entity" }}
        />
      </Col>

      {/* <Col span={8}>
        <InputField
          label="P.O Box"
          fieldname="poBox"
          control={control}
          initValue=""
          iProps={{
            placeholder: "Enter P.O. Box",
            type: "number",
          }}
          disabled={disableAll}
        // isRequired={true}
        // validate={errors.poBox && "error"}
        // validMessage={
        //   errors.poBox && errors.poBox.message
        // }
        // rules={{ required: "Please enter P.O box number" }}
        />
      </Col> */}
      <Col span={6}>
        <InputRadio
          isRequired={true}
          label="Is the Property Furnished?"
          fieldname="furnished"
          // noStyle={true}
          control={control}
          initValue={isFurnished || "YES"}
          disabled={data?.leaseType === "new" && data?.tenantDetails?.paymentPlanId}
          options={
            <Row gutter={[16, 16]}>
              <Col span={6}>
                <Radio value="YES">Yes</Radio>
              </Col>
              <Col span={6}>
                <Radio value="NO">No</Radio>
              </Col>
            </Row>
          }
        />
      </Col>
      {/* <hr> */}
      <Col span={23}>
        <Paragraph className="m-20 ag-primary smallFont12">
          Check the box to offer this Payment Plan, uncheck to remove it.
        </Paragraph>
        <PaymentPlans
          booking={true}
          rowSelection={rowSelection}
          plansData={plansData}
          setSelectedRowKeys={setSelectedRowKeys}
          selectedPlan={selectedPlans}
        />
        <div style={{ height: "0", overflow: "hidden", margin: "0" }}>
          <InputField
            label=""
            fieldname={`selectedPaymentPlans`}
            setValue={setValue}
            // valueGot={item?.type?.value}
            control={control}
            initValue=""
            iProps={{ readOnly: true, type: "hidden" }}
          />
        </div>
      </Col>
      <Col span={23}>
        {fields.map((item, index) => (
          <Fragment key={item.id}>
            <Billings
              key={item.id}
              item={item}
              billingType={billingType}
              control={control}
              errors={errors}
              remove={remove}
              setValue={setValue}
              disableAll={props.disableAll}
              getValues={getValues}
              index={index}
              deleteIndex={deleteIndex}
              noIstallment={noIstallment}
              noIData={installmentsList}
              parking={parking}
              setNoInstallment={setNoInstallment}
              disableClone={disableClone}
              enableClone={enableClone}
            />
          </Fragment>
        ))}
        {!props.disableAll && (
          <Row gutter={24}>
            <Col span={24}>
              <Button
                type="link"
                className="p-0"
                htmlType="button"
                icon={<PlusOutlined />}
                onClick={parkingLimit}
              >
                <Text
                  className="ag-primary fontWeight600"
                  style={{ fontSize: "18px" }}
                >
                  Add more billing
                </Text>
              </Button>
            </Col>
          </Row>
        )}
      </Col>
    </>
  );
};
