import React from "react";

const index = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.1523 3.46094L18.9922 10.1055C18.6406 10.0352 18.2891 10 17.9375 10C17.4102 10 16.9531 10.0703 16.4609 10.1758L10.625 5.21875L4.4375 10.457V18.4375C4.4375 19.0703 4.92969 19.5625 5.5625 19.5625H7.25V14.2188C7.25 13.4453 7.84766 12.8125 8.65625 12.8125H12.5938C12.6289 12.8125 12.6641 12.8125 12.7344 12.8477C12.3828 13.3398 12.1367 13.9023 11.9609 14.5H8.9375V19.5625H12.3125H12.7344C13.1562 20.2305 13.7188 20.8281 14.3516 21.25H5.5625C3.98047 21.25 2.75 20.0195 2.75 18.4375V11.8984L1.87109 12.6367C1.51953 12.918 0.992188 12.8828 0.675781 12.5312C0.394531 12.1797 0.429688 11.6523 0.78125 11.3359L10.0625 3.46094C10.3789 3.21484 10.8359 3.21484 11.1523 3.46094ZM23 16.1875C23 18.0156 22.0156 19.668 20.4688 20.582C18.8867 21.4961 16.9531 21.4961 15.4062 20.582C13.8242 19.668 12.875 18.0156 12.875 16.1875C12.875 14.3945 13.8242 12.7422 15.4062 11.8281C16.9531 10.9141 18.8867 10.9141 20.4688 11.8281C22.0156 12.7422 23 14.3945 23 16.1875ZM20.293 14.6758C20.082 14.4648 19.6953 14.4648 19.4844 14.6758L17.375 16.8203L16.3555 15.8008C16.1445 15.5898 15.7578 15.5898 15.5469 15.8008C15.3359 16.0117 15.3359 16.3984 15.5469 16.6094L16.9531 18.0156C17.1641 18.2266 17.5508 18.2266 17.7617 18.0156L20.293 15.4844C20.5039 15.2734 20.5039 14.8867 20.293 14.6758Z" fill="#24435D" />
        </svg>
    );
};

export default index;
