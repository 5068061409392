import React, { useState, useEffect } from "react";

// others
import dayjs from "dayjs";
import Button from "../../../../molecules/Button";
import { useForm, useWatch } from "react-hook-form";
import { Row, Col, Space } from "antd";
import { apiDateFormat } from "./utils";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";

type iProps = {
  dateRange: any;
};

const index = (props: iProps) => {
  const formate = "MMM DD, YYYY";

  return (
    <Row gutter={16} className="roster-main-footer">
      {props.dateRange && (
        <p className="roster-footer_date-label">{`${apiDateFormat(
          props.dateRange[0],
          formate
        )} - ${apiDateFormat(props.dateRange[1], formate)}`}</p>
      )}
      <Space>
        <Button
          label="Cancel"
          onClick={() => {}}
          className="roster-footer_cancel-button"
        />

        <Button
          label="Apply"
          onClick={() => {}}
          className="roster-footer_apply-button"
        />
      </Space>
    </Row>
  );
};

export default index;
