import { Row, Col, Typography, Card, Image, Button } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "../../../../../../utils/axiosInceptor";
import {
  // baseContractService,
  leaseService,
} from "../../../../../../configs/constants";
import Grid from "../../../../../atoms/Grid";
import { Popup } from "../../../../../atoms/Popup";
import { dateByformat } from "../../../../../../utils/dateHelper";
import { getPresignedImage } from "../../../../../../utils/media";
import PdfViewer from "../../../../../molecules/PDFViewer/pdvViewNew";


export default (props) => {

  const tenantData = useSelector(
    (state: any) => state.tenantsSummary.tenantData,
  );

  const [leaseData, setLeaseData] = useState([]);
  const [viewOrDownloadUrl, setViewOrDownloadUrl] = useState("");
  const [showPdf, setShowPdf] = useState(false);
  const [visible, setVisible] = useState(false);

  const leaseDetail = tenantData?.leaseDetail;

  useEffect(() => {
    const documentDetails = leaseDetail?.find(lease => lease?.documents?.length > 0);
    const leaseDocumentDetails = leaseDetail?.find(lease => lease?.ejari?.ejariDocuments?.length > 0);
    const userDocumentDetail = documentDetails?.tenantDetails;
    const approvedDocs = documentDetails?.requiredDocuments;
    const ejari = leaseDocumentDetails?.ejari?.ejariDocuments;
    const userDocuments = documentDetails?.documents;
    let contract

    if(ejari?.length > 0) {
      contract = {
        type: 'Tenancy Contract',
        documentRef: "N/A",
        expiryDate: "N/A",
        actions: ejari?.find(document => (document.documentName === 'LTV_landlordSignedEjariContract' && document.documentId))?.documentId || 
        ejari?.find(document => (document.documentName === 'LTV_tenantSignedEjariContract' && document.documentId))?.documentId ||
        ejari?.find(document => (document.documentName === 'unSignedEjariContract' && document.documentId))?.documentId
      }
    }
    
    setLeaseData([
      approvedDocs?.find(document => document.type === 'passport')?.status === 'approved' && {
        type: 'Passport',
        documentRef: userDocumentDetail?.passport || "N/A",
        expiryDate: userDocumentDetail?.passportExpiry ? dateByformat(userDocumentDetail?.passportExpiry, "DD-MM-YYYY") :  "N/A" || "N/A",
        actions: approvedDocs?.find(document => document.type === 'passport')?.documentId || userDocuments?.find(document => document.documentName === 'passport')?.documentId
      },
      approvedDocs?.find(document => document.type === 'uaeVisa')?.status === 'approved' && {
        type: 'Visa',
        documentRef: userDocumentDetail?.uaeVisaNumber || "N/A",
        expiryDate: userDocumentDetail?.uaeVisaExpiry ? dateByformat(userDocumentDetail?.uaeVisaExpiry, "DD-MM-YYYY") :  "N/A" || "N/A",
        actions: approvedDocs?.find(document => document.type === 'uaeVisa')?.documentId || userDocuments?.find(document => document.documentName === 'uaeVisa')?.documentId
      },
      approvedDocs?.find(document => document.type === 'emiratesIdFront')?.status === 'approved' && {
        type: 'Emirates Front',
        documentRef: userDocumentDetail?.emiratesID || "N/A",
        expiryDate: userDocumentDetail?.emiratesExpiry ? dateByformat(userDocumentDetail?.emiratesExpiry, "DD-MM-YYYY") :  "N/A" || "N/A",
        actions: approvedDocs?.find(document => document.type === 'emiratesIdFront')?.documentId || userDocuments?.find(document => document.documentName === 'emiratesIdFront')?.documentId
      },
      approvedDocs?.find(document => document.type === 'emiratesIdBack')?.status === 'approved' && {
        type: 'Emirates Back',
        documentRef: userDocumentDetail?.emiratesID || "N/A",
        expiryDate: userDocumentDetail?.emiratesExpiry ? dateByformat(userDocumentDetail?.emiratesExpiry, "DD-MM-YYYY") :  "N/A" || "N/A",
        actions: approvedDocs?.find(document => document.type === 'emiratesIdBack')?.documentId || userDocuments?.find(document => document.documentName === 'emiratesIdBack')?.documentId
      },
      approvedDocs?.find(document => document.type === 'companyTradeLicense')?.status === 'approved' && {
        type: 'Trade License',
        documentRef: "N/A",
        expiryDate: "N/A",
        actions: approvedDocs?.find(document => document.type === 'companyTradeLicense')?.documentId || userDocuments?.find(document => document.documentName === 'companyTradeLicense')?.documentId
      },
      approvedDocs?.find(document => document.type === 'bankStatement')?.status === 'approved' && {
        type: 'Bank Statement',
        documentRef: "N/A",
        expiryDate: "N/A",
        actions: approvedDocs?.find(document => document.type === 'bankStatement')?.documentId || userDocuments?.find(document => document.documentName === 'bankStatement')?.documentId
      },
      contract
    ])
  }, [tenantData]);

  const getUrl = async (data) => {

    let payload = typeof data !== 'string' ? data : [data]

    const url = await getPresignedImage(payload, true, true);
    setViewOrDownloadUrl(url[0]?.location);
    if(url[0]?.documnentData?.type === 'pdf') {
      setShowPdf(true);
    } else {
      setVisible(true);
    }
  };

  const columns = [
    {
      headerName: "Type",
      field: "type",
      width: 370
    },
    {
      headerName: "Document Ref",
      field: "documentRef",
      width: 370
    },
    {
      headerName: "Expiry Date",
      field: "expiryDate",
      width: 370
    },
    {
      headerName: "Actions",
      field: "actions",
      width: 400,
      cellRendererFramework: viewRender,
    }
  ];
  
  function viewRender(param) {
    if(param?.data?.actions) {
      return (
        <Row>
          <Button size="large" type="primary" 
            onClick={() => {
                param.value && getUrl(param.value);
              }
            } 
            style={{marginRight: 10}}>View</Button>
  
          <Button size="large" type="primary" onClick={() => downLoadDocument(param)}>Download</Button>
        </Row>
      );
    } else {
      return ''
    }

  }

  const downLoadDocument = async (param) => {

    const url = `${leaseService}/document/getDocument/${param.value}?preview=false`;
    
    try {
      const response = await axios.get(url, { headers: { 'bussiness-unit': 'AUTO', 'accept': '*/*', 'organization-unit': 'AGP', 'channel': 'MOBILE', 'accept-language': 'EN', 'source': 'Orion' } })
        
      let { data } = response;

        var link = document.createElement('a');
        link.target="_blank"
        link.href = data?.result?.location
        link.download = `${param?.data?.type?.replace(/\s+/g, "")}.${data?.result?.documnentData?.type}`;
        link.dispatchEvent(new MouseEvent('click'));

    } catch (error) {
      
    }
  }

  const onClosePopUp = () => {
    setViewOrDownloadUrl('')
    setVisible(false);
  };

  const popupContent = {
    closable: true,
    visibility: visible,
    content: (
      <Image
        preview={false}
        width="100%"
        style={{ padding: "20px" }}
        src={viewOrDownloadUrl}
      />
    ),
    width: 650,
    onCancel: onClosePopUp,
  };

  
  return (
    <>
      <PdfViewer
        pdfData={viewOrDownloadUrl}
        openFileView={showPdf}
        setOpenFileView={setShowPdf}
      />
      <Card bordered={false} bodyStyle={{ padding: 0 }}>
        <Row gutter={24}>
          <Col span={24}>
            <Grid
              data={leaseData?.filter(lease => lease)}
              columns={columns}
              pagination={false}
              noRowlabel="Documents"
            />
          </Col>
        </Row>
      </Card>
      <Popup {...popupContent} />
    </>
  )
};
