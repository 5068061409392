import React, {  Fragment } from "react";
import { Card, Row, Col, Typography,} from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import { InputField, InputSwitch } from "../../../atoms/FormElement";

const RequiredDocs = (props) => {
  const { control,setValue, index, document, docLabel } = props;
  const { Title } = Typography;

  const getDocLabel = (key) => {
    if (docLabel?.length > 0)
      return docLabel?.filter(item => item?.value == key)?.[0]?.label;
    else return key;

  }

  return (
    <Fragment>
      <Card bordered={false} className="document-card">
        <Row gutter={24} justify="space-between" align="middle">
          <Col span={12}>
            <Row gutter={24}>
              <Col span={12} style={{ display: "flex" }}>
                <FileTextOutlined className="require-doc" />
                <Title level={5}>{document?.title || `${getDocLabel(document?.type)}`}</Title>
              </Col>
              <Col span={6}>
                <InputField
                  classes="fm-input-hidden"
                  fieldname={`requiredDocuments[${index}].type`}
                  control={control}
                  // valueGot={document?.length && document[index]?.type}
                  // setValue={setValue}
                  initValue={document?.type}
                  iProps={{ readOnly: true, type: "hidden" }}
                />
                <InputSwitch
                  isRequired={true}
                  label=""
                  fieldname={`requiredDocuments[${index}].checked`}
                  valueGot={true}
                  setValue={setValue}
                  // noStyle={true}
                  // onChange={(e) => onPaymentPlans(e.target.value)}
                  control={control}
                  initValue={true}
                />
              </Col>
            </Row>
          </Col>
          <Col>
          </Col>
        </Row>
      </Card>
    </Fragment>
  );
};

export default RequiredDocs;
