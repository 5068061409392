// others
import { GET_PARKING_BAYS, GET_PAYMENT_PLANS, GET_TEMPLATES_DATA } from "./types";
import {
  CREATE_RENEWAL,
  SET_FORM_STATE,
  CREATE_BOOKING,
  UPDATE_BOOKING,
  DELETE_BOOKING,
  GET_CONFIG_DATA,
  SET_BOOKING_FORM,
  GET_BOOKING_DETAIL,
  GET_LANDLORD_USERS,
  CLEAR_BOOKING_FORM,
  GET_BOOKING_UNIT_DETAILS,
  GET_TENANT_DETAILS_BY_EMAIL,
} from "./types";
import {
  tracker,
  filterConfigData,
  filteLandlordUsers,
  filterBookingDetails,
} from "./utils";

const initialState = {
  formState: 1,
  configData: {},
  bookingForm: {},
  tracker: tracker,
  paymentPlans: [],
  landlordUsers: [],
  bookingDetails: {},
  bookingUnitDetails: {},
  templatesData: [],
  parkingUnits:[]
};

export default (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {
    case CLEAR_BOOKING_FORM:
      return {
        ...state,
        formState: 1,
        bookingForm: {},
        bookingDetails: {},
      };
    case SET_BOOKING_FORM:
      return { ...state, bookingForm: { ...state.bookingForm, ...data } };
    case SET_FORM_STATE:
      const tracker = state.tracker;
      let formState = state.formState;

      if (data !== "back") {
        if (formState <= tracker.length - 1) {
          tracker[formState].active = true;
          tracker[formState - 1].complete = true;
          formState = formState + 1;
        }
      } else {
        formState = formState - 1;
        tracker[formState].active = false;
        tracker[formState].complete = false;
        tracker[formState - 1].active = true;
        tracker[formState - 1].complete = false;
      }

      return { ...state, tracker, formState };
    case GET_BOOKING_DETAIL:
      if (data)
        state = {
          ...state,
          bookingForm: filterBookingDetails(data, state.bookingForm),
        };

      return { ...state, bookingDetails: data };
    case GET_BOOKING_UNIT_DETAILS:
      return { ...state, bookingUnitDetails: data };
    case GET_CONFIG_DATA:
      return {
        ...state,
        configData: filterConfigData(data),
      };
    case CREATE_BOOKING:
      return { ...state, bookingDetails: data };
    case CREATE_RENEWAL:
      return { ...state };
    case UPDATE_BOOKING:
      return {
        ...state,
        bookingDetails: data?.data?.result,
        bookingForm: filterBookingDetails(
          data?.data?.result,
          state.bookingForm
        ),
      };
    case GET_TENANT_DETAILS_BY_EMAIL:
      return {
        ...state,
        bookingForm: filterBookingDetails(
          { tenantDetails: data },
          state.bookingForm
        ),
      };
    case GET_LANDLORD_USERS:
      return { ...state, landlordUsers: filteLandlordUsers(data) };
    case DELETE_BOOKING:
      return {
        ...state,
        formState: 1,
        bookingForm: {},
        bookingDetails: {},
      };
    case GET_PAYMENT_PLANS:
      return { ...state, paymentPlans: data };
    case GET_TEMPLATES_DATA:
      return {
        ...state,
        templatesData: data,
      };
    case GET_PARKING_BAYS:
      return { ...state, parkingUnits: data };
    default:
      return state;
  }
};
