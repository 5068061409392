import React,{useEffect, useState} from "react";
import { Row, Col } from "antd";
import {
  DateField,
  InputField,
  TextAreaField,
  ReactSelectField
} from "../../../../../atoms/FormElement";
import { getUsers } from "../../../../BookingForm/ducks/services";
import moment from "moment";

var _ = require("lodash");

export default (props) => {

  const { control, errors, noEdit } = props;

  const [userList,setUserList] = useState([]);

  const getUserList = async ()=>{
     
    try{
       const users =  await getUsers();
       let valueAndLabel = [];

       users?.forEach((item,index)=>{
        valueAndLabel.push({
          value:item?.userId,
          label:item?.name
        })
       })
       setUserList(valueAndLabel)
  
    }catch(error){

    }

   }

   useEffect(()=>{
    userList?.length === 0 && getUserList();
   },[])
  return (
    <Row gutter={24}>
      <Col span={12}>
        <InputField
          isRequired={!noEdit}
          label="Building Name"
          fieldname="buildingName"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Building Name",
            readOnly: noEdit,
            className: `${noEdit ? "noEdit" : ""}`,
          }}
          rules={{ required: "Please enter Building Name" }}
          validate={errors.buildingName && "error"}
          validMessage={errors.buildingName && errors.buildingName.message}
        />
      </Col>
      <Col span={12}>
        <InputField
          isRequired={!noEdit}
          label="Property Reference Number"
          fieldname="propertyReferenceNumber"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Property Reference Number",
            readOnly: noEdit,
            className: `${noEdit ? "noEdit" : ""}`,
          }}
          rules={{ required: "Please enter property reference number" }}
          validate={errors.propertyReferenceNumber && "error"}
          validMessage={
            errors.propertyReferenceNumber &&
            errors.propertyReferenceNumber.message
          }
        />
      </Col>
      <Col span={12}>
        <InputField
          isRequired={!noEdit}
          label="Business Unit Name"
          fieldname="businessUnitName"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Business Unit Name",
            readOnly: noEdit,
            className: `${noEdit ? "noEdit" : ""}`,
          }}
          disabled={true}
          rules={{ required: "Please enter Business Unit Name" }}
          validate={errors.businessUnitName && "error"}
          validMessage={
            errors.businessUnitName && errors.businessUnitName.message
          }
        />
      </Col>

      <Col span={12}>
        <InputField
          isRequired={!noEdit}
          label="Property Code"
          fieldname="propertyCode"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Property Code",
            readOnly: noEdit,
            className: `${noEdit ? "noEdit" : ""}`,
          }}
          rules={{ required: "Please enter Property Code" }}
          validate={errors.propertyCode && "error"}
          validMessage={errors.propertyCode && errors.propertyCode.message}
        />
      </Col>

      <Col span={12}>
        {/* <InputField
          // isRequired={!noEdit}
          control={control}
          classes="noEditLabel"
          label="Property Admin"
          fieldname="propertyAdmin"
          validate={errors.propertyAdmin && "error"}
          // rules={{ required: "Please enter Property Code" }}
          validMessage={errors.propertyAdmin && errors.propertyAdmin.message}
          iProps={{
            // readOnly: true,
            // className: "noEdit",
            placeholder: "Property Admin",
          }}
        /> */}

      <ReactSelectField
          isRequired={!noEdit}
          label="Property Admin"
          fieldname="propertyAdmin"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Select one",
            isDisabled: noEdit,
            className: `${noEdit ? "disablerselect" : ""}`,
          }}
          selectOption={userList}
          rules={{ required: "Please select property admin" }}
          validate={errors.propertyAdmin && "error"}
          validMessage={errors.propertyAdmin && errors.propertyAdmin.message}
        />
      </Col>
      {/* <Col span={6}>
        <DateField
          isRequired={false}
          label="Built In"
          fieldname="builtIn"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            className: `${noEdit ? "noEdit nodateset" : ""}`,
            disabled: noEdit,
            format: "YYYY-MM-DD",
          }}
        />
      </Col>
      <Col span={6}>
        <DateField
          isRequired={!noEdit}
          label="Available From"
          fieldname="available_from"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            className: `${noEdit ? "noEdit nodateset" : ""}`,
            disabled: noEdit,
            format: "YYYY-MM-DD",
          }}
          rules={{
            required: "Please enter date",
            setValueAs: (value) => moment(value, "YYYY-MM-DD"),
          }}
          validate={errors.available_from && "error"}
          validMessage={errors.available_from && errors.available_from.message}
        />
      </Col>
      <Col span={24}>
        <TextAreaField
          label="Description"
          fieldname="description"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            readOnly: noEdit,
            className: `${noEdit ? "noEdit" : ""}`,
            autoSize: { minRows: 4, maxRows: 4 },
          }}
          rules={{
            required: "Please enter description",
          }}
          validate={errors.description && "error"}
          validMessage={errors.description && errors.description.message}
        />
      </Col> */}
    </Row>
  );
};
