import React from "react";
import { Typography, Card } from "antd";

const { Title, Text } = Typography;

const HeadingChip = (props) => {
  const { heading, value } = props;

  return (
    <Card bordered={false} style={{ borderRadius: 4 }}>
      <Title level={4} className="chips-head mb-0 ag-primary">
        {heading} {value && <Text>({value})</Text>}
      </Title>
    </Card>
  );
};

export default HeadingChip;
