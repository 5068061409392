import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm, useWatch } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";

import {
  Row,
  Col,
  Button,
  Typography,
  Form,
  Collapse,
  message,
  Card,
  Radio,
  Space,
  UploadFile,
  Upload,
  Steps,
} from "antd";
import {
  EQARI_GOOGLE_KEY,
  EQARI_GOOGLE_KEY_TRANSLATION,
  leaseService,
} from "../../../configs/constants";
import TenantDetails from "./components/TenantDetails";
import axios from "../../../utils/axiosInceptor";
import { Popup, PopupConfirm } from "../../atoms/Popup";
import DashboardLayout from "../../molecules/DashboardLayout";
import {
  getParkingSlots,
  getLeaseFormDetails,
  getLeaseAdditionalDocumentList,
  emptyLead,
  getPaymentModes,
} from "./ducks/actions";
import UnitDetails from "./components/UnitDetails";
import LeaseTerms from "./components/LeaseTerms";
import {
  uniquiFileName,
  getContractSingleUpload,
  getSingleUpload,
} from "../../../utils/fileUpload";
import BookingTerms from "./components/BookingTerms";
import RenewalTerms from "./components/RenewalTerms";
import BillingSchedule from "./components/BillingSchedule";
import DocumentsList from "./components/DocumentsList";
import AdditionalDocument from "./components/AdditionalDocs";
import ApprovalHistory from "./components/ApprovalHistory";
import Rejectform from "./components/RejectFrom";
import PaymentSchedule from "./components/PaymentSchedule";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { getPaymentConfig } from "../Properties/AddProperty/ducks/actions/paymentAction";
import { cancelLeaseForm } from "../../../utils/fileDownload";
import {
  ActivateEjari,
  CreateEjariContract,
  DownloadEjariContract,
  PrintEjariCertificateContract,
  sendTenantInvite,
  updateBookingStatus,
  UploadEjariCertificate,
  UploadSignedEjariContract,
} from "../BookingListing/ducks/actions";
import { getConfigData } from "../Properties/ducks/actions";
import { RcFile } from "antd/lib/upload/interface";
import {
  CheckOutlined,
  SettingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { getPresignedImage } from "../../../utils/media";
import dayjs from "dayjs";
import { dateByformat } from "../../../utils/dateHelper";
import { ReactSelectField } from "../../atoms/FormElement";
import PdfTemplateView from "../../molecules/PDFViewer/pdfTemplateView";
import { PendingIcon } from "../../atoms/Icons";
import RejectPopup from "./components/RejectionPopup";
import { TENANCY_CONTRACT, getDocumentKey } from "../../../configs/documents";
import PaymentPlans from "./components/PaymentPlans";
import { validateEmail } from "../../../utils/helper";
import { getDetailsFromEmail } from "../Maintenance/Technicians/ducks/services";
import { getBookingUnitDetail } from "../BookingForm/ducks/actions";
import { payment_plans } from "../BookingForm/components/utils";

var _ = require("lodash");

const { Title } = Typography;
const { Panel } = Collapse;

const fileObjectinit = {
  passportFile: {
    uniqueName: "",
    fileData: "",
  },
  visaFile: {
    uniqueName: "",
    fileData: "",
  },
  emiratesFile: {
    uniqueName: "",
    fileData: "",
  },
  financialSupportFile: {
    uniqueName: "",
    fileData: "",
  },
  signedBookFile: {
    uniqueName: "",
    fileData: "",
  },
  otherDocsFile: {
    uniqueName: "",
    fileData: "",
  },
};

export default (props) => {
  const dispatch: any = useDispatch();
  const {
    control,
    // set,
    handleSubmit,
    formState: { errors, isDirty },
    clearErrors,
    setValue,
    setError,
    getValues,
    unregister,
    reset,
    trigger,
    resetField,
    register,
  } = useForm();

  const { LEASEID } = useParams();
  const { state } = useLocation();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isClearTriggered, setIsClearTriggered] = useState(false);
  const [visible, setVisible] = useState(false);
  const [popTrigger, setPopTrigger] = useState(0);

  const [disableAll, setDisableAll] = useState(false);
  const [disableApproveReject, setDisableApproveReject] = useState(false);
  const [disableCancelButton, setDisableCancelButton] = useState(false);
  const [disableLeasebtn, setDisableLeasebtn] = useState(false);
  const [deletedAdditionalDocs, setDeletedAdditionalDocs] = useState([]);
  const [disabletenant, setDisableTenant] = useState(false);
  const [isLeaseActive, setIsLeaseActive] = useState(false);
  const [isBookingApproved, setIsBookingApproved] = useState(false);

  const [priorLeaseEnd, setPriorLeaseEnd] = useState(null);

  const templateOptions = [
    { label: "Additional Document", value: "additionalDocument" },
  ];

  const [templateDocs, setTemplateDocs] = useState(templateOptions);

  //Booking create to booking approved

  const [cancelBtn, setCancelBtn] = useState(true);
  const [saveBtn, setSaveBtn] = useState(true);
  const [updatePdcBtn, setUpdatePdcBtn] = useState(false);
  const [sendBtn, setSendBtn] = useState(false);
  const [reSendBtn, setReSendBtn] = useState(false);
  const [approveBtn, setApproveBtn] = useState(false);
  const [rejectBtn, setRejectBtn] = useState(false);
  const [confirmBtn, setConfirmBtn] = useState(false);

  //Booking approved to Ejari/Lease
  //template creation from button
  const [contractCreated, setContractCreated] = useState(false);
  const [contractSent, setContractSent] = useState(false);
  const [contractSigned, setContractSigned] = useState(false);

  //manual
  const [uploadEjariCertificateBtn, setUploadEjariCertificateBtn] =
    useState(false);
  //auto
  const [createEjariBtn, setCreateEjariBtn] = useState(false);
  const [downloadEjariBtn, setDownloadEjariBtn] = useState(false);
  const [uploadEjariBtn, setUploadEjariBtn] = useState(false);
  const [activateEjariBtn, setActivateEjariBtn] = useState(false);
  const [downloadCertificateBtn, setDownloadCertificateBtn] = useState(false);

  const [sendBookingApprovalBtn, setSendBookingApprovalBtn] = useState(false);
  const [editBookingBtn, setEditBookingBtn] = useState(false);
  const [manageBookingApprovalBtn, setManageBookingApprovalBtn] =
    useState(false);
  const [sendLeaseApprovalBtn, setSendLeaseApprovalBtn] = useState(false);
  const [manageLeaseApprovalBtn, setManageLeaseApprovalBtn] = useState(false);

  const [sendSignatureBtn, setSendSignatureBtn] = useState(false);

  const [ejariDownloadUrl, setEjariDownloadUrl] = useState("");
  const [signedEjariDownloadUrl, setSignedEjariDownloadUrl] = useState("");
  const [ejariCertificateDownloadUrl, setEjariCertificateDownloadUrl] =
    useState("");

  const [showPdf, setShowPdf] = useState(false);
  const [templatePDF, setTemplatePDF] = useState("");
  const [templateData, setTemplateData] = useState(null);

  const [currentStep, setCurrentStep] = useState(null);

  const [requiredDocuments, setRequiredDocuments] = useState([]);

  const [generatedDocuments, setGeneratedDocuments] = useState([]);

  const [changedPaymentPlan, setChangedPaymentPlan] = useState([]);

  const [finalRequiredDocuments, setFinalRequiredDocuments] =
    useState(fileObjectinit);
  const [sendEmptyDoc, setSendEmptyDoc] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState({});
  const [rejectionStatus, setRejectionStatus] = useState("");
  const [rejectionDocument, setRejectionDocument] = useState("");
  const [legalEntity, setLegalEntity] = useState("");
  const [bookingPeriod, setBookingPeriod] = useState(14);

  const [unitDetail, setUnitDetail] = useState(null);

  const [tenantAccepted, setTenantAccepted] = useState(false);

  const [isCSVUploaded, setIsCSVUploaded] = useState(false);

  // const [isManagerApproval, setIsManagerApproval] = useState(false);

  const [signedEjariFile, setSignedEjariFile] = useState<UploadFile[]>([]);

  const [ejariCertificateFile, setEjariCertificateFile] = useState<
    UploadFile[]
  >([]);
  const [emailDetailsLoading, setEmailDetailsLoading] = useState(false);
  const leaseFormData = useSelector(
    (state: any) => state.leasingForm.leaseFormData
  );


  const configKeys = ["nationalityList", "requiredBookingDocumentsList"];
  const configData = useSelector((state: any) => state.dashboard.configData);
  const parkingSlotsDataFromApi = useSelector(
    (state: any) => state.leasingForm.parkingSlots
  );

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [plansData, setPlansData] = useState([]);
  const [unitPlans, setUnitPlans] = useState([]);

  useEffect(() => {
    dispatch(getConfigData(configKeys.join(",")));
  }, []);

  const translate = async (str, field) => {
    const apiKey = EQARI_GOOGLE_KEY_TRANSLATION;
    const apiUrl = "https://translation.googleapis.com/language/translate/v2";

    const params = new URLSearchParams({
      q: str,
      source: "en",
      target: "ar",
      key: apiKey,
    });

    const url = `${apiUrl}?${params.toString()}`;
    const repo = await fetch(url, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data?.data?.translations) {
          const translatedText = data?.data?.translations?.[0]?.translatedText;
          setValue(field, translatedText);
          // console.log(`Translated text (): ${translatedText}`);
        }
      })
      .catch((error) => {
        console.error("Error translating text:", error);
      });
  };

  useEffect(() => {
    if (state?.isDisabledAll === true) {
      setDisableAll(true);
    }
  }, [state]);


  const PaymentType = [];
 

 



  useEffect(() => {
    if (state?.selectedProperty && state?.selectedUnit) {
      const unit = {
        propertyName: state?.selectedProperty?.property_name,
        propertyId: state?.selectedProperty?.propertyId, //added for parking-slots
        unitCode: state?.selectedUnit?.unit?.unitCode,
        usagesType: state?.selectedUnit?.unit?.usagesType,
        unitType: state?.selectedUnit?.unit?.unitType,
        unitSubType: state?.selectedUnit?.unit?.unitSubType,
        areaSqft:
          state?.selectedUnit?.unit?.areaSqft ??
          state?.selectedUnit?.unit?.suiteSize,
      };
      setSelectedUnit(unit);
      const businessUnit = state?.selectedUnit?.businessUnitName;
      const bookingPeriod = state?.selectedUnit?.bookingPeriod;

      if (businessUnit) setLegalEntity(businessUnit);
      if (bookingPeriod) setBookingPeriod(bookingPeriod);
      if (state?.selectedUnit?.leaseStartDate)
        //renewal-active vacating case
        setPriorLeaseEnd(state?.selectedUnit?.leaseStartDate);
    }
  }, [state]);

  useEffect(() => {
    if (leaseFormData?.propertyDetails)
      setUnitDetail({
        ...leaseFormData?.propertyDetails,
        propertyId: leaseFormData?.propertyId,
      });
    else if (selectedUnit) setUnitDetail(selectedUnit);
  }, [leaseFormData?.propertyDetails, selectedUnit]);

  useEffect(() => {
    if (unitDetail?.propertyId)
      dispatch(getParkingSlots(unitDetail?.propertyId));
  }, [unitDetail]);

  const goBack = () => {
    if (state?.isLease == true) navigate("/bookingforms");
    else navigate("/bookings");
  };

  useEffect(() => {
    LEASEID && dispatch(getLeaseFormDetails(LEASEID));
    
    return (): any => dispatch(emptyLead());
  }, []);

  useEffect(() => {
    if (leaseFormData?.unitId)
      dispatch(getBookingUnitDetail(leaseFormData?.unitId));
  }, [leaseFormData?.unitId]);

  

  const resetButtons = () => {
    setSaveBtn(true);
    setUpdatePdcBtn(false);
    setCancelBtn(true);
    setSendBtn(false);
    setReSendBtn(false);

    setApproveBtn(false);
    setRejectBtn(false);
    setConfirmBtn(false);

    setUploadEjariCertificateBtn(false);
    setCreateEjariBtn(false);
    setDownloadEjariBtn(false);
    setUploadEjariBtn(false);
    setActivateEjariBtn(false);
    setDownloadCertificateBtn(false);

    if (state?.isDisabledAll === true) setDisableAll(true);
    else setDisableAll(false);
  };

  useEffect(() => {
    resetButtons();
    if (leaseFormData) {
      const status = leaseFormData?.leaseStatus;
      var documentsPending = 0;
      var bookingConfirmed = false;
      var kycApproved = false;
      var bookingApproved = false;
      var paymentsReceived = false;
      const ejariProcessing = leaseFormData?.leaseTerms?.ejariProcessing;
      var contractCreated = false;
      var contractSent = false;
      var tenantSigned = false;
      var contractSigned = false;
      var tenantAccepted = false;

      const loginUserType = leaseFormData?.loginUserType;

      if (leaseFormData?.requiredDocuments?.length > 0) {
        documentsPending = leaseFormData?.requiredDocuments.filter((item) => {
          return item.status !== "approved";
        }).length;
      }

      // onPaymentPlans(isFurnished);

      const managerApproval =
        leaseFormData?.managerApproval === "enable" ? true : false;

      const leaseManagerApproval =
        leaseFormData?.leaseManagerApproval === "enable" ? true : false;

      if (leaseFormData?.leaseStatusHistory?.length > 0) {
        bookingConfirmed = !!leaseFormData?.leaseStatusHistory.find(
          (e) => e.status === "documents_submitted"
        ); // booking_confirmed -> kyc_submitted
        tenantAccepted = !!leaseFormData?.leaseStatusHistory.find(
          (e) => e.status === "kyc_approved"
        ); // tenant_information_submitted -> kyc_submitted -> kyc_approved
        let csvUploaded = !!leaseFormData?.leaseStatusHistory.find(
          (e) => e.status === "uploaded"
        );
        kycApproved = !!leaseFormData?.leaseStatusHistory.find(
          (e) => e.status === "kyc_approved"
        );
        contractCreated = !!leaseFormData?.leaseStatusHistory.find(
          (e) => e.status === "contract_created"
        );
        contractSent = !!leaseFormData?.leaseStatusHistory.find(
          (e) => e.status === "awaiting_tenant_signature"
        ); //contract_send_to_tenant -> awaiting_tenant_signature
        tenantSigned = !!leaseFormData?.leaseStatusHistory.find(
          (e) => e.status === "awaiting_landlord_signature"
        ); //contract_signed_by_tenant ->   awaiting_landlord_signature
        contractSigned = !!leaseFormData?.leaseStatusHistory.find(
          (e) => e.status === "contract_signed"
        );
        bookingApproved = !!leaseFormData?.leaseStatusHistory.find(
          (e) => e.status === "booking_approved"
        );
        paymentsReceived = !!leaseFormData?.leaseStatusHistory.find(
          (e) => e.status === "rent_payments_received"
        );

        setIsCSVUploaded(csvUploaded);
        setTenantAccepted(tenantAccepted);

        setContractCreated(contractCreated);
        setContractSent(contractSent);
        setContractSigned(contractSigned);
      }

      const check = {
        save: true,
        cancel: true,
        send: false,
        resend: false,
        approve: false,
        reject: false,
        updatepdc: false,
        confirm: false,
        uploadCertificate: false,
        createEjari: false,
        downloadEjari: false,
        uploadEjari: false,
        activateEjari: false,
        downloadEjariCertificate: false,
        isBookingApproved: false,
        sendBookingApproval: false,
        editBooking: false,
        bookingApproval: false,
        sendLeaseApproval: false,
        leaseApproval: false,
        sendSignature: false,
      };

      if (status) {
       
        if (status === "booking_created" || status === "created") {
          //changed from created
          check.send = true;
          check.cancel = true;
        }
        if (status === "tenant_invitation_sent") {
          check.cancel = true;
          check.resend = true;
        }

        if (status === "kyc_submitted" || status === "documents_submitted") {
          check.cancel = true;
          check.resend = false;
        }

        if (
          status !== "kyc_approved" &&
          status !== "kyc_rejected" &&
          documentsPending === 0
        ) {
          check.approve = true;
          check.reject = true;
          check.cancel = true;
        }
        // if (status === "kyc_approved") {
        //   // check.updatepdc = true;
        //   check.save = false;
        //   check.cancel = true;
        // }

        if (managerApproval === true && kycApproved && !bookingApproved) {
          if (loginUserType === "leaseManager") {
            check.save = true;
            check.sendBookingApproval = true;
            if (status === "sent_for_booking_approval") {
              //awaiting_booking_request changed from
              check.save = false;
              setDisableAll(true);
              // check.sendBookingApproval = false;
              check.editBooking = true;
            }
          } else if (loginUserType === "managersManager") {
            check.save = false;
            setDisableAll(true);
            if (status === "sent_for_booking_approval")
              check.bookingApproval = true;
          } else if (loginUserType === "landlord") {
            check.save = false;
            setDisableAll(true);
            check.bookingApproval = true;
            // if (status === "sent_for_booking_approval")
            //   check.bookingApproval = true;
          }
        }

        if (kycApproved && status === "documents_approved") {
          check.sendBookingApproval = false;
        } else if (kycApproved) {
          check.save = false;
          setDisableAll(true);
        }

        if (bookingApproved) {
          check.updatepdc = true;
        }

        if (
          leaseManagerApproval === true &&
          paymentsReceived &&
          !contractCreated
        ) {
          if (loginUserType === "leaseManager") {
            check.save = true;
            check.sendLeaseApproval = true;
            if (status === "sent_for_lease_approval") {
              //sent_for_lease_approval changed from
              check.save = false;
              setDisableAll(true);
              check.sendLeaseApproval = false;
              // check.editBooking = true;
            }
          } else if (loginUserType === "managersManager") {
            check.save = false;
            setDisableAll(true);
            if (status === "sent_for_lease_approval")
              check.leaseApproval = true;
          }
        } else if (paymentsReceived) {
        }

        if (contractCreated && !tenantSigned) {
          check.sendSignature = true;
        }
        if (contractSigned) {
          check.activateEjari = true;
        }

        // on booking approved
        // UPDATE PDC BUTTON
        // check.updatepdc = true;

        //on Send For Approval
        // awaiting_booking_request  --> form readonly / disableAll == true

        //on Edit Booking
        // status update  --> form readonly / disableAll == false

        ///REMOVE THIS STATUS NOT APPLICABLE ANYMORE
        // if (status === "landlord_pdc_updated") {
        //   check.confirm = true;
        //   check.updatepdc = true;
        //   check.save = false;
        //   check.cancel = true;
        //   check.approve = false;
        //   check.reject = false;

        // }

        // if (status === "booking_approved") {
        //   if (ejariProcessing === "auto") {
        //     check.createEjari = true;
        //   } else if (ejariProcessing === "manual") {
        //     check.uploadCertificate = true;
        //   }

        //   check.updatepdc = true; //changed for new paymentupdate //old: false, also below ones too
        //   check.save = false;
        //   check.cancel = true;
        //   check.approve = false;
        //   check.reject = false;

        //   check.isBookingApproved = true;

        // }
        // if (status === "draft_ejari" || status === "tenant_signed_ejari_contract"
        //   || status === "landlord_signed_ejari_contract" || status === "ejari_contract_downloaded"
        //   || status === "tenant_signed_ejari_contract_downloaded"
        //   || status === "ejari_contract_activated") {

        //   check.updatepdc = true; //added
        //   check.downloadEjari = true;
        //   check.save = false;
        //   check.cancel = false;
        //   check.approve = false;
        //   check.reject = false;

        //   check.isBookingApproved = true;
        // }

        // if (status === "tenant_signed_ejari_contract" || status === "tenant_signed_ejari_contract_downloaded") {
        //   check.updatepdc = true; //added
        //   check.uploadEjari = true;
        //   check.save = false;
        //   check.cancel = false;
        //   check.approve = false;
        //   check.reject = false;

        //   check.isBookingApproved = true;
        // }
        // if (status === "landlord_signed_ejari_contract") {
        //   check.updatepdc = true; //added
        //   check.activateEjari = true;
        //   check.save = false;
        //   check.cancel = false;
        //   check.approve = false;
        //   check.reject = false;

        //   check.isBookingApproved = true;
        // }
        // if (status === "ejari_contract_activated" || status === "ejari_certificate_downloaded" || status === "active") {
        //   check.updatepdc = true; //added
        //   check.downloadEjariCertificate = true;
        //   check.activateEjari = true;
        //   check.save = false;
        //   check.cancel = false;
        //   check.approve = false;
        //   check.reject = false;

        //   check.isBookingApproved = true;

        //   setDisableAll(true);
        // }
        // if (status === "booking_reject") {
        //   check.cancel = false;
        //   check.save = false;
        //   check.approve = false;
        //   check.reject = false;
        //   setDisableAll(true);
        // }

        if (status === "active") {
          setIsLeaseActive(true);
          check.activateEjari = false;
          check.updatepdc = true;
          check.sendSignature = false;
          check.save = false;
          //added
          // if (ejariProcessing === "manual") {
          //   check.uploadCertificate = true;
          // }
        }

        if (status === "booking_cancelled") {
          check.activateEjari = false;
          check.updatepdc = false;
          check.cancel = false;
          check.save = false;
          check.sendSignature = false;
          check.approve = false;
          check.reject = false;
          check.confirm = false;
        }

        if (status === "booking_rejected" && loginUserType === "leaseManager") {
          check.activateEjari = false;
          check.updatepdc = false;
          check.cancel = false;
          check.save = false;
          check.sendSignature = false;
          check.approve = false;
          check.reject = false;
          check.confirm = false;
          check.editBooking = true;
          check.bookingApproval = false;
        }

        //BUG FIX: BUTTONS HIDDEN IN RENEWAL CASE
        if (leaseFormData?.leaseType == "renewal") {
          check.send = false;
          check.resend = false;
        }

        // // added by me reference 7414
        // if (leaseFormData?.leaseType === "renewal") check.updatepdc = true;

        setIsBookingApproved(check.isBookingApproved);

        //TODO:: REVERSE ABOVE CONDITIONS ORDER FOR CODE OPTIMIZATION

        setSaveBtn(check.save);

        setCancelBtn(check.cancel);
        setSendBtn(check.send);
        setReSendBtn(check.resend);

        setApproveBtn(check.approve);
        setRejectBtn(check.reject);
        setConfirmBtn(check.confirm);

        setUpdatePdcBtn(check.updatepdc);
        setUploadEjariCertificateBtn(check.uploadCertificate);

        setCreateEjariBtn(check.createEjari);
        setDownloadEjariBtn(check.downloadEjari);
        setUploadEjariBtn(check.uploadEjari);
        setActivateEjariBtn(check.activateEjari);
        setDownloadCertificateBtn(check.downloadEjariCertificate);

        setSendBookingApprovalBtn(check.sendBookingApproval);
        setEditBookingBtn(check.editBooking);
        setManageBookingApprovalBtn(check.bookingApproval);
        setSendLeaseApprovalBtn(check.sendLeaseApproval);
        setManageLeaseApprovalBtn(check.leaseApproval);

        setSendSignatureBtn(check.sendSignature);
      }
    }
  }, [leaseFormData]);

  useEffect(() => {
    stepsFix(); //call to set step no.
  }, [
    contractSigned,
    contractCreated,
    contractSent,
    updatePdcBtn,
    uploadEjariCertificateBtn,
    approveBtn,
  ]);

  const stepsFix = () => {
    let step = null;
    //5 Sign Contract: contract has been signed by the tenant
    if (contractSigned === true) {
      step = 5;
    }
    // contract signed by tenant
    //4 Contract Created: landlord has marked the agreement as sent to tenant
    else if (contractCreated === true && contractSent === true) {
      step = 4;
    }
    // contract created and sent to tenant
    //3 Payments Received: landlord has received the payments and updates the payment schedule after entering the details
    else if (updatePdcBtn === true || uploadEjariCertificateBtn === true) {
      step = 3;
    }
    //2 KYC & Documents Submitted: tenant has filled out his KYC form and Added Documents
    else if (approveBtn === true) {
      step = 2;
    }
    //1 Booking Created: default
    else if (LEASEID) step = 1;
    // console.log("stepschanged",step);
    step && setCurrentStep(step);
  };

  const ejarifileProps = {
    className: "file-upload",
    name: "file",
    accept: ".pdf",
    multiple: false,
    // showUploadList: false,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} Please upload only CSV file`);
      }
    },
    onRemove: (file) => {
      setSignedEjariFile([]);
    },
    beforeUpload: (file) => {
      setSignedEjariFile([file]);

      return false;
    },
    signedEjariFile,
  };

  const certificatefileProps = {
    className: "file-upload",
    name: "certificatefile",
    accept: ".pdf",
    multiple: false,
    // showUploadList: false,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} Please upload only CSV file`);
      }
    },
    onRemove: (file) => {
      setEjariCertificateFile([]);
    },
    beforeUpload: (file) => {
      setEjariCertificateFile([file]);

      return false;
    },
    ejariCertificateFile,
  };

  //TODO: make common bw both forms

  useEffect(() => {
    let finalDocs = [];
    if (configData?.requiredBookingDocumentsList) {
      const docs = _.map(
        configData?.requiredBookingDocumentsList,
        (value, key) => {
          return { value: key, label: value };
        }
      );
      finalDocs = docs;
      if (leaseFormData?.requiredDocuments?.length > 0) {
        let existDocs = leaseFormData?.requiredDocuments.map((item) => {
          return item?.type;
        });
        finalDocs = docs.filter((item) => !existDocs.includes(item.value));
      } else {
        finalDocs = docs.map((item) => {
          if (
            item.value == "passport" ||
            item.value == "emiratesIdFront" ||
            item.value == "emiratesIdBack"
          )
            item.isDisabled = true;
          else item.isDisabled = false;
          return item;
        });
      }
      setRequiredDocuments(finalDocs);
    }
  }, [
    configData?.requiredBookingDocumentsList,
    leaseFormData?.requiredDocuments,
  ]);

  const disableClone = (type) => {
    let arr = [];
    arr = getValues().additionalDocs;
    let check = arr.filter((item) => item.value == type);
    // console.log("newArr", requiredDocuments)

    if (check.length > 0) {
      // console.log("checkcheck",check)
      let newArr = [];
      newArr = [...requiredDocuments];

      let findType = null;
      findType = newArr.findIndex((item) => item.value == type);
      if (findType != null) {
        requiredDocuments.map((item, index) => {
          let b = [];
          b = arr.filter((y) => item.value === y.value);

          if (b.length > 0) {
            newArr[index].isDisabled = true;
            // console.log("newarr", newArr)
          }
          // else {
          //   newArr[index].isDisabled = false;
          // }
        });
        // console.log("findType", newArr)
        setRequiredDocuments(Object.assign([], newArr));
      }
    }
    // else if()
  };

  
  let signatoryEmailWatcher = useWatch({ control, name: "signatoryEmail" });
  let signatoryNameWatcher = useWatch({ control, name: "signatoryName" });
  let tenantNameWatcher = useWatch({ control, name: "name" });

  

  const isFurnished = useWatch({
    control,
    name: "furnished",
    defaultValue: leaseFormData?.furnished || "YES",
  });


  const onEmailChange = (e) => {
    callDetailsApi(e);
  };
  useEffect(() => {
    if (signatoryNameWatcher && signatoryNameWatcher.length >= 3) {
      translate(signatoryNameWatcher, "signatoryNameArabic");
    }
  }, [signatoryNameWatcher]);

  useEffect(() => {
    if (tenantNameWatcher && tenantNameWatcher.length >= 3) {
      translate(tenantNameWatcher, "arabicName");
    }
  }, [tenantNameWatcher]);

  const callDetailsApi = async (e) => {
    setEmailDetailsLoading(true);
    console.log("e", e.target);

    const resp = await dispatch(getDetailsFromEmail(e.target.value));

    setValue("signatoryName", resp?.tenantNameEN || "");
    setValue("signatoryNameArabic", resp?.tenantNameAR || "");
    setValue("signatoryMobileNumber", resp?.mobileNumber || "");
    setValue("signatoryDesignation", resp?.designationAsPerVisa || "");
    setValue("signatoryCompany", resp?.company || "");
    setTimeout(() => {
      setEmailDetailsLoading(false);
    }, 1000);
  };

  const onPlanChange = (newSelectedRowKeys: React.Key[]) => {
    let filterData = [];
    if (newSelectedRowKeys.length > 0) {
      const leasePlansData = leaseFormData?.paymentPlan?.length > 0 ?
        leaseFormData?.paymentPlan?.map((i, index) => ({ ...i, key: index })) : [];
      filterData = leasePlansData?.filter((val, index) =>
        newSelectedRowKeys.includes(index)
      );
      // console.log('selectedRowKeys changed: ', plansData, newSelectedRowKeys, data);
      setSelectedRowKeys(newSelectedRowKeys);
    } else {
      filterData = [];
    }
    setChangedPaymentPlan(filterData);
  };

  const rowSelection = {
    selectedRowKeys,
    type: "radio",
    onChange: onPlanChange,
  };

  const openPlanChange = () => {
    setPopTrigger(3);
    setVisible(true);
  };

  const onPaymentPlans = async () => {
    setTimeout(async () => {
      const unit = getValues("unitCode"); //"123456-G-101";
      const value = getValues("furnished");
      // console.log(value, unit);
      if (unit) {
        try {
          const url = `${leaseService}/unit/paymentPlan?unitCode=${unit}&furnished=${value}`;
          const res = await axios.get(url);
          let data = [];
          if (res?.data?.result?.length > 0) {
            data = res.data.result.map((item, index) => ({
              ...item,
              key: index,
            }));
          }
          setUnitPlans(data);
        } catch (e) {
          const { response } = e;
          console.log("reponse", response);
          message.error(response?.data?.error);
        }
      }
    }, 200);
  };

  const mappedPlansData = () => {
    const furnished = getValues("furnished");
    let finalData = [];
    const selectPlans = leaseFormData?.paymentPlan;
    payment_plans.map((plan) => {
      const select =
        selectPlans?.length > 0
          ? selectPlans?.find(
              (item) =>
                item?.installments == plan && item?.furnished == furnished
            )
          : null;
      if (select) finalData.push(select);
      else {
        const select2 =
          unitPlans?.length > 0
            ? unitPlans?.find(
                (item) =>
                  item?.installments == plan && item?.furnished == furnished
              )
            : null;
        select2 && finalData.push(select2);
      }
    });
    // console.log("finalData", finalData,selectPlans,data);
    finalData = finalData.map((i, index) => ({ ...i, key: index }));
    return finalData;
  };

  useEffect(() => {
    // console.log("MAPP",mappedPlansData())
    setPlansData(mappedPlansData());
  }, [leaseFormData?.paymentPlan, unitPlans]);

  useEffect(() => {
    onPaymentPlans();
  }, [isFurnished, leaseFormData?.furnished]);

  const onUpdate = () => {
    resetForm();
    setTimeout(() => {
      LEASEID && dispatch(getLeaseFormDetails(LEASEID));
    }, 1000);
  };

  const resetForm = () => {
    reset({
      OtherDocs: [],
      othercharges: [],
      addpayment: [],
      // payment: [],
      // billings: [],
    });
  };

  //Functions for Booking approval to Ejari/Lease below
  const createEjari = async () => {
    setLoading(true);
    try {
      const res = await CreateEjariContract(LEASEID);
      message.success(res?.message);
      setLoading(false);
      onUpdate();
    } catch ({ response }) {
      message.error(response?.data?.error);
      setLoading(false);
    }
  };

  const downloadEjariContract = async () => {
    // setLoading(true);
    const ejari = leaseFormData?.ejari;
    if (ejari && ejari?.ejariDocuments?.length > 0) {
      const document = ejari?.ejariDocuments.find(
        (e) => e.documentName === "unSignedEjariContract"
      );
      const document1 = ejari?.ejariDocuments.find(
        (e) => e.documentName === "LTV_tenantSignedEjariContract"
      ); //tenantSignedEjariContract
      const document2 = ejari?.ejariDocuments.find(
        (e) => e.documentName === "landlordSignedEjariContract"
      );
      const document3 = ejari?.ejariDocuments.find(
        (e) => e.documentName === "ejariCertificateDocument"
      );
      const document4 = ejari?.ejariDocuments.find(
        (e) => e.documentName === "ejariDocumentUploaded"
      );

      if (document?.documentId) {
        const url = await getPresignedImage([document?.documentId]);
        setEjariDownloadUrl(url[0]);
      }
      if (document2?.documentId) {
        const url = await getPresignedImage([document2?.documentId]);
        setSignedEjariDownloadUrl(url[0]);
      } else if (document1?.documentId) {
        const url = await getPresignedImage([document1?.documentId]);
        setSignedEjariDownloadUrl(url[0]);
      }
      if (document3?.documentId) {
        const url = await getPresignedImage([document3?.documentId]);
        setEjariCertificateDownloadUrl(url[0]);
      }
      if (document4?.documentId) {
        const url = await getPresignedImage([document4?.documentId]);
        setEjariCertificateDownloadUrl(url[0]);
      }
    }
    // try {
    //   const res = await DownloadEjariContract(LEASEID);
    //   console.log("res", res);
    //   message.success(res?.message);
    //   setLoading(false);
    //   onUpdate();
    // } catch ({ response }) {
    //   message.error(response?.data?.error);
    //   setLoading(false);
    // }
  };

  useEffect(() => {
    downloadEjariContract();
  }, [leaseFormData?.ejari]);

  useEffect(() => {
    if (signedEjariFile.length > 0 && LEASEID) uploadSignedEjariContract();
  }, [signedEjariFile]);

  const uploadSignedEjariContract = async () => {
    const signedFile = signedEjariFile[0] as RcFile;
    if (signedFile) {
      const payload = { leaseId: LEASEID, file_asset: signedFile };
      try {
        const res = await UploadSignedEjariContract(payload);
        // console.log("resulet::", res)
        message.success("successfully uploaded Ejari");
        setLoading(false);
        onUpdate();
      } catch ({ response }) {
        message.error(response?.data?.error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (ejariCertificateFile.length > 0 && LEASEID) uploadEjariCertificate();
  }, [ejariCertificateFile]);

  const uploadEjariCertificate = async () => {
    const signedFile = ejariCertificateFile[0] as RcFile;
    if (signedFile) {
      const payload = { leaseId: LEASEID, file_asset: signedFile };
      try {
        const res = await UploadEjariCertificate(payload);
        // console.log("resulet::", res)
        message.success("successfully uploaded Ejari Certificate");
        setLoading(false);
        onUpdate();
      } catch ({ response }) {
        message.error(response?.data?.error);
        setLoading(false);
      }
    }
  };

  const activateEjari = async () => {
    setLoading(true);
    try {
      const res = await ActivateEjari(LEASEID);
      message.success("Lease activated");
      setLoading(false);
      onUpdate();
    } catch ({ response }) {
      message.error(response?.data?.error);
      setLoading(false);
    }
  };

  const downloadEjariCertificate = async () => {
    setLoading(true);
    try {
      const res = await PrintEjariCertificateContract(LEASEID);
      message.success(res?.message);
      setLoading(false);
      onUpdate();
    } catch ({ response }) {
      message.error(response?.data?.error);
      setLoading(false);
    }
  };

  //Functions for Booking create to approval below
  const sendToTenant = async () => {
    const email = leaseFormData?.tenantDetails?.email;
    if (email && LEASEID) {
      setLoading(true);
      let payload = {
        emails: [email],
        usertype: "tenant",
        leaseId: LEASEID,
        inviteAll: false,
      };
      let successMessage = "Invite sent to tenant successfully";
      const status = await sendTenantInvite(payload, successMessage);
      setLoading(false);
      if (status === 200) {
        const res = await updateBookingStatus(LEASEID, {
          status: "tenant_invitation_sent",
        });
        if (res === 200) {
          message.success(successMessage);
          onUpdate();
        }
      }
    } else {
      message.error("No email id present");
    }
  };

  const ApproveTenantInfo = async () => {
    setLoading(true);
    try {
      const res = await updateBookingStatus(LEASEID, {
        status: "tenant_information_approved",
      });
      if (res === 200) {
        message.success("successfully approved tenant information");
        setLoading(false);
        onUpdate();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const RejectTenantInfo = () => {
    setRejectionStatus("kyc_rejected");
    setPopTrigger(0);
    setVisible(true);
  };

  const ApproveDocument = async (data) => {
    setLoading(true);
    try {
      const res = await updateBookingStatus(LEASEID, {
        status: "tenant_documents_approved",
        documentType: data.docName ?? "",
        comments: data.docName,
      });
      if (res === 200) {
        message.success("successfully approved document");
        setLoading(false);
        onUpdate();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const RejectDocument = (data) => {
    setRejectionStatus("tenant_documents_partial_reject");
    data.docName && setRejectionDocument(data.docName);
    setPopTrigger(0);
    setVisible(true);
  };

  const onRejection = () => {
    setPopTrigger(0);
    setVisible(false);
    setRejectionStatus("");
    setRejectionDocument("");
    onUpdate();
  };

  const onUpdateLeaseManager = async () => {
    const val = getValues();
    setLoading(true);
    try {
      const res = await updateBookingStatus(LEASEID, {
        leaseManager: val?.leaseManager?.value,
      });
      if (res === 200) {
        message.success("Successfully updated");
        setLoading(false);
        onUpdate();
      }
    } catch (error) {
      console.log("Err", error);
      setLoading(false);
    }
  };

  const sendForApproval = async () => {
    setLoading(true);
    const payload = {
      sendForApproval: "yes",
    };
    try {
      const url = `${leaseService}/booking/${LEASEID}`;
      await axios.patch(url, payload);
      setLoading(false);
      message.success("Booking has been sent for approval");
      onUpdate();
    } catch (e) {
      setLoading(false);
      const { response } = e;
      console.log("reponse", response);
      message.error(response?.data?.error);
    }
  };

  const editBooking = async () => {
    setLoading(true);
    const payload = {
      editBooking: "yes",
    };
    try {
      const url = `${leaseService}/booking/${LEASEID}`;
      await axios.patch(url, payload);
      setLoading(false);
      message.success("Booking can now be edited");
      onUpdate();
    } catch (e) {
      setLoading(false);
      const { response } = e;
      message.error(response?.data?.error);
    }
  };

  const AcceptApproval = async () => {
    PopupConfirm({
      title: `Are you sure you want to approve booking?`,
      onOk: async () => {
        setLoading(true);
        const payload = {
          sendForApproval: "approved",
        };
        try {
          const url = `${leaseService}/booking/${LEASEID}`;
          await axios.patch(url, payload);
          setLoading(false);
          message.success("Booking has been approved");
          onUpdate();
        } catch (e) {
          setLoading(false);
          const { response } = e;
          console.log("reponse", response);
          message.error(response?.data?.error);
        }
      },
      okText: "Yes",
    });
  };

  const RejectApproval = async (data) => {
    setLoading(true);
    const payload = {
      sendForApproval: "rejected",
      rejectionReason: data?.rejectReason ?? "",
    };
    try {
      const url = `${leaseService}/booking/${LEASEID}`;
      await axios.patch(url, payload);
      setLoading(false);
      message.success("Booking has been rejected");
      setVisible(false);
      onUpdate();
    } catch (e) {
      setLoading(false);
      const { response } = e;
      console.log("reponse", response);
      message.error(response?.data?.error);
    }
  };

  const sendLeaseForApproval = async () => {
    setLoading(true);
    const payload = {
      sendLeaseForApproval: "yes",
    };
    try {
      const url = `${leaseService}/booking/${LEASEID}`;
      await axios.patch(url, payload);
      setLoading(false);
      message.success("Lease has been sent for approval");
      onUpdate();
    } catch (e) {
      setLoading(false);
      const { response } = e;
      console.log("reponse", response);
      message.error(response?.data?.error);
    }
  };

  const AcceptLeaseApproval = async () => {
    PopupConfirm({
      title: `Are you sure you want to approve lease?`,
      onOk: async () => {
        setLoading(true);
        const payload = {
          sendLeaseForApproval: "approved",
        };
        try {
          const url = `${leaseService}/booking/${LEASEID}`;
          await axios.patch(url, payload);
          setLoading(false);
          message.success("Lease has been approved");
          onUpdate();
        } catch (e) {
          setLoading(false);
          const { response } = e;
          console.log("reponse", response);
          message.error(response?.data?.error);
        }
      },
      okText: "Yes",
    });
  };

  const RejectLeaseApproval = async (data) => {
    setLoading(true);
    const payload = {
      sendLeaseForApproval: "rejected",
      rejectionReason: data?.rejectReason ?? "",
    };
    try {
      const url = `${leaseService}/booking/${LEASEID}`;
      await axios.patch(url, payload);
      setLoading(false);
      message.success("Lease has been rejected");
      setVisible(false);
      onUpdate();
    } catch (e) {
      setLoading(false);
      const { response } = e;
      console.log("reponse", response);
      message.error(response?.data?.error);
    }
  };

  const openRejectPopup = (index) => {
    setPopTrigger(index);
    setVisible(true);
  };

  // const acceptForm = async () => {
  //   setLoading(true);
  //   const payLoad = {
  //     leaseId: LEASEID,
  //   };
  //   const url = `${leaseService}/approve-lease`;
  //   try {
  //     await axios.post(url, payLoad);
  //     message.success(`Lease Form Approved successfully`);
  //     setLoading(false);
  //     setTimeout(() => {
  //       navigate("/lease");
  //     }, 1000);
  //   } catch (e) {
  //     setLoading(false);
  //     const { response } = e;
  //     message.error(response?.data?.result);
  //   }
  // };

  const approveForm = async () => {
    setLoading(true);
    try {
      const res = await updateBookingStatus(LEASEID, {
        status: "booking_approved",
      });
      if (res === 200) {
        message.success("Booking approved");
        setLoading(false);
        onUpdate();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const updatePaymentSchedule = async (val, updateDataOnly?) => {
    const isValid = await trigger("", { shouldFocus: true }); // triggers form validation for all fields
    let additionalPayment = [];
    // console.log("validate", isValid);
    if (isValid) {
      setLoading(true);

      const values = getValues();
      // console.log("eve", val, values);

      //to add additional payments at any stage
      if (values?.addpayment?.length > 0) {
        additionalPayment = values?.addpayment.map((item) => {
          return {
            new: "yes",
            description: item?.type?.value,
            actualAmount: +item?.amount,
            vat: +item?.vat,
            totalAmount: item?.total,
            modeOfPayment: item?.mode?.value,
            documentNo: item?.docNo,
            bankName: item?.bankName,
            entity: values?.legalEntity,
            dueDate: dayjs(item?.dueDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
            chequeDate: dayjs(item?.chequeDate).isValid()
              ? dayjs(item?.chequeDate).format("YYYY-MM-DD")
              : "",
            chequeStatus: "",
            creditCardRequest: "",
            paymentStatus: "",
            installments: 1 //FIX ADDED for new pdc data 
          };
        });
      }

      const payments = values?.payment?.length > 0 ? values?.payment : [];

      const paymentSchedule = payments.map((item) => {
        let payObj = {
          description: item?.type,
          actualAmount: item?.amount,
          vat: item?.vat,
          totalAmount: item?.total,
          modeOfPayment: item?.mode?.value,
          documentNo: item?.docNo,
          bankName: item?.bankName,
          entity: values?.legalEntity,
          dueDate: dayjs(item?.dueDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
          chequeDate: dayjs(item?.chequeDate).format("YYYY-MM-DD"),
          chequeStatus: item?.chequeStatus,
          creditCardRequest: item?.creditCardRequest,
          paymentStatus: item?.paymentStatus,
        };
        //ADDED TO FIX PDC DATA ISSUES WD BE/FE
        if (item?.pdcId)
          payObj["pdcId"] = item?.pdcId;
        if (item?.installments)
          payObj["installments"] = item?.installments;
        return payObj;
      });
      // console.log("payments", [...paymentSchedule, ...additionalPayment]);

      if (paymentSchedule?.length > 0 || additionalPayment?.length > 0) { // ADDED FOR FIX BUG 7457
        let payload = {
          paymentSchedule: [...paymentSchedule, ...additionalPayment],
          leaseId: LEASEID,
        };

        if (updateDataOnly == true)
          //ADDED FIX WORKAROUND FOR #7437
          payload["updateDataOnly"] = true;

        // REMOVE STATUS IN REQ SP-13
        // if (isLeaseActive !== true && isBookingApproved !== true)
        //   payload["status"] = "landlord_pdc_updated";

        const url = `${leaseService}/booking/pdc`;
        await axios
          .post(url, payload)
          .then((response) => {
            // console.log("RPEPN", response);
            let { data } = response;
            setLoading(false);
            message.success("Payment schedule updated");

            setTimeout(() => {
              onUpdate();
            }, 800);
          })
          .catch((error) => {
            // console.log("RPEPN", error);
            message.error(error.response.data.error);
            setLoading(false);
          });
      } else {
        message.error("Payment details not filled");
        setLoading(false);
      }
    }

 
  };

  const generateTemplate = async (document) => {
    const url = `${leaseService}/leasing/createContract/${LEASEID}?type=${document}`; ///${LEASEID}
    await axios
      .get(url, {})
      .then((response) => {
        console.log("RPEPN", response);
        const { data } = response;
        // let docs = data?.result?.documents;
        // getDocumentsList(docs);
        onUpdate();
        message.destroy();
        message.success("Document generated");
      })
      .catch((error) => {
        // console.log("RPEPN", error);
        message.error("Document generation failed");
        // message.error(error.response.data.error)
      });
  };

  const onChangeTemplate = async (e) => {
    const isValid = await trigger("", { shouldFocus: true }); // triggers form validation for all fields
    if (isValid === true) {
      // setLoading(true);
      // const formValues = getValues();

      try {
        //REMOVED SP-13
        // if (saveBtn)
        //   await onSubmit(formValues);
        // if (updatePdcBtn)
        //   await updatePaymentSchedule(formValues);
        message.loading("Generating document", 10000);
        await generateTemplate(getDocumentKey(e?.value));
        setShowPdf(true);
        // setLoading(false);
      } catch {
        throw Error("Failed");
      }
    }

    // console.log("STARTED");

    // console.log("ENDED");

    // console.log("fa", e?.value);

    // let contract = null;
    // const docs = leaseFormData?.ejari?.ejariDocuments;
    // if (docs?.length > 0)
    //   contract = docs.find(item => item.documentName === "unSignedEjariContract")

    // if (contract) {
    //   PopupConfirm({
    //     title: `Do you want to re-generate contract?`,
    //     cancelText: "No",
    //     okText: "Yes",
    //     onOk: async () => {
    //       await generateTemplate();
    //       setShowPdf(true);
    //       return;
    //     },
    //     onCancel: () => {
    //       setShowPdf(true);
    //       return;
    //     }
    //   });
    // } else {

    // }
  };

  const sendForEsignature = async (document) => {
    PopupConfirm({
      title: `This action will send the document to the tenant for signature. Are you sure?`,
      cancelText: "No",
      okText: "Yes",
      onOk: async () => {
        await addToLeaseAPI(document)
          .then(async () => {
            let load = message.loading("Sending");
            const url = `${leaseService}/leasing/sendContractForSign/${LEASEID}?type=${document}`; ///${LEASEID}
            await axios
              .get(url, {})
              .then((response) => {
                message.destroy();
                message.success("Successfully sent to tenant");
                setShowPdf(false);
              })
              .catch((error) => {
                message.error(error.response.data.error);
              });
            onUpdate();
          })
          .catch((error) => {
            message.error(error.response.data.error);
          });

        return;
      },
      // onCancel: () => {
      //   return;
      // }
    });
  };

  const saveToLease = async (document) => {
    setLoading(true);
    try {
      await addToLeaseAPI(document);
      setLoading(false);
      message.success("Saved successfully");
      onUpdate();
    } catch (error) {
      message.error(error.response.data.error);
      setLoading(false);
    }
  };

  const addToLeaseAPI = async (document?) => {
    const payload = {
      addToLease: "true",
      documentKey: document,
    };
    const url = `${leaseService}/leasing/${LEASEID}`;
    try {
      await axios.patch(url, payload);
    } catch (error) {
      throw error;
    }
  };

  const onTemplatePDF = async (doc) => {
    const result = await getPresignedImage([doc]); //change
    if (result && result.length > 0) {
      setTemplatePDF(result[0]);
    } else {
      message.error("Something wrong with Media");
    }
    return result;
  };

  const getDocumentsList = (docs = []) => {
    if (docs?.length > 0) {
      //declarations - documents
      const documents = [];
      let contract = null;
      // let certifcate = null;

      //-----------------------------------------------------------------------
      //additional documents
      let additional = docs.find(
        (item) => item.documentName === "additionalDocument"
      );

      //contract documents
      // let draft = docs.find(item => item.documentName === "draft");
      let unsigned = docs.find(
        (item) => item.documentName === "unSignedEjariContract"
      );
      let tensigned = docs.find(
        (item) => item.documentName === "LTV_tenantSignedEjariContract"
      );
      let lantansigned = docs.find(
        (item) => item.documentName === "LTV_landlordSignedEjariContract"
      );

      // //certificate documents
      // let ejariCert = docs.find(item => item.documentName === "ejariCertificateDocument");
      // let ejariCertUploaded = docs.find(item => item.documentName === "ejariDocumentUploaded");

      //-----------------------------------------------------------------------

      //contract checks
      if (lantansigned && tensigned)
        contract = { ...lantansigned, signBy: "both" };
      else if (lantansigned) contract = { ...lantansigned, signBy: "landlord" };
      else if (tensigned) contract = { ...tensigned, signBy: "tenant" };
      else if (unsigned) contract = { ...unsigned, signBy: "unsigned" };
      // else if (draft)
      //   contract = draft;

      if (additional && additional?.addToLease == true) {
        setTemplateDocs(
          templateOptions.filter((item) => item.value != "additionalDocument")
        );
        if (additional.isTenantSigned == true)
          additional = { ...additional, signBy: "tenant" };
        else additional = { ...additional, signBy: "unsigned" };
      } else {
        setTemplateDocs(templateOptions);
      }
      //certificate checks
      // if (ejariCert)
      //   certifcate = ejariCert;
      // else if (ejariCertUploaded)
      //   certifcate = ejariCertUploaded;

      //------------------------------------------------------------------------

      contract &&
        documents.push({ ...{ label: "Tenancy Contract" }, ...contract });
      // certifcate && documents.push({ ...{ label: "Ejari Certificate" }, ...certifcate });

      additional &&
        documents.push({
          ...{ label: "Additional Document", remove: true },
          ...additional,
        });

      //------------------------------------------------------------------------
      setGeneratedDocuments(documents);
      // console.log("documentsLerra", documents);
      //TODO
      //move this code
      if (additional?.documentId) {
        onTemplatePDF(additional?.documentId);
        setTemplateData(additional);
      }
    }
  };

  useEffect(() => {
    const ejariDocs = leaseFormData?.ejari?.ejariDocuments ?? [];
    const addDocs = leaseFormData?.documents ?? [];
    getDocumentsList([...ejariDocs, ...addDocs]);
  }, [leaseFormData?.ejari?.ejariDocuments, leaseFormData?.documents]);

  const onSubmit = async (val) => {

    clearErrors()

    if(val.numberOfOcupants && !(/^\d+$/.test(val.numberOfOcupants))) {
      setError('numberOfOcupants', {type: 'manual', message: 'Please enter valid number of ocupants' })
      return
    }

    setLoading(true);
    const billingChargesArray = [];
    const defaultDocs = [
      { type: "passport", status: "pending" },
      { type: "emiratesIdFront", status: "pending" },
      { type: "emiratesIdBack", status: "pending" },
    ];
    const existingDocuments =
      leaseFormData?.requiredDocuments?.length > 0
        ? leaseFormData?.requiredDocuments
        : defaultDocs;
    const documentsArray = [...existingDocuments];
    const billingSchedule = [];

    if (val?.othercharges?.length > 0) {
      val?.othercharges?.map((item, index) => {
        if (
          item?.type?.label !== "EJARI FEE" &&
          item?.type?.label !== "SECURITY DEPOSIT"
        ) {
          const amount: any = item?.amount;
          billingChargesArray.push({           //modified keys based on new UI: ref #7453
            billingChargesType: item?.type?.value,
            total: parseFloat(amount),
            modeOfPayment: item?.mode?.value, //added in sp12
            numberOfInstallments: item?.intallments?.value,
            //ADDED BELOW KEYS TO FIX PDC GENERATION ISSUE: 7465, 7463 + added defaults to fix case for newly added lines
            actualAmount: item?.actualAmount ?? parseFloat(amount),
            amountType: item?.amountType ?? "amount",
            dueDate: item?.dueDate ?? dayjs().format("YYYY-MM-DD"),
            vat: item?.vat ?? 0
          });
        }
      });
    }

    if (val?.additionalDocs?.length > 0) {
      val.additionalDocs.map((item) => {
        if (item?.value) {
          let doc = { type: item?.value, status: "pending" };
          if (item?.documentLabel) doc["documentLabel"] = item?.documentLabel;
          documentsArray.push(doc);
        }
      });
    }

    // "installmentEndDate": "Invalid Date",
    // "actualAmount": "NaN",

    if (val?.billings?.length > 0) {
      val?.billings.flatMap((bill) =>
        bill.instaArray.map((inst) => {
          if (
            inst.total != "NaN" &&
            inst.installmentStartDate != "Invalid Date"
          ) {
            let vat = +inst?.vat;
            billingSchedule.push({
              billingChargesType: bill.billtype,
              yearNumber: 1,
              installments: inst.installmentno,
              installmentStartDate:
                dateByformat(
                  dayjs(inst.start_date, "DD-MM-YYYY"),
                  "YYYY-MM-DD"
                ) ?? inst.start_date,
              installmentEndDate:
                dateByformat(
                  dayjs(inst.end_date, "DD-MM-YYYY"),
                  "YYYY-MM-DD"
                ) ?? inst.end_date,
              actualAmount: inst.amount,
              vat: vat != null ? vat : "0",
              total: inst.total,
            });
          }
        })
      );
    }

    let postJson: any = {
      name: val.name,
      arabicName: val.arabicName,
      nationality: val.nationality.value,
      maritalStatus: val.maritalStatus.value,
      numberOfOcupants: val.numberOfOcupants,
      poBox: val.poBox, //additional poBox
      unitCode: val.unitCode,
      furnished: val?.furnished,
      leaseManager: val?.leaseManager?.value,
      mobile: val.mobile,
      email: val.email,
      externalAgent: val.externalAgent,
      bookingTerms: {
        // "annualBaseRent": val.annualBaseRent,
        // "numberOfInstallments": val.noOfInstallments.value,
        // "nonRefundableAmount": val.nonRefundableAmount,
        bookingStartDate: dateByformat(val.bookingStartDate),
        bookingEndDate: dateByformat(val.bookingEndDate),
        legalEntity: val.legalEntity,
        moveInDate: dateByformat(val.moveInDate),
        billingCharges: billingChargesArray,
        // "poBox": val.poBox,
      },
      isSignatory:
        (val?.showAuthorizedSignatory).toUpperCase() === "YES" ? "yes" : "no",
      signatoryUser: {
        name: val?.signatoryName,
        email: val?.signatoryEmail,
        arabicName: val?.signatoryNameArabic,
        mobile: val?.signatoryMobileNumber,
        designation: val?.signatoryDesignation,
        company: val?.signatoryCompany,
      },
      leaseTerms: {
        ejariProcessing: val.ejariProcessing.value,
        leaseNature: val.leaseNature.value,
        leaseType: "New",
        moveInDate: dateByformat(val.moveInDate),
        leaseStartDate: dateByformat(val.leaseStartDate),
        leaseEndDate: dateByformat(val.leaseEndDate),
        rentFreeStartDate: dateByformat(val.rentFreeStartDate),
        rentFreeEndDate: dateByformat(val.rentFreeEndDate),
        // "noOfCheques": val.noOfInstallments.value,
        // "contractAmount": val.annualBaseRent,
        // "securityDeposit": val.nonRefundableAmount,
        // "noOfPaidParkingSlots": val.noOfPaidParkingSlots,
        // "noOfFreeParkingSlots": val.noOfFreeParkingSlots
      },
      requiredDocuments: documentsArray,
      billingSchedule: billingSchedule,
      paymentPlan: val?.selectedPaymentPlans,
    };

    if (leaseFormData.leaseType === "renewal") {
      postJson["bookingTerms"] = {
        ...postJson["bookingTerms"],
        ...{
          annualBaseRent: val.rentAmount,
          renewalAmount: val.annualBaseRent,
          changeValue: val.changeValue,
          amountType: val.amountType,
          amountChange: val.amountChange,
          numberOfInstallments: val.noOfInstallments.value,
        },
      };
    }

    if (val?.designationAsPerVisa)
      postJson["designationAsPerVisa"] = val.designationAsPerVisa.value;
    if (val?.employmentSector)
      postJson["employmentSector"] = val.employmentSector.value;
    if (val?.income) postJson["income"] = val.income.value;

    if (LEASEID) {
      const url = `${leaseService}/booking/details/${LEASEID}`;
      await axios
        .patch(url, postJson)
        .then((response) => {
          console.log("RPEPN", response);
          let { data } = response;
          setLoading(false);
          message.success("Updated successfully");
          onUpdate();
        })
        .catch((error) => {
          message.error(error.response.data.error);
          setLoading(false);
        });
      console.log("postjsonUPDATE", postJson);
    } else {
      const url = `${leaseService}/booking`;
      // "email": val.email,
      postJson["email"] = val.email;
      postJson["mobile"] = val.mobile;
      await axios
        .post(url, postJson)
        .then((response) => {
          console.log("RPEPN", response);
          let { data } = response;
          setLoading(false);
          message.success(data?.message);
          setTimeout(() => navigate("/bookings"), 1000);
        })
        .catch((error) => {
          message.error(error.response.data.error);
          setLoading(false);
        });
      console.log("postjsonCREATE", postJson);
    }
  };
  const onCancelBook = () => {
    PopupConfirm({
      title: `Are you sure you want to cancel`,
      onOk: () => onCancleBookingForm(LEASEID),
      okText: "Yes",
    });
  };

  const onCancleBookingForm = async (leadID) => {
    // setLoading(true);
    const res = await updateBookingStatus(leadID, { status: "booking_reject" });
    if (res === 200) {
      message.success("Booking has been cancelled");
      onUpdate();
    }
    // cancelLeaseForm(leadID).then((response) => {
    //   if (response?.data?.message === "Success") {
    //     message.success(response?.data?.result);
    //     setTimeout(() => navigate("/lease"), 1000);
    //     setLoading(false);
    //   }
    // }).catch((error) => {
    //   message.error("Previous Lead Status not found !")
    //   setLoading(false);
    // });
  };

  const changePaymentPlan = async () => {
    setLoading(true);
    console.log("changedPaymentPlan", changedPaymentPlan);
    const planId = changedPaymentPlan[0]?.paymentPlanId;
    if (planId) {
      let payload = {
        paymentPlanId: planId,
      };
      try {
        const url = `${leaseService}/booking/${LEASEID}`;
        const res = await axios.patch(url, payload);
        // console.log("LOG:res", res);
        message.success("Payment plan updated");
        setLoading(false);
        setVisible(false);
        onUpdate();
      } catch (error) {
        setLoading(false);
        message.error(error.message);
      }
    } else {
      setLoading(false);
      message.error("Plan is not selected");
    }
  };

  const onConfirmPlanChange = () => {
    PopupConfirm({
      title: `This will update the payment schedule with the selected payment plan. Are you sure?`,
      onOk: () => changePaymentPlan(),
      okText: "Confirm",
    });
  };

  const popup = [
    {
      title: (
        <Title level={3} className="mb-0">
          Reject Reason
        </Title>
      ),
      width: 650,
      content: (
        <Rejectform
          leaseId={LEASEID}
          rejectionStatus={rejectionStatus}
          rejectionDocument={rejectionDocument}
          onSubmit={onRejection}
          onClose={() => setVisible(false)}
        />
      ),
    },
    {
      title: (
        <Title level={3} className="mb-0">
          Booking Reject Reason
        </Title>
      ),
      width: 650,
      content: (
        <RejectPopup
          leaseId={LEASEID}
          onSubmit={RejectApproval}
          onClose={() => setVisible(false)}
        />
      ),
    },
    {
      title: (
        <Title level={3} className="mb-0">
          Lease Reject Reason
        </Title>
      ),
      width: 650,
      content: (
        <RejectPopup
          leaseId={LEASEID}
          onSubmit={RejectLeaseApproval}
          onClose={() => setVisible(false)}
        />
      ),
    },
    {
      title: (
        <Title level={3} className="mb-0">
          Change Payment Plan
        </Title>
      ),
      width: 850,
      content: (
        <PaymentPlans
          rowSelection={rowSelection}
          setSelectedRowKeys={setSelectedRowKeys}
          plansData={leaseFormData?.paymentPlan?.map((i, index) => ({ ...i, key: index }))} //FIX BUG selected plan
          selectedPlan={[leaseFormData?.tenantDetails?.paymentPlanId]}
          confirmChange={onConfirmPlanChange}
        />
      ),
    },
  ];

  const popupContent = {
    title: popup[popTrigger].title,
    closable: true,
    visibility: visible,
    content: popup[popTrigger].content,
    width: popup[popTrigger].width ?? 650,
    onCancel: () => setVisible(false),
  };

  return (
    <DashboardLayout load={loading}>
      {templatePDF && (
        <PdfTemplateView
          pdf={templatePDF}
          data={templateData}
          width={"80%"}
          sendForEsignature={sendForEsignature}
          saveToLease={saveToLease}
          onCancel={() => setShowPdf(false)}
          visible={showPdf}
        />
      )}
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Title level={2} className="mb-0 ag-fontSize32">
                {/* Leasing Form - Residential Leasing */}
                Booking Form
              </Title>
            </Col>
            <Col span={21} style={{ margin: "20px auto" }}>
              <Steps
                current={currentStep || 0}
                initial={0}
                labelPlacement="vertical"
                progressDot={(dot, { status, index }) => {
                  return status == "wait" || status == "process" ? (
                    <PendingIcon />
                  ) : (
                    <CheckOutlined />
                  );
                }}
                size="small"
                items={[
                  {
                    title: "Booking Created",
                    description: "Create booking and invite tenant",
                  },
                  {
                    title: "KYC & Documents Submitted",
                    description: "Review documents and KYC",
                  },
                  {
                    title: "Payments Received",
                    description: "Enter and confirm Payment Schedule details",
                  },
                  {
                    title: "Contract Created",
                    description: "Create contract and send for signature",
                  },
                  {
                    title: "Sign Contract",
                    description: "Review and counter-sign contract",
                  },
                ]}
              />
            </Col>
            <Col span={19}>
              <Radio.Group
                defaultValue="SER"
                buttonStyle="solid"
                value={unitDetail?.usagesType}
              // onChange={(e) => setType(e.target.value)}
              >
                <Space size={12}>
                  {/* <Radio.Button value="NF">Notifications</Radio.Button> */}
                  <Radio.Button value="Residential">Residential</Radio.Button>
                  <Radio.Button value="Commercial">Commercial</Radio.Button>
                </Space>
              </Radio.Group>
            </Col>
            <Col span={5}>
              {isCSVUploaded != true && (
                <ReactSelectField
                  // isRequired={true}
                  fieldname="services"
                  // label="Templates"
                  iProps={{ placeholder: "Documents" }}
                  control={control}
                  onChange={(e) => onChangeTemplate(e)}
                  initValue=""
                  selectOption={templateDocs}
                />
              )}
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Form
            layout="vertical"
            name="bookingform"
            onFinish={handleSubmit(onSubmit)}
            scrollToFirstError
          >
            <Collapse
              defaultActiveKey={["1", "2", "3", "4"]}
              expandIconPosition="right"
              ghost
              className="ag-collapse ag-border-card"
            >
              <Panel header="1. Tenant Details" key="1" forceRender={true}>
                <TenantDetails
                  getValues={getValues}
                  signatoryEmailWatcher={signatoryEmailWatcher}
                  emailDetailsLoading={emailDetailsLoading}
                  disableAll={disableAll || !saveBtn}
                  setValue={setValue}
                  control={control}
                  errors={errors}
                  register={register}
                  resetField={resetField}
                  nationalityList={configData?.nationalityList}
                  detailsApi={leaseFormData}
                  onEmailChange={onEmailChange}
                />
              </Panel>

              <Panel header="2. Unit Details" key="2" forceRender={true}>
                <Row gutter={24}>
                  <UnitDetails
                    disableAll={disableAll || !saveBtn}
                    setValue={setValue}
                    control={control}
                    errors={errors}
                    unitDetails={unitDetail}
                    parkingSlots={parkingSlotsDataFromApi}
                  />
                </Row>
              </Panel>

              {/* {disableAll != true && ( */}
              {leaseFormData?.leaseType != "renewal" && (
                <Panel
                  header="3. Available Payment Plans"
                  key="3"
                  forceRender={true}
                >
                  <Row gutter={24}>
                    <BookingTerms
                      disableAll={disableAll || !saveBtn}
                      setValue={setValue}
                      register={register}
                      resetField={resetField}
                      control={control}
                      errors={errors}
                      getValues={getValues}
                      reset={reset}
                      leaseId={LEASEID}
                      plansData={plansData}
                      onPaymentPlans={onPaymentPlans}
                      isFurnished={leaseFormData?.furnished}
                      // setSelectedPaymentPlans={setSelectedPaymentPlans}
                      propertyId={unitDetail?.propertyId}
                      bookingPeriod={bookingPeriod}
                      onUpdateLeaseManager={onUpdateLeaseManager}
                      billingsApi={leaseFormData?.billingCharges}
                      // paid={leaseFormData?.leaseTerms}
                      legalEntity={legalEntity}
                      data={leaseFormData}
                      parking={parkingSlotsDataFromApi}
                      isLeaseActive={isLeaseActive}
                      saveBtn={saveBtn}
                    // leasestatus={leaseFormData?.status}
                    />
                  </Row>
                </Panel>
              )}

              {leaseFormData?.leaseType === "renewal" && (
                <Panel header="3. Renewal Terms" key="3" forceRender={true}>
                  <Row gutter={24}>
                    <RenewalTerms
                      reset={reset}
                      saveBtn={true}
                      errors={errors}
                      isDisable={true}
                      control={control}
                      leaseId={LEASEID}
                      setValue={setValue}
                      data={leaseFormData}
                      getValues={getValues}
                      disableAll={disableAll}
                      legalEntity={legalEntity}
                      parking={parkingSlotsDataFromApi}
                      billingsApi={leaseFormData?.billingCharges}
                    />
                  </Row>
                </Panel>
              )}

              {/* )} */}
              <Panel
                header="4. Lease Terms"
                forceRender={true}
                key="4"
              // extra={clearData()}
              >
                <LeaseTerms
                  disableAll={disableAll || !saveBtn}
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  errors={errors}
                  isRenewal={false}
                  register={register}
                  propertyId={unitDetail?.propertyId}
                  isLeaseActive={isLeaseActive}
                  data={leaseFormData?.leaseTerms}
                  leaseType={leaseFormData?.leaseType}
                  priorLeaseExpiry={priorLeaseEnd}
                  parking={parkingSlotsDataFromApi}
                  isClearTriggered={isClearTriggered}
                  details={leaseFormData?.bookingTerms}
                />
              </Panel>
            </Collapse>
            <Row gutter={24}>
              <Col span={24}>
                <Row gutter={[24, 24]} justify="space-between">
                  <Col span={24}>
                    <Title level={3} style={{ color: "#000" }} className="mb-0">
                      5. Documents:
                    </Title>
                  </Col>
                  <DocumentsList
                    LEASEID={LEASEID}
                    control={control}
                    setValue={setValue}
                    resetField={resetField}
                    leaseData={leaseFormData}
                    popup={popup}
                    tenantAccepted={tenantAccepted}
                    isAdd={saveBtn}
                    isRenewal={false}
                    disableClone={disableClone}
                    requiredBookingDocumentsList={requiredDocuments}
                    RejectDocument={RejectDocument}
                    ApproveDocument={ApproveDocument}
                    setFinalRequiredDocuments={setFinalRequiredDocuments}
                    setSendEmptyDoc={setSendEmptyDoc}
                    generatedDocs={generatedDocuments}
                    onUpdate={onUpdate}
                    sendForEsignature={sendForEsignature}
                    contractSent={contractSent}
                    sendSignatureBtn={sendSignatureBtn}
                  />
                </Row>
              </Col>
            </Row>
            <Row gutter={[24, 24]} style={{ marginTop: "90px" }}>
              <Col span={24}>
                <Collapse
                  expandIconPosition="right"
                  ghost
                  className="ag-collapse ag-border-card"
                  defaultActiveKey={["6", "7"]}
                >
                  {/* <Panel
                    header="6. Billing Schedule"
                    key="6"
                    forceRender={true}
                  >
                    <BillingSchedule
                      control={control}
                      errors={errors}
                      getValues={getValues}
                      setValue={setValue}
                      register={register}
                    />
                  </Panel> */}
                  <BillingSchedule
                    control={control}
                    errors={errors}
                    getValues={getValues}
                    setValue={setValue}
                    register={register}
                  />
                  <Panel
                    header="6. Payment Schedule"
                    className="payment-schedule"
                    key="7"
                    forceRender={true}
                    extra={
                      leaseFormData?.leaseType === "new" &&
                      leaseFormData?.tenantDetails?.paymentPlanId && (
                        <Button
                          type="link"
                          className="btn-url-white changeplan-btn"
                          onClick={() => openPlanChange()}
                          disabled={leaseFormData?.paymentSchedule    //ADDED FOR CHECKS CONDITIONS
                            ?.filter(item => item?.chequeStatus == "paid")?.length > 0
                            ? true : false}
                        >
                          <SettingOutlined />
                          Change Plan
                        </Button>
                      )
                    }
                  >
                    <PaymentSchedule
                      // !A!B + !AB
                      disableAll={!updatePdcBtn}
                      updatePdcBtn={updatePdcBtn}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      data={leaseFormData?.bookingTerms}
                      depositsPaid={
                        leaseFormData?.leaseStatusHistory
                        ?.find(d=>d?.status=="deposit_paid") ? true : false
                      }
                      bookingformPrePaymentPayload={leaseFormData?.bookingformPrePaymentPayload ?? {}}
                      isCSVUploaded={isCSVUploaded || isLeaseActive || disableAll || !saveBtn}
                      getValues={getValues}
                      register={register}
                      resetField={resetField}
                      paymentSchedule={leaseFormData?.paymentSchedule}
                      ejariCharges={leaseFormData?.securityAndEjariFeePayment}
                      leaseType={leaseFormData?.leaseType} //ADDED FIX FOR #7437 to display add payment button
                    // statusForm={leaseFormData?.status}
                    />
                  </Panel>
                </Collapse>
              </Col>
            </Row>

            {/* <Row gutter={[24, 24]} justify="space-between">
              <Col span={24}>
                <Title level={3} style={{color:"#000"}}>8. Additional Documents:</Title>
              </Col>
              <Col span={24}>
                <AdditionalDocument
                  disableAll={disableAll}
                  setValue={setValue}
                  control={control}
                  errors={errors}
                  getValues={getValues}
                  leaseData={leaseFormData}
                  deletedAdditionalDocs={deletedAdditionalDocs}
                  setDeletedAdditionalDocs={setDeletedAdditionalDocs}
                />
              </Col>
            </Row> */}

            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Collapse
                  defaultActiveKey={["9"]}
                  expandIconPosition="right"
                  ghost
                  className="ag-collapse ag-border-card"
                >
                  <Panel
                    header="7. Approval History"
                    key="9"
                    className="approval-history"
                    forceRender={true}
                  >
                    <ApprovalHistory
                      control={control}
                      errors={errors}
                      approvalData={leaseFormData?.leaseStatusHistory}
                    />
                  </Panel>
                </Collapse>
              </Col>
            </Row>

            <Row gutter={[24, 24]}>
              <Col span={24}>
                <div className="steps-bookingForm">
                  <Button onClick={() => goBack()}>Back</Button>
                  <div className="buttonSteps">
                    {/* {loginMode === "Lease_Manager" ? (
                      <>
                        <Button
                          type="primary"
                          danger
                          disabled={disableApproveReject}
                          onClick={() => setVisible(true)}
                        >
                          Send back to LE
                        </Button>
                        <Button
                          type="primary"
                          className="btn-blue"
                          onClick={() => acceptForm()}
                          disabled={disableApproveReject}
                        >
                          Approve
                        </Button>
                        <Button
                          type="primary"
                          className="btn-blue"
                          // danger
                          disabled={disableCancelButton}
                          onClick={onCancelBook}
                        >
                          Cancel Lease form
                        </Button>
                      </>
                    ) : ( */}
                    {/* // (loginMode === "Lease_Executive" ||
                      //   loginMode === "propertyExecutive") */}
                    {disableAll != true && (
                      <>
                        {cancelBtn && LEASEID && (
                          <Button
                            type="primary"
                            htmlType="button"
                            className="ag-red-button"
                            disabled={disableAll || disabletenant}
                            //  || checkButton?.length > 0}
                            onClick={() => {
                              onCancelBook();
                            }}
                          >
                            Cancel Booking
                          </Button>
                        )}
                        {saveBtn && (
                          <Button
                            type="primary"
                            className="btn-green"
                            htmlType="submit"
                            disabled={disableAll}
                          >
                            {LEASEID ? "Update" : "Save"} Booking
                          </Button>
                        )}
                        {sendBtn && (
                          <Button
                            type="primary"
                            htmlType="button"
                            className="btn-blue"
                            disabled={disableAll || disabletenant}
                            //  || checkButton?.length > 0}
                            onClick={sendToTenant}
                          >
                            Send to tenant 
                          </Button>
                        )}
                        {reSendBtn && (
                          <Button
                            type="primary"
                            htmlType="button"
                            className="btn-blue"
                            disabled={disableAll || disabletenant}
                            //  || checkButton?.length > 0}
                            onClick={sendToTenant}
                          >
                            Re-Send to tenant
                          </Button>
                        )}
                        {approveBtn && (
                          <Button
                            type="primary"
                            className="btn-blue"
                            htmlType="button"
                            disabled={disableAll || disableLeasebtn}
                            onClick={ApproveTenantInfo}
                          >
                            Approve Tenant Information
                          </Button>
                        )}
                        {rejectBtn && (
                          <Button
                            type="primary"
                            className="btn-blue"
                            htmlType="button"
                            disabled={disableAll || disableLeasebtn}
                            onClick={RejectTenantInfo}
                          >
                            Reject Tenant Information
                          </Button>
                        )}
                        {/* CASE AUTO */}
                        {createEjariBtn && (
                          <Button
                            type="primary"
                            className="btn-blue"
                            onClick={() => createEjari()}
                            disabled={
                              PaymentType.length > 0
                                ? true
                                : false || disableAll
                            }
                          >
                            Create Ejari
                          </Button>
                        )}
                        {downloadEjariBtn && ejariDownloadUrl && (
                          <Button
                            type="primary"
                            href={ejariDownloadUrl}
                            target="_blank"
                            className="btn-blue"
                            // onClick={() => downloadEjariContract()}
                            disabled={
                              PaymentType.length > 0
                                ? true
                                : false || disableAll
                            }
                          >
                            Download Ejari Contract
                          </Button>
                        )}
                        {downloadEjariBtn && signedEjariDownloadUrl && (
                          <Button
                            type="primary"
                            href={signedEjariDownloadUrl}
                            target="_blank"
                            className="btn-blue"
                            // onClick={() => downloadEjariContract()}
                            disabled={
                              PaymentType.length > 0
                                ? true
                                : false || disableAll
                            }
                          >
                            Download Signed Ejari Contract
                          </Button>
                        )}
                        {uploadEjariBtn && signedEjariDownloadUrl && (
                          <Upload {...ejarifileProps}>
                            <Button
                              icon={<UploadOutlined />}
                              type="primary"
                              className="btn-blue"
                              // onClick={() => uploadSignedEjariContract()}
                              disabled={
                                PaymentType.length > 0
                                  ? true
                                  : false || disableAll
                              }
                            >
                              Upload Signed Ejari
                            </Button>
                          </Upload>
                        )}
                      </>
                    )}

                    {confirmBtn && (
                      <Button
                        type="primary"
                        className="btn-blue"
                        onClick={() => approveForm()}
                      // disabled={disableAll}
                      >
                        Approve Booking
                      </Button>
                    )}

                    {/*  BOOKING APPROVAL FLOW */}
                    {sendSignatureBtn && (
                      <Button
                        type="primary"
                        className="btn-blue"
                        onClick={() => sendForEsignature(TENANCY_CONTRACT)}
                      // disabled={disableAll}
                      >
                        {contractSent == true ? "Re-send" : "Send"} For
                        eSignature
                      </Button>
                    )}
                    {sendBookingApprovalBtn && (
                      <Button
                        type="primary"
                        className="btn-blue"
                        onClick={() => sendForApproval()}
                        disabled={editBookingBtn}
                      >
                        {editBookingBtn
                          ? "Sent For Approval"
                          : "Send For Approval"}
                      </Button>
                    )}
                    {editBookingBtn && (
                      <Button
                        type="primary"
                        className="btn-blue"
                        onClick={() => editBooking()}
                      // disabled={disableAll}
                      >
                        Edit Booking
                      </Button>
                    )}
                    {manageBookingApprovalBtn && (
                      <>
                        <Button
                          type="primary"
                          className="btn-blue"
                          onClick={() => AcceptApproval()}
                        // disabled={disableAll}
                        >
                          Approve
                        </Button>
                        <Button
                          type="primary"
                          className="btn-blue"
                          onClick={() => openRejectPopup(1)}
                        // disabled={disableAll}
                        >
                          Reject
                        </Button>
                      </>
                    )}

                    {/*  LEASE APPROVAL FLOW */}
                    {sendLeaseApprovalBtn && (
                      <Button
                        type="primary"
                        className="btn-blue"
                        onClick={() => sendLeaseForApproval()}
                      // disabled={disableAll}
                      >
                        {disableAll
                          ? "Sent Lease For Approval"
                          : "Send Lease For Approval"}
                      </Button>
                    )}
                    {/* )} */}
                    {manageLeaseApprovalBtn && (
                      <>
                        <Button
                          type="primary"
                          className="btn-blue"
                          onClick={() => AcceptLeaseApproval()}
                        // disabled={disableAll}
                        >
                          Approve Lease
                        </Button>
                        <Button
                          type="primary"
                          className="btn-blue"
                          onClick={() => openRejectPopup(2)}
                        // disabled={disableAll}
                        >
                          Reject Lease
                        </Button>
                      </>
                    )}

                    {!updatePdcBtn &&
                      leaseFormData?.leaseType == "renewal" && ( //updatePdcBtn
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="btn-green"
                          // disabled={disableAll || disabletenant}
                          //  || checkButton?.length > 0}
                          onClick={(e) => {
                            e.preventDefault();
                            updatePaymentSchedule(e, true);
                          }}
                        >
                          Update Payment Schedule
                        </Button>
                      )}

                    {updatePdcBtn && ( //updatePdcBtn
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="btn-green"
                        // disabled={disableAll || disabletenant}
                        //  || checkButton?.length > 0}
                        onClick={(e) => {
                          e.preventDefault();
                          updatePaymentSchedule(e);
                        }}
                      >
                        Confirm Payment Schedule
                      </Button>
                    )}

                    {activateEjariBtn && (
                      <Button
                        type="primary"
                        className="btn-blue"
                        onClick={() => activateEjari()}
                      // disabled={PaymentType.length > 0 ? true : false || disableAll}
                      >
                        Activate
                      </Button>
                    )}

                    {/* CASE AUTO */}
                    {downloadCertificateBtn &&
                      !ejariCertificateDownloadUrl &&
                      !uploadEjariCertificateBtn && (
                        <Button
                          type="primary"
                          className="btn-blue"
                          onClick={() => downloadEjariCertificate()}
                        // disabled={PaymentType.length > 0 ? true : false || disableAll}
                        >
                          Generate Ejari Certificate
                        </Button>
                      )}
                    {/* CASE MANUAL */}
                    {uploadEjariCertificateBtn && (
                      <Upload {...certificatefileProps}>
                        <Button
                          icon={<UploadOutlined />}
                          type="primary"
                          className="btn-blue"
                        // onClick={() => uploadSignedEjariContract()}
                        // disabled={PaymentType.length > 0 ? true : false || disableAll}
                        >
                          {" "}
                          {ejariCertificateDownloadUrl ? "Re-" : ""}
                          Upload Ejari Certificate
                        </Button>
                      </Upload>
                    )}
                    {downloadCertificateBtn && ejariCertificateDownloadUrl && (
                      <Button
                        type="primary"
                        href={ejariCertificateDownloadUrl}
                        target="_blank"
                        className="btn-blue"
                      // disabled={PaymentType.length > 0 ? true : false || disableAll}
                      >
                        Download Ejari Certificate
                      </Button>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Popup destroyOnClose={true} {...popupContent} />
    </DashboardLayout>
  );
};
