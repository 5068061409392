import React, { useState, useEffect } from "react";
import DashboardHeader from "../../../../molecules/DashboardHeader";
import userimage from "../../../../../assets/img/user.png";
import { useSelector, useDispatch } from "react-redux";
import {
  getInprogressCount,
  getClosedCount,
} from "../../Tickets/ducks/actions";

const figures = [
  {
    label: "New",
    value: 0,
  },
  {
    label: "In Progress",
    value: 0,
  },
  {
    label: "Resolved",
    value: 0,
  },
];

const userDetail = {
  username: "Nabeel",
  designation: "Facility Manager",
  userPic: userimage,
};

export default (props) => {
  const dispatch = useDispatch();
  let figureData = Array.from(figures);

  const [ticketStatus, setTicketStatus] = useState(figures);
  const ticketsCountApiNew = []
  // useSelector(
  //   (state) => state.tickets.ticketsCountNew,
  // );
  const ticketsCountApiProgress =[]
  //  useSelector(
  //   (state) => state.tickets.ticketsCountProgress,
  // );
  const ticketsCountApiClosed =[]
  //  useSelector(
  //   (state) => state.tickets.ticketsCountClosed,
  // );

  useEffect(() => {
    // dispatch(getInprogressCount());
    // dispatch(getClosedCount());
  }, []);

  useEffect(() => {
    // figureData[0].value = ticketsCountApiNew;
    // figureData[1].value = ticketsCountApiProgress;
    // figureData[2].value = ticketsCountApiClosed;
    // setTicketStatus(figureData);
  }, [ticketsCountApiNew, ticketsCountApiProgress, ticketsCountApiClosed]);

  return <DashboardHeader user={userDetail} figures={ticketStatus} />;
};
