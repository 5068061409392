import React, { useState, useEffect } from "react";
import { Col, Row, Button } from "antd";
import { InputField, ReactSelectField } from "../../../atoms/FormElement";
import { DeleteOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useWatch } from "react-hook-form";

var _ = require("lodash");

let securityInst = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
];

export default (props) => {
  const {
    item,
    control,
    errors,
    billingType,
    remove,
    index,
    noIstallment,
    parking,
    setValue,
    getValues,
    setNoInstallment,
    noIData,
    disableClone,
    deleteIndex,
    enableClone,
  } = props;

  let rcInst = [{ label: 1, value: 1 }];
  const [enable, setEnable] = useState(true);
  const [disable, setDisable] = useState(false);
  const [instali, setInstalli] = useState([]);
  const [rcDisable, setRCDisable] = useState(false);
  const [typeM, setTypeM] = useState("");
  const configData = {
    rental_commission_percentage: 0.05
  }


  const payment_Modes = [
    {
      "id": "cheque",
      "name": "cheque",
    },
    {
      "id": "cash",
      "name": "cash",
    },
    {
      "id": "bank_transfer",
      "name": "bank_transfer",
    },

    {
      "id": "credit_card",
      "name": "credit_card",
    }
  ];


  useEffect(() => {
    item?.type?.value != "PARKING" && setEnable(false);
    // item?.type?.label != "RENTAL COMMISSION" && setRCDisable(false);
    if (
      item?.type?.value == "ejari_fee" ||
      item?.type?.value === "rent_installment" ||
      item?.type?.value == "security_deposit"
    ) {
      setDisable(true);
    } else if (
      item?.type?.value == "ejari_fee" ||
      item?.type?.value === "rent_installment" ||
      item?.type?.value == "security_deposit"
    )
      // disableClone(item?.type?.value);
      onInstallmentChange(item?.intallments);
  }, []);

  const onChange = (e, index) => {
    if (e.value == "rental_commission") {
      setValue(`othercharges[${index}].intallments`, { label: "1", value: 1 });
      setValue(
        `othercharges[${index}].amount`,
        getValues("annualBaseRent") * configData?.rental_commission_percentage,
      );
      setRCDisable(true);
    } else {
      setRCDisable(false);
      setValue(
        `othercharges[${index}].amount`,
        0
      );
    }
    e.value == "PARKING" ? setEnable(true) : setEnable(false);
    // setTypeM(e.value);
    // disableClone(e.value);
  };

  const onInstallmentChange = (e) => {
    if (item?.type?.value === "rent_installment") {
      setValue("noOfInstallments", e);
      let install = [];
      Array.from(Array(e.value), (_, index) => index + 1).forEach((item) =>
        install.push({ label: item, value: item }),
      );
      let moreArr = getValues().othercharges;
      if (moreArr.length > 0) {
        moreArr.map((item, index) => {
          if (
            item.intallments.value > e.value &&
            item.type.value !== "rent_installment"
          ) {
            setValue(`othercharges[${index}].intallments`, "");
          }
        });
      }
      setNoInstallment(install);
    }
  };

  const onAmountChange = (e) => {

    const value = parseFloat(e);

    if (item?.type?.value === "rent_installment") {
      console.log("Amount changed to:", value);
      setValue("annualBaseRent", value);
      let moreArr = getValues().othercharges;
      if (moreArr.length > 0) {
        moreArr.map((item, index) => {
          if (item.type.value == "rental_commission") {
            setValue(
              `othercharges[${index}].amount`,
              value * configData?.rental_commission_percentage,
            );
          }
        });
      }
    } else if (item?.type?.value == "security_deposit") {
      setValue("nonRefundableAmount", value);
    }
  };

  // console.log("mode2", errors)

  return (
    <Row gutter={24} style={{ position: "relative" }}>
      <Col span={6}>
        <InputField
          classes="d-none"
          label="id"
          fieldname={`othercharges[${index}].itemId`}
          iProps={{ type: "number" }}
          control={control}
          initValue={item?.itemId || null}
        />
        <InputField
          classes="d-none"
          label="item"
          fieldname={`othercharges[${index}]`}
          control={control}
          initValue={item || null}
        />
        <ReactSelectField
          isRequired={true}
          label="Select Billing Charges"
          fieldname={`othercharges[${index}].type`}
          control={control}
          iProps={{
            placeholder: "Select one",
            isDisabled: props.disableAll ? true : disable,
          }}
          initValue={item?.type}
          selectOption={billingType}
          // selectOption={[{label:"Base Rent",value:"baseRent"}]}
          onChange={(e) => onChange(e, index)}
          rules={{ required: "Select one" }}
          validate={
            Object.entries(errors).length > 0 &&
            errors?.othercharges?.length > 0 &&
            errors?.othercharges[index]?.type &&
            "error"
          }
          validMessage={
            Object.entries(errors).length > 0 &&
            errors?.othercharges?.length > 0 &&
            errors?.othercharges[index]?.type &&
            errors?.othercharges[index]?.type?.message
          }
        />
      </Col>
      <Col span={6}>
        <ReactSelectField
          isRequired={true}
          label="No. of Instalments"
          fieldname={`othercharges[${index}].intallments`}
          control={control}
          iProps={{
            placeholder: "Select one",
            isDisabled: props.disableAll
              ? true
              : rcDisable ||
              // item?.type?.value == "ejari_fee" ||
              // item?.type?.value == "security_deposit" ||
              // item?.type?.value != "rental_commission"
              item?.type?.value != "rent_installment"
          }}
          onChange={onInstallmentChange}
          selectOption={
            item?.type?.value === "rent_installment"
              ? noIData
              : item?.type?.value == "security_deposit"
                ? securityInst
                : [{ label: "1", value: 1 }]
            // : noIstallment
          }
          initValue={item?.intallments}
          rules={{ required: "Select one" }}
        // validate={
        //   Object.entries(errors).length > 0 &&
        //   errors?.othercharges?.length > 0 &&
        //   errors?.othercharges[index]?.intallments &&
        //   "error"
        // }
        // validMessage={
        //   Object.entries(errors).length > 0 &&
        //   errors?.othercharges?.length > 0 &&
        //   errors?.othercharges[index]?.intallments &&
        //   errors?.othercharges[index]?.intallments?.message
        // }
        />
      </Col>
      <Col span={6}>
        <ReactSelectField
          isRequired={true}
          label="Payment Mode"
          fieldname={`othercharges[${index}].mode`}
          control={control}
          iProps={{
            placeholder: "Select one",
            isDisabled: props.disableAll ? true : false,
          }}
          // initValue={item?.mode ? { label: item?.mode, value: item?.mode } : { value: "cheque", label: "Cheque" }}
          selectOption={payment_Modes?.map((item, index) => {
            return { label: item?.name, value: item?.id };
          })}
        // selectOption={[{label:"Base Rent",value:"baseRent"}]}
        // onChange={(e) => onChange(e, index)}
        // rules={{ required: "Select one" }}
        // validate={
        //   Object.entries(errors).length > 0 &&
        //   errors?.othercharges?.length > 0 &&
        //   errors?.othercharges[index]?.type &&
        //   "error"
        // }
        // validMessage={
        //   Object.entries(errors).length > 0 &&
        //   errors?.othercharges?.length > 0 &&
        //   errors?.othercharges[index]?.type &&
        //   errors?.othercharges[index]?.type?.message
        // }
        />
      </Col>
      <Col span={6}>
        <InputField
          // isRequired={true}
          label="Amount"
          fieldname={`othercharges[${index}].amount`}
          disabled={props.disableAll
            ? true
            : false
            // rcDisable //removed in SP 12 to make it editable 
            // ||
            // item?.type?.value == "ejari_fee" ||
            // item?.type?.value == "security_deposit" ||
            // item?.type?.value == "rental_commission"   //removed in SP 12 to make it editable 
          }
          iProps={{
            placeholder: "Amount",
            type: "number",
            // isDisabled: props.disableAll
            //   ? true
            //   : rcDisable ||
            //   item?.type?.value == "ejari_fee" ||
            //   item?.type?.value == "security_deposit" ||
            //   item?.type?.value == "rental_commission",
            onInput: (event) => onAmountChange(event.target.value),
          }}
          // onInput={onAmountChange}
          control={control}
          initValue={item?.amount}
          validate={
            Object.entries(errors).length > 0 &&
            errors?.othercharges?.length > 0 &&
            errors?.othercharges[index]?.amount &&
            "error"
          }
          validMessage={
            Object.entries(errors).length > 0 &&
            errors?.othercharges?.length > 0 &&
            errors?.othercharges[index]?.amount &&
            errors?.othercharges[index]?.amount?.message
          }
          rules={{
            // required: "Enter Amount",
            min: { value: 1, message: "Enter amount" },
            setValueAs: (value) => parseFloat(value?.toFixed(2)),
          }}
        />
      </Col>
      {enable && (
        <>
          <Col span={6}>
            <ReactSelectField
              isRequired={true}
              label="Paid Parking Slot"
              fieldname={`othercharges[${index}].slot`}
              iProps={{
                isDisabled: props.disableAll,
                placeholder: "Select one",
              }}
              control={control}
              initValue={
                item?.slot ? { label: item.slot, value: item.slot } : ""
              }
              selectOption={_.map(parking, (e) => ({ label: e, value: e }))}
              rules={{ required: "Select one" }}
              validate={
                Object.entries(errors).length > 0 &&
                errors?.othercharges?.length > 0 &&
                errors?.othercharges[index]?.slot &&
                "error"
              }
              validMessage={
                Object.entries(errors).length > 0 &&
                errors?.othercharges?.length > 0 &&
                errors?.othercharges[index]?.slot &&
                errors?.othercharges[index]?.slot?.message
              }
            />
          </Col>
        </>
      )}
      {!props.disableAll && (//index > 0 && ( //(deleteIndex ?? 2)
        <Button
          className="removebtn"
          style={{ top: "2em" }}
          type="link"
          icon={<DeleteOutlined />}
          onClick={() => {
            remove(index);
            enableClone(typeM);
          }}
          danger
        />
      )}
    </Row>
  );
};
