import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Spin,
  Typography,
  Space,
  message,
  Radio
} from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useForm, useWatch } from "react-hook-form";
import {
  DateField,
  InputRadio,
  ReactSelectField,
  TextAreaField
} from "../../../../../atoms/FormElement";
import {
  maintenanceService
} from "../../../../../../configs/constants";
import axios from "../../../../../../utils/axiosInceptor";
import { useDispatch, useSelector } from "react-redux";
import { getAvailableTechnicians, assignAvailableTechnicians, getAvailablity, getTicketType, getOrgByID } from '../../ducks/actions';
import { getMaintenanceTypes } from "../../../../Configurations/ducks/actions";
import { defaultMaintenancePreferredDateDays, radioOptions } from "../../../../BookingForm/components/utils";

var _ = require("lodash");
const { Text } = Typography;

const reasons = [
  // { value: "closed_by_system", label: "Closed by system" }, removed as per feedback
  // { value: "closed_by_tenant", label: "Closed by tenant" },
  { value: "duplicate", label: "Duplicate" },
  { value: "false_alarm", label: "False alarm" },
  { value: "issue_self_resolved", label: "Issue Self-Resolved" },
  { value: "landlord_not_responsible", label: "Landlord not responsible" },
  { value: "no_access", label: "No access" },
  { value: "other", label: "Other" }
]

const defaultVal = {
  comment: "",
  reason: "",
  date: "",
  timeslot: "",
  technician: "",
  desc: "",
  media: "",
  propertytype: "",
  mobile: "",
  unit_code: "",
  tickettype: null,
  ticketsubtype: null,
  isCategory: "no"
};

const { Paragraph } = Typography;

const ReAssignTechnician = props => {

  const dispatch: any = useDispatch();

  const [subTypes, setSubTypes] = useState([]);
  const [ticketsType, setTicketType] = useState([]);

  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [slots, setSlots] = useState([]);
  const [technicians, setTechnicians] = useState([]);

  const availableSlots = useSelector(
    (state: any) => state.tickets.availableSlots,
  );

  const { control, handleSubmit, formState: { errors }, reset, setValue, getValues, setError } = useForm({
    defaultValues: defaultVal,
  });

  const { ticket, onBack } = props

  const { ticketStatus } = ticket

  const datecheck: any = useWatch({ control, name: "date", defaultValue: "" });

  const isCategory: any = useWatch({ control, name: "isCategory", defaultValue: "no" });

  const ticketTypeApi = useSelector((state: any) => state.tickets.ticketsType);
  const organization = useSelector((state: any) => state.tickets.orgData);


  // console.log("TICKET", ticket, organization);

  const timeInMins = (start?, end?) => {
    // Create Moment.js objects for the times
    const preferredTimeStart = moment(start || ticket?.preferredTimeStart, 'HH:mm');
    const preferredTimeEnd = moment(end || ticket?.preferredTimeEnd, 'HH:mm');

    // Calculate the difference in minutes
    return preferredTimeEnd.diff(preferredTimeStart, 'minutes').toString();
  }

  const checktype: any = useWatch({ control, name: "tickettype" });
  const checksubtype: any = useWatch({ control, name: "ticketsubtype" });

  useEffect(() => {

    const orgId = JSON.parse(
      window.localStorage.getItem("login-response")
    )?.orgId;

    if (orgId && props?.ticket?.propertyId) {
      const param = {
        serviceProviderId: orgId,
        property: props?.ticket?.propertyId
      }
      dispatch(getTicketType(param));
    }

    if (props?.ticket?.orgId) {
      // console.log("orgId", orgId, props?.ticket?.orgId);
      dispatch(getOrgByID(props?.ticket?.orgId));
    }
  }, []);

  useEffect(() => {
    if (checktype?.value && ticketsType?.length > 0) {
      setValue("ticketsubtype", "");
      // console.log('second...');
      const subCategories = ticketsType?.find(item => item.categoryId === checktype?.value)?.subCategory || ticketsType?.find(item => item.categoryId === checktype?.value)?.subCategories
      subCategories?.length > 0 && setSubTypes(subCategories);
    }
  }, [checktype]);

  useEffect(() => {
    if (moment(datecheck).isValid()) {
      setValue("timeslot", "");

      let body = {
        categoryId: ticket?.category?.categoryId,
        startDate: dayjs(datecheck).format("YYYY-MM-DD"),
        propertyId: ticket?.propertyId,
        timeInMin: timeInMins(),
        bookingId: ticket?.bookingId,
        unitType: ticket?.unitType,
        ticketType: ticket?.maintenanceType,
      };
      if (isCategory == 'yes' && checksubtype && checktype) {
        const newCategory = getValues('tickettype')?.value;
        const subType = subTypes.find(item => item?.subCategoryId == checksubtype.value);
        // console.log("ticketCategiry",ticket?.category?.categoryId,newCategory);
        body['categoryId'] = newCategory || ticket?.category?.categoryId;
        body['timeInMin'] = subType?.timeInMin;
      }

      dispatch(getAvailablity(body));

    } else {
      setValue("timeslot", "");
      setSlots([]);
    }
  }, [checktype, datecheck, checksubtype]);

  useEffect(() => {
    if (Object.keys(availableSlots)?.length > 0 && datecheck) {
      let date = dayjs(datecheck).format("YYYY-MM-DD")
      if (availableSlots[date])
        setSlots(availableSlots[date]);
      else {
        setSlots([]); message.error("Unavailable, Please try further dates")
      }
    } else {
      setSlots([]);
    }
  }, [availableSlots]);

  useEffect(() => {
    // console.log("tickets", props?.ticket);
    if (props?.ticket?.unitType === 'commercial', ticketTypeApi) {
      ticketTypeApi && setTicketType(ticketTypeApi?.commercialCategories)
      if (ticketTypeApi?.commercialCategories?.length == 0) setTicketType([]);
    }
    if (props?.ticket?.unitType === 'residential') {
      ticketTypeApi && setTicketType(ticketTypeApi?.residentialCategories)
      if (ticketTypeApi?.residentialCategories?.length == 0) setTicketType([]);
    }

  }, [ticketTypeApi]);


  useEffect(() => {

    ; (async () => {
      const ticket = props.ticket;

      ticket?.category && setValue("tickettype", { label: ticket?.category?.name, value: ticket?.category?.categoryId });
      setTimeout(() => {
        ticket?.subCategory && setValue("ticketsubtype", { label: ticket?.subCategory?.name, value: ticket?.subCategory?.subCategoryId });
        // console.log('first...');
      }, 2000);

      if (ticket && ticket.ticketStatus === 'reassign') {
        let timeInMin

        if (ticket?.preferredTimeStart && ticket?.preferredTimeEnd) {
          timeInMin = timeInMins()
        }

        let payload = {
          "categoryId": ticket.category?.categoryId,
          "propertyId": ticket.propertyId,
          "timeInMin": timeInMin,
          "preferredTimeStart": ticket.preferredTimeStart,
          "preferredTimeEnd": ticket.preferredTimeEnd,
          "preferredDate": ticket.preferredDate,
          "unitType": ticket?.unitType,
          "ticketType": ticket?.maintenanceType,
        }

        try {
          const availableTechnicians = await getAvailableTechnicians(payload)
          if (availableTechnicians.status === 200) {
            setTechnicians(availableTechnicians.data?.result?.filter(tech => tech?.technicianId !== ticket?.technician?.technicianId))
          }
        } catch (error) {
          message.error(error?.response?.data?.error)
        }
      }

    })()
  }, [props.ticket])

  const onFinish = async (values) => {

    let payload, url, messageText;
    if (ticketStatus === 'close') {
      if (!values?.reason || values?.reason?.value === 'select') {
        setError('reason', { type: 'manual', message: 'Please select the reason to close this ticket' })
        return
      }
      payload = {
        "reason": values.comment,    //comemnt box
        "status": "issue_resolved",  // fixed 
        "ticketSubStatus": values?.reason?.label //dropdown
      }
      url = `${maintenanceService}/maintenance/ticket/${ticket.ticketId}`
      messageText = 'Ticket closed successfully'
    }

    if (ticketStatus === 'reschedule') {
      payload = {
        "preferredDate": dayjs(values.date).format("YYYY-MM-DD"), //convert date to string,
        "preferredTimeStart": values?.timeslot?.value?.split('-')[0],
        "preferredTimeEnd": values?.timeslot?.value?.split('-')[1],
        "timeInMin": timeInMins(),
        "reason": "",
        //.........
        "categoryId": values?.tickettype?.value || ticket?.category?.categoryId,
        // "categoryName": values?.tickettype?.label || ticket?.category?.name,
        // "subCategoryId": values?.ticketsubtype?.value || ticket?.subCategory?.subCategoryId,
        // "subCategoryName": values?.ticketsubtype?.label || ticket?.subCategory?.name,
        // "type": "forward",
        //..........
        "propertyId": ticket?.propertyId,
        "bookingId": ticket?.bookingId,
        "unitType": ticket?.unitType,
        "ticketType": ticket?.maintenanceType,
        "technician": {
          "technicianId": ticket?.technician?.technicianId,
          "name": ticket?.technician?.name
        }
      }

      if (isCategory == 'yes') {
        payload = {
          ...payload,
          "categoryId": values?.tickettype?.value || ticket?.category?.categoryId,
          "categoryName": values?.tickettype?.label || ticket?.category?.name,
          "subCategoryId": values?.ticketsubtype?.value || ticket?.subCategory?.subCategoryId,
          "subCategoryName": values?.ticketsubtype?.label || ticket?.subCategory?.name,
          "type": "forward",
          "timeInMin": timeInMins(payload?.preferredTimeStart, payload?.preferredTimeEnd)
        }
      }

      url = `${maintenanceService}/maintenance/ticketReschedule/${ticket.ticketId}`
      messageText = 'Ticket Rescheduled successfully'
    }

    if (ticketStatus === 'reassign') {
      const workLoadId = technicians.find(technician => technician.technicianId === values?.technician?.value)?.workLoadId
      payload = {
        "workLoadId": workLoadId,
        "name": values?.technician?.label,
        "technicianId": values?.technician?.value,
        "bookingId": ticket?.bookingId,
        "unitType": ticket?.unitType,
        "ticketType": ticket?.maintenanceType,
        "oldTechnician": {
          "technicianId": ticket?.technician?.technicianId,
          "name": ticket?.technician?.name
        }
      }
      url = `${maintenanceService}/maintenance/ticketReassign/${ticket.ticketId}`
      messageText = 'Ticket Reassigned successfully'
    }

    try {

      setLoader(true);
      await axios.patch(url, payload);

      setTimeout(() => {
        reset();
        setLoader(false);
        message.success(messageText);
        setSubmited(false);
        props.onSubmit();
      }, 3000);

    } catch (error) {
      setLoader(false);
      setisError(true);
      const { response } = error;
      setErrorMessage(response?.data?.error);
    }
  };

  function disabledDate(current) {
    let today = dayjs().format("YYYY-MM-DD");
    return (  //maintenancePreferredDateDays
      (current && dayjs(current).format("YYYY-MM-DD") < today
      || dayjs(today).add(organization?.maintenancePreferredDateDays || defaultMaintenancePreferredDateDays, "day").isBefore(current)));
  }

  const getButtonText = () => {
    switch (ticketStatus) {
      case 'close':
        return 'Close Ticket'
        break;

      case 'reassign':
        return 'Re Assign'
        break;

      case 'reschedule':
        return 'Re Schedule'
        break;

      default:
        break;
    }
  }

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {/* <Button
        className="p-0 h-auto w-auto ag-default mb-1 modal_button"
        type="link"
        size="large"
        onClick={onBack}
        icon={<CloseOutlined />}
      /> */}
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
              {/* <Paragraph className="ag-default fontWeight600 mb-0">Loading</Paragraph> */}
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
          <Row gutter={24}>
            {
              ticketStatus === 'close' && (
                <Col span={12}>
                  <ReactSelectField
                    fieldname="reason"
                    label="Select reason to close the ticket"
                    control={control}
                    iProps={{
                      placeholder: "Select Reason",
                    }}
                    initValue=""
                    selectOption={
                      reasons
                    }
                    validate={errors.reason && "error"}
                    validMessage={
                      errors.reason && errors.reason.message
                    }
                  />
                </Col>
              )
            }
            {
              ticketStatus === 'close' && (
                <Col span={24}>
                  <Paragraph strong className="ag-primary mt-1">
                    Why are you rejecting this ticket?
                  </Paragraph>
                  <TextAreaField
                    fieldname="comment"
                    label=""
                    control={control}
                    iProps={{
                      placeholder: "Write your feedback here",
                      autoSize: { minRows: 3, maxRows: 3 },
                    }}
                    initValue=""
                    validate={errors.comment && "error"}
                    validMessage={errors.comment && errors.comment.message}
                  />
                </Col>
              )
            }

            {ticketStatus === 'reschedule' && <>
              <Col span={24}>
                <InputRadio
                  initValue={"yes"}
                  isRequired={true}
                  control={control}
                  fieldname="isCategory"
                  // validate={errors.isSignatory && "error"}
                  label="Do you want to change maintenance type?"
                  // setValue={setValue}
                  // valueGot={bookingForm["isSignatory"]}
                  // validMessage={errors.isSignatory && errors.isSignatory.message}
                  rules={{
                    // required: "This field is required",
                  }}
                  // onChange={(e: any) =>
                  //   // updateState({ isSignatory: e.target.value })
                  // }
                  options={
                    <Row gutter={[16, 16]}>
                      {radioOptions.map((option, i) => (
                        <Col span={6} key={`${option.value}-${i}`}>
                          <Radio value={option.value}>{option.label}</Radio>
                        </Col>
                      ))}
                    </Row>
                  }
                />
              </Col>
              {isCategory && isCategory == "yes" && (
                <>
                  <Col span={12}>
                    <ReactSelectField
                      isRequired={true}
                      fieldname="tickettype"
                      label="Maintenance Type"
                      control={control}
                      rules={{ required: "Please select Maintenance Type" }}
                      selectOption={
                        ticketsType && ticketsType?.map((item) => ({
                          value: item?.categoryId,
                          label: item?.name || item?.categoryName,
                        })) || []
                      }
                      // iProps={{ placeholder: "Select type", isDisabled: !ticketsType?.length }}
                      validate={errors.tickettype && "error"}
                      validMessage={errors.tickettype && errors.tickettype.message}
                      initValue={""}
                    // setValue={setValue}
                    />
                  </Col>
                  <Col span={12}>
                    <ReactSelectField
                      isRequired={true}
                      fieldname="ticketsubtype"
                      label="Sub-Type"
                      control={control}
                      iProps={{
                        placeholder: "Select Sub-Type",
                        // isDisabled: !subTypes.length,
                      }}
                      rules={{
                        required: "Please select ticket sub-type",
                      }}
                      initValue=""
                      selectOption={
                        subTypes?.map((item) => ({
                          value: item?.subCategoryId,
                          label: item?.name,
                        }))
                      }
                      validate={errors.ticketsubtype && "error"}
                      validMessage={
                        errors.ticketsubtype && errors.ticketsubtype.message
                      }
                    />
                  </Col>

                </>)}

            </>}

            {
              ticketStatus === 'reschedule' && (
                <Col span={12}>
                  <DateField
                    isRequired={true}
                    fieldname="date"
                    label="Visit Date"
                    control={control}
                    iProps={{
                      placeholder: "DD-MM-YYYY",
                      format: "DD-MM-YYYY",
                      disabledDate: disabledDate,
                    }}
                    rules={{
                      required: "Please enter date",
                      setValueAs: (value) => moment(value).format("YYYY-MM-DD"),
                    }}
                    initValue={null}
                    validate={errors.date && "error"}
                    validMessage={errors.date && errors.date.message}
                  />
                </Col>
              )
            }
            {
              ticketStatus === 'reschedule' && (
                <Col span={12}>
                  <ReactSelectField
                    isRequired={true}
                    fieldname="timeslot"
                    label={`Time Slot ${slots.length > 0 ? "(available)" : ""}`}
                    control={control}
                    iProps={{ placeholder: "Select time" }}
                    rules={{
                      required: "Please select time",
                    }}
                    selectOption={_.map(slots, (item) => ({
                      value: item,
                      label: item,
                    }))}
                    validate={errors.timeslot && "error"}
                    validMessage={errors.timeslot && errors.timeslot.message}
                  />
                </Col>
              )
            }
            {
              ticketStatus === 'reassign' && (
                <Col span={12}>
                  <ReactSelectField
                    isRequired={true}
                    fieldname="technician"
                    label={`Technician ${technicians.length > 0 ? "(available)" : ""}`}
                    control={control}
                    iProps={{ placeholder: "Select Technician" }}
                    rules={{
                      required: "Please select Technician",
                    }}
                    selectOption={_.map(technicians, (item) => ({
                      value: item?.technicianId,
                      label: item?.name,
                    }))}
                    validate={errors.technician && "error"}
                    validMessage={errors.technician && errors.technician.message}
                  />
                </Col>
              )
            }
          </Row>
          <Row gutter={24} justify="space-between">
            <Col>
              <Button className="ag-gray-button-outline" onClick={onBack}>
                Back
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button" htmlType="submit">
                {
                  getButtonText()
                }
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    setSubmited(false);
                    setisError(false);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
    </Form>)
}

export default ReAssignTechnician;
