import React, { useState, useEffect } from "react";
import { Button, Row, Col, Typography, Space, Table, Card } from "antd";
import Grid from "../../../atoms/Grid";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Popup, PopupConfirm } from "../../../atoms/Popup";
import AddTimeSlot from "./Modals/AddTimeSlot";
import { useSelector, useDispatch } from "react-redux";
// import {getTimeSlotList, getTimeSlotTypes} from "../ducks/actions";
import moment from "moment";
import UpdateTimeSlot from "./Modals/UpdateTimeSlot";
import { maintenanceService } from "../../../../configs/constants";
import axios from "axios";
import AddPublicHoliday from "./Modals/AddPublicHoliday";
import AddOperatingHours from "./Modals/AddOperatingHours";

const { Title } = Typography;

const OperatingHours = () => {
  const [searchText, setSearchText] = useState();
  const [timeSlotRowData, setTimeSlotRowData] = useState({});
  const dispatch: any = useDispatch();
  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const timeSlotsList = [];
  //  useSelector(
  //   (state: any) => state.configuration.timeslotsList
  // );
  const timeSlotsType = [];
  //  useSelector(
  //   (state: any) => state.configuration.timeSlotsType
  // );
  const [onClose, setOnClose] = useState(false);
  const [onRefresh, setOnRefresh] = useState("0");
  const [timeSlotRows, setTimeSlotRows] = useState([]);
  const [rowRefresh, setRowRefresh] = useState(false);

  useEffect(() => {
    // dispatch(getTimeSlotList());
    // dispatch(getTimeSlotTypes());
  }, []);

  // useEffect(() => {
  //   let data: any = [];
  //   if (timeSlotsList) {
  //     for (const [key, value] of Object.entries(timeSlotsList)) {
  //       let rowData: any = value;
  //       let indexItem: any = [];
  //       let rowItem: any = [];
  //       rowData?.map((item, index) => {
  //         indexItem.push(index + 1);
  //         data.push({
  //           ...{ list: value },
  //           ...item,
  //           ...{ TimeAvailabilityType: key },
  //           ...{ indexItem },
  //         });
  //       });
  //     }
  //   }
  //   setTimeSlotRows(data);
  // }, [timeSlotsList]);


  const addHoliday = () => {
    setVisible(true);
    setTrigger(0);
  };

  function onSubmit() {
    // dispatch(getListZones(1, limit, ""));
    // setPage(1);
    setVisible(false);
  }


  const popup = [
    {
      title: (
        <Title level={3} className="mb-0">
          Add Schedule
        </Title>
      ),
      content: (
        <AddOperatingHours editMode={false} onCancel={() => setVisible(false)} onSubmit={onSubmit} 
          // onClose={onClose}
          // setOnClose={setOnClose}
          // setVisible={setVisible}
          // setOnRefresh={setOnRefresh}
          // setTimeSlotRows={setTimeSlotRows}
          // timeSlotsType={timeSlotsType?.map((index) => ({
          //   label: index.name,
          //   value: index.id,
          // }))}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Update a Time Slot
        </Title>
      ),
      content: (
        <UpdateTimeSlot
          setVisible={setVisible}
          onClose={onClose}
          setOnClose={setOnClose}
          onRefresh={onRefresh}
          setTimeSlotRows={setTimeSlotRows}
          setOnRefresh={setOnRefresh}
          rowData={timeSlotRowData}
          timeSlotsType={timeSlotsType?.map((index) => ({
            label: index.name,
            value: index.id,
          }))}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Delete a Time Slot
        </Title>
      ),
      content: (
        <UpdateTimeSlot
          setVisible={setVisible}
          rowData={timeSlotRowData}
          timeSlotsType={timeSlotsType?.map((index) => ({
            label: index.name,
            value: index.id,
          }))}
        />
      ),
      width: 650,
    },
  ];

  // useEffect(() => {
  //   setOnRefresh("0");
  //   setTimeSlotRows([]);
  //   // dispatch(getTimeSlotList());
  // }, [onRefresh]);

  const onCancel = () => {
    setOnClose(true);
    setVisible(false);
  };

  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => setVisible(false),
  };

  const handleUpdate = (data) => {
    setVisible(true);
    setTrigger(1);
    setTimeSlotRowData(data?.data);
  };

  const deleteTimeSlots = (item: any) => {
    return PopupConfirm({
      title: `Are you sure you want to delete`,
      cancelText: "Back",
      okText: "Delete",
      okType: "danger",
      onOk() {
        const url = `${maintenanceService}/config/delete-time-slot`;
        try {
          axios
            .post(
              url,
              {
                timeSlotId: item?.data?.id,
              },
              { headers: { application: 4 } }
            )
            .then((res) => {
              // dispatch(getTimeSlotList());
            });
        } catch (e) {

        }
      },
    });
  };

  const actionEditDelete = (params) => {
    return (
      <>
        <Space size={16}>
          <Button
            type="link"
            onClick={() => handleUpdate(params)}
            className="p-0 ag-success"
          >
            <EditOutlined />
          </Button>
          {/* <Button type="link"   danger className="p-0">
                  <DeleteOutlined style={{ color: '#FB7171' }}    onClick={() => deleteTimeSlots(params)} />
                </Button> */}
        </Space>
      </>
    );
  };

  const startDateFormat = (params) => {
    const [startDate, endDate] = params.data.timeSlot.split("-");
    return moment(startDate, ["h:mm:ss"]).format("h:mm A");
  };

  const endDateFormat = (params) => {
    const [startDate, endDate] = params.data.timeSlot.split("-");
    return moment(endDate, ["h:mm:ss"]).format("h:mm A");
  };

  // const columnDefs = [
  //   {
  //     headerName: "Id",
  //     field: "id",
  //     valueGetter: "node.rowIndex + 1",
  //     minWidth: 40,
  //     maxWidth: 80,
  //   },
  //   {
  //     headerName: "Type",
  //     field: "TimeAvailabilityType",
  //   },
  //   { headerName: "Description", field: "description" },
  //   {
  //     headerName: "Start Time",
  //     field: "start",
  //     cellRendererFramework: startDateFormat,
  //   },
  //   {
  //     headerName: "End Time",
  //     field: "end",
  //     cellRendererFramework: endDateFormat,
  //   },
  //   {
  //     headerName: "Actions",
  //     field: "actions",
  //     cellRendererFramework: actionEditDelete,
  //   },
  // ];

  const column = [
    {
      title: "Start Date",
      dataIndex: "name",
      key: "name",
      width: 220,
      // render: (item) => {
      //   return <>{_filterName("SP", item)}</>;
      // },
    },
    {
      title: "End Date",
      dataIndex: "properties",
      key: "properties",
      width: 220,
      // render: (_, { properties }) => {
      //   return (
      //     <>
      //       {properties?.map((tag) => {
      //         return (
      //           <></>
      //                     );
      //       })}
      //     </>
      //   );
      // },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 100,
      render: (text, record) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              // onClick={() => onEdit(record)}
              className="p-0 ag-success"
            >
              <div className="Simple-tooltip">
                <EditOutlined />
                <section className="tt-content">Edit</section>
              </div>
            </Button>
          </Space>
        );
      },
    },
  ];


  return (
    <>
      <Row gutter={24}>
        <Col span={24}>
          <Row justify="end" className="mb-1">
            {/* <Button
              size="large"
              type="primary"
               onClick={addHoliday}
            >
              Add Schedule <PlusOutlined />
            </Button> */}
          </Row>
        </Col>
      </Row>
    
      <AddOperatingHours editMode={false} onCancel={() => setVisible(false)} onSubmit={onSubmit}  />
      {/* <Row gutter={24} className="ant-table-row">
        <Col span={24}>
          <Table
            columns={column}
            scroll={{ y: 500 }}
            dataSource={timeSlotRows}
            rowKey={(record, index) => index}
            className="custom-table org_table_wrap"
            pagination={{
              hideOnSinglePage: true,
            }}
          />
        </Col>
      </Row> */}
      {/* <Row gutter={24}>
        <Col xl={24}>
          {timeSlotsList && (
            <Grid
              data={timeSlotRows}
              columns={columnDefs}
              pagination={false}
              defaultSettings={gridProps}
              filterGrid={searchText}
              noRowlabel="TimeSlots"
              // noRowbtnAction={addTechnician}
            />
          )}
        </Col>
      </Row> */}
      <Popup destroyOnClose={true} {...popupProps} />
    </>
  );
};

export default OperatingHours;
