import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, Typography, message, Space, Spin } from "antd";
import {
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  InputField,
  // InputHiddenField,
  // TimeRangeField,
} from "../../../../atoms/FormElement";
import axios from "../../../../../utils/axiosInceptor";
import { maintenanceService } from "../../../../../configs/constants";
import { SelectField } from "../../../../atoms/FormElement";
// import { getTimeSlotList } from "../../ducks/actions";
import moment from "moment";
import { Popup, PopupConfirm } from "../../../../atoms/Popup";

const { Paragraph } = Typography;
const defaultValues: any = {
  description: "",
  timeSlotTypeId: "",
};

const UpdateTimeSlot = (props) => {
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues,
  } = useForm({ defaultValues });
  const dispatch: any = useDispatch();
  const [start, setStart] = useState("");
  const [formData, setFormData] = useState({
    id: null,
    description: null,
    list: [],
    timeAvailabilityTypeId: null,
    TimeAvailabilityType: null,
  });
  const [indexes, setIndexes] = useState([1]);
  const [counter, setCounter] = useState(2);
  const [newRow, setNewRow] = useState(false);

  useEffect(() => {
    if (props?.rowData) {
      const {
        rowData: {
          description,
          timeSlot,
          id,
          timeAvailabilityTypeId,
          TimeAvailabilityType,
          list,
          indexItem,
        },
        timeSlotsType,
      } = props;
      setFormData({
        id,
        description,
        list,
        timeAvailabilityTypeId,
        TimeAvailabilityType,
      });
      setIndexes(indexItem);
      setCounter((prevCounter) => prevCounter + indexItem?.length);
    }
  }, [props?.rowData]);

  const resetForm = () => {
    reset({ defaultValues });
    setIndexes([1]);
    setCounter(2);
    props?.setOnClose(false);
  };

  const onUpdate = async (value) => {
    setLoader(true);
    setSubmited(true);

    value.timeSlotId = formData?.id;
    value.timeSlotTypeId = value.timeSlotTypeId.value;
    value.timeSlots = {
      start: value.timeSlots.start.format("HH:mm:ss"),
      end: value.timeSlots.end.format("HH:mm:ss"),
    };

    try {
      let url = `${maintenanceService}/config/update-time-slot`;
      await axios.post(url, value, { headers: { application: 4 } });
      setTimeout(() => {
        setLoader(false);
        setSubmited(false);
        message.success("Timeslot update successfully");
        // dispatch(getTimeSlotList());
        props.setVisible(false);
      }, 3000);
    } catch (e) {
      setLoader(false);
      setisError(true);
      const { response } = e;
      setErrorMessage(response?.data?.message);
    }
  };

  const onFinish = async (value) => {
    const timeSlotsFormat = value?.timeSlots
      ?.filter((word) => word.id == "")
      .map((item) => {
        item.start = item.start_end_time[0].format("HH:mm:ss");
        item.end = item.start_end_time[1].format("HH:mm:ss");
        delete item["start_end_time"]; // Removes json.foo from the dictionary.
        delete item["id"]; // Removes json.foo from the dictionary.
        return item;
      });
    value.timeSlots = timeSlotsFormat.filter((el) => el != null);

    setLoader(true);
    setSubmited(true);
    value.timeSlotTypeId = value.timeSlotTypeId.value;
    try {
      let url = `${maintenanceService}/config/add-time-slot`;
      await axios.post(url, value, { headers: { application: 4 } });
      setTimeout(() => {
        setLoader(false);
        setSubmited(false);
        message.success("Timeslot added successfully");
        props.setTimeSlotRows([]);
        props?.setOnRefresh("1");
        resetForm();
        props.setVisible(false);
      }, 3000);
    } catch (e) {
      setLoader(false);
      setisError(true);
      const { response } = e;
      setErrorMessage(response?.data?.message);
    }
  };

  const addMoretIimeSlots = () => {
    setIndexes((prevIndexes) => [...prevIndexes, counter]);
    setCounter((prevCounter) => prevCounter + 1);
  };

  useEffect(() => {
    let data = getValues()?.timeSlots?.filter((word) => word.id == "");
    setNewRow(!!data?.length);
  }, [indexes, formData]);

  const removeMoretIimeSlots = (index, data) => {
    if (data) {
      return PopupConfirm({
        title: `Are you sure you want to delete`,
        cancelText: "Back",
        okText: "Delete",
        okType: "danger",
        onOk() {
          const url = `${maintenanceService}/config/delete-time-slot`;
          try {
            axios
              .post(
                url,
                {
                  timeSlotId: data?.id,
                },
                { headers: { application: 4 } }
              )
              .then((res) => {
                props?.setOnRefresh("1");
                setIndexes((prevIndexes) => [
                  ...prevIndexes.filter((item) => item !== index),
                ]);
                setCounter((prevCounter) => prevCounter - 1);
                setFormData({
                  ...formData,
                  ...{
                    list: formData?.list?.filter((word) => {
                      return word.id !== data?.id;
                    }),
                  },
                });
              });
          } catch (e) {
            console.log(e);
          }
        },
      });
    } else {
      setIndexes((prevIndexes) => [
        ...prevIndexes.filter((item) => item !== index),
      ]);
      setCounter((prevCounter) => prevCounter - 1);
    }
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
          <Row gutter={24}>
            <Col span={12}>
              <SelectField
                isRequired={true}
                fieldname="timeSlotTypeId"
                label="Type"
                control={control}
                iProps={{ placeholder: "Select type", isMulti: false }}
                rules={{ required: "Please select type" }}
                // initValue={defaultBuiladSelected}
                selectOption={props?.timeSlotsType}
                validate={errors.timeSlotTypeId && "error"}
                setValue={setValue}
                valueGot={{
                  value: formData?.timeAvailabilityTypeId,
                  label: formData?.TimeAvailabilityType,
                }}
                validMessage={
                  errors.timeSlotTypeId && errors.timeSlotTypeId.message
                }
              />
            </Col>
            <Col span={12}>
              <InputField
                isRequired={true}
                fieldname="description"
                fieldKey="description"
                label="Description"
                control={control}
                rules={{
                  required: "Please enter description",
                }}
                iProps={{ placeholder: "Description" }}
                initValue=""
                setValue={setValue}
                valueGot={formData?.description}
                validate={errors.description && "error"}
                validMessage={errors.description && errors.description.message}
              />
            </Col>
          </Row>
          {indexes.map((index) => {
            const fieldName = `timeSlots[${index}]`;
            let start_date = null;
            let end_date = null;
            let data = null;
            if (
              formData?.list !== undefined &&
              Object.keys(formData?.list)?.length > 0 &&
              formData?.list[indexes.indexOf(index)] !== undefined
            ) {
              const [startDate, endDate] =
                formData?.list[indexes.indexOf(index)]?.timeSlot?.split("-");
              data = formData?.list[indexes.indexOf(index)];
              start_date = moment(startDate, "h:mm a");
              end_date = moment(endDate, "h:mm a");
            }
            return (
              <Row gutter={24} key={fieldName}>
                {/* <Col span={20}>
                  {Object.keys(formData?.list)?.length > 0 &&
                  formData?.list[indexes.indexOf(index)] !== undefined ? (
                    <TimeRangeField
                      isRequired={true}
                      fieldname={`${fieldName}.start_end_time`}
                      fieldKey={`${fieldName}.start_end_time`}
                      label="Start Time / End Time"
                      control={control}
                      // rules={{
                      //   required: "Please select start & end time.",
                      // }}
                      iProps={{
                        format: "h:mm a",
                        disabled: true,
                        readonly: true,
                      }}
                      initValue=""
                      setValue={setValue}
                      valueGot={[start_date, end_date]}
                      // validate={errors?.['timeSlots']?.[indexes.indexOf(index)]?.start_end_time && "error"}
                      // validMessage={errors?.['timeSlots']?.[indexes.indexOf(index)]?.start_end_time && errors?.['timeSlots']?.[indexes.indexOf(index)]?.start_end_time?.message}
                    />
                  ) : (
                    <>
                      <TimeRangeField
                        isRequired={true}
                        fieldname={`${fieldName}.start_end_time`}
                        fieldKey={`${fieldName}.start_end_time`}
                        label="Start Time / End Time"
                        control={control}
                        rules={{
                          required: "Please select start & end time.",
                          validate: (value) =>
                            moment(value[0]).format("HH:mm:ss") !==
                              moment(value[1]).format("HH:mm:ss") ||
                            "Incorrect Time",
                        }}
                        iProps={{ format: "h:mm a" }}
                        initValue=""
                        validate={
                          Object.entries(errors).length > 0 &&
                          // errors?.timeSlots?.length > 0 &&
                          errors.timeSlots[index]?.start_end_time &&
                          "error"
                        }
                        validMessage={
                          Object.entries(errors).length > 0 &&
                          // errors?.timeSlots?.length > 0 &&
                          errors.timeSlots[index]?.start_end_time &&
                          errors.timeSlots[index]?.start_end_time.message
                        }
                      />
                    </>
                  )}
                </Col>
                <Col span={10} className="hidden">
                  <InputHiddenField
                    fieldname={`${fieldName}.id`}
                    fieldKey={`${fieldName}.id`}
                    control={control}
                    initValue=""
                    setValue={setValue}
                    valueGot={data?.id}
                  />
                </Col> */}
                <Col span={4}>
                  <Form.Item className="remove-more-timeslot" label=".">
                    <Button
                      type="dashed"
                      onClick={() => removeMoretIimeSlots(index, data)}
                      block
                      icon={<MinusCircleOutlined />}
                    >
                      Remove
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            );
          })}
          {formData?.list !== undefined &&
            indexes.length < 30 + Object.keys(formData?.list)?.length && (
              <Form.Item className="add-more-timeslot">
                <Button
                  type="dashed"
                  onClick={() => addMoretIimeSlots()}
                  block
                  icon={<PlusOutlined style={{ fontSize: "14px" }} />}
                >
                  Add more time slots
                </Button>
              </Form.Item>
            )}
          <Row gutter={24} justify="space-between">
            <Col>
              <Button
                className="ag-error"
                htmlType="button"
                onClick={() => {
                  resetForm();
                  props.setVisible(false);
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                className="ag-green-button"
                htmlType="submit"
                disabled={!newRow}
              >
                Add
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0 sentanceCase">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    setSubmited(false);
                    setisError(false);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
    </Form>
  );
};

export default UpdateTimeSlot;
