import React, { useState, useEffect } from "react";

// others
import { Row } from "antd";
import { SchedulePopover as Popover } from "../components";

type iProps = {
  item: any;
};

let initialState = {
  selectedItem: {},
};

const index = (props: iProps) => {
  // console.log({ props });
  // states
  const [{ selectedItem = props.item }, setState] = useState(initialState);
  const userDetails = JSON.parse(localStorage.getItem("login-response"));

  const updateChild = (state: {}) =>
    setState((prevState: any) => ({ ...prevState, ...state }));

  useEffect(() => {
    updateChild({ selectedItem: props.item });
  }, []);

  const clubFromAndTo = () => {
    if (props.item?.span?.to) {
      return props.item?.span?.from + " - " + props.item?.span?.to;
    } else {
      return props.item?.span?.from;
    }
  };
  return (
    <Row gutter={6}>
      <Popover
        item={{
          ...selectedItem,
          userId: userDetails.userId,
          recDetails: props,
        }}
        prevItem={props.item}
        updateChild={updateChild}
      >
        <div className="grid-section">
          <div className={props.item?.date && "available"}></div>
          <p className="available-label">{clubFromAndTo()}</p>
        </div>
      </Popover>
    </Row>
  );
};

export default index;
