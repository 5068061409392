export const daysArr = [
  {
    watcherName: "sundayWatcher",
    dayName: "sunday",
    label: "Sunday",
    errorLabel: "sunday",
    placeholder: "Select Slot",
  },
  {
    watcherName: "mondayWatcher",
    dayName: "monday",
    label: "Monday",
    errorLabel: "monday",
    placeholder: "Select Slot",
  },
  {
    watcherName: "tuesdayWatcher",
    dayName: "tuesday",
    label: "Tuesday",
    errorLabel: "tuesday",
    placeholder: "Select Slot",
  },
  {
    watcherName: "wednesdayWatcher",
    dayName: "wednesday",
    label: "Wednesday",
    errorLabel: "wednesday",
    placeholder: "Select Slot",
  },
  {
    watcherName: "thursdayWatcher",
    dayName: "thursday",
    label: "Thursday",
    errorLabel: "thursday",
    placeholder: "Select Slot",
  },
  {
    watcherName: "fridayWatcher",
    dayName: "friday",
    label: "Friday",
    errorLabel: "friday",
    placeholder: "Select Slot",
  },
  {
    watcherName: "saturdayWatcher",
    dayName: "saturday",
    label: "Saturday",
    errorLabel: "saturday",
    placeholder: "Select Slot",
  },
];

export const weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const watcherMapper = (key, watchers) => {
  const obj = {
    mondayWatcher: watchers.mondayWatcher,
    tuesdayWatcher: watchers.tuesdayWatcher,
    wednesdayWatcher: watchers.wednesdayWatcher,
    thursdayWatcher: watchers.thursdayWatcher,
    fridayWatcher: watchers.fridayWatcher,
    saturdayWatcher: watchers.saturdayWatcher,
    sundayWatcher: watchers.sundayWatcher,
  };
  return obj[key] || "";
};
