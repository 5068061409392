import React from "react";
import { Form, Row, Col, Button } from "antd";
import { TextAreaField } from "../../../atoms/FormElement";
import { useForm } from "react-hook-form";

export default (props) => {
  const { onReject, onCancel, field } = props;
  const { control, reset, handleSubmit } = useForm();

  const handleReject = (data) => {
    reset();
    onReject(data.rejectReason);
  };
  return (
    <Form layout="vertical" onFinish={handleSubmit(handleReject)}>
      <Row gutter={24}>
        <Col span={24}>
          <TextAreaField
            label="Enter a comment for rejection:"
            fieldname="rejectReason"
            iProps={{
              placeholder: "Some reason....",
            }}
            initValue=""
            control={control}
          />
        </Col>
      </Row>
      <Row gutter={24} justify="space-between">
        <Col>
          <Button size="large" onClick={() => onCancel(field)}>
            Back
          </Button>
        </Col>
        <Col>
          <Button size="large" className="ag-red-button" htmlType="submit">
            Reject
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
