import React from "react";

const index = () => {
  return (
    <svg
      width="30"
      height="24"
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 1.5C14.1562 1.5 13.5 2.20312 13.5 3V5.85938L12 4.5V3C12 1.35938 13.3125 0 15 0H27C28.6406 0 30 1.35938 30 3V21C30 22.6875 28.6406 24 27 24H18.75C19.0781 23.5781 19.3125 23.0625 19.4062 22.5H27C27.7969 22.5 28.5 21.8438 28.5 21V3C28.5 2.20312 27.7969 1.5 27 1.5H15ZM23.25 9.75H24.75C25.5469 9.75 26.25 10.4531 26.25 11.25V12.75C26.25 13.5938 25.5469 14.25 24.75 14.25H23.25C22.4062 14.25 21.75 13.5938 21.75 12.75V11.25C21.75 10.4531 22.4062 9.75 23.25 9.75ZM23.25 11.25V12.75H24.75V11.25H23.25ZM21.75 17.25C21.75 16.4531 22.4062 15.75 23.25 15.75H24.75C25.5469 15.75 26.25 16.4531 26.25 17.25V18.75C26.25 19.5938 25.5469 20.25 24.75 20.25H23.25C22.4062 20.25 21.75 19.5938 21.75 18.75V17.25ZM24.75 17.25H23.25V18.75H24.75V17.25ZM17.25 3.75H18.75C19.5469 3.75 20.25 4.45312 20.25 5.25V6.75C20.25 7.59375 19.5469 8.25 18.75 8.25H17.25C16.4062 8.25 15.75 7.59375 15.75 6.75V5.25C15.75 4.45312 16.4062 3.75 17.25 3.75ZM17.25 5.25V6.75H18.75V5.25H17.25ZM21.75 5.25C21.75 4.45312 22.4062 3.75 23.25 3.75H24.75C25.5469 3.75 26.25 4.45312 26.25 5.25V6.75C26.25 7.59375 25.5469 8.25 24.75 8.25H23.25C22.4062 8.25 21.75 7.59375 21.75 6.75V5.25ZM24.75 5.25H23.25V6.75H24.75V5.25ZM1.5 13.3594V21C1.5 21.8438 2.15625 22.5 3 22.5H15C15.7969 22.5 16.5 21.8438 16.5 21V13.3594C16.5 12.9375 16.3125 12.5156 15.9844 12.2344L9.98438 6.75C9.42188 6.23438 8.53125 6.23438 7.96875 6.75L1.96875 12.2344C1.64062 12.5156 1.5 12.9375 1.5 13.3594ZM0 21V13.3594C0 12.5156 0.328125 11.7188 0.9375 11.1094L6.9375 5.625C8.10938 4.59375 9.84375 4.59375 11.0156 5.625L17.0156 11.1094C17.625 11.7188 18 12.5156 18 13.3594V21C18 22.6875 16.6406 24 15 24H3C1.3125 24 0 22.6875 0 21ZM10.5 13.5H7.5V16.5H10.5V13.5ZM7.5 12H10.5C11.2969 12 12 12.7031 12 13.5V16.5C12 17.3438 11.2969 18 10.5 18H7.5C6.65625 18 6 17.3438 6 16.5V13.5C6 12.7031 6.65625 12 7.5 12Z"
        fill="#24435D"
      />
    </svg>
  );
};

export default index;
