import React from "react";

interface IProps {
  icon: any;
  label: string;
  className?: string;
}

const index = (props: IProps) => {
  const Icon = props.icon;

  return (
    <div className="icon-row">
      <div className="icon-container">
        <Icon />
      </div>
      <p className="icon-label">{props.label}</p>
    </div>
  );
};

export default index;
