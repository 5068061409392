import { Card, Col, Row, Typography, Button, Table } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { dateByformat } from "../../../../../../utils/dateHelper";
import Grid from "../../../../../atoms/Grid";
import { statusLabel } from "../../../../../../utils/media";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "../../../../../../utils/axiosInceptor";
import {
  propertyService,
  unitService,
} from "../../../../../../configs/constants";

var _ = require("lodash");

const { Title, Text } = Typography;

const dataSource = [
  {
    key: "1",
    startdate: "02/08/20",
    enddate: "02/08/21",
    agreement: "2618",
    type: "Owner",
    propertiesunits: "1 Unit",
    status: (
      <Button type="link" className="p-0">
        View Offer
      </Button>
    ),
  },
];




function viewRender(param) {

  // console.log("PARAM,", param);
  // let _text = param.data.renewalStatus ? 'Renewal in Progress' : statusLabel(param?.value).toString() || "";
  let _text = "";
  if (param?.data?.renewalStatus) { //param?.data?.leaseType == "renewal" &&
    if (param.data.renewalStatus == 'renewal_declined')
      _text = statusLabel("vacated").toString();
    else if (param.data.renewalStatus == 'renewal_accepted' && (param.data.leaseStatus == "active" || param.data.leaseStatus == "renewed"))
      _text = statusLabel(param.data.leaseStatus).toString();
    else _text = 'Renewal in Progress';
  } else _text = statusLabel(param?.value).toString() || "";
  // _text = //param.data.renewalStatus && param.data.renewalStatus == 'renewal_accepted' ? statusLabel("active").toString() :
  //   param.data.renewalStatus && param.data.renewalStatus == 'renewal_declined' ? statusLabel("vacated").toString()
  //     : param.data.renewalStatus ? 'Renewal in Progress' : statusLabel(param?.value).toString() || "";
  return <Text className="f-16">{_text}</Text>;
}

export default ({ tenantData }) => {
  const [leaseData, setLeaseData] = useState([]);
  const navigate = useNavigate();
  // const lease = useSelector((state: any) => state.tenantsSummary.leaseData);


  useEffect(() => {

    let temp = [];
    const tenant = tenantData?.userDetail;

    _.map(tenantData?.leaseDetail, item => {
      const property = item?.propertyDetails;
      const lease = item?.leaseTerms;
      console.log("tenantData@#", item);
      temp.push({
        //property
        "key": item?._id,
        "propertyName": property?.propertyName ?? "NA",
        "unitCode": property?.unitCode ?? "NA",
        "usagesType": property?.usagesType ?? "NA",
        "unitType": property?.unitType ?? "NA",
        "unitNumber": property?.unitNumber ?? "NA",
        "areaSqft": property?.areaSqft ?? "NA",
        "unitId": item?.unitId,
        "leaseId": item?.leaseId,
        "leaseStartDate": lease?.leaseStartDate ?? "NA",
        "leaseEndDate": lease?.leaseEndDate ?? "NA",
        //leaseStatus
        "canView": item?.canView ?? null,
        "leaseStatus": item?.leaseStatus,
        "renewalStatus": item?.renewalStatus,
      });
    });


    setLeaseData(temp);
  }, [tenantData]);

  const columns = [
    {
      headerName: "Property Name",
      field: "propertyName",
      maxWidth: 350,
    },
    {
      headerName: "Unit Number",
      field: "unitNumber",
    },
    {
      headerName: "Unit Code",
      field: "unitCode",
      minWidth: 250,
      maxWidth: 320,
    },
    {
      headerName: "Type",
      field: "usagesType",
    },
    {
      headerName: "Unit Type",
      field: "unitType",
    },
    {
      headerName: "Lease Start Date",
      field: "leaseStartDate",
      cellRendererFramework: (item) => dateByformat(item.data.leaseStartDate, "DD-MM-YYYY"),

    },
    {
      headerName: "Lease End Date",
      field: "leaseEndDate",
      cellRendererFramework: (item) => dateByformat(item.data.leaseEndDate, "DD-MM-YYYY"),

    },
    {
      headerName: "Lease Status",
      field: "leaseStatus",
      maxWidth: 300,
      minWidth: 180,
      cellRendererFramework: viewRender,
    },
    {
      headerName: "Action",
      maxWidth: 300,
      minWidth: 180,
      cellRendererFramework: viewRenderLease,
    },
  ];

  const getUnitDetails = async (id) => {

    const url = `${propertyService}/leasing/getById?unitId=${id}`;

    const {
      data: { result },
    } = await axios.get(url);

    return result
  };

  const getInnerUnitDetails = async (data) => {
    try {
      // const unit = await getUnitDetails(data.unitId)
      window.open(`/viewbookingform/${data.leaseId}`, '_blank');
    } catch (error) {
      console.log(error);
    }
  }

  function viewRenderLease(param) {
    let _text = "booking";
    if (param?.data?.renewalStatus) { //param?.data?.leaseType == "renewal" &&
      if (param.data.renewalStatus == 'renewal_declined')
        _text = 'booking';
      else if (param.data.renewalStatus == 'renewal_accepted' && (param.data.leaseStatus == "active" || param.data.leaseStatus == "renewed"))
        _text = 'lease'
      else _text = 'renewal';
    } else if (param.data.leaseStatus == "active") _text = 'lease'; else _text = 'booking';

    return (<div onClick={() => param.data.canView && getInnerUnitDetails(param.data)}>
      <a style={param.data.canView ? {} : { color: 'grey' }} className={`f-16`} target="_blank" >
        {/* {(param?.data?.leaseStatus === 'active' || param?.data?.leaseStatus === 'lease_expired' || param.data.renewalStatus == 'renewal_accepted') ? `Go to lease` : 'Go to booking'} */}
        {`Go to ${_text}`}
      </a>
      <ArrowRightOutlined style={{ marginLeft: 5, cursor: 'pointer', paddingTop: 2, color: 'grey' }} />
    </div>);

  }

  return (
    <Card bordered={false} bodyStyle={{ padding: 0 }}>
      <Row gutter={24}>
        <Col span={24}>
          <Grid
            data={leaseData}
            columns={columns}
            pagination={false}
            noRowlabel="Lease"
          />
        </Col>
      </Row>
    </Card>
  );
};
