import React, { useState, useEffect } from "react";

// others
import { Row } from "antd";
import { SchedulePopover as Popover } from "../components";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

type iProps = {
  item: any;
};

let initialState = {
  selectedItem: {},
};

const index = (props: iProps) => {
  // states
  const [{ selectedItem = props.item }, setState] = useState(initialState);
  const { dateRange, weekSelected = {} } = useSelector(
    (state: any) => state?.technicians || {}
  );
  const { value = "" } = weekSelected || {};

  const updateChild = (state: {}) =>
    setState((prevState: any) => ({ ...prevState, ...state }));

  useEffect(() => {
    updateChild({ selectedItem: props.item });
  }, []);
  const { startDate = "", endDate = "" } = dateRange || {};
  const checkIfDateIsBefore = () => {
    if (
      dayjs(startDate)
        .day(props.item.recDetails?.item?.day)
        .isBefore(dayjs().add(-1, "day"))
    ) {
      if (value === "current_week") {
        return "- -";
      } else if (value === "two_weeks") {
        return "- -";
      } else if (value === "custom_weeks") {
        return "Day off";
      } else {
        return "Day off";
      }
    } else {
      return "Day off";
    }
  };
  return (
    <Row gutter={6} className="roster-nonshif">
      <Popover
        item={selectedItem}
        prevItem={props.item}
        updateChild={updateChild}
      >
        <div className="grid-section">
          <div
            className={
              checkIfDateIsBefore() === "- -" ? "" : "available day-off"
            }
          />
          <p className="available-label">{checkIfDateIsBefore()}</p>
        </div>
      </Popover>
    </Row>
  );
};

export default index;
