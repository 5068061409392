import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import {
  InputField,
  ReactSelectField,
  DateField,
} from "../../../atoms/FormElement";
import { useFieldArray, useWatch } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { getLandlordUsers } from "../ducks/actions";
import dayjs from "dayjs";
import { lease_nature } from "../../BookingForm/components/utils";

var _ = require("lodash");
const initparking = { slot: "" };

export default (props) => {
  const dispatch: any = useDispatch();
  const {
    control,
    errors,
    getValues,
    setValue,
    data,
    leaseType,
    disableAll,
    parking,
    isClearTriggered,
    details,
    isRenewal,
    priorLeaseExpiry,
    isLeaseActive,
    register,
  } = props;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "free",
  });

  const [parkSlot, setParkSlot] = useState([]);
  const [landlordUserOptions, setlandlordUserOptions] = useState([]);

  const [renewal, setRenewal] = useState(false);
  const [isRentFreeDate, SetIsRentFreeDate] = useState(false);
  const leaseFormData = useSelector(
    (state: any) => state.leasingForm.leaseFormData
  );
  // redux states
  const { bookingUnitDetails } = useSelector((state: any) => state.newBooking);
  const landlordUsers = useSelector(
    (state: any) => state.leasingForm.landlordUsers
  );

  const leaseNatureData = useSelector(
    (state: any) => state.leasingForm.leaseNature
  );

  useEffect(() => {
    console.log("data?.leaseType", leaseType, isRenewal);
    if (leaseType && (leaseType === "renewal" || leaseType === "Renewal"))
      setRenewal(true);
    else if (isRenewal === false) setRenewal(false);
    else setRenewal(true);
  }, [isRenewal, leaseType]);

  useEffect(() => {
    if (isRenewal || leaseFormData?.leaseType === "renewal") {
      if (leaseFormData?.propertyId)
        dispatch(getLandlordUsers(leaseFormData?.propertyId));

      setValue(
        "legalEntity",
        leaseFormData?.bookingTerms?.legalEntity ||
          bookingUnitDetails?.businessUnitName
      );

      setValue("leaseManager", {
        label: leaseFormData?.leaseManager?.name,
        value: leaseFormData?.leaseManager?.userId,
      });
    }
  }, [data, leaseFormData?.propertyId]);

  useEffect(() => {
    if (isClearTriggered) {
      setValue("leaseStartDate", null);
      setValue("leaseEndDate", null);
      setValue("moveInDate", null);
      setValue("rentfreestart", null);
      setValue("rentfreeend", null);
      let arr = [];
      arr = getValues().free;
      arr.forEach((item, index) => {
        setValue(`free[${index}].slot`, "");
      });
      let temp = [];
      parking.map((item) => {
        temp.push({ label: item, value: item, isDisabled: false });
      });
      setParkSlot(temp);
    }
  }, [isClearTriggered]);

  useEffect(() => {
    if (data) {
      // if (data?.leaseType === "Renewal" || data?.leaseType === "renewal")
      //   console.log("YESSS:", data?.leaseType)
      // setRenewal(true);

      // console.log("renewalDate", isRenewal)
      if (isRenewal === true && data?.leaseEndDate) {
        //added for renewal creation ones
        const date = dayjs(data?.leaseEndDate)
          .add(1, "day")
          .format("YYYY-MM-DD");
        setValue("leaseStartDate", dayjs(date, "YYYY-MM-DD"));
        setLeaseEndDate(date);
      } else {
        data?.leaseStartDate &&
          setValue("leaseStartDate", dayjs(data?.leaseStartDate, "YYYY-MM-DD"));
        data?.leaseEndDate &&
          setValue("leaseEndDate", dayjs(data?.leaseEndDate, "YYYY-MM-DD"));
      }

      if (
        isRenewal === false &&
        data?.leaseType !== "renewal" &&
        data?.leaseType !== "Renewal"
      ) {
        data?.moveInDate &&
          setValue("moveInDate", dayjs(data?.moveInDate, "YYYY-MM-DD"));
      }

      data?.rentFreeStartDate &&
        setValue(
          "rentFreeStartDate",
          dayjs(data?.rentFreeStartDate, "YYYY-MM-DD")
        );
      data?.rentFreeEndDate &&
        setValue("rentFreeEndDate", dayjs(data?.rentFreeEndDate, "YYYY-MM-DD"));

      if (data?.no_of_free_parking_slots) {
        for (let i = 0; i < data?.no_of_free_parking_slots; i++)
          if (data?.free_parking_slots.length > 0) {
            append({
              slot: {
                label: data?.free_parking_slots[i],
                value: data?.free_parking_slots[i],
              },
            });
          } else {
            append(initparking);
          }
      }
      if (data?.leaseNature) {
        setValue("leaseNature", {
          label: data?.leaseNature,
          value: data?.leaseNature,
        });
      }
      if (data?.ejariProcessing) {
        setValue("ejariProcessing", {
          label: data?.ejariProcessing,
          value: data?.ejariProcessing,
        });
      }
    }
  }, [data]);

  useEffect(() => {
    if (priorLeaseExpiry) {
      //added for vacating ones-
      const date = dayjs(priorLeaseExpiry).format("YYYY-MM-DD");
      setValue("leaseStartDate", dayjs(date, "YYYY-MM-DD"));
      setLeaseEndDate(date);
    }
  }, [priorLeaseExpiry]);

  useEffect(() => {
    if (parking) {
      let temp = [];
      parking.map((item) => {
        temp.push({ label: item, value: item, isDisabled: false });
      });
      setParkSlot(temp);
    }
  }, [parking]);

  const commenceDate = useWatch({
    control,
    name: "leaseStartDate",
  });

  const bookingStartDate = useWatch({
    control,
    name: "bookingStartDate",
  });

  const rentFreeStart = useWatch({
    control,
    name: "rentFreeStartDate",
  });

  const rentFreeEnd = useWatch({
    control,
    name: "rentFreeEndDate",
  });

  useEffect(() => {
    if (rentFreeEnd || rentFreeStart) {
      SetIsRentFreeDate(true);
    } else {
      SetIsRentFreeDate(false);
    }
  }, [rentFreeEnd, rentFreeStart]);

  const setLeaseEndDate = (start) => {
    setValue("moveInDate", "");
    if (start) {
      var end = dayjs(start).add(1, "year").subtract(1, "day");
      setValue("leaseEndDate", end);
      setRentfree();
    } else setValue("leaseEndDate", "");
  };

  const disableClone = (slot) => {
    let arr = [];
    arr = getValues().free;
    let check = arr.find((item) => item.slot.label == slot.label);
    if (check) {
      let newArr = [];
      newArr = [...parkSlot];
      parkSlot.map((item, index) => {
        let b = {};
        b = arr.find((y) => y.slot.label == item.label);
        if (b) {
          newArr[index].isDisabled = true;
        } else {
          newArr[index].isDisabled = false;
        }
      });
      setParkSlot(Object.assign([], newArr));
    }
  };

  const setRentfree = () => {
    let x: any = "";
    let enddate = getValues("leaseEndDate");
    let startdate = getValues("leaseStartDate");
    x = dayjs(enddate).diff(dayjs(startdate), "month", true);
    if (x > 12) {
      setValue("rentFreeStartDate", dayjs(startdate).add(12, "month"));
      setValue("rentFreeEndDate", dayjs(enddate));
    } else {
      setValue("rentFreeStartDate", null);
      setValue("rentFreeEndDate", null);
    }
  };

  const setDatesForRent = () => {
    let x: any = "";
    let enddate = getValues("rentFreeEndDate");
    let startdate = getValues("leaseStartDate");
    x = dayjs(enddate).diff(dayjs(startdate), "month", true);
    if (x > 12) {
      setValue("rentFreeStartDate", dayjs(startdate).add(12, "month"));
      setValue("leaseEndDate", dayjs(enddate));
    } else {
      setValue("rentFreeStartDate", null);
      setValue("rentFreeEndDate", null);
      startdate &&
        setValue(
          "leaseEndDate",
          dayjs(startdate).add(1, "year").subtract(1, "day")
        );
    }
  };

  const disableMoveinDate = (current) => {
    return (
      (current && current.valueOf() < dayjs(commenceDate, "YYYY-MM-DD")) ||
      (current &&
        current.valueOf() >= dayjs(details?.leaseEndDate, "YYYY-MM-DD"))
    );
  };

  const disableCommenceDate = (current) => {
    if (priorLeaseExpiry) {
      return (
        current && current.valueOf() < dayjs(priorLeaseExpiry, "YYYY-MM-DD")
      );
    } else {
      let date = getValues("bookingStartDate");
      return (
        (current && current.valueOf() < dayjs(date, "YYYY-MM-DD")) ||
        (current &&
          current.valueOf() >= dayjs(date, "YYYY-MM-DD").add(30, "day"))
      );
    }
  };

  const disableExpiryDate = (current) => {
    // console.log(moment(commenceDate).add(1, "year").add(1, "month").subtract(1, "day"))
    // console.log(moment(commenceDate).add(1, "year"))

    let dateFormat = "YYYY-MM-DD";
    // let _first_month = dayjs(commenceDate)
    //   .add(1, "year")
    //   .add(1, "month")
    //   .subtract(1, "day");
    // let _second_month = dayjs(commenceDate)
    //   .add(1, "year")
    //   .add(61, "day");

    let currentDateFormat = current.format(dateFormat);

    let rentCondition = true;

    // if (isRenewal === false && (data?.leaseType !== "renewal" && data?.leaseType !== "Renewal")) {
    //   rentCondition = (_second_month.format(dateFormat) !== currentDateFormat && _first_month.format(dateFormat) !== currentDateFormat);
    // }

    return (
      current &&
      dayjs(commenceDate)
        .add(1, "year")
        .subtract(1, "day")
        .format(dateFormat) !== currentDateFormat
      // && rentCondition
    );
  };

  const disableRentStartDate = (current) => {
    const leaseEnd = dayjs(commenceDate).add(1, "year");

    let dateFormat = "YYYY-MM-DD";
    let currentDateFormat = current.format(dateFormat);
    return (
      current &&
      currentDateFormat !== dayjs(leaseEnd, "YYYY-MM-DD").format(dateFormat)
    );
  };

  const disableRentEndDate = (current) => {
    let leaseEnd = dayjs(commenceDate).add(1, "year");

    let leaseFinalEnd = dayjs(commenceDate).add(1, "year").add(62, "day");

    return (
      (current && current.valueOf() < dayjs(leaseEnd, "YYYY-MM-DD")) ||
      (current && current.valueOf() >= dayjs(leaseFinalEnd, "YYYY-MM-DD"))
    );
  };

  const rentFreeStartValidation = isRentFreeDate
    ? {
        rules: { required: "Enter Date" },
        validate: errors?.rentFreeStartDate && "error",
        validMessage:
          errors?.rentFreeStartDate && errors?.rentFreeStartDate.message,
      }
    : {};

  const rentFreeEndValidation = isRentFreeDate
    ? {
        rules: { required: "Enter Date" },
        validate: errors?.rentFreeEndDate && "error",
        validMessage:
          errors?.rentFreeEndDate && errors?.rentFreeEndDate.message,
      }
    : {};

  return (
    <Row gutter={24}>
      <Col span={24}>
        {(isRenewal || leaseFormData?.leaseType === "renewal") && (
          <Row gutter={24}>
            <Col span={8}>
              <ReactSelectField
                label="Lease Executive"
                fieldname="leaseManager"
                control={control}
                iProps={{
                  placeholder: "Select one",
                  isDisabled: isLeaseActive,
                }} // disable when lease active
                selectOption={landlordUsers?.map((user: any) => ({
                  label: user.name,
                  value: user.userId,
                }))}
                isRequired={true}
                setValue={setValue}
                register={register}
                resetField={props?.resetField}
                validate={errors.leaseManager && "error"}
                validMessage={
                  errors.leaseManager && errors.leaseManager.message
                }
                rules={{ required: "Please select one" }}
              />
            </Col>

            <Col span={8}>
              <InputField
                label="Legal Entity"
                fieldname="legalEntity"
                control={control}
                iProps={{ placeholder: "Enter legal entity" }}
                isRequired={true}
                validate={errors.legalEntity && "error"}
                validMessage={errors.legalEntity && errors.legalEntity.message}
                disabled={bookingUnitDetails?.businessUnitName ? true : false}
                rules={{ required: "Please enter legal entity" }}
              />
            </Col>
          </Row>
        )}

        <Row gutter={24}>
          <Col span={8}>
            <ReactSelectField
              label="Ejari Processing"
              fieldname="ejariProcessing"
              control={control}
              iProps={{ placeholder: "Select one", isDisabled: disableAll }}
              initValue=""
              selectOption={[
                { label: "AUTO", value: "auto" },
                { label: "MANUAL", value: "manual" },
              ]}
              isRequired={true}
              validate={errors.ejariProcessing && "error"}
              validMessage={
                errors.ejariProcessing && errors.ejariProcessing.message
              }
              rules={{ required: "Please select ejari processing" }}
            />
          </Col>

          <Col span={8}>
            <ReactSelectField
              label="Lease nature"
              fieldname="leaseNature"
              control={control}
              iProps={{ placeholder: "Select one", isDisabled: disableAll }}
              initValue=""
              selectOption={lease_nature}
              isRequired={true}
              validate={errors.leaseNature && "error"}
              validMessage={errors.leaseNature && errors.leaseNature.message}
              rules={{ required: "Please select lease nature" }}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={24}>
          <Col span={8}>
            <DateField
              isRequired={true}
              label="Lease commencement date"
              fieldname="leaseStartDate"
              control={control}
              validate={errors.leaseStartDate && "error"}
              onChange={(e) => setLeaseEndDate(e)}
              validMessage={
                errors.leaseStartDate && errors.leaseStartDate.message
              }
              initValue=""
              rules={{
                required: "Enter Lease commencement date",
                // setValueAs: (value) => value && value.toString(),
              }}
              iProps={{
                placeholder: "DD-MM-YYYY",
                format: "DD-MM-YYYY",
                disabledDate: disableCommenceDate,
                disabled: disableAll,
              }}
            />
          </Col>
          <Col span={8}>
            <DateField
              isRequired={true}
              label="Lease expiry date"
              fieldname="leaseEndDate"
              control={control}
              onChange={(e) => setRentfree()}
              validate={errors.leaseEndDate && "error"}
              validMessage={errors.leaseEndDate && errors.leaseEndDate.message}
              initValue=""
              // rules={{
              //   required: "Enter Lease expiry date",
              //   // setValueAs: (value) => value && value.toString(),
              // }}
              iProps={{
                disabledDate: disableExpiryDate,
                placeholder: "DD-MM-YYYY",
                format: "DD-MM-YYYY",
                disabled: disableAll,
              }}
            />
          </Col>
          {renewal === false && (
            <Col span={8}>
              <DateField
                isRequired={true}
                label="Move-In Date"
                fieldname="moveInDate"
                control={control}
                initValue=""
                iProps={{
                  disabledDate: disableMoveinDate,
                  placeholder: "DD-MM-YYYY",
                  format: "DD-MM-YYYY",
                  disabled: disableAll || !commenceDate,
                }}
                rules={{ required: "Enter Date" }}
                validate={errors?.moveInDate && "error"}
                validMessage={errors?.moveInDate && errors?.moveInDate.message}
              />
            </Col>
          )}
        </Row>
      </Col>
      {renewal === false && (
        <>
          <Col span={8}>
            <DateField
              isRequired={isRentFreeDate}
              label="Rent free start date"
              fieldname="rentFreeStartDate"
              control={control}
              initValue=""
              iProps={{
                placeholder: "DD-MM-YYYY",
                format: "DD-MM-YYYY",
                disabledDate: disableRentStartDate,
                disabled: disableAll,
              }}
              {...rentFreeStartValidation}
            />
          </Col>
          <Col span={8}>
            <DateField
              isRequired={isRentFreeDate}
              label="Rent free end date"
              fieldname="rentFreeEndDate"
              control={control}
              initValue=""
              onChange={(e) => setDatesForRent()}
              iProps={{
                placeholder: "DD-MM-YYYY",
                format: "DD-MM-YYYY",
                disabledDate: disableRentEndDate,
                disabled: disableAll,
              }}
              {...rentFreeEndValidation}
            />
          </Col>
        </>
      )}
      {/* {fields.map((item: any, index) => (
        <Col span={8} key={item.id}>
          <ReactSelectField
            isRequired={true}
            label={`Select Free Parking Slot #${index + 1}`}
            fieldname={`free[${index}].slot`}
            control={control}
            rules={{ required: "Select one" }}
            initValue={item.slot}
            onChange={disableClone}
            iProps={{ placeholder: "Select one", isDisabled: disableAll }}
            // selectOption={parkSlot}
            selectOption={[{label:"TETS",value:"test"}]}
            validate={
              Object.entries(errors).length > 0 &&
              errors?.free?.length > 0 &&
              errors?.free[index]?.slot &&
              "error"
            }
            validMessage={
              Object.entries(errors).length > 0 &&
              errors?.free?.length > 0 &&
              errors?.free[index]?.slot &&
              errors?.free[index]?.slot?.message
            }
          />
        </Col>
      ))} */}
    </Row>
  );
};
