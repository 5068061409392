import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Typography,
    Button,
    message,
    Space,
    Form,
    Popover,
} from "antd";
import {

    InfoCircleOutlined,
    PlusOutlined,
} from "@ant-design/icons";

import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { InputField, ReactSelectField } from "../../../../atoms/FormElement";
import { DeleteIcon, Empty } from "../../../../../assets/svg";
import { useSelector } from "react-redux";
import { filterParkingUnits, removeKeyFromArray } from "../utils";
import { PopupConfirm } from "../../../../atoms/Popup";

var _ = require("lodash");

export default (props) => {

    const { data, handleSave, onClose } = props;

    // redux states
    let { bookingDetails } = useSelector((state: any) => state.newBooking);

    // console.log("DATAAAA:",data);

    const {
        control,
        setValue,
        resetField,
        register,
        clearErrors,
        setError,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm();

    const { Title, Text } = Typography;

    const { parkingUnits } = useSelector(
        (state: any) => state.newBooking
    );

    const initDocBar = {
        // "parking": "",
        // "parkingType": "",
        // "bayNumber": "",
        // "actualAmount": ""
    };

    const { fields, append, remove } = useFieldArray({
        control,
        name: "parkings",
    });


    const parkingsWatch = useWatch({ control, name: "parkings" });


    const removePaid = (index) => {

        PopupConfirm({
            title: 'Paid parking will be removed. Please ensure to cancel any unpaid invoices (if applicable) from the Payments Screen.',
            content: "",
            onOk: () => {
                remove(index);
                //  setTimeout(() => {
                //     console.log("AFTERREMOVE--->", parkingsWatch, index);
                // }, 5000);
            },
            okText: "Remove",
        });

        // message.info('Paid parking removed. Please ensure to cancel any unpaid invoices (if applicable) from the Payments Screen')

    }

    // useEffect(() => {

    //     console.log("parkingsWatch", parkingsWatch)

    // }, [parkingsWatch]);

    const onChange = (e, index, isSetBay?) => {
        isSetBay === true && e?.label && setValue(`parkings[${index}].bayNumber`, e?.label); //added to add baynumber and unitId in payload
        setTimeout(() => {
            if (e?.value === "unpaid" || getValues(`parkings[${index}].parkingType`)?.value === "unpaid") {
                register(`parkings[${index}].actualAmount`, { required: false, min: 0 });
                setValue(`parkings[${index}].actualAmount`, 0);
            }
            else setValue(`parkings[${index}].actualAmount`, null);
        }, 300);
    };

    const onSubmit = async (data: any, type) => {

        clearErrors()

        let dataCopy = { ...(data || getValues()) };

        if (dataCopy.numberOfOcupants && !(/^\d+$/.test(dataCopy.numberOfOcupants))) {
            setError('numberOfOcupants', { type: 'manual', message: 'Please enter valid number of ocupants' })
            return
        }

        // if (dataCopy?.parkings?.length) {
        dataCopy = dataCopy?.parkings?.map(item => ({
            ...item, bayNumber: item?.bayNumber?.value || item?.bayNumber,
            parkingType: item?.parkingType?.value
        }))?.map(item => removeKeyFromArray(item, 'isRemoved'));
        // console.log("DATACOPE",dataCopy); return;
        await handleSave(dataCopy);
        onClose();
        // } else message.error("Parkings not added");
    };

    const isParkingPaid = (pdc, parkingId) => {
        if (pdc?.length > 0 && data?.length > 0 && parkingId) {
            const parkingPDC = pdc?.find(e => e.parkingUnitId === parkingId);
            if (parkingPDC && parkingPDC?.chequeStatus && parkingPDC?.chequeStatus == 'paid')
                return true; else return false;
        } else return false;
    }

    useEffect(() => {
        data?.length &&
            data?.map((item, index) => {
                let obj = {
                    ...item,
                    bayNumber: parkingUnits?.length > 0 ? { label: item?.bayNumber, value: item?.bayNumber } : item?.bayNumber,
                    unitId: parkingUnits?.length > 0 && item?.unitId ? { label: item?.bayNumber, value: item?.unitId } : item?.bayNumber,
                    parkingType: { label: item?.parkingType, value: item?.parkingType },
                    isRemoved: item?.parkingType == 'paid' ? true : false,
                    isPaid: isParkingPaid(bookingDetails?.paymentSchedule, item?.unitId)
                }
                append(obj)
                setTimeout(() => {
                    setValue(`parkings[${index}].actualAmount`, item?.actualAmount);
                }, 1600);
            });
    }, [data]);

    return (
        <Form
            layout="vertical"
            name="bookingform"
            scrollToFirstError
            className="bf-main"
            onFinish={handleSubmit(onSubmit)}
        >
            <Row gutter={24} className="mb-4">
                <Col span={24} className="mb-4">
                    <Text className="fontWeight600 ag-default ft-main-label">Add Parking</Text>
                </Col>
            </Row>
            {parkingUnits?.length > 0 ? (<>
                <Row gutter={24} className="mb-4">
                    {fields.length > 0 && (<>
                        <Col span={3}>
                            <Text className="fontWeight600 ag-default smallFont12">PARKING</Text>
                        </Col>
                        <Col span={5}>
                            <Text className="fontWeight600 ag-default smallFont12">PARKING TYPE</Text>
                        </Col>
                        <Col span={7}>
                            <Text className="fontWeight600 ag-default smallFont12">BAY NO.</Text>
                        </Col>
                        <Col span={7}>
                            <Text className="fontWeight600 ag-default smallFont12">AMOUNT</Text>
                        </Col>
                        <Col span={2}>
                            {/* <Text className="fontWeight600 ag-default">Parking</Text> */}
                        </Col>
                    </>)}
                </Row>
                {fields.map((item, index) => {
                    return (
                        <Row gutter={24} key={item.id} className="h-auto" style={{ marginBottom: '1rem' }}>
                            <Col span={3}><span className="require-doc serialno-tag">
                                {index + 1}</span>
                                <InputField
                                    classes="fm-input-hidden"
                                    fieldname={`parkings[${index}].parking`}
                                    control={control}
                                    initValue={index + 1}
                                    iProps={{ readOnly: true, type: "hidden" }}
                                />
                                <InputField
                                    classes="fm-input-hidden"
                                    fieldname={`parkings[${index}].bayNumber`}
                                    control={control}
                                    initValue={""}
                                    iProps={{ readOnly: true, type: "hidden" }}
                                />
                                {parkingsWatch?.length > 0 && parkingsWatch[index]?.isPaid == true && (
                                    <InputField
                                    classes="fm-input-hidden"
                                    fieldname={`parkings[${index}].actualAmount`}
                                    control={control}
                                    initValue={""}
                                    iProps={{ readOnly: true, type: "hidden" }}
                                    />
                                )}
                            </Col>
                            <Col span={5}>
                                <ReactSelectField
                                    // style={{ height: '12px' }}
                                    label=""
                                    classes="mb-0 sm-input"
                                    fieldname={`parkings[${index}].parkingType`}
                                    control={control}
                                    iProps={{
                                        placeholder: "Select type",
                                        isDisabled: (parkingsWatch?.length > 0 && parkingsWatch[index]?.isPaid == true)
                                    }}
                                    // initValue={""}
                                    onChange={(e) => onChange(e, index)}
                                    selectOption={[{ label: "paid", value: "paid" }, { label: "unpaid", value: "unpaid" }]}
                                    rules={{ required: "Please select parking type" }}
                                    validate={Object.entries(errors).length > 0 &&
                                        errors?.parkings &&
                                        errors?.parkings[index]?.parkingType && "error"
                                    }
                                    validMessage={
                                        Object.entries(errors).length > 0 &&
                                        errors?.parkings &&
                                        errors?.parkings[index]?.parkingType &&
                                        errors?.parkings[index]?.parkingType?.message
                                    }
                                />
                            </Col>
                            <Col span={7}>
                                {parkingUnits?.length > 0 ?
                                    (<ReactSelectField
                                        // style={{ height: '12px' }}
                                        label=""
                                        classes="mb-0 sm-input"
                                        fieldname={`parkings[${index}].unitId`}
                                        control={control}
                                        iProps={{
                                            placeholder: "Select bay number",
                                            isDisabled: (parkingsWatch?.length > 0 && parkingsWatch[index]?.isPaid == true)
                                        }}
                                        initValue={''}
                                        // valueGot={dataCopy?.[index]?.bayNumber ? { label: dataCopy?.[index]?.bayNumber, value: dataCopy?.[index]?.bayNumber } : null}
                                        // setValue={setValue}
                                        onChange={(e) => onChange(e, index, true)}
                                        selectOption={filterParkingUnits(parkingUnits, parkingsWatch || data)} //Added for bug: 8139
                                        rules={{ required: "Please select bay number" }}
                                        validate={Object.entries(errors).length > 0 &&
                                            errors?.parkings &&
                                            errors?.parkings[index]?.unitId && "error"
                                        }
                                        validMessage={
                                            Object.entries(errors).length > 0 &&
                                            errors?.parkings &&
                                            errors?.parkings[index]?.unitId &&
                                            errors?.parkings[index]?.unitId?.message
                                        }
                                    />) :
                                    (<InputField
                                        initValue={null}
                                        control={control}
                                        isRequired={true}
                                        setValue={setValue}
                                        classes="mb-0 sm-input"
                                        label=""
                                        iProps={{ placeholder: "Enter bay no." }}
                                        fieldname={`parkings[${index}].bayNumber`}
                                        // valueGot={bookingForm?.numberOfOcupants}
                                        rules={{ required: "Please enter bay no" }}
                                        validate={Object.entries(errors).length > 0 &&
                                            errors?.parkings &&
                                            errors?.parkings[index]?.bayNumber && "error"
                                        }
                                        validMessage={
                                            Object.entries(errors).length > 0 &&
                                            errors?.parkings &&
                                            errors?.parkings[index]?.bayNumber &&
                                            errors?.parkings[index]?.bayNumber?.message
                                        }
                                    />)}

                            </Col>
                            <Col span={7} >
                                <InputField
                                    initValue={null}
                                    control={control}
                                    isRequired={true}
                                    setValue={setValue}
                                    // resetField={resetField}
                                    // register={register}
                                    classes="mb-0 sm-input"
                                    label=""
                                    disabled={(parkingsWatch?.length > 0 && parkingsWatch[index]?.parkingType?.value == "unpaid")
                                        || parkingsWatch?.length > 0 && parkingsWatch[index]?.isPaid == true}
                                    iProps={{
                                        placeholder: "Enter amount",
                                        // readOnly: (parkingsWatch?.length > 0 && parkingsWatch[index]?.isPaid == true)
                                    }}
                                    fieldname={`parkings[${index}].actualAmount`}
                                    rules={{ required: "Please enter amount", min: { value: 1, message: "Paid parking amount cannot be 0" } }}
                                    validate={
                                        Object.entries(errors).length > 0 &&
                                        errors?.parkings &&
                                        errors?.parkings[index]?.actualAmount &&
                                        "error"
                                    }
                                    validMessage={
                                        Object.entries(errors).length > 0 &&
                                        errors?.parkings &&
                                        errors?.parkings[index]?.actualAmount &&
                                        errors?.parkings[index]?.actualAmount?.message
                                    }
                                />
                            </Col>
                            <Col span={2}>
                                {/* {parkingsWatch?.length > 0 && parkingsWatch[index]?.parkingType?.value != "paid" ? ( */}
                                {parkingsWatch?.length > 0 && parkingsWatch[index]?.isRemoved == true ?
                                    (<Button type="link" className="ef-action-btn" onClick={() => removePaid(index)}>
                                        <DeleteIcon />
                                    </Button>) : (
                                        <Button type="link" className="ef-action-btn" onClick={() => remove(index)}>
                                            <DeleteIcon />
                                        </Button>)}
                                {/* ) : (
                                    // <Popover
                                    //     placement="left"
                                    //     content={
                                    //         <Text type="danger">
                                    //             <InfoCircleOutlined /> 
                                    //         </Text>
                                    //     }
                                    //     trigger="hover"
                                    // // open={true}
                                    // >
                                    //     <Button type="link" className="ef-action-btn" disabled={true}>
                                    //         <DeleteIcon />
                                    //     </Button>
                                    // </Popover>
                                // )} */}
                            </Col>
                        </Row>
                    );
                })}
                {/* {(filteredOptions?.length > 0) && ( */}
                <Row gutter={[24, 24]} justify="end">
                    <Col span={24}>
                        <Button
                            type="dashed"
                            // className="p-0"
                            htmlType="button"
                            icon={<PlusOutlined />}
                            block={true}
                            onClick={() => {
                                append(initDocBar);
                            }}
                        >
                            <Text
                                className="ag-primary fontWeight600"
                                style={{ fontSize: "14px" }}
                            >
                                Add Parking
                            </Text>
                        </Button>
                    </Col>
                </Row>
                {/* )} */}
            </>) :
                (<Row style={{ justifyContent: "center" }}>
                    <Empty
                        width="200px"
                        height="200px"
                        style={{ alignContent: "center" }}
                    />
                    <Text>
                        No parking units have been uploaded. Kindly go to the properties section and upload parking units via CSV.
                    </Text>
                </Row>
                )}

            <Row gutter={[24, 24]} justify="end" className="mt-1">
                <Col>
                    <Space>
                        <Button
                            className="ef-discard-btn"
                            onClick={() => onClose()}
                        >Cancel</Button>
                        {/* {fields?.length != 0 && ( //bug: 8098 ----> new requirement from vishwajeet*/}
                        <Button
                            type="primary"
                            className="ef-approve-btn"
                            htmlType="submit"
                        >Update</Button>
                        {/* )} */}

                    </Space>
                </Col>
            </Row>

        </Form>
    );
};
