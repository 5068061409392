import React, { useEffect } from "react";
import { Form, Row, Col, Button } from "antd";
import { InputField } from "../../../../atoms/FormElement";
import { useForm } from "react-hook-form";

const AddContractNumber = (props) => {
  const { onClose, handleUpdate } = props;
  const { control, formState: { errors }, setValue, handleSubmit } = useForm();

  const handleFormSubmit = async (data) => {
    let payload = {
      "contractNumber": data?.contractNumber,
    };

    handleUpdate && await handleUpdate(payload);
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(handleFormSubmit)}>
      <Row gutter={24}>
        <Col span={24}>
          <InputField
            label="Ejari Contract Number"
            fieldname="contractNumber"
            iProps={{ placeholder: "Enter number" }}
            initValue=""
            setValue={setValue}
            isRequired={true}
            control={control}
            rules={{ required: "Required" }}
            validate={errors.contractNumber && "error"}
            validMessage={errors.contractNumber && errors.contractNumber.message}
          />
        </Col>
      </Row>
      <Row gutter={24} justify="space-between">
        <Col>
          <Button size="large" className="ef-discard-btn" onClick={onClose}>
            Back
          </Button>
        </Col>
        <Col>
          <Button size="large" className="ef-approve-btn" htmlType="submit">
            Update
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default AddContractNumber;
