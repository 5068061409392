import React, { useState, useEffect } from "react";

import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import axios from "../../../../../../utils/axiosInceptor";
import { getOperatingHours } from "../../ducks/actions";
import { InputCheckbox, TimeRangeField } from "../../../../../atoms/FormElement";
import { CheckCircleFilled, CloseCircleOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { maintenanceService } from "../../../../../../configs/constants";
import { Row, Col, Button, Form, Typography, message, Card, } from "antd";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

const { Title } = Typography;

var _ = require("lodash");

const AddOperatingHours = (props) => {
  const [submited, setSubmited] = useState(false);
  const [shift, setShift] = useState(null);
  const [noEdit, setNoEdit] = useState(true);
  const opertingHoursApi = useSelector(
    (state: any) => state.shifts.operatingHours
  );

  const shifts = [1, 2];
  const days = [
    { title: 'Sunday', key: 0 },
    { title: 'Monday', key: 1 },
    { title: 'Tuesday', key: 2 },
    { title: 'Wednesday', key: 3 },
    { title: 'Thursday', key: 4 },
    { title: 'Friday', key: 5 },
    { title: 'Saturday', key: 6 },
  ];


  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue
  } = useForm();

  const dispatch: any = useDispatch();

  useEffect(() => {
    dispatch(getOperatingHours());
  }, []);

  useEffect(() => {
    
    if (opertingHoursApi) {
      const schedules = opertingHoursApi[0]?.schedules

      setShift(schedules[0].shift?._id)
      if (schedules?.length > 0) {
        _.map(schedules, (day) =>
          setValue(`days[${day.day}]`, true)
        );
      }
    }
  }, [opertingHoursApi]);


  const setTime = (time) => {
    return dayjs(time).format("HH:mm")
  }


  const onFinish = async (values) => {
    // setLoader(true);
    setSubmited(true);
    if(shift) {
      const days = _.map(values.days, (value, key) => {
        return value && {shift: shift, day: key}
      }).filter(day => day)
  
      const postJson = {
        schedules: days
      };
  
      try {
        let url = `${maintenanceService}/maintenance/assignShiftToSP`; ///maintenance/category`;
        const resp = await axios.post(
          url,
          postJson,
        );
        setSubmited(false);
        message.success("Operating days updated successfully");
        dispatch(getOperatingHours());
        setNoEdit(true);
      } catch (e) {
        const { response } = e;
        setNoEdit(true);
        message.error(response?.data?.error);
      }
    } else {
      message.error('Please add atlease one shift and make it as a default shift');
    }
  };



  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Card
            bordered={false}
            // bodyStyle={{ padding: "0 32px 42px" }}
            headStyle={{ padding: "0 32px", borderBottom: 0 }}
            className="operating-hours"
            title={
              <Title level={3} className="mb-0">
                {noEdit ? "" : "Edit"} Operating Hours
              </Title>
            }
            extra={[
              <Button
                key={1}
                type="link"
                className={`p-0 no-border no-shadow ${noEdit && "d-none"}`}
                icon={<CloseCircleOutlined />}
                onClick={() => setNoEdit(true)}
                htmlType="button">
                Cancel
              </Button>,
              <Button
                key={2}
                type="link"
                className={`p-0 no-border no-shadow ${noEdit && "d-none"}`}
                icon={<CheckCircleFilled />}
                htmlType="submit"
              >
                Save
              </Button>,
              <Button
                key={3}
                type="link"
                className={`p-0 no-border no-shadow ${!noEdit && "d-none"}`}
                icon={<EditOutlined />}
                onClick={() => setNoEdit(false)}
                htmlType="button" 
              >
                Edit
              </Button>,
            ]}
          >
            {/* <Title level={5} className="ag-primary">
              Shifts
            </Title>
            <Row gutter={24}>
              {shifts.map((index) => {
                const fieldName = `shifts[${index}]`;
                return (
                  <Col span={12}>
                    <Row key={fieldName}>
                      <Col span={3}>
                        <Title level={5} className="ag-primary mb-0">
                          #{index}
                        </Title>
                      </Col>
                      <Col span={18}>
                        <TimeRangeField
                          isRequired={!noEdit}
                          fieldname={`${fieldName}`}
                          fieldKey={`${fieldName}`}
                          // label={`Shift ${index}`}
                          classes={`${noEdit ? "time-range-hours noEditLabel" : "time-range-hours"}`}
                          control={control}
                          rules={{
                            required: "Please select start & end time.",
                            validate: (value) =>
                              dayjs(value[0]).format("HH:mm") !==
                              dayjs(value[1]).format("HH:mm") ||
                              "Incorrect Time",
                          }}
                          iProps={{
                            format: "h:mm a",
                            readOnly: noEdit,
                            disabled: noEdit,
                            className: `${noEdit ? "noEdit" : ""}`,
                          }}
                          initValue=""
                          validate={
                            errors?.["shifts"]?.[index] && "error"
                          }
                          validMessage={
                            errors?.["shifts"]?.[index] &&
                            errors?.["shifts"]?.[index]?.message
                          }
                        />
                      </Col>
                    </Row>
                  </Col>

                );
              })}
            </Row> */}

            <Row gutter={24} className="days-list">
              <Col span={24}>
                <Title level={5} className="ag-primary">
                  Days
                </Title>
                {days.map((item, index) => (
                  <InputCheckbox
                    key={item.title}
                    fieldname={`days[${item.key}]`}
                    label={item.title}
                    control={control}
                    classes={`days-check ${noEdit ? "noEditLabel" : ""}`}
                    iProps={{
                       readOnly: noEdit,
                       disabled: noEdit,
                      className: `${noEdit ? "noEdit" : ""}`,
                    }}
                    initValue={false}
                  />
                ))
                }
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Form >
  );
};

export default AddOperatingHours;