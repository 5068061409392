import React from "react";
import Icon from "@ant-design/icons";

const PendingSvg = () => {
  return (
    <svg className="svg-icon" width="1em" height="1em" fill="currentColor" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M512 85.333333a426.666667 426.666667 0 1 0 426.666667 426.666667A426.666667 426.666667 0 0 0 512 85.333333z m0 768a341.333333 341.333333 0 1 1 341.333333-341.333333 341.333333 341.333333 0 0 1-341.333333 341.333333z"  /><path d="M512 298.666667a213.333333 213.333333 0 1 0 213.333333 213.333333 213.333333 213.333333 0 0 0-213.333333-213.333333z"  /></svg>
  );
};

// style="width: 1em; height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;"
const PendingIcon = (props) => <Icon component={PendingSvg} {...props} />;

export default PendingIcon;
