import React from "react";

const index = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.0195 8.80176L11.1797 2.15723C10.8633 1.91113 10.4062 1.91113 10.0898 2.15723L0.808594 10.0322C0.457031 10.3486 0.421875 10.876 0.703125 11.2275C1.01953 11.5791 1.54688 11.6143 1.89844 11.333L2.77734 10.5947V17.1338C2.77734 18.7158 4.00781 19.9463 5.58984 19.9463H14.3789C13.7461 19.5244 13.1836 18.9268 12.7617 18.2588H8.96484V13.1963H11.9883C12.1641 12.5986 12.4102 12.0361 12.7617 11.5439C12.6914 11.5088 12.6562 11.5088 12.6211 11.5088H8.68359C7.875 11.5088 7.27734 12.1416 7.27734 12.915V18.2588H5.58984C4.95703 18.2588 4.46484 17.7666 4.46484 17.1338V9.15332L10.6523 3.91504L16.4883 8.87207C16.9805 8.7666 17.4375 8.69629 17.9648 8.69629C18.3164 8.69629 18.668 8.73145 19.0195 8.80176ZM23.4824 14.8926C23.4824 17.7279 21.1839 20.0264 18.3486 20.0264C15.5133 20.0264 13.2148 17.7279 13.2148 14.8926C13.2148 12.0573 15.5133 9.75879 18.3486 9.75879C21.1839 9.75879 23.4824 12.0573 23.4824 14.8926ZM19.4116 14.5057C19.2642 14.5057 19.1315 14.432 19.0726 14.2994C19.0284 14.1667 19.0578 14.0045 19.161 13.9013L19.7654 13.2969C18.8367 12.3977 17.3626 12.3977 16.4338 13.3117C15.5199 14.2404 15.5199 15.7293 16.4338 16.658C17.3626 17.572 18.8515 17.572 19.7802 16.658C19.9571 16.4664 20.2519 16.4664 20.4435 16.658C20.6204 16.8349 20.6204 17.1297 20.4435 17.3214C19.1463 18.6039 17.0677 18.6039 15.7705 17.3214C14.488 16.0241 14.488 13.9456 15.7705 12.6483C17.053 11.3658 19.1315 11.3658 20.4288 12.6336L21.0479 12.0144C21.1364 11.926 21.2985 11.8965 21.4312 11.9407C21.5639 11.9997 21.6523 12.1324 21.6523 12.265V14.1519C21.6523 14.3583 21.4902 14.5057 21.2985 14.5057H19.4116Z" fill="#24435D"/>
        </svg>        
    );
};

export default index;
