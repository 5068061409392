import {
  GET_LEASEFORMS,
  ADD_UNITS_VISIBLE,
  SET_LEAD_ID,
  GET_CURRENT_PAGE,
} from "./constants";

const initialState = {
  getLeaseForms: {
    data: [],
  },
  visiblePopup: false,
  getLeadID: "",
  getCurrentPage: 0,
};
export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case GET_LEASEFORMS:
      return { ...state, getLeaseForms: data };
    case ADD_UNITS_VISIBLE:
      return { ...state, visiblePopup: !state.visiblePopup };
    case SET_LEAD_ID:
      return { ...state, getLeadID: data };
    case GET_CURRENT_PAGE:
      return { ...state, getCurrentPage: data };
    default:
      return state;
  }
};
