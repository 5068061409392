import React from "react";

// others
import { Col, Spin } from "antd";

interface IProps {
  visible: boolean;
}

const index = (props: IProps) => {
  const display = props.visible ? "flex" : "none";

  return (
    <Col span={24} className="loading-component" style={{ display }}>
      <Spin size="large" />
    </Col>
  );
};

export default index;
