import React from "react";

type IProps = {};

const index = (props: IProps) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 15.5L12 9.5L18 15.5"
        stroke="#2B2B2D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 15.5L12 9.5L18 15.5"
        stroke="white"
        strokeOpacity="0.1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default index;
