import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardLayout from "../../../molecules/DashboardLayout";
import { Row, Col, Button, Typography, Radio, Select } from "antd";
import { useParams, useLocation } from "react-router-dom";
import BreadCrumb from "../../../atoms/BreadCrumb";
import { Calendar, Space, DatePicker, ConfigProvider } from "antd";
import SectionHeader from "../../../molecules/SectionHeader";
import NotificationCell from "./components/DateNotifications";
import {
  getSingleProperty,
  getSinglePropertyEmpty,
} from "../AddProperty/ducks/actions/addInfoAction";
import dayjs from "dayjs";
import en_GB from "antd/lib/locale-provider/en_GB";

import {
  emptySchedule,
  getPropertiesList,
  getPropertyVisitByID,
  getScheduledVisits,
  updateScheduledVisitRequest,
} from "../ducks/actions";

var _ = require("lodash");

const { Title } = Typography;

const VisitScheduling = () => {
  const { id } = useParams();
  const location = useLocation();
  const dispatch:any = useDispatch();
  // states
  const [propertyId, setPropertyId] = useState<string>();

  const [datePicker, setdatePicker] = useState(dayjs(new Date(Date.now())));
  const [scheduledVisits, setScheduledVisits] = useState([]);
  let [startDateOfMonth, setStartDateOfMonth] = useState<string>(
    dayjs(new Date(Date.now())).startOf("month").format("YYYY-MM-DD"),
  );
  let [endDateOfMonth, setEndDateOfMonth] = useState<string>(
    dayjs(new Date(Date.now())).endOf("month").format("YYYY-MM-DD"),
  );
  let [selectedMonth, setSelectedMonth] = useState<string>(
    dayjs(new Date(Date.now())).format("MMMM"),
  );
  let [selectedYear, setSelectedYear] = useState<string>(
    dayjs(new Date(Date.now())).format("YYYY"),
  );
  let [selectedDate, setSelectedDate] = useState<Object>(
    dayjs(new Date(Date.now())),
  );

  // redux
  const propertyDetails = useSelector(
    (state: any) => state.addInfoReducer.getSingleProperty,
  );

  const scheduledVisitsAPI = useSelector(
    (state: any) => state.dashboard.scheduledVists,
  );

  const propertyVisitsAPI = useSelector(
    (state: any) => state.dashboard?.propertyVisits,
  );
  const propertiesCardApi = useSelector(
    (state: any) => state.dashboard.propertiesCard,
  );

  const breadcrumbList: any = [
    {
      label: "Properties",
      route: "/properties",
    },
    {
      label: propertyDetails.name,
      route: `/property-detail/${propertyId}`,
    },
    {
      label: "Visit Scheduling",
      route: "/visit-schedule",
    },
  ];
  const breadcrumbList2: any = [
    {
      label: "Visit Scheduling",
      route: "/visit-schedule",
    },
  ];

  useEffect(() => {
    if (location?.state?.code) {
      dispatch(getPropertyVisitByID(location?.state?.code));
    } else if (id) {
      dispatch(getSingleProperty(id));
      dispatch(getScheduledVisits(id, startDateOfMonth, endDateOfMonth));
      setPropertyId(id);
    } else {
      dispatch(getPropertiesList("", 0, 1000, true, "", ""));
    }
    return () => {
      dispatch(emptySchedule());
      dispatch(getSinglePropertyEmpty());
    };
  }, []);

  useEffect(() => {
    if (location?.state?.code) {
      dispatch(getPropertyVisitByID(location?.state?.code));
    } else if (id) {
      dispatch(getSingleProperty(id));
      dispatch(getScheduledVisits(id, startDateOfMonth, endDateOfMonth));
      setPropertyId(id);
    } else {
      dispatch(getPropertiesList("", 0, 1000, true, "", ""));
    }
  }, [id]);

  useEffect(() => {
    if (Object.keys(propertyVisitsAPI).length > 0) {
      let defautDate = dayjs(propertyVisitsAPI?.start_date);
      setdatePicker(defautDate);
      setSelectedDate(defautDate);
      setSelectedMonth(defautDate.format("MMMM"));
      setSelectedYear(defautDate.format("YYYY"));

      let start = dayjs(propertyVisitsAPI?.start_date)
        .startOf("month")
        .format("YYYY-MM-DD");
      let enddate = dayjs(propertyVisitsAPI?.end_date)
        .endOf("month")
        .format("YYYY-MM-DD");
      dispatch(
        getScheduledVisits(propertyVisitsAPI?.property?.id, start, enddate),
      );
      if (location?.state?.code || id) {
        setPropertyId(propertyVisitsAPI?.property?.id);
      }
    }
  }, [propertyVisitsAPI]);

  useEffect(() => {
    if (scheduledVisitsAPI) {
      setScheduledVisits(scheduledVisitsAPI);
    }
  }, [scheduledVisitsAPI]);

  const setSelectedDateOnMonthPickerChange = (date) => {
    setSelectedMonth(dayjs(date).startOf("month").format("MMMM"));
    selectedMonth = dayjs(date).startOf("month").format("MMMM");
    setSelectedYear(dayjs(date).startOf("month").format("YYYY"));
    selectedYear = dayjs(date).startOf("month").format("YYYY");

    setSelectedDate(
      dayjs()
        .month(+selectedMonth)
        .year(parseInt(selectedYear))
        .startOf("month"),
    );
    selectedDate = dayjs()
      .month(+selectedMonth)
      .year(parseInt(selectedYear))
      .startOf("month");
    setStartDateOfMonth(
      dayjs(+selectedDate).startOf("month").format("YYYY-MM-DD"),
    );
    startDateOfMonth = dayjs(+selectedDate)
      .startOf("month")
      .format("YYYY-MM-DD");
    setEndDateOfMonth(dayjs(+selectedDate).endOf("month").format("YYYY-MM-DD"));
    endDateOfMonth = dayjs(+selectedDate).endOf("month").format("YYYY-MM-DD");

    dispatch(getScheduledVisits(propertyId, startDateOfMonth, endDateOfMonth));
  };

  const handleDateRange = (date) => {
    if (date) {
      setdatePicker(dayjs(date));
      setSelectedDateOnMonthPickerChange(date);
    }
  };

  const handleOnCelanderChange = (date) => {
    if (date) {
      setdatePicker(date);
      setSelectedDateOnMonthPickerChange(date);
    }
  };

  const scheduledVisitRequestCallBack = (visitId, visitStatus) => {
    dispatch(updateScheduledVisitRequest(visitId, visitStatus));
    dispatch(getScheduledVisits(propertyId, startDateOfMonth, endDateOfMonth));
  };

  const toDate = () => {
    setdatePicker(dayjs(new Date(Date.now())));
    setSelectedDateOnMonthPickerChange(new Date(Date.now()));
  };

  const renderDataCell = (value) => {
    return (
      <NotificationCell
        value={value}
        scheduledVisits={scheduledVisits}
        scheduledVisitRequestCallBack={scheduledVisitRequestCallBack}
      />
    );
  };

  const onSelectDate = () => {};

  const onChange = (e) => {
    if (e) {
      setPropertyId(e);
      dispatch(getScheduledVisits(e, startDateOfMonth, endDateOfMonth));
    } else {
      setPropertyId(null);
      setScheduledVisits([]);
    }
  };

  return (
    <>
      <DashboardLayout>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            {propertyDetails && propertyDetails.name ? (
              <BreadCrumb list={breadcrumbList} />
            ) : (
              <BreadCrumb list={breadcrumbList2} />
            )}
          </Col>
          <Col span={24}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <SectionHeader
                  noBorder={true}
                  heading="Visit Scheduling"
                  total={propertyDetails.name}
                />
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Space size={24}>
                      {!location?.state?.code && !id && (
                        <Select
                          allowClear
                          mode="multiple"
                          showSearch
                          size="large"
                          value={propertyId}
                          onChange={onChange}
                          style={{ width: 200 }}
                          placeholder="Select Properties"
                          filterOption={(input, option) =>
                            option.children.toString().toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {_.map(propertiesCardApi.properties, (x) => (
                            <Select.Option value={x.asset_id}>
                              {x.name}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                      <DatePicker
                        defaultValue={datePicker}
                        value={datePicker}
                        size="large"
                        picker="month"
                        onChange={handleDateRange}
                      />
                      <Button type="primary" size="large" onClick={toDate}>
                        Jump to Today
                      </Button>
                    </Space>
                  </Col>
                  <Col span={24}>
                    <ConfigProvider locale={en_GB}>
                      <Calendar
                        headerRender={() => {
                          return (
                            <>
                              <Row
                                gutter={8}
                                style={{
                                  padding: "20px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Col>
                                  <Title level={4}>
                                    {`${selectedMonth ? selectedMonth : ""} ${
                                      selectedYear ? selectedYear : ""
                                    }`}
                                  </Title>
                                </Col>
                                <Col>
                                  <Radio.Group
                                    defaultValue="month"
                                    size="small"
                                    style={{ marginRight: "30px" }}
                                  >
                                    <Radio.Button
                                      key={1}
                                      className="radio-group-month"
                                      value="month"
                                    >
                                      Month view
                                    </Radio.Button>
                                  </Radio.Group>
                                </Col>
                              </Row>
                            </>
                          );
                        }}
                        defaultValue={dayjs(datePicker)}
                        className="ag-calender visitSchedule"
                        onPanelChange={onSelectDate}
                        dateCellRender={renderDataCell}
                        value={dayjs(+selectedDate).startOf("month")}
                        onSelect={handleOnCelanderChange}
                      />
                    </ConfigProvider>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </DashboardLayout>
    </>
  );
};

export default VisitScheduling;
