import React, { useState, useEffect } from "react";

import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import axios from "../../../../../utils/axiosInceptor";
import { getAllMaintenanceTypes, getMaintenanceTypes, getOperatingHours } from "../../ducks/actions";
import { DateField, InputCheckbox, InputField, ReactSelectField, TimeField, TimeRangeField } from "../../../../atoms/FormElement";
import { CheckCircleFilled, CloseCircleOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { maintenanceService } from "../../../../../configs/constants";
import { BsPlusCircle, BsArrowCounterclockwise } from "react-icons/bs";
import { Row, Col, Button, Form, Typography, message, Space, Spin, Card, List, Checkbox } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import dayjs from "dayjs";

const { Title } = Typography;

var _ = require("lodash");

// const defaultValues: any = {
//   name: "",
// };

const { Paragraph } = Typography;

const AddOperatingHours = (props) => {
  const [submited, setSubmited] = useState(false);
  // const [indexes, setIndexes] = useState([1, 2]);
  const [noEdit, setNoEdit] = useState(true);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const opertingHoursApi = useSelector(
    (state: any) => state.configuration.operatingHours
  );

  const orgId = JSON.parse(window.localStorage.getItem("login-response"))?.orgId;

  const shifts = [1, 2];
  const days = [
    { title: 'Sunday', key: 0 },
    { title: 'Monday', key: 1 },
    { title: 'Tuesday', key: 2 },
    { title: 'Wednesday', key: 3 },
    { title: 'Thursday', key: 4 },
    { title: 'Friday', key: 5 },
    { title: 'Saturday', key: 6 },
  ];


  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue
  } = useForm();

  const dispatch: any = useDispatch();




  useEffect(() => {
    dispatch(getOperatingHours());
  }, []);



  useEffect(() => {
    if (opertingHoursApi) {
      if (opertingHoursApi?.dailyShifts?.length > 0) {
        _.map(opertingHoursApi?.dailyShifts, (shift, index) => {
          let from = dayjs(shift?.from, "HH:mm");
          let to = dayjs(shift?.to, "HH:mm");
          setValue(`shifts[${index + 1}]`, [from, to]);
        });
      }
      if (opertingHoursApi?.workingDaysInWeek?.length > 0) {
        _.map(opertingHoursApi?.workingDaysInWeek, (day) =>
          setValue(`days[${day}]`, true)
        );
      }
    }
  }, [opertingHoursApi]);


  const setTime = (time) => {
    return dayjs(time).format("HH:mm")
  }


  const onFinish = async (values) => {
    // setLoader(true);
    setSubmited(true);

    const days = _.map(values.days, (value, key) => value === true && key).filter(k => k !== false);
    const shift1 = values.shifts[1];
    const shift2 = values.shifts[2];

    const postJson = {
      profileId: opertingHoursApi?.profileId ?? "",
      dailyShifts: [
        { "from": setTime(shift1[0]), "to": setTime(shift1[1]), "label": "shift1" },
        { "from": setTime(shift2[0]), "to": setTime(shift2[1]), "label": "shift2" }
      ],
      workingDaysInWeek: days

    };



    try {
      let url = `${maintenanceService}/maintenance/operatingHours/${opertingHoursApi._id}`; ///maintenance/category`;
      const resp = await axios.put(
        url,
        postJson,
      );
      setSubmited(false);
      // console.log("result", resp)
      message.success("Operating hours updated successfully");
      dispatch(getOperatingHours());
      setNoEdit(true);
    } catch (e) {
      // setLoader(false);
      // setisError(true);
      const { response } = e;
      setNoEdit(true);
      message.error(response?.data?.error);
    }
  };



  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Card
            bordered={false}
            // bodyStyle={{ padding: "0 32px 42px" }}
            headStyle={{ padding: "0 32px", borderBottom: 0 }}
            className="operating-hours"
            title={
              <Title level={3} className="mb-0">
                {noEdit ? "" : "Edit"} Operating Hours
              </Title>
            }
            extra={[
              <Button
                key={1}
                type="link"
                className={`p-0 no-border no-shadow ${noEdit && "d-none"}`}
                icon={<CloseCircleOutlined />}
                onClick={() => setNoEdit(true)}
                htmlType="button">
                Cancel
              </Button>,
              <Button
                key={2}
                type="link"
                className={`p-0 no-border no-shadow ${noEdit && "d-none"}`}
                icon={<CheckCircleFilled />}
                htmlType="submit"
              >
                Save
              </Button>,
              <Button
                key={3}
                type="link"
                className={`p-0 no-border no-shadow ${!noEdit && "d-none"}`}
                icon={<EditOutlined />}
                onClick={() => setNoEdit(false)}
                htmlType="button"
              >
                Edit
              </Button>,
            ]}
          >
            <Title level={5} className="ag-primary">
              Shifts
            </Title>
            <Row gutter={24}>
              {shifts.map((index) => {
                const fieldName = `shifts[${index}]`;
                return (
                  <Col span={12}>
                    <Row key={fieldName}>
                      <Col span={3}>
                        <Title level={5} className="ag-primary mb-0">
                          #{index}
                        </Title>
                      </Col>
                      <Col span={18}>
                        <TimeRangeField
                          isRequired={!noEdit}
                          fieldname={`${fieldName}`}
                          fieldKey={`${fieldName}`}
                          // label={`Shift ${index}`}
                          classes={`${noEdit ? "time-range-hours noEditLabel" : "time-range-hours"}`}
                          control={control}
                          rules={{
                            required: "Please select start & end time.",
                            validate: (value) =>
                              dayjs(value[0]).format("HH:mm") !==
                              dayjs(value[1]).format("HH:mm") ||
                              "Incorrect Time",
                          }}
                          iProps={{
                            format: "h:mm a",
                            readOnly: noEdit,
                            disabled: noEdit,
                            className: `${noEdit ? "noEdit" : ""}`,
                          }}
                          initValue=""
                          validate={
                            errors?.["shifts"]?.[index] && "error"
                          }
                          validMessage={
                            errors?.["shifts"]?.[index] &&
                            errors?.["shifts"]?.[index]?.message
                          }
                        />
                      </Col>
                    </Row>
                  </Col>

                );
              })}
            </Row>


            <Row gutter={24} className="days-list">
              {/* style={{ textAlign: "center" }}// */}
              <Col span={24}>
                <Title level={5} className="ag-primary">
                  Days
                </Title>
                {/* <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  Select all
                </Checkbox> */}
                {/* <Checkbox.Group
                  style={{ width: "100%" }}
                  value={checked}
                  // onChange={(checkedValues) => {
                  //   setChecked(checkedValues);
                  // }}
                > */}
                {days.map((item, index) => (


                  <InputCheckbox
                    key={item.title}
                    fieldname={`days[${item.key}]`}
                    label={item.title}
                    control={control}
                    classes={`days-check ${noEdit ? "noEditLabel" : ""}`}
                    iProps={{
                       readOnly: noEdit,
                       disabled: noEdit,
                      className: `${noEdit ? "noEdit" : ""}`,
                    }}
                    initValue={false}
                  />

                ))
                }

              </Col>
            </Row>

          </Card>
        </Col>
      </Row>

    </Form >
  );
};

export default AddOperatingHours;