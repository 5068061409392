import { Card, Col, Row, Typography, Form, Select } from 'antd';
import React from 'react';
import BreadCrumb from '../../../atoms/BreadCrumb';
import { InputField, ReactSelectField } from '../../../atoms/FormElement';
import DashboardLayout from '../../../molecules/DashboardLayout';
import { useForm, useWatch } from "react-hook-form";
import RequireDoc from '../../../molecules/RequireDoc';

const { Title } = Typography;

const breadcrumbList: any = [
  {
    label: 'Tenants',
    route: '/tenants'
  },
  {
    label: 'Add New Tenant',
    route: '/add-tenant'
  }
]

const AddTenant = props => {

  const { control, handleSubmit, formState: { errors } } = useForm({});

  const onSubmit = (val) => { }

  return (
    <DashboardLayout>
      <Form
        layout="vertical"
        name="addInformation"
        onFinish={handleSubmit(onSubmit)}
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <BreadCrumb list={breadcrumbList} />
          </Col>
          <Col span={24}>
            <Title level={2} className="mb-0 ag-fontSize32">
              Add New Tenant
            </Title>
          </Col>

          <Col span={24}>
            <Card
              bordered={false}
              className="h-auto"
              title={
                <Title level={3} className="mb-0">
                  Tenant Details
                </Title>
              }
            >
              <Row gutter={24}>
                <Col span={8}>
                  <InputField
                    isRequired={true}
                    label="Tenant Type"
                    fieldname="tenant_type"
                    control={control}
                    validate={errors.tenant_type && "error"}
                    validMessage={
                      errors.tenant_type && errors.tenant_type.message
                    }
                    initValue=""
                    iProps={{ placeholder: "Individual" }}
                    rules={{ required: "Please enter type" }}
                  />
                </Col>
                <Col span={8}>
                  <InputField
                    isRequired={true}
                    label="Tenant ID"
                    fieldname="tenant_id"
                    control={control}
                    validate={errors.tenant_id && "error"}
                    validMessage={errors.tenant_id && errors.tenant_id.message}
                    initValue=""
                    iProps={{ placeholder: "Enter ID", type: "number" }}
                    rules={{ required: "Please enter ID" }}
                  />
                </Col>
                <Col span={8}>
                  <InputField
                    isRequired={true}
                    label="Tenant Name"
                    fieldname="tenant_name"
                    control={control}
                    validate={errors.tenant_name && "error"}
                    validMessage={
                      errors.tenant_name && errors.tenant_name.message
                    }
                    initValue=""
                    iProps={{ placeholder: "Enter name" }}
                    rules={{ required: "Please enter name" }}
                  />
                </Col>
                <Col span={8}>
                  <InputField
                    isRequired={true}
                    label="Tenant Surname"
                    fieldname="tenant_surname"
                    control={control}
                    validate={errors.tenant_surname && "error"}
                    validMessage={
                      errors.tenant_surname && errors.tenant_surname.message
                    }
                    initValue=""
                    iProps={{ placeholder: "Enter name" }}
                    rules={{ required: "Please enter surname" }}
                  />
                </Col>
                <Col span={8}>
                  {/* // TODO(REPLACE): THIS SELECT WITH REACT SELECT */}
                  <ReactSelectField
                    isRequired={true}
                    label="Marital Status"
                    fieldname="marital_status"
                    control={control}
                    validate={errors.marital_status && "error"}
                    validMessage={
                      errors.marital_status && errors.marital_status.message
                    }
                    initValue=""
                    selectOption={[{
                      label: "Single",
                      value: 0
                    },
                    {
                      label: "Married",
                      value: 1
                    }
                    ]

                      // <>
                      //   <Select.Option value={0}>Single</Select.Option>
                      //   <Select.Option value={1}>Married</Select.Option>
                      // </>
                    }
                    iProps={{ placeholder: "Enter name" }}
                    rules={{ required: "Please enter surname" }}
                  />
                </Col>
                <Col span={8}>
                  <InputField
                    isRequired={true}
                    label="Employment Sector"
                    fieldname="employment_sector"
                    control={control}
                    validate={errors.employment_sector && "error"}
                    validMessage={
                      errors.employment_sector &&
                      errors.employment_sector.message
                    }
                    initValue=""
                    iProps={{ placeholder: "Enter category" }}
                    rules={{ required: "Please enter category" }}
                  />
                </Col>
                <Col span={8}>
                  <InputField
                    isRequired={true}
                    label="Designation as Per Visa"
                    fieldname="designation"
                    control={control}
                    validate={errors.designation && "error"}
                    validMessage={
                      errors.designation && errors.designation.message
                    }
                    initValue=""
                    iProps={{ placeholder: "Enter designation" }}
                    rules={{ required: "Please enter designation" }}
                  />
                </Col>
                <Col span={8}>
                  {/* // TODO(REPLACE): THIS SELECT WITH REACT SELECT */}
                  <ReactSelectField
                    isRequired={true}
                    label="Income"
                    fieldname="income"
                    control={control}
                    validate={errors.income && "error"}
                    validMessage={errors.income && errors.income.message}
                    initValue=""
                    selectOption={[
                      {
                        label: "5,000 - 10,000",
                        value: 0,
                      },
                      {
                        label: "10,000 - 20,000",
                        value: 1,
                      }]
                      // <>
                      //   <Select.Option value={0}>5,000 - 10,000</Select.Option>
                      //   <Select.Option value={1}>10,000 - 20,000</Select.Option>
                      // </>
                    }
                    iProps={{ placeholder: "Select income" }}
                    rules={{ required: "Please enter income" }}
                  />
                </Col>
                <Col span={8}>
                  <InputField
                    isRequired={true}
                    label="Address"
                    fieldname="address"
                    control={control}
                    validate={errors.address && "error"}
                    validMessage={errors.address && errors.address.message}
                    initValue=""
                    iProps={{ placeholder: "Enter Address" }}
                    rules={{ required: "Please enter Address" }}
                  />
                </Col>
                {/* // TODO(REPLACE): THIS SELECT WITH REACT SELECT */}
                <Col span={8}>
                  <ReactSelectField
                    isRequired={true}
                    label="Country"
                    fieldname="country"
                    control={control}
                    validate={errors.country && "error"}
                    validMessage={errors.country && errors.country.message}
                    initValue=""
                    selectOption={[
                        {
                          label: "United Arab Emirates",
                          value: 0,
                        },
                        {
                          label: "United Kingdom",
                          value: 1,
                        }]
                      // <>
                      //   <Select.Option value={0}>
                      //     United Arab Emirates
                      //   </Select.Option>
                      //   <Select.Option value={1}>United Kingdome</Select.Option>
                      // </>
                    }
                    iProps={{ placeholder: "Select country" }}
                    rules={{ required: "Please enter country" }}
                  />
                </Col>
                <Col span={8}>
                  {/* // TODO(REPLACE): THIS SELECT WITH REACT SELECT */}
                  <ReactSelectField
                    isRequired={true}
                    label="City"
                    fieldname="city"
                    control={control}
                    validate={errors.city && "error"}
                    validMessage={errors.city && errors.city.message}
                    initValue=""
                    selectOption={[
                      {
                        label: "Dubai",
                        value: 0,
                      },
                      {
                        label: "Abu Dhabi",
                        value: 1,
                      }]
                      // <>
                      //   <Select.Option value={0}>Dubai</Select.Option>
                      //   <Select.Option value={1}>Abu Dhabi</Select.Option>
                      // </>
                    }
                    iProps={{ placeholder: "Select city" }}
                    rules={{ required: "Please enter city" }}
                  />
                </Col>
                <Col span={8}>
                  <InputField
                    isRequired={true}
                    label="Mobile number"
                    fieldname="mobile"
                    control={control}
                    validate={errors.mobile && "error"}
                    validMessage={errors.mobile && errors.mobile.message}
                    initValue=""
                    rules={{
                      pattern: {
                        value: /^[0-9]*$/,
                        message: "Value should be a Number",
                      },
                      minLength: { value: 9, message: "Minimum 9 digits" },
                      maxLength: { value: 9, message: "Maximum 9 digits" },
                      required: "Please enter valid Mobile No.",
                      setValueAs: (value) => "+971".concat(value),
                    }}
                    iProps={{ addonBefore: "+971" }}
                  />
                </Col>
                <Col span={8}>
                  <InputField
                    isRequired={true}
                    label="Email Address"
                    fieldname="email"
                    control={control}
                    validate={errors.email && "error"}
                    validMessage={errors.email && errors.email.message}
                    initValue=""
                    iProps={{ placeholder: "Enter email address" }}
                    rules={{ required: "Please enter email address" }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>

          <Col span={24}>
            <Title level={2} className="mb-0 ag-fontSize32">
              Required Documents:
            </Title>
          </Col>
          <Col span={24}>
            <Row gutter={24}>
              <Col span={24}>
                <RequireDoc
                  // popup={onAceptReject}
                  title={"Passport"}
                  // docsData={tenantData.documents?.passport}
                  // disableThis={disableAll}
                  isRejected={false}
                  isContact={false}
                  control={control}
                  errors={errors}
                  fieldName="passport_1"
                  // setDisableFront={setDisableFront}
                  // accept={disableFront}
                  // noUpdate={updateDone}
                  optional={false}
                />
              </Col>
              <Col span={24}>
                <RequireDoc
                  control={control}
                  errors={errors}
                  // popup={onAceptReject}
                  title={"Valid UAE Visa"}
                  // docsData={tenantData.documents?.visa}
                  // disableThis={disableAll}
                  isContact={false}
                  fieldName={"visa_1"}
                  // setDisableFront={setDisableFront}
                  // accept={disableFront}
                  // noUpdate={updateDone}
                  optional={false}
                />
              </Col>

              <Col span={24}>
                <RequireDoc
                  errors={errors}
                  control={control}
                  // popup={onAceptReject}
                  title={"Emirates ID"}
                  // docsData={tenantData.documents?.emirates_id}
                  // disableThis={disableAll}
                  // setDisableFront={setDisableFront}
                  isContact={false}
                  fieldName={"emirates_1"}
                  // accept={disableFront}
                  // noUpdate={updateDone}
                  optional={false}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </DashboardLayout>
  );
};

export default AddTenant;
