import React, { useState, useEffect } from "react";

import AddSubType from "./Modals/AddSubType";
import Panel from "../../../molecules/Panel";
import { Popup } from "../../../atoms/Popup";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Row, Col, Typography, Space, Radio } from "antd";
import AddMaintanence from "./Modals/AddMaintenance";
import UpdateServices from "./Modals/UpdateServices";
import { useDispatch, useSelector } from "react-redux";
import { getMaintenanceTypes, getMaintenanceSubTypes } from "../ducks/actions";

const { Title } = Typography;

const Services = (props) => {
  const dispatch: any = useDispatch();

  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [childDetail, setChildDetails] = useState();
  const [parentID, setParentID] = useState();
  const [parentKey, setParentKey] = useState(0);
  const [childKey, setChildKey] = useState();
  const [childData, setChildData] = useState([]);
  const [subTicketList, setSubTicketList] = useState([]);
  const [categoryRoot, setCategoryRoot] = useState([]);
  const categoryData = useSelector(
    (state: any) => state.configuration.maintenanceTypes
  );

  const [type, setType]: any = useState("RES");

  const [onClose, setOnClose] = useState(false);

  useEffect(() => {
    dispatch(getMaintenanceTypes());
    // setSubTicketList(filterSubData());
  }, []);

  useEffect(() => {
    const residentialCategories = categoryData?.residentialCategories;
    residentialCategories?.length > 0 && setCategoryRoot(residentialCategories)
  }, [categoryData]);

  useEffect(() => {
    setSubTicketList(filterSubData(categoryRoot?.[parentKey]?.subCategories));
  }, [parentKey, categoryRoot]);

  const showModal = (param) => {
    setTrigger(param);
    setVisible(true);
  };

  const filterData = () => {
    if (categoryRoot && categoryRoot?.length > 0)
      return categoryRoot.map((item) => ({
        id: item.categoryId,
        title: item.categoryName,
        subCategories: item.subCategories,
        categoryId: item.categoryId,
      }));
  };

  const onUpdate = () => {
    // setSubTicketList(filterSubData());
  }

  const popup = [
    {
      title: (
        <Title level={3} className="ag-white mb-0">
          Update Maintenance Type
        </Title>
      ),
      // content: (
      //   <UpdateServices
      //     setVisible={setVisible}
      //     childDetail={childDetail}
      //     type="category"
      //   />
      // ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="ag-white mb-0">
          Update Maintenance Sub-Type
        </Title>
      ),
      content: (
        <UpdateServices
          childDetail={childDetail}
          // parentID={parentID}
          categoryType={type}
          parentKey={parentKey}
          setVisible={setVisible}
          // childData={childData}
          childKey={childKey}
          categoryRoot={categoryRoot}
          onUpdate={onUpdate}
          type="subtype"
        />
      ),
    },
    {
      title: (
        <Title level={3} className="ag-white mb-0">
          Add Maintenance Type
        </Title>
      ),
      content: (
        <AddMaintanence
          onClose={onClose}
          setOnClose={setOnClose}
          visible={visible}
          setVisible={setVisible}
          selected={categoryData}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="ag-white mb-0">
          Add Maintenance Sub-Type
        </Title>
      ),
      content: (
        <AddSubType
          onClose={onClose}
          setOnClose={setOnClose}
          setVisible={setVisible}
        // category={filterData()}
        />
      ),
    },
  ];

  const onCancel = () => {
    setOnClose(true);
    setVisible(false);
  };
  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    className: "config-modal-width",
    onCancel: () => onCancel(),
  };

  const filterSubData = (data) => {
    if (data && data?.length > 0)
      return data.map((item) => ({
        id: item.subCategoryId,
        title: item.name,
        // categoryId: "asfsaf",
        timeInMin: item.timeInMin,
        subCategoryId: item.subCategoryId,
      }));
  };



  const getItemDetails = (item, type) => {
    // console.log("ttem",item);
    // if (type === "Maintenance Types") {
    // dispatch(getMaintenanceSubTypes(item?.categoryId));
    // setChildData(item?.subCategories);
    setParentID(item?.categoryId);
    // setSubTicketList(filterSubData(item?.subCategories));
    if (item?.index != null && item?.index != undefined)
      setParentKey(item?.index);
    // filterSubData();
    // }
  };

  const getCurrentSelect = (item) => { setChildDetails(item); setChildKey(item?.index); };


  useEffect(() => {
    // console.log("TYPEE",type,categoryData);
    switch (type) {
      case "RES":
        setCategoryRoot(categoryData?.residentialCategories)
        break;
      case "COM":
        setCategoryRoot(categoryData?.commercialCategories)
        break;
      case "INT":
        setCategoryRoot(categoryData?.internalCategories)
        break;
      default:
        setCategoryRoot(categoryData?.residentialCategories)
    }

    // residentialCategories
    // commercialCategories
    // internalCategories
  }, [type]);

  return (
    <>
      <Row gutter={24}>
        <Col xl={24}>
          <Row gutter={24}>
            <Col span={12}>
              <Radio.Group
                defaultValue="RES"
                buttonStyle="solid"
                onChange={(e) => setType(e.target.value)}
              >
                <Space size={12}>
                  <Radio.Button value="RES">Residential</Radio.Button>
                  <Radio.Button value="COM">Commercial</Radio.Button>
                  <Radio.Button value="INT">Internal</Radio.Button>
                </Space>
              </Radio.Group>
            </Col>
            {/* to merge code: uat */}
            <Col span={12}>
              <Row justify="end" className="mb-1">
                <Button
                  size="large"
                  type="primary"
                  onClick={() => showModal(2)}
                >
                  Add Maintenance Type <PlusOutlined />
                </Button>
              </Row>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              {/* <Row justify="end" className="mb-1">
                <Button
                  size="large"
                  type="primary"
                  onClick={() => showModal(2)}
                >
                  Add Maintenance Type <PlusOutlined />
                </Button>
              </Row> */}
              <Panel
                ticketlist={filterData()}
                setVisible={setVisible}
                setTrigger={setTrigger}
                panelName="maintenance"
                category="Maintenance Types"
                getItemDetails={getItemDetails}
                // getCurrentSelect={getCurrentSelect}
                count={categoryRoot?.length ? categoryRoot?.length : 0}
              />
            </Col>
            <Col span={12}>
              {/* <Row justify="end" className="mb-1">
                <Button
                  size="large"
                  type="primary"
                  onClick={() => showModal(3)}
                >
                  Add Sub-Type <PlusOutlined />
                </Button>
              </Row> */}
              <Panel
                ticketlist={subTicketList}
                setVisible={setVisible}
                setTrigger={setTrigger}
                category="Sub-Types"
                count={subTicketList?.length ? subTicketList?.length : 0}
                getItemDetails={getItemDetails}
                getCurrentSelect={getCurrentSelect}
                panelName="sub_type"
                isAction={true}
              />
            </Col>
            {/* <Col span={8}>
              <Panel ticketlist={data} category="Time Slots" count={6} />
            </Col> */}
          </Row>
        </Col>
      </Row>
      <Popup {...popupProps} />
    </>
  );
};

export default Services;
