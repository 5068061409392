import axios from "../../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import { propertyService } from "../../../../../configs/constants";

const url = `${propertyService}/unit/`;

export const getPropertyUnits = (propertyId, buID?, masterList?) => {

  // console.log(propertyId,'propertyId')
  return async (dispatch) => {

    let finalUrl
    if(propertyId && buID) {
      finalUrl = `${url}unitList?businessUnitId=${buID}&propertyId=${propertyId}&perPage=20000`
    } else if (!propertyId && buID) {
      finalUrl = `${url}unitList?businessUnitId=${buID}&perPage=20000`
    } else if (propertyId && !buID) {
      finalUrl = `${url}unitList?&propertyId=${propertyId}&perPage=20000`
    } else {
      finalUrl = `${url}unitList?perPage=20000`
    }

    if(masterList == true && !propertyId && !buID) { //  && buID
      finalUrl = finalUrl + `&listType=master`
    }

    const {
      data: { result },
    } = await axios.get(finalUrl); //MONGO ID _id (can be done by)
    // getById?propertyId=${propertyId}`); //Property ID
    // console.log("unitList",result)

    dispatch({
      type: action_types.PROPERTY_UNITS,
      data: result?.data,
    });
  };
};


export const getParkingUnits = (propertyId) => {
  return async (dispatch) => {
    const {
      data: { result },
    } = await axios.get(`${url}parkingList/${propertyId}`);
    dispatch({
      type: action_types.PARKING_UNITS,
      data: result,
    });
  };
};

export const emptyPropertyUnits = () => {
  return async (dispatch) => {
      dispatch({
      type: action_types.PROPERTY_UNITS,
      data: [],
    });
  };
};



export const getBookingUnitList = (propertyId) => {
  return async (dispatch) => {
    const {
      data: { result },
    } = await axios.get(`${url}bookingUnitList/${propertyId}`); //MONGO ID _id (can be done by)
    // getById?propertyId=${propertyId}`); //Property ID
    // console.log("bookingUnitList",result)
    dispatch({
      type: action_types.BOOKING_PROPERTY_UNITS,
      data: result,
    });
  };
};

export const getSingleUnit = (unitId) => {
  return async (dispatch) => {
    const {
      data: { result },
    } = await axios.get(`${url}getById?_id=${unitId}`);
    dispatch({
      type: action_types.GET_SINGLE_UNIT,  
      data: result,
    });
  };
};

export const getPaymentPlan = (propertyId) => {
  return async (dispatch) => {
    const {
      data: { result },
    } = await axios.get(`${url}/${propertyId}/payment-plans`);
    dispatch({
      type: action_types.GET_PAYMENT_PLAN,
      data: result,
    });
  };
};
