import React from "react";

const index = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 1.5H13.5C12.6562 1.5 12 2.20312 12 3V21C12 21.8438 12.6562 22.5 13.5 22.5H21C21.7969 22.5 22.5 21.8438 22.5 21V3C22.5 2.20312 21.7969 1.5 21 1.5ZM13.5 0H21C22.6406 0 24 1.35938 24 3V21C24 22.6875 22.6406 24 21 24H13.5C11.8125 24 10.5 22.6875 10.5 21V3C10.5 1.35938 11.8125 0 13.5 0ZM9 6V7.5H3C2.15625 7.5 1.5 8.20312 1.5 9V21C1.5 21.8438 2.15625 22.5 3 22.5H9.23438C9.42188 23.0625 9.75 23.5781 10.125 24H3C1.3125 24 0 22.6875 0 21V9C0 7.35938 1.3125 6 3 6H9ZM4.125 15H6.375C6.98438 15 7.5 15.5156 7.5 16.125V18.375C7.5 19.0312 6.98438 19.5 6.375 19.5H4.125C3.46875 19.5 3 19.0312 3 18.375V16.125C3 15.5156 3.46875 15 4.125 15ZM4.5 18H6V16.5H4.5V18ZM15 18.375V16.125C15 15.5156 15.4688 15 16.125 15H18.375C18.9844 15 19.5 15.5156 19.5 16.125V18.375C19.5 19.0312 18.9844 19.5 18.375 19.5H16.125C15.4688 19.5 15 19.0312 15 18.375ZM16.5 18H18V16.5H16.5V18ZM4.125 9H6.375C6.98438 9 7.5 9.51562 7.5 10.125V12.375C7.5 13.0312 6.98438 13.5 6.375 13.5H4.125C3.46875 13.5 3 13.0312 3 12.375V10.125C3 9.51562 3.46875 9 4.125 9ZM4.5 12H6V10.5H4.5V12ZM15 4.125C15 3.51562 15.4688 3 16.125 3H18.375C18.9844 3 19.5 3.51562 19.5 4.125V6.375C19.5 7.03125 18.9844 7.5 18.375 7.5H16.125C15.4688 7.5 15 7.03125 15 6.375V4.125ZM16.5 4.5V6H18V4.5H16.5ZM16.125 13.5C15.4688 13.5 15 13.0312 15 12.375V10.125C15 9.51562 15.4688 9 16.125 9H18.375C18.9844 9 19.5 9.51562 19.5 10.125V12.375C19.5 13.0312 18.9844 13.5 18.375 13.5H16.125ZM16.5 10.5V12H18V10.5H16.5Z"
        fill="#24435D"
      />
    </svg>
  );
};

export default index;
