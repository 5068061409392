import React, { useEffect, useRef } from "react";

// others
import dayjs from "dayjs";
import { useForm, useWatch } from "react-hook-form";
import { CommonCard } from "../../components";
import { useParams } from "react-router-dom";
import { Row, Col, Form, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  DateField,
  InputField,
  ReactSelectField,
} from "../../../../atoms/FormElement";
import {
  setFormState,
  createBooking,
  updateBooking,
  setBookingForm,
  getBookingDetail,
} from "../../ducks/actions";
import {
  dateFormat,
  lease_nature,
  apiDateFormat,
  datePlaceholder,
  disableMoveInDate,
  handleLeaseEndDate,
  disableRentEndDate,
  disablePreviousDate,
  disableLeaseEndDate,
  filterDropdownValue,
  disableFitOutEndDate,
  disableRentStartDate,
  rentFreePeriodValues,
  disableBookingEndDate,
  disableLeaseStartDate,
  excludedLeaseTermsKeys,
  disableFitOutStartDate,
  datePlaceholderddmmyyFormat,
  parkingPayload
} from "../utils";
import Parkings from "../Parkings";

interface IProps {
  parentState: (obj: object) => void;
}

const index = (props: IProps) => {
  // hooks initialization
  const dispatch: any = useDispatch();
  const leaseEndDate = useRef(null);
  const initialLeaseEndDate = useRef(null);

  // redux states
  const { bookingForm, landlordUsers, bookingDetails } = useSelector(
    (state: any) => state.newBooking
  );

  const { leaseId = bookingDetails.leaseId } = useParams();

  const {
    control,
    trigger,
    setValue,
    setError,
    getValues,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const parkingsWatcher = useWatch({ control, name: "parkings" });

  const isBooking = Object.keys(bookingDetails).length > 0;

  useEffect(() => {
    bookingForm?.parkings?.length > 0 && setValue("parkings", bookingForm?.parkings);
  }, [bookingForm?.parkings]);

  const onSubmit = async (data: any, type) => {

    clearErrors()

    let dataCopy = { ...(data || getValues()) };

    if(dataCopy.numberOfOcupants && !(/^\d+$/.test(dataCopy.numberOfOcupants))) {
      setError('numberOfOcupants', {type: 'manual', message: 'Please enter valid number of ocupants' })
      return
    }

    const rentFreePeriod = parseInt(dataCopy?.rentFreePeriod?.value)

    let body = {
      leaseManager: dataCopy.leaseManager.value,
      numberOfOcupants: dataCopy.numberOfOcupants,
      externalAgent: dataCopy.externalAgent,
      leaseTerms: {
        leaseType: "new",
        leaseNature: dataCopy.leaseNature.value,
        moveInDate: apiDateFormat(dataCopy.moveInDate),
        leaseEndDate: apiDateFormat(dataCopy.leaseEndDate),
        leaseStartDate: apiDateFormat(dataCopy.leaseStartDate),
        fitOutStartDate: apiDateFormat(dataCopy.fitOutStartDate),
        fitOutEndDate: apiDateFormat(dataCopy.fitOutEndDate),
        rentFreeEndDate: rentFreePeriod > 0 ? apiDateFormat(dataCopy.leaseEndDate) : '',
        rentFreeStartDate: rentFreePeriod > 0 ? apiDateFormat(dayjs(initialLeaseEndDate.current).add(1, "day")) : '',
      },
      bookingTerms: {
        ...bookingForm?.bookingTerms,
        bookingEndDate: apiDateFormat(dataCopy.bookingEndDate),
        bookingStartDate: apiDateFormat(dataCopy.bookingStartDate),
      },
      parkings: parkingPayload(dataCopy?.parkings)
    };

    if (type === "next") {
      let valid = true;

      for (let key in dataCopy) {
        if (!excludedLeaseTermsKeys.includes(key) && !dataCopy[key]) {
          valid = false;
          await trigger("", { shouldFocus: true });
          break;
        }
      }

      if (valid) {
        dispatch(setFormState());
        dispatch(setBookingForm(body));
      }
    } else {
      
      body = { ...bookingForm, ...body };

      props.parentState({ load: true });

      try {
        const response = await dispatch(
          !isBooking ? createBooking(body) : updateBooking({ leaseId, body })
        );

        !isBooking && (await dispatch(getBookingDetail(response.data?.result)));

        props.parentState({ load: false });
        message.success(response.data.message);
      } catch (error) {
        props.parentState({ load: false });
        message.error(
          error.response?.data?.error || error.response?.data?.message
        );
      }
    }
  };

  const setLeaseEndDateFun = (e) => {
    // console.log(e);
    leaseEndDate.current = e;
  }

  return (
    <Form
      layout="vertical"
      name="bookingform"
      scrollToFirstError
      className="bf-main"
      onFinish={handleSubmit(onSubmit)}
    >
      <CommonCard
        label="Lease Terms"
        leftBtnLabel="Back"
        rightBtnLabel="Next"
        handleNext={onSubmit}
        parentState={props.parentState}
        handleBack={() => dispatch(setFormState("back"))}
      >
        <Col span={24}>
          <Row gutter={24}>
            <Col span={8}>
              <ReactSelectField
                control={control}
                isRequired={true}
                setValue={setValue}
                label="Lease Executive"
                fieldname="leaseManager"
                selectOption={landlordUsers}
                iProps={{ placeholder: "Select" }}
                rules={{ required: "Please select one" }}
                validate={errors.leaseManager && "error"}
                validMessage={
                  errors.leaseManager && errors.leaseManager.message
                }
                valueGot={filterDropdownValue(
                  bookingForm?.leaseManager?.userId ||
                  bookingForm?.leaseManager,
                  landlordUsers
                )}
              />
            </Col>

            <Col span={8}>
              <InputField
                label="External Agent"
                fieldname="externalAgent"
                control={control}
                initValue=""
                setValue={setValue}
                valueGot={
                  bookingForm?.externalAgent || 'empty'
                }
                iProps={{ placeholder: "Enter External Agent" }}
              />
            </Col>

            <Col span={8}>
              <ReactSelectField
                isRequired={true}
                control={control}
                setValue={setValue}
                label="Lease Nature"
                fieldname="leaseNature"
                selectOption={lease_nature}
                iProps={{ placeholder: "Select" }}
                validate={errors.leaseNature && "error"}
                rules={{ required: "Please select lease nature" }}
                validMessage={errors.leaseNature && errors.leaseNature.message}
                valueGot={filterDropdownValue(
                  bookingForm?.leaseTerms?.leaseNature,
                  lease_nature
                )}
              />
            </Col>

            <Col span={4}>
              <DateField
                isRequired={true}
                control={control}
                setValue={setValue}
                label="Booking Start Date"
                fieldname="bookingStartDate"
                rules={{ required: "Please select date" }}
                validate={errors.bookingStartDate && "error"}
                iProps={{
                  format: datePlaceholderddmmyyFormat,
                  placeholder: datePlaceholderddmmyyFormat,
                  disabledDate: disablePreviousDate,
                }}
                valueGot={dateFormat(
                  bookingForm?.bookingTerms?.bookingStartDate
                )}
                onChange={(date) => {
                  if (date)
                    setValue("bookingEndDate", dayjs(date).add(14, "day"));
                  else setValue("bookingEndDate", "");
                }}
                validMessage={
                  errors.bookingStartDate && errors.bookingStartDate.message
                }
              />
            </Col>

            <Col span={4}>
              <DateField
                control={control}
                isRequired={true}
                setValue={setValue}
                label="Booking End date"
                fieldname="bookingEndDate"
                rules={{ required: "Please select date" }}
                validate={errors.bookingEndDate && "error"}
                iProps={{
                  format: datePlaceholderddmmyyFormat,
                  placeholder: datePlaceholderddmmyyFormat,
                  disabledDate: (date) =>
                    disableBookingEndDate(
                      date,
                      getValues("bookingStartDate"),
                      getValues("bookingEndDate")
                    ),
                }}
                valueGot={dateFormat(bookingForm?.bookingTerms?.bookingEndDate)}
                validMessage={
                  errors.bookingEndDate && errors.bookingEndDate.message
                }
              />
            </Col>

          </Row>

          {/* second row */}
          <Row gutter={24}>
            <Col span={4}>
              <DateField
                // isRequired={true}
                control={control}
                setValue={setValue}
                label="Fit-out Start Date"
                fieldname="fitOutStartDate"
                // rules={{ required: "Please select date" }}
                // validate={errors.fitOutStartDate && "error"}
                iProps={{
                  format: datePlaceholderddmmyyFormat,
                  placeholder: datePlaceholderddmmyyFormat,
                  disabledDate: (date) =>
                    disableFitOutStartDate(date,
                      undefined,
                      getValues("leaseStartDate"),
                      getValues("bookingStartDate")
                    ),
                }}
                valueGot={dateFormat(
                  bookingForm?.leaseTerms?.fitOutStartDate
                )}
              // onChange={(date) => {
              //   if (date)
              //     setValue("bookingEndDate", dayjs(date).add(14, "day"));
              //   else setValue("bookingEndDate", "");
              // }}
              // validMessage={
              //   errors.fitOutStartDate && errors.fitOutStartDate.message
              // }
              />
            </Col>

            <Col span={4}>
              <DateField
                control={control}
                // isRequired={true}
                setValue={setValue}
                label="Fit-out End date"
                fieldname="fitOutEndDate"
                // rules={{ required: "Please select date" }}
                // validate={errors.fitOutEndDate && "error"}
                onChange={(date) => {
                  if (date) {
                    setValue(
                      "leaseStartDate",
                      dayjs(date).add(1, "day")
                    );
                    setValue('moveInDate', dayjs(date).add(1, "day"))
                  }
                  else setValue("leaseStartDate", "");
                }}
                iProps={{
                  format: datePlaceholderddmmyyFormat,
                  placeholder: datePlaceholderddmmyyFormat,
                  disabledDate: (date) =>
                    disableFitOutEndDate(date,
                      undefined,
                      getValues("leaseStartDate"),
                      getValues("fitOutStartDate")
                    ),
                }}
                valueGot={dateFormat(bookingForm?.leaseTerms?.fitOutEndDate)}
              // validMessage={
              //   errors.fitOutEndDate && errors.fitOutEndDate.message
              // }
              />
            </Col>

{/* 
            <Col span={4}>
              <DateField
                control={control}
                setValue={setValue}
                label="Rent Free Start Date"
                fieldname="rentFreeStartDate"
                iProps={{
                  format: datePlaceholderddmmyyFormat,
                  placeholder: datePlaceholderddmmyyFormat,
                  disabledDate: (date) =>
                    disableRentStartDate(date, getValues("leaseEndDate"), getValues("rentFreeEndDate")),
                }}
                valueGot={dateFormat(bookingForm?.leaseTerms?.rentFreeStartDate)}
              />
            </Col>

            <Col span={4}>
              <DateField
                control={control}
                setValue={setValue}
                label="Rent Free End Date"
                fieldname="rentFreeEndDate"
                onChange={(e) => { 
                  if(e) setValue('leaseEndDate', e)
                }}
                iProps={{
                  format: datePlaceholderddmmyyFormat,
                  placeholder: datePlaceholderddmmyyFormat,
                  disabledDate: (date) =>
                    disableRentEndDate(date, getValues("leaseEndDate")),
                }}
                valueGot={dateFormat(bookingForm?.leaseTerms?.rentFreeEndDate)}
              />
            </Col> */}

            <Col span={4}>
              <DateField
                control={control}
                isRequired={true}
                setValue={setValue}
                label="Lease Start Date"
                fieldname="leaseStartDate"
                validate={errors.leaseStartDate && "error"}
                rules={{
                  required: "Enter lease start date",
                }}
                onChange={(date) => {
                  if (date) {
                    setValue(
                      "leaseEndDate",
                      dayjs(date).add(1, "year").subtract(1, "day")
                    );
                    setValue('moveInDate', date)
                    initialLeaseEndDate.current = null
                  }
                  else setValue("leaseEndDate", "");
                  setValue("rentFreePeriod", null);
                }}
                iProps={{
                  format: datePlaceholderddmmyyFormat,
                  placeholder: datePlaceholderddmmyyFormat,
                  disabledDate: (date) =>
                    disableLeaseStartDate(
                      date,
                      undefined,
                      getValues("leaseStartDate"),
                      getValues("bookingStartDate")
                    ),
                }}
                valueGot={dateFormat(bookingForm?.leaseTerms?.leaseStartDate)}
                validMessage={
                  errors.leaseStartDate && errors.leaseStartDate.message
                }
              />
            </Col>

            <Col span={4}>
              <DateField
                control={control}
                isRequired={true}
                setValue={setValue}
                label="Lease End Date"
                fieldname="leaseEndDate"
                validate={errors.leaseEndDate && "error"}
                rules={{
                  required: "Enter lease end date",
                }}
                onChange={(date) => {
                  if(date) {
                    setLeaseEndDateFun(date)
                    initialLeaseEndDate.current = null
                  }
                  setValue("rentFreePeriod", null);
                  // handleLeaseEndDate(  
                  //   date,
                  //   getValues("leaseStartDate"),
                  //   setValue
                  // )
                }
                }
                iProps={{
                  format: datePlaceholderddmmyyFormat,
                  placeholder: datePlaceholderddmmyyFormat,
                  disabledDate: (date) =>
                    disableLeaseEndDate(date, getValues("leaseStartDate")),
                }}
                valueGot={dateFormat(bookingForm?.leaseTerms?.leaseEndDate)}
                validMessage={
                  errors.leaseEndDate && errors.leaseEndDate.message
                }
              />
            </Col>

            <Col span={4}>
              <ReactSelectField
                control={control}
                setValue={setValue}
                label="Rent Free Period"
                fieldname="rentFreePeriod"
                selectOption={rentFreePeriodValues()}
                onChange={(e) => {
                  if(!initialLeaseEndDate.current) initialLeaseEndDate.current = getValues("leaseEndDate")
                  setValue("leaseEndDate", dayjs(initialLeaseEndDate.current || getValues("leaseEndDate")).add(parseInt(e.value), "days"));
                }}
                iProps={{ placeholder: "Select" }}
                valueGot={bookingForm?.leaseTerms?.rentFreeStartDate && bookingForm?.leaseTerms?.rentFreeEndDate && filterDropdownValue(
                 String(dayjs(bookingForm?.leaseTerms?.rentFreeEndDate).diff(dayjs(bookingForm?.leaseTerms?.rentFreeStartDate), 'days') + 1),
                  rentFreePeriodValues()
                )}
              />
            </Col>
          </Row>

          {/* third row */}
          <Row gutter={24}>
            <Col span={4}>
              <DateField
                control={control}
                isRequired={true}
                setValue={setValue}
                label="Move-In Date"
                fieldname="moveInDate"
                rules={{ required: "Enter Date" }}
                validate={errors?.moveInDate && "error"}
                valueGot={dateFormat(bookingForm?.leaseTerms?.moveInDate)}
                validMessage={errors?.moveInDate && errors?.moveInDate.message}
                iProps={{
                  format: datePlaceholderddmmyyFormat,
                  placeholder: datePlaceholderddmmyyFormat,
                  disabledDate: (date) =>
                    disableMoveInDate(
                      date,
                      getValues("leaseStartDate"),
                      getValues("bookingStartDate")
                    ),
                }}
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24} style={{ marginTop: "20px" }}>
              <p className="ft-main-label">Parking</p>
              <Parkings data={parkingsWatcher} setParkingValue={(data) => setValue("parkings", data)} />
            </Col>
          </Row>
        </Col>
      </CommonCard>
    </Form>
  );
};

export default index;
