import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Typography, message, Space, Spin } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  DateField,
  InputField,
  ReactSelectField,
  SelectField,
} from "../../../../../atoms/FormElement";
import {
  getAllAreas,
  getAreaStatus,
  getListZones,
  getZoneData,
} from "../../ducks/actions";
import moment from "moment";
import { maintenanceService } from "../../../../../../configs/constants";
import axios from "../../../../../../utils/axiosInceptor";

var _ = require("lodash");

const defaultValues: any = {
  zone_name: "",
  region_name: "",
  date_of_addition: "",
  zone_status_type_name: "",
};

const { Paragraph } = Typography;

const AddZone = (props) => {
  const dispatch: any = useDispatch();
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [propertyList, setPropertyList] = useState([]);
  const areaApi = []; //useSelector((state) => state.zones.areasData);
  const areastatusApi = []; // useSelector((state) => state.zones.statusData);
  const buildingApi = useSelector((state: any) => state.zones.buildingData);
  const {
    control,
    formState: { errors },
    reset,
    setValue,
    handleSubmit,
  } = useForm({ defaultValues });

  // const propertyList = buildingApi?.properties;
  const technicianList = buildingApi?.techinician;

  const orgId = JSON.parse(
    window.localStorage.getItem("login-response")
  )?.orgId;
  const zoneId = props?.data?.zoneId;

  useEffect(() => {
    // dispatch(getAllAreas());
    // dispatch(getAreaStatus());
    dispatch(getZoneData());
    setPropertyList(buildingApi?.properties);
  }, []);

  useEffect(() => {
    setPropertyList(buildingApi?.properties);
  }, [buildingApi]);

  useEffect(() => {
    if (props.data) {
      setValue("name", props.data.name, { shouldValidate: true });
      setValue("description", props.data.description);
      if (props.data?.properties.length > 0) {
        const selectedProperties = _.map(props.data.properties, (item) => {
          return { label: item.name, value: item.propertyId };
        });
        setValue("properties", selectedProperties, { shouldValidate: true });
      }
      if (props.data?.technicians.length > 0) {
        const selectedTechnicians = _.map(props.data.technicians, (item) => {
          return { label: item.name, value: item.userId };
        });
        setValue("technicians", selectedTechnicians, { shouldValidate: true });
      }
    } else {
      reset();
    }
  }, [props.data]);

  const onFinish = async (values) => {
    reset();
    setLoader(true);
    setSubmited(true);

    const {
      name,
      description,
      // status,
    } = values;

    let postJson = {};
    if (props.editMode == true && props.data && zoneId) {
      // UPDATE ZONE -------------------------------------------

      postJson = {
        // name,
        // status: status.value,
        description,
        property: values.properties.map((item) => {
          return { propertyId: item.value, name: item.label };
        }),
        technicians: values.technicians.map((item) => {
          return { userId: item.value, name: item.label };
        }),
      };

      const url = `${maintenanceService}/zone/${zoneId}`;

      try {
        const resp = await axios.patch(`${url}`, postJson);
        setTimeout(() => {
          reset();
          setLoader(false);
          // dispatch(getListZones());
          message.success(resp?.data?.message);
          setSubmited(false);
          props.onSubmit();
        }, 1000);
      } catch (e) {
        setLoader(false);
        setSubmited(false);
        // setisError(true);
        const { response } = e;
        // setErrorMessage(response?.data?.error);
        message.error(response?.data?.error);
      }
    } else {
      // ADD ZONE -------------------------------------------
      postJson = {
        name,
        orgId: orgId ?? "",
        // status: status.value,
        description,
        property: values.properties.map((item) => {
          return { propertyId: item.value, name: item.label };
        }),
        technicians: values.technicians.map((item) => {
          return { userId: item.value, name: item.label };
        }),
      };

      const url = `${maintenanceService}/zone`;
      try {
        const resp = await axios.post(`${url}`, postJson);
        setTimeout(() => {
          reset();
          setLoader(false);
          // dispatch(getListZones());
          message.success(resp?.data?.message);
          setSubmited(false);
          props.onSubmit();
        }, 1000);
      } catch (e) {
        setLoader(false);
        setSubmited(false);
        // setisError(true);
        const { response } = e;
        // setErrorMessage(response?.data?.error);
        message.error(response?.data?.error);

      }
    }
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
              {/* <Paragraph className="ag-default fontWeight600 mb-0">Loading</Paragraph> */}
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
          <Row gutter={24}>
            <Col span={12}>
              <InputField
                isRequired={true}
                fieldname="name"
                label="Name of Zone"
                control={control}
                rules={{
                  required: "Please enter zone name",
                }}
                iProps={{ placeholder: "Al Barsha" }}
                initValue=""
                disabled={props.editMode}
                validate={errors.name && "error"}
                validMessage={errors.name && errors.name.message}
              />
            </Col>
            <Col span={12}>
              <InputField
                isRequired={false}
                fieldname="description"
                label="Description of Zone"
                control={control}
                // rules={{
                //   required: "Please enter zone description",
                // }}
                iProps={{ placeholder: "describe zone" }}
                initValue=""
                // validate={errors.description && "error"}
                // validMessage={errors.description && errors.description.message}
              />
            </Col>
            {/* <Col span={12}>
              <SelectField
                isRequired={true}
                fieldname="region_name"
                label="Area"
                control={control}
                iProps={{ placeholder: "Select Area" }}
                rules={{ required: "Please select area" }}
                initValue=""
                selectOption={
                  areaApi.length > 0 &&
                  areaApi.map((item) => ({ value: item.id, label: item.name }))
                }
                validate={errors.region_name && "error"}
                validMessage={errors.region_name && errors.region_name.message}
              />
            </Col> */}
            {/* <Col span={12}>
              <DateField
                isRequired={true}
                fieldname="date_of_addition"
                label="Date of Activation"
                control={control}
                iProps={{ placeholder: "DD-MM-YYYY", format: "DD-MM-YYYY" }}
                rules={{
                  required: "Please enter date",
                  setValueAs: (value) => moment(value).format("YYYY-MM-DD"),
                }}
                initValue=""
                validate={errors.date_of_addition && "error"}
                validMessage={
                  errors.date_of_addition && errors.date_of_addition.message
                }
              />
            </Col> */}
            {/* <Col span={12}>
            <ReactSelectField
                isRequired={true}
                fieldname="status"
                label="Status"
                control={control}
                iProps={{ placeholder: "Select Status" }}
                rules={{ required: "Please select status" }}
                initValue=""
                selectOption={[{value:"enable",label:"Enable"},{value:"disable",label:"Disable"}]}
                // selectOption={
                //   areastatusApi.length > 0 &&
                //   areastatusApi.map((item) => ({
                //     value: item.id,
                //     label: item.name,
                //   }))
                // }
                validate={errors.zone_status_type_name && "error"}
                validMessage={
                  errors.zone_status_type_name &&
                  errors.zone_status_type_name.message
                }
              />
            </Col> */}
            <Col span={12}>
              <ReactSelectField
                isRequired={true}
                fieldname="properties"
                label="Assign buildings"
                control={control}
                iProps={{ placeholder: "Select buildings", isMulti: true }}
                rules={{ required: "Please select buildings" }}
                initValue=""
                selectOption={propertyList?.map((item) => ({
                  value: item.propertyId,
                  label: item.buildingName,
                }))}
                validate={errors.properties && "error"}
                validMessage={errors.properties && errors.properties.message}
                onChange={() => {

                  dispatch(getZoneData());
                }}
              />
            </Col>
            <Col span={12}>
              <ReactSelectField
                isRequired={true}
                fieldname="technicians"
                label="Assign technicians"
                control={control}
                iProps={{ placeholder: "Select technicians", isMulti: true }}
                rules={{ required: "Please select technicians" }}
                initValue=""
                selectOption={technicianList?.map((item) => ({
                  value: item.userId,
                  label: item.name,
                }))}
                validate={errors.technicians && "error"}
                validMessage={errors.technicians && errors.technicians.message}
              />
            </Col>
          </Row>
          <Row gutter={24} justify="space-between">
            <Col>
              <Button
                className="ag-gray-button-outline"
                htmlType="button"
                onClick={props.onCancel}
              >
                Back
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button" htmlType="submit">
                {props?.editMode == false ? "Add" : "Update"}
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0 sentanceCase">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    setSubmited(false);
                    setisError(false);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
    </Form>
  );
};

export default AddZone;
