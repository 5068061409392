import React from "react";

const index = () => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.625 15.5H15.625" stroke="#24435D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.625 11V3.5" stroke="#24435D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.625 11L12.625 8" stroke="#24435D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.625 11L6.625 8" stroke="#24435D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
                
    );
};

export default index;
