import axios from "../../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import { authService, maintenanceService } from "../../../../../configs/constants";
const zonelistUrl = `${maintenanceService}/facilityManager/fetchZones`;
const getTechniciansUrl = `${maintenanceService}/serviceprovider/technician`;
const url = `${maintenanceService}/facilityManager/fetchTechnicianProperties`;
const category = `${maintenanceService}/serviceprovider/detail`;

export const getAllZones = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(zonelistUrl);
      dispatch({
        type: action_types.GET_ZONES_LIST,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getAllTechnicians = (page, limit, search) => {
  return async (dispatch) => { 
    const url = `${getTechniciansUrl}?perPage=${limit}&page=${page}${
      search ? `&search=${search}` : ""
    }`;
    try {
      const {
        data: { result },
      } = await axios.get(url);
      dispatch({
        type: action_types.GET_TECH_LIST,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const invite = async (payload, message) => {
  try {
    const url = `${authService}/user/sendInvite`;
    const { status } = await axios.post(url, payload, {
      headers: { application: 4 },
    });
    
    return status;

  } catch (error) {}
};

export const getMaintananceCategories = (page = 1) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(`${maintenanceService}/maintenance/category`);
      
      dispatch({
        type: action_types.GET_MAINTANANCE_CATEGORIES,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getNewMaintananceCategories = (page = 1) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(`${maintenanceService}/serviceprovider/detail`);
      
      dispatch({
        type: action_types.GET_NEW_MAINTANANCE_CATEGORIES,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getTechniciansForServiceProviders = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(`${maintenanceService}/maintenance/technician/dropdown`);
      
      dispatch({
        type: action_types.GET_SP_TECHNICIANS,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

// export const getTechProperties = (id) => {
//   return async (dispatch) => {
//     try {
//       const {
//         data: { result },
//       } = await axios.get(`${url}?technicianId=${id}`);
//       dispatch({
//         type: action_types.GET_TECH_PROPERTY,
//         data: result,
//       });
//     } catch (e) {
//       console.log("Err", e);
//     }
//   };
// };
