import React, { useState } from 'react';
import { Tag } from 'antd';

const TruncatedTags = ({ tags, maxVisibleTags }) => {
//   const [showAll, setShowAll] = useState(false);

  const visibleTags =  tags.slice(0, maxVisibleTags); //showAll ? tags :

  return (
    <div className="tag-container">
      {visibleTags.map((tag, index) => (
        <Tag className="tag-sm" key={index}>{tag}</Tag>
      ))}
      { tags.length > maxVisibleTags && ( //!showAll &&
        <Tag className="tag-sm more">+ {tags.length - maxVisibleTags} More</Tag> //onClick={() => setShowAll(true)}
      )}
    </div>
  );
};

export default TruncatedTags;