import * as action_types from "./constants";

const initialState = {
  unitsList: {
    count: 0,
    results: [],
  },
  prospectList: {
    count: 0,
    results: [],
  },
  unitsFilters: {},
};

export default (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {
    case action_types.GET_UNITS:
      return { ...state, unitsList: data };
    case action_types.GET_PROSPECTS:
      return { ...state, prospectList: data };
    case action_types.EMPTY_TENANTS:
      return { ...state, unitsList: data };
    case action_types.SET_UNIT_FILTERS:
      return { ...state, unitsFilters: data };
    default:
      return state;
  }
};
