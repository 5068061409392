import React from "react";

const index = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="ft-next-icon"
    >
      <path
        d="M9.25 6.5L15.25 12.5L9.25 18.5"
        stroke="#8B95A2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default index;
