import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Divider,
  Space,
  Typography,
  message,
  Spin,
} from "antd";
import { useForm, useWatch, Controller } from "react-hook-form";
import {
  InputField,
  SelectField,
  DateField,
  ReactSelectField,
} from "../../../../atoms/FormElement";
import { updatePDCStatus } from "../../ducks/actions";
import { PlusCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { maintenanceService } from "../../../../../configs/constants";
import axios from "../../../../../utils/axiosInceptor";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";

var _ = require("lodash");
const { Text } = Typography;

const defaultVal = {
  status: { value: 'pending', label: 'Pending' },
  paymentMode: "",
  paymentRef: "",
  bankName: "",
  reason: { value: 'signature_mismatch ', label: 'Signature mismatch' },
  paymentStatus: { value: 'pending', label: 'Pending' },
  paymentModeType: { value: 'cheque', label: 'Cheque' }
};

const { Paragraph } = Typography;

const visibilityOptions = [
  { value: 'pending', label: 'Pending' },
  { value: 'paid', label: 'Paid' },
  { value: 'bounce', label: 'Bounce' },
  { value: 'cancelled', label: 'Cancelled' },
]

const paymentModeOptions = [
  { value: 'cheque', label: 'Cheque' },
  { value: 'cash', label: 'Cash' },
  { value: 'bank_transfer', label: 'Bank Transfer' },
  { value: 'credit_card', label: 'Credit Card' },
  { value: 'directDebit', label: 'Direct Debit' },
]

const bouncedReasons = [
  { value: 'select', label: 'Select' },
  { value: 'signature_mismatch ', label: 'Signature mismatch' },
  { value: 'insufficient_funds', label: 'Insufficient funds' },
  { value: 'damaged_cheque', label: 'Damaged cheque' },
  { value: 'overwriting_on_cheque ', label: 'Overwriting on cheque ' },
  { value: 'incorrect_amount', label: 'Incorrect amount' },
]

const ModifyPaymentStatus = (props) => {
  const dispatch: any = useDispatch();

  const { data } = props;
  const isNew = data.isNew;
 console.log( data?.params?.data?.modeOfPayment,' data?.params?.data?.modeOfPayment')
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [chequeType, setCheque] = useState(null);
  const [paymentMode, setPaymentMode] = useState(null);

  const { control, handleSubmit, formState: { errors }, reset, setValue, getValues, setError } = useForm({
    defaultValues: defaultVal,
  });

  const status = getValues()?.status?.value
  const paymentRef = getValues()?.paymentRef
  const bankName = getValues()?.bankName
  const reason = getValues()?.reason?.value

  const onBack = () => {
    reset();
    props.onCancel();
  };

  useEffect(() => {
    if(isNew) {
      setValue(
        "paymentStatus",
        { value: data?.params?.data?.chequeStatus, label: visibilityOptions.find(option => option.value?.toLowerCase() === data?.params?.data?.chequeStatus?.toLowerCase())?.label },
        { shouldValidate: true, }
      )
      setValue(
        "paymentModeType",
        { value: data?.params?.data?.modeOfPayment, label: paymentModeOptions.find(option => option.value?.toLowerCase() === data?.params?.data?.modeOfPayment?.toLowerCase())?.label },
        { shouldValidate: true, }
      )
      setCheque(data?.params?.data?.chequeStatus?.toLowerCase())
      setPaymentMode(data?.params?.data?.modeOfPayment?.toLowerCase())
    } else {
      setValue("status", data.status, { shouldValidate: true });
      setValue(
        "status",
        { value: data.status, label: visibilityOptions.find(option => option.value === data.status)?.label },
        { shouldValidate: true, }
      );
      setValue("paymentMode", data.paymentMode, { shouldValidate: true });
      setValue("paymentRef", data?.params?.data?.documentNo, { shouldValidate: true });
      setValue("bankName", data?.params?.data?.bankName, { shouldValidate: true });
      setValue("reason", 
      data.reason ? { value: data.reason, label: bouncedReasons.find(option => option.value === data.reason)?.label } : bouncedReasons[0]
      , { shouldValidate: true });
    }
  }, [data])

  useEffect(() => {
    return () => {
      reset();
    }
  }, [])

  const onFinish = async (values) => {

    if(status === 'bounce') {
      let returnStatus = false;
      if(!values?.paymentRef) {
        setError('paymentRef', {type: 'manual', message: 'Please enter Payment Ref' })
        returnStatus = true
      }
      if(!values?.bankName) {
        setError('bankName', {type: 'manual', message: 'Please enter Bank Name' })
        returnStatus = true
      }
      if(values?.reason?.value === 'select') {
        setError('reason', {type: 'manual', message: 'Please select the reason' })
        returnStatus = true
      }

      if(returnStatus) return
    }
    
    if(status === 'paid') {
      if(!values?.paymentRef) {
        setError('paymentRef', {type: 'manual', message: 'Please enter Payment Ref' })
        if(values?.bankName) return
      }
      if(!values?.bankName) {
        setError('bankName', {type: 'manual', message: 'Please enter Bank Name' })
        return
      }
    }

    if(status === 'cancelled') {
      if(values?.reason?.value === 'select') {
        setError('reason', {type: 'manual', message: 'Please select the Reason' })
        return
      }
    }

    setLoader(true);
    setSubmited(true);

    let payload;
    if(status === 'bounce') {
      payload = {
        chequeStatus: values?.status?.value,
        documentNo: values?.paymentRef,
        bankName: values?.bankName,
        reason: values?.reason?.value,
        penaltyFee: values?.bouncedChequeFee,
        penaltyPaymentMode: values?.bouncedChequePaymentMode?.value
      }
    } else {
      payload = {
        chequeStatus: values?.status?.value,
        documentNo: values?.paymentRef,
        bankName: values?.bankName,
        reason: values?.reason?.value,
      }
    }

    if(isNew) {
      if((values?.paymentModeType?.value === 'cheque') && 
      (values?.paymentStatus?.value === 'bounce' || values?.paymentStatus?.value === 'cancelled' || values?.paymentStatus?.value === 'paid')) {
        setLoader(false);
        setSubmited(false);
        props.openModalAction({paymentMode: values?.paymentModeType?.value, status: values?.paymentStatus?.value , params: data?.params})
        return
      }
      payload = {
        modeOfPayment: values?.paymentModeType?.value,
        chequeStatus: values?.paymentStatus?.value
      }
    }

    try {
      await updatePDCStatus(
        {
          leaseId: data?.params?.data?.leaseId,
          pdcId: data?.params?.data?._id,
        },
        payload
      );

      reset();
      setLoader(false);
      message.success("Status updated successfully");
      setSubmited(false);
      props.onUpdate();

    } catch (error) {
      setLoader(false);
      setisError(true);
      const { response } = error;
      setErrorMessage(response?.data?.error);
    }
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
              {/* <Paragraph className="ag-default fontWeight600 mb-0">Loading</Paragraph> */}
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
        {
          !isNew ? (
            <Row gutter={24}>
              <Col span={12}>
                <ReactSelectField
                  fieldname="status"
                  label="Status"
                  disabled={true}
                  control={control}
                  iProps={{ placeholder: "Select status", isDisabled: true}}
                  // selectOption={visibilityOptions}
                  // validate={errors.status && "error"}
                  // validMessage={errors.status && errors.status.message}
                />
              </Col>
              <Col span={12}>
                <InputField
                  fieldname="paymentMode"
                  label="Payment Mode"
                  control={control}
                  iProps={{ placeholder: "Enter Payment Mode" }}
                  initValue=""
                  disabled={true}
                />
              </Col>
              {
                (status !== 'cancelled') && <Col span={12}>
                  <InputField
                    fieldname="paymentRef"
                    label="Payment Ref"
                    control={control}
                    // isRequired={!checkBankName}
                    iProps={{ placeholder: "Enter Payment Ref" }}
                    initValue=""
                    // rules={!checkBankName ? {
                    //   required: "Please enter Payment Ref",
                    // } : {}}
                    validate={errors.paymentRef && "error"}
                    validMessage={errors.paymentRef && errors.paymentRef.message}
                  />
                </Col>
              }
              {
                (status !== 'cancelled') &&
                <Col span={12}>
                  <InputField
                    fieldname="bankName"
                    label="Bank Name"
                    control={control}
                    // isRequired={!checkPaymentRef}
                    iProps={{ placeholder: "Enter Bank Name" }}
                    initValue=""
                    // rules={!checkPaymentRef ? {
                    //   required: "Please enter Bank Name",
                    // } : {}}
                    validate={errors.bankName && "error"}
                    validMessage={errors.bankName && errors.bankName.message}
                  />
                </Col>
              }

              {/* {
                (status === 'bounce') && <Divider plain>OR</Divider>
              } */}

              {
                (status !== 'paid') && 
                  <Col span={12}>
                    <ReactSelectField
                      // isRequired={!checkPaymentRef && !checkBankName}
                      fieldname="reason"
                      label="Reason"
                      control={control}
                      iProps={{ placeholder: "Select reason"}}
                      // rules={!checkPaymentRef && !checkBankName ? {
                      //   required: "Please select reason",
                      // } : {}}
                      selectOption={bouncedReasons}
                      validate={errors.reason && "error"}
                      validMessage={errors.reason && errors.reason.message}
                    />
                  </Col>
              }
              {
                (status !== 'paid') && <Col span={12}></Col> 
              }
              
              {
                (status !== 'paid' && status === 'bounce') && 
                <Col span={12}>
                  <InputField
                    fieldname="bouncedChequeFee"
                    label="Bounced Cheque Fee"
                    control={control}
                    iProps={{ placeholder: "Bounced Cheque Fee", type: "number", }}
                    initValue=""
                  />
                </Col>
              }
              {
                (status !== 'paid' && status === 'bounce') && 
                <Col span={12}>
                  <ReactSelectField
                    fieldname="bouncedChequePaymentMode"
                    label="Bounced Cheque Payment Mode"
                    control={control}
                    iProps={{ placeholder: "Enter Payment Mode" }}
                    selectOption={paymentModeOptions}
                  />
                </Col>
              }
            </Row>
          ) : (
            <Row gutter={24}>
              <Col span={12}>
                <ReactSelectField
                  fieldname="paymentModeType"
                  label="Payment Mode"
                  control={control}
                  iProps={{ placeholder: "Select payment mode", isDisabled: data?.params?.data?.chequeStatus === 'paid'}}
                  selectOption={paymentModeOptions}
                  onChange={(e) => {
                    if(e) setPaymentMode(e.value)
                  }}
                />
              </Col>
              <Col span={12}>
                <ReactSelectField
                  fieldname="paymentStatus"
                  label="Payment Status"
                  selectOption={ paymentMode === 'cheque' ? 
                      chequeType === 'bounce' ? 
                      [{value: "paid", label: "Paid"}, {value: "cancelled", label: "cancelled"}] : 
                    visibilityOptions : 
                    [{value: "pending", label: "Pending"}, {value: "paid", label: "Paid"}, {value: "cancelled", label: "cancelled"}]
                  }
                  control={control}
                  iProps={{ placeholder: "Select payment status", isDisabled: data?.params?.data?.chequeStatus === 'paid'}}
                />
              </Col>
            </Row>
          )
        }
          <Row gutter={24} justify="space-between">
            <Col>
              <Button className="ag-gray-button-outline" onClick={onBack}>
                Back
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button" htmlType="submit">
                {status === 'paid' ? 'Confirm payment' : 'Update'}
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    setSubmited(false);
                    setisError(false);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
    </Form>
  );
};

export default ModifyPaymentStatus;