import React, { useState, useEffect } from "react";
import DashboardLayout from "../../molecules/DashboardLayout";
import {
  Row,
  Col,
  Pagination,
  Card,
  Spin,
  Form,
  Space,
  Select,
  Input,
  MenuProps,
  Typography
} from "antd";
import moment from "moment";
import SectionHeader from "../../molecules/SectionHeader";
import Grid from "../../atoms/Grid";
import { useDispatch, useSelector } from "react-redux";
import { getNotices } from "./ducks/actions";
import { RightArrow } from "../../atoms/Icons";
import { useNavigate } from "react-router-dom";
import { FormOutlined,SendOutlined, FilterOutlined, MailOutlined, ClockCircleOutlined, EyeOutlined, EditOutlined, CopyOutlined, DeleteOutlined, CheckSquareOutlined, FieldTimeOutlined } from "@ant-design/icons";
import { maintenanceService } from "../../../configs/constants";
import Title from "antd/es/typography/Title";
import { Popup } from "../../atoms/Popup";
import ModifyNotice from "./components/ModifyNotice";
import { BsBuilding } from "react-icons/bs";
import e from "express";
import { getPropertiesDrop } from "../Properties/ducks/actions";
import { emptyPropertyUnits, getPropertyUnits } from "../Properties/PropertyDetail/ducks/actions";
import axios from "../../../utils/axiosInceptor";
import { useSearchHistory } from "../../../utils/filterHelper";
import { setFilterTenant } from "../App/SearchFilters/ducks/actions";

const { Text } = Typography;

const gridProps = {
  sortable: true,
  filter: true,
  resizable: true,
  minWidth: 200,
};

const limit = 20;

const searchKeys = [{ value: "name", label: "Name", default: true },
{ value: "phone", label: "Phone" },
{ value: "email", label: "Email" }];

export default (props) => {
  const dispatch: any = useDispatch();
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const noticeApi = useSelector((state: any) => state.notices.noticeList);
  const [updateValue, setUpdateValue] = useState({});
  
  const Filter = useSelector((state: any) => state.filters.tenantsData);

  const [search, setSearch] = useState(Filter[Object.keys(Filter)[0]] ?? "");
  const [status, setStatus] = useState(null);

  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [loading, setLoading] = useState(false);
  const [viewNoticeAction, setAction] = useState(null);
  const [viewNoticeData, setNotice] = useState(null);

  useEffect(() => {
    dispatch(getPropertiesDrop());
  }, []);

  
  const getSentCount = (status, notice) => {
    let statusCount = ''
    switch(status) {
      case 'scheduled': {
        if(moment(`${notice.item.deliveryDate} ${notice.item.deliveryTime}`, 'YYYY-MM-DD HH:mm').isBefore(moment())) {
          statusCount = notice.sentCount
        }
      }
        break;
      default:
        statusCount
    }

    return statusCount
  }

  const getStatus = (status, notice) => {
    let statusUI = <></>
    switch(status) {
      case 'draft':
        statusUI = <Text style={{padding: 10, borderRadius: 5 , backgroundColor: 'grey', color: 'white'}}>Draft</Text>
        break;
      case 'pending':
      case 'scheduled': {
        if(moment(`${notice.item.deliveryDate} ${notice.item.deliveryTime}`, 'YYYY-MM-DD HH:mm').isBefore(moment())) {
          statusUI = <Text style={{padding: 10, borderRadius: 5 , backgroundColor: 'green', color: 'white'}}>Sent</Text>
        } else if (status === 'pending') {
          statusUI = <Text style={{padding: 10, borderRadius: 5 , backgroundColor: 'yellow', color: 'black'}}>Pending</Text>
        } else {
          statusUI = <Text style={{padding: 10, borderRadius: 5 , backgroundColor: 'orange', color: 'white'}}>Schedule</Text>
        }
      }
        break;
      // case 'test':
      //   statusUI = <Text style={{padding: 10, borderRadius: 5 , backgroundColor: 'red', color: 'white'}}>Test</Text>
      //   break;
      // case 'sent': {
      //   if(moment(notice.item.deliveryDate).isAfter(moment())) {
      //     statusUI = <Text style={{padding: 10, borderRadius: 5 , backgroundColor: 'green', color: 'white'}}>Sent</Text>
      //   } else {
      //     statusUI = <Text style={{padding: 10, borderRadius: 5 , backgroundColor: 'green', color: 'white'}}>Pending</Text>
      //   }
      //   break;
      // }
      case 'cancelled':
        statusUI = <Text style={{padding: 10, borderRadius: 5 , backgroundColor: 'red', color: 'white'}}>Cancelled</Text>
        break;
      default:
        statusUI
    }

    return statusUI
  }

  const noticeCol = [
    {
      headerName: "STATUS",
      field: "status",
      key: "status",
      minWidth: 190,
      cellRendererFramework: ({ data }) => {
        return (
          <Space size={24}>
            {
              getStatus(data.status, data)
            }
          </Space>
        );
      },
    },
    {
      headerName: "TITLE",
      field: "title",
      key: "title",
      minWidth: 220,
    },
    {
      headerName: "TYPE",
      field: "type",
      key: "type",
      minWidth: 140,
    },
    {
      headerName: "DATE AND TIME",
      field: "dateandtime",
      key: "dateandtime",
      minWidth: 220
    },
    {
      headerName: "SENT",
      field: "sentCount",
      key: "sentCount",
      minWidth: 140,
      cellRendererFramework: ({ data }) => {
        return (
          <Space size={24}>
            {
              getSentCount(data.status, data)
            }
          </Space>
        );
      },
    },
    {
      headerName: "ACKNOWLEDGED",
      field: "acknowledged",
      key: "acknowledged",
      minWidth: 140
    },
    {
      headerName: "Actions",
      field: "actions",
      key: "actions",
      minWidth: 300,
      cellRendererFramework: ({ data }) => {
        return (
          <Space size={16}>
            <EyeOutlined className="icons-page" onClick={() => viewAction('view', data)} /> 
            {
              (
                (!moment(`${data.item.deliveryDate} ${data.item.deliveryTime}`, 'YYYY-MM-DD HH:mm').isBefore(moment()) && (data.status === 'scheduled' || data.status === 'pending')) ||
                (data.status === 'draft' ||  data.item.isDraft)
              ) && 
              <EditOutlined className="icons-page" onClick={() => viewAction('edit', data)}  /> 
            }
            {
              (data.status !== 'cancelled') && 
              <CopyOutlined className="icons-page" onClick={() => viewAction('copy', data)}  /> 
            }
            {
              (data.status === 'draft' || data.item.isDraft) && 
              <DeleteOutlined className="icons-page" onClick={() => triggerAction('delete', data)}  />
            }
          </Space>
        );
      },
    },
  ];

  const viewAction = (action, notice) => {
    setAction(action)
    setNotice(notice.item)
    setVisible(true)
  }

  const triggerAction = async (action, notice) => {

    setLoader(true);

    let url, method, payload;

    if (action === 'copy') {
      url = `${maintenanceService}/user/notices`; 
      method = 'POST';
      payload= {
        "action" : "copy",
        "noticeId": notice?.item?.noticeId
      }
    }

    if (action === 'delete') {
      url = `${maintenanceService}/user/notices/${notice?.item?.noticeId}`; 
      method = 'PATCH';
      payload= {
        isDelete : true
      }
    }

    try {

      const res = await axios({
        method: method,
        url: url,
        data: payload
      })

      let paramStatus = status === 'sent' ? 'scheduled' : status

      dispatch(getNotices(limit, 1, paramStatus));

      setLoader(false);

    } catch (error) {
      setLoader(false);
    }

  }

  const onAdd = () => {
    let paramStatus = status === 'sent' ? 'scheduled' : status
    dispatch(getNotices(limit, currentPage, paramStatus));
    setVisible(false);
  };

  const handlePagination = (pg) => {
    setCurrentPage(pg);
    let paramStatus = status === 'sent' ? 'scheduled' : status
    dispatch(getNotices(limit, pg, paramStatus));
  };

  useEffect(() => {
    if (noticeApi?.data) {
      let temp = [];
      noticeApi?.data?.map((item) => {

        temp.push({
          status: item.status,
          title: item.title, 
          type: `${item?.deliveryMethod[0]?.app && item?.deliveryMethod[0]?.email ? 'App & Email' : item?.deliveryMethod[0]?.app ? 'App' : 'Email'}`, 
          dateandtime: `${moment(item.deliveryDate).format("DD-MM-YYYY")}, ${item?.deliveryTime?.replace(/\s+/g, ' ')}`, 
          sentCount: item.sentCount,
          acknowledged: item.aknowledgementCount, 
          item: item
        });
      });
      
      if(status === 'sent') temp = temp.filter(notice => moment(`${notice.item.deliveryDate} ${notice.item.deliveryTime}`, 'YYYY-MM-DD HH:mm').isBefore(moment()))

      if(status === 'scheduled') temp = temp.filter(notice => !moment(`${notice.item.deliveryDate} ${notice.item.deliveryTime}`, 'YYYY-MM-DD HH:mm').isBefore(moment()))

      setData(temp);
    } else {
      setData([]);
    }
  }, [noticeApi]);

  const popup = [
    {
      title: (
        <Title level={3} className="m-0">
          Create Notices
        </Title>
      ),
      content: (
        <ModifyNotice
          onSubmit={onAdd}
          onCancel={() => setVisible(false)}
          notice={viewNoticeData}
          action={viewNoticeAction}
        />
      ),
      width: 650,
    }
  ];

  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => setVisible(false),
  };

  const setFilter = (statusFilter) => {
    setStatus(statusFilter)
    setCurrentPage(1);
    let paramStatus = statusFilter === 'sent' ? 'scheduled' : statusFilter
    dispatch(getNotices(limit, 1, paramStatus));
  }

  useEffect(() => {
    setFilter('')
  }, [])

  return (
    <>
      <DashboardLayout load={loading}>
        <Row gutter={[32, 32]}>
          <Col span={24}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <SectionHeader
                  heading="Notices"
                  noBorder={true}
                  total={status === 'scheduled' ? data.length : noticeApi ? noticeApi?.totalCount : 0}
                  // addNewAction={ addTenant }
                  // dropNewAction={onDropClick}
                  // dropItems={MenuItems}
                  // dropTrigger={["click"]}
                  addNewAction={() => {
                    setAction(null)
                    setNotice(null)
                    setVisible(true)}
                  }
                  // search={(e) => onTopSearch(e)}
                  // searchBy={(e) => onTopSearchBy(e)}
                  searchOptions={searchKeys}
                  defaultSearch={search}

                  // viewOption={propertyOptions}
                  viewOptionIcon={<FilterOutlined />}
                  // viewOptionLabel="Filter by : property/unit"
                  justifiedItems={true}
                  updateValue={updateValue}
                  justifyEnd={true}
                />
              </Col>
              <Col span={24}>
                <Pagination
                  pageSize={limit}
                  className="ag-property-text-pagination"
                  total={status === 'scheduled' ? data.length : noticeApi ? noticeApi?.totalCount : 0}
                  showTotal={(total, range) =>
                    `Showing ${range[0]}-${range[1]} of ${total} Notices`
                  }
                  defaultPageSize={noticeApi?.perPage}
                  defaultCurrent={1}
                  showSizeChanger={false}
                  current={currentPage}
                  onChange={(e) => handlePagination(e)}
                />
              </Col>
              <Col span={24}>
                {loader && (
                  <Row gutter={[24, 24]}>
                    <Col span={24}>
                      <Space size={24} className="w-100 text-center" direction="vertical">
                        <Spin size="large" className="ag-fontSize32" />
                      </Space>
                    </Col>
                  </Row>
                )}
                <Row gutter={[24, 24]} justify="end">
                  <Col span={24}>
                    <Card
                      bordered={false}
                      className="ag-nospace-body"
                    // extra={<SearchFelds />}
                    >
                      

                      <Row style={{marginTop: 25}}>
                        <Col style={{marginLeft: '5%'}} className={`icons-page ${status === `` && `icons-page-hover`}`} onClick={() => setFilter("")}>
                          <CheckSquareOutlined /> 
                          <Text className="icons-page-text">All</Text>
                        </Col>
                        <Col style={{marginLeft: '5%'}} className={`icons-page ${status === `scheduled` && `icons-page-hover`}`} onClick={() => setFilter("scheduled")}>
                          <ClockCircleOutlined /> 
                          <Text className="icons-page-text">Schedule</Text>
                        </Col>
                        <Col style={{marginLeft: '5%'}} className={`icons-page ${status === `sent` && `icons-page-hover`}`} onClick={() => setFilter("sent")}>
                          <SendOutlined /> 
                          <Text className="icons-page-text">Sent</Text>
                        </Col>
                        <Col style={{marginLeft: '5%'}} className={`icons-page ${status === `draft` && `icons-page-hover`}`} onClick={() => setFilter("draft")}>
                          <FormOutlined /> 
                          <Text className="icons-page-text">Draft</Text>
                        </Col>
                      </Row>

                      <Grid
                        refCol="status"
                        data={data}
                        columns={noticeCol}
                        autoSizeColumns={true}
                        pagination={false}
                        defaultSettings={gridProps}
                        noRowlabel="Bookings"
                        fullPage={true}
                      />

                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </DashboardLayout>
      <Popup destroyOnClose={true} {...popupProps} />
    </>
  );
};