import React, { useState, useEffect } from "react";

// others
import { useForm, useWatch } from "react-hook-form";
import Button from "../../../../molecules/Button";
import { Col, Row, Form, Space, Switch, Popover } from "antd";
import { ReactSelectField } from "../../../../atoms/FormElement";
import { useSelector } from "react-redux";
import {
  getAllTechnicianRecords,
  getAllTechnicianShifts,
  rosterLoader,
  updateTechnicianSchedule,
  updateTechnicianScheduleRemove,
} from "./ducks/actions";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";

type iProps = {
  item: any;
  prevItem: any;
  children: any;
  updateChild: (obj: object) => void;
};

let initialState = {
  popupVisible: false,
};

const index = (props: iProps) => {
  // states
  const [{ popupVisible }, setState] = useState(initialState);
  const dispatch: any = useDispatch();
  const { dateRange } = useSelector((state: any) => state?.technicians);
  const { startDate = "", endDate = "" } = dateRange || {};
  const allTechnicianShifts = useSelector(
    (state: any) => state?.technicians?.technicianShifts
  );

  const {
    control,
    setValue,
    getValues,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const shiftName = useWatch({ control, name: "shiftName" });
  const updatePopover = (state: {}) =>
    setState((prevState: any) => ({ ...prevState, ...state }));

  const handleSwitch = (bool: boolean) => {
    const itemCopy = { ...props.item, ...{ isDayOff: bool, letex: true } };

    props.updateChild({ selectedItem: { ...itemCopy } });
  };
  const technicianName = props?.prevItem?.record?.meta?.label?.EN || "";
  const onSubmit = async () => {
    dispatch(rosterLoader(true));
    const dataGetRec = {
      startDate: dayjs(startDate).format("YYYY-MM-DD"),
      endDate: dayjs(endDate).format("YYYY-MM-DD"),
      search: "",
    };
    if (props?.item?.isDayOff) {
      const apiPayload = {
        userId: props?.item?.userId,
        roasterId: props?.item?.recordId,
        date: dayjs(startDate)
          .day(props?.item?.recDetails?.item?.day)
          .format("YYYY-MM-DD"),
        from: "00:00",
        to: "23:59",
        reason: "Visit a doctor",
      };
      dispatch(
        updateTechnicianScheduleRemove({
          apiPayload,
          getAllTechnicianRecords,
          dataGetRec,
        })
      );
    } else {
      const apiPayload = {
        roasterId: props?.item?.recordId,
        shiftId: getValues("shiftName")?._id,
        date: dayjs(startDate)
          .day(props?.item?.recDetails?.item.day)
          .format("YYYY-MM-DD"),
      };

      dispatch(
        updateTechnicianSchedule({
          apiPayload,
          getAllTechnicianRecords,
          dataGetRec,
        })
      );
    }
    await dispatch(getAllTechnicianShifts());
    updatePopover({ popupVisible: false });
    dispatch(rosterLoader(false));
  };

  const clearState = () => {
    setValue("shiftName", "");
    updatePopover({ popupVisible: false });
    props.updateChild({ selectedItem: props.prevItem });
  };
  const date = dayjs(startDate)
    .day(props?.item?.recDetails?.item.day || 0)
    .format("MMM DD YYYY");
  const content = (
    <Form className="bf-main" onFinish={handleSubmit(onSubmit)}>
      <div className="roster-popover">
        <div>
          <p className="popover-tech-name">{technicianName}</p>
          <p className="popover-date">{date}</p>
        </div>

        <Row gutter={6}>
          <p className="popover-day-off">Is Day Off ?</p>
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            onChange={handleSwitch}
            checked={props.item.isDayOff}
            className="ag-switch popover-switch"
          />
        </Row>

        {!props.item.isDayOff && (
          <p className="popover-time">{shiftName?.description || ""}</p>
        )}

        {!props?.item?.isDayOff ? (
          <Col span={24} className="popover-dropdown">
            <ReactSelectField
              isRequired={true}
              control={control}
              setValue={setValue}
              onChange={() => {
                trigger(["shiftName"]);
              }}
              fieldname="shiftName"
              validate={errors.shiftName && "error"}
              iProps={{ placeholder: "Select shift" }}
              rules={{ required: "Please select shift name" }}
              validMessage={errors.shiftName && errors.shiftName.message}
              selectOption={allTechnicianShifts || []}
            />
          </Col>
        ) : (
          <Col span={24} className="popover-dropdown"></Col>
        )}

        <Row>
          <Space>
            <Button label="Update" type="submit" />

            <Button
              label="Cancel"
              onClick={() => clearState()}
              className="cc-fotter_back-button"
            />
          </Space>
        </Row>
      </div>
    </Form>
  );

  return (
    <Popover
      trigger="click"
      content={content}
      open={popupVisible}
      placement="bottomLeft"
      popupVisible={popupVisible}
      title="Technician’s Schedule"
      onOpenChange={(bool) => {
        updatePopover({ popupVisible: bool });
        !bool && clearState();
      }}
    >
      {props.children}
    </Popover>
  );
};

export default index;
