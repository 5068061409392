import { Button, Col, Row, Typography, Divider  } from "antd";
import React from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const { Text } = Typography;

const SendInviteToTenant = (props) => {

  const onFinish = () => {
    props.onConfirm();
  };

  return (
    <>
      <Row gutter={24}>
        <Col>
          <Divider /> 
        </Col>
        <Col xl={24}>
          <Text strong>{props.title || `Would you like to send an invite to the technician`}</Text>
        </Col>
        <Col>
          <Divider /> 
        </Col>
      </Row>

      <Row gutter={24} justify="space-between">
        <Col>
          <Button
            className="ag-error theme_grey_btn"
            htmlType="button"
            onClick={() => {
              props.onCancel();
            }}
          >
            {<CloseOutlined />} No
          </Button>
        </Col>
        <Col>
          <Button className="ag-green-button theme_btn" onClick={() => {
              onFinish()
            }}>
            {<CheckOutlined />} Yes
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default SendInviteToTenant;
