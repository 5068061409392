
import React, { useState, useEffect, useRef } from "react";
import {
  Layout,
  Row,
  Col,
  Breadcrumb,
  Steps,
  Button,
  message,
  Spin,
  Card,
  Form,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { BsXCircleFill, BsDownload, BsCloudUpload, BsFillCheckCircleFill } from "react-icons/bs";

import axios from 'axios';

import { Controller, useForm } from "react-hook-form";
import {
  propertyService, serverUrl
} from "../../../../configs/constants";
import type { UploadFile } from 'antd/es/upload/interface';
import { InboxOutlined } from '@ant-design/icons';


import { CSVLink } from "react-csv";

import { Upload } from 'antd';
import { RcFile } from "antd/lib/upload/interface";
const { Dragger } = Upload;
const { Content } = Layout;

var _ = require("lodash");


// CreateProperty;

export default (props) => {
  // const [uploadProgress, setUploadprogress] = useState(0);
  const initials = { unit:0, building:0 };
  const [failedData, setFailed] = useState([]);
  const [successData, setSuccess] = useState(initials);
  const [loading, setLoading] = useState(false);

  const { control, formState: { errors }, setValue, handleSubmit } = useForm();

  const headers = [
    { label: "Business Unit Name", key: "businessunit" },
    { label: "Building Name", key: "buildingname" },
    { label: "Property Code", key: "propertycode" },
    { label: "Unit Code", key: "unitcode" },
    { label: "Reason", key: "reason" }
  ];

  const csvReport = {
    data: failedData,
    headers: headers,
    filename: 'Property_Failure_log.csv'
  };



  // csv
  const [file, setFile] = useState();
  const [array, setArray] = useState([]);

  // const [filelist, setfilelist] = useState([]);
  const [propertyFile, setProperty] = useState<UploadFile[]>([]);

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const status = () => {
    let text = "";
    if (successData.building > 0 && successData.unit > 0){
       text = ` Successfully Added  ${successData.building} Buildings and ${successData.unit} Units`; 
    }
    else if (successData.building > 0 && successData.unit == 0)
    text = `Successfully Added ${successData.building} Buildings`;
    else if (successData.building == 0 && successData.unit > 0)
    text =  `Successfully Added ${successData.unit} Units`;
    else
    text = "";
    return text;
  }

  const resetModal = () => {
    setProperty([]);
    setFailed([]);
    setSuccess(initials);
  }

  const onFinish = async (values) => {

    const postJson = {
      file_asset: propertyFile[0],
      // orgId: "orgId-83a0fecf-a07a-46fc-98ec-dc49359abed1",
      // orgId:"orgId-83a0fecf-a07a-46fc-98ec-dc49359abed1"
    };

    resetModal();
    setLoading(true);



    if (propertyFile[0]) {

      let fd = new FormData();
      fd.append('file_asset', propertyFile[0] as RcFile);
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };

      // return axios.post("http://localhost:5000/upload", fd, config)
      const url = `${propertyService}/property/UploadFile`;

      await axios.post(url, fd, config).then((response) => {

        const { data } = response;



        if (data?.result?.failedresult?.length > 0) {
          // console.log("sorry failed by: ", data?.result?.failedData?.length);
          const failedArry = _.map(data.result.failedresult, (e) => {
            return {
              "businessunit": e.businessunit,
              "buildingname": e.buildingname,
              "propertycode": e.propertycode,
              "unitcode": e.unitcode,
              "reason": e.reason
            }
          });
          setFailed(failedArry);
        }
        if (data?.result?.successResultCount){
          setSuccess({ unit: data?.result?.successResultCount?.bulkDataToCreateUnitCount, building: data?.result?.successResultCount?.bulkDataToCreateBuildingCount });
          message.success(response.data.message);
          props.onSubmit();
        }
        setLoading(false);
        setProperty([]);

      }).catch((error) => {

        setLoading(false);
        message.error(error.response.data.error)

      })

    } else {
      setLoading(false);
      message.error("Please select a file to upload");
    }

  };
  const headerKeys = Object.keys(Object.assign({}, ...array));

  const propertyFileProps = {
    className: "file-upload",
    name: "file",
    accept: '.csv',
    multiple: false,
    // showUploadList: false,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {

      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} Please upload only CSV file`);
      }
    },
    onDrop(e) {

      // setProperty(e.dataTransfer.files);
    },
    onRemove: (file) => {
      setProperty([]);
    },
    beforeUpload: (file) => {
      setProperty([file]);
      return false;
    },
    propertyFile,
  };


  return (

    <Layout className="layout-space prop_popup">
      <Content className="main-content">

        <Row gutter={24}>

          <Col flex="1 1 500px">
            {/* File Uploader */}
            <div style={{ textAlign: "center" }}>
              {/* <h1 className="csvheading">Upload your data through CSV file</h1> */}

              <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
                <Row gutter={24}>


                  <Dragger {...propertyFileProps}
                    fileList={propertyFile}

                    className="drag_drop_csv">
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                    Download the sample template and fill the data as per the template, convert master file into CSV and upload it.
                    <br></br><br></br>
                    <span>Note: </span>Only CSV files are accepted.
                    </p>
                  </Dragger>
                  <div className="csvmodel">
                  <a className="downloadcsv" href={`${serverUrl}/templates/residential/ResidentialBuildingAndUnitTemplate_v1.xlsx`} download>
                    {<BsDownload/>}  Download residential template file
                    </a>
                    <a className="downloadcsv" href={`${serverUrl}/templates/commercial/CommercialBuildingAndUnitTemplate_v1.xlsx`} download>
                      {<BsDownload/>} Download commercial template file
                    </a>
                   
                  </div>
                      <Col span={24} className="csv_error_wrap">
                  {loading ? (
                    <Spin />
                  ) :

                    (<>
                  
                     { successData.building || successData.unit ?
                     (
                      <p className="success_csv">{<BsFillCheckCircleFill/>}
                     {status()} 
                     </p>
                     )
                     : ""}
                   
                      {failedData?.length > 0 ? (
                        <div className="csv_error_log">
                          <span className="csv_error"> {<BsXCircleFill color='red' />} {failedData?.length} - Records Failed <CSVLink className="csv_log" {...csvReport}>{<BsDownload/>} Download Failure Log</CSVLink></span><br />
                          {/* <span className="csv_log"><CSVLink {...csvReport}>Download Failure Log</CSVLink></span> */}
                        </div>
                      ) : ""}

                    </>)}
                    <Button htmlType="submit" disabled={loading} type="primary" size="large" className="csv_publish">
                      {<BsCloudUpload/>}Publish
                    </Button>
                    </Col>
                  

                </Row>
              </Form>

              <br />
            </div>
          </Col>

        </Row>
      </Content>
    </Layout>
  );
};
