import axios from "../../../../utils/axiosInceptor";
import { contactUs } from "../../../../configs/constants";

export const submitContactUs = async (payload) => {
  try {
    const url = `${contactUs}/user/contactus`;
    const { status } = await axios.post(url, payload)
    return status;
  } catch (error) {}
};
