export const LeaseStatuses = [
    { value: "draft", label: "Draft", order: 10 },
    { value: "opportunity", label: "Opportunity Created", order: 10 },

    { value: "active", label: "Active", order: 300 },
    { value: "booking_cancelled", label: "Booking Cancelled", order: 300 },

    { value: "created", label: "Draft – Submitted", order: 20 },
    { value: "booking_created", label: "Draft – Submitted", order: 20 }, //CHANGED AS PER FLOW -> booking_created will not be there
    { value: "tenant_invitation_sent", label: "Tenant Invitation Sent", order: 30  },
    { value: "awaiting_kyc_information", label: "Tenant Invitation Sent", order: 40 },//"Awaiting KYC Information" : as discussed with vishwajeet
    { value: "tenant_information_submitted", label: "Tenant Information Submitted", order: 50 },
    { value: "tenant_documents_uploaded", label: "Tenant Documents Uploaded", order: 50 },
    { value: "tenant_documents_approved", label: "Tenant Documents Approved", order: 50 },
    { value: "tenant_documents_rejected", label: "Tenant Documents Rejected", order: 50 },
    { value: "tenant_information_incomplete", label: "Tenant Information_incomplete", order: 60 },
    { value: "tenant_information_approved", label: "Tenant Information Approved", order: 60 },
    { value: "kyc_submitted", label: "Opportunity Created", order: 70 }, //"KYC Submitted" => (opportunity) Opportunity Created / (draft) Tenant Invitation Sent
    { value: "awaiting_documents", label: "Awaiting Documents", order: 75 },
    { value: "documents_submitted", label: "KYC Submitted", order: 80 }, // "Documents Submitted"
    { value: "documents_approved", label: "Documents Approved", order: 90 },
    { value: "kyc_rejected", label: "KYC Rejected", order: 100 },
    { value: "kyc_approved", label: "KYC Approved", order: 110 },

    { value: "booking_confirmed", label: "Booking Confirmed", order: 120 },
    { value: "landlord_pdc_updated", label: "Landlord PDC Updated", order: 121 },

    { value: "sent_for_booking_approval", label: "Sent For Booking Approval", order: 124 },
    { value: "booking_rejected", label: "Booking Rejected", order: 125 },
    { value: "booking_reject", label: "Booking Rejected", order: 125 },
    { value: "booking_approved", label: "Booking Approved", order: 130 },
    { value: "booking_form_sent", label: "Booking Form Sent", order: 135 },

    { value: "payment_initiated", label: "Payment Initiated", order: 140 },
    { value: "awaiting_deposit", label: "Awaiting Deposit", order: 145 },
    { value: "payment_successful", label: "Payment Successful", order: 145 },
    { value: "deposit_paid", label: "Deposit Paid", order: 150 },
    { value: "rent_payments_received", label: "Rent Payments Received", order: 155 },
    
    { value: "sent_for_lease_approval", label: "Sent For Lease Approval", order: 160 },
    { value: "lease_rejected", label: "Lease Rejected", order: 165 },
    { value: "lease_approved", label: "Lease Approved", order: 170 },
    
    { value: "draft_ejari", label: "Draft Ejari", order: 180 },
    { value: "contract_created", label: "Contract Created", order: 185 },
    { value: "contract_send_to_tenant", label: "Contract Sent To Tenant", order: 190 },
    { value: "awaiting_tenant_signature", label: "Awaiting Tenant Signature", order: 195 },
    { value: "awaiting_renewal_fee", label: "Awaiting Renewal Fee", order: 195 },
    
    { value: "contract_signed_by_tenant", label: "Contract Signed By Tenant", order: 200 },
    { value: "tenant_signed_ejari_contract", label: "Tenant Signed Ejari Contract", order: 205 },
    { value: "tenant_signed_ejari_contract_downloaded", label: "Tenant Signed Ejari Contract Downloaded", order: 206 },
    { value: "awaiting_landlord_signature", label: "Awaiting Landlord Signature", order: 210 },
    { value: "landlord_signed_ejari_contract", label: "Landlord Signed Ejari Contract", order: 215 },
    { value: "contract_signed", label: "Contract Signed", order: 220 },
    { value: "ejari_contract_downloaded", label: "Ejari Contract Downloaded", order: 225 },
    { value: "ejari_contract_activated", label: "Ejari Contract Activated", order: 226 },
    { value: "ejari_certificate_downloaded", label: "Ejari Certificate Downloaded", order: 227 },
    
    { value: "lease_expired", label: "Lease Expired", order: 300 },
    { value: "booking_in_progress", label: "Booking In Progress", order: 190 },

    { value: "renewal_sent", label: "Renewal Sent", order: 20 },
   
    { value: "renewed", label: "Renewed", order: 200 },
    { value: "vacated", label: "Vacated", order: 200 },

    { value: "to_review", label: "To Review", order: 10 },
    { value: "payment_plan_rejected", label: "Payment Plan Rejected" , order: 50 },
    { value: "payment_plan_approved", label: "Payment Plan Approved", order: 60 },
    { value: "courier_pickup_selected", label: "Courier Pickup Selected", order: 130 },
    { value: "self_delivery_selected", label: "Self Delivery Selected", order: 130 },
    { value: "renewal_accepted", label: "Renewal Accepted", order: 50 },
    { value: "ready_to_send", label: "Ready To Send", order: 80 },
    { value: "sent", label: "Sent to tenant", order: 80 },
    { value: "sent_for_approval", label: "Sent For Approval", order: 100 },
    { value: "uploaded", label: "Uploaded", order: 50 },
    { value: "renewal_declined", label: "Renewal Declined", order: 20 },


    //contract Type
    { value: "renewal", label: "Renewal", order: 30 },
    { value: "new", label: "New", order: 30 },

    { value: "active_renewing", label: "Active – Renewing", order: 30 },
    { value: "active_vacating", label: "Active – Vacating", order: 30 },


]