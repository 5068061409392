import React from "react";

const index = () => {
    return (
        <svg style={{marginRight: "9px"}} width="10" height="14" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.70508 0.875V5.09375H11.9238C12.3809 5.09375 12.7676 5.48047 12.7676 5.9375C12.7676 6.42969 12.3809 6.78125 11.9238 6.78125H7.70508V11C7.70508 11.4922 7.31836 11.8438 6.86133 11.8438C6.36914 11.8438 6.01758 11.4922 6.01758 11V6.78125H1.79883C1.30664 6.78125 0.955078 6.42969 0.955078 5.9375C0.955078 5.48047 1.30664 5.09375 1.79883 5.09375H6.01758V0.875C6.01758 0.417969 6.36914 0.03125 6.86133 0.03125C7.31836 0.03125 7.70508 0.417969 7.70508 0.875ZM0.392578 16.9062C0.392578 16.4492 0.744141 16.0625 1.23633 16.0625H12.4863C12.9434 16.0625 13.3301 16.4492 13.3301 16.9062C13.3301 17.3984 12.9434 17.75 12.4863 17.75H1.23633C0.744141 17.75 0.392578 17.3984 0.392578 16.9062Z" fill="#2F2F37"/>
        </svg>          
    );
};

export default index;
