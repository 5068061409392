import React from "react";

const index = () => {
  return (
    <svg
      width="30"
      height="22"
      viewBox="0 0 30 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 1.25V14H12V7.25C12 6.03125 12.9844 5 14.25 5H24.75C27.6094 5 30 7.39062 30 10.25V14.75V17.75V20.75C30 21.1719 29.625 21.5 29.25 21.5C28.8281 21.5 28.5 21.1719 28.5 20.75V18.5H1.5V20.75C1.5 21.1719 1.125 21.5 0.75 21.5C0.328125 21.5 0 21.1719 0 20.75V17.75V14.75V1.25C0 0.875 0.328125 0.5 0.75 0.5C1.125 0.5 1.5 0.875 1.5 1.25ZM28.5 17V15.5H12.75H1.5V17H28.5ZM28.5 10.25C28.5 8.1875 26.8125 6.5 24.75 6.5H14.25C13.8281 6.5 13.5 6.875 13.5 7.25V14H28.5V10.25ZM4.5 8.75C4.5 9.59375 4.92188 10.2969 5.625 10.7188C6.28125 11.1406 7.17188 11.1406 7.875 10.7188C8.53125 10.2969 9 9.59375 9 8.75C9 7.95312 8.53125 7.25 7.875 6.82812C7.17188 6.40625 6.28125 6.40625 5.625 6.82812C4.92188 7.25 4.5 7.95312 4.5 8.75ZM10.5 8.75C10.5 10.1094 9.75 11.3281 8.625 12.0312C7.45312 12.6875 6 12.6875 4.875 12.0312C3.70312 11.3281 3 10.1094 3 8.75C3 7.4375 3.70312 6.21875 4.875 5.51562C6 4.85938 7.45312 4.85938 8.625 5.51562C9.75 6.21875 10.5 7.4375 10.5 8.75Z"
        fill="#24435D"
      />
    </svg>
  );
};

export default index;
