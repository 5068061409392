import axios from "../../../../../../utils/axiosInceptor";
import * as action_types from "../constants";
import {
  authService,
  propertyService,
  leaseService,
  maintenanceService,
} from "../../../../../../configs/constants";

const cityUrl = `${propertyService}/property/cities`;
const stateUrl = `${propertyService}/property/regions`;
const countryUrl = `${propertyService}/property/countries`;
const featureAmenitiesUrl = `${propertyService}/property-landlord/features/`;
const usagetypeUrl = `${propertyService}/property-landlord/asset/usage-types`;
const unittypeUrl = `${propertyService}/property`;
// const notificationUrl = `${leaseService}/leads/notification/receive`;
// const getPropertyUrl = `${propertyService}/property-landlord/`;
const getPropertyUrl = `${propertyService}/property/`;

const getPropertyByIdUrl = `${propertyService}/property-landlord`;
const getLeadsListUrl = `${leaseService}/leads/get-all-leads`;
const presignedImageUrl = `${leaseService}/leads/download-signed-url`;

// AddGeneral Info Actions
const headers = {
  Authorization:
    "Bearer" +
    " " +
    JSON.parse(window.localStorage.getItem("login-response"))?.token,
};

export const getFeaturesAmenities = () => {
  return async (dispatch) => {
    const {
      data: {
        result: { property_amenities, property_features },
      },
    } = await axios.get(featureAmenitiesUrl);
    dispatch({
      type: action_types.GET_FEATURES_AMENITIES,
      data: {
        property_amenities,
        property_features,
      },
    });
  };
};

// export const getNotifications = (limit = 5, page = 0) => {
//   return async (dispatch) => {
//     const url = `${notificationUrl}?limit=${limit}&page=${page}&status=`;
//     const {
//       data: { result },
//     } = await axios.get(url);
//     dispatch({
//       type: action_types.GET_NOTIFICATIONS,
//       data: result,
//     });
//   };
// };

// export const getNewNotifications = (limit = 5, page = 0) => {
//   return async (dispatch) => {
//     const url = `${notificationUrl}?limit=${limit}&page=${page}&status=new`;
//     const {
//       data: { result },
//     } = await axios.get(url);
//     dispatch({
//       type: action_types.GET_NEW_NOTIFICATIONS,
//       data: result,
//     });
//   };
// };

export const storeSingleImageKey = (data) => {
  return async (dispatch) => {
    dispatch({
      type: action_types.STORE_SINGLE_IMAGE_KEY,
      data: data,
    });
  };
};

export const storeMultiImageKey = (data) => {
  return async (dispatch) => {
    dispatch({
      type: action_types.STORE_MULTI_IMAGE_KEY,
      data: data,
    });
  };
};

export const getLeadesLists = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(getLeadsListUrl, { headers });
      dispatch({
        type: action_types.GET_LEADS_LISTS,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};
export const getGeoData = () => {
  return async (dispatch) => {
    const cityData = await axios.get(cityUrl, { headers });
    const stateData = await axios.get(stateUrl, { headers });
    const countryData = await axios.get(countryUrl, { headers });
    dispatch({
      type: action_types.GET_GEO_DATA,
      data: {
        cityData,
        stateData,
        countryData,
      },
    });
  };
};

export const getUsageType = () => {
  return async (dispatch) => {
    const {
      data: { result },
    } = await axios.get(usagetypeUrl);
    dispatch({
      type: action_types.GET_USAGE_DETAIL,
      data: result,
    });
  };
};

export const getUnitType = (usageid) => {
  return async (dispatch) => {
    const {
      data: { result },
    } = await axios.get(`${unittypeUrl}/${usageid}/unit-types`);
    dispatch({
      type: action_types.GET_UNIT_DETAIL,
      data: result,
    });
  };
};

export const getUnitSubType = (usageid) => {
  return async (dispatch) => {
    const {
      data: { result },
    } = await axios.get(`${unittypeUrl}/${usageid}/sub-unit-types`);
    dispatch({
      type: action_types.GET_SUBUNIT_TYPE,
      data: result,
    });
  };
};

export const getPropertyById = (propertyId) => {
  return async (dispatch) => {
    const {
      data: { result },
    } = await axios.get(
      `${getPropertyByIdUrl}/${propertyId}/property-unit-details`,
    );
    dispatch({
      type: action_types.GET_PROPERTY_BY_ID,
      data: result,
    });
  };
};

export const getSingleProperty = (propertyId) => {
  return async (dispatch) => {
    const {
      data: { result },
    } = await axios.get(`${getPropertyUrl}getById?_id=${propertyId}`); //MONGO ID _id (can be done by)
                                       // getById?propertyId=${propertyId}`); //Property ID
    dispatch({
      type: action_types.GET_SINGLE_PROPERTY,
      data: result,
    });
  };
};

export const getSinglePropertyEmpty = () => {
  return (dispatch) => {
    dispatch({
      type: action_types.GET_SINGLE_PROPERTY_EMPTY,
      data: {},
    });
  };
};

export const retainGeneralInfoData = (data) => {
  return (dispatch) => {
    dispatch({
      type: action_types.ADD_PROPERTY_GENERALINFO_DATA,
      data,
    });
  };
};

export const retainPropertyDetailsData = (data) => {
  return (dispatch) => {
    dispatch({
      type: action_types.ADD_PROPERTY_PROPERTY_DETAILS_DATA,
      data,
    });
  };
};

export const retainPropertyFeaturesData = (data) => {
  return (dispatch) => {
    dispatch({
      type: action_types.ADD_PROPERTY_PROPERTY_FEATURES_DATA,
      data,
    });
  };
};

// export const getPresignedImage = async (mediaArray) => {
//   const mediaUrls = [];
//   for (let i of mediaArray) {
//     const postData = {"url":i}
//     const {
//       data: { result },
//     } = await axios.post(presignedImageUrl, postData);
//     mediaUrls.push(result?.imageUrl)
//   }
//   return mediaUrls;
//   }

// export const getPresignedImage = async (media) => {
//   const postData = { url: media || "" };
//   const {
//     data: { result },
//   } = await axios.post(presignedImageUrl, postData);
//   return result?.imageUrl;
// };
