import React, { useState, useEffect } from "react";
import { Button, Checkbox, Col, Modal, Form, Radio, Row, Space, Typography, Upload, UploadFile, message } from "antd";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {useParams} from 'react-router-dom'
import PdfView from "./pdvViewNew";
import { PopupConfirm } from "../../atoms/Popup";
import { generateDocumentService } from "../../modules/BookingForm/ducks/services";
import { getBookingDetail } from "../../modules/BookingForm/ducks/actions";


const PdfCascadeView = (props) => {

  const { onSubmit, setTrigger,signBy, downloadUrl,documentType, placeholder, sendForEsignature, documentSent, documentName } = props;


  let docType = documentType === 'bookingForm' ? 'BOOKING_FORM_INST' : 'TENANCY_CONTRACT';
  const {
    control,
    // set,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
   
  } = useForm();

  const [pdfData, setPdfData] = useState(null);

  const [pdfFile, setPdfFile] = useState<UploadFile[]>([]);

  const [previewFile, setPreviewFile] = useState(null);

  const [openFileView, setOpenFileView] = useState(false);
  
  const[isLoading,setIsLoading] = useState(false); 

  const { leaseId = "" } = useParams(); 


  const { Title } = Typography;

  const dispatch:any = useDispatch();

  const submitForm = (values) => {
    let payload = {};
    const file = pdfFile[0];
    // console.log("FILE", file);
    const checks = values?.signedByCheck ?? [];

    if (file?.originFileObj)
      payload['file'] = file?.originFileObj;
    // landlord / tenant / both
    if (checks && checks?.length > 0) {
      if (checks.includes(3) && checks.includes(2))
        payload['signBy'] = 'both'
      else if (checks.includes(3))
        payload['signBy'] = 'landlord'
      else if (checks.includes(2))
        payload['signBy'] = 'tenant'
      else
        message.error("Document is unsigned");
    }
    if (payload['signBy']) {
      message.loading("Saving document")
      onSubmit(payload);
    }
  }


  const setSignBychecks = () => {
    if (signBy && signBy === "both")
      setValue('signedByCheck', [2, 3]);
    else if (signBy && signBy === "landlord")
      setValue('signedByCheck', [3]);
    else if (signBy && signBy === "tenant")
      setValue('signedByCheck', [2]);
    else if (signBy && signBy === "unsigned")
      setValue('signedByCheck', [1]);
  }


  useEffect(() => {
    getFile();
    setSignBychecks();
  }, [props?.pdf]);

  const getFile = async () => {
    setPdfData(props?.pdf);
  }

  const setPreviewUrl = (file) => {
    if (file?.originFileObj) {
      let url = URL.createObjectURL(file.originFileObj)
      setPreviewFile(url);
    }
  }

  useEffect(() => {
    if (pdfFile && pdfFile?.length > 0)
      setPreviewUrl(pdfFile[0])
  }, [pdfFile]);

  const fileProps = {
    className: "file-upload",
    name: "file",
    accept: '.pdf',
    multiple: false,
    fileList: pdfFile?.length > 0 ? pdfFile : [],
    onChange(info) {
      const { status } = info.file;
      const { fileList } = info;
      if (fileList && fileList?.length > 0) {
        setPdfFile([fileList[0]]);
      }
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} Please upload pdf file`);
      }
    },
    onRemove: () => {
      setPdfFile(null);
    },
    beforeUpload: (file) => {
      setPdfFile([file]);
      return false;
    },
    pdfFile,
  };
 


  const refreshDocs = async (data:object) => {
    
    // setIsLoading(true);
    // dispatch(getBookingDetail(leaseId)
    PopupConfirm({
      title: `This will refresh the document with updated data`,
      okText: "Yes",
      onOk: async () => {
        try {
          const res = await generateDocumentService(data);
          
          
          if(res.data?.code === 200){
            dispatch(getBookingDetail(leaseId));
            message.success(res.data?.message);
            // setTrigger(-1)
          }
       
        } catch (error) {
          setIsLoading(false)
          console.log(error)
        }
      },
    })
    
  }
  return (<>

    <PdfView
      previewFile={previewFile}
      pdfData={pdfData}
      openFileView={openFileView}
      setOpenFileView={setOpenFileView}
    />

    <Form
      // layout="vertical"
      name="documentTemplateForm"
      onFinish={handleSubmit(submitForm)}
      scrollToFirstError
    >
      <Row>
        <Col span={12}>
         

          {(!previewFile && pdfData) && (
            <embed id="myvideo" src={pdfData && (pdfData + "#toolbar=0&navpanes=0&scrollbar=0")} title=""

              width="100%" height="330px" />
          )}
          {previewFile && (
            <object id="myvideo" data={previewFile + "#toolbar=0&navpanes=0&scrollbar=0"} title=""
              width="100%" height="330px" />
          )}



          <Row>
            <Col style={{ display: "flex", marginTop: "10px", justifyContent: "center", alignItems: "center", width: "100%" }}>
              <Button type="link" onClick={() => setOpenFileView(true)}>View</Button>
              {(!previewFile && pdfData) && (
                <Button type="link" onClick={() => downloadUrl()}>Download</Button>
                // href={pdfData} target="_blank"
              )}
              {/* {documentName != "additionalDocument" && ( */}
                <Upload {...fileProps}>
                  <Button type="link" className="p-0 ag-primary">
                    Upload
                  </Button>
                </Upload>
              {/* )} */}

            </Col>
          </Row>
        </Col>

        {placeholder && (

          <Col span={12} style={{ display: "flex", flexDirection: "column", marginTop: "0px", justifyContent: "end", alignItems: "center" }}>
            {/* onChange={onChange} value={value} */}
            <Title level={5}>Select document type</Title>

            {/* TODO: //can be refactored */}
            <Form.Item
              required={true}
              validateStatus={errors.signedByCheck ? "error" : ""}
              help={errors.signedByCheck && (<>{errors?.signedByCheck?.message}</>)}

            >
              <Controller
                name={"signedByCheck"}
                control={control}
                rules={{ required: 'Please select type' }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Checkbox.Group
                    value={value}
                    ref={ref}
                    onChange={(e) => {
                      onChange(e);
                      props.onChange && props.onChange(e);
                    }}
                  >
                    <Space direction="vertical">
                      <Checkbox value={1} disabled={value?.includes(3) || value?.includes(2)}>Unsigned</Checkbox>
                      <Checkbox value={2} disabled={value?.includes(1)}>Signed by tenant</Checkbox>
                      <Checkbox value={3} disabled={value?.includes(1)}>Signed by landlord</Checkbox>
                    </Space>
                  </Checkbox.Group>
                )}
              />
            </Form.Item>



            <Row>
              <Col>
                {(signBy == "unsigned") && (
                  <Button
                    type="primary"
                    className="btn-blue"
                    onClick={() => sendForEsignature()}
                  // disabled={disableAll}
                  >
                    {documentSent == true ? "Re-send" : "Send"} For eSignature
                  </Button>
                )}
              </Col>
              <Col>
                {(signBy == "unsigned") && (
                  <Button
                    disabled={isLoading}
                    type="primary"
                    className="btn-blue"
                    onClick={() => refreshDocs({ leaseId, document:docType,regenerate:true })}
                  
                  >
                  {isLoading ? "Loading...": "Refresh Document" }  
                  </Button>
                )}
              </Col>
              <Col>
                <Button
                 
                  type="primary"
                  className="btn-green"
                  htmlType="submit"
                >Save</Button>
              </Col>

            </Row>




          </Col>
        )}
      </Row>

    </Form>
  </>);
};
export default PdfCascadeView;
