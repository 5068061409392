import React, { useEffect,useState } from "react";
import { Form, Row, Col, Button, message, Checkbox,Upload ,Space,Typography} from "antd";
import { ReactSelectField,DateField,InputField } from "../../../../atoms/FormElement";
import { useForm,Controller ,useWatch} from "react-hook-form";
import { PlusCircleOutlined } from "@ant-design/icons";
import { updateBooking } from "../../ducks/actions";
import { getUsers } from "../../ducks/services";
import { getServiceProviders } from "../../../ServiceProvider/ducks/actions";
import { useParams } from "react-router-dom";
import { useDispatch ,useSelector} from "react-redux";
import { PopupConfirm } from "../../../../atoms/Popup";
import { dateBookingDDMMYYFormat } from "../utils";
import { convertDataForReactSelect } from "../utils";
import { getSingleTicketMediaUpload,uniquiFileName } from "../../../../../utils/fileUpload";
import { maintenanceService } from "../../../../../configs/constants";
import axios from "axios";
import { getAvailablity } from "../../../Maintenance/Tickets/ducks/actions";
import dayjs from "dayjs";
import moment from "moment";
import { isDisabled } from "@testing-library/user-event/dist/utils";
var _ = require("lodash");

const MoveOutPopup = (props) => {
   
    const { onClose,leaseExpireDate ,propertyDetail,propertyId,unitId} = props;

    const { control, formState: { errors }, setValue, handleSubmit } = useForm();

    const dispatch: any = useDispatch();
    const { leaseId = "" } = useParams();
    const[loading,setLoading] = useState(false);

//  for upload image of any request for service provider 

    const mediaUploader =  async ()=>{
      const normalizedUploads = [];
    const mediaAsset = [];

    uploads.forEach((e) =>
      normalizedUploads.push({
        ...e,
        modifiedName: uniquiFileName(e?.originFileObj.name),
      }),
    );

    let noupload = false;

    await Promise.all(
      normalizedUploads.map(async (e, index) => {
        let res = null;
        res = await getSingleTicketMediaUpload(e.modifiedName, e.originFileObj);

        const doc = res?.result;
        if (doc)
          doc?.documentId && mediaAsset.push({ "documentId": doc?.documentId, "type": doc?.document?.type && doc?.document?.type?.toLowerCase() });
        if (res == false) {
          noupload = true;
        }
      }),
    );

    if (noupload) { //|| mediaAsset.length === 0
      // setLoader(false);
      // setisError(true);
      message.error("Media not Uploaded. Please Try again");
      return false;
    }else{
      return mediaAsset;
    }
      
    }


    //  for create ticket if user select add serive request checkbox in case of confirm move out story number 8455

    const createTicket = async (serviceRequestData)=>{

      
     let mediaForService:any = [];
      
    //  if user have fault image to uplaod 
      uploads?.length > 0 && await mediaUploader().then((result)=>{mediaForService = result});
       const subType = ticketsType.find(item => item?.categoryId == cateId?.value);
       const subCateTime = subType?.subCategories?.find((item)=> item?.subCategoryId == checksubCate?.value)
      const [startTime, endTime] = serviceRequestData?.timeslot?.value.split("-");

      const postJson = {
        // "propertyId":propertyId,
        // "propertyName":propertyDetail?.propertyName,
        "ticketType": serviceRequestData?.requesttype?.value,
        "unitType":  propertyDetail?.usagesType?.toLowerCase(),
        "preferredDate": dayjs(serviceRequestData.date).format("YYYY-MM-DD"), //convert date to string
        "unitId":unitId,
        "recurrenceId": false,
        "preferredTimeStart": startTime,
        "preferredTimeEnd": endTime,
        "category": {
          "categoryId": serviceRequestData.categoryId.value,
          // "name": ticketName?.categoryName || mainCategory?.name || mainCategory?.categoryName,
          "name": serviceRequestData?.categoryId?.label,
        },
        "subCategory": {
          "subCategoryId": serviceRequestData.subCategoryId.value,
          "name": serviceRequestData.subCategoryId.label,
        },
        "documents": mediaForService,
        "parentCaseId": "",
        "timeInMin": subCateTime?.timeInMin,
        "ticketUserType": "ServiceProvider"
      };

      if (serviceRequestData?.desc)
        postJson["description"] = serviceRequestData?.desc;
      if (serviceRequestData?.mobile)
        postJson["alternateNumber"] = serviceRequestData?.mobile;

     
      const url = `${maintenanceService}/maintenance/portalCreateTicket`;

      try {
        await axios.post(url, postJson);
        onClose();
      } catch (e) {
        
        const { response } = e;
        message.error(response?.data?.error);
      }
    
    }
// submit function for form
    const confirmMoveOut = async (data:any) => {
  
      const {vacatingDate} = data;
      let usersToBeNotify = ''
      if(data?.userList?.length > 0){
        data?.userList?.forEach(element => {
          usersToBeNotify += element?.value + ','
       });
      }
     
      const date = dayjs(new Date(vacatingDate.$y, vacatingDate.$M, vacatingDate.$D, vacatingDate.$H, vacatingDate.$m, vacatingDate.$s, vacatingDate.$ms));

     // Format the date as "YYYY-MM-DD"
      const formattedDate = date.format("YYYY-MM-DD");
    
        
       
        PopupConfirm({
          title: `This action will terminate the existing lease for this unit on the vacating date. Are you sure you wish to proceed?`,
          cancelText: "No",
          okText: "Yes",
          onOk: async () => {
          
            try{         
              setLoading(true);
              const response = await dispatch(
                updateBooking({
                  leaseId,
                  body: {
                      onlyBooking:true,
                      "renewalToTenant": "moveout_confirmed",
                      "vacatingDate" : formattedDate,
                      "notifyUsers":usersToBeNotify,   
                  }
                }));
                
                if(response?.data?.code === 200){
                  message.success(response?.data?.message);

                  if(serviceRequest){
                    createTicket(data);
                  }else{
                    setLoading(false);
                    onClose();
                  }
                 
                }
                // console.log(response,'formattedDate');
            }catch(error){
              setLoading(false);
              if(error?.response?.data?.success === false){
                message.error(error?.response?.data?.error)
              }
            }
          },
        });
    };


    const [uploads, setUploads] = useState([]);
    const handleChange = (e) => {
        setUploads(e.fileList);
      };

      const dummyRequest = (response) => {
        setTimeout(() => {
          response.onSuccess("ok");
        }, 0);
      };

      const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
          src = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
          });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
      };
      const { Text } = Typography;


   const [serviceRequest,setServiceRequest] = useState(false);
   const [userList,setUserList] = useState([]);
   const [slots, setSlots] = useState([]);

   const getUserList = async ()=>{
     
    try{
       const users =  await getUsers();
       let valueAndLabel = [];

       users?.forEach((item,index)=>{
        valueAndLabel.push({
          value:item?.userId,
          label:item?.name
        })
       })
       setUserList(valueAndLabel)
  
    }catch(error){

    }

   }
   const [ticketsType, setTicketType] = useState([]);
   const services:any = useSelector((state:any) =>state?.service);
   const availableSlots = useSelector(
    (state: any) => state.tickets.availableSlots,
  );

  useEffect(() => {
    if (Object.keys(availableSlots)?.length > 0 && datecheck) {
      let date = dayjs(datecheck).format("YYYY-MM-DD")
      if (availableSlots[date])
        setSlots(availableSlots[date]);
      else {
        setSlots([]); message.error("Unavailable, Please try further dates")
      }
    } else {
      setSlots([]);
    }
  }, [availableSlots]);



   useEffect(()=>{
    if(serviceRequest){
      dispatch(getServiceProviders())
    }else{
      userList?.length === 0 && getUserList();
    }

   },[serviceRequest]);

   const requesttype: any = useWatch({ control, name: "requesttype" });
   const cateId: any = useWatch({ control, name: "categoryId" });
   const checksubCate: any = useWatch({ control, name: "subCategoryId" });
   const datecheck: any = useWatch({ control, name: "date", defaultValue: "" });
   useEffect(()=>{

    if (requesttype?.value === 'internal') {
            
      const mergedObject:any =  services?.serviceProviders.reduce((acc:any, obj:any) => {
        acc.internalCategories = acc?.internalCategories.concat(obj?.internalCategories);
        return acc;
      }, { internalCategories: [] });
         setTicketType(mergedObject?.internalCategories);
       if (services?.serviceProviders?.length == 0) setTicketType([]);
    }
    if (requesttype?.value === 'regular') {

      

      if (propertyDetail?.usagesType === 'Commercial') {
         
        const mergedObject:any =  services?.serviceProviders.reduce((acc:any, obj:any) => {
          acc.commercialCategories = acc?.commercialCategories.concat(obj?.commercialCategories);
          return acc;
        }, { commercialCategories: [] });
         
        setTicketType(mergedObject?.commercialCategories);
        if (services?.serviceProviders?.length == 0) setTicketType([]);
      }
      if (propertyDetail?.usagesType === 'Residential') {
        const mergedObject:any =  services?.serviceProviders.reduce((acc:any, obj:any) => {
          acc.residentialCategories = acc?.residentialCategories.concat(obj?.residentialCategories);
          return acc;
        }, { residentialCategories: [] });

          setTicketType(mergedObject?.residentialCategories);
        if (services?.serviceProviders?.length == 0) setTicketType([]);
      }
    }
   },[requesttype,cateId?.value]);
 
   



   useEffect(() => {
    if ( moment(datecheck).isValid() && cateId?.value && checksubCate?.value) {
      setValue("timeslot", "");

      const subType = ticketsType.find(item => item?.categoryId == cateId?.value);
      const subCateTime = subType?.subCategories?.find((item)=> item?.subCategoryId == checksubCate?.value)
      
     

      let body = {
        categoryId: cateId?.value,
        startDate: dayjs(datecheck).format("YYYY-MM-DD"),
        propertyId:propertyId,
        timeInMin: subCateTime?.timeInMin,
        unitType: propertyDetail?.usagesType?.toLowerCase(),
        ticketType: requesttype?.value
      };

      dispatch(getAvailablity(body));

    } else {
      setValue("timeslot", "");
      setSlots([]);
    }
  }, [datecheck]);



    return (
        <Form layout="vertical" onFinish={handleSubmit(confirmMoveOut)}>
            <Row gutter={16}>

                <Col span={24}>
                    <DateField
                        control={control}
                        label={`Vacating Date`}
                        isRequired={true}
                        setValue={setValue}
                        rules={{ required: `Please enter vacating date` }}
                        fieldname={`vacatingDate`}
                        validate={errors.vacating_date && "error"}
                        valueGot={dateBookingDDMMYYFormat(
                          leaseExpireDate
                        )}
                        validMessage={errors.vacating_date && errors.vacating_date.message}
                        iProps={{
                            className: "cd",
                            placeholder: `Please enter vacating date`,
                        }}
                    />
                </Col>

                {userList?.length > 0 && (
                    <Col span={24}>
                        <ReactSelectField
                            label={"Select Users"}
                            fieldname="userList"
                            iProps={{ placeholder: "Select user" }}
                            initValue=""
                            isMulti={true}
                            setValue={setValue}
                            control={control}
                            selectOption={userList}
                        />
                    </Col>


                )}

                <Col span={10}>
                    <Checkbox style={{ paddingBottom: '24px' }} className={'move-out-check'} onChange={(e) => setServiceRequest(e.target.checked)}>Add a Service Request</Checkbox>
                </Col>
            </Row>

           {
            serviceRequest &&
             <Row gutter={16}>
             <Col span={24}>
               <InputField
                 fieldname="property"
                 label="Property Name"
                 control={control}
                 // iProps={{ placeholder: "Enter Additional Details" }}
                 initValue={propertyDetail?.propertyName}
                 disabled={true}
               />
             </Col>
             <Col span={12}>
               <InputField
                 label="Unit Number"
                 fieldname="unit_number"
                 control={control}
                 initValue={propertyDetail?.unitCode}
                 disabled={true} 
               />
             </Col>
             <Col span={12}>
             <ReactSelectField
                    isRequired={serviceRequest}
                    fieldname="requesttype"
                    label="Request Type"
                    control={control}
                    defaultValue={'regular'}
                    rules={{ required: "Please select Request Type" }}
                    selectOption={
                      [
                        {
                          value: 'internal',
                          label: 'Internal',
                        },
                        {
                          value: 'regular',
                          label: 'Regular',
                        }
                      ]
                    }
                    iProps={{ placeholder: "Select type" }}
                    validate={errors.requesttype && "error"}
                    validMessage={errors.requesttype && errors.requesttype.message}
                    initValue=""
                  />
             </Col>
             <Col span={12}>
             
                            <ReactSelectField
                            label={"Select Category"}
                            fieldname="categoryId"
                            iProps={{ placeholder: "Select Category",isDisabled:!requesttype?.value }}
                            initValue=""
                            isMulti={false}
                            setValue={setValue}
                            isRequired={serviceRequest}
                            control={control}
                            rules={{ required: "Required" }}
                            validate={errors.categoryId && "error"}
                            validMessage={errors.categoryId && errors.categoryId.message}
                            selectOption={convertDataForReactSelect(ticketsType,false,null)}
                            
                           
                        />
             </Col>
             <Col span={12}>
                    <ReactSelectField
                            label={"Select Sub Category"}
                            fieldname="subCategoryId"
                            iProps={{ placeholder: "Select Sub Category",isDisabled:!cateId?.value }}
                            initValue=""
                            isMulti={false}
                            setValue={setValue}
                            isRequired={serviceRequest}
                            control={control}
                            rules={{ required: "Required" }}
                            validate={errors.subCategoryId && "error"}
                            validMessage={errors.subCategoryId && errors.subCategoryId.message}
                            selectOption={cateId?.value && convertDataForReactSelect(ticketsType, true, cateId?.value)}
                            />
                            
             </Col>
             <Col span={12}>
               <DateField
                 isRequired={serviceRequest}
                 fieldname="date"
                 label="Visit Date"
                 control={control}
                 iProps={{
                   placeholder: "DD-MM-YYYY",
                   format: "DD-MM-YYYY",
                  // disabledDate: disabledDate,
                 
                 }}
                 rules={{
                   required: "Please enter date",
                   setValueAs: (value) => moment(value).format("YYYY-MM-DD"),
                 }}
                 initValue={null}
                 validate={errors.date && "error"}
                 validMessage={errors.date && errors.date.message}
               />
             </Col>
             <Col span={12}>
               <ReactSelectField
                 isRequired={serviceRequest}
                 fieldname="timeslot"
                  label={`Time Slot ${slots.length > 0 ? "(available)" : ""}`}
               
                 control={control}
                 iProps={{ placeholder: "Select time" }}
                 rules={{
                   required: "Please select time",
                 }}
                 selectOption={_.map(slots, (item) => ({
                   value: item,
                   label: item,
                 }))}
                 validate={errors.timeslot && "error"}
                 validMessage={errors.timeslot && errors.timeslot.message}
               />
             </Col>
 
             <Col span={12}>
               <InputField
                 fieldname="desc"
                 label="Additional Details"
                 control={control}
                 iProps={{ placeholder: "Enter Additional Details" }}
                 initValue=""
               />
             </Col>
             <Col span={12}>
               <InputField
                 label="Contact Number"
                 fieldname="mobile"
                 control={control}
                 initValue=""
                 iProps={{ addonBefore: "+971", }}
                 // isRequired={true}
                 validate={errors.mobile && "error"}
                 validMessage={
                   errors.mobile && errors.mobile.message
                 }
                 // disabled={disableAll}
                 rules={{
                   minLength: {
                     value: 9,
                     message: "Please enter valid contact number, 9 digits required",
                   },
                   maxLength: {
                     value: 9,
                     message: "Please enter valid contact number, 9 digits required",
                   },
                   // required: "Please enter Mobile No.",
                 }}
               />
             </Col>
 
             <Col span={24}>
               <Form.Item
                 className="mb-0"
                 validateStatus={errors.media && "error"}
                 // help={errors.media && errors.media.message}
               >
                 <Controller
                   name="media"
                   control={control}
                   // rules={{ required: "Please select attachment" }}
                   render={({ field: { onChange, value } }) => {
                     return (
                       <Upload
                         listType="picture-card"
                         // fileList={value?.fileList}
                         onChange={(e) => {
                           onChange(e);
                           handleChange(e);
                         }}
                         multiple={true}
                         accept="image/*, video/*"
                         customRequest={dummyRequest}
                         onPreview={onPreview}
                         className="custom-upload mb-2"
                       >
                         <Space className="ag-success fontSize24" align="center">
                           <PlusCircleOutlined />
                           <Text className="ag-success RegularFont">Add Photo/Video</Text>
                         </Space>
                       </Upload>
                     );
                   }}
                 />
               </Form.Item>
             </Col>
             </Row>
           }
            <Row gutter={24} justify="space-between">
                <Col>
                    <Button size="large" onClick={onClose}>
                        Back
                    </Button>
                </Col>
                <Col>
                    <Button  size="large" className="ag-red-button" htmlType="submit">
                        {"Confirm"}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default React.memo(MoveOutPopup);
