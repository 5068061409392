import { readFile } from "fs";

// others
var _ = require("lodash");

export const filterConfigData = (configData: any) => {
  const configDataCopy = configData?.reduce(
    (acc, curr) => ({ ...acc, ...curr }),
    {}
  );


if(configDataCopy?.requiredDocumentsListwithAttibutes){
  
  let configuredDocuments = [];
  let index = 0;
  for (const item in  configDataCopy?.requiredDocumentsListwithAttibutes) {
    let newObj = {...configDataCopy?.requiredDocumentsListwithAttibutes[item]}
    newObj['status'] = 'pending';
    newObj['checked'] = true;
    newObj['type'] = Object.keys(configDataCopy?.requiredDocumentsListwithAttibutes)[index];
    configuredDocuments.push(newObj);
    index++;
  }
  configDataCopy["configuredDocuments"] = configuredDocuments;
}
  if (configDataCopy?.nationalityList?.length > 0)
    configDataCopy["nationalityList"] = filterArray(
      configDataCopy?.nationalityList
    );

  if (configDataCopy?.requiredBookingDocumentsList) {
    const docs = _.map(
      configDataCopy?.requiredBookingDocumentsList,
      (value, key) => {
        return { value: key, label: value };
      }
    );
    configDataCopy["requiredBookingDocumentsList"] = docs;
  }

  return configDataCopy;
};

const filterArray = (data: any) => {
  return _.map(data, (item: string) => ({
    label: item,
    value: item.toLowerCase(),
  }));
};

export const filteLandlordUsers = (data: any) => {
  return _.map(data, (item: any) => ({
    label: item.name,
    value: item.userId,
  }));
};

export const tracker = [
  {
    active: true,
    complete: false,
    title: "Tenant",
    label: "Personal information",
  },
  {
    active: false,
    complete: false,
    title: "Lease Terms",
    label: "Rental agreement details",
  },
  {
    active: false,
    complete: false,
    title: "Payment Plans",
    label: "Options for rent payment",
  },
  {
    active: false,
    complete: false,
    title: "Documents",
    label: "Required paperwork",
  },
];

export const filterBookingDetails = (data: any, bookingForm?: any) => {
  let dataCopy: any = { ...bookingForm, ...data };
  const {
    isSignatory,
    signatoryUser,
    tenantDetails,
    leaseTerms,
    bookingTerms,
    leaseManager,
    paymentPlan,
    commercialPaymentPlan,
    requiredDocuments,
    furnished,
    parkings,
  } = dataCopy;
  if (tenantDetails) {
    dataCopy = {
      email: tenantDetails?.email,
      name: tenantDetails?.tenantNameEN,
      externalAgent: dataCopy?.externalAgent,
      arabicName: tenantDetails?.tenantNameAR,
      nationality: tenantDetails?.nationality, //RESIDENTIAL
      maritalStatus: tenantDetails?.maritalStatus, //RESIDENTIAL
      designation: tenantDetails?.designation || tenantDetails?.designationAsPerVisa, //COMMERCIAL
      company: tenantDetails?.company, //COMMERCIAL
      numberOfOcupants: tenantDetails?.numberOfOcupants,
      mobile: tenantDetails?.mobileNumber?.substr(
        tenantDetails?.mobileNumber.length - 9
      ),
    };
  }
  if (leaseManager)
    dataCopy = { ...dataCopy, ...{ leaseManager: leaseManager.userId } };
  if (leaseTerms) dataCopy = { ...dataCopy, ...{ leaseTerms } };
  if (bookingTerms) dataCopy = { ...dataCopy, ...{ bookingTerms } };
  if (paymentPlan) dataCopy = { ...dataCopy, ...{ paymentPlan } };
  if (commercialPaymentPlan)
    dataCopy = { ...dataCopy, ...{ commercialPaymentPlan } };
  if (requiredDocuments) dataCopy = { ...dataCopy, ...{ requiredDocuments } };
  if (furnished) dataCopy = { ...dataCopy, ...{ furnished } };
  if (signatoryUser) dataCopy = { ...dataCopy, ...{ signatoryUser } };
  if (isSignatory) dataCopy = { ...dataCopy, ...{ isSignatory } };
  if (parkings) dataCopy = { ...dataCopy, ...{ parkings } };

  return dataCopy;
};
