
import React, { useState, useEffect } from "react";
import {
    Layout,
    Row,
    Col,
    Button,
    message,
    Image,
    Spin,
} from "antd";


import axios from 'axios';

import type { UploadFile } from 'antd/es/upload/interface';
import { DeleteOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons';

import { Upload } from 'antd';
import { RcFile } from "antd/lib/upload/interface";
import { getPresignedImage, uploadDocumentMedia } from "../../../../../utils/media";
import { leaseService } from "../../../../../configs/constants";
import { PopupConfirm } from "../../../../atoms/Popup";
const { Dragger } = Upload;
const { Content } = Layout;


export default ({ item, onUpdate }) => {

    const [documentId, setDocumentId] = useState(item?.documentId);

    const [loading, setLoading] = useState(false);

    const [paymentAttachFile, setPaymentAttachFile] = useState<UploadFile[]>([]);

    const [attachURL, setAttachURL] = useState(null);


    const getUrl = async (data) => {
        const url = await getPresignedImage(data);
        setAttachURL(url[0]);
    };

    useEffect(() => {
        setLoading(true);
        documentId ? getUrl([documentId]) : setAttachURL(null);
        setLoading(false);
    }, [documentId]);

    useEffect(() => {
        if (paymentAttachFile.length > 0)
            uploadSignedEjariContract();
    }, [paymentAttachFile]);

    const uploadSignedEjariContract = async () => {

        setLoading(true);
        try {
            const { data } = await uploadDocumentMedia({ file_asset: paymentAttachFile[0] as RcFile });
           

            const documentId = data?.result?.documentId;
            documentId && await updateAttachedDoc(documentId)
            message.success("Successfully uploaded document");
            setLoading(false);
            onUpdate();
        } catch ({ response }) {
            message.error(response?.data?.error);
            setLoading(false);
        }
    }


    const updateAttachedDoc = async (docId) => {
        const payload = {
            "documentId": docId
        };
        const LEASEID = item?.leaseId;
        const PDCID = item?._id;
        try {
            const url = `${leaseService}/booking/paymentSchedule/${LEASEID}/${PDCID}`;
            await axios.patch(url, payload);
            setDocumentId(docId);
        } catch (e) {
            const { response } = e;
            message.error(response?.data?.error);
        }
    }

    const removeAttachedDoc = async () => {
        return PopupConfirm({
            title: `Are you sure you want to delete?`,
            cancelText: "Back",
            okText: "Delete",
            okType: "danger",
            async onOk() {
                setLoading(true);
                await updateAttachedDoc("");
                message.success("Successfully deleted attachment");
                setAttachURL(null);
                setPaymentAttachFile([]);
                setLoading(false);
                onUpdate();
            },
        });

    }

    const fileProps = {
        className: "file-upload",
        name: "file",
        accept: '.jpg,.png,.jpeg',
        multiple: false,
        // showUploadList: false,
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} Please upload only image file`);
            }
        },
        onRemove: (file) => {
            setPaymentAttachFile([]);
        },
        beforeUpload: (file) => {
            setPaymentAttachFile([file]);
            return false;
        },
        paymentAttachFile,
    };

    return (

        <Layout className="layout-space prop_popup" style={{ minHeight: "245px" }}>
            <Content className="main-content" >

                {(attachURL && !loading) && (
                    <>
                        <Row gutter={24}>
                            <Col flex="1 1 500px" style={{ minHeight: "245px", overflow: "hidden", padding: "10px" }}>
                                <Image
                                    preview={false}
                                    width="100%"
                                    style={{ padding: "0px" }}
                                    src={attachURL}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24} style={{ marginTop: "15px" }}>

                            <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    type="primary"
                                    htmlType="button"
                                    className="ag-red-button"
                                    onClick={removeAttachedDoc}
                                >
                                    Delete
                                </Button>
                                <Upload {...fileProps}>
                                    <Button
                                        // icon={<UploadOutlined />}
                                        type="primary"
                                        className="btn-blue"
                                    >Change</Button>
                                </Upload>
                                <Button
                                    type="primary"
                                    href={attachURL}
                                    target="_blank"
                                    className="btn-blue"
                                    download
                                >
                                    Download
                                </Button>
                            </Col>
                        </Row>
                    </>
                )}

                {loading && (
                    <div className="justify-center" style={{ textAlign: "center", minHeight: "255px" }}>
                        <Spin />
                    </div>
                )}

                {((!documentId && !loading)) && (
                    <Dragger {...fileProps}
                        fileList={paymentAttachFile}

                        className="drag_drop_csv">
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">
                            Upload the payment attachment file in the form of cheques or relevant document for the payment
                            <br></br><br></br>
                            <span>Note: </span>Only image files are accepted.                    </p>
                    </Dragger>
                )}

            </Content>
        </Layout>
    );
};
