import moment from "moment";
import {
  GET_TENANT_DETAILS,
  GET_UNIT_DETAILS,
  GET_LEASE_NATURE,
  GET_PARKING_SLOTS,
  GET_BOOKING_TERMS,
  GET_TENANT_CONTACTS,
  SAVE_BOOKING_DRAFT,
  GET_TERMS_CONDITIONS,
  GET_APPROVAL_HISTORY,
  GET_BOOKING_DOCS,
  GET_BOOKING_DETAILS,
  GET_BOOKING_REJECT_REASONS,
} from "./constants";
import axios from "../../../../../utils/axiosInceptor";
import {
  bookingFormUnit,
  baseContractService,
} from "../../../../../configs/constants";
import {
  normalizeTenantDetails,
  normalizeDraftData,
  normalizeTenantContacts,
  normalizeTermsConditions,
} from "./normalizers";

export const getTenantDetails = (partyId) => {
  return async (dispatch) => {
    const url = `${baseContractService}/${partyId}/tenant-details`;
    const response = await axios.get(url);
    dispatch({
      type: GET_TENANT_DETAILS,
      data: response?.data?.result,
    });
  };
};

export const getUnitDetails = (assetId) => {
  return async (dispatch) => {
    const url = `${bookingFormUnit}/${assetId}/property-unit-details`;
    const response = await axios.get(url);
    dispatch({
      type: GET_UNIT_DETAILS,
      data: response?.data?.result,
    });
  };
};

export const getBookingRejectionReason = () => {
  return async (dispatch) => {
    const url = `${baseContractService}/lead-status-rejection-reasons`;
    const response = await axios.get(url);

    dispatch({
      type: GET_BOOKING_REJECT_REASONS,
      data: response?.data?.result,
    });
  };
};

export const getLeaseNature = () => {
  return async (dispatch) => {
    const url = `${baseContractService}/lease-nature`;
    const response = await axios.get(url);
    dispatch({
      type: GET_LEASE_NATURE,
      data: response?.data?.result,
    });
  };
};

export const getParkingSlots = (leadId) => {
  return async (dispatch) => {
    const url = `${baseContractService}/get-parking-slots/${leadId}`;
    const response = await axios.get(url);
    dispatch({
      type: GET_PARKING_SLOTS,
      data: response?.data?.result,
    });
  };
};

export const getBookingTerms = (leadId) => {
  return async (dispatch) => {
    const url = `${baseContractService}/booking-terms/${leadId}`;
    const response = await axios.get(url);
    dispatch({
      type: GET_BOOKING_TERMS,
      data: response?.data?.result,
    });
  };
};

export const getTenantContacts = () => {
  return async (dispatch) => {
    const url = `${baseContractService}/get-tenant-contacts`;
    const response = await axios.get(url);
    let data = [];
    if (response?.data?.result.length) {
      data = normalizeTenantContacts(response.data.result);
    }
    dispatch({
      type: GET_TENANT_CONTACTS,
      data,
    });
  };
};

export const getTermsConditions = () => {
  return async (dispatch) => {
    const url = `${baseContractService}/terms-and-condition-entities`;
    const response = await axios.get(url);
    const data = response?.data?.result;
    if (data.length) {
      const normalizedData = normalizeTermsConditions(data);
      dispatch({
        type: GET_TERMS_CONDITIONS,
        data: normalizedData,
      });
    }
  };
};

export const getAprovalHistory = (leadId) => {
  return async (dispatch) => {
    const url = `${baseContractService}/approval-history/${leadId}`;
    const response = await axios.get(url);
    dispatch({
      type: GET_APPROVAL_HISTORY,
      data: response?.data?.result,
    });
  };
};

export const saveBookingDraft = (data) => {
  return async (dispatch) => {
    const url = `${baseContractService}/save-booking-draft`;
    const dataObj = normalizeDraftData(data);
    const response = await axios.post(url, dataObj);
    dispatch({
      type: SAVE_BOOKING_DRAFT,
    });
  };
};

export const getBookingFormDocs = (leadId) => {
  return async (dispatch) => {
    const url = `${baseContractService}/booking-form-documents/${leadId}`;
    const response = await axios.get(url);
    dispatch({
      type: GET_BOOKING_DOCS,
      data: response?.data?.result,
    });
  };
};

export const getBookingFormDetails = (leadId) => {
  return async (dispatch) => {
    const url = `${baseContractService}/get-booking-form-after-saving/${leadId}`;
    const response = await axios.get(url);
    dispatch({
      type: GET_BOOKING_DETAILS,
      data: response?.data?.result,
    });
  };
};

/* 

Get Booking Form Documents:
Request: GET
API: {​​​​​​​​{​​​​​​​​API}​​​​​​​​}​​​​​​​​/contract/booking-form-documents/:leadId

​[1:49 PM] Husain Feroz
    

Get Marital Status:
Request: GET
API: {​​​​​​​​{​​​​​​​​API}​​​​​​​​}​​​​​​​​/contract/marital-status

 */
