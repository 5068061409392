import React, { useState, useEffect, Fragment } from "react";

// others
import { Loading } from "./index";
import { useForm } from "react-hook-form";
import { numberWithCommas } from "../utils";
import { Empty } from "../../../../../assets/svg";
import Button from "../../../../molecules/Button";
import { useDispatch, useSelector } from "react-redux";
import { InputNumberField } from "../../../../atoms/FormElement";
import { updateBooking, getBookingDetail } from "../../ducks/actions";
import { Col, Row, Form, Space, Radio, message, Typography } from "antd";

interface IProps {
  parentState: (obj: object) => void;
}

const { Text } = Typography;

let initialState = {
  paymentId: "",
  visible: false,
  paymentPlan: [],
  selectedIndex: null,
  isPlanChanged: false
};

const index = (props: IProps) => {
  // hooks initialization
  const dispatch: any = useDispatch();

  // redux states
  const { bookingDetails } = useSelector((state: any) => state.newBooking);

  let {
    control,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm();

  // states
  let [{ visible, paymentId, paymentPlan, isPlanChanged, selectedIndex }, setState] =
    useState(initialState);

  const updateState = (state: {}) =>
    setState((prevState: any) => ({ ...prevState, ...state }));

  useEffect(() => {
    updateState({
      paymentPlan: bookingDetails.paymentPlan,
      paymentId: bookingDetails?.tenantDetails?.paymentPlanId,
      selectedIndex: bookingDetails?.paymentPlan?.findIndex(
        (payment: any) =>
          payment?.paymentPlanId ===
          bookingDetails?.tenantDetails?.paymentPlanId
      ),
    });
  }, []);

  const onSubmit = async (data: any) => {
    updateState({ visible: true });

    let payload = {};
    let formData = {};

    for (let key in data) {
      const splitKey = key.split("-");

      if (selectedIndex === parseInt(splitKey[1])) {
        const val = data[key].toString();
        if (val.includes(","))
          payload[splitKey[0]] = parseInt(val.replace(",", ""));
        else payload[splitKey[0]] = parseInt(val);

        payload["installments"] = paymentPlan[selectedIndex].installments;
      }
    }

    for (let key in paymentPlan) {
      if (
        paymentPlan[key].paymentPlanId ==
        bookingDetails?.tenantDetails?.paymentPlanId
      ) {
        payload["oldNoOfYear"] = paymentPlan[key].noOfYear;
        payload["oldAnnualRent"] = paymentPlan[key].annualRent;
        payload["oldSecurityDeposit"] = paymentPlan[key].securityDeposit;
      }
    }

    const copy = [...paymentPlan];
    copy[selectedIndex] = payload;

    formData = {
      paymentPlan: [...copy],
      paymentPlanId: paymentId,
    };

    if (bookingDetails?.paymentPlanApproval !== "disable") {
      formData["paymentPlanSendForApproval"] = "yes";
      formData["oldPaymentPlanId"] =
        bookingDetails?.tenantDetails?.paymentPlanId;
    }

    try {
      const response = await dispatch(
        updateBooking({
          body: formData,
          onlyBooking: true,
          leaseId: bookingDetails.leaseId,
        })
      );

      await dispatch(getBookingDetail(bookingDetails.leaseId));

      updateState({ visible: false });
      message.success(response.data.message);
      props.parentState({ modalPayload: { open: false } });
    } catch (error) {
      updateState({ visible: false });
      message.error(error.response?.data?.message);
    }
  };

  const textStyle = { fontSize: "12px" };
  const isPayment = paymentPlan.length > 0;
  // const approvalBtnDisabled = isDirty
  //   ? false
  //   : paymentId === bookingDetails?.tenantDetails?.paymentPlanId ||
  //     !["enable", "rejected", "approved"].includes(
  //       bookingDetails?.paymentPlanApproval
  //     );

  // const saveBtnDisabled =
  //   bookingDetails?.paymentPlanApproval !== "disable" ||
  //   ["enable", "rejected"].includes(bookingDetails?.paymentPlanApproval);

  const btnDisabled = isDirty
    ? false : (isPlanChanged && paymentId) ? false 
    : paymentId === bookingDetails?.tenantDetails?.paymentPlanId ||
      !["enable", "rejected", "approved"].includes(
        bookingDetails?.paymentPlanApproval
      );

  const btnLabel =
    bookingDetails?.paymentPlanApproval !== "disable"
      ? ((bookingDetails?.paymentPlanApproval != "sent" ? "Send" : "Sent") + " for approval")
      : "Save";


  return (
    <Form
      layout="vertical"
      name="bookingform"
      scrollToFirstError
      onFinish={handleSubmit(onSubmit)}
      className="bf-main view-booking edit-payment-schedule"
    >
      <Loading visible={visible} />

      {isPayment && (
        <Row gutter={[24, 24]} style={{ letterSpacing: "1.2px" }}>
          <Col span={12}>
            <Text style={textStyle} className="fontWeight500 ag-default mb-1">
              Number of Payment
            </Text>
          </Col>
          <Col span={4}>
            <Text style={textStyle} className="fontWeight500 ag-default mb-2">
              Annual Rent
            </Text>
          </Col>
          <Col span={4}>
            <Text style={textStyle} className="fontWeight500 ag-default mb-2">
              Number of Years
            </Text>
          </Col>
          <Col span={4}>
            <Text style={textStyle} className="fontWeight500 ag-default mb-2">
              Security Deposit
            </Text>
          </Col>
        </Row>
      )}

      {paymentPlan.map((item: any, index: number) => {
        const disabled = bookingDetails?.paymentPlanApproval === "sent" 
        || (!bookingDetails?.leaseStatusHistory?.find((e) => e.status === "kyc_approved") && bookingDetails?.tenantDetails?.paymentPlanId) //added second condition ref: (8297,8327)
        || (bookingDetails?.directDebit == true && item?.installments === 1);
        return (
          <Fragment>
            <Row gutter={24} style={{ position: "relative" }}>
              <Col span={12}>
                <Radio
                  disabled={disabled}
                  value={item?.installments}
                  checked={paymentId === item?.paymentPlanId}
                  onChange={() =>
                    updateState({
                      paymentId: item?.paymentPlanId,
                      selectedIndex: index,
                      isPlanChanged: true
                    })
                  }
                >{`${item?.installments} Cheques`}</Radio>
              </Col>

              <Col span={4}>
                <InputNumberField
                  control={control}
                  isRequired={true}
                  iProps={{ min: 0 }}
                  rules={{ required: "required" }}
                  fieldname={`annualRent-${index}`}
                  initValue={numberWithCommas(item?.annualRent)}
                  validate={errors[`annualRent-${index}`] && "error"}
                  disabled={disabled || paymentId !== item?.paymentPlanId}
                />
              </Col>

              <Col span={4}>
                <InputNumberField
                  control={control}
                  isRequired={true}
                  initValue={item?.noOfYear}
                  fieldname={`noOfYear-${index}`}
                  rules={{ required: "required" }}
                  validate={errors[`noOfYear-${index}`] && "error"}
                  disabled={disabled || paymentId !== item?.paymentPlanId}
                />
              </Col>

              <Col span={4}>
                <InputNumberField
                  control={control}
                  isRequired={true}
                  rules={{ required: "required" }}
                  fieldname={`securityDeposit-${index}`}
                  initValue={numberWithCommas(item?.securityDeposit)}
                  validate={errors[`securityDeposit-${index}`] && "error"}
                  disabled={disabled || paymentId !== item?.paymentPlanId}
                />
              </Col>
            </Row>
          </Fragment>
        );
      })}

      {!isPayment && (
        <Row style={{ justifyContent: "center" }}>
          <Empty
            width="200px"
            height="200px"
            style={{ alignContent: "center" }}
          />
        </Row>
      )}

      {isPayment && (
        <Row gutter={24} className="footer">
          <Space size="middle">
            <Button
              label="Cancel"
              className="bf-discard-button"
              onClick={() =>
                props.parentState({ modalPayload: { open: false } })
              }
            />

            {/* {bookingDetails?.paymentPlanApproval !== "disable" && ( */}
              <Button
                type="submit"
                label={btnLabel}
                disabled={btnDisabled}
                className={`cc-fotter_next-button 
                ${btnDisabled && "disable-button"}`}
              />
            {/* )} */}

            {/* {bookingDetails?.paymentPlanApproval === "disable" && (
              <Button
                label="Save"
                type="submit"
                disabled={saveBtnDisabled}
                className={`cc-fotter_next-button ${
                  saveBtnDisabled && "disable-button"
                }`}
              />
            )} */}
          </Space>
        </Row>
      )}
    </Form>
  );
};

export default index;
