import React, { useEffect } from "react";
import { Col, Typography } from "antd";
import { InputField, ReactSelectField } from "../../../../../atoms/FormElement";
import { useDispatch, useSelector } from "react-redux";
import { getGeoData } from "../../ducks/actions/addInfoAction";
import { subLocationList, locationsList } from "../../../ducks/constants";
import { WrappedMap } from "../../../../../../utils/WrappedMap";
import { EQARI_GOOGLE_KEY } from "../../../../../../configs/constants";
import { getConfigData } from "../../../ducks/actions";

var _ = require("lodash");
const { Text } = Typography;

export default (props) => {
  const { control, errors, setValue, noEdit } = props;

  const dispatch: any = useDispatch();

  const configData = useSelector((state: any) => state.dashboard.configData);

  const configKeys = ["locationlist", "emiratesList", "nationalityList"];
  const countries = configData?.nationalityList?.filter(
    (c) => c === "United Arab Emirates"
  );


  useEffect(() => {
    // if (!configData) {
    dispatch(getConfigData(configKeys.join(",")));
    // }
  }, []);

  // const onPlaceChange = (place) => {
  //   place.address_components.map((e) => {
  //     if (e.types.includes("neighborhood")) {
  //       console.log("neighbou", e.long_name);
  //       setValue("neighborhood", e.long_name);
  //     }
  //     if (e.types.includes("locality")) {
  //       let city = apiGeoData?.cityData?.data?.result.find(
  //         (item) => item.name === e.long_name,
  //       );
  //       if (city) {
  //         setValue("city", { value: city.id, label: city.name });
  //       }
  //     }
  //     if (e.types.includes("route")) {
  //       setValue("street_address", e.long_name);
  //     }
  //     if (e.types.includes("administrative_area_level_1")) {
  //       let state = apiGeoData?.stateData?.data?.result.find(
  //         (item) => item.name === e.long_name,
  //       );
  //       if (state) {
  //         setValue("region", { value: state.id, label: state.name });
  //       }
  //     }
  //     if (e.types.includes("postal_code")) {
  //       setValue("zipcode", e.long_name);
  //     }
  //     if (e.types.includes("country")) {
  //       let setcountry = apiGeoData?.countryData?.data?.result.find(
  //         (item) => item.name == e.long_name,
  //       );
  //       if (setcountry) {
  //         setValue("country", { value: setcountry.id, label: setcountry.name });
  //       }
  //     }
  //   });
  //   setValue("latitude", place.geometry.location.lat());
  //   setValue("longitude", place.geometry.location.lng());
  // };

  return (
    <>
      {/* {!noEdit && (
        <>
          <Col span={24}>
            <Text
              style={{
                color: "#001056",
                marginBottom: 8,
                display: "inline-block",
              }}
            >
              Place Autocomplete Address
            </Text>
          </Col>
          <Col span={24}>
            <div className="mb-2">
              <WrappedMap
                googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${EQARI_GOOGLE_KEY}`}
                apiKey={EQARI_GOOGLE_KEY}
                containerElement={<div style={{ height: "100%" }} />}
                loadingElement={<div style={{ height: "100%" }} />}
                mapElement={<div style={{ height: "100%" }} />}
                address={true}
                onPlace={onPlaceChange}
              />
            </div>
          </Col>
        </>
      )} */}

      <Col span={12}>
        <InputField
          isRequired={!noEdit}
          label="Address Line 1"
          fieldname="addressLine1"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Enter address Line 1",
            readOnly: noEdit,
            className: `${noEdit ? "noEdit" : ""}`,
          }}
          rules={{ required: "Please enter address Line 1" }}
          validate={errors.addressLine1 && "error"}
          validMessage={errors.addressLine1 && errors.addressLine1.message}
        />
      </Col>
      <Col span={12}>
        <InputField
          isRequired={!noEdit}
          label="Address Line 2"
          fieldname="addressLine2"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Enter address Line 2",
            readOnly: noEdit,
            className: `${noEdit ? "noEdit" : ""}`,
          }}
          rules={{ required: "Please enter address Line 2" }}
          validate={errors.addressLine2 && "error"}
          validMessage={errors.addressLine2 && errors.addressLine2.message}
        />
      </Col>
      <Col span={8}>
        <ReactSelectField
          isRequired={!noEdit}
          label="Emirate"
          fieldname="emirates"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Select one",
            isDisabled: noEdit,
            className: `${noEdit ? "disablerselect" : ""}`,
          }}
          selectOption={_.map(configData?.emiratesList, (item) => ({
            value: item,
            label: item,
          }))}
          rules={{ required: "Please select emirate" }}
          validate={errors.emirates && "error"}
          validMessage={errors.emirates && errors.emirates.message}
        />
      </Col>

      {/* <Col span={6}>
        <ReactSelectField
          isRequired={!noEdit}
          label="State/ Region"
          fieldname="region"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Select one",
            isDisabled: noEdit,
            className: `${noEdit ? "disablerselect" : ""}`,
          }}
          selectOption={_.map(apiGeoData?.stateData?.data?.result, (item) => ({
            value: item.id,
            label: item.name,
          }))}
          rules={{ required: "Please enter State/ Region" }}
          validate={errors.region && "error"}
          validMessage={errors.region && errors.region.message}
        />
      </Col> */}

      <Col span={8}>
        <ReactSelectField
          isRequired={!noEdit}
          label="Country"
          fieldname="country"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Select one",
            isDisabled: noEdit,
            className: `${noEdit ? "disablerselect" : ""}`,
          }}
          selectOption={_.map(countries, (item) => ({
            value: item,
            label: item,
          }))}
          rules={{ required: "Please enter country" }}
          validate={errors.country && "error"}
          validMessage={errors.country && errors.country.message}
        />
      </Col>

      <Col span={8}>
        <ReactSelectField
          isRequired={!noEdit}
          label="Community"
          fieldname="community"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Select one",
            isDisabled: noEdit,
            className: `${noEdit ? "disablerselect" : ""}`,
          }}
          selectOption={_.map(
            configData?.locationlist?.sublocation,
            (item, key) => ({
              value: key,
              label: item,
            })
          )}
          rules={{ required: "Please select" }}
          validate={errors.community && "error"}
          validMessage={errors.community && errors.community.message}
        />
      </Col>

      {/* <Col span={6}>
        <ReactSelectField
          isRequired={!noEdit}
          label="Sub Location"
          fieldname="subLocation"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Select one",
            isDisabled: noEdit,
            className: `${noEdit ? "disablerselect" : ""}`,
          }}
        selectOption={locationsList.map((item) => ({
            label: item,
            value: item,
          }))}
          rules={{ required: "Please select" }}
          validate={errors.subLocation && "error"}
          validMessage={errors.subLocation && errors.subLocation.message}
        />
      </Col> */}
      {/* 
      <Col span={6}>
        <InputField
          isRequired={!noEdit}
          label="Zip Code"
          fieldname="zipcode"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "Enter Zip Code",
            readOnly: noEdit,
            type: "number",
            min: 1,
            className: `${noEdit ? "noEdit" : ""}`,
          }}
          rules={{ required: "Please enter zipcode" }}
          validate={errors.zipcode && "error"}
          validMessage={errors.zipcode && errors.zipcode.message}
        />
      </Col> */}

      <Col span={6}>
        <InputField
          isRequired={!noEdit}
          label="Latitude"
          fieldname="latitude"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "25.0554",
            readOnly: noEdit,
            type: "number",
            className: `${noEdit ? "noEdit" : ""}`,
          }}
          // rules={{ required: "Please enter latitude" }}
          validate={errors.latitude && "error"}
          validMessage={errors.latitude && errors.latitude.message}
        />
      </Col>
      <Col span={6}>
        <InputField
          isRequired={!noEdit}
          label="Longitude"
          fieldname="longitude"
          control={control}
          classes={`${noEdit ? "noEditLabel" : ""}`}
          initValue=""
          iProps={{
            placeholder: "55.1522",
            readOnly: noEdit,
            type: "number",
            className: `${noEdit ? "noEdit" : ""}`,
          }}
          // rules={{ required: "Please enter latitude" }}
          validate={errors.longitude && "error"}
          validMessage={errors.longitude && errors.longitude.message}
        />
      </Col>
    </>
  );
};
