import React from "react";

// others
import dayjs from "dayjs";
import { NonShift, ShiftItem } from "../components";

export const COLUMN_WIDTH: number = 180;

export const format: string = "MM/DD/YYYY";

export const apiDateFormat = (date: any, format: string = "YYYY-MM-DD") => {
  return date ? dayjs(date).format(format) : "";
};

export const weekOptions = [
  {
    label: "Current Week",
    value: "current_week",
  },
  {
    label: "Next Week",
    value: "next_week",
  },
  {
    label: "Two Weeks",
    value: "two_weeks",
  },
  {
    label: "Custom",
    value: "custom_weeks",
  },
];

export const filterDataSource = (
  columns: Array<any>,
  dataSource: Array<any>,
  dateRange: any,
  selectWeek: any
) => {
  const dataCopy: Array<any> = [...dataSource] || [];
  let result = [];
  if (selectWeek?.value === "two_weeks") {
    result = dataCopy.map((i) => {
      let dataCorrect = i;
      let repeatedArray = [];
      repeatedArray = [];
      dataCorrect?.schedules?.map((j, jindex) => {
        if (dayjs(j.date).isAfter(dayjs(dayjs().endOf("week")))) {
          repeatedArray = [...repeatedArray, j.day];
          dataCorrect.schedules[jindex] = {
            ...j,
            day: j.day + 7,
          };
        } else {
          repeatedArray = [...repeatedArray, j.day];
        }
      });

      return dataCorrect;
    });
  }
  if (selectWeek?.value === "custom_weeks") {
    const differenceOfDays = dayjs(dateRange?.[0]).diff(
      dayjs(dateRange?.[0]).startOf("week"),
      "days"
    );
    result = dataCopy.map((i) => {
      let dataCorrect = i;
      let repeatedArray = [];
      repeatedArray = [];
      dataCorrect?.schedules?.map((j, jindex) => {
        let days: any = dayjs(j.date)
          .set("hour", 0)
          .diff(dateRange?.[0].set("hour", 0), "days", true);
        days = Math.round(days) == -0 ? 0 : Math.round(days);
        if (dateRange?.[0]) {
          repeatedArray = [...repeatedArray, j.day];
          dataCorrect.schedules[jindex] = {
            ...j,
            day: days,
          };
        }
      });
      return dataCorrect;
    });
  } else {
    result = dataCopy.map((i) => {
      let dataCorrect = i;
      let repeatedArray = [];
      repeatedArray = [];
      dataCorrect?.schedules?.map((j, jindex) => {
        let notFound = repeatedArray.indexOf(j.day);
        if (notFound === -1) {
          repeatedArray = [...repeatedArray, j.day];
        } else {
          repeatedArray = [...repeatedArray, j.day];
          dataCorrect.schedules[jindex] = {
            ...j,
            day: j.day + 7,
          };
        }
      });

      return dataCorrect;
    });
  }
  let dateToShow = null;
  if (dateRange?.[0]) {
    if (
      selectWeek?.value === "current_week" ||
      selectWeek?.value === "next_week"
    ) {
      dateToShow = {
        _id: "",
        schedules: [
          {
            day: 0,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].startOf("week").add(0, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0]
                      .startOf("week")
                      .add(0, "day")
                      .format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 1,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].startOf("week").add(1, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0]
                      .startOf("week")
                      .add(1, "day")
                      .format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 2,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].startOf("week").add(2, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0]
                      .startOf("week")
                      .add(2, "day")
                      .format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 3,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].startOf("week").add(3, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0]
                      .startOf("week")
                      .add(3, "day")
                      .format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 4,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].startOf("week").add(4, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0]
                      .startOf("week")
                      .add(4, "day")
                      .format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 5,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].startOf("week").add(5, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0]
                      .startOf("week")
                      .add(5, "day")
                      .format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 6,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].startOf("week").add(6, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0]
                      .startOf("week")
                      .add(6, "day")
                      .format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
        ],
        meta: {
          value: "",
          label: {
            EN: ` Technician name`,
          },
        },
      };
    } else if (selectWeek?.value === "two_weeks") {
      dateToShow = {
        _id: "",
        schedules: [
          {
            day: 0,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].startOf("week").add(0, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0]
                      .startOf("week")
                      .add(0, "day")
                      .format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 1,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].startOf("week").add(1, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0]
                      .startOf("week")
                      .add(1, "day")
                      .format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 2,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].startOf("week").add(2, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0]
                      .startOf("week")
                      .add(2, "day")
                      .format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 3,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].startOf("week").add(3, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0]
                      .startOf("week")
                      .add(3, "day")
                      .format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 4,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].startOf("week").add(4, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0]
                      .startOf("week")
                      .add(4, "day")
                      .format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 5,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].startOf("week").add(5, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0]
                      .startOf("week")
                      .add(5, "day")
                      .format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 6,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].startOf("week").add(6, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0]
                      .startOf("week")
                      .add(6, "day")
                      .format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 7,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].startOf("week").add(7, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0]
                      .startOf("week")
                      .add(7, "day")
                      .format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 8,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].startOf("week").add(8, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0]
                      .startOf("week")
                      .add(8, "day")
                      .format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 9,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].startOf("week").add(9, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0]
                      .startOf("week")
                      .add(9, "day")
                      .format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 10,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].startOf("week").add(10, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0]
                      .startOf("week")
                      .add(10, "day")
                      .format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 11,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].startOf("week").add(11, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0]
                      .startOf("week")
                      .add(11, "day")
                      .format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 12,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].startOf("week").add(12, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0]
                      .startOf("week")
                      .add(12, "day")
                      .format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 13,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].startOf("week").add(13, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0]
                      .startOf("week")
                      .add(13, "day")
                      .format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
        ],
        meta: {
          value: "",
          label: {
            EN: "Technician name",
          },
        },
      };
    } else {
      //for custom dates
      dateToShow = {
        _id: "",
        schedules: [
          {
            day: 0,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0].format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 1,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].add(1, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0].add(1, "day").format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 2,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].add(2, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0].add(2, "day").format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 3,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].add(3, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0].add(3, "day").format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 4,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].add(4, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0].add(4, "day").format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 5,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].add(5, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0].add(5, "day").format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 6,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].add(6, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0].add(6, "day").format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 7,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].add(7, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0].add(7, "day").format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 8,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].add(8, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0].add(8, "day").format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 9,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].add(9, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0].add(9, "day").format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 10,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].add(10, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0].add(10, "day").format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 11,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].add(11, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0].add(11, "day").format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 12,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].add(12, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0].add(12, "day").format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
          {
            day: 13,
            date: "",
            span: {
              from: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "62px",
                  }}
                >
                  <span style={{ height: "18px" }}>
                    {dateRange[0].add(13, "day").format("dddd")}
                    <br />
                  </span>
                  <span style={{ height: "18px" }}>
                    {dateRange[0].add(13, "day").format("MMM DD")}
                  </span>
                </div>
              ),
              to: "",
            },
          },
        ],
        meta: {
          value: "",
          label: {
            EN: "Technician name",
          },
        },
      };
    }
  }

  let returnableArr = [];
  // const sortedArr = result.sort((a, b) => {
  //   const nameA = a?.meta?.label?.EN?.toUpperCase(); // Ignore case
  //   const nameB = b?.meta?.label?.EN?.toUpperCase(); // Ignore case
  //   if (nameA < nameB) {
  //     return -1;
  //   }
  //   if (nameA > nameB) {
  //     return 1;
  //   }
  //   return 0;
  // })

  var sortedArr = result.slice(0);

  sortedArr.sort(function (a, b) {
    {
      var x = a?.meta?.label?.EN?.toLowerCase();

      var y = b?.meta?.label?.EN?.toLowerCase();

      return x > y ? 1 : x < y ? -1 : 0;
    }
  });

  if (dateToShow) {
    returnableArr = [dateToShow, ...sortedArr];
  } else {
    returnableArr = sortedArr;
  }
  return returnableArr;
};

export const dateRangeValues = (type: string) => {
  let dates: Array<object> = [];
  // custom | current_week | next_week | two_weeks

  switch (type) {
    case "two_weeks":
      dates[0] = dayjs().startOf("weeks");
      dates[1] = dayjs().endOf("week").add(7, "day");
      break;
    case "next_week":
      dates[0] = dayjs().startOf("weeks").add(7, "day");
      dates[1] = dayjs().endOf("week").add(7, "day");
      break;
    case "current_week":
      dates[0] = dayjs().startOf("weeks");
      dates[1] = dayjs().endOf("weeks");
    case "custom_weeks":
      dates[0] = dayjs();
      dates[1] = dayjs().endOf("week").add(0, "day");
      break;
  }

  return dates;
};

export const disableDate = (date: any) => {
  return date.valueOf() < dayjs().startOf("weeks");
};

export const getDatesInRange = (dates: Array<any>, columns: Array<any>) => {
  const endDate = new Date(dates[1]);
  const startDate = new Date(dates[0]);
  const date = new Date(startDate.getTime());
  const ranges: Array<any> = [];

  while (date <= endDate) {
    ranges.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  let i: number = 0;
  let columnsCopy: Array<any> = [...columns];

  if (ranges.length === 14) columnsCopy = [...columnsCopy, ...columnsCopy];

  while (i < columnsCopy.length) {
    columnsCopy[i] = { ...columnsCopy[i], ...{ date: ranges[i] } };
    i = i + 1;
  }

  // console.log({ columnsCopy });

  return columnsCopy;
};

const renderItem = (colId: number, record: any) => {
  let isEmpty = true;
  const { schedules = [] } = record;

  return (
    <>
      {schedules.map((schedule: any, index: number) => (
        <div key={`${index}-${schedule.day}`}>
          {schedule.day == colId && (
            <>
              {(isEmpty = false)}
              <ShiftItem
                item={{
                  ...schedule,
                  ...{
                    isDayOff: false,
                    value: record.value,
                    technicianName: record.technicianName,
                    recordId: record._id,
                    record: { ...record, colId },
                  },
                }}
              />
            </>
          )}
        </div>
      ))}

      {isEmpty && (
        <NonShift
          item={{
            ...record,
            ...{
              isDayOff: true,
              recordId: record._id,
              record: { ...record, colId },
              recDetails: { item: { day: colId } },
            },
          }}
        />
      )}
    </>
  );
};

export const columns = [
  {
    width: COLUMN_WIDTH,
    colId: "technicianName",
    cellRendererFramework: ({ data }) => ` ${data?.meta?.label?.EN}`,
  },
  {
    colId: 0,
    // field: "sunday" + "  - " + dayjs().format("DD-MM-YYYY"),
    width: COLUMN_WIDTH,
    cellRendererFramework: ({ data }) => renderItem(0, data),
  },
  {
    colId: 1,
    // field: "monday",
    width: COLUMN_WIDTH,
    cellRendererFramework: ({ data }) => renderItem(1, data),
  },
  {
    colId: 2,
    // field: "tuesday",
    width: COLUMN_WIDTH,
    cellRendererFramework: ({ data }) => renderItem(2, data),
  },
  {
    colId: 3,
    // field: "wednesday",
    width: COLUMN_WIDTH,
    cellRendererFramework: ({ data }) => renderItem(3, data),
  },
  {
    colId: 4,
    // field: "thursday",
    width: COLUMN_WIDTH,
    cellRendererFramework: ({ data }) => renderItem(4, data),
  },
  {
    colId: 5,
    // field: "friday",
    width: COLUMN_WIDTH,
    cellRendererFramework: ({ data }) => renderItem(5, data),
  },
  {
    colId: 6,
    // field: "saturday",
    width: COLUMN_WIDTH,
    cellRendererFramework: ({ data }) => renderItem(6, data),
  },
];

export const extendedDates = [
  {
    colId: 7,
    field: "sunday",
    width: COLUMN_WIDTH,
    cellRendererFramework: ({ data }) => renderItem(7, data),
  },
  {
    colId: 8,
    field: "monday",
    width: COLUMN_WIDTH,
    cellRendererFramework: ({ data }) => renderItem(8, data),
  },
  {
    colId: 9,
    field: "tuesday",
    width: COLUMN_WIDTH,
    cellRendererFramework: ({ data }) => renderItem(9, data),
  },
  {
    colId: 10,
    field: "wednesday",
    width: COLUMN_WIDTH,
    cellRendererFramework: ({ data }) => renderItem(10, data),
  },
  {
    colId: 11,
    field: "thursday",
    width: COLUMN_WIDTH,
    cellRendererFramework: ({ data }) => renderItem(11, data),
  },
  {
    colId: 12,
    field: "friday",
    width: COLUMN_WIDTH,
    cellRendererFramework: ({ data }) => renderItem(12, data),
  },
  {
    colId: 13,
    field: "saturday",
    width: COLUMN_WIDTH,
    cellRendererFramework: ({ data }) => renderItem(13, data),
  },
];
