import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import {
  propertyService,
  leaseService,
  baseContractService,
  authService,
} from "../../../../configs/constants";
import { message } from "antd";

export const getTenants = (id, limit, page, search, searchBy,filter = {}) => {

  // let params = new URLSearchParams({
  //   ...param,
  // });
  // const {
  //   data: { result },
  // } = await Axix.get(ticketsTypeUrl + params.toString());
  return async (dispatch) => {
    let params = new URLSearchParams({
        ...filter,
      });
    let tenants = "";
    let url = "";
    if (id) {
      // tenants = `${propertyService}/property/${id}/tenants`;
      tenants = `${leaseService}/tenant/user`; ///leasing/getAll`;
      url = `${tenants}&limit=${limit}&page=${page}`;
    } else {
      tenants = `${leaseService}/tenant/user`; ///leasing/getAll`;
      url = `${tenants}?limit=${limit}&page=${page}&searchby=${`${searchBy}&text=${search}&`+params.toString()} `;
      //${
      //   // search && "&" + searchBy + "=" + search
      //   search && "&search=" + search
      // }`;
    }


    const {
      data: { result },
    } = await axios.get(url);
    dispatch({
      type: action_types.GET_TENANTS,
      data: result,
    });
  };
};

export const emptyTenants = () => {
  return (dispatch) => {
    dispatch({
      type: action_types.EMPTY_TENANTS,
      data: {
        count: 0,
        results: [],
      },
    });
  };
};

export const getPropects = (id, limit, page, search, searchBy) => {
  return async (dispatch) => {
    let prospects = "";
    if (id) {
      prospects = `${propertyService}/property/${id}/tenants`;
    } else {
      prospects = `${leaseService}/leads/get-tenant-prospects`;
    }

    const url = `${prospects}?limit=${limit}&page=${page}${search && "&" + searchBy + "=" + search
      }`;
    const {
      data: { result },
    } = await axios.get(url);
    dispatch({
      type: action_types.GET_PROSPECTS,
      data: result,
    });
  };
};

export const sendInvite = async (payload, message) => {
  try {
    const url = `${authService}/user/sendInvite`;
    const { status } = await axios.post(url, payload, {
      headers: { application: 4 },
    });

    return status;

    console.log(payload, status);
  } catch (error) { }
};
