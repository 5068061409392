import React, { ReactElement } from "react";

// others
import Button from "../../../molecules/Button";
import { useNavigate } from "react-router-dom";
import { deleteBooking } from "../ducks/actions";
import { useDispatch, useSelector } from "react-redux";
import { BackIcon, NextIcon } from "../../../../assets/svg";
import { Row, Col, Space, message, Typography, Tag } from "antd";

interface IProps {
  label?: string;
  children: ReactElement;
  leftBtnLabel?: string;
  rightBtnLabel?: string;
  handleBack?: () => void;
  additionalLabel?: string;
  parentState?: (obj: object) => void;
  handleNext?: (data: any, type: string) => void;
  handleDone?: (data: any, type: string) => void;
  topButton?: { label: string; action: (obj: object) => void };
  statusHeader?: ReactElement;
}

var _ = require("lodash");

const index = (props: IProps) => {
  // hooks initialization
  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  // redux states
  const { bookingDetails } = useSelector((state: any) => state.newBooking);

  // variables
  const isBooking = Object.keys(bookingDetails || {}).length > 0;

  const handleDiscard = async () => {
    props.parentState({ load: true });

    const response = await dispatch(deleteBooking(bookingDetails.leaseId));
    if (response?.status === 200) {
      props.parentState({ load: false });
      navigate("/bookings");
      message.success("Your booking was successfully deleted");
    } else {
      props.parentState({ load: false });
      message.error(response.data.error || response.data.message);
    }
  };

  const { Paragraph } = Typography;
  return (
    <Col span={24} className="cc-main">
      {props?.statusHeader && (
        <Col span={24}>
          <Tag className="fm-status-header" color="cyan">
            <div>{props.statusHeader}</div>
          </Tag>
        </Col>
      )}
      <Col span={24} className="ft-label-container">
        <Row>
          <Row className="cc-fotter_left">
            <p className="ft-main-label">{props.label}</p>
          </Row>
          <Row className="cc-fotter_right">
            {props?.topButton && (
              <Button
                label={props?.topButton?.label || "Save"}
                className="cc-fotter_next-button"
                onClick={() => props?.topButton?.action({})}
              />
            )}
          </Row>
        </Row>
      </Col>

      {props.label && <Col span={24} className="ft-divider" />}

      {props.additionalLabel && (
        <Paragraph className="m-10 smallFont13 fp-para-line">
          {props.additionalLabel}
        </Paragraph>
      )}

      {props.children}

      {(props.leftBtnLabel || props.rightBtnLabel) && (
        <Row className="cc-fotter-container">
          <Row className="cc-fotter_left">
            {props.leftBtnLabel && (
              <Button
                label={props.leftBtnLabel}
                onClick={props.handleBack}
                className="cc-fotter_back-button"
                icon={<BackIcon width="16px" height="16px" />}
              />
            )}
          </Row>
          <Row className="cc-fotter_right">
            {props.rightBtnLabel && (
              <Space size="middle">
                {isBooking && (
                  <Button
                    label="Discard"
                    onClick={handleDiscard}
                    className="bf-discard-button"
                  />
                )}

                <Button
                  type="submit"
                  label="Save Draft"
                  className="bf-save-draft-button"
                />

                {props?.handleNext && (
                  <Button
                    label={props.rightBtnLabel}
                    className="cc-fotter_next-button"
                    icon={<NextIcon width="16px" height="16px" />}
                    onClick={() => props?.handleNext(undefined, "next")}
                  />
                )}

                {props?.handleDone && (
                  <Button
                    label={props.rightBtnLabel}
                    className="cc-fotter_next-button"
                    onClick={() => props?.handleDone(undefined, "done")}
                  />
                )}
              </Space>
            )}
          </Row>
        </Row>
      )}
    </Col>
  );
};

export default index;
