import * as React from "react";

const index = (props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 151 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="mask0_2155_15301"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={151}
      height={150}
    >
      <path d="M150.609 0H0.609375V150H150.609V0Z" fill="white" />
    </mask>
    <g mask="url(#mask0_2155_15301)">
      <path
        d="M71.7141 120.021C108.787 123.773 127.894 114.653 129.035 92.6617C130.745 59.6743 101.931 63.2379 93.5402 54.5439C85.1487 45.85 94.5491 58.2052 78.3884 40.1893C67.6141 28.1785 53.2575 28.1168 35.3187 40.0047C20.8491 51.7813 19.2842 67.2257 30.6234 86.3374C41.9627 105.449 55.6594 116.677 71.7141 120.021Z"
        fill="#E4E4E4"
      />
      <path
        d="M121.365 57.3664C119.631 57.3664 118.227 55.9599 118.227 54.2262C118.227 52.4924 119.631 51.0859 121.365 51.0859C123.099 51.0859 124.503 52.4924 124.503 54.2262C124.503 55.9599 123.099 57.3664 121.365 57.3664ZM121.365 55.5766C122.107 55.5766 122.71 54.9728 122.71 54.2262C122.71 53.4795 122.107 52.8757 121.365 52.8757C120.622 52.8757 120.019 53.4795 120.019 54.2262C120.019 54.9728 120.622 55.5766 121.365 55.5766Z"
        fill="#6DE1FF"
      />
      <mask
        id="mask1_2155_15301"
        style={{
          maskType: "luminance",
        }}
        maskUnits="userSpaceOnUse"
        x={53}
        y={37}
        width={60}
        height={18}
      >
        <path
          d="M112.377 37.5225H54.0317L53.998 54.2056H112.342L112.377 37.5225Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_2155_15301)">
        <path
          d="M54.8668 38.3685C62.3434 38.3685 63.278 42.7877 63.278 47.1989V52.0115C63.278 52.7741 63.8579 53.3937 64.5715 53.3937L108.625 53.0105C109.339 53.0105 109.919 52.3914 109.919 51.6288V46.4405C109.919 41.7077 106.329 37.8704 101.901 37.8704C101.601 37.8704 54.5537 38.3685 54.8668 38.3685Z"
          fill="#084E7E"
        />
        <path
          d="M54.8652 39.1774C54.8652 39.8989 55.4517 40.4844 56.1736 40.4844C101.911 40.4844 101.919 40.4839 101.927 40.4844C104.925 40.4895 107.353 42.939 107.358 45.9629V52.1629H108.651C109.365 52.1629 109.944 51.5788 109.944 50.8587V45.9629C109.963 41.4914 106.386 37.8512 101.954 37.832H101.902H101.419C101.3 37.839 101.185 37.8582 101.068 37.8708H56.1718C55.4503 37.8708 54.8652 38.4559 54.8652 39.1774Z"
          fill="#092B49"
        />
      </g>
      <mask
        id="mask2_2155_15301"
        style={{
          maskType: "luminance",
        }}
        maskUnits="userSpaceOnUse"
        x={36}
        y={36}
        width={72}
        height={79}
      >
        <path d="M107.9 36.6826H36.123V114.837H107.9V36.6826Z" fill="white" />
      </mask>
      <g mask="url(#mask2_2155_15301)">
        <path
          d="M101.901 40.3244V37.8711H55.3186C49.2149 37.8804 44.2691 42.8678 44.2598 49.022V113.053C44.2598 113.773 44.8392 114.357 45.5528 114.357H85.4373C91.5331 114.338 96.4668 109.352 96.471 103.206V45.9543V45.8931C96.5088 42.8945 98.928 40.3328 101.901 40.3286C101.914 40.3286 101.925 40.3248 101.936 40.3244H101.901Z"
          fill="white"
        />
        <path
          d="M55.2922 37.8705C49.188 37.8803 44.2418 42.8677 44.2324 49.0219V86.5088L72.0838 37.8359L55.2922 37.8705Z"
          fill="#C5ECF6"
        />
        <path
          d="M44.2575 64.1421V49.8487C44.2674 43.694 49.2132 38.7071 55.3174 38.6973H101.9M40.9473 112.584C43.729 112.584 44.2323 109.629 44.2323 107.761C44.2323 92.4865 44.2575 81.6893 44.2575 77.816M44.501 68.5089V70.2262"
          stroke="#092B49"
          strokeWidth={2.3215}
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M101.9 37.832H101.418C97.1896 38.0895 93.8892 41.6199 93.8835 45.8932V46.0152V103.174C93.8756 107.068 91.2667 110.468 87.5312 111.453C87.1228 111.559 86.7046 111.611 86.2868 111.615V111.617H86.2546H86.2214H37.8672C39.8083 113.33 42.3476 114.372 45.1293 114.374H45.1466H59.0882H85.4368C85.7209 114.374 86.1602 114.374 86.4452 114.331C86.9476 114.284 87.4457 114.2 87.9359 114.079C92.9434 112.903 96.4896 108.404 96.4962 103.217V45.9367V45.876C96.5331 43.0652 98.6686 40.7386 101.443 40.4844C101.596 40.476 101.89 40.4769 102.042 40.4844L101.9 37.832Z"
          fill="#092B49"
        />
      </g>
      <mask
        id="mask3_2155_15301"
        style={{
          maskType: "luminance",
        }}
        maskUnits="userSpaceOnUse"
        x={33}
        y={99}
        width={54}
        height={16}
      >
        <path
          d="M86.3278 99.9043H33.3203V114.603H86.3278V99.9043Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask3_2155_15301)">
        <path
          d="M101.901 37.832H101.418C97.1898 38.09 93.8907 41.6199 93.8846 45.8932V46.0152V103.174C93.8767 107.068 91.2678 110.469 87.5323 111.453C86.6842 111.674 85.7935 111.674 84.9458 111.453C81.1986 110.455 78.5907 107.031 78.601 103.122V101.382C78.6061 100.662 78.0314 100.074 77.3173 100.069H69.1215H62.5323H52.3739H41.7379H35.3809C34.6673 100.069 34.0879 100.654 34.0879 101.374V103.218C34.0823 109.374 39.0271 114.369 45.1304 114.374H45.1468H59.0884H85.4365C85.7211 114.374 86.1608 114.374 86.4454 114.331C86.9482 114.284 87.4468 114.201 87.937 114.079C92.9444 112.903 96.4902 108.404 96.4963 103.218V45.9372V45.876C96.5332 43.0657 98.6692 40.7381 101.444 40.4848C101.605 40.476 101.765 40.476 101.927 40.4848C104.924 40.4895 107.352 42.9395 107.358 45.9629V52.1633H108.651C109.365 52.1633 109.944 51.5792 109.944 50.8591V45.9629C109.963 41.4914 106.385 37.8517 101.953 37.832H101.901Z"
          fill="#092B49"
        />
      </g>
      <mask
        id="mask4_2155_15301"
        style={{
          maskType: "luminance",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={151}
        height={150}
      >
        <path d="M0.609375 0H150.609V150H0.609375V0Z" fill="white" />
      </mask>
      <g mask="url(#mask4_2155_15301)">
        <mask
          id="mask5_2155_15301"
          style={{
            maskType: "luminance",
          }}
          maskUnits="userSpaceOnUse"
          x={45}
          y={39}
          width={52}
          height={73}
        >
          <path
            d="M96.9887 39.6025C93.9513 39.6025 54.6989 39.6025 54.6989 39.6025L47.8064 44.5091L45.2363 59.3455V100.117C45.2363 100.117 76.5447 100.117 77.4793 100.117C78.4139 100.117 78.5307 100.934 78.5307 101.518C78.5307 102.103 79.1148 111.799 86.0074 111.799C92.8999 111.799 93.8345 105.49 93.8345 101.986C93.8345 98.481 93.8345 46.2614 93.8345 45.0932C93.8345 43.925 95.2363 40.4203 96.9887 39.6025Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask5_2155_15301)">
          <path
            d="M104.118 92.5937C110.982 86.3041 111.457 75.6526 105.181 68.8028C98.9037 61.9527 88.2516 61.4985 81.3877 67.788C74.5237 74.0776 74.047 84.7294 80.324 91.5796C86.6007 98.4294 97.2538 98.8833 104.118 92.5937Z"
            fill="#C6EBF5"
          />
          <path
            d="M104.118 92.5937C110.982 86.3041 111.457 75.6526 105.181 68.8028C98.9037 61.9527 88.2516 61.4985 81.3877 67.788C74.5237 74.0776 74.047 84.7294 80.324 91.5796C86.6007 98.4294 97.2538 98.8833 104.118 92.5937Z"
            stroke="#C6EBF5"
            strokeWidth={2.3215}
            strokeMiterlimit={10}
          />
        </g>
      </g>
      <path
        d="M54.6777 88.6963H67.3203"
        stroke="#A4CDD5"
        strokeWidth={2.3215}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M54.6777 76.9199H67.3203"
        stroke="#A4CDD5"
        strokeWidth={2.3215}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M54.6758 65.1445H83.3323"
        stroke="#084E7E"
        strokeWidth={2.3215}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M54.6758 52.5273H86.6435"
        stroke="#084E7E"
        strokeWidth={2.3215}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M104.969 87.4471C105.85 86.569 107.275 86.5704 108.155 87.449L119.271 98.5467C120.148 99.4224 120.149 100.843 119.273 101.721L119.269 101.725C118.389 102.602 116.963 102.602 116.083 101.723L104.967 90.6256C104.09 89.7494 104.089 88.3284 104.965 87.4513L104.969 87.4471Z"
        fill="#084E7E"
      />
      <path
        d="M107.34 89.7431C114.204 83.4535 114.68 72.802 108.403 65.9522C102.126 59.1021 91.4742 58.6479 84.6103 64.9375C77.7464 71.2271 77.2697 81.8789 83.5467 88.729C89.8233 95.5788 100.476 96.0327 107.34 89.7431Z"
        fill="white"
      />
      <path
        d="M107.34 89.7431C114.204 83.4535 114.68 72.802 108.403 65.9522C102.126 59.1021 91.4742 58.6479 84.6103 64.9375C77.7464 71.2271 77.2697 81.8789 83.5467 88.729C89.8233 95.5788 100.476 96.0327 107.34 89.7431Z"
        stroke="#084E7E"
        strokeWidth={2.3215}
        strokeMiterlimit={10}
      />
      <path
        d="M102.193 81.1208L100.598 82.7142L96.4946 78.6105L92.4217 82.6829L90.8184 81.0801L94.8913 77.0067L90.7871 72.9025L92.3815 71.3086L96.4852 75.4128L100.558 71.3399L102.161 72.9432L98.0885 77.0166L102.193 81.1208Z"
        fill="#084E7E"
      />
    </g>
  </svg>
);
export default index;
