export const paymentTypes = [

    { label: "Rent Installment", value: "rent_installment" },
    { label: "Ejari fee", value: "ejari_fee" },
    { label: "Security Deposit", value: "security_deposit" },
    { label: "Rental Commission", value: "rental_commission" },
    { label: "Administration Charges", value: "administration_charges" },
    { label: "Furniture Charges", value: "furniture_charges" },
    { label: "Parking Charges", value: "parking_charges" },
    { label: "Service Charges", value: "service_charges" },
]