import React, { useState, useEffect } from "react";
import { Card, Space, Typography, Image, List, Button, message } from "antd";
import { LocationIcon } from "../../../../atoms/Icons";
import { useNavigate } from "react-router-dom";
import { propertyService } from "../../../../../configs/constants";
import axios from "axios";
import * as status_types from "../../../../atoms/PropertyCard/constants";
import { PopupConfirm } from "../../../../atoms/Popup";
import Logo from "../../../../../assets/img/BUILDING_placeholder.svg";
import { getPresignedImage } from "../../../../../utils/media";

const { Title, Text } = Typography;

export const GetImageUrl = ({ data }) => {
  const [imgurl, setImgurl] = useState("");

  const geturl = async (item) => {
    if (item.image && item.image.length > 0) {
      let i = await getPresignedImage(item.image[0].data);
      i && setImgurl(i[0]);
    }
  };

  useEffect(() => {
    data && geturl(data);
  }, [data]);

  return (
    <Image
      src={imgurl ? imgurl : Logo}
      placeholder={true}
      preview={false}
      width={164}
      height={115}
      fallback={Logo}
    />
  );
};

export default ({ propertyDetails, propertyId, units  }) => { //updated
  const { buildingName, address } = propertyDetails;
  const navigate = useNavigate();

  // const cloneUnit = async (unit, name) => {
  //   const cloneJson = {
  //     unit_id: unit,
  //     // asset_id,
  //     name: name + " Clone",
  //   };
  //   let url = `${propertyService}/property-landlord/clone-unit`;
  //   try {
  //     await axios.post(url, cloneJson);
  //     message.success("Unit Succcessfully Cloned");
  //     // updated();
  //   } catch (e) {
  //     const { response } = e;
  //     message.error(response.data.message);
  //   }
  // };

  // const Statuses1 = (status) => {
  //   switch (status) {
  //     case status_types.PROPERTY_DISABLED:
  //       return false;
  //     case status_types.PROPERTY_DRAFT:
  //       return false;
  //     case status_types.PROPERTY_RENTED:
  //       return true;
  //     case status_types.PROPERTY_PUBLISHED:
  //       return true;
  //     case status_types.PROPERTY_RESERVED:
  //       return true;
  //   }
  // };

  const Statuses = (stat) => {
    // console.log(stat)
    switch (stat) {
      case status_types.PROPERTY_ENABLED:
        return "Active";
      case status_types.PROPERTY_DISABLED:
        return "Disabled";
      case status_types.PROPERTY_DRAFT:
        return "Vacant";
      case status_types.PROPERTY_RENTED:
        return "Occupied";
      case status_types.PROPERTY_PUBLISHED:
        return "Vacant";
      case status_types.PROPERTY_RESERVED:
        return "Reserved";
    }
  };

  // const onStatus = (id, status) => {
  //   PopupConfirm({
  //     title: `Are you sure you want to ${
  //       status === "disabled" ? "unpublish" : "publish"
  //     } this property?`,
  //     content: "This will change the property status",
  //     onOk: () => onStatusChanged(id, status),
  //     okText: "Confirm",
  //   });
  // };

  // const onStatusChanged = async (id, status) => {
  //   const url = `${propertyService}/property-landlord/change-property-status/${id}`;
  //   try {
  //     const res = await axios.put(url, { property_status: status });
  //     message.success(res?.data?.message || "Status Updated");
  //     // updated();
  //   } catch (e) {
  //     const { response } = e;
  //     message.error(response.data.message);
  //   }
  // };

  return (
    <Card bordered={false} bodyStyle={{ padding: "32px 24px" ,width:"100%"}}>
      <List
        bordered={false}
        className="unit_list"
        itemLayout="horizontal"
        dataSource={units}
        renderItem={(item: any) => 
          (
          <List.Item
            className="no-border"
            // actions={[
            //   <Space direction="vertical" size={4} className="text-right">
            //     <Button className="property_card_btn"
            //       type="primary"
            //       onClick={() =>
            //         navigate(`/property-detail/${propertyId}/unit/${item._id}`,{
            //           state: { parentname: buildingName },
            //         })
            //       }
            //     >
            //       View Unit
            //     </Button>
            //   </Space>,
            // ]}
          >
            <List.Item.Meta
              style={{ alignItems: "center" }}
              avatar={
                <Space style={{ position: "relative" }}>
                  <GetImageUrl data={item.unit?.media} />
                  <Text
                    className={`statusUnit ${
                      item?.unitStatus === "disabled" ? "inactive" : ""
                    } ${item?.unitStatus === "occupied" ? "inactive" : ""} ${
                      item?.unitStatus === "reserved" ? "inactive" : ""
                    }`} //vacant, occupied, reserved
                  >
                  {Statuses(item?.unitStatus)}
                  </Text>
                </Space>
              }
              description={
                <Space size={0} direction="vertical">
                  <Text className="ag-primary fontWeight700">
                  {buildingName} {(item.unit) ? item.unit?.unitCode : ""}

                  </Text>
                  <Text className="smallFont12">{buildingName} { item.unit?.usageType ? ("- " + item.unit?.usageType) : ""}</Text>
                  <Space size={4}>
                    <LocationIcon className="ag-default" />
                    <Text className="smallFont12 ag-primary">
                      {address ? ((address?.addressLine2 ?? address?.addressLine1) + " " + address?.emirates) : ""}
                    </Text>
                  </Space>
                  <Text className="smallFont12">
                    {
                    item.unit ?
                    (item.unit?.unitSubType + " - " + item.unit?.unitType + " - " + item.unit?.areaSqft + " sq.ft.")
                    : ""
                    } 
                    
                    
                  </Text>
                </Space>
              }
            />
          </List.Item>
        )}
      />
    </Card>
  );
};
