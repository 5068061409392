import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { setFilterBooking } from "../app/modules/App/SearchFilters/ducks/actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export const useSearchHistory = (setFilter = null) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchHistory, setSearchHistory] = useState({});
  const dispatch: any = useDispatch();

  useEffect(() => {
    // Load search history from URL parameters on component mount
    const urlParams = new URLSearchParams(location.search);

    const searchHistoryParam = urlParams.get('history');

    if (urlParams) {
      urlParams.forEach((value, key) => {
        setSearchHistory((prevSearchHistory) => ({
          // ...prevSearchHistory,
          [key]: value,
        }));

      })
    }

    // console.log("urlParams",urlParams, location.search,searchHistoryParam);

    if (searchHistoryParam) {
      const parsedSearchHistory = JSON.parse(searchHistoryParam);
      setSearchHistory(parsedSearchHistory);
    }
  }, []);

  useEffect(() => {
    // Update URL parameters whenever search history changes
    const urlParams = new URLSearchParams(location.search);
    // urlParams.set('history', JSON.stringify(searchHistory));
    navigate(`?${urlParams.toString()}`, { replace: true });

    if (setFilter)
      dispatch(setFilter(searchHistory));
  }, [searchHistory]);

  const addToSearchHistory = (key, value) => {

    const urlParams = new URLSearchParams(""); //location.search
    urlParams.set(key, value);
    const newPath = `?${urlParams.toString()}`;
    navigate(newPath, { replace: true });

    setSearchHistory((prevSearchHistory) => ({
      // ...prevSearchHistory,
      [key]: value,
    }));
  };

  return { searchHistory, addToSearchHistory };
};
