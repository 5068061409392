import React, { useState, useEffect } from "react";
import { Card } from "antd";
import { Pie } from "@ant-design/charts";
import { useDispatch, useSelector } from "react-redux";
import { getSegregatedCount } from "../../ducks/actions";

export default (props) => {
  const dispatch = useDispatch();
  const [typeData, setTypeData] = useState([]);
  let normalized: any = [];

  const ticketTypes = //useSelector((state) => state.maintenance.ticketData);

  useEffect(() => {
    // dispatch(getSegregatedCount());
  }, []);

  useEffect(() => {
    // if (ticketTypes) {
    //   Object.entries(ticketTypes).map(([Key, Value]) => {
    //     normalized.push({ type: Key, tickets: Value, value: Value });
    //   });
    //   setTypeData(normalized);
    // }
  }, [ticketTypes]);

  const pieconfig = {
    appendPadding: 10,
    data: typeData,
    angleField: "value",
    colorField: "type",
    radius: 0.7,
    legend: false,
    color: ["#184461", "#1C5D3B", "#447093", "#A2D69E", "#FB7171"],
    tooltip: {
      formatter: (_ref: any) => {
        return { name: _ref.type, value: _ref.value + " ticket(s)" };
      },
    },
    label: {
      type: "spider",

      labelHeight: 28,
      rotate: 30,
      content: function content(_ref) {
        var percent = Math.round(_ref.percent * 100)
          .toString()
          .concat("%");
        return `${_ref.type} \n ${_ref.value} ticket(s)`;
      },
      style: {
        fontSize: 12,
      },
    },
  };

  return (
    <Card bordered={false} title="Ticket Types" className="noicon-space">
      {/* <Pie {...pieconfig} /> */}
    </Card>
  );
};
