import React, { useState, useEffect } from "react";
import { Row, Col, Card, Typography, Button, Form, message } from "antd";
import {
  EditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import { useForm, useWatch } from "react-hook-form";
import { InputField, ReactSelectField, InputSwitch } from "../../../../atoms/FormElement";
import { propertyService } from "../../../../../configs/constants";
import { bedroomCategory, bathList } from "../../ducks/constants";
import axios from "../../../../../utils/axiosInceptor";
import { useDispatch, useSelector } from "react-redux";
import { getUnitSubType } from "../../AddProperty/ducks/actions/addInfoAction";
import { getConfigData } from "../../ducks/actions";
import dayjs from "dayjs";
var _ = require("lodash");

const { Title, Text } = Typography;

export default ({ propertyDetails, parentId, updateApi }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();
  const [noEdit, setNoEdit] = useState(true);
  const [block, setBlock] = useState(false);
  const dispatch: any = useDispatch();
  const configKeys = ["usageTypesList"];
  const configData = useSelector((state: any) => state.dashboard.configData);

  const unitSubTypes = configData?.usageTypesList?.residential?.apartment;
  const blockWatcher = useWatch({ control, name: "block" });



  const onSubmit = async (val) => {

    const {
      //      number_of_beds,
      //       number_of_baths,
      areaSqft,
      unitNumber,
      unitSubType,
      block,
      blockreason
    } = val;
    const postJson = {
      // number_of_beds: Number(number_of_beds.value),
      // number_of_baths: Number(number_of_baths.value),
      unit: {
        ...propertyDetails?.unit,
        areaSqft: areaSqft,
        unitNumber: unitNumber,
        unitSubType: unitSubType.value,
      },
      // usage_type_id: propertyDetails.usage_type_id,
      // unit_type_id: propertyDetails.unit_type_id,
      // unit_subtype_id: val.unit_subtype_id.value,
    };

    let postBlockJson = {};

    let url = `${propertyService}/unit/${propertyDetails.unitId}`;
    let blockUrl = `${propertyService}/unit/blockunit`;

    if(block) {
      postBlockJson = {
        "unitId": propertyDetails.unitId,
        "reason" : blockreason
      }
      await axios
      .post(blockUrl, postBlockJson)
    } else if (propertyDetails?.blockData) {
      postBlockJson = {
        "unitId": propertyDetails.unitId,
        "reason" : blockreason,
        "blockStatus": "unblock"
      }
      await axios
      .post(blockUrl, postBlockJson)
    }

    await axios
      .patch(url, postJson)
      .then((response) => {
        // console.log("respond", response);

        message.success(response.data.message);
        // message.success("Unit Details Updated");
        setNoEdit(true);
        updateApi();
      })
      .catch((error) => {
        // console.log("error", error);
        message.error(error.response.data.error);
        setNoEdit(true);
        updateApi();
      });
  };

  useEffect(() => {
    dispatch(getConfigData(configKeys.join(",")));
  }, []);

  useEffect(() => {
    if (Object.keys(propertyDetails).length && propertyDetails?.unit) {
      const unit = propertyDetails?.unit;
      setValue("unitNumber", unit.unitNumber);
      setValue("areaSqft", unit.areaSqft);
      setValue("blockreason", unit.blockreason);
      setValue("unitSubType", {
        label: unit.unitSubType,
        value: unit.unitSubType,
      });
      if(propertyDetails?.blockData?.block) {
        setBlock(true)
        setValue("block", true);
        setValue("blockreason", propertyDetails?.blockData?.reason);
      }
      // setValue(
      //   "number_of_baths",
      //   bathList.find(
      //     (item) => item.value.toString() == propertyDetails.number_of_baths,
      //   ),
      // );
      // setValue(
      //   "number_of_beds",
      //   bedroomCategory.find(
      //     (item) => item.value.toString() == propertyDetails.number_of_beds,
      //   ),
      // );
      // dispatch(getUnitSubType(propertyDetails.unit_type_id));
    }

    return () => {
      setBlock(false)
      setValue("block", false);
      setValue("blockreason", null);
    }
  }, [propertyDetails]);

  // useEffect(() => {
  //   if (apiUnitSubTypeData.length > 0 && propertyDetails?.unit_subtype_id) {
  //     setValue("unit_subtype_id", {
  //       label: apiUnitSubTypeData.find(
  //         (x) => x?.id == propertyDetails?.unit_subtype_id,
  //       )?.name,
  //       value: apiUnitSubTypeData.find(
  //         (x) => x?.id == propertyDetails?.unit_subtype_id,
  //       )?.id,
  //     });
  //   }
  // }, [apiUnitSubTypeData]);

  return (
    <Form
      layout="vertical"
      name="bookingform"
      onFinish={handleSubmit(onSubmit)}
    >
      <Card
        bordered={false}
        bodyStyle={{ padding: "16px 32px 0" }}
        headStyle={{ padding: "16px 32px 0", borderBottom: 0 }}
        title={
          <Title level={5} className="ag-primary mb-0">
            General Information
          </Title>
        }
        extra={[
          <Button
            key={1}
            type="link"
            className={`p-0 no-border no-shadow ${noEdit && "d-none"}`}
            icon={<CloseCircleOutlined />}
            onClick={() => setNoEdit(true)}
            htmlType="button"
          >
            Cancel
          </Button>,
          <Button
            key={2}
            type="link"
            className={`p-0 no-border no-shadow ${noEdit && "d-none"}`}
            icon={<CheckCircleFilled />}
            htmlType="submit"
          >
            Save
          </Button>,
          <Button
            key={3}
            type="link"
            className={`p-0 no-border no-shadow ${!noEdit && "d-none"}`}
            icon={<EditOutlined />}
            onClick={() => setNoEdit(false)}
            htmlType="button"
          />,
        ]}
      >
        <Row gutter={24}>
          <Col span={6}>
            <InputField
              isRequired={!noEdit}
              label="Unit Number"
              fieldname="unitNumber"
              control={control}
              classes={`${noEdit ? "noEditLabel" : ""}`}
              initValue=""
              iProps={{
                placeholder: "Unit Number",
                readOnly: noEdit,
                className: `${noEdit ? "noEdit" : ""}`,
              }}
              rules={{ required: "Please enter Unit Number" }}
              validate={errors.unitNumber && "error"}
              validMessage={errors.unitNumber && errors.unitNumber.message}
            />
          </Col>
          <Col span={6}>
            <ReactSelectField
              isRequired={!noEdit}
              label="Unit Sub Type"
              fieldname="unitSubType"
              control={control}
              classes={`${noEdit ? "noEditLabel" : ""}`}
              initValue=""
              iProps={{
                placeholder: "Select one",
                isDisabled: noEdit,
                className: `${noEdit ? "disablerselect" : ""}`,
              }}
              selectOption={_.map(
                unitSubTypes,
                (item) => ({
                  value: item,
                  label: item,
                }),
              )}
              rules={{ required: "Please enter Unit Sub Type" }}
              validate={errors.unitSubType && "error"}
              validMessage={errors.unitSubType && errors.unitSubType.message}
              // onChange={(e) => {
              //   if (propertyDetails.usage_type_id != 1) {
              //     setValue("number_of_beds", {
              //       label: e.label,
              //       value: e.label == "Studio" ? 0 : Number(e.label.charAt(0)),
              //     });
              //   } else {
              //     setValue("number_of_beds", "");
              //   }
              // }}
            />
          </Col>
          {/* <Col span={6}>
            <ReactSelectField
              isRequired={!noEdit}
              label="Bed Rooms"
              fieldname="number_of_beds"
              control={control}
              classes={`${noEdit ? "noEditLabel" : ""}`}
              initValue=""
              iProps={{
                placeholder: "Select one",
                isDisabled: true,
                className: `${noEdit ? "disablerselect" : ""}`,
              }}
              selectOption={bedroomCategory}
            />
          </Col>
          <Col span={6}>
            <ReactSelectField
              isRequired={!noEdit}
              label="Bath Rooms"
              fieldname="number_of_baths"
              control={control}
              classes={`${noEdit ? "noEditLabel" : ""}`}
              initValue=""
              iProps={{
                placeholder: "Select one",
                isDisabled: noEdit,
                className: `${noEdit ? "disablerselect" : ""}`,
              }}
              selectOption={bathList}
              rules={{ required: "Please enter bathrooms" }}
              validate={errors.number_of_baths && "error"}
              validMessage={
                errors.number_of_baths && errors.number_of_baths.message
              }
            />
          </Col> */}
          <Col span={6}>
            <InputField
              isRequired={!noEdit}
              label="Size, Sq.ft"
              fieldname="areaSqft"
              control={control}
              classes={`${noEdit ? "noEditLabel" : ""}`}
              initValue=""
              iProps={{
                readOnly: noEdit,
                className: `${noEdit ? "noEdit" : ""}`,
              }}
              rules={{
                required: "Please enter Size",
                pattern: {
                  value: /^(?!\.?$)\d{0,9}(\.\d{0,2})?$/,
                  message:
                    "Value should be a Number and not greater than 2 decimal places",
                },
              }}
              validate={errors.areaSqft && "error"}
              validMessage={errors.areaSqft && errors.areaSqft.message}
            />
          </Col>
        </Row>
        {
          propertyDetails.unitStatus === 'vacant' && 
          <Row gutter={24}>
            <Col span={6}>
              <Text className={`${noEdit ? "noEditLabel" : ""}`} style={noEdit ? {fontWeight: 500, color: '#767676', fontSize: 14} : {fontSize: 14}}>
                Block Unit
              </Text>
              <div style={{marginTop: 16, marginBottom: 20}}>
              <InputSwitch
                label="Blocking Reason"
                fieldname={`block`}
                iProps={{
                  disabled: noEdit,
                }}
                control={control}
                valueGot={block}
                setValue={setValue}
              />
              </div>
              
            </Col>
            {
              blockWatcher && (
                <Col span={6}>
                  <InputField
                    isRequired={!noEdit}
                    label="Blocking Reason"
                    fieldname="blockreason"
                    control={control}
                    classes={`${noEdit ? "noEditLabel" : ""}`}
                    initValue=""
                    rules={{
                      required: "Please enter Blocking Reason"
                    }}
                    iProps={{
                      placeholder: "Blocking Reason",
                      readOnly: (noEdit),
                      className: `${(noEdit) ? "noEdit" : ""}`,
                    }}
                    validate={errors.blockreason && "error"}
                    validMessage={errors.blockreason && errors.blockreason.message}
                  />
                </Col>
              )
            }

            {
              ( propertyDetails?.blockData?.name && blockWatcher ) && (
                <Col span={6}>
                  <InputField
                    isRequired={false}
                    label="Blocked By"
                    fieldname="blockedBy"
                    initValue={`${propertyDetails?.blockData?.name} on ${dayjs(propertyDetails?.blockData?.createdAt).format('D MMMM YYYY')}`}
                    control={control}
                    classes={`${noEdit ? "noEditLabel" : ""}`}
                    iProps={{
                      readOnly: true,
                      className: `noEdit`,
                    }}
                  />
                </Col>
              )
            }
          </Row>
        }
      </Card>
    </Form>
  );
};
