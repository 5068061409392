import React from "react";

const index = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.125 5.25H19.875C21.1055 5.25 22.125 6.26953 22.125 7.5V9.64453C22.125 9.99609 21.8789 10.3125 21.5625 10.418C20.8945 10.6641 20.4375 11.2969 20.4375 12C20.4375 12.7383 20.8945 13.3711 21.5625 13.6172C21.8789 13.7227 22.125 14.0391 22.125 14.3906V16.5C22.125 17.7656 21.1055 18.75 19.875 18.75H4.125C2.85938 18.75 1.875 17.7656 1.875 16.5V14.3906C1.875 14.0391 2.08594 13.7227 2.4375 13.6172C3.07031 13.3711 3.5625 12.7383 3.5625 12C3.5625 11.2969 3.07031 10.6641 2.4375 10.418C2.08594 10.3125 1.875 9.99609 1.875 9.64453V7.5C1.875 6.26953 2.85938 5.25 4.125 5.25ZM3.5625 7.5V9.08203C4.54688 9.67969 5.25 10.7695 5.25 12C5.25 13.2656 4.54688 14.3555 3.5625 14.9531V16.5C3.5625 16.8164 3.80859 17.0625 4.125 17.0625H19.875C20.1562 17.0625 20.4375 16.8164 20.4375 16.5V14.9531C19.418 14.3555 18.75 13.2656 18.75 12C18.75 10.7695 19.418 9.67969 20.4375 9.08203V7.5C20.4375 7.21875 20.1562 6.9375 19.875 6.9375H4.125C3.80859 6.9375 3.5625 7.21875 3.5625 7.5ZM15.9375 13.6875V10.3125H8.0625V13.6875H15.9375ZM6.375 9.75C6.375 9.15234 6.86719 8.625 7.5 8.625H16.5C17.0977 8.625 17.625 9.15234 17.625 9.75V14.25C17.625 14.8828 17.0977 15.375 16.5 15.375H7.5C6.86719 15.375 6.375 14.8828 6.375 14.25V9.75Z" fill="#24435D" />
        </svg>
    );
};

export default index;
