import React, { useEffect, useState } from "react";
import { Table } from "antd";
import moment from "moment";
import { statusLabel } from "../../../../utils/media";

export default (props) => {
  const { approvalData } = props;

  const col = [
    // { title: "#", dataIndex: "sno", key: "sno", width: 80 },
    { title: "Created By", dataIndex: "updatedBy", key: "updatedBy" },
    // { title: "Managed By", dataIndex: "managedBy", key: "managedBy" },
    // { title: "Stage", dataIndex: "stage", key: "stage" },
    { title: "Status", dataIndex: "status", key: "status",
    render: (text) => statusLabel(text),
    
  },
    { title: "Comment", dataIndex: "comments", key: "comments" },
    { title: "Reason", dataIndex: "reason", key: "reason" },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => moment(text).format("DD-MM-YYYY hh:mmA"),
    },
    {
      title: "Uploaded At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => moment(text).format("DD-MM-YYYY hh:mmA"),
    },
  ];

  return <Table columns={col} dataSource={approvalData} pagination={false} />;
};
