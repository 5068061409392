import * as action_types from "./constants";

const initialState = {
  bookingRenewalList: [],
  bookingPendingRequests: { data: [], totalCount: 0 },
  leasePendingRequests: { data: [], totalCount: 0 },
  planPendingRequests: { data: [], totalCount: 0 },
  pendingRenewalRequests: { data: [], totalCount: 0 },
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.GET_BOOKINGRENEWALFORMS:
      return { ...state, bookingRenewalList: data };
    case action_types.GET_BOOKING_REQUESTS:
      return { ...state, bookingPendingRequests: data };
    case action_types.GET_LEASE_REQUESTS:
      return { ...state, leasePendingRequests: data };
    case action_types.GET_PLAN_REQUESTS:
      return { ...state, planPendingRequests: data };
    case action_types.GET_RENEWAL_REQUESTS:
      return { ...state, pendingRenewalRequests: data };
    default:
      return state;
  }
};