import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import {
  baseContractService,
  propertyService,
  scheduleVisitService,
} from "../../../../configs/constants";

const mapUrl = `${propertyService}/property-landlord/dashboard/fetchPropertyLongLat`;
const statsUrl = `${propertyService}/property-landlord/dashboard/stats`;
// const properties = `${propertyService}/property-landlord/user/properties`;
const properties = `${propertyService}/property/getAll`;
const propertyList = `${propertyService}/property/propertyList`;

const configUrl = `${propertyService}/configData`;

const propertiesCount = `${propertyService}/property-landlord/dashboard/property-status`;

export const getPinProperties = () => {
  return async (dispatch) => {
    const {
      data: { result },
    } = await axios.get(mapUrl);
    dispatch({
      type: action_types.GET_PROPERTIES_LATLONG,
      data: result,
    });
  };
};

export const getStats = () => {
  return async (dispatch) => {
    const {
      data: { result },
    } = await axios.get(statsUrl);
    dispatch({
      type: action_types.GET_DASHBOARD_STATS,
      data: result,
    });
  };
}; 

export const getPropertiesList = (
  search,
  page,
  limit,
  card,
  status,
  isList,
) => {
  return async (dispatch) => {
    let url = `${properties}?${isList && "property_type=unit" + "&"}${
      status && "property_status=" + status + "&"
    }page=${page}${"&perPage=" + limit}${search && "&search=" + search}`;

    // let url = `${properties}?${isList && "property_type=unit" + "&"}${
    //   search && "asset_name=" + search + "&"
    // }perPage=${limit}&page=${page}`;



    const {
      data: { result },
    } = await axios.get(url);

    if (card === false) {
      if (status === "rented") {
        dispatch({
          type: action_types.GET_PROPERTIES_LIST_O,
          data: result,
        });
      } else {
        dispatch({
          type: action_types.GET_PROPERTIES_LIST_V,
          data: result,
        });
      }
    } else {
      dispatch({
        type: action_types.GET_PROPERTIES_CARD,
        data: result,
      });
    }
  };
};

export const getPropertiesDrop= () => {
  return async (dispatch) => {
    let url = `${propertyList}`;



    const {
      data: { result },
    } = await axios.get(url);

      dispatch({
        type: action_types.GET_PROPERTIES_DROP,
        data: result,
      });
  
  };
};

export const getPropertiesDropBUID = (id) => {
  return async (dispatch) => {
    let url = `${propertyList}?businessUnitId=${id}`;

    const {
      data: { result },
    } = await axios.get(url);

      dispatch({
        type: action_types.GET_PROPERTIES_DROP,
        data: result,
      });
  
  };
};

export const getConfigData = (keys) => {
  return async (dispatch) => {
    let url = `${configUrl}?key=${keys}`;



    const {
      data: { result },
    } = await axios.get(url);

      dispatch({
        type: action_types.GET_CONFIG_DATA,
        data: result,
      });
  
  };
};




export const getScheduledVisits = (propertyId, startDate, endDate) => {
  return async (dispatch) => {
    let url = "";
    let vtype: any = typeof propertyId;

    if (vtype == "object") {
      url = `${propertyService}/property/user-schedule-visits?visit_purpose_type=property_visit&start_date=${startDate}&end_date=${endDate}`;
      const {
        data: { result },
      } = await axios.post(url, { asset_id: propertyId });
      dispatch({
        type: action_types.GET_SCHEDULED_VISITS,
        data: result,
      });
    } else {
      url = `${scheduleVisitService}/${propertyId}/schedule-visits?start_date=${startDate}&end_date=${endDate}`;
      const {
        data: { result },
      } = await axios.get(url);
      dispatch({
        type: action_types.GET_SCHEDULED_VISITS,
        data: result,
      });
    }
  };
};

export const emptySchedule = () => {
  return (dispatch) => {
    dispatch({
      type: action_types.EMPTY_SCHEDULE,
      data: [],
    });
  };
};

export const getPropertyVisitByID = (visitId) => {
  return async (dispatch) => {
    let url = `${scheduleVisitService}/visits/${visitId}`;
    const {
      data: { result },
    } = await axios.get(url);
    dispatch({
      type: action_types.GET_PROPERTY_VISITS,
      data: result,
    });
  };
};

export const updateScheduledVisitRequest = (visitId, visitStatus) => {
  return async (dispatch) => {
    let url = `${scheduleVisitService}/update-visit-status/${visitId}`;
    const {
      data: { result },
    } = await axios.put(url, { status: visitStatus });
    dispatch({
      type: action_types.UPDATE_SCHEDULED_VISIT_REQUEST,
      data: result,
    });
  };
};

export const getCountProperties = () => {
  return async (dispatch) => {
    const {
      data: { result },
    } = await axios.get(propertiesCount);
    dispatch({
      type: action_types.GET_PROPERTIES_COUNT,
      data: result,
    });
  };
};

export const downloadPropertyData = (assetID) => async (dispatch) => {
  const {
    data: { result },
  } = await axios.get(
    `${propertyService}/property-landlord/export/tickets?asset_id=${assetID}`,
  );
  dispatch({
    type: action_types.DOWNLOAD_DATA,
    data: result,
  });
};

export const resetState = () => {
  return (dispatch) => {
    dispatch({
      type: action_types.RESET_STATE,
      data: null,
    });
  };
};

export const getChequeList = (id) => async (dispatch) => {
  const {
    data: { result },
  } = await axios.get(`${baseContractService}/get-payment-schedule/${id}`);
  dispatch({
    type: action_types.CHEQUE_LIST,
    data: result,
  });
};
