import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Space,
  Typography,
  message,
  Spin,
} from "antd";
import { useForm, useWatch } from "react-hook-form";
import {
  InputField,
  ReactSelectField,
  TimeField
} from "../../../../../atoms/FormElement";
import moment from 'moment';
import dayjs from "dayjs";
import { ExclamationCircleOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { addShift, editShift } from "../../ducks/actions";


const { Paragraph, Title } = Typography;

const defaultVal = {
  name: "",
  description: "",
  start_time: "",
  end_time: "",
  break: {label: 'No break', value: '0'},
  breakStart_time: "",
};

const modifyShift = (props) => {

  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [breakTime, setBreakTime] = useState(null);

  const { control, handleSubmit, formState: { errors }, reset, setValue, setError } = useForm({
    defaultValues: defaultVal,
  });

  const _break: any = useWatch({ control, name: "break" });

  const isNew = Object.keys(props?.data).length === 0 && props?.data.constructor === Object;

  const breakTimeList = [
    {label: 'No break', value: '0'},
    {label: '30 Minutes', value: '00:30'},
    {label: '45 Minutes', value: '00:45'},
    {label: '1 Hour', value: '01:00'},
    {label: '1 Hour 30 Minutes', value: '01:30'},
    {label: '2 Hours', value: '02:00'},
    {label: '3 Hours', value: '03:00'},
    {label: '4 Hours', value: '04:00'},
  ]

  function timeDifference(startTime, endTime) {
    // Parse the input strings as Date objects
    const startDate = new Date(`1970-01-01T${startTime}`).getTime();
    const endDate = new Date(`1970-01-01T${endTime}`).getTime();

    // Calculate the time difference in milliseconds
    const timeDiff = endDate - startDate;

    // Convert the time difference to hours, minutes, and seconds
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    // Return the time difference as an object
    return {
        hours: hours,
        minutes: minutes,
        seconds: seconds
    };
}

  const setApiValuesToForm = (key, value) => {
    switch (key) {
      case 'shifts':
        {
          
          const shift1 = value[0];
          const shift2 = value[1];
          
          setStartTime(shift1.from)
          setEndTime(shift1.to)
          if(shift2) {
            
            const timeToDiff = timeDifference(shift1.to, shift2.from)
            const duration = moment.duration({ hours: timeToDiff.hours, minutes: timeToDiff.minutes });
            const formattedDuration = moment.utc(duration.asMilliseconds()).format('HH:mm');
            setValue("break", {label: breakTimeList.find(t => t.value === formattedDuration)?.label, value: formattedDuration})
            setBreakTime(shift1.to)
            setEndTime(shift2.to)
          }
        }
        break;
    
      default:
        setValue(key, value);
        break;
    }
  }

  const onChangeOrganzation = (e) => {
    // showBreakStartTime(e.value === '0')
    
  };

  useEffect(() => {
    if(props.data) {
      Object.entries(props?.data).forEach(([key, value]) => {
        setApiValuesToForm(key, value);
      })
    }
  }, [props.data, props])

  const onBack = () => {
    reset();
    props.onCancel();
  };

  const onFinish = async (values) => {

    if(!startTime) {
      setError('start_time', {type: 'manual', message: 'Please select the shift start time' })
      return
    }

    if(!endTime) {
      setError('end_time', {type: 'manual', message: 'Please select the shift end time' })
      return
    }

    if(values.break.value !== '0' && !breakTime) {
      setError('breakStart_time', {type: 'manual', message: 'Please select the shift start time' })
      return
    }

    let payload;

    if(values.break.value !== '0') {
      const momentTime1 = moment(breakTime, 'HH:mm');
      const momentTime2 = moment(values.break.value, 'HH:mm');
  
      const totalTime = momentTime1.add(momentTime2.hours(), 'hours').add(momentTime2.minutes(), 'minutes');

      const formattedTotalTime = totalTime.format('HH:mm');

      payload =  {
          "name": values.name,
          "shifts": [{
            "from": startTime,
            "to": breakTime,
          }, {
            "from": formattedTotalTime,
            "to": endTime,
          } ],
          "description": values.description,
          "profileId": values.profileId
        }
    } else {
      payload =
        {
          "shifts": [{
            "from": startTime,
            "to": endTime,
          } ],
          "name": values.name,
          "description": values.description,
          "profileId": values.profileId
        }
    }

    try {
      
      if(isNew) {
        await addShift(payload)
      } else {
        await editShift(payload)
      }
      
      reset();
      setLoader(false);
      message.success(`Shift ${isNew ? 'added' : 'updated'} successfully`);
      setSubmited(true);
      props.onSubmit()
    } catch (e) {
      setLoader(false);
      setisError(true);
      const { response } = e;
      
      setErrorMessage(response?.data?.error);
    }
  }

  const disabledTime = (time) => {
    return{
      disabledHours: () => Array.from({ length: 25 }, (_, index) => index).filter((hour) => hour <= parseInt(startTime?.split(':')?.[0])),
    }
  }

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
              {/* <Paragraph className="ag-default fontWeight600 mb-0">Loading</Paragraph> */}
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
          <Row gutter={24}>
            <Col span={18}>
              <InputField
                isRequired={true}
                fieldname="name"
                label="Name"
                control={control}
                rules={{
                  required: "Please enter shift name",
                }}
                iProps={{ placeholder: "Enter Shift Name" }}
                initValue=""
                validate={errors.name && "error"}
                validMessage={errors.name && errors.name.message}
              />
            </Col>
            <Col span={24}>
              <Row gutter={24}>
                <Col span={6}>
                  <TimeField
                    isRequired={true}
                    fieldname="start_time"
                    label="Start Time"
                    control={control}
                    rules={{
                      setValueAs: (value) => moment(value).format("HH:mm"),
                    }}
                    initValue=""
                    iProps={{ 
                      format: "HH:mm", 
                      value: startTime && dayjs(startTime, "HH:mm") || "", 
                      onChange: (timeChange, timeString) => {
                        setStartTime(timeString)
                      },
                    }}
                    setValue={setValue}
                    validate={errors.start_time && "error"}
                    validMessage={
                      errors.start_time && errors.start_time.message
                    }
                  />
              </Col>
              <Col span={6}>
                <TimeField
                  isRequired={true}
                  fieldname="end_time"
                  label="End Time"
                  control={control}
                  rules={{
                    setValueAs: (value) => moment(value).format("HH:mm"),
                  }}
                  initValue=""
                  iProps={{ 
                    format: "HH:mm", 
                    value: endTime && dayjs(endTime, "HH:mm") || "", 
                    onChange: (timeChange, timeString) => {
                      setEndTime(timeString)
                    },
                    disabledTime: disabledTime
                  }}
                  setValue={setValue}
                  validate={errors.end_time && "error"}
                  validMessage={
                    errors.end_time && errors.end_time.message
                  }
                />
              </Col>
              <Col span={6}>
                <ReactSelectField
                  control={control}
                  initialValue={[]}
                  label="Break"
                  fieldname="break"
                  selectOption={breakTimeList}
                  onChange={(e) => onChangeOrganzation(e)}
                  validate={errors.break && "error"}
                  validMessage={errors.break && errors.break.message}
                  iProps={{ placeholder: "Select break time"}}
                />
              </Col>
              {
                _break.value !== '0' && 
                <Col span={6}>
                  <TimeField
                    isRequired={true}
                    fieldname="breakStart_time"
                    label="Break Start"
                    control={control}
                    rules={{
                      setValueAs: (value) => moment(value).format("HH:mm"),
                    }}
                    initValue=""
                    iProps={{ 
                      format: "HH:mm", 
                      value: breakTime && dayjs(breakTime, "HH:mm") || "", 
                      onChange: (timeChange, timeString) => {
                        setBreakTime(timeString)
                      },
                      disabledTime: disabledTime
                    }}
                    validate={errors.breakStart_time && "error"}
                    validMessage={
                      errors.breakStart_time && errors.breakStart_time.message
                    }
                  />
                </Col>
              }
              </Row>
            </Col>
            
            <Col span={18}>
              <InputField
                fieldname="description"
                label="Description"
                control={control}
                iProps={{ placeholder: "Enter Description" }}
                initValue=""
              />
            </Col>
          </Row>
          <Row gutter={24} justify="space-between">
            <Col>
              <Button className="ag-gray-button-outline" onClick={onBack}>
                Back
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button" htmlType="submit">
                {isNew ? 'Save' : 'Update'}
              </Button>
            </Col>
          </Row>
        </>
      ) : 
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    setSubmited(false);
                    setisError(false);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </>
        )
      }
    </Form>
  );
};

export default modifyShift;
