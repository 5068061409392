
import React, { useState, useEffect, useRef } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  message,
  Spin,
  Card,
  Form,
} from "antd";


import axios from 'axios';

import { useForm } from "react-hook-form";
import {
  maintenanceService, serverUrl
} from "../../../../../../configs/constants";
import type { UploadFile } from 'antd/es/upload/interface';
import { InboxOutlined } from '@ant-design/icons';


import { CSVLink } from "react-csv";
import { BsXCircleFill, BsDownload, BsCloudUpload, BsFillCheckCircleFill, BsPlusCircleDotted } from "react-icons/bs";
import { Upload } from 'antd';
import { RcFile } from "antd/lib/upload/interface";
const { Dragger } = Upload;
const { Content } = Layout;

var _ = require("lodash");



// CreateTechnician;

export default (props) => {
  // const [uploadProgress, setUploadprogress] = useState(0);

  const [failedData, setFailed] = useState([]);
  const [successData, setSuccess] = useState(0);
  const [loading, setLoading] = useState(false);

  const { control, formState: { errors }, setValue, handleSubmit } = useForm();

  const headers = [
    { label: "Employee Number", key: "employeeNumber" },
    { label: "Technician Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Mobile", key: "mobile" },
    { label: "Reason", key: "reason" }
  ];

  const csvReport = {
    data: failedData,
    headers: headers,
    filename: 'Technician_Failure_log.csv'
  };



  // csv
  const [file, setFile] = useState();
  const [array, setArray] = useState([]);

  // const [filelist, setfilelist] = useState([]);
  const [technicianFile, setTechnician] = useState<UploadFile[]>([]);

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };


  const resetModal = () => {
    setTechnician([]);
    setFailed([]);
    setSuccess(0);
  }

  const onFinish = async (values) => {

    resetModal();
    setLoading(true);



    if (technicianFile[0]) {
      const url = `${maintenanceService}/maintenance/UploadFile`;


      let fd = new FormData();
      fd.append('file_asset', technicianFile[0] as RcFile);
      // const config = { headers: fd.getHeaders ? fd.getHeaders() : { 'Content-Type': 'multipart/form-data' } };



      await axios.post(url, fd, { headers: { 'Accept': '*/*', 'Content-Type': 'multipart/form-data' } }).then((response) => {

        const { data } = response;



        if (data?.result?.faildArr?.length > 0) {
          // console.log("sorry failed by: ", data?.result?.failedData?.length);
          const failedArry = _.map(data.result.faildArr, (e) => {
            return {
              "technicianname": e.technicianname,
              "serviceprovidername": e.serviceprovidername,
              "email": e.email,
              "mobile": e.mobile,
              "reason": e.reason
            }
          });
          setFailed(failedArry);
        }
        if (data?.result?.finalArr?.length > 0) {
          setSuccess(data.result.finalArr.length);
          message.success(response.data.message);
          props.onSubmit();
        }
        setLoading(false);
        setTechnician([]);

      }).catch((error) => {

        setLoading(false);
        message.error(error.response.data.error)

      })

    } else {
      setLoading(false);
      message.error("Please select a file to upload");
    }

  };
  const headerKeys = Object.keys(Object.assign({}, ...array));

  const technicianFileProps = {
    className: "file-upload",
    name: "file",
    accept: 'csv',
    multiple: false,
    // showUploadList: false,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {

      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} Please upload only CSV file`);
      }
    },
    onDrop(e) {

      // setTechnician(e.dataTransfer.files);
    },
    onRemove: (file) => {
      setTechnician([]);
    },
    beforeUpload: (file) => {
      setTechnician([file]);
      return false;
    },
    technicianFile,
  };


  return (

    <Layout className="layout-space prop_popup">
      <Content className="main-content">

        <Row gutter={24}>

          <Col flex="1 1 500px">
            {/* File Uploader */}
            <div style={{ textAlign: "center" }}>
              {/* <h1 className="csvheading">Upload your data through CSV file</h1> */}

              <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
                <Row gutter={24}>


                  <Dragger {...technicianFileProps}
                    fileList={technicianFile}

                    className="drag_drop_csv">
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                      Download the sample template and fill the data as per the template, convert master file into CSV and upload it.
                      <br></br><br></br>
                      <span>Note: </span>Only CSV files are accepted.                    </p>
                  </Dragger>
                  <div className="csvmodel">
                    {/* <a className="downloadcsv" href="https://d2mykrr58m5d3e.cloudfront.net/templates/residential/ResidentialActiveTenancyContractTemplate_v1.xlsx" download>
                      {<BsDownload />} Download residential template file
                    </a> */}
                    <a className="downloadcsv" href={`${serverUrl}/templates/technician/TechnicianTemplate_v1.xlsx`} download>
                      {<BsDownload />} Download template file
                    </a>

                    <a className="downloadcsv" onClick={() => props.onAddTechnician()}>
                    {<BsPlusCircleDotted />}Add single technician
                    </a>
                    {/* //https://dw9ddt4u56hxs.cloudfront.net */}
                  </div>

                  <Col span={24} className="csv_error_wrap">
                    {loading ? (
                      <Spin />
                    ) :

                      (<>

                        {successData > 0 ?
                          (

                            <p className="success_csv">{<BsFillCheckCircleFill />}
                              <>Successfully Added {successData} Records</>
                            </p>

                          )
                          : ""}

                        {failedData?.length > 0 ? (
                          <div className="csv_error_log">
                            <span className="csv_error"> {<BsXCircleFill color='red' />} {failedData?.length} - Records Failed <CSVLink className="csv_log" {...csvReport}>{<BsDownload />} Download Failure Log</CSVLink></span><br />
                            {/* <span>{failedData?.length} Records Failed</span><br /> */}
                            {/* <span><CSVLink {...csvReport}>{<BsDownload/>} Download Failure Log</CSVLink></span> */}
                          </div>
                        ) : ""}
                        <Button htmlType="submit" disabled={loading} type="primary" size="large" className="csv_publish">
                          {<BsCloudUpload />}Publish
                        </Button>

                      </>)}
                  </Col>
                </Row>
              </Form>

              <br />
            </div>
          </Col>

        </Row>
      </Content>
    </Layout>
  );
};
