import { useDispatch } from "react-redux";
import { baseContractService, contractService } from "../../../../../configs/constants";
import axios from "../../../../../utils/axiosInceptor";

import * as action_types from "./constants";

// export const getLeaseForms = (limit, page = 1, value = "", field) => async (
//   dispatch,

// ) => {
//   // const url = `${contractService}/booking?unitstatus=occupied&limit=${limit}&page=${page}&searchby=${`${field}&text=${value}`}`;
//   const {
//     data: { result },
//   } = await axios.get(url);
//   // if (result) {
//   //   dispatch({
//   //     type: GET_LEASEFORMS,
//   //     data: result,
//   //   });
//   // }
// };
export const setFilterBooking = (filter) => {
  return async (dispatch) => {
    dispatch({
      type: action_types.GET_BOOKINGS_STATE,
      data: filter,
    });
  }
}

export const setFilterLease = (filter) => {
  return async (dispatch) => {
    dispatch({
      type: action_types.GET_LEASES_STATE,
      data: filter,
    });
  }
}

export const setFilterRenewal = (filter) => {
  return async (dispatch) => {
    dispatch({
      type: action_types.GET_RENEWALS_STATE,
      data: filter,
    });
  }
}

export const setFilterUnits = (filter) => {
  return async (dispatch) => {
    dispatch({
      type: action_types.GET_UNITS_STATE,
      data: filter,
    });
  }
}

export const setFilterUnitStatus = (filter) => {
  return async (dispatch) => {
    dispatch({
      type: action_types.GET_UNITS_STATUS_STATE,
      data: filter,
    });
  }
}


export const setFilterTenant = (filter) => {
  return async (dispatch) => {
    dispatch({
      type: action_types.GET_TENANTS_STATE,
      data: filter,
    });
  }
}


