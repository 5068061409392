import { USER_SCREENS_ACCESS } from "./constant";
  
  const initialState = {
    userScreenAccess: []
  };
  
  export const userScreens = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
      case USER_SCREENS_ACCESS:
        return { ...state, userScreenAccess: data };
      default:
        return state;
    }
  };
  