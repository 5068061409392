import React from "react";
import { Breadcrumb } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const BreadCrumb = (props) => {
  const base = props.base ? props.base : "/dashboard";
  return (
    <Breadcrumb separator={<RightOutlined />} className="breadcrum">
      <Breadcrumb.Item>
        {/* <Link to={base}>Dashboard</Link> */}
       
      </Breadcrumb.Item>
     {props.list.map((item, index) => (
        <Breadcrumb.Item key={index} className="sentanceCase">
          {index + 1 === props.list.length ? (
            item.label
          ) : (
            <Link to={item.route}>{item.label}</Link>
          )}
        </Breadcrumb.Item>
      ))} 
    </Breadcrumb>
  );
};

export default BreadCrumb;
