import React, { useState, useEffect,memo } from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Card,
  Image,
  Typography,
  message,
  Upload,
  Space,
  Spin,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import PropertyFigureChip from "../../../../atoms/PropertyFigureChip";
import {
  MaintenanceIcon,
  ScheduleIcon,
  LocationIcon,
} from "../../../../atoms/Icons";
import ImgCrop from "antd-img-crop";
import {
  EQARI_GOOGLE_KEY,
  propertyService,
} from "../../../../../configs/constants";
import { WrappedMap } from "../../../../../utils/WrappedMap";
import GalleryImages from "./components/GalleryImages";

import {
  getSingleUpload,
  uniquiFileName,
} from "../../../../../utils/fileUpload";
import axios from "axios";
import PicturePlaceholder from "../../../../../assets/img/Picture-Placeholder.svg";
import placeholder from '../../../../../assets/img/BUILDING_placeholder.svg';
import { getPresignedImage } from "../../../../../utils/media";
const { Text, Title } = Typography;

export default ({ propertyDetails,fullUnitName, updated, isUnit, parentName }) => {
  const {
    buildingName,
    address,
    image,
    visits,
    maintenance_request,
    asset_id,
  } = propertyDetails;

  const navigate = useNavigate();
  const [imageData, setImageData] = useState("");
  const [galleryImages, setGalleryImages] = useState([]);
  const [fileList, setFilelist] = useState([]);
  const [marker, setMarker] = useState([]);
  const [profileImgId, setProfileImgId] = useState();
  const [loading, setLoading] = useState(false);
  const propertyId = location.pathname;

  // console.log(propertyId);
  const getImageUrl = async (data) => {
    getPresignedImage(data)
      .then((res) => {
        setImageData(res[0]);
      })
      .catch((e) => {
        setImageData("");
        message.error("Image not loaded");
      });
  };

  const dummyRequest = (response) => {
    setTimeout(() => {
      response.onSuccess("ok");
    }, 0);
  };

  useEffect(() => {
    if (image && image.length) {
      const filtered = image.filter(
        (e) => e.media_purposeT_type === "profile_photo",
      );
      if (filtered.length > 0) {
        setProfileImgId(filtered[0].object_id);
        getImageUrl(filtered[0].data);
      }
      const galleryPhotos = image.filter(
        (e) => e.media_purposeT_type === "property_photo",
      );
      if (galleryPhotos.length) {
        setGalleryImages(galleryPhotos);
      }
    } else {
      setImageData("");
    }
  }, [image]);

  // useEffect(() => {
  //   if (
  //     property_info &&
  //     property_info.length &&
  //     property_info[0] &&
  //     property_info[0]?.latitude
  //   ) {
  //     const markerLocal = [
  //       {
  //         key: 0,
  //         latitude: property_info[0]?.latitude,
  //         longitude: property_info[0]?.longitude,
  //       },
  //     ];
  //     setMarker(markerLocal);
  //   }
  // }, [property_info]);

  const uploadProfileImg = async (file) => {
    setLoading(true);
    let fileModified = {
      ...file,
      modifiedName: uniquiFileName(file.originFileObj.name),
    };
    await getSingleUpload(
      fileModified.modifiedName,
      fileModified.originFileObj,
    );

    const fileJson = {
      display_media: {
        url: "http://s3LikeURL.aws.amazon.com",
        content_type: "image",
        mime_type: file.originFileObj.name.substr(
          file.originFileObj.name.lastIndexOf(".") + 1,
        ),
        content_purpose: "profile_photo",
        object_key: fileModified.modifiedName,
      },
    };

    if (imageData) {
      let urlprofile = `${propertyService}/property-landlord/${asset_id}/display/${profileImgId}`;
      try {
        await axios.put(urlprofile, fileJson);
        message.success("Profile Pic Updated");
        setLoading(false);
        updated();
      } catch (e) {
        setLoading(false);
        message.error("Something went wrong");
        console.log("error", e);
      }
    } else {
      let urlprofile = `${propertyService}/property-landlord/${asset_id}/display`;
      try {
        await axios.post(urlprofile, fileJson);
        message.success("Profile Pic Uploaded");
        setLoading(false);
        updated();
      } catch (e) {
        setLoading(false);
        message.error("Something went wrong");
        console.log("error", e);
      }
    }
  };

  const profileprops = {
    name: "file",
    accept: "image/*",
    onChange(info) {
      setFilelist(info.fileList);
      if (info.file.status === "done") {
        let fl = [...info.fileList].slice(-1);
        uploadProfileImg(fl[0]);
      }
    },
  };

  return (
    <Space direction="vertical" size={24} className="pd_tab_left">
      <Card bordered={false}>
        <Row gutter={[24, 24]}>
         
          <Col span={24}>
            <Space direction="vertical" size={16} className="w-100 text-center">
              <div className="prop_info">
              <Image
            preview={false}
            width={140}
            height={165}
            placeholder={true}
            alt="Al Ghurair Residence"
            src={placeholder}
            // src={propertyImage ? propertyImage : Logo}
            
          />
        <div className="sidebar_content">
              <Title level={3} className="ag-primary mb-0">
              {isUnit && fullUnitName ? fullUnitName : buildingName ?? ""  }
              </Title>
              <Space size={15}>
                {isUnit ? (
                  <Text className="ag-primary RegularFont sentanceCase">
                    {parentName}
                  </Text>
                ) : (
                  <>
                    {/* <LocationIcon className="ag-primary" /> */}
                    <Text className="ag-primary RegularFont">
                    {address?.addressLine2 ?? address?.addressLine1 } {address?.emirates}
                    </Text>
                  </>
                )}
              </Space>
              </div>
              </div>
            </Space>
          </Col>

        </Row>
      </Card>

    </Space>
  );
};
