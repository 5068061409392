import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Typography,
  message,
  Space,
  Spin,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux';
import { SelectField } from "../../../../../atoms/FormElement";
import { maintenanceService } from "../../../../../../configs/constants";
import axios from '../../../../../../utils/axiosInceptor'

const defaultValues: any = {
  building: '',
};

const { Paragraph } = Typography;

const AddBuilding = props => {

  const dispatch = useDispatch();
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const buildingApi = []//useSelector(state => state.zones.buildingData);
  const { control, formState:{errors}, reset, handleSubmit,setValue } = useForm({ defaultValues });
  const [formData,setFormData] = useState({id:null,name:null,status:{},defaultBuild:null,area:{},activation_date:null}); 

  var preSelectedBuildings = [];


  // useEffect(() => {
  //   dispatch(getBuilding())
  // }, [])

  useEffect(()=>{
    let data = props?.zoneData?.data;
    // console.log(data)
    const defaultBuildSelected:any = [];
     preSelectedBuildings = data?.properties?.map((index) => {
      return defaultBuildSelected.push({'label':index?.Asset?.name,'value':index?.Asset?.id});
    });

    setFormData({
      id:data?.id,
      name:data?.name,
      status:{  
        label:data?.status?.display_name,
        value:data?.status?.name
      },
      defaultBuild:defaultBuildSelected,
      area:{  
        label:data?.dataDump?.ZoneContactMechanism?.[0]?.ContactMechanism?.PhysicalAddress?.Region?.name,
        value:data?.dataDump?.ZoneContactMechanism?.[0]?.ContactMechanism?.PhysicalAddress?.Region?.id,
      },
      activation_date:data?.dataDump?.addition_date,
      })
  },[props])


  const onFinish = async (values) => {
    setLoader(true);
    setSubmited(true);
    let buildings = values.properties.map(item => (item.value))
    const url = `${maintenanceService}/maintenance/`;
    let zoneJson = {
      zone_id: props.id,
      building_ids: buildings,
    }
    try {
      await axios.post(`${url}createZoneBuildings`, zoneJson);
      setTimeout(() => {
        reset();
        setLoader(false);
        message.success('Building added successfully');
        setSubmited(false);
        props.onSubmit();
      }, 3000);
    } catch (e) {
      setLoader(false);
      setisError(true);
      const { response } = e;
      setErrorMessage(response?.data?.message)
    }
  }

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {loader && !isError &&
        (<Row gutter={[24, 24]} >
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
              {/* <Paragraph className="ag-default fontWeight600 mb-0">Loading</Paragraph> */}
            </Space>
          </Col>
        </Row>)}
      {!submited ?
        (<>

          <Row gutter={24}>
            <Col span={24}>
              <SelectField
                isRequired={true}
                fieldname="properties"
                label="Assign a building"
                control={control}
                iProps={{ placeholder: 'Select buildings', isMulti: true }}
                rules={{ required: "Please select buildings" }}
                initValue=''
                selectOption={buildingApi.length > 0 && buildingApi.map(item => ({ value: item.id, label: item.name }))}
                validate={errors.properties && "error"}
                validMessage={errors.properties && errors.properties.message}
                valueGot={formData?.defaultBuild}
                setValue={setValue}
              />
            </Col>
          </Row>
          <Row gutter={24} justify="space-between">
            <Col>
              <Button className="ag-gray-button-outline" htmlType="button" onClick={() => { reset(); props.onCancel() }}>Back</Button>
            </Col>
            <Col>
              <Button className="ag-green-button" htmlType="submit">Add</Button>
            </Col>
          </Row>
        </>
        )
        :
        isError &&
        (<>
          <Row gutter={[24, 24]} >
            <Col span={24}>
              <Space size={24} className="w-100 text-center" direction="vertical">
                <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                <Paragraph className="ag-error fontWeight600 mb-0">{errorMessage}</Paragraph>
              </Space>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}><Button className="ag-gray-button-outline" htmlType="button" onClick={() => { setSubmited(false); setisError(false) }}>Back</Button></Col>
          </Row>
        </>)
      }
    </Form>
  );
};

export default AddBuilding;