import React, { useState, useEffect } from "react";
import { Button, Col, List, Menu, Modal, Row, Segmented, Space, Tabs, Typography, message } from "antd";
import { Download, Empty, SaveFile, Sign } from "../../../../../assets/svg";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { generateDocument, getBookingDetail, saveDocumentToLease } from "../../ducks/actions";
import { useSelector } from "react-redux";
import { getPresignedImage } from "../../../../../utils/media";
import { Loading } from ".";
import { PopupConfirm } from "../../../../atoms/Popup";
import { sendContractForSignService } from "../../ducks/services";

let initialState = {
  visible: false,
  downloadedDocs: []
};

const GenerateDoc = (props) => {
  const [docData, setDocData] = useState(null);
  const [viewOrDownloadUrl, setViewOrDownloadUrl] = useState(null);

  // states
  const [{ visible, downloadedDocs }, setState] = useState(initialState);

  const updateState = (state: {}) => setState((prevState: any) => ({ ...prevState, ...state }));

  const { Text } = Typography;

  const [docKey, setDocKey] = useState("");

  const dispatch: any = useDispatch();

  const { leaseId = "" } = useParams(); 

  // redux states
  const { bookingDetails, templatesData } = useSelector((state: any) => state.newBooking);

  // const documentList = ["additionalDocument", "document 1", "document 2", "document 3"]; //, "ejari document", "contractual agreement", "Leasing contract"

  useEffect(() => {
    docKey && getUrl();
  }, [docKey, bookingDetails]);


  const retainDownloadedDocs = (docUrl) => {
    let docs = downloadedDocs;
    docs[docKey] = docUrl;
    if (!downloadedDocs[docKey])
      updateState({ downloadedDocs: docs });
  }


  const generateDocumentTemplate = async () => {
    updateState({ visible: true });
    try {
      const res = await dispatch(
        generateDocument({ leaseId, document: docKey })
      ); 
      const document = filterDocument(res?.data?.result);
      if (document?.documentId) {
        const url = await getPresignedImage([document?.documentId]);
        url?.length ? setViewOrDownloadUrl(url[0]) : setViewOrDownloadUrl(null);
        updateState({ visible: false });
        retainDownloadedDocs(url[0]);
      } else message.error("Document could not be downloaded");
    } catch (error) {
      message.error("Error generating document");
      setViewOrDownloadUrl(null);
      setDocData(null);
      updateState({ visible: false });
    }
  }

  const setOrDownloadDocument = async (documentId, preview?) => {
    updateState({ visible: true });
    let url = [];
    if (downloadedDocs[docKey] && preview == true)
      url[0] = downloadedDocs[docKey];
    else
      url = await getPresignedImage([documentId], preview);
    if (url && url?.length) {
      if (preview == false)
        window.location.href = url[0]; //for download
      else {
        setViewOrDownloadUrl(url[0]);
        retainDownloadedDocs(url[0]);
      }
    }
    updateState({ visible: false });
  }

  const filterDocument = (data?) => {
    return data?.documents?.find(i => i.documentName == docKey);
  }

  const getUrl = async (preview = true) => {
    const document = filterDocument(bookingDetails);
    if (document?.documentId) {
      setDocData(document);
      setOrDownloadDocument(document?.documentId, preview);
    } else {
      generateDocumentTemplate();
    }
  };

  const saveToLease = async () => {
    updateState({ visible: true });
    let payload = {
      leaseId,
      body: {
        addToLease: "true",
        documentKey: docKey
      }
    }
    const res = await dispatch(
      saveDocumentToLease(payload)
    );
    updateState({ visible: false });
    // console.log("resss", res);
  }


  const sendForEsignature = () => {
    // const res = await saveDocumentToLease(payload);
    PopupConfirm({
      title: `This action will send the document to the tenant for signature. Are you sure?`,
      cancelText: "No",
      okText: "Yes",
      onOk: async () => {
        updateState({ visible: true });
        try {
          await saveToLease();
          setTimeout(async () => {
            await sendContractForSignService({ leaseId, document: docKey });
            await dispatch(getBookingDetail(bookingDetails?.leaseId));
            updateState({ visible: false });
            message.success("Sent to the tenant for eSignature");
          }, 800);
        } catch (error) {
          message.error(error?.response?.data?.error);
          updateState({ visible: false });
        }
      },
    });
  };

  return (
    <>
      <Row gutter={24}>
        <Loading visible={visible} />
        <Col span={10} style={{ backgroundColor: "#fff" }}>
          <Menu
            className="generate-doc-menu"
            onClick={(e) => setDocKey(e["key"])}
            openKeys={[docKey]}
            mode="inline"
            items={templatesData?.map((i) => ({ key: i.value, label: i.label }))}
          />
        </Col>
        <Col span={14}>
          {viewOrDownloadUrl &&
            (<Space size={14} className="mb-4">
              {((saveToLease && docData?.addToLease == false) &&
                < Button type="primary" className="generate-button" onClick={() => saveToLease()}>
                  <SaveFile />
                  Save to lease
                </Button>
              )}

              {((sendForEsignature && docData?.signaturesPlaceholder == true) &&
                <Button disabled={docData?.sendToTenant == true} type="primary" className="generate-button" onClick={() => sendForEsignature()}>
                  <Sign />
                  {docData?.sendToTenant == true ? "Sent" : "Send"} for Signature
                </Button>
              )}

              <Button type="primary" className="generate-button" onClick={() => getUrl(false)}>
                <Download />
                Download
              </Button>
            </Space>
            )}

          <div className="pdf-container">
            {viewOrDownloadUrl ? (
              <embed src={(viewOrDownloadUrl + "#toolbar=0&navpanes=0&scrollbar=0")} title=""
                width="100%" height="700px" />
            ) :
              (<>
                <Empty
                  width="200px"
                  height="200px"
                  style={{ alignContent: "center", marginTop: "-50px" }}
                />
                <Text className="m-0 ag-primary defaultFont">Please select a document to view</Text>
              </>
              )}
          </div>
        </Col>
      </Row >
    </>
  );
};
export default GenerateDoc;
