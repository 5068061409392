import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
const _ = require('lodash');
import {
  BsBuilding,
  BsGear,
  BsGrid,
  BsPeople,
  BsNewspaper,
  BsCalendarWeek,
  BsMap,
  BsTags,
  BsClock,
  BsJournalBookmark,
} from "react-icons/bs";

import { Layout, Menu } from "antd";
import {
  HomeOutlined,
  ToolOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { MODULES } from "../../../configs/modules";
import { CREATE, DELETE, READ } from "../../../configs/operations";
import Permissions from "../../../utils/permissions";
import { BookingsIcon, LeasesIcon, MaintenanceIcon, PaymentsIcon, RenewalsIcon, ServiceProviderIcon, TechniciansIcon, TicketsIcon } from "../../../assets/svg";
import { json } from "stream/consumers";

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = (props) => {

  const userScreenAccess: any = useSelector((state: any) => state?.userScreens?.userScreenAccess);
  const [openKeys, setOpenKeys] = useState("");

  const location = useLocation();


  const onOpenChange = (options) => {
    setOpenKeys(options[1]);
  };

  const checkScreenAccess = (key, type) => {

    if (type === 1 && userScreenAccess.length > 0) {
      return userScreenAccess.includes(key) ? false : true;
    } else if (type === 2 && userScreenAccess.length > 0) {
      if (key === 'contracts') {
        const result = _.some(['bookings', 'leases', 'renewalsInProgress', 'upcomingRenewals', 'payments'], elem => _.includes(userScreenAccess, elem));
        return result;
      } else {
        const result = _.some(['serviceProviders', 'tickets'], elem => _.includes(userScreenAccess, elem));
        return result;
      }
    } else if (type === 2 && userScreenAccess.length == 0) {
      return true
    }



  }


  return (

    <Menu
      theme="dark"
      openKeys={[openKeys]}
      onOpenChange={onOpenChange}
      selectedKeys={[location.pathname]}
      mode="horizontal"
      style={{
        height: "58px",
        alignItems: "center",
        justifyContent: "start",
        padding: "20px 35px",
        marginTop: "48px",
      }}
    >


      {Permissions.isAllowed(MODULES.DASHBOARD, [READ]) && (
        <Menu.Item icon={<BsGrid />} className="li" key="/dashboard">
          <Link to="/dashboard">Dashboard</Link>
        </Menu.Item>
      )}
      {Permissions.isAllowed(MODULES.PROPERTIES, [READ]) && (
        <Menu.Item icon={<BsBuilding />} className="li" key="/properties">
          <Link to="/properties">Properties</Link>
        </Menu.Item>
      )}

      {Permissions.isAllowed(MODULES.MANAGERS, [READ]) && (
        <Menu.Item icon={<HomeOutlined />} key="/units">
          <Link to="/units">Units</Link>
        </Menu.Item>
      )}

      {Permissions.isAllowed(MODULES.TENANTS, [READ]) && (
        <Menu.Item style={!checkScreenAccess('tenants', 1) ? { display: 'block' } : { display: 'none' }} icon={<BsPeople />} key="/tenants">
          <Link to="/tenants">Tenants</Link>
        </Menu.Item>
      )}

      {Permissions.isAllowed(MODULES.PROPERTIES, [READ]) && (
        <SubMenu key="sub3" icon={<FileTextOutlined />} style={!checkScreenAccess('contracts', 2) ? { display: 'none' } : { display: 'block' }} title="Contracts">
          <Menu.Item style={!checkScreenAccess("bookings", 1) ? { display: 'block', width: "200px" } : { display: 'none' }} key="/bookings" icon={<BookingsIcon />}>
            <Link to="/bookings">Bookings</Link>
          </Menu.Item>
          <Menu.Item style={!checkScreenAccess('leases', 1) ? { display: 'block', width: "200px" } : { display: 'none' }} key="/bookingforms" icon={<LeasesIcon />}>
            <Link to="/bookingforms">Leases</Link>
          </Menu.Item>
          <Menu.Item key="/contracts" style={!checkScreenAccess('upcomingRenewals', 1) ? { display: 'block', width: "200px" } : { display: 'none' }} icon={<RenewalsIcon />}>
            <Link to="/contracts">Upcoming Renewals</Link>
          </Menu.Item>
          <Menu.Item style={!checkScreenAccess('renewalsInProgress', 1) ? { display: 'block', width: "200px" } : { display: 'none' }} key="/booking-Renewals" icon={<RenewalsIcon />}>
            <Link to="/booking-Renewals">Renewals in Progress</Link>
          </Menu.Item>
          <Menu.Item style={!checkScreenAccess('payments', 1) ? { display: 'block', width: "200px" } : { display: 'none' }} key="/payments" icon={<PaymentsIcon />}>
            <Link to="/payments">Payments</Link>
          </Menu.Item>
        </SubMenu>
      )}

      <SubMenu key="Maintenance" icon={<ToolOutlined />} style={!checkScreenAccess('sp', 2) ? { display: 'none' } : { display: 'block' }} title="Maintenance">
        {Permissions.isAllowed(MODULES.SERVICE_PROVIDERS, [READ]) && (
          <Menu.Item style={!checkScreenAccess('serviceProviders', 1) ? { display: 'block' } : { display: 'none' }} key="/service-providers" icon={<ServiceProviderIcon />}>
            <Link to="/service-providers">Service Providers</Link>
          </Menu.Item>
        )}
        {Permissions.isAllowed(MODULES.TICKETS, [READ]) && (
          <Menu.Item style={!checkScreenAccess('tickets', 1) ? { display: 'block' } : { display: 'none' }} key="/tickets" icon={<TicketsIcon />}>
            <Link to="/tickets">Tickets</Link>
          </Menu.Item>
        )}
        {Permissions.isAllowed(MODULES.TECHNICIANS, [READ]) && (
          <Menu.Item key="/technicians" icon={<TechniciansIcon />}>
            <Link to="/technicians">Technicians</Link>
          </Menu.Item>
        )}
        {Permissions.isAllowed(MODULES.ZONES, [READ]) && (
          <Menu.Item key="/zones" icon={<BsMap className="bi" />}>
            <Link to="/zones">Zones</Link>
          </Menu.Item>
        )}
        {Permissions.isAllowed(MODULES.ROSTER, [READ]) && (
          <Menu.Item key="/roster" icon={<BsCalendarWeek className="bi" />}>
            <Link to="/roster">Roster</Link>
          </Menu.Item>
        )}

      </SubMenu>

      {Permissions.isAllowed(MODULES.CONFIGURATIONS, [READ]) && (
        <SubMenu key="Configuration" icon={<BsGear />} title="Configuration">
          {Permissions.isAllowed(MODULES.CONFIGURATIONS, [READ]) && (
            <Menu.Item key="/categories" icon={<BsTags className="bi" />}>
              <Link to="/categories">Categories</Link>
            </Menu.Item>
          )}
          {Permissions.isAllowed(MODULES.CONFIGURATIONS, [READ]) && (
            <Menu.Item key="/open-hours" icon={<BsClock className="bi" />}>
              <Link to="/open-hours">Operating Hours</Link>
            </Menu.Item>
          )}
          {Permissions.isAllowed(MODULES.CONFIGURATIONS, [READ]) && (
            <Menu.Item key="/public-holidays" icon={<BsJournalBookmark className="bi" />}>
              <Link to="/public-holidays">Public Holidays</Link>
            </Menu.Item>
          )}
        </SubMenu>
      )}

      {Permissions.isAllowed(MODULES.NOTICE, [READ]) && (
        <Menu.Item style={!checkScreenAccess('notices', 1) ? { display: 'block' } : { display: 'none' }} icon={<BsNewspaper />} className="li" key="/notices">
          <Link to="/notices">Notices</Link>
        </Menu.Item>
      )}

      {Permissions.isAllowed(MODULES.USERS, [READ]) && (
        <Menu.Item style={!checkScreenAccess('users', 1) ? { display: 'block' } : { display: 'none' }} icon={<BsPeople />} key="/users">
          <Link to="/users">Users</Link>
        </Menu.Item>
      )}


    </Menu>
  );
};

export default Sidebar;
