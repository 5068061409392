import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../molecules/DashboardLayout";
import {
  Row,
  Col,
  Pagination,
  Card,
  Input,
  Space,
  Button,
  Form,
  Select,
} from "antd";
import Grid from "../../../atoms/Grid";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumb from "../../../atoms/BreadCrumb";
import SectionHeader from "../../../molecules/SectionHeader";
import { SearchOutlined } from "@ant-design/icons";
import { ArrowRightOutlined } from "@ant-design/icons";
import { getOpertunities } from "./ducks/actions";
import moment from "moment";

const { Option } = Select;
const breadcrumbList: any = [
  {
    label: "Lead Managment",
    route: "/leads",
  },
  {
    label: "Opportunities",
    route: "/opportunities",
  },
];

function viewRender() {
  return (
    <Button type="link">
      <ArrowRightOutlined style={{ fontSize: 25 }} />
    </Button>
  );
}

function dateFormat(param) {
  return (
    <span>{moment(param.data.lead_time).format("YYYY-MM-DD HH:MM:SS")}</span>
  );
}

const gridProps = {
  sortable: true,
  filter: true,
};

const searchBy = [{ label: "Tenant Name", value: "tenant_name" }];

const limit = 10;

const breadcrumbListProperties: any = [];

const OpportunityList = () => {
  const dispatch:any = useDispatch();
  const navigate = useNavigate();
  const location  = useLocation();
  const [searchByT, setSearchBy] = useState("");
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const opertunitiesData = useSelector(
    (state: any) => state.opertunities.getOpertunities,
  );
  const column = [
    {
      headerName: "id",
      field: "id",
      minWidth: 40,
      maxWidth: 80,
    },
    { headerName: "Lead Number", field: "lead_number" },
    { headerName: "Lead Name", field: "tenant_name" },
    { headerName: "Type", field: "type" },
    { headerName: "Mobile Number", field: "mobile_number" },
    { headerName: "Email", field: "email" },
    {
      headerName: "Lead Time",
      field: "lead_time",
      cellRendererFramework: dateFormat,
    },
    {
      headerName: "View Details",
      field: "detail",
      maxWidth: 150,
      onCellClicked: (row) => {
        // window.localStorage.setItem("selectedPartyId", row.data.party_id);
        // window.localStorage.setItem("assetId", row.data.asset_id);
        navigate(
          `/opportunity-detail/${row.data.id}/${row.data.asset_id}/${row.data.party_id}`,
        );
      },
      cellRendererFramework: viewRender,
    },
  ];

  useEffect(() => {
    dispatch(getOpertunities(limit, currentPage, "", ""));
  }, []);

  const handlePagination = (pg) => {
    setCurrentPage(pg);
    dispatch(getOpertunities(limit, pg, "", ""));
  };

  const onSearch = (val) => {
    setCurrentPage(0);
    if (val.searchText) {
      setSearch(val.searchText);
      setSearchBy(val.searchBy);
      dispatch(getOpertunities(limit, 0, val.searchText, val.searchBy));
    } else {
      setSearch("");
      dispatch(getOpertunities(limit, 0, "", ""));
    }
  };

  const SearchFelds = () => {
    return (
      <Form onFinish={onSearch}>
        <Space wrap>
          <Form.Item name="searchText" className="mb-0" initialValue={search}>
            <Input type="text" placeholder="Search" value={search} />
          </Form.Item>
          <Form.Item
            name="searchBy"
            className="mb-0"
            initialValue={"tenant_name"}
          >
            <Select placeholder="Select">
              {searchBy.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item className="mb-0 noborder-btn">
            <Button
              htmlType="submit"
              className="ag-gray-button"
              icon={<SearchOutlined />}
            />
          </Form.Item>
        </Space>
      </Form>
    );
  };

  return (
    <DashboardLayout>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <BreadCrumb
            list={
              location?.state?.from ? breadcrumbListProperties : breadcrumbList
            }
          />
        </Col>
        <Col span={24}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <SectionHeader
                heading="Opportunities"
                total={opertunitiesData?.count || 0}
              />
            </Col>
            <Col span={24}>
              <Pagination
                className="ag-property-text-pagination"
                total={opertunitiesData?.count || 0}
                showTotal={(total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} Opportunities`
                }
                showSizeChanger={false}
                defaultPageSize={10}
                defaultCurrent={1}
                current={currentPage + 1}
                onChange={(e) => handlePagination(e - 1)}
              />
            </Col>

            <Col span={24}>
              <Row gutter={[24, 24]} justify="end">
                <Col span={24}>
                  <Card extra={<SearchFelds />} bodyStyle={{ padding: "0px" }}>
                    <Grid
                      data={opertunitiesData?.rows}
                      columns={column}
                      pagination={false}
                      defaultSettings={gridProps}
                      noRowlabel="Oppertunities"
                      fullPage={true}
                    />
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </DashboardLayout>
  );
};

export default OpportunityList;
