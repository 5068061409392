import * as React  from "react";
import { BrowserRouter as Router, Routes, Route  } from "react-router-dom";

import { ROOT } from "../configs/routeNames";

import Pages from "./Pages";

const Root = () => {

  return (
  <Router>
    <Routes>
      <Route path={ROOT} element={<Pages/>} />
    </Routes>
  </Router>
);
}

export default Root;