import React, { useState, useEffect } from "react";
import DashboardLayout from "../../molecules/DashboardLayout";
import {
  Row,
  Col,
  Pagination,
  Card,
  Button,
  MenuProps,
  message,
  Typography,
  Tooltip
} from "antd";
import SectionHeader from "../../molecules/SectionHeader";
import Grid from "../../atoms/Grid";
import * as status_types from "../../atoms/PropertyCard/constants";
import { useDispatch, useSelector } from "react-redux";
import { getUnits, getUnitDetails, setFilters } from "./ducks/actions";
import { RightArrow } from "../../atoms/Icons";
import { useNavigate } from "react-router-dom";
import { FilterOutlined, PlusSquareFilled } from "@ant-design/icons";
import { useSearchHistory } from "../../../utils/filterHelper";
import { setFilterUnits } from "../App/SearchFilters/ducks/actions";
import { unitBookingStatus } from "../BookingForm/components/utils";

const gridProps = {
  sortable: true,
  filter: true,
  resizable: true,
  minWidth: 200,
};

const limit = 50;

const initialSearchKeys = [
  { value: "unitCode", label: "Unit No" },
  { value: "propertyName", label: "Property Name" },
  { value: "propertyCode", label: "Property Code" },
  { value: "location", label: "Location" }, //changed from community ref: 8405
];

const { Title, Text } = Typography;

export default (props) => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [searchKeys, setSearchKeys] = useState(initialSearchKeys);
  const unitsApi = useSelector((state: any) => state.units.unitsList);
  const unitsFilters = useSelector((state: any) => state.units.unitsFilters);
  const [updateValue, setUpdateValue] = useState({});

  const Filter = useSelector((state: any) => state.filters.unitssData);

  const defaultFilterByValues = { unitStatus: 'vacant', usagesType: "all" };

  const [currentPage, setCurrentPage] = useState(unitsFilters?.currentPage || 1);
  const { searchHistory, addToSearchHistory } = useSearchHistory(setFilterUnits);
  const [searchBy, setSearchBy] = useState(unitsFilters?.searchBy ? unitsFilters?.searchBy : "propertyName")  //: Filter ? Object.keys(Filter)[0]  // (Object.keys(Filter)[0] ?? "propertyName")
  const [search, setSearch] = useState(unitsFilters?.search ? unitsFilters?.search : "");
  const [filterBy, setfilterBy] = useState(unitsFilters?.filterBy || defaultFilterByValues)
  const [loading, setLoading] = useState(false);
  const [deselectAll, setDeselectAll] = useState(false);

  // console.log("Filter", Filter);

  const MenuItems: MenuProps["items"] = [
    {
      label: "Active Tenancy",
      key: 0,
    },
    // {
    //   label: "Billing Schedule ",
    //   key: 1,
    // },
    {
      label: "Payment Schedule",
      key: 2,
    },
  ];

  const defaultFilterOptions = [
    {
      name: "unitStatus",
      label: "Status",
      initialValue: unitsFilters.filterBy?.unitStatus || defaultFilterByValues?.unitStatus,
      options: [
        {
          value: 'all',
          label: 'All'
        },
        {
          value: 'vacant',
          label: 'Vacant',
        },
        {
          value: 'reserved',
          label: 'Reserved'
        },
        {
          value: 'occupied',
          label: 'Occupied'
        },
        {
          value: 'blocked',
          label: 'Blocked'
        },
      ],
    },
    {
      name: "usagesType",
      label: "Usage Type",
      initialValue: unitsFilters.filterBy?.usagesType || defaultFilterByValues?.usagesType,
      options: [
        {
          value: 'all',
          label: 'All'
        },
        {
          value: 'residential',
          label: 'Residential',
        },
        {
          value: 'commercial',
          label: 'Commercial'
        }
      ],
    }
  ];

  const [propertyOptions, setPropertyOptions] = useState(defaultFilterOptions);

  function viewRender(record) {
    return (
      <>
        <Button
          type="primary"
          style={{ marginRight: 5 }}
          onClick={() => {
            navigate(`/property-detail/${record.data?.responce?.propertyId}/unit/${record.data?.responce?._id}`, {
              state: {
                parentname: record?.data?.responce?.propertyName, showNav: true, usageType: record?.data?.responce?.unit?.usagesType
              },
            })
          }
          }
        >
          View Unit
        </Button>
        {
          record.data.unitStatus === "vacant" && (
            <Button
              icon={<PlusSquareFilled />}
              disabled={record?.data?.responce?.blockData?.block}
              onClick={() => navigate(`/newbookingform`,
                {
                  state: {
                    selectedUnit: {
                      unit: record?.data?.responce?.unit,
                      bookingPeriod: record?.data?.responce?.bookingPeriod,
                      businessUnitId: record?.data?.responce?.businessUnitId,
                      businessUnitName: record?.data?.responce?.businessUnitName,
                      unitId: record?.data?.responce?.unitId
                    },
                    selectedProperty: { property_name: record?.data?.responce?.propertyName, propertyId: record?.data?.responce?.propertyId }
                  }
                })
              }
              className="ag-primary"
            > Add Booking </Button>
          )
        }
        {
          record.data.unitStatus === "reserved" && (
            <Button
              icon={<RightArrow />}
              onClick={() => getInnerUnitDetails(record.data.unitId, "occupied")}
              className="ag-primary"
            > View Booking </Button>
          )
        }
        {
          record.data.unitStatus === "occupied" && (
            <Button
              icon={<RightArrow />}
              onClick={() => {
                getInnerUnitDetails(record.data.unitId, "occupied")
              }
              }
              className="ag-primary"
            > View Lease </Button>
          )
        }
      </>
    );
  }

  const Statuses = (stat) => {
    switch (stat) {
      case status_types.PROPERTY_ENABLED:
        return "Active";
      case status_types.PROPERTY_DISABLED:
        return "Disabled";
      case status_types.PROPERTY_DRAFT:
        return "Vacant";
      case status_types.PROPERTY_RENTED:
        return "Occupied";
      case status_types.PROPERTY_PUBLISHED:
        return "Vacant";
      case status_types.PROPERTY_RESERVED:
        return "Reserved";
      case status_types.PROPERTY_BLOCKED:
        return "Blocked";
    }
  };

  const convertToNormalCase = (input = '') => {
    // Check if the input is in camel case
    if (input?.indexOf('_') === -1 && input !== input?.toLowerCase()) {
      return input?.replace(/([a-z])([A-Z])/g, '$1 $2');
    }

    // Check if the input is in snake case
    if (input?.indexOf('_') !== -1) {
      return input?.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    }

    return input?.charAt(0).toUpperCase() + input?.slice(1); // Already in normal case
  }

  const tenantCol = [
    {
      headerName: "Status",
      field: "unitStatus",
      // width: 150,
      cellStyle: { textAlign: "center" },
      cellRendererFramework: ({ data }) => (
        <Text
          className={`statusUnit 
          ${data?.unitStatus === "disabled" ? "inactive" : ""} 
          ${data?.unitStatus === "occupied" ? "inactive" : ""} 
          ${data?.unitStatus === "reserved" ? "inactive-orange" : ""}
          ${data?.responce?.blockData?.block ? "inactive-block" : ""}
            `} //vacant, occupied, reserved
        >
          {Statuses(data?.responce?.blockData?.block ? 'blocked' : data?.unitStatus)}
        </Text>
        
      ),
    },
    {
      headerName: "Booking Type",
      field: "leaseStatus",
      width: 100,
      cellRendererFramework: (record) => {
        if (record?.data?.unitStatus === 'reserved')
          return unitBookingStatus(record?.data?.responce?.leaseStatus); else return '--';
      }
    },
    {
      headerName: "Booking Status",
      field: "leaseStatus",
      width: 300,
      cellRendererFramework: (record) => {

        return record?.data?.unitStatus === 'reserved' ? convertToNormalCase(record?.data?.responce?.leaseStatus) : '--'
      }
    },
    {
      headerName: "Unit Type",
      field: "unitType",
      // width: 150
    },
    {
      headerName: "Location",
      field: "location",
      width: 300
    },
    {
      headerName: "Property",
      field: "property",
      // width: 180
    },
    {
      headerName: "Unit No",
      field: "unitNo",
      // width: 280,
    },
    {
      headerName: "Beds",
      field: "unitNumber",
      // width: 200
    },
    {
      headerName: "Built-up Area",
      field: "BuiltUpArea",
      // width: 120
    },
    {
      headerName: "Plot Area",
      field: "PlotArea",
      // width: 120
    },
    {
      headerName: "No. of Bookings",
      field: "PlotArea",
      // width: 120
      cellRendererFramework: (record) => (
        record?.data?.responce?.bookingCount ? (
          <Tooltip title="View bookings" trigger="hover" showArrow={false}>
            <Button onClick={() => navigate(`/bookings`, { state: { unitId: record?.data?.unitNo } })} type="link" className="">
              {record?.data?.responce?.bookingCount} ↗
            </Button>
          </Tooltip>
        ) : ""
      )
    },
    {
      headerName: "View Details",
      cellRendererFramework: viewRender,
      cellStyle: { textAlign: "right" },
      filter: false,
      width: 340,
 
      pinned:'right'
    },
  ];

  const getInnerUnitDetails = async (id, type) => {
    setLoading(true);
    try {
      const unit = await getUnitDetails(id);
      if (unit?.leaseStatus && unit?.leaseStatus != "draft")  //ADDED CHECKS TO FIX DRAFT/NONDRAFT FORM ISSUE: REF->8142
        navigate(`/viewbookingform/${unit.leaseId}`);
      else navigate(`/newbookingform/${unit.leaseId}`);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handlePagination = (pg) => {
    setCurrentPage(pg);
    // dispatch(getUnits(false, limit, pg, search, searchBy, { unitStatus: filterBy }));
  };
  useEffect(() => {
    if (unitsApi?.data) {
      let temp = [];
      unitsApi?.data?.map((item) => {
        temp.push({
          unitId: item.unitId,
          unitStatus: item.unitStatus,
          unitType: item?.unit?.unitType,
          location: `${item?.address?.community || ""} ${item?.address?.subCommunity ? (", " + item?.address?.subCommunity) : ""}`, //{item?.address?.addressLine2}, ${item?.address?.addressLine1}`,
          property: item.propertyName,
          unitNo: item?.unit?.unitCode,
          unitNumber: item?.unit?.unitSubType,
          BuiltUpArea: item?.unit?.areaSqft,
          PlotArea: item?.unit?.suiteSize,
          responce: item,
        });
      });
      setData(temp);
    } else {
      setData([]);
    }
  }, [unitsApi]);

  useEffect(() => {
    if (unitsFilters?.searchBy) {
      // console.log("unitsFilters >> ", unitsFilters?.searchBy);
      const setArray = searchKeys.map((item) => {
        let val = null;
        if (item.value === unitsFilters?.searchBy) {
          val = { ...item, default: true };
        } else {
          val = { ...item, default: false };
        }
        return val;
      });
      setSearchKeys(setArray);
    }

    // setSearchBy("unitCode");
  }, [unitsFilters, unitsFilters?.searchBy]);

  useEffect(() => {
    if (
      Object.keys(unitsFilters).length !== 0 &&
      unitsFilters.constructor === Object
    ) {
      setPropertyOptions(defaultFilterOptions);
      // console.log("IF::",false, limit, unitsFilters.currentPage, unitsFilters.search, unitsFilters.searchBy, { unitStatus: unitsFilters.filterBy });
      // console.log("DIFF::::", unitsFilters, "GIVEN:", { search, searchBy, filterBy, currentPage })
      dispatch(
        getUnits(
          false,
          limit,
          unitsFilters.currentPage,
          unitsFilters.search,
          unitsFilters.searchBy,
          { ...unitsFilters?.filterBy }
        )
      );
    }
    // else {
    //   // setCurrentPage(1)
    //   // addToSearchHistory(searchBy, search);
    //   setTimeout(() => {
    //     console.log("ELSE::",false, limit, 1, search, searchBy, { unitStatus: filterBy })
    //     dispatch(getUnits(false, limit, 1, search, searchBy, { unitStatus: filterBy }));
    //   }, 500);
    // }
  }, [unitsFilters]);

  useEffect(() => {
    let currPage = currentPage;
    if (
      unitsFilters?.search != search ||
      unitsFilters?.searchBy != searchBy ||
      unitsFilters?.filterBy != filterBy
    )
      currPage = 1;
    dispatch(setFilters({ search, searchBy, filterBy, currentPage: currPage }));
  }, [search, searchBy, filterBy, currentPage]);

  useEffect(() => {
    let key = Object.keys(searchHistory)[0];
    if (searchKeys.length > 0)
      searchKeys.map((item) => {
        if (key == item.value) item["default"] = true;
        else item["default"] = false;
        return item;
      });
  }, [searchHistory]);

  function onTopSearch(e) {
    if (e?.length === 0) setSearch("");
    else if (e?.length < 2)
      message.error("Please enter atleast two characters");
    else setSearch(e);
  }

  function onTopSearchBy(e) {
    setSearchBy(e?.value);
  }

  function handlePropertyChange(property, label) {
    setfilterBy({ ...filterBy, [label]: property.value });
  }

  return (
    <>
      <DashboardLayout load={loading}>
        <Row gutter={[32, 32]}>
          <Col span={24}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <SectionHeader
                  heading="Units"
                  noBorder={true}
                  total={unitsApi?.totalCount || 0}
                  // addNewAction={ addTenant }
                  // dropNewAction={onDropClick}
                  dropItems={MenuItems}
                  dropTrigger={["click"]}

                  search={(e) => onTopSearch(e)}
                  searchBy={(e) => onTopSearchBy(e)}
                  searchOptions={searchKeys}
                  defaultSearch={search}

                  viewOption={propertyOptions}
                  viewOptionIcon={<FilterOutlined />}
                  viewOptionLabel="Filter by : status/usage"
                  justifiedItems={true}
                  updateValue={updateValue}
                  justifyEnd={true}
                  handleView={handlePropertyChange}
                // addMoreText={"Unit"}
                // search={onTopSearch}
                />
              </Col>
              <Col span={24}>
                {/* <Pagination
                  pageSize={limit}
                  className="ag-property-text-pagination"
                  total={unitsApi?.totalCount || 0}
                  showTotal={(total, range) =>
                    `Showing ${range[0]}-${range[1]} of ${total} Units`
                  }
                  defaultPageSize={unitsApi?.perPage}
                  defaultCurrent={1}
                  showSizeChanger={false}
                  current={currentPage}
                  onChange={(e) => handlePagination(e)}
                /> */}

                <Pagination
                  className="ag-property-text-pagination"
                  total={unitsApi?.totalCount || 0}
                  showTotal={(total, range) =>
                    `Showing ${range[0]}-${range[1]} of ${total} Units`
                  }
                  showSizeChanger={false}
                  defaultPageSize={50} //leaseformdata?.perPage ||
                  defaultCurrent={1}
                  current={unitsFilters?.currentPage || currentPage}
                  onChange={(e) => handlePagination(e)}
                />
              </Col>
              <Col span={24} className="managers_tenants_detail">
                <Card bordered={false} className="ag-nospace-body">
                  <Grid
                    data={data}
                    columns={tenantCol}
                    pagination={false}
                    defaultSettings={gridProps}
                    noRowlabel="Tenants"
                    rowSelection="multiple"
                    // onSelectionChanged={onSelectionChanged}
                    isRowSelectable={false}
                    fullPage={true}
                    deselectAll={deselectAll}
                  />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </DashboardLayout>
    </>
  );
};
