import React, { useState, useEffect } from "react";

import { useForm,useWatch } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../../../../utils/axiosInceptor";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ReactSelectField } from "../../../../atoms/FormElement";
import { maintenanceService } from "../../../../../configs/constants";
import { Row, Col, Button, Form, Typography, message, Space, Spin } from "antd";
const { Paragraph } = Typography;

var _ = require("lodash");

const defaultValues = {};

const user = {
  application: "",
  role: "",
};

const AddUser = (props) => {
  const dispatch: any = useDispatch();

  const { properties } = useSelector((state: any) => state.service);
  const { dropDownServiceProviders } = useSelector(
    (state: any) => state.service
  );

  const userDetails = JSON.parse(localStorage.getItem("login-response"));

  const [loader, setLoader] = useState(false);
  const [isError, setisError] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [residentialOptions, setResidentialOptions] = useState([]);
  const [commercialOptions, setCommercialOptions] = useState([]);
  const [internalOptions, setInternalOptions] = useState([]);

  const [errorMessage, setErrorMessage] = useState(false);
  const {
    control,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // TODO:CHECK IT
  const onChangeOrganzation = (e) => {
    // console.log("CATEGGGS",e);
    setResidentialOptions(e?.residential);
    setCommercialOptions(e?.commercial);
    setInternalOptions(e?.internal);
  };

  useEffect(() => {
    return () => {
      reset();
    };
  }, [props]);

  useEffect(() => {
    // loading categories on edit click itself instead of only SP change
    const Specialities = dropDownServiceProviders?.find(
      (item) => item.value === props?.selected?.serviceProviderId
    );

    // console.log("Specialities", Specialities)
    setResidentialOptions(Specialities?.residential);
    setCommercialOptions(Specialities?.commercial);
    setInternalOptions(Specialities?.internal);



  }, []);

  useEffect(() => {

   
    if (props.selected) {
      const selectedSP = dropDownServiceProviders.filter(
        (item) => item.value == props.selected?.serviceProviderId
      )[0];


      const selectedProperty = properties.filter((item) => {
        return props.selected.properties.filter((propertyId) => {
          if (item.value === propertyId) {
            return item;
          }
        })[0];
      });


      //TODO: split categories
      const residentialCategories = selectedSP?.residential?.filter((item) => {
        return props.selected?.residentialCategories.filter((categoryId) => {
          if (item.value === categoryId) {
            return item;
          }
        })[0];
      });

      const commercialCategories = selectedSP?.commercial?.filter((item) => {
        return props.selected?.commercialCategories.filter((categoryId) => {
          if (item.value === categoryId) {
            return item;
          }
        })[0];
      });

      const internalCategories = selectedSP?.internal?.filter((item) => {
        return props.selected?.internalCategories.filter((categoryId) => {
          if (item.value === categoryId) {
            return item;
          }
        })[0];
      });

      // console.log("SELETCED",selectedSP,dropDownServiceProviders,residentialCategories,commercialCategories,internalCategories)

      setValue("serviceProviderId", selectedSP, { shouldValidate: true });
      setValue("properties", selectedProperty, { shouldValidate: true });
      setValue("residentialCategories", residentialCategories, { shouldValidate: true });
      setValue("commercialCategories", commercialCategories, { shouldValidate: true });
      setValue("internalCategories", internalCategories, { shouldValidate: true });

    } else {
      reset();
    }
  }, [props, props]);

  const onFinish = async (values) => {
    reset();
    setLoader(true);
    setSubmited(true);

    let postJson = {};
    if (props?.selected) {
      postJson = {
        _id: props.selected._id,
        orgId: userDetails.orgId,
        serviceProviderId: values.serviceProviderId.value,
        properties: _.map(values.properties, (item) => item.value),
        residentialCategories: _.map(values?.residentialCategories, (item) => item.value),
        commercialCategories: _.map(values?.commercialCategories, (item) => item.value),
        internalCategories: _.map(values?.internalCategories, (item) => item.value),

        // TODO: add 3 categories proper
      };

      const url = `${maintenanceService}/maintenance/assignPropertyCategory`;

      axios
        .post(url, postJson, { headers: { application: 4 } })
        .then((response) => {
          reset();
          setLoader(false);
          setSubmited(false);
          props.onSubmit();
          message.success(response?.data?.message);
        })
        .catch((error) => {
          reset();
          setLoader(false);
          setSubmited(false);
          message.error(error.response.data.error);
        });
    } else {
      postJson = {
        orgId: userDetails.orgId,
        serviceProviderId: values.serviceProviderId.value,
        properties: _.map(values.properties, (item) => item.value),
        residentialCategories: _.map(values?.residentialCategories, (item) => item.value),
        commercialCategories: _.map(values?.commercialCategories, (item) => item.value),
        internalCategories: _.map(values?.internalCategories, (item) => item.value),

        // TODO: add categories proper
      };

      const url = `${maintenanceService}/maintenance/assignPropertyCategory`;

      axios
        .post(url, postJson, { headers: { application: 4 } })
        .then((response) => {
          reset();
          setLoader(false);
          setSubmited(false);
          props.onSubmit();
          message.success(response?.data?.message);
        })
        .catch((error) => {
          reset();
          setLoader(false);
          setSubmited(false);
          message.error(error.response.data.error);
        });
    }
  };

   // Watch all form values
   const watchedValues = useWatch({
    control
  });

  //  chek if value are change in case of confirm lease renewal

  // useEffect(() => {
  // }, [watchedValues]);

  return (
    <>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        {loader && !isError && (
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Space
                size={24}
                className="w-100 text-center"
                direction="vertical"
              >
                <Spin size="large" className="ag-fontSize32" />
              </Space>
            </Col>
          </Row>
        )}
        {!submited ? (
          <>
            <Row gutter={24}>
              <Col span={12}>
                <ReactSelectField
                  isRequired={true}
                  control={control}
                  label="Service Provider"
                  fieldname="serviceProviderId"
                  selectOption={dropDownServiceProviders}
                  onChange={(e) => onChangeOrganzation(e)}
                  validate={errors.serviceProviderId && "error"}
                  iProps={{ placeholder: "Select Service Provider" }}
                  rules={{ required: "Please select service provider" }}
                  validMessage={
                    errors.serviceProviderId && errors.serviceProviderId.message
                  }
                />
              </Col>
              <Col span={12}>
                <ReactSelectField
                  label="Buildings"
                  isRequired={true}
                  control={control}
                  classes="fixed-scroll-select"
                  fieldname="properties"
                  selectOption={properties}
                  validate={errors.properties && "error"}
                  rules={{ required: "Please select building" }}
                  iProps={{ placeholder: "Select Buildings", isMulti: true }}
                  validMessage={errors.properties && errors.properties.message}
                />
              </Col>
              {/* internalOptions commercialOptions  residentialOptions */}
              <Col span={12}>
                <ReactSelectField
                  control={control}
                  // isRequired={true}
                  label="Residential Specialities"
                  fieldname="residentialCategories"
                  selectOption={residentialOptions}
                  // validate={errors.residentialCategories && "error"}
                  // rules={{ required: "Please select specialities" }}
                  // validMessage={errors.residentialCategories && errors.residentialCategories.message}
                  iProps={{ placeholder: "Select Specialities", isMulti: true }}
                />
              </Col>
              <Col span={12}>
                <ReactSelectField
                  control={control}
                  // isRequired={true}
                  label="Commercial Specialities"
                  fieldname="commercialCategories"
                  selectOption={commercialOptions}
                  // validate={errors.commercialCategories && "error"}
                  // rules={{ required: "Please select specialities" }}
                  // validMessage={errors.commercialCategories && errors.commercialCategories.message}
                  iProps={{ placeholder: "Select Specialities", isMulti: true }}
                />
              </Col>
              <Col span={12}>
                <ReactSelectField
                  control={control}
                  // isRequired={true}
                  label="Internal Specialities"
                  fieldname="internalCategories"
                  selectOption={internalOptions}
                  // validate={errors.internalCategories && "error"}
                  // rules={{ required: "Please select specialities" }}
                  // validMessage={errors.internalCategories && errors.internalCategories.message}
                  iProps={{ placeholder: "Select Specialities", isMulti: true }}
                />
              </Col>
            </Row>
            <Row gutter={24} justify="space-between">
              <Col>
                <Button
                  className="ag-gray-button-outline theme_grey_btn"
                  htmlType="button"
                  onClick={() => {
                    reset();
                    props.onCancel();
                  }}
                >
                  Back
                </Button>
              </Col>
              <Col>
               { (watchedValues?.commercialCategories?.length > 0 || watchedValues?.residentialCategories?.length > 0 || watchedValues?.internalCategories?.length > 0  ) && 
               <Button className="ag-green-button theme_btn" htmlType="submit">
                  {props?.editMode ? "Update" : "Add"}
                </Button>}
              </Col>
            </Row>
          </>
        ) : (
          isError && (
            <>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Space
                    size={24}
                    className="w-100 text-center"
                    direction="vertical"
                  >
                    <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                    <Paragraph className="ag-error fontWeight600 mb-0 sentanceCase">
                      {errorMessage}
                    </Paragraph>
                  </Space>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Button
                    className="ag-gray-button-outline theme_grey_btn"
                    htmlType="button"
                    onClick={() => {
                      reset();
                      setSubmited(false);
                      setisError(false);
                    }}
                  >
                    Back
                  </Button>
                </Col>
              </Row>
            </>
          )
        )}
      </Form>
    </>
  );
};

export default AddUser;
