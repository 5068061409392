import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  List,
  Popover,
  Row,
  Typography,
  message,
} from "antd";
import { useForm, useWatch } from "react-hook-form";

import GeneratedDoc from "../../../../../molecules/GeneratedDoc";
import { PopupConfirm } from "../../../../../atoms/Popup";
import axios from "axios";
import { leaseService } from "../../../../../../configs/constants";
import { CommonCard } from "../../../components";
import { useDispatch } from "react-redux";
import {
  createBooking,
  deleteBooking,
  getBookingDetail,
  getConfigData,
  setBookingForm,
  setFormState,
  updateBooking,
} from "../../../ducks/actions";
import { useSelector } from "react-redux";
import TenantDocs from "./TenantDocs";
import { useNavigate, useParams } from "react-router";
import { PaperClipOutlined, PlusOutlined } from "@ant-design/icons";
import { PlusMinus } from "../../../../../../assets/svg";
import { BsFiles, BsPerson } from "react-icons/bs";
import { Loading } from "../../modalContent";
import { uploadDocumentMedia } from "../../../../../../utils/media";
import { RcFile } from "antd/es/upload";
import { sendContractForSignService } from "../../../ducks/services";

let initialState = {
  loading: false,
};

export default (props) => {
  const { Text } = Typography;

  const {
    booking,
    handleGenerate,
    disable,
  } = props;

  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  
  const [{ loading }, setState] = useState(initialState);


  // redux states
  const { configData, bookingForm, bookingDetails, templatesData } = useSelector((state: any) => state.newBooking);
  const navigate = useNavigate();
  const { leaseId = bookingDetails.leaseId } = useParams();
  
  const isBooking = Object.keys(bookingDetails).length > 0;

  const dispatch: any = useDispatch();
  const disableAddDoc: boolean = bookingDetails?.leaseStatus == "sent_for_lease_approval" ||
    bookingDetails?.leaseStatus == "sent_for_booking_approval" ||
    bookingDetails?.paymentPlanApproval === "sent" ||
    !!bookingDetails?.leaseStatusHistory?.find((e) => e.status === "deposit_paid"); //ADDED for bug: 8288 //REMOVED: for another bug !!disable;  // REMOVED FOR BUG: !!bookingDetails?.leaseStatusHistory?.find((e) => e.status === "documents_approved") ||
  const disableGenDoc: boolean = bookingDetails?.leaseStatus != 'active' && !!disable; //Fix changed for bug: 7899


  const onRemoveDocument = async (docItem:any) => {

    PopupConfirm({
      title: `Are you sure you want to delete ${docItem?.documentName} ?`,
      okText: "Yes",
      onOk: async () => {
        const url = `${leaseService}/leasing/removeDocument/${leaseId}/${docItem?.documentName}?documentId=${docItem?.documentId}`;
        await axios
          .get(url, {})
          .then(async(response) => {
            await dispatch(getBookingDetail(bookingDetails?.leaseId));
            message.success("Removed document");
          })
          .catch((error) => {
            message.error(error.response.data.error);
          });
      },
    });
  };


  const sendForEsignature = async (document) => {
    PopupConfirm({
      title: `This action will send the document to the tenant for signature. Are you sure?`,
      cancelText: "No",
      okText: "Yes",
      onOk: async () => {
        props.parentState({ load: true });
        await sendContractForSignService({ leaseId, document });
        await dispatch(getBookingDetail(bookingDetails?.leaseId));
        props.parentState({ load: false });
      },
    });
  };

  const prepareDocsPayload = async (data: any, docKey: string) => {
    const documentId: string = data?.documentId;
    const type: string = data?.document?.type;
    const documents: any[] = bookingDetails?.documents || [];
    let updatedDocs = [];
    if (documents?.length > 0 && documents?.find(e => e.documentName == docKey))
      updatedDocs = documents?.map(e => { if (e?.documentName == docKey) e = { ...e, documentId, type }; return e; })
    else updatedDocs = [...documents, { documentName: docKey, type, documentId }];
    return updatedDocs || [];
  }

  const uploadRequiredDocument = async (file, docKey?: string) => {
    if (bookingDetails?.requiredDocuments?.length > 0) {
      props.parentState({ load: true });
      try {
        const { data } = await uploadDocumentMedia({ file_asset: file as RcFile });
        const updatedDocs = await prepareDocsPayload(data?.result, docKey);
        const requiredDocs = bookingDetails?.requiredDocuments.map(i => { if (i.type == docKey && i.status == "rejected") i.status = "pending"; return i; });
        let body = { documents: updatedDocs, requiredDocuments: requiredDocs }; //changed from requiredDocuments // BUG FIX CHANGED ALL OF THIS TO FIX THE UPLOAD PAYLOAD ISSUE AS PER AJY
        data?.result && await dispatch(updateBooking({ leaseId, body, onlyBooking: true }));
        await dispatch(getBookingDetail(leaseId))
        message.success("Successfully uploaded document");
        props.parentState({ load: false });
        //  for getting success code on upload and then
        //  show popup for getting expiry of document if required
        return data?.result;

      } catch ({ response }) {
        message.error(response?.data?.error);
        props.parentState({ load: false });
      }
    } else message.error("Required documents are not added in the form");
  }

  const handleAttachDocAction = (data) => {
    props.parentState({
      modalPayload: {
        iKey: "15",
        width: "600px",
        extraText: "",
        title: "Attach Docs",
        attachDoc: data,
        open: true,
      }, 
    });
  
  }

  const getGeneratedDocuments = () => {
    const documents = [];
    let contract = null;
    const ejariDocs: any[] = bookingDetails?.ejari?.ejariDocuments || [];
    const addDocs: any[] = bookingDetails?.documents || [];
    const attachDocs: any[] = bookingDetails?.portalDocuments || [];

    const docs = [...ejariDocs?.map(i => ({ ...i, isLeasingDoc: true })), ...addDocs];
  
    //------------------------------------------------------------------------
    //contract documents
    let unsigned = docs.find(
      (item) => item.documentName === "unSignedEjariContract"
    );
    let tensigned = docs.find(
      (item) => item.documentName === "LTV_tenantSignedEjariContract"
    );
    let lantansigned = docs.find(
      (item) => item.documentName === "LTV_landlordSignedEjariContract"
    );
    //contract checks
    if (lantansigned && tensigned)
      contract = { ...lantansigned, signBy: "both" };
    else if (lantansigned) contract = { ...lantansigned, signBy: "landlord" };
    else if (tensigned) contract = { ...tensigned, signBy: "tenant" };
    else if (unsigned) contract = { ...unsigned, signBy: "unsigned" };
    contract &&
      documents.push({ ...{ label: "Tenancy Contract" }, ...contract });
    //-----------------------------------------------------------------------
    //additional documents
    templatesData?.forEach((template) => {
      let additional = docs.find(
        (item) => item.documentName === template?.value
      ); 
      if (additional && additional?.addToLease == true) {
        if (additional?.signaturesPlaceholder == true) {
          if (additional.isTenantSigned == true)
            additional = { ...additional, signBy: "tenant" };
          else additional = { ...additional, signBy: "unsigned" };
        }
        additional &&
          documents.push({
            ...{ label: template?.label, remove: true },
            ...additional,
          });
      }
    });

    //------------------------------------------------------------------------
    //Bookingform document
    let bookingForm = docs.find(
      (item) => item.documentName == "bookingForm"
    );
    if (bookingForm && bookingForm?.documentId) { //&& bookingForm?.addToLease == true
      if (bookingForm?.signaturesPlaceholder == true) {
        if (bookingForm.isTenantSigned == true)
          bookingForm = { ...bookingForm, signBy: "tenant" };
        else bookingForm = { ...bookingForm, signBy: "unsigned" };
      }
      bookingForm &&
        documents.push({
          ...{ label: "Booking Form", remove: false, addToLease: true },
          ...bookingForm,
        });
    }
    //------------------------------------------------------------------------

    //------------------------------------------------------------------------
    //Attach portal documents
    if (attachDocs?.length > 0) {
      attachDocs.map(item => {
        documents.push({
          ...{ label: item?.documentName, remove: true, addToLease: true, handleAction: () => handleAttachDocAction(item) },
          ...item,
        });
      })
    }
    //------------------------------------------------------------------------


    return documents;
  };

  const onSubmit = async (data: any, type) => {
    let dataCopy = { ...(data || getValues()) };
    const selectedDocs =
      dataCopy?.requiredDocuments?.length > 0
        ? dataCopy?.requiredDocuments
          ?.filter((d) => d?.checked === true)
          .map((i) => ({ type: i.type, status: "pending" }))
        : [];

    const additionalDocs =
      dataCopy?.additionalDocs?.length > 0
        ? dataCopy?.additionalDocs?.map((i) => ({
          type: i?.value,
          status: "pending",
        }))
        : [];

    let body = {
      requiredDocuments: [...selectedDocs, ...additionalDocs],
    };

    let valid = true;

    if (valid) {
      dispatch(setBookingForm(body));

      body = { ...bookingForm, ...body };

      props.parentState({ load: true });

      try {
        if (type === "done") body["createBooking"] = "yes"; //moved this condition down to not store this in redux

        const response = await dispatch(
          !isBooking ? createBooking(body) : updateBooking({ leaseId, body })
        );

        !isBooking && (await dispatch(getBookingDetail(response.data?.result)));

        props.parentState({ load: false });
        message.success(response.data.message);
        if (type === "done") navigate("/bookings");
      } catch (error) {
        props.parentState({ load: false });
        message.error(
          error.response?.data?.error || error.response?.data?.message
        );
      }
    }
  };


  return (
    <Form
      layout="vertical"
      name="bookingform"
      scrollToFirstError
      className="bf-main"
      onFinish={handleSubmit(onSubmit)}
    >
      <Loading visible={loading} />

      <Col span={24}>
        <Row gutter={24}>
          {booking?.requiredDocuments?.map((val, index) => {
            return (
              <React.Fragment key={index+val?.title}>
                <Col span={24}>
                  <TenantDocs
                    control={control}
                    document={val}
                    setState={setState}
                    disable={disableAddDoc}
                    uploadRequiredDocument={uploadRequiredDocument}
                    onRemoveDocument={(e) =>
                      onRemoveDocument(val)
                    }
                    docLabel={
                      configData["requiredBookingDocumentsList"]
                    }
                  />
                </Col>
              </React.Fragment>
            );
          })}

          {getGeneratedDocuments()?.map((item, index) => {
            return (
              item?.addToLease === true &&
              item?.documentId && (
                <React.Fragment key={index}>
                  <Col span={24}>
                    <GeneratedDoc
                      LEASEID={leaseId}
                      title={item.label}
                      setValue={setValue}
                      control={control}
                      sendForEsignature={sendForEsignature}
                      data={item}
                      documentId={item.documentId}
                      onRemoveDocument={() => onRemoveDocument(item)
                      }
                    />
                  </Col>
                </React.Fragment>
              )
            );
          })}

          <Col span={24} className="other-docs">
        
            <Row gutter={[24, 24]} justify="end">
              <Col span={24}>
                <Popover
                  placement="top"
                  className="ef-pop-over"
                  content={
                    <List size="small">
                      <List.Item key={1} onClick={() => !disableAddDoc && handleGenerate("6")}>
                        <Button className="p-0" type="link" disabled={disableAddDoc}>
                          <BsPerson style={{ top: "2px", position: "relative" }} /> &nbsp; Request document from Tenant
                        </Button>
                      </List.Item>
                      <List.Item key={2} onClick={() => !disableGenDoc && handleGenerate("7")}>
                        <Button className="p-0" type="link" disabled={disableGenDoc}>
                          <BsFiles /> &nbsp; Generate document from Template
                        </Button>
                      </List.Item>
                      <List.Item key={3} onClick={() => handleGenerate("15")}>
                        <Button className="p-0" type="link">
                          <PaperClipOutlined /> &nbsp; Attach Document
                        </Button>
                      </List.Item>
                    </List>
                  }
                >
                  <Button
                    type="dashed"
                    htmlType="button"
                    icon={<PlusOutlined />}
                    block={true}
            
                  >
                    <Text
                      className="ag-primary fontWeight600"
                      style={{ fontSize: "14px" }}
                    >
                      Add More Documents
                    </Text>
                  </Button>
                </Popover>
              </Col>
            </Row>
            {/* )} */}
          </Col>
        </Row>
      </Col>
    </Form>
  );
};
