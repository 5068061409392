import React, { useState, useEffect, Fragment } from "react";

// others
import { CommonCard } from "../components";
import { useForm, useWatch } from "react-hook-form";
import { Row, Col, Form, Radio, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Info, Calendar } from "../../../../assets/svg";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import {
  translateService,
  getKycDetailsService,
  getTenantDetailByEmailService,
} from "../ducks/services";
import {
  DateField,
  InputField,
  InputRadio,
  ReactSelectField,
} from "../../../atoms/FormElement";
import {
  KYC_CONTENT,
  radioOptions,
  kycInitValue,
  filterPayload,
  validateEmail,
  marital_status,
  handleKycFields,
  signatoryPayload,
  filterKycSelectList,
  filterDropdownValue,
} from "./utils";
import {
  setFormState,
  createBooking,
  updateBooking,
  setBookingForm,
  getBookingDetail,
  getTenantDetailByEmail,
} from "../ducks/actions";
import dayjs from "dayjs";

interface IProps {
  parentState: (obj: object) => void;
  usageType: "commercial" | "residential";
}

let initialState = {
  kycDetails: [],
  isSignatory: "",
};

let timeOutId: any = "";
let aTimeOutId: any = "";
var _ = require("lodash");

const index = (props: IProps) => {
  // hooks initialization
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch: any = useDispatch();
  const { leaseId = "" } = useParams();
  // redux states
  const { configData, bookingForm, bookingDetails } = useSelector( (state: any) => state.newBooking
  );

  const {
    trigger,
    control,
    setValue,
    setError,
    getValues,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const email = useWatch({ control, name: "email" });
  const signatoryEmail = useWatch({ control, name: "signatoryEmail" });
  const name = useWatch({ control, name: "name" });
  const signatoryName = useWatch({control,name: "signatoryName"});

  const isBooking = Object.keys(bookingDetails).length > 0;

  // states
  const [{ kycDetails, isSignatory }, setState] = useState(initialState);

  const updateState = (state: {}) => setState((prevState: any) => ({ ...prevState, ...state }));

  useEffect(() => {
    if (isBooking) {
      updateState({
        isSignatory: bookingForm?.isSignatory || bookingDetails?.isSignatory,
      });
      setValue(
        "isSignatory",
        bookingForm?.isSignatory || bookingDetails?.isSignatory
      );
    }

    getKycDetails();
  }, [isBooking]);

  useEffect(() => {
    if (validateEmail(email) && email !== bookingForm?.email)
      dispatch(getTenantDetailByEmail(email));

    if (
      email !== signatoryEmail &&
      validateEmail(signatoryEmail) &&
      signatoryEmail !== bookingForm?.signatoryUser?.email
    )
      getSignatoryTenant(signatoryEmail);
  }, [email, signatoryEmail]);

  useEffect(() => {
    if (name && name.length > 1) {
      clearTimeout(timeOutId);

      timeOutId = setTimeout(async () => {
        const response = await translateService(name);
        if (response?.translatedText)
          setValue("arabicName", response?.translatedText);
      }, 2000);
    }

    if (signatoryName && signatoryName.length > 1) {
      clearTimeout(aTimeOutId);

      aTimeOutId = setTimeout(async () => {
        const response = await translateService(signatoryName);
        if (response?.translatedText)
          setValue("signatoryArabicName", response?.translatedText);
      }, 2000);
    }
  }, [name, signatoryName]);

  const getSignatoryTenant = async (email: string) => {
    try {
      const response = await getTenantDetailByEmailService(email);
      if (response) {
        setValue("signatoryEmail", response?.email);
        setValue("signatoryName", response?.tenantNameEN);
        setValue("signatoryArabicName", response?.tenantNameAR);
        setValue("signatoryMobile", response?.mobileNumber);
        setValue("designation", response?.designationAsPerVisa);
        setValue("company", response?.company);
      }
    } catch (error: any) {}
  };

  const getKycDetails = async () => {
    try {
      const unitId = !isBooking
        ? state?.selectedUnit?.unitId
        : bookingDetails?.unitId;

      const response = await getKycDetailsService(unitId);
      if (response?.length > 0) updateState({ kycDetails: response });
    } catch (error) {}
  };


  const onSubmit = async (data?: any, type?) => {
    clearErrors();
    let dataCopy = { ...(data || getValues()) };
    dataCopy = props.usageType === "residential"
        ? {
            ...dataCopy,
            ...{
              //TODO: ONLY FOR RESIDENTIAL
              nationality: dataCopy.nationality.value,
              maritalStatus: dataCopy.maritalStatus.value,
            },
          }
        :
         { ...dataCopy };

    if (!isBooking) {
      dataCopy = {
        ...dataCopy,
        ...{
          unitCode: state.selectedUnit.unit.unitCode,
        },
      };
    }

    if (kycDetails?.length > 0) dataCopy = handleKycFields(dataCopy);

    if (type === "next") {
      let valid = true;

      for (let key in dataCopy) {
        if (!dataCopy[key]) {
          valid = false;
          await trigger("", { shouldFocus: true });
          break;
        }
      }
      valid = await trigger("", { shouldFocus: true });
      if (valid) {
        if (dataCopy["isSignatory"] === "no")
          dataCopy["signatoryUser"] = signatoryPayload(dataCopy);

        if (!isBooking) {
          props.parentState({ load: true });
          try {
            const response = await dispatch(
              !isBooking
                ? createBooking(filterPayload(dataCopy, props.usageType))
                : updateBooking({
                    leaseId,
                    body: filterPayload(dataCopy, props.usageType),
                  })
            );

            !isBooking &&
              (await dispatch(getBookingDetail(response.data?.result)));
            props.parentState({ load: false });
            message.success(response.data.message);
            !isBooking &&
              navigate(`/newbookingform/${response.data?.result}`, {
                replace: true,
              });
            dispatch(setFormState());
          } catch (error) {
            props.parentState({ load: false });
            message.error(
              error.response?.data?.error || error.response?.data?.message
            );
          }
        } else {
          dispatch(setBookingForm(filterPayload(dataCopy, props.usageType)));
          dispatch(setFormState());
        }
      }
    } else {
      props.parentState({ load: true });

      if (dataCopy["isSignatory"] === "no")
        dataCopy["signatoryUser"] = signatoryPayload(dataCopy);

      try {
        const response = await dispatch(
          !isBooking
            ? createBooking(filterPayload(dataCopy, props.usageType))
            : updateBooking({
                leaseId,
                body: filterPayload(dataCopy, props.usageType),
              })
        );

        // !isBooking && (
          await dispatch(getBookingDetail(leaseId || response.data?.result));
          // );

        props.parentState({ load: false });
        message.success(response.data.message);
        !isBooking &&
          navigate(`/newbookingform/${response.data?.result}`, {
            replace: true,
          });
      } catch (error) {
        props.parentState({ load: false });
        message.error(
          error.response?.data?.error || error.response?.data?.message
        );
      }
    }
  };

  const signatoryRequired = isSignatory === "no";
  
  const handleBack = () => {
    navigate(-1);
  };


  return (
    <Form
      layout="vertical"
      name="bookingform"
      scrollToFirstError
      className="bf-main"
      onFinish={handleSubmit(onSubmit)}
    >
      <CommonCard
        leftBtnLabel="Back"
        handleBack={handleBack}
        rightBtnLabel="Next"
        handleNext={onSubmit}
        label="Tenant Details"
        parentState={props.parentState}
        additionalLabel="Enter an email address to look for an existing tenant or add a new one."
      >
        <Col span={24}>
          <Row gutter={24}>
            <Col span={8}>
              <InputField
                control={control}
                fieldname="email"
                isRequired={true}
                setValue={setValue}
                label="Email Address"
                valueGot={bookingForm["email"]}
                validate={errors.email && "error"}
                iProps={{ placeholder: "john.smith@email.com" }}
                validMessage={errors.email && errors.email.message}
                rules={{
                  required: "Email Address Required",
                  pattern: {
                    message: "Please enter valid email",
                    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/,
                  },
                }}
              />
            </Col>

            <Col span={8}>
              <InputField
                fieldname="name"
                control={control}
                isRequired={true}
                setValue={setValue}
                label="Tenant Name"
                valueGot={bookingForm["name"]}
                validate={errors.name && "error"}
                iProps={{ placeholder: "John Smith" }}
                validMessage={errors.name && errors.name.message}
                rules={{
                  required: "Please enter tenant name",
                  pattern: {
                    value: /^[a-zA-Z_ ]*$/,
                    message: "Please enter valid tenant name",
                  },
                }}
              />
            </Col>

            {props.usageType?.toLocaleLowerCase() === "residential" && (
              <Col span={8}>
                <InputField
                  isRequired={true}
                  control={control}
                  setValue={setValue}
                  fieldname="arabicName"
                  label="Tenant Name Arabic"
                  iProps={{ placeholder: "جون سميث" }}
                  valueGot={bookingForm["arabicName"]}
                  validate={errors.arabicName && "error"}
                  validMessage={errors.arabicName && errors.arabicName.message}
                  rules={{
                    required: "Please enter tenant arabic name",
                    pattern: {
                      value: /[\u0600-\u06FF]/,
                      message: "Please enter valid tenant arabic name",
                    },
                  }}
                />
              </Col>
            )}
          </Row>

          {/* next row */}
          <Row gutter={24}>
            <Col span={8}>
              <InputField
                isRequired={true}
                control={control}
                fieldname="mobile"
                setValue={setValue}
                label="Mobile number"
                valueGot={bookingForm["mobile"]}
                validate={errors.mobile && "error"}
                validMessage={errors.mobile && errors.mobile.message}
                iProps={{ addonBefore: "+971", placeholder: "554441212" }}
                rules={{
                  pattern: /^[\d]{0,9}$/,
                  minLength: {
                    value: 9,
                    message:
                      "Please enter valid mobile number, 9 digits required",
                  },
                  maxLength: {
                    value: 9,
                    message:
                      "Please enter valid mobile number, 9 digits required",
                  },
                  required: "Please enter Mobile No.",
                }}
              />
            </Col>
            {/*------------------------------------- RESIDENTIAL ONLY FIELDS ------------------------------------- */}
            {props.usageType == "residential" && (
              <>
                <Col span={8}>
                  <ReactSelectField
                    isRequired={true}
                    control={control}
                    label="Nationality"
                    setValue={setValue}
                    fieldname="nationality"
                    iProps={{ placeholder: "Select" }}
                    validate={errors.nationality && "error"}
                    selectOption={configData["nationalityList"]}
                    rules={{ required: "Please select nationality" }}
                    validMessage={
                      errors.nationality && errors.nationality.message
                    }
                    valueGot={filterDropdownValue(
                      bookingForm["nationality"],
                      configData["nationalityList"]
                    )}
                  />
                </Col>

                <Col span={8}>
                  <ReactSelectField
                    control={control}
                    isRequired={true}
                    setValue={setValue}
                    label="Marital Status"
                    fieldname="maritalStatus"
                    selectOption={marital_status}
                    iProps={{ placeholder: "Select" }}
                    validate={errors.maritalStatus && "error"}
                    rules={{ required: "Please select marital status" }}
                    validMessage={
                      errors.maritalStatus && errors.maritalStatus.message
                    }
                    valueGot={filterDropdownValue(
                      bookingForm["maritalStatus"],
                      marital_status
                    )}
                  />
                </Col>
              </>
            )}

            {/*------------------------------------- COMMERCIAL ONLY FIELDS ------------------------------------- */}
            {props.usageType == "commercial" && (
              <>
                <Col span={8}>
                  <InputField
                    isRequired={true}
                    control={control}
                    setValue={setValue}
                    label="Designation"
                    fieldname="tenantDesignation"
                    iProps={{ placeholder: "Manager" }}
                    valueGot={
                      bookingForm["designation"] ||
                      bookingDetails["designationAsPerVisa"]
                    }
                    validate={errors.tenantDesignation && "error"}
                    validMessage={
                      errors.tenantDesignation &&
                      errors.tenantDesignation.message
                    }
                    rules={{ required: "Please enter designation" }}
                  />
                </Col>

                <Col span={8}>
                  <InputField
                    isRequired={true}
                    control={control}
                    setValue={setValue}
                    label="Company"
                    fieldname="tenantCompany"
                    iProps={{ placeholder: "Xyz Real Estate" }}
                    valueGot={bookingForm["company"]}
                    validate={errors.tenantCompany && "error"}
                    validMessage={
                      errors.tenantCompany && errors.tenantCompany.message
                    }
                    rules={{ required: "Please enter company" }}
                  />
                </Col>
              </>
            )}
            {/*--------------------------------------------------------------------------------------- */}
          </Row>

          {/* next row */}
          <Row gutter={24}>
            {kycDetails.map((item: any, index: number) => {
              const tooltip = {
                title: KYC_CONTENT,
                icon: <Info width="16px" height="16px" />,
              };

              return (
                <Fragment key={item.label}>
                  {["text", "number", "numberWithComma"].includes(
                    item.type
                  ) && (
                    <Col span={8}>
                      <InputField
                        control={control}
                        label={item.label}
                        fieldname={`kyc-${item.name}`}
                        iProps={{ placeholder: item.label }}
                        initValue={kycInitValue(
                          item.name,
                          bookingDetails?.kycDetails
                        )}
                        {...{ tooltip }}
                      />
                    
                    </Col>
                  )}

                  {["date"].includes(item.type) && (
                    <Col span={8}>
                      <DateField
                        control={control}
                        label={item.label}
                        fieldname={`kyc-${item.name}`}
                        iProps={{
                          suffixIcon: <Calendar />,
                          placeholder: "DD-MM-YYYY",
                        }}
                        initValue={kycInitValue(
                          item.name,
                          bookingDetails?.kycDetails
                        )}
                        {...{ tooltip }}
                      />
                     
                    </Col>
                  )}

                  {["select"].includes(item.type) && (
                    <Col span={8}>
                      <ReactSelectField
                        control={control}
                        label={item.label}
                        fieldname={`kyc-${item.name}`}
                        iProps={{ placeholder: item.label }}
                        selectOption={filterKycSelectList(item.options[0])}
                        initValue={filterDropdownValue(
                          kycInitValue(item.name, bookingDetails?.kycDetails),
                          filterKycSelectList(item.options[0])
                        )}
                        {...{ tooltip }}
                      />
                    </Col>
                  )}
                </Fragment>
              );
            })}
          </Row>

          {/* next row */}
          <Row gutter={24}>
            <Col span={8}>
              <InputRadio
                initValue={"yes"}
                isRequired={true}
                control={control}
                fieldname="isSignatory"
                validate={errors.isSignatory && "error"}
                label="Is the tenant the authorized signatory?"
                setValue={setValue}
                valueGot={bookingForm["isSignatory"]}
                validMessage={errors.isSignatory && errors.isSignatory.message}
                rules={{
                  required: "This field is required",
                }}
                onChange={(e: any) =>
                  updateState({ isSignatory: e.target.value })
                }
                options={
                  <Row gutter={[16, 16]}>
                    {radioOptions.map((option, i) => (
                      <Col span={6} key={`${option.value}-${i}`}>
                        <Radio value={option.value}>{option.label}</Radio>
                      </Col>
                    ))}
                  </Row>
                }
              />
            </Col>
          </Row>

          {/* signatory */}
          {signatoryRequired && (
            <Col span={24}>
              <p className="td-label">Additional Information for Signatory</p>
              <div className="ft-divider" />

              <Row gutter={24}>
                <Col span={8}>
                  <InputField
                    control={control}
                    setValue={setValue}
                    label="Email Address"
                    fieldname="signatoryEmail"
                    isRequired={signatoryRequired}
                    validate={errors.signatoryEmail && "error"}
                    valueGot={bookingForm?.signatoryUser?.email}
                    iProps={{ placeholder: "john.smith@email.com" }}
                    validMessage={
                      errors.signatoryEmail && errors.signatoryEmail.message
                    }
                    rules={{
                      required: "Email Address Required",
                      pattern: {
                        message: "Please enter valid email",
                        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/,
                      },
                    }}
                  />
                </Col>

                <Col span={8}>
                  <InputField
                    control={control}
                    setValue={setValue}
                    label="Signatory Name"
                    fieldname="signatoryName"
                    isRequired={signatoryRequired}
                    iProps={{ placeholder: "John Smith" }}
                    validate={errors.signatoryName && "error"}
                    valueGot={bookingForm?.signatoryUser?.name}
                    validMessage={
                      errors.signatoryName && errors.signatoryName.message
                    }
                    rules={{
                      required: "Please enter signatory name",
                      pattern: {
                        value: /^[a-zA-Z_ ]*$/,
                        message: "Please enter valid signatory name",
                      },
                    }}
                  />
                </Col>
                {props.usageType?.toLocaleLowerCase() === "residential" && (
                  <Col span={8}>
                    <InputField
                      control={control}
                      setValue={setValue}
                      label="Signatory Arabic Name"
                      isRequired={signatoryRequired}
                      fieldname="signatoryArabicName"
                      iProps={{ placeholder: "جون سميث" }}
                      validate={errors.signatoryArabicName && "error"}
                      valueGot={bookingForm?.signatoryUser?.arabicName}
                      rules={{
                        required: "Please enter signatory arabic name ",
                      }}
                      validMessage={
                        errors.signatoryArabicName &&
                        errors.signatoryArabicName.message
                      }
                    />
                  </Col>
                )}
              </Row>

              {/* next row */}
              <Row gutter={24}>
                <Col span={8}>
                  <InputField
                    control={control}
                    setValue={setValue}
                    label="Mobile number"
                    fieldname="signatoryMobile"
                    isRequired={signatoryRequired}
                    validate={errors.signatoryMobile && "error"}
                    valueGot={bookingForm?.signatoryUser?.mobile}
                    iProps={{ addonBefore: "+971", placeholder: "554441212" }}
                    validMessage={
                      errors.signatoryMobile && errors.signatoryMobile.message
                    }
                    rules={{
                      pattern: /^[\d]{0,9}$/,
                      minLength: {
                        value: 9,
                        message:
                          "Please enter valid mobile number, 9 digits required",
                      },
                      maxLength: {
                        value: 9,
                        message:
                          "Please enter valid mobile number, 9 digits required",
                      },
                      required: "Please enter Mobile No.",
                    }}
                  />
                </Col>

                <Col span={8}>
                  <InputField
                    control={control}
                    label="Designation"
                    setValue={setValue}
                    fieldname="designation"
                    isRequired={signatoryRequired}
                    iProps={{ placeholder: "Manager" }}
                    validate={errors.designation && "error"}
                    valueGot={bookingForm?.signatoryUser?.designation}
                    rules={{ required: "Please enter your designation." }}
                    validMessage={
                      errors.designation && errors.designation.message
                    }
                  />
                </Col>

                <Col span={8}>
                  <InputField
                    label="Company"
                    control={control}
                    fieldname="company"
                    setValue={setValue}
                    isRequired={signatoryRequired}
                    validate={errors.company && "error"}
                    iProps={{ placeholder: "Xyz Real Estate" }}
                    valueGot={bookingForm?.signatoryUser?.company}
                    rules={{ required: "Please enter company name." }}
                    validMessage={errors.company && errors.company.message}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Col>
      </CommonCard>
    </Form>
  );
};

export default index;
