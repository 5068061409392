import * as action_types from "./constants";
var _ = require("lodash");

const initialState = {
  unitsList: [],
  parkingUnitsList:[],
  plan: [],
  unitData: [],
  bookingUnits: []
};

export default (state = initialState, action) => {
  const { type, data } = action;
  
  switch (type) {
    
    case action_types.PROPERTY_UNITS:
     
      return {
        ...state, unitsList: data,
        unitsDropdown: _.map(data, (item) => ({
          value: item.unitId,
          label: item.unit?.unitCode,
        }))
      };
    case action_types.PARKING_UNITS:
      return {
        ...state, parkingUnitsList: data,
        // unitsDropdown: _.map(data, (item) => ({
        //   value: item.unitId,
        //   label: item.unit?.unitCode,
        // }))
      };
    case action_types.BOOKING_PROPERTY_UNITS:
      return {
        ...state, bookingUnits: data.length > 0 ? data : [],
        bookingUnitsDropdown: _.map(data, (item) => ({
          value: item.unitId,
          label: item.unit?.unitCode,
        }))
      };
    case action_types.GET_PAYMENT_PLAN:
      return { ...state, plan: data };
    case action_types.GET_SINGLE_UNIT:
      return { ...state, unitData: data };


    default:
      return state;
  }
};
