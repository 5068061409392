import React, { FC, useState } from 'react'
import { Form, Row, Col, Button, Upload, Avatar } from "antd";
import { disablePreviousDate, datePlaceholderddmmyyFormat } from '../utils';
import { UploadOutlined } from "@ant-design/icons"
import { useForm } from "react-hook-form";
import { DateField, InputField } from "../../../../atoms/FormElement";


/**
* @author
* @function @
**/

function DocExpiry({ getDocumentExpiry, fileProps, document, uploadFile }) {

    const { control, formState: { errors }, setValue, handleSubmit, reset } = useForm();

    const [fileUploadErrors, setFileUploadErrrors]: any = useState(false);

    const handleDataSubmit = async (data: any) => {

        if (!uploadFile) {
            setFileUploadErrrors(true);
            return false;
        } else {

            let successCode = await getDocumentExpiry(data);
            if (successCode == 200) {
                reset();
            }
        }



    };

    const onError = errors => {

        if (errors?.documentId || errors?.docExpiry) {
            setFileUploadErrrors(true)
        } else {
            setFileUploadErrrors(false)
        }
    };

    const documentTitle = () => {
        return document?.title;
    }
    return (

        <Form layout="vertical" onFinish={handleSubmit(handleDataSubmit, onError)}>
            <Row gutter={8}>
                {
                    (document?.isExpiry) &&
                    <>
                        <Col span={12}>
                            <InputField
                                label={`Enter ${documentTitle()?.length > 30 ? documentTitle()?.slice(0, 28) + '...' : documentTitle()} id`}
                                fieldname="documentId"
                                iProps={{ placeholder: "Document Id" }}
                                initValue=""
                                setValue={setValue}
                                isRequired={true}
                                control={control}
                                rules={{ required: `Please enter  ${documentTitle()} Id` }}
                                validate={errors.documentId && "error"}
                                validMessage={errors.documentId && errors.documentId.message}
                                isPatteren={true}

                            />
                        </Col>

                        <Col span={11} title={documentTitle() + ' ' + 'Expiry'}>
                            <DateField

                                control={control}
                                label={`Enter ${documentTitle()?.length > 20 ? documentTitle()?.slice(0, 16) + '..' : documentTitle()} expiry`}
                                isRequired={true}
                                rules={{ required: `Please enter ${documentTitle()} expiry date` }}
                                fieldname={`docExpiry`}
                                validate={errors.docExpiry && "error"}
                                initValue={""}
                                validMessage={errors.docExpiry && errors.docExpiry.message}
                                iProps={{
                                    className: "cd",
                                    disabledDate: disablePreviousDate,
                                    placeholder: datePlaceholderddmmyyFormat,
                                }}
                            />
                        </Col>
                    </>
                }

                <Col span={24}>
                    <Upload {...fileProps}>
                        {

                            <Button className={(fileUploadErrors && !uploadFile) ? 'custom-upload-button-alert' : 'custom-upload-button'}>
                                <UploadOutlined /> {uploadFile ? uploadFile.name + " " + 'ready to upload' : 'Click to Upload'}
                            </Button>

                        }
                    </Upload>
                    {
                    (fileUploadErrors && !uploadFile) ?  <span style={{ color: '#ff4d4f', marginTop: '5px', fontSize: '12px' }}> {documentTitle()} image is required   </span>
                    :
                    !uploadFile &&  <span style={{fontSize:'12px'}}>only png, jpeg, jpg, pdf formates are accepted.</span>
                    }
                </Col>

                {
                    (document?.isExpiry) &&
                    <Col span={24}>
                       <div style={{display:'flex',justifyContent:'center'}}>
                        <div>
                        <Button className="ef-approve-btn" size="large" htmlType="submit" style={{ marginTop: '13px', height: '35px', paddingTop: '4px' }}>
                            {"Submit"}
                        </Button>
                        </div>
                       </div>
                    </Col>
                }
            </Row>

        </Form>
    )
}

export default React.memo(DocExpiry);