import React, { useState, useEffect } from "react";
import RequireDocument from "../../../molecules/RequirDoc";
import { Col, message } from "antd";
import { uniquiFileName } from "../../../../utils/fileUpload";
import { useWatch } from "react-hook-form";
import OtherDocs from "../../ContractFlow/ContractBookingForm/OtherDocs";
import GeneratedDoc from "../../../molecules/GeneratedDoc";
import { PopupConfirm } from "../../../atoms/Popup";
import axios from "axios";
import { leaseService } from "../../../../configs/constants";

const fileObjectinit = {
  passportFile: {
    uniqueName: "",
    fileData: "",
  },
  visaFile: {
    uniqueName: "",
    fileData: "",
  },
  emiratesFile: {
    uniqueName: "",
    fileData: "",
  },
  financialSupportFile: {
    uniqueName: "",
    fileData: "",
  },
  signedBookFile: {
    uniqueName: "",
    fileData: "",
  },
  otherDocsFile: {
    uniqueName: "",
    fileData: "",
  },
};

export default (props) => {
  const [docFileOriObject, setDocFileOriObject] = useState(fileObjectinit);
  const [requiredDocArray, setRequiredDocArray] = useState([]);
  const [requiredBookingDocuments, setRequiredBookingDocuments] = useState([]);


  const {
    LEASEID,
    control,
    setValue,
    leaseData,
    setFinalRequiredDocuments,
    setSendEmptyDoc,
    RejectDocument,
    ApproveDocument,
    resetField,
    requiredBookingDocumentsList,
    isAdd,
    disableClone,
    isRenewal,
    tenantAccepted,
    popup,
    generatedDocs,
    onUpdate,
    sendForEsignature,
    contractSent,
    sendSignatureBtn
  } = props;

  // const generatedDocs = [
  //   {
  //     "label": "Tenancy Contract",
  //     "documentName": "unSignedEjariContract",
  //     "type": "pdf",
  //     "documentId": "documentId_a965994b-f992-44df-ba28-1040b4e0723c",
  //     "addToLease": true,
  //     "sendToTenant": false,
  //     "createdAt": "2023-08-14T11:18:25.614Z",
  //     "updatedAt": "2023-08-14T11:18:25.614Z"
  // }
  // ];

  const onRemoveDocument = async (key) => {
    PopupConfirm({
      title: `Are you sure you want to remove`,
      okText: "Yes",
      onOk: async () => {
        // api/v1/leasing/removeDocument/leaseId-13a34c9d-863f-46fb-98ed-7594b5cf5501/ADDITIONAL_DOCUMENT'
        const url = `${leaseService}/leasing/removeDocument/${LEASEID}/ADDITIONAL_DOCUMENT`;
        await axios.get(url, {}).then((response) => {
          message.success("Removed document");
          onUpdate();
        }).catch((error) => {
          message.error(error.response.data.error);
        });
      },
    });
  }


  const getDocKeys = (key) => {
    if (
      leaseData &&
      leaseData?.tenantDetail &&
      Object.keys(leaseData?.tenantDetail).length
    ) {
      if (
        leaseData?.tenantDetail?.contacts?.length &&
        leaseData?.tenantDetail?.selectedContact
      ) {
        const defaultContact = leaseData?.tenantDetail?.contacts?.find(
          (e) => e.party_id === leaseData?.tenantDetail?.selectedContact,
        );
        if (defaultContact) {
          const passportKey = defaultContact?.documents?.find(
            (e) => e.purpose_type === "passport",
          )?.passport_key;
          const visaKey = defaultContact?.documents?.find(
            (e) => e.purpose_type === "visa",
          )?.visa_key;
          const emiratesKey = defaultContact?.documents?.find(
            (e) => e.purpose_type === "emirates_id",
          )?.emirates_id_key;

          switch (key) {
            case "passport":
              return passportKey;
            case "visa":
              return visaKey;
            case "emirates":
              return emiratesKey;
            default:
              break;
          }
        } else {
          const passportKey = leaseData?.tenantDetail?.documents?.find(
            (e) => e.purpose_type === "passport",
          )?.passport_key;
          const visaKey = leaseData?.tenantDetail?.documents?.find(
            (e) => e.purpose_type === "visa",
          )?.visa_key;
          const emiratesKey = leaseData?.tenantDetail?.documents?.find(
            (e) => e.purpose_type === "emirates_id",
          )?.emirates_id_key;

          switch (key) {
            case "passport":
              return passportKey;
            case "visa":
              return visaKey;
            case "emirates":
              return emiratesKey;
            default:
              break;
          }
        }

        return "";
      } else {
        const passportKey = leaseData?.tenantDetail?.documents?.find(
          (e) => e.purpose_type === "passport",
        )?.passport_key;
        const visaKey = leaseData?.tenantDetail?.documents?.find(
          (e) => e.purpose_type === "visa",
        )?.visa_key;
        const emiratesKey = leaseData?.tenantDetail?.documents?.find(
          (e) => e.purpose_type === "emirates_id",
        )?.emirates_id_key;

        switch (key) {
          case "passport":
            return passportKey;
          case "visa":
            return visaKey;
          case "emirates":
            return emiratesKey;
          default:
            break;
        }
      }
    } else {
      return "";
    }
  };


  // useEffect(() => {
  //   if (requiredBookingDocumentsList?.length > 0) {
  //     if (leaseData && leaseData?.requiredDocuments?.length > 0) {
  //       setRequiredBookingDocuments(requiredBookingDocumentsList)
  //     } else {
  //       let docs = requiredBookingDocumentsList.map(item => {
  //         if (item.value == "passport" || item.value == "emiratesIdFront" || item.value == "emiratesIdBack")
  //           item.isDisabled = true;
  //         else
  //           item.isDisabled = false;
  //         return item;
  //       });

  //       setRequiredBookingDocuments(docs)
  //     }
  //   }

  // }, [requiredBookingDocumentsList]);

  const watcher = useWatch({
    control: control,
    name: "contacts",
  });

  const getFromWatcher = (key) => {
    const defaultContact = leaseData?.tenantDetail?.contacts?.find(
      //@ts-ignore
      (e) => e.party_id === watcher?.value,
    );
    const passportKey = defaultContact?.documents?.find(
      (e) => e.purpose_type === "passport",
    )?.passport_key;
    const visaKey = defaultContact?.documents?.find(
      (e) => e.purpose_type === "visa",
    )?.visa_key;
    const emiratesKey = defaultContact?.documents?.find(
      (e) => e.purpose_type === "emirates_id",
    )?.emirates_id_key;

    switch (key) {
      case "passport":
        return passportKey;
      case "visa":
        return visaKey;
      case "emirates":
        return emiratesKey;
      default:
        break;
    }
    return "";
  };

  useEffect(() => {
    const arr = [];
    if (
      leaseData &&
      leaseData?.requiredDocuments?.length > 0
      // &&
      // Object.keys(leaseData?.requiredDocuments).length
    ) {
      const arr = [];
      let passportKey = "";
      let visaKey = "";
      let emiratesKey = "";

      if (watcher && Object.keys(watcher).length) {
        passportKey = getFromWatcher("passport");
        visaKey = getFromWatcher("visa");
        emiratesKey = getFromWatcher("emirates");
      } else {
        passportKey = getDocKeys("passport");
        visaKey = getDocKeys("visa");
        emiratesKey = getDocKeys("emirates");
      }

      const financialSupportKey =
        leaseData?.requiredDocuments?.financial_document || "";
      const signedFormKey =
        leaseData?.requiredDocuments?.signed_booking_form || "";
      // const otheDocKey = leaseData?.requiredDocuments?.other_document || "";

      const keyFileObj = { ...docFileOriObject };
      keyFileObj.passportFile.uniqueName = passportKey;
      keyFileObj.visaFile.uniqueName = visaKey;
      keyFileObj.emiratesFile.uniqueName = emiratesKey;
      keyFileObj.financialSupportFile.uniqueName = financialSupportKey;
      keyFileObj.signedBookFile.uniqueName = signedFormKey;
      // keyFileObj.otherDocsFile.uniqueName = otheDocKey;

      // arr.push({
      //   docName: "Passport",
      //   image: "",
      //   docData: passportKey,
      //   upKey: "passport",
      // });

      leaseData?.requiredDocuments.map(data => {
        // console.log("DATA", data)
        arr.push({
          docName: data?.type,
          id: data?.type,
          docLabel: data?.documentLabel,
          image: "",
          docType: data?.documentFileType ?? "",
          docData: data?.documentId ?? "",
          status: data?.status,
          upKey: data?.type,
          isExpired: data?.isExpired
        });
      });



      // arr.push({
      //   docName: "Valid UAE Visa",
      //   image: "",
      //   docData: visaKey,
      //   upKey: "visa",
      // });
      // arr.push({
      //   docName: "Emirates ID",
      //   image: "",
      //   docData: emiratesKey,
      //   upKey: "emirates",
      // });
      // arr.push({
      //   docName: "Financial Supporting Document",
      //   image: "",
      //   docData: financialSupportKey,
      //   upKey: "financial",
      // });
      // arr.push({
      //   docName: "Signed Booking Form",
      //   image: "",
      //   docData: signedFormKey,
      //   upKey: "signed",
      // });
      // arr.push({
      //   docName: "Other documents",
      //   image: "",
      //   docData: otheDocKey,
      //   upKey: "other",
      // });
      setRequiredDocArray(arr);
      setDocFileOriObject(keyFileObj);
      setFinalRequiredDocuments(keyFileObj);
    } else {
      //TODO: key from config data
      arr.push({
        docName: "passport",
        id: "passport",
        image: "",
        docType: "",
        docData: "",
        status: "",
        upKey: "passport",
      })
      arr.push({
        docName: "emiratesIdFront",
        id: "emiratesIdFront",
        image: "",
        docType: "",
        docData: "",
        status: "",
        upKey: "emiratesIdFront",
      })
      arr.push({
        docName: "emiratesIdBack",
        id: "emiratesIdBack",
        image: "",
        docType: "",
        docData: "",
        status: "",
        upKey: "emiratesIdBack",
      });


      // arr.push({
      //   docName: "Emirates ID",
      //   image: "",
      //   docData: "",
      //   upKey: "emirates",
      // });
      // arr.push({
      //   docName: "Valid UAE Visa",
      //   image: "",
      //   docData: "",
      //   upKey: "visa",
      // });
      // arr.push({
      //   docName: "Financial Supporting Document",
      //   image: "",
      //   docData: "",
      //   upKey: "financial",
      // });
      // arr.push({
      //   docName: "Signed Booking Form",
      //   image: "",
      //   docData: "",
      //   upKey: "signed",
      // });
      // arr.push({
      //   docName: "Other documents",
      //   image: "",
      //   docData: "",
      //   upKey: "other",
      // });
      setRequiredDocArray(arr);
    }
  }, [leaseData, watcher]);

  // const uploadFiles = (key, data) => {
  //   setSendEmptyDoc(true);
  //   const obj = { ...docFileOriObject };
  //   switch (key) {
  //     case "passport":
  //       obj.passportFile.fileData = data;
  //       obj.passportFile.uniqueName = uniquiFileName(data?.name);
  //       break;
  //     case "visa":
  //       obj.visaFile.fileData = data;
  //       obj.visaFile.uniqueName = uniquiFileName(data?.name);
  //       break;
  //     case "emirates":
  //       obj.emiratesFile.fileData = data;
  //       obj.emiratesFile.uniqueName = uniquiFileName(data?.name);
  //       break;
  //     case "financial":
  //       obj.financialSupportFile.fileData = data;
  //       obj.financialSupportFile.uniqueName = uniquiFileName(data?.name);
  //       break;
  //     case "signed":
  //       obj.signedBookFile.fileData = data;
  //       obj.signedBookFile.uniqueName = uniquiFileName(data?.name);
  //       break;
  //     // case "other":
  //     //   obj.otherDocsFile.fileData = data;
  //     //   obj.otherDocsFile.uniqueName = uniquiFileName(data?.name);
  //     //   break;
  //     default:
  //       break;
  //   }
  //   setDocFileOriObject(obj);
  //   setFinalRequiredDocuments(obj);
  // };

  const documentHandler = (data) => {
    switch (data.action) {
      case "reject":
        RejectDocument(data);
        break;
      case "accept":
        ApproveDocument(data);
        break;
      default:
        break;
    }
  };



  return (
    <>
      {requiredDocArray.map((val, index) => {
        return (val.upKey == "signed" && !val.docData) ||
          val.upKey == "other -> s" ? null : (
          <React.Fragment key={index}>
            <Col span={24}>
              <RequireDocument
                title={val?.docLabel ?? val.docName}
                setValue={setValue}
                control={control}
                docsData={val.docData}
                document={val}
                isRenewal={isRenewal}
                tenantAccepted={tenantAccepted}
                documentHandler={documentHandler}
                upKey={val.upKey}
              // uploadFiles={uploadFiles}
              />
            </Col>
          </React.Fragment>
        );
      })}
      {/* TASKSSS 152 */}
      {/* get the values from ejari documents ,
       create status conditions,
       add view modal, 
       open change modal, 
       also fix deep-link
       addpdf download link
       upload pdf api and input
       form input radio/check condition
       submit form api
       update values api
       */}
      {generatedDocs?.map((item, index) => {
        return (item?.addToLease === true && item?.documentId) && (
          <React.Fragment key={index}>
            <Col span={24}>
              <GeneratedDoc
                LEASEID={LEASEID}
                title={item.label}
                setValue={setValue}
                onUpdate={onUpdate}
                control={control}
                sendForEsignature={sendForEsignature}
                contractSent={contractSent}
                sendSignatureBtn={sendSignatureBtn}
                data={item}
                documentId={item.documentId}
                onRemoveDocument={() => onRemoveDocument(item?.documentName)}
              />
            </Col>
          </React.Fragment>
        );
      })}
      <Col span={24} className="other-docs">
        {requiredBookingDocumentsList?.length > 0 && (
          <>
            <OtherDocs
              setValue={setValue}
              resetField={resetField}
              control={control}
              isAdd={isAdd}
              data={leaseData}
              requiredDocArray={requiredDocArray}
              disableClone={disableClone}
              requiredBookingDocumentsList={requiredBookingDocumentsList}
              page={"lease"}
            />
          </>
        )}
      </Col>
    </>
  );
};
