import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Space,
  Typography,
  message,
  Divider,
  Spin,
} from "antd";
import { useForm, useWatch } from "react-hook-form";
import { Popup } from "../../../../../atoms/Popup";
import {
  InputField,
  ReactSelectField,
  DateField,
} from "../../../../../atoms/FormElement";
import { maintenanceService } from "../../../../../../configs/constants";
import moment from "moment";
import dayjs from "dayjs";
import axios from "../../../../../../utils/axiosInceptor";
import {
  ExclamationCircleOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import SendInviteToTenant from "../SendInviteToTenant";
import {
  getMaintananceCategories,
  invite,
  getNewMaintananceCategories,
} from "../../ducks/actions";
import { useSelector, useDispatch } from "react-redux";
import { daysArr, watcherMapper, weekDays } from "./utils";

var _ = require("lodash");
const { Title, Text } = Typography;

const { Paragraph } = Typography;

const EditTechnician = (props) => {
  const dispatch: any = useDispatch();

  const { categoriesList, categoriesNewList } = useSelector(
    (state: any) => state.categories
  );

  const { residential, commercial, internal } = categoriesNewList;

  const [submited, setSubmited] = useState(true);
  const [formSubmited, setFormSubmited] = useState(false);
  const [inviteSent, setInviteSent] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [visible, setVisible] = useState(false);
  const [technicianDetails, setTechnicianDetails] = useState(null);
  const [timeSlotsArrayToShow, setTimeSlotsArrayToShow] = useState([]);
  const [shifts, setShifts] = useState(null);
  const [rosters, setRoasters] = useState(null);

  const typeList = [
    {
      _id: "631748a148d5ce556279800c",
      profileId: {},
      from: "10:00",
      to: "12:00",
      name: "Test-1",
      description: "",
    },
    {
      _id: "631748a148d5ce556279800c",
      profileId: {},
      from: "12:00",
      to: "14:00",
      name: "Test-2",
      description: "",
    },
    {
      _id: "631748a148d5ce556279800c",
      profileId: {},
      from: "15:00",
      to: "17:00",
      name: "Test-3",
      description: "",
    },
    {
      _id: "631748a148d5ce556279800c",
      profileId: {},
      from: "17:00",
      to: "19:00",
      name: "Test-4",
      description: "",
    },
  ];

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onSearch = (value: string) => {};

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    reset,
    register,
    setValue,
    setError,
    clearErrors,
  } = useForm();
  let mondayWatcher = useWatch({ control, name: "monday" });
  let tuesdayWatcher = useWatch({ control, name: "tuesday" });
  let wednesdayWatcher = useWatch({ control, name: "wednesday" });
  let thursdayWatcher = useWatch({ control, name: "thursday" });
  let fridayWatcher = useWatch({ control, name: "friday" });
  let saturdayWatcher = useWatch({ control, name: "saturday" });
  let sundayWatcher = useWatch({ control, name: "sunday" });
  const isNew =
    Object.keys(props?.data).length === 0 && props?.data.constructor === Object;
  const formValues = getValues();

  const setApiValuesToForm = (key, value) => {
    switch (key) {
      case "residentialCategories":
        {
          const selectedResidentialCategories = residential?.filter((item) => {
            return value?.some((category) => {
              return item.value === category.categoryId;
            });
          });

          setValue(key, selectedResidentialCategories, {
            shouldValidate: true,
          });
        }
        break;
      case "commercialCategories":
        {
          const selectedCommercialCategories = commercial?.filter((item) => {
            return value?.some((category) => {
              return item.value === category.categoryId;
            });
          });

          setValue(key, selectedCommercialCategories, { shouldValidate: true });
        }
        break;
      case "internalCategories":
        {
          const selectedInternalCategories = internal?.filter((item) => {
            return value?.some((category) => {
              return item.value === category.categoryId;
            });
          });

          setValue(key, selectedInternalCategories, { shouldValidate: true });
        }
        break;

      default:
        setValue(key, value);
        break;
    }
  };

  const onChangeOrganzation = (e) => {
    // setSpecialities(e);
  };

  const getFilteredSlots = (shifts) => {
    const filteredTypelist =
      shifts?.map((item, key) => {
        return {
          label: item.name,
          value: item.name,
          fromTo: item.shifts[0].from + " -" + item.shifts[0].to,
          id: item._id,
        };
      }) || [];

    setTimeSlotsArrayToShow([
      {
        label: "No Shift",
        value: "No Shift",
        fromTo: "No Shift",
      },
      ...filteredTypelist,
    ]);
  };

  useEffect(() => {
    if (props.data) {
      Object.entries(props.data).forEach(([key, value]) => {
        setApiValuesToForm(key, value);
      });
    }
  }, [props.data, props, residential, commercial, internal]);

  const getTechncianRosterShifts = async () => {
    const rosters = await axios({
      method: "get",
      url: `${maintenanceService}/maintenance/getRoaster?userId=${props.data.userId}`,
    });

    const shifts = await axios({
      method: "get", //get master
      url: `${maintenanceService}/maintenance/getShifts`,
    });

    setShifts(shifts?.data?.result);
    getFilteredSlots(shifts?.data?.result);
    setRoasters(rosters?.data?.result);

    rosters?.data?.result[0]?.schedules.forEach(function (element) {
      setValue(
        weekDays[element.day],
        {
          label: element.shifts.name,
          value: element.shifts.name,
        },
        { shouldValidate: true }
      );
    });
  };

  useEffect(() => {
    getTechncianRosterShifts();
  }, [props.data, props]);

  useEffect(() => {
    // dispatch(getMaintananceCategories());
    dispatch(getNewMaintananceCategories());
  }, []);

  const onBack = () => {
    reset();
    props.onCancel();
  };

  const sendInviteToTenant = async () => {};

  const onClickInvite = async () => {
    if (technicianDetails) {
      let successMessage = "";
      setLoader(true);
      let payload = {
        contacts: [technicianDetails.userId],
        usertype: "technician",
        inviteAll: false,
      };

      const status = await invite(payload, successMessage);
      setLoader(false);
      if (status) {
        message.success("Successfully sent the invite message to the tenant!");
        props.onUpdateTech();
      } else {
        setLoader(false);
        message.error("Error in sending Invite.");
      }
    }
  };

  const onFinish = async (values) => {
    // console.log("form ", getValues());

    let url, payload, method;

    const residentialCategories = values?.residentialCategories
      ? values?.residentialCategories?.map((cat) => cat.value)
      : [];
    const commercialCategories = values?.commercialCategories
      ? values?.commercialCategories?.map((cat) => cat.value)
      : [];
    const internalCategories = values?.internalCategories
      ? values?.internalCategories?.map((cat) => cat.value)
      : [];

    if (
      (values?.residentialCategories?.length === 0 &&
        values?.commercialCategories?.length === 0 &&
        values?.internalCategories?.length === 0) ||
      !(
        !!values?.internalCategories ||
        !!values?.commercialCategories ||
        !!values?.residentialCategories
      )
    ) {
      setError("residentialCategories", {
        type: "manual",
        message:
          "Please select atleast any one category from residential, commercial or Internal",
      });
      return;
    } else {
      clearErrors();
    }

    setLoader(true);

    if (isNew) {
      url = `${maintenanceService}/maintenance/technician/add`;
      method = `post`;
      payload = {
        email: values.email,
        mobile: values.number,
        name: values.first_name,
        employeeNumber: values.employeeNumber,
        dateOfJoining: values.dateOfJoining,
        residentialCategories: residentialCategories,
        commercialCategories: commercialCategories,
        internalCategories: internalCategories,
      };
    } else {
      url = `${maintenanceService}/technician/user/${values.userId}`;
      method = `patch`;
      payload = {
        email: values.email,
        mobile: values.number,
        name: values.first_name,
        residentialCategories: residentialCategories,
        commercialCategories: commercialCategories,
        internalCategories: internalCategories,
      };
    }

    try {
      const resTechnician = await axios({
        method: method,
        url: url,
        data: payload,
      });
      setTechnicianDetails(resTechnician);

      if (!isNew) {
        let mapSchedules = [],
          nonOptedDays = [];
        Object.entries(getValues()).forEach(([key, value]) => {
          if (weekDays.includes(key)) {
            if (
              value.id ||
              shifts.find((shift) => shift.name === value.value)?._id
            ) {
              console.log(
                value.id,
                shifts.find((shift) => shift.name === value.value)?._id
              );

              mapSchedules.push({
                shift:
                  value.id ||
                  shifts.find((shift) => shift.name === value.value)?._id,
                day: weekDays.indexOf(key),
              });
            } else {
              nonOptedDays.push(weekDays.indexOf(key));
            }
          }
        });

        let roasterPayload = {
          roasterId: rosters?.[0]._id,
          mapSchedules: mapSchedules,
        };
        let nonOptedRosterPayload = {
          roasterId: rosters?.[0]._id,
          days: nonOptedDays,
        };
        const updateOptedRoster = await axios({
          method: "patch",
          url: `${maintenanceService}/maintenance/updateRoaster`,
          data: roasterPayload,
        });

        const updateNonOptedRoster = await axios({
          method: "patch",
          url: `${maintenanceService}/maintenance/removeRoaster`,
          data: nonOptedRosterPayload,
        });

        getTechncianRosterShifts();
      }

      reset();
      setLoader(false);
      message.success("Technician updated successfully");
      setSubmited(false);
      // setVisible(true)
      if (isNew) {
        setInviteSent(true);
      } else {
        props.onUpdateTech();
      }
    } catch (e) {
      setSubmited(false);
      setInviteSent(false);
      setLoader(false);
      setisError(true);
      const { response } = e;

      setErrorMessage(response?.data?.error);
    }
  };

  const popup = {
    title: (
      <Title level={3} className="mb-0">
        Warning
      </Title>
    ),
    content: (
      <SendInviteToTenant
        onCancel={() => {
          setVisible(false);
          setFormSubmited(false);
          props.onUpdateTech();
        }}
        onConfirm={() => {
          setVisible(false);
          sendInviteToTenant();
        }}
      />
    ),
    width: 650,
  };

  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup.title,
    content: popup.content,
    width: popup.width,
    onCancel: () => setVisible(false),
  };

  function disabledDate(current) {
    let today = dayjs().format("YYYY-MM-DD");
    return current && dayjs(current).format("YYYY-MM-DD") < today;
  }

  const getRosterTimings = (item) => {
    if(rosters) {
      const rosterObj = rosters && rosters[0]?.schedules?.find(
        (roster) =>
          roster?.day ===
          weekDays.indexOf(item?.dayName)
      )?.shifts?.shifts

      if (rosterObj && rosterObj.length === 2) {
        return `${rosterObj[0]?.from} - ${rosterObj[1]?.to}`
      } else {
        return rosterObj && `${rosterObj[0]?.from} - ${rosterObj[0]?.to}` || 'No Shift'
      }
    }
  }

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {loader && !isError && !submited && !inviteSent && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
              {/* <Paragraph className="ag-default fontWeight600 mb-0">Loading</Paragraph> */}
            </Space>
          </Col>
        </Row>
      )}
      {submited ? (
        <>
          <Row gutter={24}>
            <Col span={24} style={{ paddingBottom: "1rem" }}>
              <Paragraph className="fontWeight600 mb-0">
                {"Technicians Details"}
              </Paragraph>
            </Col>
            <Col span={12}>
              <InputField
                label="Email"
                fieldname="email"
                control={control}
                initValue=""
                isRequired={true}
                rules={{
                  pattern: {
                    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    message: "Enter valid Email",
                  },
                  required: "Please enter Email",
                }}
                iProps={{ placeholder: "Enter Email Address" }}
                validate={errors.email && "error"}
                validMessage={errors.email && errors.email.message}
              />
            </Col>
            <Col span={12}>
              <InputField
                label="Name"
                fieldname="first_name"
                control={control}
                initValue=""
                iProps={{ placeholder: "Enter Email Name" }}
                isRequired={true}
                validate={errors.first_name && "error"}
                validMessage={errors.first_name && errors.first_name.message}
                rules={{
                  required: "Please enter valid Name",
                }}
              />
            </Col>
            {isNew && (
              <Col span={12}>
                <InputField
                  label="Employee Number"
                  fieldname="employeeNumber"
                  control={control}
                  initValue=""
                  iProps={{ placeholder: "Enter Employee Number" }}
                  validate={errors.employeeNumber && "error"}
                  validMessage={
                    errors.employeeNumber && errors.employeeNumber.message
                  }
                />
              </Col>
            )}
            {isNew && (
              <Col span={12}>
                <DateField
                  isRequired={true}
                  fieldname="dateOfJoining"
                  label="Joining Date"
                  control={control}
                  iProps={{
                    placeholder: "DD-MM-YYYY",
                    format: "DD-MM-YYYY",
                    // disabledDate: disabledDate,
                  }}
                  rules={{
                    required: "Please enter date",
                    setValueAs: (value) => moment(value).format("YYYY-MM-DD"),
                  }}
                  initValue={null}
                  validate={errors.dateOfJoining && "error"}
                  validMessage={
                    errors.dateOfJoining && errors.dateOfJoining.message
                  }
                />
              </Col>
            )}

            <Col span={12}>
              <InputField
                label="Contact Number"
                fieldname="number"
                control={control}
                initValue=""
                iProps={{
                  addonBefore: "+971",
                  placeholder: "Please enter valid contact number",
                }}
                isRequired={true}
                validate={errors.number && "error"}
                validMessage={errors.number && errors.number.message}
                // disabled={disableAll}
                rules={{
                  minLength: {
                    value: 9,
                    message:
                      "Please enter valid contact number, 9 digits required",
                  },
                  maxLength: {
                    value: 9,
                    message:
                      "Please enter valid contact number, 9 digits required",
                  },
                  required: "Please enter Mobile No.",
                }}
              />
            </Col>
            <Col span={12}>
              <ReactSelectField
                control={control}
                initialValue={[]}
                label="Residential"
                fieldname="residentialCategories"
                selectOption={residential}
                onChange={(e) => onChangeOrganzation(e)}
                validate={errors.residentialCategories && "error"}
                validMessage={
                  errors.residentialCategories &&
                  errors.residentialCategories.message
                }
                iProps={{
                  placeholder: "Select Residential Categories",
                  isMulti: true,
                }}
              />
            </Col>
            <Col span={12}>
              <ReactSelectField
                control={control}
                initialValue={[]}
                label="Commercial"
                fieldname="commercialCategories"
                selectOption={commercial}
                onChange={(e) => onChangeOrganzation(e)}
                validate={errors.commercialCategories && "error"}
                validMessage={
                  errors.commercialCategories &&
                  errors.commercialCategories.message
                }
                iProps={{
                  placeholder: "Select Commercial Categories",
                  isMulti: true,
                }}
              />
            </Col>
            <Col span={12}>
              <ReactSelectField
                control={control}
                initialValue={[]}
                label="Internal"
                fieldname="internalCategories"
                selectOption={internal}
                onChange={(e) => onChangeOrganzation(e)}
                validate={errors.internalCategories && "error"}
                validMessage={
                  errors.internalCategories && errors.internalCategories.message
                }
                iProps={{
                  placeholder: "Select Internal Categories",
                  isMulti: true,
                }}
              />
            </Col>
            {!isNew && (
              <>
                <Paragraph className="fontWeight600 mb-0">
                  {"Shift Details"}
                </Paragraph>
                <Row className="day-component flex pb-4">
                  {daysArr.map((item, id) => {
                    return (
                      <Col
                        span={6}
                        key={id}
                        className={`ph-2 pv-1 select-form-component`}
                      >
                        <div
                          className={`border-radius-12 ${
                            timeSlotsArrayToShow[0]?.label ===
                              formValues?.[item.dayName]?.label ||
                            !formValues?.[item.dayName]?.label
                              ? "bg-red"
                              : "bg-green"
                          } text-center pv-3 ph-3`}
                        >
                          <Paragraph className="m-0 pb-1">
                            {item.label}
                          </Paragraph>
                          <ReactSelectField
                            //  {...register(item.dayName, { required: true})}
                            style={{ borderRadius: 8 }}
                            control={control}
                            // isRequired
                            initialValue={[]}
                            label={
                              formValues?.[item.dayName]?.fromTo ||
                              getRosterTimings(item)
                            }
                            fieldname={item.dayName}
                            selectOption={timeSlotsArrayToShow}
                            // validate={formValues[item.dayName] && "Please select a value"}
                            validMessage={
                              errors[item.dayName] && "Please select a value"
                            }
                            iProps={{
                              placeholder: "Select Slot",
                              isMulti: false,
                              required: "Please select the value",
                            }}
                          />
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </>
            )}
          </Row>

          <Row className="pv-5 mv-5" gutter={24} justify="space-between">
            <Col>
              <Button className="ag-gray-button-outline" onClick={onBack}>
                Back
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button" htmlType="submit">
                {isNew ? "Save" : "Update"}
              </Button>
            </Col>
          </Row>
        </>
      ) : inviteSent ? (
        <>
          <Row gutter={24} justify="center">
            <Col>
              <Divider />
            </Col>
            <Col xl={24} style={{ textAlign: "center" }}>
              <Title
                style={{ textAlign: "center" }}
                level={2}
              >{`Would you like to send an invite to the technician ?`}</Title>
            </Col>
            <Col>
              <Divider />
            </Col>
          </Row>

          <Row gutter={24} justify="space-between">
            <Col>
              <Button
                className="ag-error theme_grey_btn"
                htmlType="button"
                onClick={() => {
                  props.onCancel();
                }}
              >
                {<CloseOutlined />} No
              </Button>
            </Col>
            <Col>
              <Button
                className="ag-green-button theme_btn"
                onClick={() => onClickInvite()}
              >
                {<CheckOutlined />} Yes
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    setSubmited(true);
                    setisError(false);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
      <Popup destroyOnClose={true} {...popupProps} />
    </Form>
  );
};

export default EditTechnician;
