import React, { useState, useEffect, Fragment } from "react";
import { Popup, PopupConfirm } from "../../../../atoms/Popup";
import {
  Card,
  Row,
  Col,
  Typography,
  Button,
  Image,
  Upload,
  message,
  Space,
  Form,
  Input,
} from "antd";
import {
  FileTextOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import PdfViewer from "../../../../molecules/PDFViewer/pdfView";

import { useFieldArray, Controller, useForm, useWatch } from "react-hook-form";
import { InputField, ReactSelectField } from "../../../../atoms/FormElement";
import { getPresignedImage } from "../../../../../utils/media";
import { DeleteIcon } from "../../../../../assets/svg";
import { useSelector } from "react-redux";
import { updateBooking } from "../../ducks/actions";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";

var _ = require("lodash");

export default (props) => {
  const [visible, setVisible] = useState(false);
  const {
    
    isAdd,
   
  } = props;

  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const { Title, Text } = Typography;

  const [viewImage, setViewImage] = useState(null);
  const [isAddMore, setIsAddMore] = useState(true);

  const [isOtherDoc, setIsOtherDoc] = useState(null);

  const { configData, bookingDetails } = useSelector(
    (state: any) => state.newBooking
  );

  const dispatch: any = useDispatch();
  const { leaseId = "" } = useParams();

  useEffect(() => {
    setIsAddMore(isAdd);
  }, [isAdd]);

  const onClosePopUp = () => {
    setViewImage(null);
    setVisible(false);
  };

  const initDocBar = {
    id: "",
    file: "",
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "additionalDocs",
  });

  const additionalDocs = useWatch({ control, name: "additionalDocs" });

  // useEffect(()=>{

  //   if(requiredDocArray?.length>0)

  // },[requiredDocArray]);

  // const onOpenPopUpHandler = async (key, i) => {
  //   if (key?.includes(".pdf")) {
  //     setPdfFile(key);
  //     setShowPdf(!showPdf);
  //   } else {
  //     setViewImage(key);
  //     setVisible(true);
  //   }
  // };

  // // const getUrl = async (d) => {
  // //   let dlink = [];
  // //   let filing = [];
  // //   await Promise.all(
  // //     d.map(async (e) => {
  // //       let naming = page == "booking" ? e.object_key : e.other_document_key;
  // //       const url = await getPresignedImage(naming);
  // //       dlink.push(url);
  // //       if (page == "booking") {
  // //         filing.push({
  // //           doc_order: e.doc_order,
  // //           id: e.tenant_object_id,
  // //           file: {
  // //             file: {
  // //               uid: e.tenant_object_id,
  // //               name: e.object_key,
  // //               status: "done",
  // //               url: url,
  // //             },
  // //           },
  // //         });
  // //       } else {
  // //         filing.push({
  // //           doc_order: e.doc_order,
  // //           id: e.tenant_object_id,
  // //           file: {
  // //             file: {
  // //               uid: e.tenant_object_id,
  // //               name: e.other_document_key,
  // //               status: "done",
  // //               url: url,
  // //             },
  // //           },
  // //         });
  // //       }
  // //     }),
  // //   );
  // //   filing.sort((a, b) => a.doc_order - b.doc_order);
  // //   setValue("OtherDocs", filing);
  // //   setViewOrDownloadUrl(filing);
  // // };

  // // // const popupContent = {
  // // //   closable: true,
  // // //   visibility: visible,
  // // //   content: (
  // // //     <Image
  // // //       preview={false}
  // // //       width="100%"
  // // //       style={{ padding: "20px" }}
  // // //       src={viewImage}
  // // //     />
  // // //   ),
  // // //   width: 650,
  // // //   onCancel: onClosePopUp,
  // // // };

  const onChange = (e, index) => {
    // if (e.value == "rental_commission") {
    //   setValue(`othercharges[${index}].intallments`, { label: "1", value: 1 });
    //   setValue(
    //     `othercharges[${index}].amount`,
    //     getValues("annualBaseRent") * configData?.rental_commission_percentage,
    //   );
    //   setRCDisable(true);
    // } else {
    //   setRCDisable(false);
    // }
    // e.value == "PARKING" ? setEnable(true) : setEnable(false);
    // setTypeM(e.value);
    // console.log("BOOKING>>>", e);
    if (e?.value === "Other") setIsOtherDoc(index);
    else if (e?.value != "Other" && index === isOtherDoc) {
      setIsOtherDoc(null);
    }
  };

  const filteredOptions = configData["requiredBookingDocumentsList"]?.filter(
    (item) => {
      return (
        !bookingDetails?.requiredDocuments
          ?.map((i) => i.type)
          ?.includes(item?.value) &&
        !additionalDocs?.map((i) => i?.type?.value)?.includes(item?.value)
      );
    }
  );

  // const handleExistingDocDelete = (id) => {
  //   // const filteredArray = existingDocuments.filter(
  //   //   (e) => e.lead_object_id !== id,
  //   // );
  //   // setExistingDocuments(filteredArray);
  //   const delArray = [...deletedAdditionalDocs];
  //   delArray.push(id);
  //   setDeletedAdditionalDocs(delArray);
  // };

  // const onUpChange = (e, i, s) => {
  //   // let v = [...viewOrDownloadUrl];
  //   // v[i] = e.file.originFileObj.name;
  //   // console.log("checking", v);
  //   // setViewOrDownloadUrl(v);
  // };

  const onSubmit = async (data: any, type) => {
    // updateState({ visible: true });
    message.loading("");
    let dataCopy = { ...(data || getValues()) };
    const existingDocuments =
      bookingDetails?.requiredDocuments?.length > 0
        ? bookingDetails?.requiredDocuments
        : [];
    const documentsArray = [];
    dataCopy?.additionalDocs?.length > 0 &&
      dataCopy.additionalDocs.map((item) => {
        if (item?.type?.value) {
          let doc = { type: item?.type?.value, status: "pending" };
          if (item?.documentLabel) doc["documentLabel"] = item?.documentLabel;
          documentsArray.push(doc);
        }
      });

    dataCopy = { requiredDocuments: [...existingDocuments, ...documentsArray] };

    props.parentState({
      modalPayload: { iKey: "", title: "", extraText: "", open: false },
    });

    if (documentsArray?.length > 0) {
      try {
        const response = await dispatch(
          updateBooking({ leaseId, body: dataCopy })
        );
        message.success(response.data.message);
        setTimeout(() => {
          message.destroy();
        }, 3000);
      } catch (error) {
        props.parentState({ visible: false });
        message.destroy();
        message.error(
          error.response?.data?.error || error.response?.data?.message
        );
      }
    } else message.error("No documents added");
  };

  return (
    <Form
      layout="vertical"
      name="bookingform"
      scrollToFirstError
      className="bf-main"
      onFinish={handleSubmit(onSubmit)}
    >
      {fields.map((item, index) => {
        return (
          <Row
            gutter={24}
            key={item.id}
            className={`h-auto add-docs ${index === (fields?.length-1) && 'last-add-docs'}`}
            style={{ marginBottom: "0.6rem", height: "50px" }}
            justify="space-between"
            align="middle"
          >
            <Col span={1}>
              <FileTextOutlined className="require-doc" />
            </Col>
            <Col span={12}>
              <ReactSelectField
                // style={{ height: '12px' }}
                isRequired={true}
                label=""
                // classes="mb-0 sm-input"
                fieldname={`additionalDocs[${index}].type`}
                control={control}
                iProps={{ placeholder: "Select document" }}
                initValue={[]}
                onChange={(e) => onChange(e, index)}
                selectOption={filteredOptions}
                rules={{ required: "Please select document type" }}
                validate={
                  Object.entries(errors).length > 0 &&
                  // errors?.additionalDocs?.length > 0 &&
                  errors?.additionalDocs[index]?.type &&
                  "error"
                }
                validMessage={
                  Object.entries(errors).length > 0 &&
                  // errors?.additionalDocs?.length > 0 &&
                  errors?.additionalDocs[index]?.type &&
                  errors?.additionalDocs[index]?.type?.message
                }
              />
            </Col>
            <Col
              span={10}
              style={{ display: "flex", justifyContent: "end", padding: 0 }}
            >
              {isOtherDoc != null && isOtherDoc == index && (
                <Form.Item className="mb-0">
                  <InputField
                    isRequired={true}
                    classes="mb-0 sm-input"
                    fieldname={`additionalDocs[${index}].documentLabel`}
                    iProps={{ placeholder: "Document Name" }}
                    control={control}
                    rules={{ required: "Please enter document name" }}
                    validate={
                      Object.entries(errors).length > 0 &&
                      // errors?.additionalDocs?.length > 0 &&
                      errors?.additionalDocs[index]?.documentLabel &&
                      "error"
                    }
                    validMessage={
                      Object.entries(errors).length > 0 &&
                      // errors?.additionalDocs?.length > 0 &&
                      errors?.additionalDocs[index]?.documentLabel &&
                      errors?.additionalDocs[index]?.documentLabel?.message
                    }
                  />
                </Form.Item>
              )}
              <Button
                type="link"
                icon={<DeleteIcon />}
                onClick={() => {
                  // setDisabledDocs(disabledDocs.filter(i=>i!=getValues(`additionalDocs[${index}]`)?.value))
                  remove(index);
                }}
              />
            </Col>

            {/* {index != fields.length - 1 && (
              <Col span={24} className="my-0 ft-divider" />
            )} */}
          </Row>
        );
      })}
      {filteredOptions?.length > 0 && (
        <Row gutter={[24, 24]} justify="end">
          <Col span={24}>
            <Button
              type="dashed"
              // className="p-0"
              htmlType="button"
              icon={<PlusOutlined />}
              block={true}
              onClick={() => {
                append(initDocBar);
              }}
            >
              <Text
                className="ag-primary fontWeight600"
                style={{ fontSize: "14px" }}
              >
                Add Document
              </Text>
            </Button>
          </Col>
        </Row>
      )}

      {filteredOptions?.length > 0 || fields.length > 0 ? (
        <Row gutter={[24, 24]} justify="end" className="mt-1">
          <Col>
            <Space>
              <Button
                // type="submit"
                // label="Update"
                className="ef-discard-btn"
                onClick={() =>
                  props.parentState({ modalPayload: { open: false } })
                }
              >
                Cancel
              </Button>
              <Button
                // label="Cancel"
                type="primary"
                className="ef-approve-btn"
                htmlType="submit"
                // onClick={() =>
                //   props.parentState({ modalPayload: { open: false } })
                // }
              >
                Request
              </Button>
            </Space>
          </Col>
        </Row>
      ) : (
        <Text className="m-0 ag-primary defaultFont">
          There are no documents to be requested.
        </Text>
      )}

      {/* <Popup {...popupContent} /> */}
    </Form>
  );
};
