import React from "react";
import { Typography, Button, Empty } from "antd";
import EmptyImg from "../../../assets/img/norow.png";

const { Text } = Typography;

const EmptyPlaceholder = (props) => {
  const { label, action } = props;

  return (
    <Empty
      className={`p-1 ${props.class && props.class}`}
      image={EmptyImg}
      imageStyle={{ width: 131, height: 142, margin: "0 auto 1rem" }}
      description={<Text>No {label} to show!</Text>}
    >
      {action && (
        <Button type="primary" onClick={action}>
          Start adding {label}
        </Button>
      )}
    </Empty>
  );
};

export default EmptyPlaceholder;
