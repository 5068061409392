import * as action_types from "./constants";

var _ = require("lodash");

const initialState = {
  mapData: [],
  stats: {
    buildingsCount: 0,
    propertyCount: 0,
    applicationCount: 0,
    maintenanceCount: 0,
  },
  propertiesCard: {},
  propertiesDrop: [],
  propertiesListO: {},
  propertiesListV: {},
  scheduledVists: {},
  updateScheduledVisitRequest: {},
  propertiesChart: null,
  downloadFile: [],
  propertyVisits: {},
  configData: [],
  chequeList: {},
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.GET_PROPERTIES_LATLONG:
      return { ...state, mapData: data };
    case action_types.GET_DASHBOARD_STATS:
      return { ...state, stats: data };
    case action_types.GET_PROPERTIES_LIST_O:
      return { ...state, propertiesListO: data };
    case action_types.GET_PROPERTIES_LIST_V:
      return { ...state, propertiesListV: data };
    case action_types.GET_PROPERTIES_CARD:
      return { ...state, propertiesCard: data };
    case action_types.GET_PROPERTIES_DROP:
      return {
        ...state, propertiesData: data, propertiesDrop: _.map(data, (item) => ({
          value: item.propertyId,
          label: item.buildingName, //+ " : " + item.buildingCode
          buName: item.businessUnitName
        }))
      };
    case action_types.GET_SCHEDULED_VISITS:
      return { ...state, scheduledVists: data };
    case action_types.GET_CONFIG_DATA:
      return { ...state, configData: data.reduce((acc,curr)=> ({...acc,...curr}),{}) };
    case action_types.UPDATE_SCHEDULED_VISIT_REQUEST:
      return { ...state, updateScheduledVisitRequest: data };
    case action_types.GET_PROPERTIES_COUNT:
      return { ...state, propertiesChart: data };
    case action_types.GET_PROPERTY_VISITS:
      return { ...state, propertyVisits: data };
    case action_types.DOWNLOAD_DATA:
      return { ...state, downloadFile: data };
    case action_types.EMPTY_SCHEDULE:
      return { ...state, scheduledVists: {} };
    case action_types.CHEQUE_LIST:
      return { ...state, chequeList: data };

    default:
      return state;
  }
};
