import React, { useState, useEffect } from "react";
import DashboardLayout from "../../molecules/DashboardLayout";
import {
  Row,
  Col,
  Pagination,
  Card,
  Button,
  Form,
  Space,
  Select,
  Input,
  Tag,
} from "antd";
import SectionHeader from "../../molecules/SectionHeader";
import Grid from "../../atoms/Grid";
import { useDispatch, useSelector } from "react-redux";
import { emptyManagers, getManagers, getMasterManagers } from "./ducks/actions";
import { RightArrow } from "../../atoms/Icons";
import { useNavigate } from "react-router-dom";
import { EditOutlined, SearchOutlined } from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import { Popup } from "../../atoms/Popup";
import AddManager from "./AddManager";

const gridProps = {
  sortable: true,
  filter: true,
  resizable: true,
  minWidth: 200,
};

const limit = 20;

const searchBy = [
  { label: "Manager Name", value: "name" }
];

export default (props) => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const id = location.pathname?.split("/")[2];
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const managerApi = useSelector((state: any) => state.managers.managersList);
  const [search, setSearch] = useState("");
  const [searchByT, setSearchBy] = useState(searchBy[0].value);

  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [selected, setSelected] = useState();
  const [updates, setUpates] = useState(false);

  

  function viewRender(record) {

    return (
      <Button
        icon={<EditOutlined />}
        onClick={() => onEdit(record.data)} 
        type="link"
        className="ag-primary"
      />
    );
  }

  const getManagerStatus = (status) => {
    switch (status) {
      case 'enable':
        return 'Active'
        break;
      case 'disable':
        return 'In Active'
        break;
    
      default:
        break;
    }
  }

  const managerCol = [
  
    {
      headerName: "User Name",
      field: "name",
    },
    {
      headerName: "Mobile Number",
      field: "mobile",
    },
    {
      headerName: "Email",
      field: "email",
      // width: 400
    },
    {
      headerName: "Buildings",
      field: "properties",
      width: 500,
      cellRenderer: (record) => {
        return (
          <>
            {
              record?.data?.properties?.map((tag) => {
                return <Tag className="tag-sm">
                    {tag.propertyName}
                  </Tag>
              })
            }
          </>
        );
      },
    },
    {
      headerName: "Status",
      field: "status",
      cellRenderer: (record) => record?.data?.status ? getManagerStatus(record?.data?.status) : '--',
    },
    {
      headerName: "View Details",
      cellRenderer: viewRender,
      cellStyle: { textAlign: "center" },
      filter: false,
      maxWidth: 140,
    },
  ];

  const addNew = () => {
    setVisible(true);
    setTrigger(0);
  };

  const onEdit = (record) => {
    
    setVisible(true);
    setTrigger(1);
    setSelected(record);
  };

  const onAdd = () => {
    setVisible(false);
    dispatch(getManagers(id, limit, currentPage, "", ""));
    dispatch(getMasterManagers())
  };

  const popup = [
    {
      title: (
        <Title level={3} className="m-0">
          Add New User
        </Title>
      ),
      content: (
        <>
          <AddManager
            onSubmit={onAdd}
            onCancel={() => setVisible(false)}
            onUpdate={setUpates}
            onchangeUpdate={updates}
            editMode={false}
          />
        </>
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="m-0">
          Edit User
        </Title>
      ),
      content: (
        <>
          <AddManager
            data={selected}
            onSubmit={onAdd}
            onCancel={() => setVisible(false)}
            onUpdate={setUpates}
            onchangeUpdate={updates}
            editMode={true}
          />
        </>
      ),
      width: 950,
    },
  ];

  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => setVisible(false),
  };

  //@ts-ignore
  useEffect(() => {
    dispatch(getManagers(id, limit, currentPage, "", ""));
    return () => dispatch(emptyManagers());
  }, []);

  const handlePagination = (pg) => {
    setCurrentPage(pg);
    dispatch(getManagers(id, limit, pg, "", ""));
  };

  const onSearch = (val) => {
    setCurrentPage(1);
    if (val.searchText) {
      setSearch(val.searchText);
      setSearchBy(val.searchBy);
      // dispatch(getManagers(id, limit, 1, val.searchText, val.searchBy));
    } else {
      setSearch("");
      // dispatch(getManagers(id, limit, 1, "", ""));
    }
  };

  const SearchFelds = () => {
    return (
      <Form onFinish={onSearch}>
        <Space wrap>
          <Form.Item name="searchText" className="mb-0" initialValue={search}>
            <Input type="text" placeholder="Search" value={search} />
          </Form.Item>
          {/* <Form.Item name="searchBy" className="mb-0" initialValue={searchByT}>
            <Select placeholder="Select" value={searchByT}>
              {searchBy.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item> */}
          <Form.Item className="mb-0 noborder-btn">
            <Button
              htmlType="submit"
              className="ag-gray-button"
              icon={<SearchOutlined />}
            />
          </Form.Item>
        </Space>
      </Form>
    );
  };

  useEffect(() => {
    if (managerApi?.data) {
      let temp = [];
      managerApi?.data?.map((item) => {
        temp.push({
          name: item.name,
          mobile: item.mobile,
          usertype: item.usertype,
          properties: item.properties,
          userId: item.userId,
          status: item.status,
          visiblity: item.visiblity,
          managerId: item.managerId,
          email: item.email,
          screens:item?.accessibleScreens
        });
      });
      setData(temp);
    }
 
  }, [managerApi]);

  return (
    <>
      <DashboardLayout>
        <Row gutter={[32, 32]}>
          <Col span={24}>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <SectionHeader
                  heading="Users"
                  noBorder={true}
                  total={managerApi?.count}
                  addNewAction={addNew}
                />
              </Col>

              <Col span={24} className="managers_tenants_detail">
                <Card
                  bordered={false}
                  className="ag-nospace-body"
                  // extra={<SearchFelds />}
                >
                  <Grid
                    data={data}
                    columns={managerCol}
                    pagination={false}
                    defaultSettings={gridProps}
                    noRowlabel="Managers"
                    fullPage={true}
                  />
                </Card>
              </Col>
              <Col span={24}>
                <Pagination
                  className="ag-property-text-pagination"
                  total={managerApi?.totalCount || 0}
                  showTotal={(total, range) =>
                    `Showing ${range[0]}-${range[1]} of ${total} Users`
                  }
                  pageSize={managerApi?.perPage || 20}
                  // defaultPageSize={managerApi?.perPage || 20}
                  defaultCurrent={1}
                  showSizeChanger={false}
                  current={currentPage}
                  onChange={(e) => handlePagination(e)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </DashboardLayout>
      <Popup {...popupProps} />
    </>
  );
};
