import React from "react";

const index = (props: any) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      className="cc-foter_back-icon"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.26172 9H14.7617"
        stroke="#4B4B4B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.26172 9L8.76172 13.5"
        stroke="#2F2F37"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.26172 9L8.76172 4.5"
        stroke="#2F2F37"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default index;
