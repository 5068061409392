import React, { useState, useEffect } from "react";
import { Card } from "antd";
import { WrappedMap } from "../../../../../utils/WrappedMap";
import { EQARI_GOOGLE_KEY } from "../../../../../configs/constants";
import fire from "../../../../../utils/firebase";
import { useSelector, useDispatch } from "react-redux";
import { getTechnicains } from "../../iRoster/components/TechnicianSchedule/ducks/actions";

export default (props) => {
  // let mapRef = fire.database().ref().limitToLast(100);
  const dispatch = useDispatch();
  const [marker, setMarker] = useState([]);
  const [addMarker, setAddMarker] = useState(null);
  const [remMarker, setRemMarker] = useState(null);
  const [infoText, setInfoText] = useState(null);
  const [tempState, setTempState] = useState(null);
  const technician = //useSelector((state) => state.rosterReducer.getTechnicians);
    useEffect(() => {
      // dispatch(getTechnicains());
      return () => {
        // mapRef.off();
      };
    }, []);

  const liveTracking = () => {
    // mapRef.on("child_added", (snapshot) => {
    //   setAddMarker(snapshot);
    // });
    // mapRef.on("child_changed", (snapshot) => {
    //   setTempState(snapshot);
    // });
    // mapRef.on("child_removed", (snapshot) => {
    //   setRemMarker(snapshot);
    // });
  };

  useEffect(() => {
    if (tempState) {
      updatePin(tempState);
    }
  }, [tempState]);

  useEffect(() => {
    // console.log("check pin", addMarker);
    if (addMarker) {
      addFirePin(addMarker);
    }
  }, [addMarker]);

  useEffect(() => {
    if (remMarker) {
      removeFirePin(remMarker);
    }
  }, [remMarker]);

  const addFirePin = (snapshot) => {
    let obj = { text: snapshot.val(), id: snapshot.key };
    // if (technician.find((item) => obj.id === `user-${item.Party.id}`)) {
    //   if (!marker.find((item) => item.id === obj.id)) {
    //     var temp1 = marker;
    //     temp1.push(obj);
    //     setMarker(Object.assign([], temp1));
    //   }
    // }
  };

  const removeFirePin = (snapshot) => {
    let obj = { text: snapshot.val(), id: snapshot.key };
    // if (technician.find((item) => obj.id === `user-${item.Party.id}`)) {
    //   var index = marker.findIndex((item) => item.id === obj.id);
    //   if (index != -1) {
    //     var temp1 = marker;
    //     temp1.splice(index, 1);
    //     setMarker(Object.assign([], temp1));
    //   }
    // }
  };

  const updatePin = (snapshot) => {
    let obj = { text: snapshot.val(), id: snapshot.key };
    var tempM = marker;
    var d = tempM.findIndex((x) => x.id === obj.id);
    if (d != -1) {
      tempM[d].text = obj.text;
      setMarker(Object.assign([], tempM));
    }
  };

  useEffect(() => {
    // technician.length > 0 && liveTracking();
  }, [technician]);

  const selectMark = (props) => {
    setInfoText(null);
  };

  // console.log("map render");

  return (
    <Card bordered={false} title="Live Tracking" className="noicon-space">
      <WrappedMap
        isMarkerShown
        positions={marker}
        selected={selectMark}
        selectedPark={infoText}
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${EQARI_GOOGLE_KEY}`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `480px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        dashboard={true}
      />
    </Card>
  );
};
