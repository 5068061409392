import { Card, Col, Row, Typography } from "antd";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Grid from "../../../../../atoms/Grid";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getDependants } from "../../ducks/actions";

const { Text } = Typography;

const columns = [
  {
    headerName: "Co-Occupant Name",
    field: "name",
    width: 370
  },
  {
    headerName: "Phone Number",
    field: "mobile",
    cellRendererFramework: viewPhoneNumber,
    width: 370
  },
  {
    headerName: "Email",
    field: "email",
    width: 370
    // cellRendererFramework: viewEmail,
  },
  {
    headerName: "Relation",
    field: "relation",
    width: 370
  },
  // {
  //   headerName: "Account Status",
  //   field: "account_status",
  //   maxWidth: 250,
  //   cellRendererFramework: viewRender,
  // },
];

function viewPhoneNumber(param) {
  return param?.data?.countryCode + "-" + param?.data?.mobile; //?.telecommunication_number?.contact_number;
}

// function viewEmail(param) {
//   return param?.data?.contact_info?.electronic_address?.email;
// }

function viewRender() {
  return <Text className="ag-success f-16">Updated</Text>;
}
export default ({tenantData}) => {
  const [leaseIds, setLeaseIds] = useState([]);
  const [dependantsData, setDependantsData] = useState([]);
  const { id } = useParams();
  const dispatch:any = useDispatch();

  // const lease = useSelector((state: any) => state.tenantsSummary.leaseData);
  const dependants = useSelector(
    (state: any) => state.tenantsSummary.dependants,
  );
  let dependantsDataTemp = [];

  useEffect(() => {
    console.log("lease", tenantData);
    // setLeaseIds(
      // tenantData.map((e) => {
        dispatch(getDependants(tenantData?.userDetail?.userId, "")) //);
      // }),
    // );
  }, [tenantData]);

  useEffect(() => {
    if (dependants?.length > 0) {
      dependantsDataTemp = [...dependantsData];
      dependants?.map((item, index) => {
        dependantsDataTemp?.push(item);
      });
    }else
    dependantsDataTemp=[];

    setDependantsData(dependantsDataTemp);
  }, [dependants]);

  return (
    <Card bordered={false} bodyStyle={{ padding: 0 }}>
      <Row gutter={24}>
        <Col span={24}>
          <Grid
            data={dependantsData}
            columns={columns}
            pagination={false}
            noRowlabel="Co-Occupants"
            overlayNoRowsTemplate={"Co-occupants are added by tenants and will show here when added."}
          />
        </Col>
      </Row>
    </Card>
  );
};
