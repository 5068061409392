import axios from "../utils/axiosInceptor";

import { leaseService, propertyService } from "../configs/constants";
import { message } from "antd";

const firstUploadUrl = `${propertyService}/document/uploadFile`;
const contractUploadUrl = `${leaseService}/leads/upload-signed-url`;
const firstJson = {
  keys: [],
};
const headers = {
  Authorization:
    "Bearer" +
    " " +
    JSON.parse(window.localStorage.getItem("login-response"))?.token,
};

export const uniquiFileName = (name) => {
  const replaceAbleKey = `eqarcom_plus_${Date.now() + String(Math.random().toString().slice(2, 4))
    }`;
  const ext = name.split(".").pop();
  return `${replaceAbleKey}.${ext}`;
};

// export const getSingleUpload = async (fileName, file) => {
//   const postJson = {
//     keys: [fileName],
//   };
//   try {
//     await axios.post(firstUploadUrl, postJson, { headers }).then((response) => {
//       if (response?.data?.result?.signedUrl) {
//         const fileHeaders = {
//           "Content-type": file?.originFileObj?.type,
//         };
//         axios.put(response?.data?.result?.signedUrl[0], file?.originFileObj, {
//           headers: fileHeaders,
//         });
//         return true;
//       }
//     });
//   } catch (e) {
//     console.log("Err", e);
//     return false;
//   }
// };

const readeFile = async (file) => {
  return new Promise((resolve) => {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      resolve(reader.result);
    });
    reader.readAsArrayBuffer(file);
  });
};
export const getSingleUpload = async (fileName, file) => {
  let rvalue = "not";
  const postJson = {
    file_asset: fileName,
  };
  try {
    const dataLoad = await readeFile(file);
    await axios.post(firstUploadUrl, postJson, { headers }).then((response) => {
      if (response?.data?.result?.signedUrl) {
        const fileHeaders = {
          "Content-type": file?.type,
        };
        const payload: any = {
          method: "PUT",
          body: dataLoad,
        };
        /* axios.put(response?.data?.result?.signedUrl[0], file, {
          headers: fileHeaders,
        }); */
        // @ts-ignore
        if (
          response?.data?.result?.signedUrl[0] !=
          "https://s3.ap-south-1.amazonaws.com/"
        ) {
          fetch(response?.data?.result?.signedUrl[0], payload);
          return;
        } else {
          return getSingleUpload(fileName, file);
        }
      }
    });
  } catch (e) {

    return rvalue;
  }
};

export const getSingleTicketMediaUpload = async (fileName, file) => {
  let rvalue = false;
  const postJson = {
    file_asset: file,
  };
  try {
    const res = await axios.post(firstUploadUrl, postJson, { headers: { 'Content-Type': 'multipart/form-data' } });
    // if(response?.data === true){
    // console.log("RESPONSE",res);
    return res?.data;
    // }
  } catch (e) {
    console.log("Err", e);
    message.error(e?.response?.data?.message);
    return rvalue;
  }
};

export const getContractSingleUpload = async (fileName, file) => {
  const postJson = {
    keys: [fileName],
  };
  try {
    const dataLoad = await readeFile(file);
    await axios
      .post(contractUploadUrl, postJson, { headers })
      .then((response) => {
        if (response?.data?.result?.signedUrl) {
          const fileHeaders = {
            "Content-type": file?.type,
          };
          const payload: any = {
            method: "PUT",
            body: dataLoad,
          };
          /* axios.put(response?.data?.result?.signedUrl[0], file, {
          headers: fileHeaders,
        }); */
          // @ts-ignore
          fetch(response?.data?.result?.signedUrl[0], payload);
          return true;
        }
      });
  } catch (e) {

    return false;
  }
};

let multiFileCached = [];
export const getMultipleUpload = async (fileKey, setMultiImageKey) => {
  multiFileCached = fileKey.map((e) => uniquiFileName(e.name));
  fileKey.forEach((e) => firstJson.keys.push(uniquiFileName(e.name)));
  firstJson.keys = Array.from(new Set(firstJson.keys));
  setMultiImageKey(firstJson.keys);

  try {
    if (multiFileCached.length === firstJson.keys.length) {
      axios
        .post(firstUploadUrl, firstJson, { headers })
        .then(async (response) => {
          if (response?.data?.result?.signedUrl) {
            const urlWithFileArray = [];
            for (let i = 0; i < response?.data?.result?.signedUrl.length; i++) {
              const urlWithFileObj = {
                signedUrl: response?.data?.result?.signedUrl[i],
                file: fileKey[i]?.originFileObj,
                fileHead: {
                  "Content-type": fileKey[i]?.originFileObj?.type,
                },
              };
              urlWithFileArray.push(urlWithFileObj);
            }
            await Promise.all(
              urlWithFileArray.map(async (e) => {
                return await axios.put(e.signedUrl, e.file, {
                  headers: e.fileHead,
                });
              }),
            );
          }
        });
    }
  } catch (e) {

    return false;
  }
};
