import React, { useState, useEffect } from "react";
import { Button, Modal } from "antd";
import { Document, Page, pdfjs } from "react-pdf";
import { openFullscreen } from "../../../utils/media";

const PdfViewer = (props) => {
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(null);
  const [docData, setDocData] = useState(null);



  const { data } = props;


  useEffect(() => {
    // console.log("data231", data);
    setDocData(data);
  }, [data]);

  useEffect(() => {
    // .catch((error) => console.error("Error fetching PDF:", error));
    pdfjs.GlobalWorkerOptions.workerSrc =
      `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    // `//unpkg.com/@react-pdf-viewer/pdfjs-dist-signature@2.7.557/build/pdf.worker.js`;
  }, []);


  const onDocumentError = (err) => console.error("pdf viewer error:", err);
  const onDocumentComplete = ({ _pdfInfo }) => setPages(_pdfInfo?.numPages ?? 1);

  const openPrintWindow = (pdfData) => {
    const blob = new Blob([pdfData], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const printWindow = window.open(url, '_blank');
    if (printWindow) {
      printWindow.onload = () => {
        printWindow.print();
      };
    } else {
      console.error('Failed to open print window.');
    }
  };



  return (
    <Modal
      visible={props?.visible}
      onCancel={props?.onCancel}
      // cancelText="Close"
      okButtonProps={{ style: { display: "none" } }}
      closable={true}
      cancelButtonProps={{ style: { display: "none" } }}//{ position: "absolute", top: "44px", left: "44px"} }}
      style={{ top: 20, paddingTop: 30 }}
      width={props?.width ?? "50%"}
    // className="pdf-width"
    >
      <div style={{ width: "100%", textAlign: "right" }}>
        {/* //TODO: put array for methods */}

        {(props?.templateMethod) && (<>


          {(!docData?.addToLease &&
            <Button type="primary" className="btn-green" onClick={() => openFullscreen()}>Save to lease</Button>
          )}

          {/* // if addtolease true && sendToTenant false && signatureplace true */}
          <Button type="primary" className="btn-blue" onClick={() => props?.templateMethod()}>Send for eSignature</Button>
        </>)}
      </div>

      {props?.pdf && (<>
        <br />
        <br />
        <embed src={props?.pdf} title=""
          width="100%" height="800px" margin-top="100px" />
      </>
      )}

    </Modal>
  );
};
export default PdfViewer;
