import { Col, Row } from "antd";
import React from "react";
import BreadCrumb from "../../../atoms/BreadCrumb";
import DashboardLayout from "../../../molecules/DashboardLayout";
import PropertyCards from "../components/AllProperties/PropertyCards";

const breadcrumbList: any = [
  // {
  //   label: "Properties",
  //   route: "/properties",
  // },
  // {
  //   label: 'Al-Muteena',
  //   route: '/'
  // }
];

const PropertyList = (props) => {
  return (
    <DashboardLayout>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          {/* <BreadCrumb list={breadcrumbList} /> */}
        </Col>
        <Col span={24}>
          <PropertyCards />
        </Col>
      </Row>
    </DashboardLayout>
    
  );
};

export default PropertyList;
