import React, { useState, useEffect } from "react";

// others
import { Loading } from "./index";
import Button from "../../../../molecules/Button";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Form, Radio, Space, message, Calendar } from "antd";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  translateService,
  getTenantDetailByEmailService,
} from "../../ducks/services";
import {
  DateField,
  InputField,
  InputRadio,
  ReactSelectField,
} from "../../../../atoms/FormElement";
import {
  radioOptions,
  filterPayload,
  validateEmail,
  marital_status,
  signatoryPayload,
  filterDropdownValue,
  filterKycSelectList,
  KYC_CONTENT,
  kycInitValue,
  handleKycFields,
  datePlaceholderddmmyyFormat,
} from "../utils";
import { updateBooking, getTenantDetailByEmail, getBookingDetail } from "../../ducks/actions";
import { Info } from "../../../../../assets/svg";

interface IProps {
  parentState: (obj: object) => void;
  kycDetails: Array<object>;
}

let initialState = {
  visible: false,
  isSignatory: "",
};

let timeOutId: any = "";
let aTimeOutId: any = "";
var _ = require("lodash");

const index = (props: IProps) => {
  // hooks initialization
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch: any = useDispatch();
  const { leaseId = "" } = useParams();

  // redux states
  const { configData, bookingForm, bookingDetails } = useSelector(
    (state: any) => state.newBooking
  );

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const email = useWatch({ control, name: "email" });
  const signatoryEmail = useWatch({ control, name: "signatoryEmail" });
  const name = useWatch({ control, name: "name" });
  const signatoryName = useWatch({
    control,
    name: "signatoryName",
  });
  const isBooking = Object.keys(bookingDetails).length > 0;

  const usagesType = bookingDetails?.propertyDetails?.usagesType;

  // states
  const [{ visible, isSignatory }, setState] = useState(initialState);

  const updateState = (state: {}) =>
    setState((prevState: any) => ({ ...prevState, ...state }));

  useEffect(() => {
    if (isBooking) {
      updateState({ isSignatory: bookingDetails?.isSignatory });
      setValue("isSignatory", bookingDetails?.isSignatory);
    }
  }, [isBooking]);

  useEffect(() => {
    if (validateEmail(email) && !bookingForm?.email)
      dispatch(getTenantDetailByEmail(email));

    if (email !== signatoryEmail && validateEmail(signatoryEmail))
      getSignatoryTenant(signatoryEmail);
  }, [email, signatoryEmail]);

  useEffect(() => {
    if (name && name.length > 1) {
      clearTimeout(timeOutId);

      timeOutId = setTimeout(async () => {
        const response = await translateService(name);
        if (response?.translatedText)
          setValue("arabicName", response?.translatedText);
      }, 2000);
    }

    if (signatoryName && signatoryName.length > 1) {
      clearTimeout(aTimeOutId);

      aTimeOutId = setTimeout(async () => {
        const response = await translateService(signatoryName);
        if (response?.translatedText)
          setValue("signatoryArabicName", response?.translatedText);
      }, 2000);
    }
  }, [name, signatoryName]);

  const getSignatoryTenant = async (email: string) => {
    try {
      const response = await getTenantDetailByEmailService(email);
      if (response) {
        setValue("signatoryEmail", response?.email);
        setValue("signatoryName", response?.tenantNameEN);
        setValue("signatoryArabicName", response?.tenantNameAR);
        setValue("signatoryMobile", response?.mobileNumber);
        setValue("designation", response?.designationAsPerVisa);
        setValue("company", response?.company);
      }
    } catch (error: any) { }
  };

  const onSubmit = async (data: any) => {
    updateState({ visible: true });
    let dataCopy = { ...(data || getValues()) };

    let residentialFields =
      usagesType != "Commercial"
        ? {
          nationality: dataCopy.nationality.value,
          maritalStatus: dataCopy.maritalStatus.value,
        }
        : {};

    dataCopy = {
      ...dataCopy,
      ...residentialFields, //conditionally setting

    };

    if (props?.kycDetails?.length > 0) {
      dataCopy = handleKycFields(dataCopy);
      dataCopy = { ...dataCopy, submitKyc: "yes" };
    };

    if (dataCopy["isSignatory"] === "no")
      dataCopy["signatoryUser"] = signatoryPayload(dataCopy);

    try {
      const response = await dispatch(
        updateBooking({
          leaseId,
          body: filterPayload(dataCopy, usagesType?.toLowerCase()),
        }) //for commercial
      );

      setTimeout(() => {
        dispatch(getBookingDetail(leaseId));
      }, 400);

      message.success(response.data.message);
      updateState({ visible: false });
      props.parentState({
        modalPayload: { iKey: "", title: "", extraText: "", open: false },
      });
    } catch (error) {
      updateState({ visible: false });
      props.parentState({ visible: false });
      message.error(
        error.response?.data?.error || error.response?.data?.message
      );
    }
  };

  const signatoryRequired = isSignatory === "no";

  return (
    <Form
      layout="vertical"
      name="bookingform"
      scrollToFirstError
      className="bf-main"
      onFinish={handleSubmit(onSubmit)}
    >
      <Loading visible={visible} />

      <Col span={24}>
        <Row gutter={24}>
          <Col span={8}>
            <InputField
              control={control}
              fieldname="email"
              isRequired={true}
              disabled={true}
              setValue={setValue}
              label="Email Address"
              valueGot={bookingForm["email"]}
              validate={errors.email && "error"}
              iProps={{ placeholder: "john.smith@email.com" }}
              validMessage={errors.email && errors.email.message}
              rules={{
                required: "Email Address Required",
                pattern: {
                  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: "Enter valid Email Address",
                },
              }}
            />
          </Col>

          <Col span={8}>
            <InputField
              fieldname="name"
              control={control}
              isRequired={true}
              setValue={setValue}
              label="Tenant Name"
              valueGot={bookingForm["name"]}
              validate={errors.name && "error"}
              iProps={{ placeholder: "John Smith" }}
              validMessage={errors.name && errors.name.message}
              rules={{ required: "Please enter tenant name" }}
            />
          </Col>

          {usagesType?.toLocaleLowerCase() === "residential" && (
            <Col span={8}>
              <InputField
                isRequired={true}
                control={control}
                setValue={setValue}
                fieldname="arabicName"
                label="Tenant Name Arabic"
                iProps={{ placeholder: "جون سميث" }}
                valueGot={bookingForm["arabicName"]}
                validate={errors.arabicName && "error"}
                validMessage={errors.arabicName && errors.arabicName.message}
                rules={{ required: "Please enter tenant arabic name " }}
              />
            </Col>
          )}
        </Row>

        {/* next row */}
        <Row gutter={24}>
          <Col span={8}>
            <InputField
              isRequired={true}
              control={control}
              disabled={true}
              fieldname="mobile"
              setValue={setValue}
              label="Mobile number"
              valueGot={bookingForm["mobile"]}
              validate={errors.mobile && "error"}
              validMessage={errors.mobile && errors.mobile.message}
              iProps={{ addonBefore: "+971", placeholder: "554441212" }}
              rules={{
                minLength: {
                  value: 9,
                  message:
                    "Please enter valid mobile number, 9 digits required",
                },
                maxLength: {
                  value: 9,
                  message:
                    "Please enter valid mobile number, 9 digits required",
                },
                required: "Please enter Mobile No.",
              }}
            />
          </Col>

          {usagesType === "Commercial" ? (
            <>
              <Col span={8}>
                <InputField
                  fieldname="tenantDesignation"
                  control={control}
                  isRequired={true}
                  setValue={setValue}
                  label="Designation"
                  valueGot={bookingForm["designationAsPerVisa"] || bookingForm?.designation}
                  validate={errors.tenantDesignation && "error"}
                  iProps={{ placeholder: "Manager" }}
                  validMessage={
                    errors.tenantDesignation && errors.tenantDesignation.message
                  }
                  rules={{ required: "Please enter designation" }}
                />
              </Col>
              <Col span={8}>
                <InputField
                  fieldname="tenantCompany"
                  control={control}
                  isRequired={true}
                  setValue={setValue}
                  label="Company"
                  valueGot={bookingForm["company"]}
                  validate={errors.tenantCompany && "error"}
                  iProps={{ placeholder: "Xyz Real Estate" }}
                  validMessage={
                    errors.tenantCompany && errors.tenantCompany.message
                  }
                  rules={{ required: "Please enter company" }}
                />
              </Col>
            </>
          ) : (
            <>
              <Col span={8}>
                <ReactSelectField
                  isRequired={true}
                  control={control}
                  label="Nationality"
                  setValue={setValue}
                  fieldname="nationality"
                  iProps={{ placeholder: "Select" }}
                  validate={errors.nationality && "error"}
                  selectOption={configData["nationalityList"]}
                  rules={{ required: "Please select nationality" }}
                  validMessage={
                    errors.nationality && errors.nationality.message
                  }
                  valueGot={filterDropdownValue(
                    bookingForm["nationality"],
                    configData["nationalityList"]
                  )}
                />
              </Col>

              <Col span={8}>
                <ReactSelectField
                  control={control}
                  isRequired={true}
                  setValue={setValue}
                  label="Marital Status"
                  fieldname="maritalStatus"
                  selectOption={marital_status}
                  iProps={{ placeholder: "Select" }}
                  validate={errors.maritalStatus && "error"}
                  rules={{ required: "Please select marital status" }}
                  validMessage={
                    errors.maritalStatus && errors.maritalStatus.message
                  }
                  valueGot={filterDropdownValue(
                    bookingForm["maritalStatus"],
                    marital_status
                  )}
                />
              </Col>
            </>
          )}
        </Row>

        {/* next row */}
        <Row gutter={24}>
          {props?.kycDetails?.map((item: any, index: number) => {
            const tooltip = {
              title: KYC_CONTENT,
              icon: <Info width="16px" height="16px" />,
            };

            return (
              <>
                {["text", "number", "numberWithComma"].includes(item.type) && (
                  <Col span={8}>
                    <InputField
                      control={control}
                      label={item.label}
                      fieldname={`kyc-${item.name}`}
                      iProps={{ placeholder: "Placeholder" }}
                      initValue={kycInitValue(
                        item.name,
                        bookingDetails?.kycDetails
                      )}
                      {...{ tooltip }}
                    />
                  </Col>
                )}

                {["date"].includes(item.type) && (
                  <Col span={8}>
                    <DateField
                      control={control}
                      label={item.label}
                      fieldname={`kyc-${item.name}`}
                      iProps={{
                        format: datePlaceholderddmmyyFormat,
                        placeholder: datePlaceholderddmmyyFormat,
                      }}
                      initValue={kycInitValue(
                        item.name,
                        bookingDetails?.kycDetails
                      )}
                      {...{ tooltip }}
                    />
                  </Col>
                )}
                {["select"].includes(item.type) && (
                  <Col span={8}>
                    <ReactSelectField
                      control={control}
                      label={item.label}
                      fieldname={`kyc-${item.name}`}
                      iProps={{ placeholder: "Select" }}
                      selectOption={filterKycSelectList(item.options[0])}
                      initValue={filterDropdownValue(
                        kycInitValue(item.name, bookingDetails?.kycDetails),
                        filterKycSelectList(item.options[0])
                      )}
                      {...{ tooltip }}
                    />
                  </Col>
                )}
              </>
            );
          })}
        </Row>

        {/* next row */}
        <Row gutter={24}>
          <Col span={8}>
            <InputRadio
              isRequired={true}
              control={control}
              fieldname="isSignatory"
              validate={errors.isSignatory && "error"}
              label="Is the tenant the authorized signatory?"
              validMessage={errors.isSignatory && errors.isSignatory.message}
              rules={{
                required: "This field is required",
              }}
              disabled={true} // ADDED CUZ OF BUG: 8173
              onChange={(e: any) =>
                updateState({ isSignatory: e.target.value })
              }
              options={
                <Row gutter={[16, 16]}>
                  {radioOptions.map((option, i) => (
                    <Col span={6} key={`${option.value}-${i}`}>
                      <Radio value={option.value}>{option.label}</Radio>
                    </Col>
                  ))}
                </Row>
              }
            />
          </Col>
        </Row>

        {/* signatory */}
        {signatoryRequired && (
          <Col span={24}>
            <p className="td-label">Additional Information for Signatory</p>
            <div className="ft-divider" />

            <Row gutter={24}>
              <Col span={8}>
                <InputField
                  control={control}
                  setValue={setValue}
                  label="Email Address"
                  fieldname="signatoryEmail"
                  isRequired={signatoryRequired}
                  valueGot={bookingDetails?.signatoryUser?.email}
                  validate={errors.signatoryEmail && "error"}
                  iProps={{ placeholder: "john.smith@email.com" }}
                  rules={{
                    required: "Email Address Required",
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: "Enter valid Email Address",
                    },
                  }}
                  validMessage={
                    errors.signatoryEmail && errors.signatoryEmail.message
                  }
                />
              </Col>

              <Col span={8}>
                <InputField
                  control={control}
                  setValue={setValue}
                  label="Signatory Name"
                  fieldname="signatoryName"
                  isRequired={signatoryRequired}
                  valueGot={bookingDetails?.signatoryUser?.name}
                  iProps={{ placeholder: "John Smith" }}
                  validate={errors.signatoryName && "error"}
                  rules={{ required: "Please enter tenant name" }}
                  validMessage={
                    errors.signatoryName && errors.signatoryName.message
                  }
                />
              </Col>

              {usagesType?.toLocaleLowerCase() === "residential" && (
                <Col span={8}>
                  <InputField
                    control={control}
                    setValue={setValue}
                    label="Signatory Arabic Name"
                    isRequired={signatoryRequired}
                    fieldname="signatoryArabicName"
                    iProps={{ placeholder: "جون سميث" }}
                    valueGot={bookingDetails?.signatoryUser?.arabicName}
                    validate={errors.signatoryArabicName && "error"}
                    rules={{ required: "Please enter tenant arabic name " }}
                    validMessage={
                      errors.signatoryArabicName &&
                      errors.signatoryArabicName.message
                    }
                  />
                </Col>
              )}
            </Row>

            {/* next row */}
            <Row gutter={24}>
              <Col span={8}>
                <InputField
                  control={control}
                  setValue={setValue}
                  label="Mobile number"
                  fieldname="signatoryMobile"
                  isRequired={signatoryRequired}
                  valueGot={bookingDetails?.signatoryUser?.mobile}
                  validate={errors.signatoryMobile && "error"}
                  iProps={{ addonBefore: "+971", placeholder: "554441212" }}
                  validMessage={
                    errors.signatoryMobile && errors.signatoryMobile.message
                  }
                  rules={{
                    minLength: {
                      value: 9,
                      message:
                        "Please enter valid mobile number, 9 digits required",
                    },
                    maxLength: {
                      value: 9,
                      message:
                        "Please enter valid mobile number, 9 digits required",
                    },
                    required: "Please enter Mobile No.",
                  }}
                />
              </Col>

              <Col span={8}>
                <InputField
                  control={control}
                  label="Designation"
                  setValue={setValue}
                  fieldname="designation"
                  isRequired={signatoryRequired}
                  valueGot={bookingDetails?.signatoryUser?.designation || bookingForm["designationAsPerVisa"] || bookingForm?.designation}
                  iProps={{ placeholder: "Manager" }}
                  validate={errors.designation && "error"}
                  rules={{ required: "Please enter your designation." }}
                  validMessage={
                    errors.designation && errors.designation.message
                  }
                />
              </Col>

              <Col span={8}>
                <InputField
                  label="Company"
                  control={control}
                  fieldname="company"
                  setValue={setValue}
                  isRequired={signatoryRequired}
                  valueGot={bookingDetails?.signatoryUser?.company}
                  validate={errors.company && "error"}
                  iProps={{ placeholder: "Xyz Real Estate" }}
                  rules={{ required: "Please enter company name." }}
                  validMessage={errors.company && errors.company.message}
                />
              </Col>
            </Row>
          </Col>
        )}

        <Row gutter={24} className="footer">
          <Space size="middle">
            <Button
              label="Cancel"
              className="bf-discard-button"
              onClick={() =>
                props.parentState({ modalPayload: { open: false } })
              }
            />

            <Button
              type="submit"
              label="Update"
              className="cc-fotter_next-button"
            />
          </Space>
        </Row>
      </Col>
    </Form>
  );
};

export default index;
