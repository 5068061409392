import React from "react";

// others
import { Item } from "../view";
import { Col, Row, Spin } from "antd";
import { Empty } from "../../../../../assets/svg";
import { numberWithCommas, filterChangeValue } from "../utils";

interface IProps {
  booking: any;
}

const index = (props: IProps) => {
  // variables
  const isBooking = props?.booking && Object.keys(props.booking).length > 0;

  const valueStyle = { textTransform: "capitalize" };

  const toNormalCase = (str) => str?.charAt(0)?.toUpperCase() + str?.slice(1)

  return (
    <Col span={24} className="view-booking">
      {!isBooking && (
        <Row gutter={24} className="loading">
          <Spin size="large" />
        </Row>
      )}

      {isBooking && props.booking?.bookingTerms?.renewalAmount && (
        <>
          <Row gutter={24} className="bd-item">
            <Item
              label={`Property Admin`}
              value={props.booking?.adminManager?.name ? toNormalCase(props.booking?.adminManager?.name) : ""}
            />
            <Item
              label="Current Annual Rent"
              value={`AED ${numberWithCommas(
                props.booking?.bookingTerms?.annualBaseRent
              )}`}
            />
            <Item
              valueStyle={valueStyle}
              label="Increase or decrease your rent?"
              value={filterChangeValue(
                props.booking?.bookingTerms?.changeValue
              )}
            />
            <Item
              valueStyle={valueStyle}
              label="Type of Adjustment"
              value={props.booking?.bookingTerms?.amountType}
            />

            {props.booking?.bookingTerms?.changeValue !== "no_change" && (
              <Item
                label={`${props.booking?.bookingTerms?.amountType} of Adjustment`}
                value={`${props.booking?.bookingTerms?.amountType === "amount"
                    ? "AED"
                    : ""
                  } ${numberWithCommas(props.booking?.bookingTerms?.amountChange)}
                ${props.booking?.bookingTerms?.amountType === "percentage"
                    ? "%"
                    : ""
                  }
                `}
              />
            )}

            <Item
              label={
                props.booking?.bookingTerms?.changeValue !== "no_change"
                  ? "Updated Rent Amount"
                  : "Rent Amount"
              }
              value={`AED ${numberWithCommas(
                props.booking?.bookingTerms?.renewalAmount
              )}`}
            />

            <Item
              label="No. of Instalments"
              value={props.booking?.bookingTerms?.numberOfInstallments}
            />
          </Row>
        </>
      )}

      {isBooking && !props.booking?.bookingTerms?.renewalAmount && (
        <Row style={{ justifyContent: "center" }}>
          <Empty
            width="200px"
            height="200px"
            style={{ alignContent: "center" }}
          />
        </Row>
      )}
    </Col>
  );
};

export default index;
