
import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Row,
  Col,
  Typography,
  Layout,
  Spin,
} from "antd";
import {
  LoadingOutlined
} from "@ant-design/icons";
import axios from "axios";
import { authService } from "../../../configs/constants";

const { Text, Paragraph } = Typography;

export default () => {
  const [loading, setLoading] = useState(true);
  const [textHTML, setTextHTML] = useState('');
  
  const [searchParams, setSearchParams] = useSearchParams();
  const noticeId = searchParams.get("noticeId")

  const antIcon = <LoadingOutlined spin />;

  useEffect(() => {
    ;(async () => {

      const url = `${authService}/user/notices/${noticeId}`;

      try {
        const {
          data: { result },
        } = await axios.get(url, { headers: { appName: "institutionalLandlordPortal" } });

        setTextHTML(result.description)
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    })()
  }, [noticeId])

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
    <Layout className="login-layout">
      <Row align="middle">
        <Col lg={24} sm={24} xs={24}>
          <div className="">
            <Row gutter={[0, 40]}>
              <Col span={24}>
                <Row gutter={[24, 24]} align="middle">
                  <Col span={24} style={{textAlign: 'center'}}>
                    <div dangerouslySetInnerHTML={{__html: textHTML}} style={{marginTop: 20}}/>
                    
                  </Col>
                  <Col span={24}>
                  </Col>
                </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Layout>
    </Spin>
  );
};
