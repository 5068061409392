var _ = require("lodash");
import * as action_types from "./constants";

const initialState: any = {
  properties: [],
  assignedList: [],
  serviceProviders: [],
  dropDownServiceProviders: [],
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.GET_ASSIGNED_LIST:
      return { ...state, assignedList: data };
    case action_types.GET_SERVICE_PROVIDER:
      return {
        ...state,
        serviceProviders: data?.serviceProviders,
        properties: _.map(data.properties, (item) => ({
          value: item.propertyId,
          label: item.buildingName,
        })),
        dropDownServiceProviders: _.map(data.serviceProviders, (item) => ({
          value: item.serviceProviderId,
          label: item.serviceProviderName,
          residential: _.map(item?.residentialCategories, (catg) => ({
            value: catg.categoryId,
            label: catg?.categoryName,
          })),
          commercial: _.map(item?.commercialCategories, (catg) => ({
            value: catg.categoryId,
            label: catg?.categoryName,
          })),
          internal: _.map(item?.internalCategories, (catg) => ({
            value: catg.categoryId,
            label: catg?.categoryName,
          })),
        })),
      };
    default:
      return state;
  }
};
