import axios from "../../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import { maintenanceService } from "../../../../../configs/constants";
const getShiftsUrl = `${maintenanceService}/maintenance/getShifts`;
const deleteShiftUrl = `${maintenanceService}/maintenance/removeShift`;
const createShiftUrl = `${maintenanceService}/maintenance/addShift`;
const assignShiftUrl = `${maintenanceService}/maintenance/assignShiftToSP`;
const editShiftUrl = `${maintenanceService}/maintenance/updateShift`;
const operatingHours = `${maintenanceService}/maintenance/getSPShifts`;

export const getOperatingHours = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(`${operatingHours}`);
      dispatch({
        type: action_types.GET_OPERATING_HOURS,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getAllShifts = (page, limit, search) => {
  return async (dispatch) => { 
    const url = `${getShiftsUrl}?perPage=${limit}&page=${page}${
      search ? `&search=${search}` : ""
    }`;
    try {
      const {
        data: { result },
      } = await axios.get(url);
      dispatch({
        type: action_types.GET_SHIFTS_LIST,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const deleteShifts = async (item) => {
    const url = `${deleteShiftUrl}`;
    try {
      const {
        data: { result },
      } = await axios.patch(url,
        {
          "id": item._id,
          "sureDelete":true
      });

      return result
    } catch (e) {
      console.log("Err", e);
    }
};

export const addShift = async (payload) => {

  const url = createShiftUrl

  try {

    const {
      data: { result },
    } = await axios.post(url, payload);

    return result

  } catch (e) {
    console.log("Err", e);
  }
};

export const assignDefault = async (payload) => {

  const url = assignShiftUrl

  try {

    const {
      data: { result },
    } = await axios.post(url, payload);

    return result

  } catch (e) {
    console.log("Err", e);
  }
};

export const editShift = async (payload) => {

  const url = editShiftUrl

  try {

    const {
      data: { result },
    } = await axios.patch(url, payload);

    return result

  } catch (e) {
    console.log("Err", e);
  }
};