import React from "react";

const index = (props: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.3125 11.125C19.3125 10.8438 19.0312 10.5625 18.75 10.5625H5.25C4.93359 10.5625 4.6875 10.8438 4.6875 11.125V19C4.6875 19.3164 4.93359 19.5625 5.25 19.5625H18.75C19.0312 19.5625 19.3125 19.3164 19.3125 19V11.125ZM18.75 8.875C19.9805 8.875 21 9.89453 21 11.125V19C21 20.2656 19.9805 21.25 18.75 21.25H5.25C3.98438 21.25 3 20.2656 3 19V11.125C3 9.89453 3.98438 8.875 5.25 8.875H18.75ZM18.4688 6.0625C18.9258 6.0625 19.3125 6.44922 19.3125 6.90625C19.3125 7.39844 18.9258 7.75 18.4688 7.75H5.53125C5.03906 7.75 4.6875 7.39844 4.6875 6.90625C4.6875 6.44922 5.03906 6.0625 5.53125 6.0625H18.4688ZM16.7812 3.25C17.2383 3.25 17.625 3.63672 17.625 4.09375C17.625 4.58594 17.2383 4.9375 16.7812 4.9375H7.21875C6.72656 4.9375 6.375 4.58594 6.375 4.09375C6.375 3.63672 6.72656 3.25 7.21875 3.25H16.7812Z"
        fill="#24435D"
      />
    </svg>
  );
};

export default index;
