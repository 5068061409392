import { combineReducers } from "redux";
import signup from "../../app/modules/SignUp/duck/reducer";
import header from "../../app/molecules/Header/duck/reducer";
import addPropertyReducer from "../../app/modules/Properties/AddProperty/ducks/reducers";
import addInfoReducer from "../../app/modules/Properties/AddProperty/ducks/reducers/addInfoReducer";
import categories from "../../app/modules/Maintenance/Technicians/ducks/reducers";
import paymentReducer from "../../app/modules/Properties/AddProperty/ducks/reducers/paymentReducer";
import tcReducer from "../../app/modules/Properties/AddProperty/ducks/reducers/tcReducer";
import spinnerReducer from "../../app/modules/Properties/AddProperty/ducks/reducers/spinReducer";
import bookingReducer from "../../app/modules/Properties/BookingForm/ducks/reducers";
import dashboard from "../../app/modules/Properties/ducks/reducers";
import tenants from "../../app/modules/Tenants/ducks/reducers";
import units from "../../app/modules/Units/ducks/reducers";
import notices from "../../app/modules/Notices/ducks/reducers";
import shifts from "../../app/modules/Configurations/Shifts/ducks/reducers";
import managers from "../../app/modules/Managers/ducks/reducers";
import configuration from "../../app/modules/Configurations/ducks/reducers";
import tenantsSummary from "../../app/modules/Tenants/TenantDetails/ducks/reducers";
import reviews from "../../app/modules/Reviews/ducks/reducers";
import maintenance from "../../app/modules/Maintenance/ducks/reducer";
import service from "../../app/modules/ServiceProvider/ducks/reducers";
import opertunities from "../../app/modules/LeadManagement/OpportunityList/ducks/reducers";
import leads from "../../app/modules/LeadManagement/Leads/ducks/reducers";
import leadDetails from "../../app/modules/LeadManagement/LeadsDetail/ducks/reducers";
import opportunityDetails from "../../app/modules/LeadManagement/OpportunityDetail/ducks/reducers";
import propertyDetails from "../../app/modules/Properties/PropertyDetail/ducks/reducers";
import contractBookingForm from "../../app/modules/ContractFlow/ContractBookingForm/ducks/reducers";
import leasingForm from "../../app/modules/LeasingFormNew/ducks/reducers";
import tenantDetails from "../../app/modules/ContractTenantDetails/ducks/reducers";
import notification from "../../app/modules/Notification/ducks/reducers";
import tickets from "../../app/modules/Maintenance/Tickets/ducks/reducer";
import leases from "../../app/modules/BookingformList/ducks/reducers";
import contracts from "../../app/modules/Contracts/ducks/reducers";
import leaseForms from "../../app/modules/LeasingForm/ducks/reducers";
import booking from "../../app/modules/BookingListing/ducks/reducers";
import bookingRenewals from "../../app/modules/BookingRenewalListing/ducks/reducers";
import ejari from "../../app/modules/Ejari/ducks/reducers";

import technicians from "../../app/modules/Maintenance/Technicians/ducks/reducers";
import zones from "../../app/modules/Maintenance/Zones/ducks/reducers";

import filters from "../../app/modules/App/SearchFilters/ducks/reducers";

import newBooking from "../../app/modules/BookingForm/ducks/reducers";
import {userScreens} from "../../app/modules/Login/ducks/reducers";

const rootReducer = combineReducers({
  header,
  signup,
  dashboard,
  addPropertyReducer, // old
  addInfoReducer,
  propertyDetails,
  paymentReducer,
  tcReducer,
  spinnerReducer,
  bookingReducer,
  reviews,
  tenants,
  units,
  notices,
  managers,
  tenantsSummary,
  maintenance,
  opertunities,
  leads,
  configuration,
  leadDetails,
  opportunityDetails,
  tenantDetails,
  contractBookingForm,
  leasingForm,
  notification,
  leases,
  shifts,
  service,
  leaseForms,
  contracts,
  booking,
  bookingRenewals,
  ejari,
  technicians,
  categories,
  zones,
  tickets,
  filters,
  newBooking,
  userScreens

});

export default rootReducer;
