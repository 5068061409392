import { Card, Col, Row, Typography, Space } from "antd";
import React, { useEffect, useState } from "react";
const dayjs = require('dayjs');
import ListPanel from "../../../../../molecules/ListPanel";
import { useSelector } from "react-redux";
import { dateByformat } from "../../../../../../utils/dateHelper";
import { Popup } from "../../../../../atoms/Popup";
import EditTenant from "../EditTenant"

const { Title, Text } = Typography;

export default (props) => {
  const tenantData = useSelector(
    (state: any) => state.tenantsSummary.tenantData,
  );
  const lease = useSelector((state: any) => state.tenantsSummary.lease);

  const [info, setInfo] = useState([]);
  const [docs, setDocs] = useState([]);
  const [editType, setEditType] = useState(null);
  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(0);

  const userDetail = tenantData?.userDetail;
  const userDocDetail = tenantData?.userDocumentDetail;
  const leaseDetails = tenantData?.leaseDetail?.length>0 ? tenantData?.leaseDetail : [];
  const documentDetail = leaseDetails?.tenantDetails;


  useEffect(() => {

    setInfo([
      {
        key: "Full Name",
        value:
          userDetail?.name ?? "N/A", // && !tenantData?.last_name

        // : `${tenantData?.first_name} ${tenantData?.last_name}`,
      },
      {
        key: "Reg. Phone Number",
        value: userDetail?.mobile || "N/A",
      },
      {
        key: "Reg.Email",
        value: userDetail?.email || "N/A",
      },
      {
        key: "Reg.address",
        value: userDocDetail?.address || documentDetail?.address || "N/A",
      },
      {
        key: "Reg.city",
        value: userDocDetail?.emirates || documentDetail?.emirates || "N/A",
      },
      {
        key: "Reg.country",
        value: documentDetail?.country || userDocDetail?.country || "N/A",
      },
      {
        key: "Nationality",
        value: userDocDetail?.nationality || documentDetail?.nationality || "N/A",
      },
      {
        key: "Emirates",
        value: userDocDetail?.emirates || documentDetail?.emirates || "N/A",
      },
    ]);
    setDocs([
      {
        key: "Passport Number",
        value: documentDetail?.passport || userDocDetail?.passport || "N/A",
      },
      {
        key: "Passport Expiry Date",
        value: documentDetail?.passportExpiry || userDocDetail?.passportExpiry ? dateByformat((documentDetail?.passportExpiry || userDocDetail?.passportExpiry), "DD-MM-YYYY") : "N/A",
      },
      {
        key: "Emirates ID Number",
        value: documentDetail?.emiratesID || userDocDetail?.emiratesId || "N/A",
      },
      {
        key: "Emirates ID Expiry Date",
        value: (documentDetail?.emirateIdExpiry || userDocDetail?.emirateIdExpiry) ? dateByformat((documentDetail?.emirateIdExpiry || userDocDetail?.emirateIdExpiry), "DD-MM-YYYY") : "N/A",
      },
      {
        key: "Visa Number",
        value: documentDetail?.uaeVisaNumber || documentDetail?.visaNumber || userDocDetail?.visaNumber || "N/A",
      },
      {
        key: "Visa Expiry Date",
        value: (documentDetail?.uaeVisaExpiry || documentDetail?.visaExpiry || userDocDetail?.visaExpiry) ? dateByformat((documentDetail?.uaeVisaExpiry || documentDetail?.visaExpiry || userDocDetail?.visaExpiry), "DD-MM-YYYY") : "N/A",
      },
      {
        key: "Trade License Number",
        value:
          documentDetail?.tradeLicense || userDocDetail?.tradeLicense || "N/A",
      },
      {
        key: "Trade License Expiry Date",
        value:
          (documentDetail?.tradeLicenseExpiry || userDocDetail?.tradeLicenseExpiry) ? dateByformat((documentDetail?.tradeLicenseExpiry || userDocDetail?.tradeLicenseExpiry), "DD-MM-YYYY") : "N/A",
      },
    ]);
  }, [tenantData, userDetail, documentDetail]);

  const onSubmit = () => {
    props.getTenants()
    setVisible(false)
  }

  const popup = [
    {
      title: (
        <Title level={3} className="m-0">
          Edit Tenant
        </Title>
      ),
      content: (
        <>
          <EditTenant
            onSubmit={onSubmit}
            editType={editType}
            onCancel={() => setVisible(false)}
            info={info}
            docs={docs}
            tenantDetail={documentDetail}
            userDetail={userDetail}
          />
        </>
      ),
      width: 850,
    },
  ];

  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => setVisible(false),
  };

  const editDetails = (type) => {
    setEditType(type)
    setVisible(true)
  }

  return (
    <Card bordered={false} bodyStyle={{ padding: "3em 3.5em" }} className="tenant_detail_cards">
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Space direction="vertical">
            <Title level={2} className="mb-0">
              {userDetail?.name ?? "N/A"}
            </Title>
            {/* <Text className="d-block defaultFont">
              User ID: {tenantData?.id}
            </Text> */}
            {/* <Text className="ag-primary">
              Account Status:{" "}
              {tenantData?.status == "enable" ? "Active" : "Inactive"}
            </Text> */}
          </Space>
        </Col>
        <Col span={24} className="tenent_detail_info">
          <Card bordered={false} bodyStyle={{ padding: "0 32% 0 0" }}>
            <Row gutter={[40, 40]}>
              <Col span={12}>
                <ListPanel data={info} title={"Tenant Details"} clickPanel={editDetails} />
              </Col>
              <Col span={12}>
                <ListPanel data={docs} title={"Documents Summary"} clickPanel={editDetails} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Popup {...popupProps} />
    </Card>
  );
};
