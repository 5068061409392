import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Typography,
  Card,
  Select,
  message,
  Button,
  Spin,
  Space,
  Table,
  Tag,
  Pagination,
} from "antd";
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import Grid from "../../../atoms/Grid/";
import { Popup } from "../../../atoms/Popup";
import SectionHeader from "../../../molecules/SectionHeader";

import AddZone from "./components/AddZone";
import BreadCrumb from "../../../atoms/BreadCrumb";
import DashboardLayout from "../../../molecules/DashboardLayout";
import { getAreaStatus, getListZones, getZoneData } from "./ducks/actions";
import { maintenanceService } from "../../../../configs/constants";
import axios from "../../../../utils/axiosInceptor";
import AddBuilding from "./components/AddBuilding";
import { EditOutlined } from "@ant-design/icons";
import EditZone from "./components/EditZone";

const antIcon = <LoadingOutlined spin />;
const { Title } = Typography;
const { Option } = Select;

const gridProps = {
  sortable: true,
  filter: false,
};

const showOption = [
  {
    value: "All",
    label: "All Zones",
  },
  {
    value: "new",
    label: "New Zone",
  },
];

const breadcrumbList: any = [
  {
    label: "Maintenance",
    route: "/zones",
  },
  {
    label: "Zones",
    route: "/zones",
  },
];

export default () => {
  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [searchText, setSearchText] = useState();
  const [zones, setZones] = useState([]);
  const [page, setPage] = useState(1);
  const limit = 10;
  const [zoneData, setZoneData] = useState({});
  const zoneApi = useSelector((state: any) => state.zones.zonesData);
  const searchField = (
    <Input
      type="text"
      onChange={(e) => onSearch(e)}
      placeholder=""
      suffix={<SearchOutlined />}
    />
  );

  useEffect(() => {
    // dispatch(getAreaStatus());
    if (zoneApi?.length == 0)
      dispatch(getListZones(1, limit, ""));
    // dispatch(getBuilding());
  }, []);

  // console.log("zoneApi", zoneApi);


  const column = [
    {
      title: "Zones",
      dataIndex: "name",
      key: "name",
      width: 220,
    },
    {
      title: "Building(s)",
      dataIndex: "properties",
      key: "properties",
      width: 220,
      render: (_, { properties }) => {
        return (
          <>
            {properties?.map((tag) => {
              return (
                <Tag className="tag-sm" key={tag?._id}>
                  {tag?.name}
                </Tag>
              );
            })}
          </>
        );
      },
    },
    {
      title: "Technician(s)",
      dataIndex: "technicians",
      key: "technicians",
      width: 220,
      render: (_, { technicians }: any) => {
        return (
          <>
            <span />
            {technicians?.map((tag) => {
              return (
                <Tag className="tag-sm" key={tag?._id}>
                  {tag?.name}
                </Tag>
              );
            })}
          </>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 100,
      render: (text, record) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => onEdit(record)}
              className="p-0 ag-success"
            >
              <div className="Simple-tooltip">
                <EditOutlined />
                <section className="tt-content">Edit</section>
              </div>
            </Button>
          </Space>
        );
      },
    },
  ];




  const onEdit = (data) => {
    setVisible(true);
    setTrigger(2);
    setZoneData(data)
  };


  const popup = [
    {
      title: (
        <Title level={3} className=" mb-0">
          Add Zone
        </Title>
      ),
      content: (
        <AddZone editMode={false} onCancel={() => setVisible(false)} onSubmit={onSubmit} />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Assign a Building
        </Title>
      ),
      content: (
        <AddBuilding
          onCancel={() => setVisible(false)}
          onSubmit={onSubmit}
          zoneData={zoneData}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="mb-0">
          Edit Zone
        </Title>
      ),
      content: (
        <AddZone onCancel={() => setVisible(false)} editMode={true} data={zoneData} onSubmit={onSubmit} />
      ),
      width: 650,
    },
  ];

  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => setVisible(false),
  };

  const onPageChange = (e) => {
    setPage(e);
    dispatch(getListZones(e, limit, searchText));
  };

  useEffect(() => {
    if (zoneApi?.data) {
      let zoneArray: any = zoneApi?.data.map((item, index) => {
        return {
          id: item._id,
          zoneId: item.zoneId,
          name: item.name,
          properties: item.property,
          status: item.status,
          technicians: item.technicians,
          description: item?.description ?? ""
        };
      });
      setZones(zoneArray);
    }
  }, [zoneApi]);

  function onSubmit() {
    dispatch(getListZones(1, limit, ""));
    setPage(1);
    setVisible(false);
  }

  // async function onStatusChange(value, option, param) {
  //   // console.log("status", option, value, param)
  //   setLoading(true);
  //   const url = `${maintenanceService}/maintenance/updateZoneStatus/${param.id}`;
  //   try {
  //     await axios.put(url, { status: option.children });
  //     setTimeout(() => {
  //       setLoading(false);
  //       message.success("Zone successfully updated");
  //       // dispatch(getListZones());
  //     }, 3000);
  //   } catch (e) {
  //     setLoading(false);
  //     const { response } = e;
  //     message.error(response?.data?.message);
  //   }
  // }

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };


  const addZone = () => {
    setVisible(true);
    setTrigger(0);
  };

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <DashboardLayout>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <BreadCrumb list={breadcrumbList} />
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <SectionHeader
              heading="Zones"
              total={zones.length}
              // showOption={showOption}
              // handleShow={handleShow}
              // search={(e) => onTopSearch(e)}
              // importAction={onClickImport}
              // filterAction={onClickFilter}
              addNewAction={addZone}
            />
            <Row gutter={24} className="ant-table-row">
              <Col span={24}>
                <Pagination
                  style={{ justifyContent: "flex-end" }}
                  className="ag-property-text-pagination"
                  total={zoneApi?.totalCount}
                  defaultPageSize={zoneApi?.perPage}
                  current={page}
                  showTotal={(total, range) =>
                    `Showing ${range[0]}-${range[1]} of ${total} Zones`
                  }
                  onChange={(e) => onPageChange(e)}
                  showSizeChanger={false}
                />
              </Col>
              <Col span={24}>
                <Table
                  columns={column}
                  scroll={{ y: 500 }}
                  dataSource={zones}
                  rowKey={(record, index) => index}
                  className="custom-table org_table_wrap"
                  pagination={{
                    hideOnSinglePage: true,
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </DashboardLayout>
      <Popup destroyOnClose={true} {...popupProps} />
    </Spin>
  );
};
