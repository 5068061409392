import React from "react";

const index = () => {
    return (
        <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.57031 18.8438H12.3047C12.7266 19.5117 13.2539 20.0742 13.8867 20.5312C13.7812 20.5312 13.6758 20.5312 13.5703 20.5312H4.57031C3.30469 20.5312 2.32031 19.5469 2.32031 18.2812V4.78125C2.32031 3.55078 3.30469 2.53125 4.57031 2.53125H10.3711C10.9688 2.53125 11.5312 2.77734 11.9531 3.19922L15.1523 6.39844C15.5742 6.82031 15.8203 7.38281 15.8203 7.98047V9.52734C15.1875 9.70312 14.625 9.94922 14.1328 10.3008V8.15625H11.3203C10.6875 8.15625 10.1953 7.66406 10.1953 7.03125V4.21875H4.57031C4.25391 4.21875 4.00781 4.5 4.00781 4.78125V18.2812C4.00781 18.5977 4.25391 18.8438 4.57031 18.8438ZM17.5078 10.4062C19.3008 10.4062 20.9531 11.3906 21.8672 12.9375C22.7812 14.5195 22.7812 16.4531 21.8672 18C20.9531 19.582 19.3008 20.5312 17.5078 20.5312C15.6797 20.5312 14.0273 19.582 13.1133 18C12.1992 16.4531 12.1992 14.5195 13.1133 12.9375C14.0273 11.3906 15.6797 10.4062 17.5078 10.4062ZM18.0703 13.2188C18.0703 12.9375 17.7891 12.6562 17.5078 12.6562C17.1914 12.6562 16.9453 12.9375 16.9453 13.2188V14.9062H15.2578C14.9414 14.9062 14.6953 15.1875 14.6953 15.4688C14.6953 15.7852 14.9414 16.0312 15.2578 16.0312H16.9453V17.7188C16.9453 18.0352 17.1914 18.2812 17.5078 18.2812C17.7891 18.2812 18.0703 18.0352 18.0703 17.7188V16.0312H19.7578C20.0391 16.0312 20.3203 15.7852 20.3203 15.4688C20.3203 15.1875 20.0391 14.9062 19.7578 14.9062H18.0703V13.2188Z" fill="#24435D"/>
        </svg>    
    );
};

export default index;
