import React, { useState, useEffect, Fragment } from "react";

// others
import axios from "axios";
import RenewalBillings from "./RenewalBillings";
import { PlusOutlined } from "@ant-design/icons";
import { getLandlordUsers } from "../ducks/actions";
import { useSelector, useDispatch } from "react-redux";
import { useFieldArray, useWatch } from "react-hook-form";
import { leaseService } from "../../../../configs/constants";
import { installmentsList } from "../../Properties/ducks/constants";
import { Col, Row, Radio, Button, message, Typography } from "antd";
import { options, changeValueOptions, rentAmountFormatter } from "./utils";
import {
  InputField,
  InputRadio,
  InputNumberField,
  ReactSelectField,
} from "../../../atoms/FormElement";

const { Text } = Typography;

const initcharge = {
  type: "",
  itemId: null,
  amount: 0,
  intallments: { label: 1, value: 1 },
  mode: {
    label: "Cheque",
    value: "cheque",
  },
};

export default (props) => {
  const dispatch: any = useDispatch();
  const {
    data,
    errors,
    control,
    parking,
    setValue,
    getValues,
    isDisable,
    propertyId,
    legalEntity,
    bookingPeriod,
  } = props;
  const [noIstallment, setNoInstallment] = useState([]);
  const [billingType, setBillingType] = useState([]);
  const [deleteIndex, setDeleteIndex] = useState(2); //startIndex for delete in billings

  const [plansData, setPlansData] = useState([]);
  const [changeValue, setChangeValue] = useState("");
  const [changeProps, setChangeProps] = useState({});

  const landlordUsers = useSelector(
    (state: any) => state.leasingForm.landlordUsers
  );

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const details = data?.bookingTerms;

  const [landlordUserOptions, setlandlordUserOptions] = useState([]);

  const get_billing_charges = [
    // { name: "Rent Installment", key: "rent_installment" },
    { name: "Ejari fee", key: "ejari_fee" },
    { name: "Security Deposit", key: "security_deposit" },
    { name: "Rental Commission", key: "rental_commission" },
    { name: "Administration Charges", key: "administration_charges" },
    { name: "Furniture Charges", key: "furniture_charges" },
    { name: "Parking Charges", key: "parking_charges" },
    { name: "Service Charges", key: "service_charges" },
  ];

  const { fields, prepend, append, remove, insert } = useFieldArray({
    control,
    name: "othercharges",
  });

  const billing = details?.billingCharges ?? [];

  const bookingStart = useWatch({
    control,
    name: "bookingStartDate",
  });

  useEffect(() => {
    if (landlordUsers?.length > 0) {
      const LLOptions = landlordUsers.map((user) => ({
        label: user.name,
        value: user.userId,
      }));
      setlandlordUserOptions(LLOptions);
    } else {
      propertyId && dispatch(getLandlordUsers(propertyId));
    }
  }, [landlordUsers, propertyId]);

  // useEffect(() => {
  //   dispatch(getLandlordUsers(propertyId));
  // }, []);

  const ejariProcessing = useWatch({
    control,
    name: "ejariProcessing",
  });

  useEffect(() => {
    if (ejariProcessing?.value == "manual") {
      // if (fields[1]?.["type"]?.value == "ejari_fee") remove(1);
      enableClone("ejari_fee");
      setDeleteIndex(1);
    } else if (ejariProcessing?.value == "auto") {
      if (fields[1]?.["type"]?.value != "ejari_fee") {
        insert(1, {
          type: { label: "ejari_fee", value: "ejari_fee" }, //get_billing_charges.find((e) => e.key == "ejari_fee"),
          intallments: {
            label: 1,
            value: 1,
          },
          amount: 0,
        });
      }
      disableClone("ejari_fee");
      setDeleteIndex(2);
    }
  }, [ejariProcessing]);

  useEffect(() => {
    let billingTypeTemp = [];
    if (get_billing_charges.length > 0) {
      get_billing_charges?.map((item, index) => {
        let isDisabledTemp = false;
        if (
          item.key == "rent_installment" ||
          // item.key == "ejari_fee" ||
          item.key == "security_deposit"
        ) {
          isDisabledTemp = true;
        }
        if (item.key != "base_rent_ic") {
          billingTypeTemp.push({
            label: item.name,
            value: item.key,
            isDisabled: isDisabledTemp,
          });
        }
      });
      setBillingType(billingTypeTemp);
    }
  }, []);

  const parkingLimit = () => {
    append(initcharge);
    // disableClone("new");
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    let filterData = [];
    if (newSelectedRowKeys.length > 0) {
      filterData = plansData.filter((val, index) =>
        newSelectedRowKeys.includes(index)
      );
      setSelectedRowKeys(newSelectedRowKeys);
    } else {
      filterData = [];
    }
    // setSelectedPaymentPlans(filterData);
    setValue("selectedPaymentPlans", filterData);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // useEffect(()=>{
  //   setSelectedRowKeys([1,2,3,4]);
  // },[]);

  const disableClone = (type) => {
    let arr = [];
    arr = getValues().othercharges;
    let check = arr.filter((item) => item.type.value == type);
    if (
      (type == "PARKING" && check.length > 4) ||
      (type != "PARKING" && check.length > 0)
    ) {
      let newArr = [];
      newArr = [...billingType];
      let findType = null;
      findType = newArr.findIndex((item) => item.value == type);
      if (findType) {
        billingType.map((item, index) => {
          let b = [];
          b = arr.filter((y) => item.value === y.type.value);

          if (b.length > 0) {
            if (item.value === "PARKING") {
              if (b.length > 4) {
                newArr[index].isDisabled = true;
              } else {
                newArr[index].isDisabled = false;
              }
            } else {
              newArr[index].isDisabled = true;
            }
          } else {
            newArr[index].isDisabled = false;
          }
        });
        setBillingType(Object.assign([], newArr));
      }
    } else {
      let newArr = [];
      newArr = [...billingType];
      let check2 = arr.filter((item) => item.type.value == "PARKING");
      arr.map((x) => {
        let a = newArr.findIndex((item) => item.value == x.type.value);
        if (a) {
          if (x.type.value != "PARKING") {
            newArr[a].isDisabled = true;
          } else {
            if (check2.length > 3) {
              newArr[a].isDisabled = true;
            }
          }
        }
      });
      setBillingType(Object.assign([], newArr));
    }
  };

  const enableClone = (type) => {
    if (!isDisable) {
      let newArr = [];
      newArr = [...billingType];
      let findType = null;
      findType = newArr.findIndex((item) => item.value == type);
      newArr[findType].isDisabled = false;
      setBillingType(Object.assign([], newArr));
    }
  };

  useEffect(() => {
    if (isDisable) {
      const { bookingTerms = {} } = data;

      if (Object.keys(bookingTerms).length > 0) {
        setValue("changeValue", bookingTerms?.changeValue);
        setValue("amountType", bookingTerms?.amountType);
        setValue("amountChange", bookingTerms?.amountChange);
        setValue("rentAmount", bookingTerms?.annualBaseRent);
        setValue("annualBaseRent", bookingTerms?.renewalAmount);
        setValue("noOfInstallments", {
          label: bookingTerms?.numberOfInstallments,
          value: bookingTerms?.numberOfInstallments,
        });

        setChangeValue(bookingTerms?.changeValue);
        setChangeValue(bookingTerms?.changeValue);
      }
    }
  }, [data]);

  useEffect(() => {
    if (details && !isDisable) {
      let installmentNo = details?.numberOfInstallments || 1;
      setValue("annualBaseRent", details.annualBaseRent);
      setValue("noOfInstallments", {
        label: installmentNo,
        value: installmentNo,
      });

      if (billing?.length > 0 && billingType.length > 0) {
        billing.map((item) => {
          if (item.type == "ejari_fee") {
            append({
              // itemId: item.itemId,
              type: billingType.find((e) => e.value == item.type),
              intallments: {
                label: item?.numberOfInstallments || 1,
                value: item?.numberOfInstallments || 1,
              },
              mode: {
                label: item?.mode || "Cheque",
                value: item?.mode || "cheque",
              },
              amount: item.amount,
              // slot: item.parkingSlot,
            });
          } else if (item.type == "security_deposit") {
            append({
              // itemId: item.itemId,
              type: billingType.find((e) => e.value == item.type),
              intallments: {
                label: item?.numberOfInstallments || 1,
                value: item?.numberOfInstallments || 1,
              },
              mode: {
                label: item?.mode || "Cheque",
                value: item?.mode || "cheque",
              },
              amount: item.amount,
              // slot: item.parkingSlot,
            });
          } else {
            if (item.type) {
              let type = billingType.find((e) => e.value == item.type);
              // let type = { label: item.type, value: item.type };
              if (type) {
                append({
                  // itemId: item.itemId,
                  type: { ...type, isDisabled: true },
                  intallments: {
                    label: item?.numberOfInstallments || 1,
                    value: item?.numberOfInstallments || 1,
                  },
                  mode: {
                    label: item?.mode || "Cheque",
                    value: item?.mode || "cheque",
                  },
                  amount: item.amount,
                  // slot: item.parkingSlot,
                });
              }
            }
          }
        });
      }
    }
  }, [details]);

  useEffect(() => {
    legalEntity && setValue("legalEntity", legalEntity);
  }, [legalEntity]);

  useEffect(() => {
    // onPaymentPlans("YES");
  }, []);

  useEffect(() => {
    if (plansData.length > 0) {
      let array = Array.from(Array(plansData.length).keys());
      setSelectedRowKeys(array);
    }
  }, [plansData]);

  const onPaymentPlans = async (value) => {
    setTimeout(async () => {
      const unit = getValues("unitCode"); //123456-G-101
      try {
        const url = `${leaseService}/unit/paymentPlan?unitCode=${unit}&furnished=${value}`;
        const res = await axios.get(url);
        if (res?.data?.result?.length > 0) {
          const data = res.data.result.map((item, index) => ({
            ...item,
            key: index,
          }));
          setPlansData(data);
        }
      } catch (e) {
        const { response } = e;
        message.error(response?.data?.error);
      }
    }, 800);
  };

  const handleChangeProps = () => {
    const changeValue = getValues("changeValue");
    const amountType = getValues("amountType");

    if (changeValue === "increase" && amountType === "percentage")
      setChangeProps({ min: undefined, max: undefined });
    else if (changeValue === "decrease" && amountType === "percentage")
      setChangeProps({ min: undefined, max: undefined });
    if (changeValue === "increase" && amountType === "amount")
      setChangeProps({ min: undefined });
    else if (changeValue === "decrease" && amountType === "amount")
      setChangeProps({ max: undefined });
  };

  const handleAmount = (value: any) => {
    let updatedRent: any = "";

    const amountType = getValues("amountType");
    const changeValue = getValues("changeValue");
    const annualBaseRent = getValues("annualBaseRent");

    if (changeValue === "increase" && amountType === "amount")
      updatedRent = annualBaseRent + value;
    else if (changeValue === "decrease" && amountType === "amount")
      updatedRent = annualBaseRent - value;
    else if (changeValue === "increase" && amountType === "percentage")
      updatedRent = annualBaseRent + (annualBaseRent * value) / 100;
    else if (changeValue === "decrease" && amountType === "percentage")
      updatedRent = annualBaseRent - (annualBaseRent * value) / 100;

    if (!value) updatedRent = value;

    setValue("rentAmount", updatedRent);
  };

  return (
    <>
      <Col span={24} style={{ marginLeft: "-12px" }}>
        <Col span={8}>
          <InputField
            disabled={true}
            control={control}
            isRequired={true}
            fieldname="annualBaseRent"
            label="Current Annual Rent"
            validate={errors.annualBaseRent && "error"}
            validMessage={
              errors.annualBaseRent && errors.annualBaseRent.message
            }
          />
        </Col>
      </Col>

      <Col span={24} style={{ marginLeft: "-12px" }}>
        <Col span={8}>
          <InputRadio
            isRequired={true}
            control={control}
            disabled={isDisable}
            fieldname="changeValue"
            label="Increase or decrease your rent?"
            validate={errors.changeValue && "error"}
            validMessage={errors.changeValue && errors.changeValue.message}
            rules={{
              required: "This field is required",
            }}
            onChange={(e: any) => {
              setChangeValue(e.target.value);
              setValue("amountChange", "");
              if (e.target.value === "no_change")
                setValue("rentAmount", details.annualBaseRent);
              else setValue("rentAmount", "");

              handleChangeProps();
            }}
            options={
              <Row gutter={[16, 16]}>
                {changeValueOptions.map((option, i) => (
                  <Col span={8} key={`${option.value}-${i}`}>
                    <Radio value={option.value}>{option.label}</Radio>
                  </Col>
                ))}
              </Row>
            }
          />
        </Col>
      </Col>

      {changeValue && changeValue !== "no_change" && (
        <>
          <Col span={24} style={{ marginLeft: "-12px" }}>
            <Col span={8}>
              <InputRadio
                isRequired={true}
                control={control}
                disabled={isDisable}
                fieldname="amountType"
                label="Type of Adjustment"
                validate={errors.amountType && "error"}
                validMessage={errors.amountType && errors.amountType.message}
                rules={{
                  required: "This field is required",
                }}
                onChange={() => {
                  setValue("amountChange", "");
                  setValue("rentAmount", "");
                  handleChangeProps();
                }}
                options={
                  <Row gutter={[16, 16]}>
                    {options.map((option, i) => (
                      <Col span={6} key={`${option.value}-${i}`}>
                        <Radio value={option.value}>{option.label}</Radio>
                      </Col>
                    ))}
                  </Row>
                }
              />
            </Col>
          </Col>

          <Col span={24} style={{ marginLeft: "-12px" }}>
            <Col span={8}>
              <InputNumberField
                isRequired={true}
                control={control}
                onChange={handleAmount}
                fieldname="amountChange"
                label="Amount of Adjustment"
                validate={errors.amountChange && "error"}
                validMessage={errors.amountChange && errors.message}
                rules={{
                  required: "This field is required",
                }}
                iProps={{
                  disabled: getValues("amountType") ? isDisable : true,
                  ...changeProps,
                }}
              />
            </Col>
          </Col>
        </>
      )}

      {changeValue && (
        <Col span={24} style={{ marginLeft: "-12px" }}>
          <Col span={8}>
            <InputNumberField
              isRequired={true}
              control={control}
              fieldname="rentAmount"
              validate={errors.rentAmount && "error"}
              validMessage={errors.rentAmount && errors.message}
              label={
                changeValue === "no_change"
                  ? "Rent Amount"
                  : "Updated Rent Amount"
              }
              rules={{
                required: "This field is required",
              }}
              iProps={{
                disabled: true,
                formatter: (value: string): any => rentAmountFormatter(value),
              }}
            />
          </Col>
        </Col>
      )}

      <Col span={8}>
        <ReactSelectField
          control={control}
          isRequired={true}
          disable={isDisable}
          label="No. of Instalments"
          fieldname="noOfInstallments"
          selectOption={installmentsList}
          validate={errors.noOfInstallments && "error"}
          rules={{ required: "Please select no. of installments" }}
          iProps={{ isDisabled: isDisable, placeholder: "Select one" }}
          validMessage={
            errors.noOfInstallments && errors.noOfInstallments.message
          }
        />
      </Col>

      {!isDisable && (
        <>
          <Col span={24}>
            <Text
              className="ag-primary fontWeight600"
              style={{ fontSize: "18px" }}
            >
              Additional Payments
            </Text>
          </Col>

          <Col span={22} style={{ marginTop: "15px" }}>
            {fields.map((item, index) => (
              <Fragment key={item.id}>
                <RenewalBillings
                  key={item.id}
                  item={item}
                  billingType={billingType}
                  control={control}
                  errors={errors}
                  remove={remove}
                  setValue={setValue}
                  disableAll={props.disableAll}
                  getValues={getValues}
                  index={index}
                  deleteIndex={deleteIndex}
                  noIstallment={noIstallment}
                  noIData={installmentsList}
                  parking={parking}
                  setNoInstallment={setNoInstallment}
                  disableClone={disableClone}
                  enableClone={enableClone}
                />
              </Fragment>
            ))}

            {!props.disableAll && (
              <Row gutter={24}>
                <Col span={24}>
                  <Button
                    type="link"
                    className="p-0"
                    htmlType="button"
                    icon={<PlusOutlined />}
                    onClick={parkingLimit}
                  >
                    <Text
                      className="ag-primary fontWeight600"
                      style={{ fontSize: "18px" }}
                    >
                      Add more billing
                    </Text>
                  </Button>
                </Col>
              </Row>
            )}
          </Col>
        </>
      )}
    </>
  );
};
