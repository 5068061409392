import React, { useEffect, useState } from "react";
import { Row, Card, Typography, Form, Button, Divider, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { propertyService } from "../../../../../configs/constants";
import {
  EditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";

import { useForm } from "react-hook-form";
import moment from "moment";

// import {
//   getUnitSubType,
//   getUnitType,
// } from "../../AddProperty/ducks/actions/addInfoAction";
import axios from "axios";
import { bedroomCategory, bathList, tfValue } from "../../ducks/constants";
import General from "../../AddProperty/components/AddInformation/General";
import Address from "../../AddProperty/components/AddInformation/Address";
import MoreDetails from "../../AddProperty/components/AddInformation/MoreDetails";
import { getConfigData } from "../../ducks/actions";
// import FeaturesAmenities from "../../AddProperty/components/AddInformation/FeaturesAmenities";

const { Title } = Typography;
var _ = require("lodash");

export default ({ propertyDetails }) => {
  const dispatch: any = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm();
  const [noEdit, setNoEdit] = useState(true);
 
  const [amenitiesList, setAmenitiesList] = useState([]);
  const [comm, setComm] = useState(false);


  const apiUnitTypeData = useSelector(
    (state: any) => state.addInfoReducer.getUnitType
  );
  
  const apiUnitSubTypeData = useSelector(
    (state: any) => state.addInfoReducer.getUnitSubType
  );

  const usageWatch = watch("usage_type_id");
  const unitWatch = watch("unit_type_id");

  useEffect(() => {
    if (usageWatch) {
   
      if (usageWatch.value == 2) {
        setComm(true);
      } else {
        setComm(false);
      }
    }
  }, [usageWatch]);


  useEffect(() => {
    if (apiUnitTypeData.length) {
      let unitlist = apiUnitTypeData.find(
        (item) => item.id === propertyDetails.unit_type_id
      );
      if (unitlist) {
        setValue("unit_type_id", { value: unitlist.id, label: unitlist.name });
      }
    }
  }, [apiUnitTypeData]);

  useEffect(() => {
    if (apiUnitSubTypeData.length) {
      let sublist: any = {};
      sublist = apiUnitSubTypeData.find(
        (item) => item.id === propertyDetails.unit_subtype_id
      );
      if (sublist) {
        setValue("unit_subtype_id", { value: sublist.id, label: sublist.name });
      } else {
        setValue("unit_subtype_id", "");
      }
    }
  }, [apiUnitSubTypeData]);

  useEffect(() => {
    if (!noEdit) {
      let featureSelected = [];
      let amenitySelected = [];
      let am = _.map(propertyDetails.property_amenities, (item) => {
        let a = amenitiesList.find((x) => x.label === item.label);
        amenitySelected.push(a);
      });
      setValue("property_features", featureSelected);
      setValue("property_amenities", amenitySelected);
    }
  }, [noEdit]);

  useEffect(() => {
    if (Object.keys(propertyDetails).length) {
    
      setValue("buildingName", propertyDetails?.buildingName);
      setValue(
        "propertyReferenceNumber",
        propertyDetails.propertyReferenceNumber
      );
      setValue("businessUnitName", propertyDetails.businessUnitName);
      setValue("propertyCode", propertyDetails.propertyCode);

      if(propertyDetails?.erpLaName){
        setValue("propertyAdmin", {
          value:propertyDetails?.propertyAdmin,
           label: propertyDetails?.erpLaName
        });
      }
      
      setValue("addressLine1", propertyDetails.address?.addressLine1);
      setValue("emirates", {
        value: propertyDetails.address?.emirates, //.id,
        label: propertyDetails.address?.emirates, //.name,
      });
      setValue("addressLine2", propertyDetails.address?.addressLine2);
    
      setValue("country", {
        value: propertyDetails.address?.country, //.id,
        label: propertyDetails.address?.country, //.name,
      });
    

      let long = Number(propertyDetails.address?.longitude);
      let lat = Number(propertyDetails.address?.latitude);
      if (long) {
        setValue("longitude", long);
      }
      if (lat) {
        setValue("latitude", lat);
      }

      if (propertyDetails.address?.community) {
        setValue("community", {
          value: propertyDetails.address?.community,
          label: propertyDetails.address?.community,
        });
      }
      
    }
  }, [propertyDetails]);

  const onSubmit = async (val) => {
   
    const postJson = {
      buildingName: val?.buildingName,
      propertyReferenceNumber: val.propertyReferenceNumber,
      propertyCode: val.propertyCode,
      propertyAdmin:val?.propertyAdmin?.value,
      erpLaName	:val?.propertyAdmin?.label,
      address: {
        addressLine1: val.addressLine1,
        addressLine2: val.addressLine2,
        emirates: val.emirates.value,
        country: val.country.value,
        community: val.community.value,
        latitude: val.latitude,
        longitude: val.longitude,
      },
    };

    try {
      await axios.patch(
        `${propertyService}/property/${propertyDetails.propertyId}`,
        postJson
      );
      message.success("Property Details Updated");
      setNoEdit(true);
    } catch (e) {
      // console.log("error", e);
      const { response } = e;
      message.error(response.data.error);
      setNoEdit(true);
    }
  };

  return (
    <>
      <Form
        layout="vertical"
        name="bookingform"
        onFinish={handleSubmit(onSubmit)}
      >
        <Card
          bordered={false}
          bodyStyle={{ padding: "16px 32px 0" }}
          headStyle={{ padding: "16px 32px 0", borderBottom: 0 }}
          title={
            <Title level={5} className="ag-primary mb-0">
              General Information
            </Title>
          }
          extra={[
            <Button
              key={1}
              type="link"
              className={`p-0 no-border no-shadow ${noEdit && "d-none"}`}
              icon={<CloseCircleOutlined />}
              onClick={() => setNoEdit(true)}
              htmlType="button"
            >
              Cancel
            </Button>,
            <Button
              key={2}
              type="link"
              className={`p-0 no-border no-shadow ${noEdit && "d-none"}`}
              icon={<CheckCircleFilled />}
              htmlType="submit"
            >
              Save
            </Button>,
            <Button
              key={3}
              type="link"
              className={`p-0 no-border no-shadow ${!noEdit && "d-none"}`}
              icon={<EditOutlined />}
              onClick={() => setNoEdit(false)}
              htmlType="button"
            >
              Edit
            </Button>,
          ]}
        >
          <General
            control={control}
            errors={errors}
            setValue={setValue}
            noEdit={noEdit}
          />
        </Card>
        <Divider className="m-0" />
        <Card
          bordered={false}
          bodyStyle={{ padding: "16px 32px 0" }}
          headStyle={{ padding: "16px 32px 0", borderBottom: 0 }}
          title={
            <Title level={5} className="ag-primary mb-0">
              Property Information
            </Title>
          }
        >
          <Row gutter={24}>
            <Address
              control={control}
              errors={errors}
              setValue={setValue}
              noEdit={noEdit}
            />

            {/* <MoreDetails
              control={control}
              errors={errors}
              setValue={setValue}
              noEdit={noEdit}
              watch={watch}
              hasUnit={!unitsApi.length}
              hasComm={comm}
            /> */}
          </Row>
          {/* <FeaturesAmenities
            noEdit={noEdit}
            control={control}
            errors={errors}
            hasUnit={unitsApi.length}
            propertyDetails={propertyDetails}
            // setFeatureList={setFeatureList}
            setAmenitiesList={setAmenitiesList}
          /> */}
        </Card>
      </Form>
    </>
  );
};
