import * as action_types from "./constants";

const initialState: any = {
  allMaintenanceTypes: [],
  maintenanceTypes: [],
  maintenanceSubTypes: [],
  publicHolidays: [],
  operatingHours:{},

};


var _ = require("lodash");

export default (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {
    case action_types.GET_ALL_MAINTENANCE_TYPE:
      return {
        ...state, allMaintenanceTypes: data,
        //  _.map(data, (item) => ({
        //   value: item.categoryId,
        //   label: item.name //+ " : " + item.buildingCode
        // }))
      };
    case action_types.GET_MAINTENANCE_TYPE:
      return { ...state, maintenanceTypes: data };
    case action_types.GET_MAINTENANCE_SUB_TYPE:
      return { ...state, maintenanceSubTypes: data };
    case action_types.GET_PUBLIC_HOLIDAYS:
      return { ...state, publicHolidays: data };
    case action_types.GET_OPERATING_HOURS:
      return { ...state, operatingHours: data };

    default:
      return state;
  }
};
