import { log } from "console";

const Permissions = (function () {

    // function closet(){
    //     // mypro = "YUMMY"
    //     // console.log("CLOSET",mypro);
    //     permits.map(p=>{
    //         console.log("PPP",p);
    //     })

    // }
    // closet();

    function _isAllowed(moduleName: String, operations: any[]) {
        
        const permits = JSON.parse(window.localStorage.getItem("login-response"))?.permissions?.permissions;        
        if (permits) {
            const module = permits.find(element => element.moduleName === moduleName);
            if (!module)
                return false;
            const allow = operations.reduce((am, cv) => {
                return am && module.operations[0][cv];
            }, true);

            return allow;
        }
        return false;
    }


    //  const RoleGuard = ({ moduleName, operations, children }) => {
    //     console.log("PROPPSS", moduleName, operations);
    //     return _isAllowed(moduleName, operations) ? children : "";
    // }
    return {
        isAllowed: _isAllowed,
    }
})();


export default Permissions;
