import React, { useState, useEffect, Fragment } from "react";
import { Popup } from "../../atoms/Popup";
import { Card, Row, Col, Typography, Button, Image, Upload, Space, Radio } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import PdfViewer from "../../molecules/PDFViewer/pdfView";
// import { getPresignedImage } from "../../modules/Properties/AddProperty/ducks/actions/addInfoAction";
import { ReactSelectField } from "../../atoms/FormElement";
import { getPresignedImage } from "../../../utils/media";
import { useForm } from "react-hook-form";

const RequirDoc = (props) => {
  const [visible, Setvisible] = useState(false);
  const { title, documentHandler, document, docsData, tenantAccepted, uploadFiles, upKey, isRenewal } = props;
  const { Title } = Typography;
  const [viewOrDownloadUrl, setViewOrDownloadUrl] = useState("");
  const [showPdf, setShowPdf] = useState(false);
  const [expired, setExpired] = useState(true);

  const [file, setFile] = useState([]);
  const [status, setStatus] = useState("");

  const [pdfFile, setPdfFile] = useState(null);

  const onClosePopUp = () => {
    Setvisible(false);
  };
  const isExistFromApi = docsData ? true : false;

  const onOpenPopUpHandler = () => {
    if (docsData && document?.docType === "pdf") {
      // console.log("docs", viewOrDownloadUrl)
      setPdfFile(viewOrDownloadUrl);
      setShowPdf(!showPdf);
    } else if (
      Object.keys(file).length &&
      !Array.isArray(file) &&
      // @ts-ignore
      file?.name.includes("pdf")
    ) {
      let urlf = window.URL.createObjectURL(file["originFileObj"]);
      setPdfFile(urlf);
      setShowPdf(!showPdf);
    } else {
      Setvisible(true);
    }
  };


  useEffect(() => {
    const stat = document?.status;
    if (stat !== "pending") {
      if (stat === "approved")
        setStatus("accept");
      else if (stat === "rejected")
        setStatus("reject");
    } else {
      setStatus("pending");
    }
  }, [document?.status]);

  useEffect(() => {
    if (document?.isExpired == true)
      setExpired(true);
    else
      setExpired(false);
  }, [document?.isExpired]);


  const getUrl = async (data) => {
    const url = await getPresignedImage([data]);
    setViewOrDownloadUrl(url[0]);
  };

  const downloadUrl = async () => {
    const url = await getPresignedImage([docsData],false);
    if(url)
    window.location.href = url[0];
  }


  // const getMedia = async (totalImages) => {
  //   const result = await getPresignedImage(totalImages); //change
  //   console.log("getting media");
  //   if (result && result.length > 0) {
  //   } else {
  //     message.error("Something wrong with Media");
  //   }
  //   return result;
  // };


  const onAction = (data) => {
    documentHandler({ ...document, action: data });
  };


  useEffect(() => {
    if (docsData) {
      getUrl(docsData);
    }
  }, [docsData]);

  const popupContent = {
    closable: true,
    visibility: visible,
    content: (
      <Image
        preview={false}
        width="100%"
        style={{ padding: "20px" }}
        src={viewOrDownloadUrl}
      />
    ),
    width: 650,
    onCancel: onClosePopUp,
  };

  // const fileProps = {
  //   className: "file-upload",
  //   name: "file",
  //   multiple: false,
  //   showUploadList: false,
  //   onChange(info) {
  //     setFile(info.file);
  //     uploadFiles(upKey, info.file.originFileObj);
  //     if (Object.keys(info.file).length && !Array.isArray(info.file)) {
  //       const url = window.URL.createObjectURL(info.file.originFileObj);
  //       setViewOrDownloadUrl(url);
  //     }
  //   },
  //   file,
  // };

  return (
    <Fragment>
      <PdfViewer
        pdf={pdfFile}
        method={null}
        onCancel={() => setShowPdf(false)}
        visible={showPdf}
      />
      <Card bordered={false}>
        <Row gutter={24} justify="space-between" align="middle">
          <Col span={12} style={{ display: "flex" }}>
            <FileTextOutlined className="require-doc" />
            <Title level={5}>{`${title}`}</Title>
            {/* <ReactSelectField
                    label=""
                    classes="mb-0"
                    fieldname={`fixedDocs[${title}]`}
                    control={props.control}
                    iProps={{ placeholder: "Document Type" }}
                    initValue={{label:title,value:title}}
                    // selectOption={additionalDocuments}
                  /> */}
          </Col>
          <Col>
            {(isExistFromApi || viewOrDownloadUrl) && (
              <>
                <Button type="link" onClick={()=>downloadUrl()}> 
                {/* // href={viewOrDownloadUrl} target="_blank" */}
                  Download
                </Button>
                <Space>
                  <Button type="link" onClick={onOpenPopUpHandler}>
                    View
                  </Button>
                </Space>
              </>
            )}

            {/* {upKey != "signed" && (
                <Upload {...fileProps}>
                  <Button type="link" className="p-0 ag-primary">
                    {isExistFromApi ? "Change" : "Upload"}
                  </Button>
                </Upload>
              )} */}

            {((expired == true && status == "pending") ? "Document expired" : "")}
            {isRenewal != true && (
              (isExistFromApi || viewOrDownloadUrl) ? (
                <>
                  <Radio.Group
                    className=""
                    name="radiogroup"
                    value={status}
                    disabled={tenantAccepted}
                    onChange={(e) => onAction(e.target.value)}
                  >
                    <Radio className="radio-group-reject" value="reject">
                      Reject
                    </Radio>
                    <Radio className="radio-group-accept" value="accept">
                      Accept
                    </Radio>
                  </Radio.Group>
                </>
              ) : status == "pending" ? (<Radio className="radio-group-pending" value="Pending" checked={true}>
                Pending
              </Radio>) : ""
            )}
          </Col>
        </Row>
      </Card>
      <Popup {...popupContent} />
    </Fragment>
  );
};

export default RequirDoc;
