import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import {
  propertyService,
  leaseService,
  baseContractService,
  authService,
} from "../../../../configs/constants";
import { message } from "antd";

export const getNotices = (limit, page, status) => {
  return async (dispatch) => {
    let tenants = "";
    let url = "";
    if (status) {
      tenants = `${leaseService}/user/notices`;
      url = `${tenants}?limit=${limit}&page=${page}&status=${status}`;
    } else {
      tenants = `${leaseService}/user/notices?limit=${limit}&page=${page}`;
      url = `${tenants}`;
    }

    const {
      data: { result },
    } = await axios.get(url);
    dispatch({
      type: action_types.GET_NOTICES,
      data: result,
    });
  };
};
