export const setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  
  export const getCookie = (cname) => {
    const name = cname + "=";
    const ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  
  export const checkCookie = (cname) => {
    const user = getCookie(cname);
    if (user != "") {
      return true;
    } else {
     /*  user = prompt("Please enter your name:", "");
      if (user != "" && user != null) {
        setCookie("username", user, 365);
      } */
      return false;
    }
  }

  export const deleteCookie = (cname) => {
    document.cookie = cname + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  export const validateEmail = (email: string) => {
    const expression = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,3}$/;
    return expression.test(email?.toLowerCase());
  };