import React, { memo, useState, useEffect, Fragment } from "react";

// others
import dayjs from "dayjs";
import { Loading } from "./index";
import { useForm } from "react-hook-form";
import Button from "../../../../molecules/Button";
import { getBookingDetail } from "../../ducks/actions";
import { useDispatch, useSelector } from "react-redux";
import { updatePdcService } from "../../ducks/services";
import { Add, Empty, Delete } from "../../../../../assets/svg";
import {
  Col,
  Row,
  Form,
  Space,
  message,
  Typography,
  Button as IButton,
} from "antd";
import {
  DateField,
  InputField,
  ReactSelectField,
  InputNumberField,
} from "../../../../atoms/FormElement";
import {
  payment_modes,
  payment_types,
  numberWithCommas,
  filterPaymentPayload,
  autoFillAdditionalLineItems,
} from "../utils";

const { Text } = Typography;

interface IProps {
  renewal: boolean;
  parentState: (obj: object) => void;
}

let initialState = {
  visible: false,
  paymentData: {
    actualAmount: 0,
    description: "",
    modeOfPayment: payment_modes[1].value,
  },
  paymentSchedule: [
    {
      actualAmount: 0,
      description: "",
      modeOfPayment: payment_modes[1].value,
    },
  ],
};

var _ = require("lodash");

const index = (props: IProps) => {
  // hooks initialization
  const dispatch: any = useDispatch();

  // redux states
  const { bookingDetails } = useSelector((state: any) => state.newBooking);

  const {
    watch,
    control,
    setValue,
    register,
    getValues,
    resetField,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // states
  let [{ visible, paymentData, paymentSchedule }, setState] =
    useState(initialState);

  const updateState = (state: {}) =>
    setState((prevState: any) => ({ ...prevState, ...state }));

  useEffect(() => {
    const subscription = watch(
      (value, { name }) =>
        paymentSchedule?.length > 0 && handleFields(name, value[name])
    );
    return () => subscription.unsubscribe();
  }, [watch, paymentSchedule]);

  const handleFields = (name: string, value: any) => {
    const splitName = name.split("-");
    const index = parseInt(splitName[1]);
    const copy: Array<any> = [...paymentSchedule];

    switch (splitName[0]) {
      case "vat":
        const actualAmount = parseInt(
          getValues(`actualAmount-${index}`)?.toString()?.replace(",", "")
        );
        const totalVat: number = (actualAmount * parseInt(value)) / 100;

        if (actualAmount)
          setValue(`totalAmount-${index}`, actualAmount + totalVat);
        break;
      case "actualAmount":
        const vat = parseInt(getValues(`vat-${index || 0}`));

        if (vat) {
          const totalVat: number = (parseInt(value) * vat) / 100;
          setValue(`totalAmount-${index}`, parseInt(value) + totalVat);
        }
        break;
      case "modeOfPayment":
        if (value?.value === "cash") {
          register(`documentNo-${index}`, {
            required: false,
            value: "",
            disabled: true,
          });
          register(`bankName-${index}`, {
            required: false,
            value: "",
            disabled: true,
          });
          register(`chequeDate-${splitName[1]}`, {
            value: "",
            disabled: true,
          });
          resetField(`chequeDate-${splitName[1]}`);
          resetField(`bankName-${index}`, { defaultValue: "" });
          resetField(`documentNo-${index}`, { defaultValue: "" });
        } else {
          setValue(
            `chequeDate-${splitName[1]}`,
            dayjs(bookingDetails?.leaseTerms?.leaseStartDate)
          );
        }

        copy[index].modeOfPayment = value.value;

        updateState({ paymentSchedule: copy });
        break;
    }
  };

  const onSubmit = async (data: any) => {
    updateState({ visible: true });

    let dataCopy = {
      updateDataOnly: true,
      leaseId: bookingDetails?.leaseId,
      paymentSchedule: filterPaymentPayload(data, paymentSchedule),
    };

    try {
      const response = await updatePdcService(dataCopy);
      await dispatch(getBookingDetail(bookingDetails?.leaseId));

      updateState({ visible: false });
      message.success(response.data.message);

      props.parentState({
        modalPayload: { iKey: "", title: "", extraText: "", open: false },
      });
    } catch (error) {
      updateState({ visible: false });
      message.error(error.response?.data?.message);
    }
  };

  const handleSelector = (data: any, index: number) => {
    const obj = autoFillAdditionalLineItems(
      data.value,
      bookingDetails?.depositPayments
    );

    if (obj) {
      const vat = obj?.vatInPercentage || 0;
      const amount = obj?.deafultAmount || "";

      setValue(`vat-${index}`, vat);
      setValue(`actualAmount-${index}`, amount);

      if (vat && amount) {
        const totalVat = (amount * vat) / 100;
        setValue(`totalAmount-${index}`, amount + totalVat);
      }
    } else {
      setValue(`vat-${index}`, 0);
      setValue(`totalAmount-${index}`, "");
      setValue(`actualAmount-${index}`, "");
    }
  };

  const handlePayment = (index?: number) => {
    let copy = [...paymentSchedule];

    if (index) copy.splice(index, 1);
    else if (index == 0) copy = [];
    else copy.unshift({ ...paymentData });

    updateState({ paymentSchedule: copy });
  };

  const textStyle = { fontSize: "12px" };
  const isPayment = paymentSchedule.length > 0;

  return (
    <Form
      layout="vertical"
      name="bookingform"
      scrollToFirstError
      onFinish={handleSubmit(onSubmit)}
      className="bf-main view-booking edit-payment-schedule"
    >
      <Loading visible={visible} />

      {isPayment && (
        <Row gutter={[24, 24]} style={{ letterSpacing: "1.2px" }}>
          <Col span={3}>
            <Text style={textStyle} className="fontWeight500 ag-default mb-1">
              DESCRIPTION
            </Text>
          </Col>
          <Col span={2}>
            <Text style={textStyle} className="fontWeight500 ag-default mb-2">
              AMOUNT
            </Text>
          </Col>
          <Col span={2}>
            <Text style={textStyle} className="fontWeight500 ag-default mb-2">
              VAT %
            </Text>
          </Col>
          <Col span={3}>
            <Text style={textStyle} className="fontWeight500 ag-default mb-2">
              TOTAL AMOUNT
            </Text>
          </Col>
          <Col span={3}>
            <Text style={textStyle} className="fontWeight500 ag-default mb-2">
              PAYMENT MODE
            </Text>
          </Col>
          <Col span={2}>
            <Text style={textStyle} className="fontWeight500 ag-default mb-2">
              DOCUMENT NO
            </Text>
          </Col>
          <Col span={2}>
            <Text style={textStyle} className="fontWeight500 ag-default mb-2">
              BANK NAME
            </Text>
          </Col>
          <Col span={3}>
            <Text style={textStyle} className="fontWeight500 ag-default mb-2">
              DUE DATE
            </Text>
          </Col>
          <Col span={3}>
            <Text style={textStyle} className="fontWeight500 ag-default mb-2">
              CHEQUE DATE
            </Text>
          </Col>
          <Col span={1}></Col>
        </Row>
      )}

      {paymentSchedule.map((item: any, index: number) => {
        const description = item.description
          ? payment_types.filter((ele: any) => ele.value === item.description)
          : "";

        const modeOfPayment = item?.modeOfPayment
          ? payment_modes.filter(
              (ele: any) => ele.value === item?.modeOfPayment
            )
          : payment_modes[1];

        return (
          <Fragment>
            <Row gutter={24} style={{ position: "relative" }}>
              <Col span={3}>
                <ReactSelectField
                  control={control}
                  isRequired={true}
                  initValue={description}
                  rules={{ required: "required" }}
                  fieldname={`description-${index}`}
                  onChange={(e: any) => handleSelector(e, index)}
                  validate={errors[`description-${index}`] && "error"}
                  selectOption={
                    props.renewal
                      ? payment_types
                      : payment_types.filter(
                          (item) => item.value !== "renewal_fee"
                        )
                  }
                />
              </Col>

              <Col span={2}>
                <InputNumberField
                  control={control}
                  isRequired={true}
                  iProps={{ min: 1 }}
                  rules={{ required: "required" }}
                  fieldname={`actualAmount-${index}`}
                  validate={errors[`actualAmount-${index}`] && "error"}
                  initValue={numberWithCommas(item?.actualAmount || "")}
                />
              </Col>

              <Col span={2}>
                <InputNumberField
                  control={control}
                  isRequired={true}
                  iProps={{ min: 0 }}
                  fieldname={`vat-${index}`}
                  rules={{ required: "required" }}
                  validate={errors[`vat-${index}`] && "error"}
                  initValue={numberWithCommas(item?.vat || "")}
                />
              </Col>

              <Col span={3}>
                <InputNumberField
                  disabled={true}
                  control={control}
                  isRequired={true}
                  rules={{ required: "required" }}
                  fieldname={`totalAmount-${index}`}
                  validate={errors[`totalAmount-${index}`] && "error"}
                  initValue={numberWithCommas(item?.totalAmount || "")}
                />
              </Col>

              <Col span={3}>
                <ReactSelectField
                  control={control}
                  isRequired={true}
                  initValue={modeOfPayment}
                  selectOption={payment_modes}
                  rules={{ required: "required" }}
                  fieldname={`modeOfPayment-${index}`}
                  validate={errors[`modeOfPayment-${index}`] && "error"}
                />
              </Col>

              <Col span={2}>
                <InputField
                  control={control}
                  initValue={item?.documentNo}
                  fieldname={`documentNo-${index}`}
                  disabled={item?.modeOfPayment === "cash"}
                  validate={errors[`documentNo-${index}`] && "error"}
                />
              </Col>

              <Col span={2}>
                <InputField
                  control={control}
                  initValue={item?.bankName}
                  fieldname={`bankName-${index}`}
                  disabled={item?.modeOfPayment === "cash"}
                  validate={errors[`bankName-${index}`] && "error"}
                />
              </Col>

              <Col span={3}>
                <DateField
                  control={control}
                  isRequired={true}
                  rules={{ required: "required" }}
                  fieldname={`dueDate-${index}`}
                  validate={errors[`dueDate-${index}`] && "error"}
                  initValue={bookingDetails?.leaseTerms?.leaseStartDate ? dayjs(
                    bookingDetails?.leaseTerms?.leaseStartDate,
                    "YYYY-MM-DD"
                  ) : ""}
                />
              </Col>

              <Col span={3}>
                <DateField
                  control={control}
                  fieldname={`chequeDate-${index}`}
                  disabled={item?.modeOfPayment === "cash"}
                  validate={errors[`chequeDate-${index}`] && "error"}
                  initValue={bookingDetails?.leaseTerms?.leaseStartDate ? dayjs(
                    bookingDetails?.leaseTerms?.leaseStartDate,
                    "YYYY-MM-DD"
                  ) : ""}
                />
              </Col>

              <Col span={1}>
                <IButton
                  style={{ marginTop: "4px" }}
                  className="delete-payment-button"
                  onClick={() => handlePayment(index)}
                >
                  <Delete width="16px" height="16px" />
                </IButton>
              </Col>
            </Row>
          </Fragment>
        );
      })}

      {!isPayment && (
        <Row style={{ justifyContent: "center" }}>
          <Empty
            width="200px"
            height="200px"
            style={{ alignContent: "center" }}
          />
        </Row>
      )}

      <Col span={24}>
        <Button
          label="Add Payment"
          className="add-payment-button"
          onClick={() => handlePayment()}
          icon={
            <Add width="20px" height="20px" style={{ marginRight: "12px" }} />
          }
        />
      </Col>

      {isPayment && (
        <Row gutter={24} className="footer">
          <Space size="middle">
            <Button
              label="Cancel"
              className="bf-discard-button"
              onClick={() =>
                props.parentState({ modalPayload: { open: false } })
              }
            />

            <Button
              type="submit"
              label="Update"
              className="cc-fotter_next-button"
            />
          </Space>
        </Row>
      )}
    </Form>
  );
};

export default memo(index);
