/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Typography, Form, Button, message } from "antd";
import LoginTemplate from "../Login/LoginTemplate";
import { UserOutlined } from "@ant-design/icons";
import { authService } from "../../../configs/constants";
import axios from "axios";
import { useForm } from "react-hook-form";
import { InputField } from "../../atoms/FormElement";
import OtpInput from "react-otp-input";
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";
import { SITEKEY } from "../../../configs/constants";

const { Text, Title } = Typography;

const OTP = (props) => {
  const [loading, setLoading] = useState(false);
  const [invalidUserFlag, setInvalidUserFlag] = useState(false);
  const [onSuccessCodeSent, setOnSuccessCodeSent] = useState(false);
  const [otp, setOTP] = useState<any>();
  const navigate = useNavigate();
  const location = useLocation();
  const { control, handleSubmit, formState:{errors} } = useForm();
  const numInputs = 4;
  const refReCaptcha = useRef(null);
  const [tokenCapcha, setReCapcha] = useState();

  const handleChange = (values) => {
    setOTP(values);
  };

  // console.log("PROPS:",location);

  const resendCode = async () => {
    setLoading(true);
    const headers = {
      application: 1,
    };

    const postJson = {
      email: location?.["state"]?.email, 
      // recapcha: tokenCapcha,
      logintype : "email",
      usertype : location?.["state"]?.usertype,
    };
    const url = `${authService}/user/signup`;
    try {
      const response = await axios.post(url, postJson, { headers: headers });
      setLoading(false);
      message.success("OTP sent successfully!");
    } catch (e) {
      // refReCaptcha.current.execute();
      setLoading(false);
      const { response } = e;
      console.log('response', response);
      
      message.error(response?.data?.error);
    }
  };

  // useEffect(() => {
  //   loadReCaptcha(SITEKEY);
  // }, []);

  const onFinish = async (values) => {
    setLoading(true);
    const headers = {
      application: 1,
    };



    const postJson = {
      // username: props?.location?.["state"]?.userEmail,
      // code: otp,
      userid: location?.["state"]?.userid,
      otp: otp,
      usertype: location?.["state"]?.usertype,
      // recapcha: tokenCapcha,
    };
    const url = `${authService}/user/verifyOtp`;
    try {
      const response = await axios.post(url, postJson, { headers: headers });

      // await window.localStorage.setItem(
      //   "verification_code",
      //   JSON.stringify(response?.data?.result),
      // );

      setLoading(false);
      message.success(
        "OTP verified successfully! You can create password now.",
      );
      // setCookie(
      //   "landlordUser",
      //   true,
      //   new Date(response?.data?.result?.exp).getDate(),
      // );
      navigate("/create-password",{
        state: {
          userid: response?.data?.result?.id,
          usertype: location?.["state"]?.usertype,          
        },
      });
    } catch (e) {
      console.log('e', e);
      
      setLoading(false);
      // refReCaptcha.current.execute();
      const { response } = e;
      console.log('response', response);
      console.log('data', response?.data);
      console.log('error', response?.data?.error);
      
      // console.log("reperr",response); 
      message.error(response?.data?.error);
    }
  };

  const verifyCallback = (token) => {
    // console.log("recaptch", token);
    setReCapcha(token);
  };

  return (
    <LoginTemplate load={loading}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Text className="defaultFont fontWeight600 green-color">
            Enter OTP
          </Text>
        </Col>
        <Col span={24}>
          <Form
            name="normal_login"
            className="forgot-password-form text-left"
            onFinish={handleSubmit(onFinish)}
          >
            <OtpInput
              isInputNum={true}
              value={otp}
              onChange={handleChange}
              numInputs={numInputs}
              className="inputStyle"
            />

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                size="large"
                disabled={otp?.length < numInputs || otp?.length == undefined}
              >
                Submit
              </Button>
            </Form.Item>
            {/* <ReCaptcha
              ref={refReCaptcha}
              action="main"
              sitekey={SITEKEY}
              verifyCallback={verifyCallback}
            /> */}
          </Form>
        </Col>
        <Col span={24}>
          <Title level={5} className="text-center">
            Did not receive an OTP?{" "}
            <Button
              type="link"
              className="p-0 defaultFont"
              onClick={resendCode}
            >
              Click to Resend.
            </Button>
          </Title>
        </Col>
      </Row>
    </LoginTemplate>
  );
};

export default OTP;
