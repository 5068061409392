import React from "react";

const index = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.41734 9.16636H9.16734V6.41636L5.959 3.20803C8.06225 2.20353 10.5703 2.63384 12.2184 4.28197C13.8665 5.9301 14.2968 8.43811 13.2923 10.5414L18.7923 16.0414C19.5517 16.8008 19.5517 18.032 18.7923 18.7914C18.0329 19.5508 16.8017 19.5508 16.0423 18.7914L10.5423 13.2914C8.43909 14.2959 5.93107 13.8655 4.28294 12.2174C2.63481 10.5693 2.20451 8.06127 3.209 5.95803L6.41734 9.16636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.41734 9.16636H9.16734V6.41636L5.959 3.20803C8.06225 2.20353 10.5703 2.63384 12.2184 4.28197C13.8665 5.9301 14.2968 8.43811 13.2923 10.5414L18.7923 16.0414C19.5517 16.8008 19.5517 18.032 18.7923 18.7914C18.0329 19.5508 16.8017 19.5508 16.0423 18.7914L10.5423 13.2914C8.43909 14.2959 5.93107 13.8655 4.28294 12.2174C2.63481 10.5693 2.20451 8.06127 3.209 5.95803L6.41734 9.16636" stroke="white" stroke-opacity="0.6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export default index;
