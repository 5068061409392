import React, { useEffect, useState } from "react";
import moment from "moment";
import { Col } from "antd";
import {
  InputField,
  DateField,
  ReactSelectField,
} from "../../../atoms/FormElement";
import { useWatch } from "react-hook-form";
import { installmentsList } from "../../Properties/ducks/constants";

const initState = {
  annualBaseRent: "",
  noOfInstallment: "",
  nonRefund: "",
  bookingStart: "",
  bookingEnd: "",
  termsAndCondition: "",
  moveInDate: "",
  poBox: "",
};
const BookingTerms = ({
  setValue,
  control,
  errors,
  details,
  getValues,
  termsConditions,
  bookingFormDetails,
}) => {
  const [stateData, setStateData] = useState(initState);

  useEffect(() => {
    if (details || bookingFormDetails) {
      const obj = {
        annualBaseRent:
          details?.annualBaseRent || bookingFormDetails?.annualBaseRent,
        externalAgent: details?.externalAgent || bookingFormDetails?.externalAgent,
        noOfInstallment: {
          label: details?.noOfInstallments || bookingFormDetails?.installment,
          value: details?.noOfInstallments || bookingFormDetails?.installment,
        },
        nonRefund:
          details?.nonRefundableAmount ||
          bookingFormDetails?.nonRefundableAmount,
        bookingStart:
          details?.bookingStartDate ||
          bookingFormDetails?.bookingDuration?.startDate,
        bookingEnd:
          details?.bookingEndDate ||
          bookingFormDetails?.bookingDuration?.endDate,
        termsAndCondition: {
          label:
            details?.terms_and_conditions ||
            bookingFormDetails?.termAndConditionsEntity,
          value:
            details?.terms_and_conditions ||
            bookingFormDetails?.termAndConditionsEntity,
        },
        moveInDate: details?.moveInDate || bookingFormDetails?.moveInDate,
        poBox: details?.poBox || bookingFormDetails?.poBox,
      };
      //@ts-ignore
      setStateData(obj);
    }
  }, [details]);

  const startDateWatch = useWatch({
    control: control,
    name: "bookingstartdate",
  });

  useEffect(() => {
    if (details) {
      if (
        details?.bookingStartDate ||
        bookingFormDetails?.bookingDuration?.startDate
      ) {
        setValue(
          "bookingstartdate",
          moment(
            details?.bookingStartDate ||
              bookingFormDetails?.bookingDuration?.startDate,
            "YYYY-MM-DD",
          ),
        );
      }
      if (
        details?.bookingEndDate ||
        bookingFormDetails?.bookingDuration?.endDate
      ) {
        setValue(
          "bookingexpirydate",
          moment(
            details?.bookingEndDate ||
              bookingFormDetails?.bookingDuration?.endDate,
            "YYYY-MM-DD",
          ),
        );
      } else {
        setValue(
          "bookingexpirydate",
          moment(
            details?.bookingStartDate ||
              bookingFormDetails?.bookingDuration?.startDate,
          ).add(14, "day"),
        );
      }
      if (details?.moveInDate || bookingFormDetails?.moveInDate) {
        setValue(
          "movedate",
          moment(
            details?.moveInDate || bookingFormDetails?.moveInDate,
            "YYYY-MM-DD",
          ),
        );
      }
    }
  }, [details]);

  const disableStartDate = (current) => {
    return (
      current &&
      current.valueOf() <
        (details?.bookingStartDate
          ? moment(details?.bookingStartDate, "YYYY-MM_DD")
          : moment().subtract(1, "day"))
    );
  };

  const disableBookingEndDate = (current) => {
    let start = getValues("bookingstartdate");
    return (
      (current && current.valueOf() < moment(start)) ||
      moment(start).add(15, "day") < current.valueOf()
    );
  };

  const disableMoveInDateDate = (current) => {
   // let startdate = getValues("bookingstartdate");
    const bookdate =
    details?.bookingStartDate ||
    bookingFormDetails?.bookingDuration?.startDate;
    return (
    (current && current.valueOf() < moment(bookdate, "YYYY-MM-DD")) ||
    (current &&
      current.valueOf() >= moment(bookdate, "YYYY-MM-DD").add(30, "day"))
    );
    // return (
    //   current &&
    //   current.valueOf() >
    //     (startdate
    //       ? current.valueOf() >
    //         moment(startdate, "YYYY-MM_DD").add(1, "month")
    //       : Date.now())
    // );
  };

  const setBookingEnddate = (startdate) => {
    setValue("bookingexpirydate", moment(startdate).add(14, "day"));
  };

  return (
    <>
      <Col span={8}>
        <InputField
          isRequired={true}
          label="Annual base rent"
          fieldname="annualBaseRent"
          control={control}
          initValue=""
          setValue={setValue}
          // valueGot={stateData?.annualBaseRent}
          valueGot={
            details?.annualBaseRent || bookingFormDetails?.annualBaseRent
          }
          validate={errors.annualBaseRent && "error"}
          validMessage={errors.annualBaseRent && errors.annualBaseRent.message}
          iProps={{ placeholder: "Enter rent amount" }}
          rules={{
            pattern: {
              value: /^[0-9]*$/,
              message: "Value should be a Number",
            },
            required: "Enter Annual base rent",
          }}
        />
      </Col>
      <Col span={8}>
        <InputField
          label="External Agent"
          fieldname="externalAgent"
          control={control}
          initValue=""
          setValue={setValue}
          valueGot={
            details?.externalAgent || bookingFormDetails?.externalAgent
          }
          iProps={{ placeholder: "Enter External Agent" }}
        />
      </Col>
      <Col span={8}>
        <ReactSelectField
          label="No. of Instalments"
          fieldname="installment"
          control={control}
          setValue={setValue}
          isRequired={true}
          valueGot={
            (details?.noOfInstallments || bookingFormDetails?.installment) && {
              label:
                details?.noOfInstallments || bookingFormDetails?.installment,
              value:
                details?.noOfInstallments || bookingFormDetails?.installment,
            }
          }
          validate={errors.installment && "error"}
          validMessage={errors.installment && errors.installment.message}
          rules={{ required: "Select No. of Instalments Proposed" }}
          initValue=""
          iProps={{ placeholder: "Select No. of Instalments" }}
          selectOption={installmentsList}
        />
      </Col>
      <Col span={8}>
        <InputField
          isRequired={true}
          label="Non-Refundable booking amount"
          fieldname="nonRefundable"
          control={control}
          initValue={0}
          setValue={setValue}
          // valueGot={stateData?.nonRefund}
          valueGot={
            details?.nonRefundableAmount ||
            bookingFormDetails?.nonRefundableAmount
          }
          validate={errors.nonRefundable && "error"}
          validMessage={errors.nonRefundable && errors.nonRefundable.message}
          iProps={{ placeholder: "Enter rent amount" }}
          rules={{
            pattern: {
              value: /^[0-9]*(\.[0-9]{0,2})?$/,
              message:
                "Value should be a Number and not greater than 2 decimal places",
            },
            required: "Enter Non-Refundable booking amount",
          }}
        />
      </Col>
      <Col span={8}>
        <DateField
          isRequired={true}
          label="Booking start date"
          fieldname="bookingstartdate"
          control={control}
          // setValue={setValue}
          // valueGot={
          //   stateData?.bookingStart &&
          //   moment(stateData?.bookingStart, "DD-MM-YYYY")
          // }
          // validate={errors.bookingstartdate && "error"}
          // validMessage={
          //   errors.bookingstartdate && errors.bookingstartdate.message
          // }
          // onChange={(e) => setBookingEnddate(e)}
          initValue=""
          rules={{
            required: "Enter Booking start date",
            setValueAs: (value) => value && value.toString(),
          }}
          iProps={{
            disabled: true,
            // placeholder: "DD-MM-YYYY",
            format: "DD-MM-YYYY",
            // disabledDate: disableStartDate,
          }}
        />
      </Col>
      <Col span={8}>
        <DateField
          isRequired={true}
          label="Booking End date"
          fieldname="bookingexpirydate"
          control={control}
          // validate={errors.bookingexpirydate && "error"}
          // validMessage={
          //   errors.bookingexpirydate && errors.bookingexpirydate.message
          // }
          initValue=""
          rules={{
            required: "Enter Booking expiry date",
            setValueAs: (value) => value && value.toString(),
          }}
          iProps={{
            disabled: true,
            // placeholder: "DD-MM-YYYY",
            format: "DD-MM-YYYY",
            // disabledDate: disableBookingEndDate,
          }}
        />
      </Col>
      <Col span={8}>
        <ReactSelectField
          isRequired={true}
          label="Legal Entity"
          fieldname="termsCondition"
          control={control}
          rules={{
            required: "Please Select Legal Entity",
          }}
          setValue={setValue}
          // valueGot={stateData?.termsAndCondition}
          valueGot={
            (details?.terms_and_conditions ||
              bookingFormDetails?.termAndConditionsEntity) && {
              label:
                details?.terms_and_conditions?.name ||
                bookingFormDetails?.termAndConditionsEntity.name,
              value:
                details?.terms_and_conditions?.id ||
                bookingFormDetails?.termAndConditionsEntity.id,
            }
          }
          validate={errors.termsCondition && "error"}
          iProps={{
            placeholder: "Select one",
            isDisabled:
              details?.terms_and_conditions ||
              bookingFormDetails?.termAndConditionsEntity
                ? true
                : false,
          }}
          validMessage={errors.termsCondition && errors.termsCondition.message}
          selectOption={termsConditions}
        />
      </Col>
      <Col span={8}>
        <DateField
          isRequired={true}
          label="Move-in date"
          fieldname="movedate"
          control={control}
          // setValue={setValue}
          // valueGot={
          //   stateData?.moveInDate && moment(stateData?.moveInDate, "DD-MM-YYYY")
          // }
          validate={errors.moveInDate && "error"}
          validMessage={errors.moveInDate && errors.moveInDate.message}
          initValue=""
          rules={{
            required: "Enter Booking expiry date",
            setValueAs: (value) => value && value.toString(),
          }}
          iProps={{
            placeholder: "DD-MM-YYYY",
            format: "DD-MM-YYYY",
            disabledDate: disableMoveInDateDate,
          }}
        />
      </Col>
      <Col span={8}>
        <InputField
          label="P.O Box"
          fieldname={`pobox`}
          control={control}
          setValue={setValue}
          valueGot={details?.poBox || bookingFormDetails?.poBox}
          initValue=""
          iProps={{ disabled: true }}
        />
      </Col>
    </>
  );
};

export default BookingTerms;
