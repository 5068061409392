import React, { useEffect, useState } from "react";
import { Table } from "antd";
import moment from "moment";

export default (props) => {
  const { approvalData } = props;

  const [apData, setApData] = useState([]);

  const getModifiedStatus = (statusName) => {
    if (statusName.toLowerCase()?.includes("draft")) {
      return "Draft";
    }
    if (statusName.toLowerCase()?.includes("confirmed")) {
      return "Awaiting Approval";
    }
    if (statusName.toLowerCase()?.includes("approved")) {
      return "Approved";
    }
    if (statusName.toLowerCase()?.includes("rejected")) {
      return "Rejected";
    }
    if (statusName.toLowerCase()?.includes("process")) {
      return "N.A";
    }
    if (statusName.toLowerCase()?.includes("booking_cancelled")) {
      return "Booking Cancelled";
    }
  };
  useEffect(() => {
    if (approvalData && approvalData.length > 0) {
      const cache = [];
      approvalData.map((e, index) => {
        cache.push({
          sno: index + 1,
          createdBy: `${e.createdBy?.first_name}  ${
            e.createdBy?.last_name ? e.createdBy?.last_name : ""
          }`,
          // managedBy: e.managedBy || "N/A",
          stage: e.stage,
          status: getModifiedStatus(e.status?.name),
          comment: e.comment,
          reason: e.reason,
          createdAt: e.createdAt,
          updatedAt: e.updatedAt,
        });
      });
      setApData(cache);
    }
  }, [approvalData]);

  const col = [
    { title: "#", dataIndex: "sno", key: "sno", width: 80 },
    { title: "Created By", dataIndex: "createdBy", key: "createdBy" },
    // { title: "Managed By", dataIndex: "managedBy", key: "managedBy" },
    { title: "Stage", dataIndex: "stage", key: "stage" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "Comment", dataIndex: "comment", key: "comment" },
    { title: "Reason", dataIndex: "reason", key: "reason" },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => moment(text).format("DD-MM-YYYY hh:mmA"),
    },
    {
      title: "Uploaded At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => moment(text).format("DD-MM-YYYY hh:mmA"),
    },
  ];

  return <Table columns={col} dataSource={apData} pagination={false} />;
};
