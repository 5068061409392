import React, { FC } from "react";
// import {
//   GoogleMap,
//   withScriptjs,
//   withGoogleMap,
//   Marker,
//   OverlayView,
// } from "react-google-maps/api";
import { GoogleMap, useJsApiLoader,OverlayView,Marker, LoadScript } from '@react-google-maps/api';
import Autocomplete, {
  ReactGoogleAutocompleteProps,
} from "react-google-autocomplete";

import Pin from "../assets/img/pin.svg";

const AutocompleteTS: FC<React.PropsWithChildren<ReactGoogleAutocompleteProps>> = Autocomplete as FC<React.PropsWithChildren<ReactGoogleAutocompleteProps>>;

declare const google: any;

const getPixelPositionOffset = (width, height) => ({
  x: -(width / 2),
  y: -(height / 2),
});


export const WrappedMap = (props) =>
  // withScriptjs<any>(
  //   withGoogleMap((props) =>
  props.address ? (
    <div className="autocomplete-textbox">
      <AutocompleteTS
        // className="autocomplete-textbox"
        // style={{ width: "100%" }}
        // bounds={{
        //   north: 25.304849,
        //   south: 25.104849,
        //   east: 55.370782,
        //   west: 55.170782,
        // }}
        options={{
          bounds: {
            north: 25.304849,
            south: 25.104849,
            east: 55.370782,
            west: 55.170782,
          },
          types: ["establishment"],
          fields: ["address_components", "geometry", "icon", "name"],
          componentRestrictions: { country: "AE" }
        }}
        // origin={{ lat: 25.304849, lng: 55.270782 }}
        onPlaceSelected={(place) => props.onPlace(place)}


      />
    </div>
  ) : 
  (
    //   <LoadScript
    //   googleMapsApiKey="YOUR_API_KEY"
    // >
    <LoadScript
    googleMapsApiKey={props?.apiKey}>
    <GoogleMap
      // defaultZoom={10}
      zoom={10}
      center={{ lat: 25.2137, lng: 55.2746 }}
      // options ={{
      //   disableDefaultUI: props.dashboard,
      // }}
      mapContainerStyle={{
        width: "100%",
        height: '400px'
      }}

    >
      {props.positions.map((position) =>
          props.dashboard === false ? (
            <OverlayView
              key={position.key}
              position={{
                lat: parseFloat(position?.latitude),
                lng: parseFloat(position?.longitude),
              }}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              getPixelPositionOffset={getPixelPositionOffset}
            >
              <div>
                {position.units && position.units.length > 0 && (
                  <p
                    style={{
                      backgroundColor: "white",
                      fontSize: 11,
                      padding: 8,
                      borderRadius: 8,
                      boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.19)",
                      marginBottom: 6,
                      marginLeft: -8,
                      color: "#1C5D3B",
                      fontWeight: 600,
                    }}
                  >
                    {`${position.units.length} unit(s)`}
                  </p>
                )}
                <div
                  style={{
                    width: 48,
                    height: 48,
                    borderRadius: "50%",
                    overflow: "hidden",
                    backgroundColor: "#fff",
                    border: "5px solid #1C5D3B",
                  }}
                >
                  {position.imgurl != "" && (
                    <img src={position.imgurl} alt="" width="48" height="48" />
                  )}
                </div>
              </div>
            </OverlayView>
          ) : (
            // <MarkerWithLabel
            //   key={position.key}
            //   position={{
            //     lat: parseFloat(position?.latitude),
            //     lng: parseFloat(position?.longitude),
            //   }}
            //   icon={{
            //     url: position.imgurl ? position.imgurl : Pin,
            //     scaledSize: new google.maps.Size(48, 48),
            //   }}
            //   labelStyle={{
            //     width: 64,
            //     backgroundColor: "white",
            //     fontSize: "11px",
            //     padding: "8px",
            //     borderRadius: 8,
            //     boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.19)",
            //   }}
            //   labelAnchor={{ x: 30, y: 80 }}

            //   // onClick={() => props.selected(position)}
            // >
            //   <div>{position?.units && position.units.length} Unit(s)</div>
            // </MarkerWithLabel>
            (<Marker
              key={position.key}
              position={{
                lat: parseFloat(position?.latitude),
                lng: parseFloat(position?.longitude),
              }}
              icon={{
                url: Pin,
                // scaledSize: new google.maps.Size(48, 48),
              }}
            />)
          ),
        )}

      {/* asdsa */}
      {/* {props.selectedPark != null && (
        <InfoWindow
          //@ts-ignore
          options={{ width: 269, maxHeight: 425 }}
          position={{
            lat: parseFloat(props.selectedPark?.firedata.text.lat),
            lng: parseFloat(props.selectedPark?.firedata.text.lng),
          }}
          onCloseClick={() => props.selected(null)}
        >
          <>
            <Space direction="vertical" size={0} className="w-100">
              <Text className="fontSize11">
                Technician id:{" "}
                {props.selectedPark?.firedata.id.replace("user-", "")}
              </Text>
              <Text
                className={`fontSize11 fontWeight600 ${
                  !props.ticketList.new && !props.ticketList.inprogress
                    ? "ag-success"
                    : "ag-primary"
                }`}
              >
                {!props.ticketList.new && !props.ticketList.inprogress
                  ? "Available"
                  : "Assigned"}
              </Text>
              <Space
                direction="vertical"
                size={16}
                align="center"
                className="w-100"
              >
                <Avatar
                  size={110}
                  gap={4}
                  style={{ fontSize: 64 }}
                  className={`${
                    !props.ticketList.new && !props.ticketList.inprogress
                      ? "greenbg"
                      : "bluebg"
                  }`}
                >
                  {(props.selectedPark?.firedata?.text?.technician_name).charAt(
                    0,
                  )}
                </Avatar>
                <Space
                  size={0}
                  direction="vertical"
                  className="w-100"
                  align="center"
                >
                  <Title
                    level={5}
                    className={`m-0 ${
                      !props.ticketList.new && !props.ticketList.inprogress
                        ? "ag-success"
                        : "ag-primary"
                    }`}
                  >
                    {props.selectedPark?.firedata.text?.technician_name}
                  </Title>
                  <Text className="fontSize11">Technician</Text>
                </Space>
              </Space>
            </Space>
            <Text className="mt-2 mb-2">Tickets</Text>
            {props.ticketList && (
              <Card
                style={{ maxHeight: 140, overflow: "auto", minHeight: 0 }}
                bodyStyle={{ background: "#EEF1FA", padding: 8 }}
              >
                {props.ticketList.new &&
                  props.ticketList.new.map((item, index) => (
                    <Text key={index} className="d-block lineHeight28">
                      Ticket: {item.case_id}
                    </Text>
                  ))}
                {props.ticketList.inprogress &&
                  props.ticketList.inprogress.map((item, index) => (
                    <Text key={index} className="d-block lineHeight28">
                      Ticket: {item.case_id}
                    </Text>
                  ))}
              </Card>
            )}
          </>
        </InfoWindow>
      )} */}
    </GoogleMap>
    </LoadScript>
  // ),
  // ),
);
