import React, { useState, useEffect } from "react";
import { Row, Col, Card, Typography, Space, Button } from "antd";
import { List } from "antd";
import { EditOutlined } from "@ant-design/icons";

import HeadingChip from "../../atoms/HeadingChip";

const { Title } = Typography;

const Panel = (props) => {
  const [tickets, setTickets] = useState([]);
  const {
    ticketlist,
    category,
    count,
    getItemDetails,
    setVisible,
    isAction,
    setTrigger,
    getCurrentSelect,
    panelName,
  } = props;
  const [color, setColor] = useState("");
  const [defaultActive, setDefaultActive] = useState(true);

  const getDetails = (item, category) => {
    if(category != "Sub-Types"){
      setColor(item.id);
      getItemDetails(item, category);
    }
  };

  useEffect(() => {
    ticketlist && setTickets(ticketlist);

    if (ticketlist !== undefined)
    if (panelName == "maintenance" && defaultActive) {
      setColor(ticketlist?.[0].id);
      setDefaultActive(false);
      getDetails(ticketlist?.[0], "Maintenance Types");
      // console.log("TICKELIST",ticketlist)
      } else if (panelName == "sub_type") {
        setColor(ticketlist?.[0]?.id);
      // console.log("SUBTICKELIST",ticketlist)

      }

    return () => setTickets([]);
  }, [ticketlist]);

  const showModal = (item) => {
    if (category === "Sub-Types") setTrigger(1);
    else setTrigger(0);

    getCurrentSelect({ ...item, ...{ type: category } });

    setVisible(true);
  };

  // useEffect(() => {
  //   if(ticketlist?.[0]?.id){
  //     getItemDetails(ticketlist?.[0], 'Maintenance Types')
  //     console.log('ticketlist?.[0].id',ticketlist?.[0])
  //   }
  // }, [ticketlist?.[0]?.id])

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <HeadingChip heading={category} value={count} />
      </Col>
      <Col span={24}>
        <Card
          bordered={false}
          className="ag-nospace-body"
          style={{ borderRadius: 4, height: 500, overflowY: "auto" }}
        >
          <Space size={24} direction="vertical" className="w-100">
            {tickets.length > 0 ? (
              <>
                <List
                  itemLayout="horizontal"
                  dataSource={tickets}
                  renderItem={(item, index) => (
                    <List.Item
                      key={item.id}
                      className={(item.id === color && category != "Sub-Types") ? "activec" : ""}
                      actions={isAction ? [
                        <Button
                          type="link"
                          className="ag-green"
                          key="list-loadmore-edit"
                          onClick={() => showModal({...item,index})}
                        >
                          <EditOutlined style={{ fontSize: 22 }} />
                        </Button>,
                      ] : []}
                    >
                      <List.Item.Meta
                        title={
                          <Button
                            type="link"
                            className="Sentence  ant-typography"
                            onClick={() => {
                              getDetails({...item,index}, category);
                            }}
                          >
                          {item?.title ?? item?.categoryId}
                           &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                           {item?.timeInMin ? item?.timeInMin + " mins"  : ""}
                          </Button>
                        }
                      />
                    </List.Item>
                  )}
                />
              </>
            ) : (
              <Title
                level={5}
                className="highlight ag-primary mb-0 p-1 text-center"
              >
                There are currently no data.
              </Title>
            )}
          </Space>
        </Card>
      </Col>
    </Row>
  );
};

export default Panel;
