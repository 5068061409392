import React from "react";

const index = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.74219 3.11328L9.39844 5.92578C9.60938 6.10156 9.75 6.34766 9.75 6.59375V8.49219L13.582 12.3242C14.6016 11.832 15.8672 12.0078 16.7109 12.8516L20.6484 16.7891C21.1055 17.2109 21.1055 17.9492 20.6484 18.3711L18.3984 20.6211C17.9766 21.0781 17.2383 21.0781 16.8164 20.6211L12.8789 16.6836C12.0352 15.8398 11.8594 14.5391 12.3867 13.5195L8.55469 9.6875H6.62109C6.375 9.6875 6.12891 9.58203 5.95312 9.37109L3.14062 5.71484C2.89453 5.36328 2.92969 4.90625 3.24609 4.58984L4.65234 3.18359C4.93359 2.90234 5.42578 2.86719 5.74219 3.11328ZM3.80859 16.1914L8.625 11.375L9.82031 12.5703L5.00391 17.3867C4.79297 17.5977 4.6875 17.8789 4.6875 18.1602C4.6875 18.7578 5.17969 19.25 5.77734 19.25C6.05859 19.25 6.33984 19.1445 6.55078 18.9336L10.9453 14.5391C10.9102 15.207 11.0508 15.875 11.3672 16.5078L7.74609 20.1289C7.21875 20.6562 6.51562 20.9375 5.77734 20.9375C4.23047 20.9375 3 19.707 3 18.1602C3 17.4219 3.28125 16.7188 3.80859 16.1914ZM19.2773 8.70312L18.3984 9.61719C17.9766 10.0391 17.4141 10.25 16.8164 10.25H15.9375C14.6719 10.25 13.6875 9.26562 13.6875 8V7.12109C13.6875 6.52344 13.8984 5.96094 14.3203 5.53906L15.2344 4.66016C13.125 4.73047 11.4375 6.45312 11.4375 8.5625V8.59766L10.875 8.03516V6.59375C10.875 6.24219 10.7695 5.89062 10.5938 5.60938C11.5781 4.02734 13.3359 2.9375 15.375 2.9375C16.2188 2.9375 17.0625 3.14844 17.8008 3.5C18.1172 3.67578 18.1523 4.09766 17.9062 4.34375L15.5156 6.73438C15.4102 6.83984 15.375 6.98047 15.375 7.12109V8.03516C15.375 8.31641 15.6211 8.59766 15.9375 8.59766H16.8164C16.957 8.59766 17.0977 8.52734 17.2031 8.42188L19.5938 6.03125C19.8398 5.78516 20.2617 5.82031 20.4375 6.13672C20.7891 6.875 21 7.71875 21 8.59766C21 10.4609 20.0508 12.1484 18.6094 13.168L17.5195 12.043C17.4844 12.0078 17.4492 11.9727 17.4141 11.9375C18.5039 11.2695 19.2422 10.0742 19.2773 8.70312Z" fill="#24435D" />
        </svg>
    );
};

export default index;
