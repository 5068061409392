import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { authService } from "../../../configs/constants";
import {
  Row,
  Col,
  Typography,
  Form,
  Select,
  Button,
  Popover,
  Progress,
  message,
} from "antd";
import { useForm, Controller, useWatch } from "react-hook-form";
import axios from "../../../utils/axiosInceptor";
import LoginTemplate from "../Login/LoginTemplate";
import TermsAndCondition from "./components/TermsAndCondition";
import { Popup } from "../../atoms/Popup";
import { setCookie } from "../../../utils/helper";
import {
  InputCheckbox,
  InputField,
  InputPassword,
  SelectField,
} from "../../atoms/FormElement";
import { ReCaptcha, loadReCaptcha } from "react-recaptcha-v3";
import { SITEKEY } from "../../../configs/constants";

export const SignUp = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [tokenCapcha, setReCapcha] = React.useState();
  const refReCaptcha = useRef(null);

  // const { username, password, email } = useSelector(
  //   (state) => ({
  //     username: state.signup.username,
  //     password: state.signup.password,
  //     email: state.signup.email,
  //   }),
  //   shallowEqual,
  // );

  const verifyCallback = (token) => {
    // console.log("recaptch", token);
    setReCapcha(token);
  };

  const dispatch:any = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    formState,
    getValues,
    setValue,
    reset,
  } = useForm({ mode: "onChange" });
  let checkterms = useWatch({ control, name: "terms", defaultValue: false });
  let checkPassword = watch("password");

  const { Title, Paragraph, Text } = Typography;

  useEffect(() => {
    setValue("email", props.location["state"].userEmail);
    const phoneValue = props?.location["state"]?.userPhone;
    const phoneNumber = phoneValue.replace(/\D/g, "").slice(-9);
    setValue("phone", phoneNumber);
    loadReCaptcha(SITEKEY);
  }, []);

  const onFinish = async (values) => {
    const { username, phone, password, email } = values;
    setLoading(true);
    const headers = {
      application: 1,
    };

    const postJson = {
      username: username,
      first_name: username,
      last_name: username,
      //password: password,
      partyId: 1,
      contact_number: phone,
      email: email,
      recapcha: tokenCapcha,
    };
    const url = `${authService}/user/register`;
    
    try {
      const response = await axios.post(url, postJson, { headers: headers });
      // await window.localStorage.setItem(
      //   "login-response",
      //   JSON.stringify(response?.data?.result),
      // );

      setLoading(false);
      // setCookie(
      //   "landlordUser",
      //   true,
      //   new Date(response?.data?.result?.exp).getDate(),
      // );
     navigate( "/otp",{
        state: {
          userEmail: email,
        },
      });
    } catch (e) {
      refReCaptcha.current.execute()
      setLoading(false);
      const { response } = e;
      message.error(response?.data?.result?.error);
    }
  };

  useEffect(() => {
    setValue("email", props.location["state"].userEmail);
    const phoneValue = props?.location["state"]?.userPhone;
    const phoneNumber = phoneValue.replace(/\D/g, "").slice(-9);
    setValue("phone", phoneNumber);
  }, []);

  const prefixSelector = (
    <SelectField
      control={control}
      fieldname="prefix"
      initValue="971"
      noStyle
      selectOption={
        <>
          <Select.Option value="971">+971</Select.Option>
          <Select.Option value="92">+92</Select.Option>
        </>
      }
    />
  );

  const content = (
    <div>
      <Progress
        percent={30}
        size="small"
        status="exception"
        showInfo={false}
        style={{ width: "272px" }}
      />
      <ul className="ag-unordered-list">
        <li className="ag-success">One Upper Case letter</li>
        <li>One Lower Case letter</li>
        <li>One number</li>
      </ul>
    </div>
  );

  const popupProps = {
    closable: false,
    visibility: visible,
    content: (
      <TermsAndCondition
        onCancel={() => setVisible(false)}
        onAccept={() => setVisible(false)}
      />
    ),
    width: 1200,
    onCancel: () => setVisible(false),
  };

  return (
    <>
      <LoginTemplate load={loading}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Text className="defaultFont fontWeight600 green-color">
              Sign up with an account
            </Text>
          </Col>
          <Col span={24}>
            <Form name="normal_signup" onFinish={handleSubmit(onFinish)}>
              <InputField
                isRequired={true}
                fieldname="username"
                control={control}
                initValue=""
                iProps={{ placeholder: "Username", size: "large" }}
                rules={{
                  required: "Please input username",
                  pattern: {
                    value: /^\S*$/,
                    message: "Input username without space!",
                  },
                }}
                validate={errors.username && "error"}
                validMessage={errors.username && errors.username.message}
              />

              <InputField
                isRequired={true}
                fieldname="email"
                control={control}
                initValue=""
                iProps={{ placeholder: "Email", size: "large" }}
                rules={{
                  pattern: {
                    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    message: "Enter valid Email Address",
                  },
                  required: "Please enter Email Address",
                }}
                validate={errors.email && "error"}
                validMessage={errors.email && errors.email.message}
              />

              {/* <Popover
                placement="rightTop"
                title="Password must contain:"
                content={content}
                trigger="click"
                visible={errors.password ? true : false}
              >
                <InputPassword
                  isRequired={true}
                  fieldname="password"
                  control={control}
                  initValue=""
                  iProps={{ placeholder: "Password", size: "large" }}
                  rules={{
                    pattern: {
                      value: /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^!&*+=]).*$/,
                      // message: "Passwords must contain at least 8 characters, 1 capital letter, 1 number and a special character",
                    },
                    required: "Please enter password",
                  }}
                  validate={errors.password && "error"}
                  // validMessage={errors.password && errors.password.message}
                />
              </Popover>
              <InputPassword
                isRequired={true}
                fieldname="confirm"
                control={control}
                initValue=""
                iProps={{ placeholder: "Confirm Password", size: "large" }}
                rules={{
                  required: "Please confirm your password!",
                  validate: (value) =>
                    value === checkPassword || "The passwords do not match",
                }}
                validate={errors.confirm && "error"}
                validMessage={errors.confirm && errors.confirm.message}
              /> */}

              <InputField
                isRequired={true}
                fieldname="phone"
                control={control}
                validate={errors.phone && "error"}
                validMessage={errors.phone && errors.phone.message}
                initValue=""
                rules={{
                  pattern: {
                    value: /^[0-9]*$/,
                    message: "Value should be a Number",
                  },
                  minLength: { value: 9, message: "Minimum 9 digits" },
                  maxLength: { value: 9, message: "Maximum 9 digits" },
                  required: "Please enter valid Mobile No.",
                  setValueAs: (value) => `+${getValues("prefix")}${value}`,
                }}
                iProps={{ size: "large", addonBefore: prefixSelector }}
              />
              <InputCheckbox
                fieldname="terms"
                label="I agree to the"
                control={control}
                validate={errors.terms && "error"}
                validMessage={errors.terms && errors.terms.message}
                initValue=""
                rules={{ required: "Should accept agreement" }}
              />
              <Button
                type="link"
                className="p-0 fontWeight600"
                onClick={() => setVisible(true)}
              >
                Terms and Conditions
              </Button>

              <Form.Item className="mb-0 mt-1 text-center">
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!checkterms}
                  className="signup-form-button"
                  size="large"
                >
                  Sign up
                </Button>
              </Form.Item>
              <ReCaptcha
                ref={refReCaptcha}
                action="main"
                sitekey={SITEKEY}
                verifyCallback={verifyCallback}
              />
            </Form>
          </Col>
          <Col span={24}>
            <Title level={5} className="text-center">
              Already have an account? <Link to="/login">Sign in here.</Link>
            </Title>
            <Paragraph className="copyright">Copyright © 2023</Paragraph>
          </Col>
        </Row>
      </LoginTemplate>
      <Popup {...popupProps} />
    </>
  );
};

export default SignUp;
