export const MODULES = {
    TENANTS: "tenant",
    PROPERTIES: "property",
    MANAGERS: "managers",
    SERVICE_PROVIDERS: "serviceProvider",
    MAINTENANCE: "maintenance",
    CONFIGURATIONS: "configurations",
    TICKETS: "tickets",
    TECHNICIANS: "technicians",
    ZONES: "zones",
    ROSTER: "roster",
    CONTRACTS:"contracts",
    USERS:"users",
    NOTICE:"notice",
    DASHBOARD: "dashboard"
}
