
import React, { FC } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import {  Spin } from 'antd';
interface IProps {}

/**
* @author
* @function @
**/

export default () => {
  return (
    <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'70vh',margin:'auto'}}>
   <div>
   <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
   </div>
    </div>
   )
 }
