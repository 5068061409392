import React, { useState, useEffect } from "react";

import { Popup } from "../../atoms/Popup";
import { EditOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import SectionHeader from "../../molecules/SectionHeader";
import DashboardLayout from "../../molecules/DashboardLayout";
import AddServiceProvider from "./component/AddServiceProvider";
import { getAssignedList, getServiceProviders } from "./ducks/actions";
import {
  Tag,
  Row,
  Col,
  Table,
  Space,
  Button,
  Typography,
  Pagination,
} from "antd";
import TruncatedTags from "../../molecules/TruncatedTags";

const { Title } = Typography;

const ServiceProvider = () => {
  const dispatch: any = useDispatch();

  const { properties } = useSelector((state: any) => state.service);
  const { assignedList } = useSelector((state: any) => state.service);
  const { serviceProviders } = useSelector((state: any) => state.service);
  const { dropDownServiceProviders } = useSelector(
    (state: any) => state.service
  );

  const [page, setPage] = useState(1);
  const [trigger, setTrigger] = useState(0);
  const [selected, setSelected] = useState();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    getList();
  }, []);



  const getList = () => {
    dispatch(getAssignedList());
    dispatch(getServiceProviders());
  };

  const onAdd = () => {
    setVisible(false);
    dispatch(getAssignedList());
    dispatch(getServiceProviders());
  };

  const onEdit = (record) => {
    setSelected(record);
    setVisible(true);
    setTrigger(1);
  };

  const popup = [
    {
      title: (
        <Title level={3} className="m-0">
          Add Service Provider
        </Title>
      ),
      content: (
        <AddServiceProvider
          onSubmit={onAdd}
          onCancel={() => setVisible(false)}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="m-0">
          Update Service Provider
        </Title>
      ),
      content: (
        <AddServiceProvider
          onSubmit={onAdd}
          editMode={true}
          selected={selected}
          onCancel={() => setVisible(false)}
        />
      ),
      width: 650,
    },
  ];

  const onCancel = () => {
    setVisible(false);
  };
  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => onCancel(),
  };

  const column = [
    {
      title: "Service Provider",
      dataIndex: "serviceProviderId",
      key: "serviceProviderId",
      width: 220,
      render: (item) => {
        return <>{_filterName("SP", item)}</>;
      },
    },
    {
      title: "Building(s)",
      dataIndex: "properties",
      key: "properties",
      width: 220,
      // className: "fixed-scroll-td",
      render: (_, { properties }) => {
        return (
            <TruncatedTags tags={properties?.map((tag) =>(_filterName("PROPERTY", tag)))} maxVisibleTags={3} />
          // <>
          //   {properties?.map((tag) => {
          //     return (
          //       <Tag className="tag-sm" key={tag}>
          //         {_filterName("PROPERTY", tag)}
          //       </Tag>
          //     );
          //   })}
          // </>
        );
      },
    },
    {
      title: "Specialities",
      children: [
        {
          title: "Residential",
          dataIndex: "residentialCategories",
          key: "residentialCategories",
          align: 'center',
          // width: 220,
          render: (_, { residentialCategories }: any) => {
            return (
              <>
                <span />
                {residentialCategories?.map((tag) => {
                  return (
                    <Tag className="tag-sm" key={tag}>
                      {_filterName("SPECIALITIES", tag, "RES")}
                    </Tag>
                  );
                })}
              </>
            );
          },
        },
        {
          title: "Commercial",
          dataIndex: "commercialCategories",
          key: "commercialCategories",
          align: 'center',
          // width: 220,
          render: (_, { commercialCategories }: any) => {
            return (
              <>
                <span />
                {commercialCategories?.map((tag) => {
                  return (
                    <Tag className="tag-sm" key={tag}>
                      {_filterName("SPECIALITIES", tag, "COM")}
                    </Tag>
                  );
                })}
              </>
            );
          },
        },
        {
          title: "Internal",
          dataIndex: "internalCategories",
          key: "internalCategories",
          align: 'center',
          // width: 220,
          render: (_, { internalCategories }: any) => {
            return (
              <>
                <span />
                {internalCategories?.map((tag) => {
                  return (
                    <Tag className="tag-sm" key={tag}>
                      {_filterName("SPECIALITIES", tag, "INT")}
                    </Tag>
                  );
                })}
              </>
            );
          },
        },
      ],
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 100,
      render: (text, record) => {
        return (
          <Space size={16}>
            <Button
              title="Click to Edit"
              type="link"
              onClick={() => onEdit(record)}
              className="p-0 ag-success"
            >
              <div className="Simple-tooltip">
                <EditOutlined />
                {/* <section className="tt-content">Edit</section> */}
              </div>
            </Button>
          </Space>
        );
      },
    },
  ];

  const addNewUser = () => {
    setVisible(true);
    setTrigger(0);
  };

  const _filterName = (type, id, subtype = null) => {
    let name = "";
    switch (type) {
      case "SP":
        for (let key in serviceProviders)
          if (id === serviceProviders[key].serviceProviderId) {
            name = serviceProviders[key].serviceProviderName;
            break;
          }
        break;
      case "PROPERTY":
        for (let key in properties)
          if (id === properties[key].value) {
            name = properties[key].label;
            break;
          }
        break;
      case "SPECIALITIES":
        for (let key in dropDownServiceProviders) {
          if (subtype == "RES") {
            for (let catg in dropDownServiceProviders[key].residential) {
              // console.log("CATG",dropDownServiceProviders[key]?.residential?.[catg]?.value,key);
              if (id === dropDownServiceProviders[key]?.residential?.[catg]?.value) {
                name = dropDownServiceProviders[key]?.residential?.[catg]?.label;
                break;
              }
            }
          } else if (subtype == "COM") {
            for (let catg in dropDownServiceProviders[key].commercial) {
              // console.log("CATG",dropDownServiceProviders[key]?.residential?.[catg]?.value,key);
              if (id === dropDownServiceProviders[key]?.commercial?.[catg]?.value) {
                name = dropDownServiceProviders[key]?.commercial?.[catg]?.label;
                break;
              }
            }
          } else if (subtype == "INT") {
            for (let catg in dropDownServiceProviders[key].internal) {
              // console.log("CATG",dropDownServiceProviders[key]?.residential?.[catg]?.value,key);
              if (id === dropDownServiceProviders[key]?.internal?.[catg]?.value) {
                name = dropDownServiceProviders[key]?.internal?.[catg]?.label;
                break;
              }
            }
          }
        }
        break;
      default:
        break;
    }

    return name;
  };

  const handlePagination = (pg) => {
    setPage(pg);
    dispatch(getAssignedList());
  };

  return (
    <>
      <DashboardLayout>
        <SectionHeader addNewAction={addNewUser} heading="Service Providers" />
        <Row gutter={24} className="ant-table-row">
          <Col span={24}>
            <Table
              columns={column}
              scroll={{ y: 500 }}
              size={"small"}
              dataSource={assignedList}
              rowKey={(record, index) => index}
              className="custom-table org_table_wrap"
              pagination={false}
            />
          </Col>
        </Row>

        <Col span={24}>
          <Pagination
            pageSize={50}
            current={page}
            defaultCurrent={1}
            showSizeChanger={false}
            total={assignedList?.length}
            onChange={(e) => handlePagination(e)}
            className="ag-property-text-pagination"
            showTotal={(total, range) =>
              `Showing ${range[0]}-${range[1]} of ${total} Service Providers`
            }
          />
        </Col>
      </DashboardLayout>
      <Popup {...popupProps} destroyOnClose={true} />
    </>
  );
};

export default ServiceProvider;
