import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Typography,
  Space,
  Select,
  Button,
  Image,
  Divider,
  message,
  Tooltip,
  Avatar,
  Input
} from "antd";
import { Comment } from '@ant-design/compatible';
import { EyeFilled, CloseOutlined, CaretRightFilled, UserOutlined, InteractionOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import starfill from "../../../../../../assets/img/starfill.png";
import starregular from "../../../../../../assets/img/starregular.png";
import {
  maintenanceService,
  // ticketBaseUrl,
} from "../../../../../../configs/constants";
import axios from "../../../../../../utils/axiosInceptor";
import { getPresignedImage } from "../../../../../../utils/media";
import moment from "moment";
import { StarOutlined, StarFilled } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { getOrgByID, getTicketByID, getTicketReviews } from "../../ducks/actions";
import LocalStorageService from "../../../../../../utils/localStorageService";
import { dateByformat } from "../../../../../../utils/dateHelper";
import { PopupConfirm, Popup } from "../../../../../atoms/Popup";
import ReAssignTechnician from "../ReAssignTechnician";

var _ = require("lodash");

const { Title, Text } = Typography;
const { Search } = Input;
const priorityOptions = [
  { value: 'p1', label: 'P1' },
  { value: 'p2', label: 'P2' },
  { value: 'p3', label: 'P3' },
  { value: 'p4', label: 'P4' },
  { value: 'p5', label: 'P5' },
]
const ViewTicket = (props) => {
  const { data, onCancel, assignTech, onActivityLog, onSubmitTicket } = props;

  const dispatch: any = useDispatch();
  const [review, setReview] = useState({ rating: 5, review: "" });
  const [comments, setComments] = useState(null);
  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [selectedTicket, setSelectedTickets] = useState();
  const [activityData, setActivityData] = useState([]);
  const [totalImageURL, setTotalImageURL] = useState([]);
  const [totalVideoUrls, setTotalVideoUrls] = useState([]);
  const [techtotalImageURL, setTechtotalImageURL] = useState([]);
  const [techtotalVideoUrls, setTechtotalVideoUrls] = useState([]);
  const [riccuranceTicket, setRiccuranceTicket] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [startRate, setStartRate] = useState(null);
  const [priority, setPriority] = useState('default');

  const ticketReviews = [];

  const ticketData = useSelector((state: any) => state.tickets.getTicketById) || {}

  let status = ticketData?.ticketStatus;
  const ticketId = data?.ticketId;
  useEffect(() => {
    dispatch(getTicketByID(ticketId));
  }, [ticketId]);

  useEffect(() => {
    if (ticketReviews) {
      setReview(ticketReviews?.[0]);
    }
  }, [ticketReviews]);



  const viewActivityLogTicket = async (data) => {
    props.onActivityLog(data);
  };


  useEffect(() => {
    if (ticketData) {
      if (ticketData?.ticketStatusHistory?.length > 0) {
        let sortedStatus = _.sortBy(ticketData?.ticketStatusHistory, [(obj) => moment(obj.createdAt)]).reverse();
        setActivityData(sortedStatus);
      }
      const starRate = ticketData?.ratingAndReview?.rating;

      if (ticketData?.priority) {
        setPriority(ticketData.priority === 'default' ? 'Select priority' : ticketData.priority)
      }

      if (starRate) {
        let ticketRateArr = []
        for (let rate = 1; rate <= 5; rate++) {
          ticketRateArr.push(<img
            alt="Ticket"
            src={rate <= starRate ? starfill : starregular}
            width={25}
            height={25}
          />)
        }
        setStartRate(ticketRateArr)
      }

      ; (async () => {
        let totalImages = [];
        let totalVideos = [];
        if (ticketData.documents && ticketData.documents.length > 0) {
          ticketData.documents.map((x) => {
            if (x?.documentId && !x?.type.includes("mp4")) {
              totalImages.push(x.documentId);
            } else {
              totalVideos.push(x.documentId);
            }
          });
          const images = await getMedia(totalImages);
          const videos = await getMedia(totalVideos);

      
          setTotalImageURL(images);
          setTotalVideoUrls(videos);
        }

        let techtotalImages = [];
        let techtotalVideos = [];
        if (ticketData?.technicianDocuments && ticketData?.technicianDocuments?.length > 0) {
          ticketData.technicianDocuments.map((x) => {
            if (x?.documentId && !x?.type.includes("mp4")) {
              techtotalImages.push(x.documentId);
            } else {
              techtotalVideos.push(x.documentId);
            }
          });
          const images = await getMedia(techtotalImages);
          const videos = await getMedia(techtotalVideos);
          setTechtotalImageURL(images);

  
          setTechtotalVideoUrls(videos);

        }
      })()
    }
  }, [ticketData])

  const viewStatus = () => {
    switch (ticketData?.ticketStatus) {
      case "reject":
        return `Rejected - ${ticketData?.technician?.name}`;
      case "initial_assessment":
        return `New - ${ticketData?.technician?.name}`;
      case "landlord_not_responsible":
        return `Landlord not Responsible - ${ticketData?.technician?.name}`;
      case "issue_resolved":
        return `Closed - ${ticketData?.technician?.name}`;
      case "issue_not_resolved":
        return `Closed - ${ticketData?.technician?.name}`;
      default:
        return `In Progress - ${ticketData?.technician?.name}`;
    }
  };

  // TODO : MAKE CONSTANT
  const tenantStatus = () => {
    switch (status) {
      case "issue_not_resolved":
        return "Not Solved";
      case "landlord_not_responsible":
        return "Not Solved";
      case "issue_resolved":
        return "Solved";
    }
  };

  const getComments = async () => {
    const url = `${maintenanceService}/maintenance/ticket/getComments/${data?.ticketId}`;
    try {
      const {
        data: { result },
      } = await axios.get(url);

      setComments(result.data)


    } catch (e) {
      console.log("Err", e);
    }
  }

  useEffect(() => {
    if (data?.ticketId) {
      getComments()
    }
  }, [data?.ticketId])

  const reopenTicket = async (id) => {
    // TODO : REMOVE PARTY ID
    const url1 = `${maintenanceService}/maintenance/ticket/${id}/reopen-closed?status=inProgress`;
    try {
      const {
        data: { result },
      } = await axios.get(url1);
      message.success("Ticket Reopen Successfully");
      props.onSubmit();
    } catch (e) {
      console.log("Err", e);
    }
  };

  const rejectTicket = (data) => {
    props.onRejectTicket(data);
  };


  const confirmTicket = (data) => {
    PopupConfirm({
      title: `Please confirm, if issue is resolved?`,
      cancelText: "No",
      okText: "Yes",
      onOk: () => {
        onConfirmTicket(true, ticketId);

      },
      onCancel: () => {
        onConfirmTicket(false, ticketId);
      }
    });

    // props.onRejectTicket(data);
  };


  const onConfirmTicket = async (isResolved, tickID) => {

    const url = `${maintenanceService}/maintenance/ticket/${tickID}`;
    try {
      const res = await axios.patch(
        url,
        {
          status: isResolved ? "issue_resolved" : "issue_not_resolved"
        },
      );
      setTimeout(() => {
        message.success("Ticket status updated successfully");
        dispatch(getTicketByID(ticketId));
        props.onSubmit();
      }, 1000);
    } catch (e) {
      const { response } = e;
      message.error(response?.data?.message)
    }
  }

  const onSubmit = () => {
    dispatch(getTicketByID(ticketId));
    setVisible(false);
    onSubmitTicket()
  };

  const popup = [
    {
      title: (
        <Title level={3} className="m-0">
          Modify Ticket
        </Title>
      ),
      content: (
        <ReAssignTechnician
          ticket={selectedTicket}
          onBack={() => setVisible(false)}
          onSubmit={onSubmit}
        />
      ),
      width: 650,
    },
  ];

  const ticketAction = (type) => {
    setVisible(true);
    setSelectedTickets({ ...ticketData, ...{ ticketStatus: type } });
  }

  const popupProps = {
    closable: trigger == 0 ? true : false,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => setVisible(false),
  };

  const getTicketStatus = () => {
    return activityData[0]?.status?.split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  const addComment = async (commentText) => {

    const url = `${maintenanceService}/maintenance/ticket/comment`;
    try {
      const res = await axios.post(
        url,
        {
          "comment": commentText,
          "type": "public",
          "moduleId": ticketId
        },
      );
      setInputValue('');

      message.success("Comment added successfully");
      getComments()

    } catch (e) {
      const { response } = e;
      message.error(response?.data?.message)
    }
  }

  const capitalizeFirstLetter = (str = '') => str?.charAt(0)?.toUpperCase() + str?.slice(1);

  function formatDate(date) {
    const currentDate = new Date();
    const providedDate = new Date(date);

    if (providedDate.toDateString() === currentDate.toDateString()) {
      const timeDiff = Math.abs(currentDate.getTime() - providedDate.getTime());
      const diffInHours = Math.floor(timeDiff / (1000 * 60 * 60));
      const diffInMinutes = Math.floor(timeDiff / (1000 * 60));

      if (diffInHours === 0) {
        if (diffInMinutes === 0) {
          return 'Less than a minute ago';
        } else {
          return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`;
        }
      } else {
        return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
      }
    } else if (providedDate < currentDate) {
      const dateOptions = { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' } as const;
      return providedDate.toLocaleDateString(undefined, dateOptions);
    } else {
      return 'Invalid date';
    }
  }

  const getMedia = async (totalImages) => {
    if (totalImages?.length > 0) {
      const result = await getPresignedImage(totalImages); //change
      if (result && result.length > 0) {
      } else {
        message.error("Something wrong with Media");
      }
      return result;
    }
    return [];
  };

  const goToRecurringTicket = async () => {
    setRiccuranceTicket(ticketId)
    dispatch(getTicketByID(ticketData.parentTicketId));
  }

  const goToOldTicket = async () => {
    dispatch(getTicketByID(ticketId));
    setTimeout(() => {
      setRiccuranceTicket('')
    }, 2000);
  }

  const changePriority = async (e) => {
    if (e.value === 'p1' || e.value === 'p2' || e.value === 'p3' || e.value === 'p4' || e.value === 'p5') {
      setPriority(e.value)
      const url = `${maintenanceService}/maintenance/ticket/${ticketId}`;
      try {
        const res = await axios.patch(
          url,
          {
            "priority": e.value
          },
        );

        message.success("Priority changed successfully");
        dispatch(getTicketByID(ticketId));

      } catch (e) {
        const { response } = e;
        message.error(response?.data?.message)
      }
    }
  }

  // const { type, subtype, status, tenant, appartment, description, technician, priority, datetime, id, confirmation_status, recuring } = data;
  return (
    <Row gutter={24}>
      <Col span={24}>
        <Row>
          <Col span={12}>
            {riccuranceTicket && (
              <Button
                style={{ float: 'left' }}
                className="p-0 h-auto w-auto ag-default mb-1"
                type="link"
                size="large"
                onClick={goToOldTicket}
                icon={<ArrowLeftOutlined />}
              />
            )}
          </Col>
          <Col span={12}>
            {!props.onBack && (
              <Button
                style={{ float: 'right' }}
                className="p-0 h-auto w-auto ag-default mb-1"
                type="link"
                size="large"
                onClick={onCancel}
                icon={<CloseOutlined />}
              />
            )}
          </Col>


        </Row>
      </Col>
      <Col span={14}>
        <Row gutter={[32, 24]}>
          <Col span={24}>
            <Space size={2} className="w-100">
              <Title level={5} className="ag-primary">{ticketData?.srNumber}</Title>
              {
                ticketData?.recurrence ? <InteractionOutlined style={{ fontSize: 28, color: '#184461', paddingBottom: 8, paddingLeft: 11 }} onClick={() => goToRecurringTicket()} /> : null
              }

            </Space>
            <Space size={2} className="w-100" direction="vertical">
              <Title level={5} className="ag-primary">
                {`${capitalizeFirstLetter(ticketData?.category?.name)} - ${capitalizeFirstLetter(ticketData?.subCategory?.name)}`}
              </Title>
              <Title level={5} className="ag-primary">
                {capitalizeFirstLetter(ticketData?.propertyName)} - {ticketData?.unitNumber}
              </Title>
            </Space>
          </Col>

          <Col span={24} style={{ marginBottom: 20 }}>
            <Space size={2} className="w-100" direction="vertical">
              <Row>
                <Col span={24}><Divider /></Col>
                <Col span={8}><Text className="ag-primary" strong>Status</Text></Col>
                <Col span={12}>
                  <Text className="smallFont14 fontWeight600">{getTicketStatus()}</Text>
                  <Text className="ag-primary" onClick={() => viewActivityLogTicket(ticketData)} strong underline style={{ cursor: 'pointer', marginLeft: 10 }}>View Details</Text>
                </Col>
                <Col span={24}><Divider /></Col>
                <Col span={8}><Text className="ag-primary" strong>Assigned to</Text></Col>
                <Col span={12}><Text className="smallFont14 fontWeight600">{ticketData?.technician?.name}</Text></Col>
                <Col span={24}><Divider /></Col>
                <Col span={8}><Text className="ag-primary" strong>Date and time</Text></Col>
                <Col span={12}>
                  {
                    <Text className="smallFont12 fontWeight600">
                      {dateByformat(ticketData?.preferredDate, "DD-MM-YYYY")} |{" "}
                      {ticketData?.preferredTimeStart &&
                        moment(ticketData?.preferredTimeStart, "hh:mm:ss").format(
                          "LT",
                        )}{" "}
                      {ticketData?.preferredTimeEnd &&
                        `to ${moment(ticketData?.preferredTimeEnd, "hh:mm:ss").format(
                          "LT",
                        )}`}
                    </Text>
                  }
                </Col>
                <Col span={24}><Divider /></Col>
                <Col span={8}><Text className="ag-primary" strong>Priority</Text></Col>
                <Col span={12}>
                  <Select
                    value={priority}
                    onChange={(e) => changePriority(e)}
                    filterOption={(input, option) =>
                      (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
                    }
                    labelInValue
                    placeholder={`Select priority`}
                    options={priorityOptions}
                  />
                </Col>
              </Row>
            </Space>
          </Col>

          {
            status !== 'issue_resolved' && (
              <Col span={24}>
                <Row>
                  <Col span={5}>
                    <Button type="primary" onClick={() => ticketAction('reassign')}>Reassign</Button>
                  </Col>
                  <Col span={1}></Col>
                  <Col span={5}>
                    <Button type="primary" onClick={() => ticketAction('reschedule')}>Reschedule</Button>
                  </Col>
                  <Col span={2}></Col>
                  <Col span={5}>
                    <Button type="primary" danger onClick={() => ticketAction('close')}>Close</Button>
                  </Col>
                </Row>
              </Col>
            )
          }

          <Col span={24}>
            <Space size={2} className="w-100" direction="vertical">
              <Row>
                <Col span={24} style={{ marginTop: '30' }}>
                  <Title level={4}>Comments</Title>
                  <Space direction="vertical">
                    <Search
                      placeholder="Add your comments"
                      allowClear
                      enterButton="Add"
                      size="large"
                      style={{ width: 380 }}
                      onChange={(e) => setInputValue(e.target.value)}
                      value={inputValue}
                      onSearch={addComment}
                    />
                  </Space>
                </Col>
              </Row>

              <Row>
                {
                  comments?.length > 0 ? (
                    <Col span={24} style={{ marginTop: '30' }}>
                      {
                        comments && comments.length &&
                        comments.map(commentUnit => (
                          <>
                            <Comment
                              // actions={actions}
                              author={<a>{commentUnit?.commentBy?.name}</a>}
                              avatar={
                                <Avatar
                                  icon={<UserOutlined />}
                                  alt={commentUnit?.commentBy?.name}
                                />
                              }
                              content={
                                <p>{commentUnit?.comment}</p>
                              }
                              datetime={
                                <Tooltip title={formatDate(commentUnit?.createdAt)}>
                                  <span>{formatDate(commentUnit?.createdAt)}</span>
                                </Tooltip>
                              }
                            />
                            <Divider />
                          </>
                        ))
                      }
                    </Col>
                  ) : <></>
                }
              </Row>
            </Space>
          </Col>

          {/* <Col span={24}>
            <Title level={5}>Description</Title>
            <Title level={5} className="grayBox">
              {ticketData?.description}
            </Title>
          </Col> */}
          {/* <Col span={24}>
            <Title level={5}>Media</Title>
            <Space size={[8, 0]} wrap>
              {data?.totalImageURL?.map((e, index) => {
                return (
                  <React.Fragment key={index}>
                    <Button className="ticket_thumb" type="link" size="large">
                      <Image
                        preview={true}
                        alt="Ticket"
                        src={e}
                        width="115"
                        height="118"
                      />
                      <EyeFilled className="thumb_icon" />
                    </Button>
                  </React.Fragment>
                );
              })}
              {data?.totalVideoUrls?.map((e, index) => {
                return (
                  <React.Fragment key={index}>
                    <Button
                      className="ticket_thumb video_thumb"
                      type="link"
                      size="large"
                    >
                      <video src={e} controls width="115" height="118"></video>
                      <CaretRightFilled className="thumb_icon" />
                    </Button>
                  </React.Fragment>
                );
              })}

              {data?.techtotalImageURL?.map((e, index) => {
                return (
                  <React.Fragment key={index}>
                    <Button className="ticket_thumb technician" type="link" size="large">
                      <Image
                        preview={true}
                        alt="Ticket"
                        src={e}
                        width="115"
                        height="118"
                      />
                      <EyeFilled className="thumb_icon" />
                    </Button>
                  </React.Fragment>
                );
              })}
              {data?.techtotalVideoUrls?.map((e, index) => {
                return (
                  <React.Fragment key={index}>
                    <Button
                      className="ticket_thumb video_thumb technician"
                      type="link"
                      size="large"
                    >
                      <video src={e} controls width="115" height="118"></video>
                      <CaretRightFilled className="thumb_icon" />
                    </Button>
                  </React.Fragment>
                );
              })}
            </Space>
          </Col> */}

          {/* {
            comments?.length > 0 ? (
              <Col span={24} style={{marginTop: '10'}}>
                <Title level={5}>Comments</Title>
                {
                  comments && comments.length && 
                  comments.map(commentUnit => (
                    <Comment
                      // actions={actions}
                      author={<a>{commentUnit?.commentBy?.name}</a>}
                      avatar={
                        <Avatar
                          icon={<UserOutlined />}
                          alt={commentUnit?.commentBy?.name}
                        />
                      }
                      content={
                        <p>{commentUnit?.comment}</p>
                      }
                      datetime={
                        <Tooltip title={moment(commentUnit?.commentBy?.createdAt).format('YYYY-MM-DD HH:mm:ss')}>
                          <span>{moment(commentUnit?.commentBy?.createdAt).format('YYYY-MM-DD HH:mm:ss')}</span>
                        </Tooltip>
                      }
                    />
                  ))
                }
              </Col>
            ) : <></>
          } */}

          {/* 
          {data?.ticketStatus == "closed" && (
            <Col span={24}>
              <Title level={5}>Rating and comments:</Title>
              {review?.rating && (
                <Rate
                  disabled
                  defaultValue={review?.rating}
                  count={review?.rating}
                  character={<StarFilled />}
                  style={{ marginRight: "8px" }}
                />
              )}
              <Rate
                disabled
                count={review?.rating ? 5 - review?.rating : 5}
                character={<StarOutlined style={{ color: "#fadb1" }} />}
              />
              <Title level={5} className="ticket-review">
                {review?.review}
              </Title>
            </Col>
          )} */}
        </Row>
      </Col>
      <Col span={9}>
        {
          startRate && (
            <Row>
              <Col span={24}>
                <Row>
                  {
                    startRate && startRate.map((item, key) => item)
                  }
                </Row>
              </Col>
            </Row>
          )
        }
        <Row>
          <Col span={24}>
            <Space size={2} className="w-100" direction="vertical">
              <Title level={4}>Description</Title>
              <Text>{ticketData?.description}</Text>
            </Space>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Space size={[8, 0]} wrap>
              {totalImageURL?.map((e, index) => {
                return (
                  <React.Fragment key={index}>
                    <Button className="ticket_thumb" type="link" size="large">
                      <Image
                        preview={true}
                        alt="Ticket"
                        src={e}
                        width="115"
                        height="118"
                      />
                      <EyeFilled className="thumb_icon" />
                    </Button>
                  </React.Fragment>
                );
              })}
              {totalVideoUrls?.map((e, index) => {
                return (
                  <React.Fragment key={index}>
                    <Button
                      className="ticket_thumb video_thumb"
                      type="link"
                      size="large"
                    >
                      <video src={e} controls width="115" height="118"></video>
                      <CaretRightFilled className="thumb_icon" />
                    </Button>
                  </React.Fragment>
                );
              })}

              {techtotalImageURL?.map((e, index) => {
                return (
                  <React.Fragment key={index}>
                    <Button className="ticket_thumb technician" type="link" size="large">
                      <Image
                        preview={true}
                        alt="Ticket"
                        src={e}
                        width="115"
                        height="118"
                      />
                      <EyeFilled className="thumb_icon" />
                    </Button>
                  </React.Fragment>
                );
              })}
              {techtotalVideoUrls?.map((e, index) => {
                return (
                  <React.Fragment key={index}>
                    <Button
                      className="ticket_thumb video_thumb technician"
                      type="link"
                      size="large"
                    >
                      <video src={e} controls width="115" height="118"></video>
                      <CaretRightFilled className="thumb_icon" />
                    </Button>
                  </React.Fragment>
                );
              })}
            </Space>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Space size={2} className="w-100" direction="vertical">
              <Title level={4}>Tenant Details</Title>
              <Row>
                <Col span={12}><Text className="ag-primary" strong>Name</Text></Col>
                <Col span={12}><Text className="smallFont14 fontWeight600">{ticketData.tenantName}</Text></Col>
                <Col span={24}><Divider /></Col>
                <Col span={12}><Text className="ag-primary" strong>EmailID</Text></Col>
                <Col span={12}><Text className="smallFont14 fontWeight600">{ticketData?.tenantemail}</Text></Col>
                <Col span={24}><Divider /></Col>
                <Col span={12}><Text className="ag-primary" strong>Mobile Number</Text></Col>
                <Col span={12}>
                  <Text className="smallFont14 fontWeight600">{ticketData?.tenantMobileNumber}</Text>
                </Col>
                <Col span={24}><Divider /></Col>
                <Col span={12}><Text className="ag-primary" strong>Alternate Number</Text></Col>
                <Col span={12}><Text className="smallFont14 fontWeight600">{ticketData?.alternateNumber}</Text></Col>
                <Col span={24}><Divider /></Col>
              </Row>
            </Space>
          </Col>
        </Row>

        {/* <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row align="bottom">
              <Col flex="auto">
                <Title level={5}>Status and Assignee:</Title>
              </Col>
              <Col flex="60px">
                <Button
                  className="p-0 ag-primary fontWeight600"
                  type="link"
                  onClick={() => viewActivityLogTicket(ticketData)}
                >
                  View Activity Log
                </Button>
              </Col>
            </Row>
            <Text className="defaultFont fontWeight600 ag-success">
              {viewStatus()}
            </Text>
          </Col>
          <Col span={24}>
            <Row align="bottom">
              <Col flex="auto">
                <Title level={5}>Tenant:</Title>
              </Col>
              <Col flex="60px">
                <Button
                  className="p-0 ag-primary fontWeight600"
                  type="link"
                  onClick={() => viewTenantTicket(ticketData)}
                >
                  View Tenant Details
                </Button>
              </Col>
            </Row>
            <Text className="defaultFont fontWeight600">{ticketData?.tenantName}</Text>
          </Col>
          <Col span={24}>
            <Title level={5}>Apartment:</Title>
            <Text className="defaultFont fontWeight600">{ticketData?.propertyName}</Text>
          </Col>
          {status != "issue_resolved" &&
            status != "landlord_not_responsible" &&
            status != "issue_not_resolved" ? (
            <></>
          ) : ( */}
        {/* // <Col span={24}>
            //   <Title level={5}>Priority:</Title>
            //   <Text className={`defaultFont fontWeight600 sentanceCase ${data?.priority === 'high' && 'ag-error'}`}>{data?.priority}</Text>
            // </Col>
          //   <>
          //     <Col span={24}>
          //       <Title level={5}>Technician Assigned:</Title>
          //       <Text className="defaultFont fontWeight600">
          //         {technician?.name}
          //       </Text>
          //     </Col>
          //     <Col span={24}>
          //       <Title level={5}>Confirmation from Tenant:</Title>
          //       <Text className="defaultFont fontWeight600 ag-primary">
          //         {tenantStatus()}
          //       </Text>
          //     </Col>
          //   </>
          // )}

          // {ticketData?.recurrence && ( */}
        {/* //   <Col span={24}>
          //     <Title level={5}>Issue Type:</Title>
          //     <Text className="defaultFont fontWeight600 ag-primary">
          //       Recurring  */}
        {/* -{" "}
                <Button
                  type="link"
                  className="ag-success p-0"
                  onClick={() => originalTicket(recuring)}
                >
                  View original ticket
                </Button> */}
        {/* </Text>
            </Col>
          )}

          <Divider
            className="m-0"
            style={{ borderBottom: "1px solid #767676" }}
          />
          <Col span={24}>
            <Title level={5}>Date and Time:</Title>
            <Text className="defaultFont fontWeight600">
              {dateByformat(ticketData?.preferredDate, "DD-MM-YYYY")} |{" "}
              {ticketData?.preferredTimeStart &&
                moment(ticketData?.preferredTimeStart, "hh:mm:ss").format(
                  "LT",
                )}{" "}
              {ticketData?.preferredTimeEnd &&
                `to ${moment(ticketData?.preferredTimeEnd, "hh:mm:ss").format(
                  "LT",
                )}`}
            </Text>
          </Col> */}
        {/* {ticketData?.ticketStatus === "initial_assessment" && !technician && !props.onBack && (
            <Col span={24}>
              <Button type="primary" size="large" onClick={assignTech}>
                Assign Technician
              </Button>
            </Col>
          )} */}
        {/* {props.onBack && (
            <Col span={24}>
              <Button type="primary" size="large" onClick={props.onBack}>
                Go back to ticket
              </Button>
            </Col>
          )}
          <Col span={24}>
            <Space size={8}> */}
        {/* {((ticketData?.ticketStatus == "progress" ||
                ticketData?.ticketStatus == "new" || ticketData?.ticketStatus != "reject") && status !== "issue_resolved") && (
                  <Button
                    type="primary"
                    size="large"
                    className="danger-btn"
                    onClick={() => rejectTicket(ticketData)}
                  >
                    Reject Ticket
                  </Button>
                )} */}
        {/* {(status === "pending_confirmation" && !ticketData?.tenantId) && (
                <Button
                  type="primary"
                  size="large"
                  onClick={() => confirmTicket(tickid)}
                >
                  Confirm Ticket
                </Button>
              )}
              {status === "issue_not_resolved" && (
                <Button
                  type="primary"
                  size="large"
                  onClick={() => reopenTicket(tickid)}
                >
                  Reopen Ticket
                </Button>
              )}
            </Space>
          </Col>
        </Row> */}
      </Col>
      <Popup destroyOnClose={true} {...popupProps} />
    </Row>
  );
};

export default ViewTicket;