import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Typography, message } from "antd";
import { useSelector, useDispatch } from "react-redux";

import Tickets from "../../../molecules/Tickets";
import AddTicket from "./components/AddTicket";
import ViewTicket from "./components/ViewTicket";
import AssignTechnician from "./components/AssignTechnician";
import ReAssignTechnician from "./components/ReAssignTechnician";

import SectionHeader from "../../../molecules/SectionHeader";
import { Popup } from "../../../atoms/Popup";
import DashboardLayout from "../../../molecules/DashboardLayout";
import BreadCrumb from "../../../atoms/BreadCrumb";

import {
  getNewTicket,
  getInprogressTicket,
  getAcknowledgedTicket,
  getClosedTicket,
  getTicketType,
  getNewTicketStatusType,
  getClosedTicketStatusType,
  getProgressTicketStatusType,
  getTicketByID,
  getTicketsExport,
  emptyTickets,
} from "./ducks/actions";
import {
  getAllTechnicians,
  getTechniciansForServiceProviders,
} from "../Technicians/ducks/actions";
import { getTechnicains } from "../iRoster/components/TechnicianSchedule/ducks/actions";
// import {
//   baseUrl,
//   baseUrl2,
//   maintenanceService,
//   ticketBaseUrl,
// } from "../../../../configs/constants";
import axios from "axios";
import { CSVDownload } from "react-csv";
import moment, { RFC_2822 } from "moment";
import { useSearchParams, useParams } from "react-router-dom";
import ActivityLog from "./components/ViewTicket/activityLog";
import RejectTicket from "./components/ViewTicket/rejectTicket";
import ViewTenantDetail from "./components/ViewTicket/ViewTenantDetail";
import { useForm } from "react-hook-form";
import LocalStorageService from "../../../../utils/localStorageService";
import {
  getMaintenanceTypes,
  getAllMaintenanceTypes,
} from "../../Configurations/ducks/actions";
import { FilterOutlined } from "@ant-design/icons";
import { getPresignedImage } from "../../../../utils/media";
import { maintenanceService } from "../../../../configs/constants";
import Permissions from "../../../../utils/permissions";
import { MODULES } from "../../../../configs/modules";
import { CREATE, READ } from "../../../../configs/operations";
import ExportTicket from "./components/ExportTicket";
const dayjs = require("dayjs");

const { Title } = Typography;


const categories = {
  value: "All",
  label: "All Categories",
};
const initialTechValue = {
  value: "All",
  label: "All Technicians",
};
const defaultNewStatusType = [
  {
    value: "All",
    label: "All New Tickets",
  },
  {
    value: "initial_assessment",
    label: "Assigned For Initial Assessment",
  },
];
const defaultInProgressStatusType = [
  {
    value: "All",
    label: "All In Progress Tickets",
  },
  { value: "reached_barcode_scanned", label: "Reached - Bar Code Scanned" },
  {
    value: "inprogress_barcode_scanned",
    label: "In Progress - Bar Code Scanned",
  },
  { value: "ticket_type_changed", label: "Ticket Type Changed" },
  { value: "issue_not_resolved", label: "Issue Not Resolved" },
  { value: "technician_forwarded", label: "Forwarded To Technician" },
  { value: "reopen_closed", label: "Reopen" },
  { value: "pending_confirmation", label: "Pending Confirmation" },
  { value: "ticket_type_confirm", label: "Ticket Type Confirm" },
];
const defaultScheduledStatusType = [
  {
    value: "All",
    label: "All Scheduled Tickets",
  },
  { value: "acknowledged", label: "Acknowledged" },
];
const defaultCompletedStatusType = [
  {
    value: "All",
    label: "All Closed Tickets",
  },
  { value: "suspended", label: "Suspended" },
  { value: "landlord_not_responsible", label: "Landlord not Responsible" },
  { value: "issue_resolved", label: "Issue Resolved" },
  { value: "reject", label: "Reject" },
];
const breadcrumbList: any = [
  {
    label: "Maintenance",
    route: "/tickets",
  },
  {
    label: "Tickets",
    route: "/tickets",
  },
];

const exportDataHeader = [
  { label: "Case Id", key: "case_id" },
  { label: "Status", key: "status" },
  { label: "Initial Assessment", key: "initial_assessment" },
  { label: "Acknowledged", key: "acknowledged" },
  { label: "Reached Barcode Scanned", key: "reached_barcode_scanned" },
  { label: "Inprogress Barcode Scanned", key: "inprogress_barcode_scanned" },
  { label: "Ticket Type Changed", key: "ticket_type_changed" },
  { label: "Issue Resolved", key: "issue_resolved" },
  { label: "Issue Not Resolved", key: "issue_not_resolved" },
  { label: "Technician Forwarded", key: "technician_forwarded" },
  { label: "Landlord not Responsible", key: "landlord_not_responsible" },
  { label: "Reopen Closed", key: "reopen_closed" },
  { label: "Pending Confirmation", key: "pending_confirmation" },
  { label: "Suspended", key: "suspended" },
  { label: "Reject", key: "reject" },
  { label: "Ticket Type Confirm", key: "ticket_type_confirm" },
  { label: "Tenant Status", key: "tenant_status" },
  { label: "Type", key: "type" },
  { label: "SubType", key: "subtype" },
  { label: "Tenant Name", key: "tenant_name" },
  { label: "Technician", key: "technician" },
  { label: "Appartment", key: "appartment" },
  { label: "Recuring", key: "recuring" },
  { label: "Description", key: "description" },
  { label: "Rating", key: "rating" },
  { label: "Review", key: "review" },
  { label: "Date", key: "date" },
];



const ticketDef: any = {
  new: [],
  inprogress: [],
  closed: [],
};

export default (props) => {
  const dispatch: any = useDispatch();

  const currentDate = dayjs().format('YYYY-MM-DD');
  const mondayDate = dayjs().startOf('week').add(1, 'day').format('YYYY-MM-DD');
  const sundayDate = dayjs().endOf('week').add(1, 'day').format('YYYY-MM-DD');

  const [ticketNew, setTicketNew] = useState([]);
  const [ticketInProgress, setTicketInProgress] = useState([]);
  const [ticketAcknowledge, setTicketAcknowledge] = useState([]);
  const [ticketClosed, setTicketClosed] = useState([]);
  const [countNew, setCountNew] = useState(1);
  const [countProgress, setCountProgress] = useState(1);
  const [countAcknowledge, setCountAcknowledge] = useState(1);
  const [countClosed, setCountClosed] = useState(1);
  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [selectedTicket, setSelectedTickets] = useState();
  const [recurringTicket, setRecurringTicket] = useState();
  const [showClosedTickets, setShowClosedTickets] = useState(false);
  const [ticketsType, setTicketType] = useState([categories]);
  const [allTechnicians, setTechnicians] = useState([initialTechValue]);
  const [technicianId, setTechnicianId] = useState();
  const [categoryName, setCategoryName] = useState();
  const [typeSearch, settypeSearch] = useState();
  const [newStatusType, setNewStatusType] = useState(defaultNewStatusType);
  const [progressStatusType, setProgressStatusType] = useState(
    defaultInProgressStatusType
  );
  const [scheduledStatusType, setScheduledStatusType] = useState(
    defaultScheduledStatusType
  );
  const [completedStatusType, setCompletedtatusType] = useState(
    defaultCompletedStatusType
  );
  const [newStatusTypeId, setNewStatusTypeId] = useState();
  const [closedStatusTypeId, setClosedStatusTypeId] = useState();
  const [progressStatusTypeId, setProgressStatusTypeId] = useState();
  const [timeRange, setTimeRange] = useState({startDate: mondayDate, endDate: sundayDate, key: 'week'});
  const [activityLogTicketData, setActivityLogTicketData] = useState();
  const [rejectTicketData, setRejectTicketData] = useState();
  const [tenantDetailData, setTenantDetailData] = useState();
  const [rejectTicketSubmit, setRejectTicketSubmit] = useState();
  const [dates, setDates] = useState({
    from_date: "",
    to_date: "",
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const notifyCaseId = props?.location?.state?.identifier_value;
  const ticketsApiNew = useSelector(
    (state: any) => state.tickets.ticketsDataNew
  );
  const ticketsApiProgress = useSelector(
    (state: any) => state.tickets.ticketsDataProgress
  );
  const ticketsApiAcknowledge = useSelector(
    (state: any) => state.tickets.ticketsDataAcknowledged
  );
  const ticketsApiClosed = useSelector(
    (state: any) => state.tickets.ticketsDataClosed
  );
  const getTechnicians = useSelector(
    (state: any) => state.technicians.techniciansList
  );

  const filterOptions = [
    {
      name: `Technicians (${allTechnicians.length - 1})`,
      label: "Technicians",
      placeholder: "Select Technician",
      initialValue: "All",
      options: allTechnicians,
      search:true
    },
    {
      name: `Date`,
      label: "Date",
      placeholder: "Select Date",
      initialValue: "week",
      options: [
        { value: "today", label: "Today" },
        { value: "week", label: "This week" },
        { value: "all", label: "All" },
      ],
    },
  ];

  const searchOptions = useMemo(() => {
    return [
      {
        name: `Categories (${ticketsType.length - 1})`,
        label: "Category",
        placeholder: "Select Category",
        initialValue: "All",
        options: ticketsType,
      },
    ];
  }, [ticketsType]);

 
 

  const ticketTypeApi = useSelector(
    (state: any) => state.configuration.allMaintenanceTypes
  );


  useEffect(() => {
    if(searchParams && searchParams.get("ticketid")) {
      let ds : any = {ticketId : searchParams.get("ticketid")}
      setSelectedTickets(ds);
      setTrigger(1);
      setVisible(true);
    }
  } , [searchParams])

  const getTokenApi = async () => {
    let token: any = localStorage.getItem("token2");

    return token;
  };

 

  const callingApi = async () => {
    setTicketNew([]);
    setTicketInProgress([]);
    setTicketAcknowledge([]);
    setTicketClosed([]);
    setCountClosed(1);
    setCountProgress(1);
    setCountAcknowledge(1);
    setCountNew(1);
    let param = {};
    if (technicianId && technicianId !== "All") {
      param["technicianId"] = technicianId;
    }
    if (timeRange.key !== "all") {
      param["startDate"] = timeRange.startDate;
      param["endDate"] = timeRange.endDate;
    }
    if (categoryName && categoryName !== "All") {
      param["categoryId"] = categoryName;
    }
    if (typeSearch) {
      param["search"] = typeSearch;
    }

    let tokena = await getTokenApi();
    if (newStatusTypeId) {
      dispatch(
        getNewTicket(tokena, 1, {
          ...param,
          ...{ subStatus: newStatusTypeId },
        })
      );
    } else {
      dispatch(getNewTicket(tokena, 1, param));
    }
    if (progressStatusTypeId) {
      dispatch(
        getInprogressTicket(tokena, 1, {
          ...param,
          ...{ subStatus: progressStatusTypeId },
        })
      );
    } else {
      dispatch(getInprogressTicket(tokena, 1, param));
    }

    dispatch(getAcknowledgedTicket(tokena, 1, param));

    if (closedStatusTypeId && showClosedTickets) {
      dispatch(
        getClosedTicket(tokena, 1, {
          ...param,
          ...{ subStatus: closedStatusTypeId },
        })
      );
    } else if (showClosedTickets) {
      dispatch(getClosedTicket(tokena, 1, param));
    } else {
    }
  };

  const callingClosedApi = async () => {
    setTicketClosed([]);
    setCountClosed(1);
    let param = {};
    if (technicianId && technicianId !== "All") {
      param["technicianId"] = technicianId;
    }
    if (timeRange.key !== "all") {
      param["startDate"] = timeRange.startDate;
      param["endDate"] = timeRange.endDate;
    }
    if (categoryName && categoryName !== "All") {
      param["categoryId"] = categoryName;
    }
    if (typeSearch) {
      param["search"] = typeSearch;
    }

    let tokena = await getTokenApi();

    if (closedStatusTypeId) {
      dispatch(
        getClosedTicket(tokena, 1, {
          ...param,
          ...{ subStatus: closedStatusTypeId },
        })
      );
    } else {
      dispatch(getClosedTicket(tokena, 1, param));
    }
  };

  useEffect(() => {
    callingApi();
  }, [technicianId, categoryName, typeSearch, timeRange]);



  useEffect(() => {

    ticketTypeApi.length === 0 && dispatch(getAllMaintenanceTypes());
    dispatch(getTechniciansForServiceProviders());
  }, []);



  function removeDuplicates(array, property) {
    const seen = new Set();
    return array.filter((item) => {
      const value = item[property];
      if (seen.has(value)) {
        return false;
      }
      seen.add(value);
      return true;
    });
  }

  useEffect(() => {
    let data =
      ticketTypeApi?.residential
        ?.map((item) => ({
          value: item.categoryId,
          label: item.name, //+ " : " + item.buildingCode
        }))
        ?.filter((item) => item?.value && item?.label) || [];

    data.unshift(categories);
    data = removeDuplicates(data, "value");
    setTicketType(data);
  }, [ticketTypeApi]);

  useEffect(() => {
    let data = getTechnicians;
    data?.unshift(initialTechValue);

    setTechnicians(data);
  }, [getTechnicians]);

 

  const onSubmit = () => {
    callingApi();
    setVisible(false);
  };

  useEffect(() => {
    if (ticketsApiNew && ticketsApiNew.data.length > 0) {
      let tempArr = [...ticketNew, ...ticketsApiNew?.data];
      tempArr = tempArr?.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t._id === value._id)
      );
      setTicketNew(tempArr);
    }
  }, [ticketsApiNew]);

  useEffect(() => {
    if (ticketsApiProgress && ticketsApiProgress.data.length > 0) {
      let tempArr = [...ticketInProgress, ...ticketsApiProgress?.data];
      tempArr = tempArr?.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t._id === value._id)
      );
      setTicketInProgress(tempArr);
    }
  }, [ticketsApiProgress]);

  useEffect(() => {
    if (ticketsApiAcknowledge && ticketsApiAcknowledge.data.length > 0) {
      let tempArr = [...ticketAcknowledge, ...ticketsApiAcknowledge?.data];
      tempArr = tempArr?.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t._id === value._id)
      );
      setTicketAcknowledge(tempArr);
    }
  }, [ticketsApiAcknowledge]);

  useEffect(() => {
    if (ticketsApiClosed && ticketsApiClosed.data.length > 0) {
      let tempArr = [...ticketClosed, ...ticketsApiClosed?.data];
      tempArr = tempArr?.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t._id === value._id)
      );
      setTicketClosed(tempArr);
    }
  }, [ticketsApiClosed]);

  const countUpdate = async (param) => {
    let parami = {};
    if (technicianId) {
      parami["technicianId"] = technicianId;
    }
    if (categoryName) {
      parami["caseCategoryName"] = categoryName;
    }
    if (typeSearch) {
      parami["search"] = typeSearch;
    }
    // let tokena = await getTokenApi();
    if (param === "new") {
      if (newStatusTypeId) {
        parami["subStatus"] = newStatusTypeId;
      }
      setCountNew(countNew + 1);
      dispatch(getNewTicket("", countNew + 1, parami));
    } else if (param === "progress") {
      if (progressStatusTypeId) {
        parami["subStatus"] = progressStatusTypeId;
      }
      setCountProgress(countProgress + 1);
      dispatch(getInprogressTicket("", countProgress + 1, parami));
    } else if (param === "acknowledge") {
      setCountAcknowledge(countAcknowledge + 1);
      dispatch(getAcknowledgedTicket("", countAcknowledge + 1, parami));
    } else if (param === "closed") {
      if (closedStatusTypeId) {
        parami["subStatus"] = closedStatusTypeId;
      }
      setCountClosed(countClosed + 1);
      dispatch(getClosedTicket("", countClosed + 1, parami));
    }
  };

  const popup = [
    {
      closable: true,
      title: (
        <Title level={3} className="mb-0">
          Add a Ticket
        </Title>
      ),
      content: (
        <AddTicket
          onSubmit={() => onSubmit()}
          onCancel={() => setVisible(false)}
        />
      ),
      width: 680,
    },
    {
      title: null,
      content: (
        <ViewTicket
          data={selectedTicket}
          onRecurring={(e) => originalTicket(e)}
          onActivityLog={(e) => activityLogTicket(e)}
          onRejectTicket={(e) => rejectTicketTicket(e)}
          onviewTenantDetails={(e) => viewTeanantDetail(e)}
          onCancel={() => setVisible(false)}
          assignTech={assignTechnician}
          onSubmit={onSubmit}
        />
      ),
      width: 920,
    },
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Assign Technician
        </Title>
      ),
      content: (
        <AssignTechnician
          ticket={selectedTicket}
          onBack={() => setTrigger(1)}
          onAssign={onAssign}
        />
      ),
      width: 650,
    },
    {
      title: null,
      content: (
        <ViewTicket
          data={recurringTicket}
          onActivityLog={(e) => activityLogTicket(e)}
          onviewTenantDetails={(e) => viewTeanantDetail(e)}
          onBack={() => {
            setTrigger(1);
            setRecurringTicket(null);
          }}
          onCancel={() => setVisible(false)}
          assignTech={assignTechnician}
          onSubmitTicket={onSubmit}
        />
      ),
      width: 920,
    },
    {
      title: null,
      content: (
        <ActivityLog
          data={activityLogTicketData}
          onBack={() => {
            if (recurringTicket) {
              setTrigger(3);
            } else {
              setTrigger(1);
            }
            setActivityLogTicketData(null);
          }}
          onCancel={() => setVisible(false)}
        />
      ),
      width: 920,
    },
    {
      title: null,
      content: (
        <RejectTicket
          data={rejectTicketData}
          setRejectTicketSubmit={setRejectTicketSubmit}
          onBack={() => {
            setTrigger(1);
            setRejectTicketData(null);
          }}
          onCancel={() => setVisible(false)}
        />
      ),
      width: 920,
    },
    {
      title: null,
      content: (
        <ViewTenantDetail
          data={tenantDetailData}
          onBack={() => {
            if (recurringTicket) {
              setTrigger(3);
            } else {
              setTrigger(1);
            }
            setTenantDetailData(null);
          }}
          onCancel={() => setVisible(false)}
        />
      ),
      width: 920,
    },
    {
      title: (
        <Title level={3} className="m-0">
          Modify Ticket
        </Title>
      ),
      content: (
        <ReAssignTechnician
          ticket={selectedTicket}
          onBack={() => setVisible(false)}
          onAssign={onAssign}
          onSubmit={onSubmit}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="m-0">
          Export Tickets
        </Title>
      ),
      content: (
        <ExportTicket
          onSubmit={() => onSubmit()}
          onCancel={() => setVisible(false)}
        />
      ),
      width: 650,
    },
  ];

  const popupProps = {
    closable: trigger == 0 ? true : false,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => setVisible(false),
  };

  function handleShowCategory(e) {
    setCategoryName(e.value);
  }

  const handleNewStatusChange = async (value) => {
    setTicketNew([]);
    let param = {};
    if (technicianId) {
      param["technicianId"] = technicianId;
    }
    if (timeRange.key !== "all") {
      param["startDate"] = timeRange.startDate;
      param["endDate"] = timeRange.endDate;
    }
    if (categoryName) {
      param["caseCategoryName"] = categoryName;
    }
    if (typeSearch) {
      param["search"] = typeSearch;
    }
    if (value !== "All") {
      param["subStatus"] = value;
    }
    setCountNew(1);
    setNewStatusTypeId(value !== "All" ? value : null);
    let tokena = await getTokenApi();
    dispatch(getNewTicket(tokena, 1, param));
  };
  const handleProgressStatusChange = async (value) => {
    setTicketInProgress([]);

    let param = {};
    if (technicianId) {
      param["technicianId"] = technicianId;
    }
    if (timeRange.key !== "all") {
      param["startDate"] = timeRange.startDate;
      param["endDate"] = timeRange.endDate;
    }
    if (categoryName) {
      param["caseCategoryName"] = categoryName;
    }
    if (typeSearch) {
      param["search"] = typeSearch;
    }
    if (value !== "All") {
      param["subStatus"] = value;
    }
    let tokena = await getTokenApi();
    setCountProgress(1);
    setProgressStatusTypeId(value !== "All" ? value : null);
    dispatch(getInprogressTicket(tokena, 1, param));
  };

  const handleScheduledStatusChange = async (value) => {
    setTicketAcknowledge([]);

    let param = {};
    if (technicianId) {
      param["technicianId"] = technicianId;
    }
    if (timeRange.key !== "all") {
      param["startDate"] = timeRange.startDate;
      param["endDate"] = timeRange.endDate;
    }
    if (categoryName) {
      param["caseCategoryName"] = categoryName;
    }
    if (typeSearch) {
      param["search"] = typeSearch;
    }
    // if (value !== "All") {
    //   param["subStatus"] = value;
    // }
    let tokena = await getTokenApi();
    setCountAcknowledge(1);
    // setProgressStatusTypeId(value !== "All" ? value : null);
    dispatch(getAcknowledgedTicket(tokena, 1, param));
  };

  const handleCompletedStatusChange = async (value) => {
    setTicketClosed([]);
    let param = {};
    if (technicianId) {
      param["technicianId"] = technicianId;
    }
    if (timeRange.key !== "all") {
      param["startDate"] = timeRange.startDate;
      param["endDate"] = timeRange.endDate;
    }
    if (categoryName) {
      param["caseCategoryName"] = categoryName;
    }
    if (typeSearch) {
      param["search"] = typeSearch;
    }
    if (value !== "All") {
      param["subStatus"] = value;
    }
    let tokena = await getTokenApi();
    setCountClosed(1);
    setClosedStatusTypeId(value !== "All" ? value : null);
    dispatch(getClosedTicket(tokena, 1, param));
  };

  function onTopSearch(e) {
    // if (e?.length < 2)
    //   message.error("Please enter atleast two characters");
    // else
    settypeSearch(e);
  }

  function assignTechnician() {
    setTrigger(2);
  }

  function originalTicket(ticket) {
    callImages(ticket, true);
  }
  function activityLogTicket(ticket) {
    callImages(ticket, "activity");
  }

  function rejectTicketTicket(ticket) {
    callImages(ticket, "rejectTicket");
  }

  function viewTeanantDetail(ticket) {
    callImages(ticket, "tenantDetail");
  }

  function onAssign() {
    setTrigger(1);
  }

  const onClickExport = async () => {
    // const ticketsUrlExport = `${ticketBaseUrl}/maintenance/export?`;
    let tokena = await getTokenApi();
    // try {
    //   await fetch(
    //     ticketsUrlExport +
    //       new URLSearchParams({
    //         partyId: LocalStorageService.getPartyId(),
    //         ...dates,
    //       }),
    //     {
    //       method: "GET",
    //       headers: {
    //         Authorization: `${tokena?.token}`,
    //       },
    //     },
    //   )
    //     .then((response) => response.json())
    //     .then((rest) => {
    //       if (rest.errors) {
    //         message.error(rest.message);
    //       } else {
    //         message.success(rest.message);
    //       }
    //     });
    // } catch (e) {
    //   console.log("Err", e);
    // }
  };



  function addTicket() {
    setVisible(true);
    setTrigger(0);
  }

  function downloadAction() {
    setTrigger(8);
    setVisible(true);
  }

  const getMedia = async (totalImages) => {
    if (totalImages?.length > 0) {
      const result = await getPresignedImage(totalImages); //change
      if (result && result.length > 0) {
      } else {
        message.error("Something wrong with Media");
      }
      return result;
    }
    return [];
  };

  const callImages = async (item, recuring, type = "") => {
    if (
      recuring !== "activity" &&
      recuring !== "rejectTicket" &&
      recuring !== "tenantDetail"
    ) {
      let totalImages = [];
      let totalVideos = [];
      if (item.documents && item.documents.length > 0) {
        item.documents.map((x) => {
          if (x?.documentId && !x?.type.includes("mp4")) {
            totalImages.push(x.documentId);
          } else {
            totalVideos.push(x.documentId);
          }
        });
        const images = await getMedia(totalImages);
        const videos = await getMedia(totalVideos);
        item.totalImageURL = images;
        item.totalVideoUrls = videos;
      }

      let techtotalImages = [];
      let techtotalVideos = [];
      if (item?.technicianDocuments && item?.technicianDocuments?.length > 0) {
        item.technicianDocuments.map((x) => {
          if (x?.documentId &&  !x?.type.includes("mp4")) {
            techtotalImages.push(x.documentId);
          } else {
            techtotalVideos.push(x.documentId);
          }
        });
        const images = await getMedia(techtotalImages);
        const videos = await getMedia(techtotalVideos);
        item.techtotalImageURL = images;
        item.techtotalVideoUrls = videos;
      }
      // if (item.VideoObject && item.VideoObject.length > 0) {
      //   item.VideoObject.map((x) => {
      //     totalVideos.push(x.property_video);
      //   });
      //   const videoA = await getMedia(totalVideos);
      //   item.totalVideoUrls = videoA;
      // }
    }
    if (
      recuring &&
      recuring !== "activity" &&
      recuring !== "rejectTicket" &&
      recuring !== "tenantDetail"
    ) {
      setRecurringTicket({ ...item, ...{ ticketStatus: type } });
      setTrigger(3);
    } else if (recuring == "activity") {
      setActivityLogTicketData({ ...item, ...{ ticketStatus: type } });
      setTrigger(4);
      // console.log("ACTIVITY",item);
    } else if (recuring == "rejectTicket") {
      setRejectTicketData({ ...item, ...{ ticketStatus: type } });
      setTrigger(5);
    } else if (recuring == "tenantDetail") {
      setTenantDetailData({ ...item, ...{ ticketStatus: type } });
      setTrigger(6);
    } else {
      setTrigger(1);
      setVisible(true);
      setSelectedTickets({ ...item, ...{ ticketStatus: type } });
    }
  };

  async function viewTicket(item, type = "") {
    callImages(item, false, type);
  }

  const onStartDate = (e) => {
    setDates({
      from_date: moment(e).format("YYYY-MM-DD"),
      to_date: "",
    });
  };
  const onEndDate = (e) => {
    setDates({ ...dates, to_date: moment(e, "YYYY-").format("YYYY-MM-DD") });
  };

  const assignDateRange = (key) => {
    switch (key) {
      case "today":
        setTimeRange({
          startDate: currentDate,
          endDate: currentDate,
          key: key,
        });
        break;
      case 'week':
        setTimeRange({startDate: mondayDate, endDate: sundayDate, key: key})
      break;
    
      default:
        setTimeRange({ startDate: null, endDate: null, key: key });
        break;
    }
  };

  const searchTrigger = (e) => {
    console.log(e);
    if (e?.key === "today" || e?.key === "week" || e?.key === "all") {
      assignDateRange(e?.key);
    } else {
      setTechnicianId(e?.value);
    }
  };

  const handleClickAction = (item, e) => {
    if (e !== "select") {
      setTrigger(7);
      setVisible(true);
      setSelectedTickets({ ...item, ...{ ticketStatus: e } });
    }
  };

  return (
    <>
      <DashboardLayout>
        {/* <Row gutter={[24, 24]}>
          <Col span={24}>
            <BreadCrumb list={breadcrumbList} />
          </Col>
        </Row> */}

        <Row gutter={24}>
          <Col span={24}>
            {/* <SectionHeader
              heading="Tickets"
              total={
                (ticketsApiNew?.totalCount || 0) +
                (ticketsApiProgress?.totalCount || 0) +
                (ticketsApiClosed?.totalCount || 0)
              }
              // showOption={technicians}
              // handleShow={handleShowTechnician}
       
              search={(e) => onTopSearch(e)}
              headReverse={true}
              searchLabel="Search by ticket number"
              exportAction2={onClickExport}
              // exportDataHeader={exportDataHeader}
              // exportData={exportData}
              // filterAction={onClickFilter}
              addNewAction={Permissions.isAllowed(MODULES.ZONES, [READ]) && addTicket}

              viewRSelectOption={filterOptions}
              viewRSelectOptionAction={() => {}}
              onChange={(e) => {searchTrigger(e)}}
              viewOptionIcon={<FilterOutlined />}
              justifiedItems={true}
              justifyStart={true}
              handleView={handleShowCategory}
              
              onStart={onStartDate}
              onEnd={onEndDate}
              dates={dates}
            /> */}

            <SectionHeader
              heading="Tickets"
              noBorder={true}
              total={
                (ticketsApiNew?.totalCount || 0) +
                (ticketsApiProgress?.totalCount || 0) +
                (ticketsApiClosed?.totalCount || 0) +
                (ticketsApiAcknowledge?.totalCount || 0)
              }
              downloadAction={downloadAction}
              addNewAction={addTicket}
              // addNewAction={Permissions.isAllowed(MODULES.ZONES, [READ]) && addTicket}
              search={(e) => onTopSearch(e)}
              searchBy={(e) => handleShowCategory(e)}
              searchOptions={searchOptions}
              searchName="Category"
              // defaultSearch={search}

              viewOption={filterOptions}
              viewOptionLabel="Filter Technicians"
              justifiedItems={true}
              justifyEnd={true}
              handleView={searchTrigger}
              onStart={onStartDate}
              onEnd={onEndDate}
              dates={dates}
              onChange={(e) => {
                searchTrigger(e);
              }}
              exportAction2={onClickExport}
            />
            <Row gutter={24} style={{ marginTop: "13px" }}>
              <Col xl={24} lg={24}>
                <Row gutter={24}>
                  <Col span={6}>
                    <Tickets
                      ticketlist={ticketNew && ticketNew}
                      category="New"
                      count={ticketsApiNew?.totalCount || 0}
                      onCount={() => countUpdate("new")}
                      viewTicket={(e) => viewTicket(e, "new")}
                      clickDropDown={(item, e) => handleClickAction(item, e)}
                      showStatusOption={newStatusType}
                      handleStatusChange={handleNewStatusChange}
                      notifyCaseId={notifyCaseId}
                    />
                  </Col>
                  <Col span={6}>
                    <Tickets
                      ticketlist={ticketAcknowledge && ticketAcknowledge}
                      count={ticketsApiAcknowledge?.totalCount || 0}
                      onCount={() => countUpdate("acknowledge")}
                      category="Scheduled"
                      viewTicket={(e) => viewTicket(e, "acknowledge")}
                      clickDropDown={(item, e) => handleClickAction(item, e)}
                      showStatusOption={scheduledStatusType}
                      handleStatusChange={handleScheduledStatusChange}
                      notifyCaseId={notifyCaseId}
                    />
                  </Col>
                  <Col span={6}>
                    <Tickets
                      ticketlist={ticketInProgress && ticketInProgress}
                      count={ticketsApiProgress?.totalCount || 0}
                      onCount={() => countUpdate("progress")}
                      category="In Progress"
                      viewTicket={(e) => viewTicket(e, "progress")}
                      clickDropDown={(item, e) => handleClickAction(item, e)}
                      showStatusOption={progressStatusType}
                      handleStatusChange={handleProgressStatusChange}
                      notifyCaseId={notifyCaseId}
                    />
                  </Col>
                  <Col span={6}>
                    <Tickets
                      ticketlist={ticketClosed && ticketClosed}
                      count={ticketsApiClosed?.totalCount || 0}
                      onCount={() => countUpdate("closed")}
                      category="Closed"
                      viewTicket={(e) => viewTicket(e, "closed")}
                      clickDropDown={(item, e) => handleClickAction(item, e)}
                      showStatusOption={completedStatusType}
                      handleStatusChange={handleCompletedStatusChange}
                      notifyCaseId={notifyCaseId}
                      showClosedTickets={showClosedTickets}
                      setShowClosedTickets={() => {
                        setShowClosedTickets(!showClosedTickets);
                        callingClosedApi();
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </DashboardLayout>
      <Popup destroyOnClose={true} {...popupProps} />
    </>
  );
};
