import * as React from "react";

const index = (props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.97168 6.41683H18.6383"
      stroke="#2F2F37"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.47103 10.0835V15.5835"
      stroke="#2F2F37"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.138 10.0835V15.5835"
      stroke="#2F2F37"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.8877 6.4165L5.80436 17.4165C5.80436 18.429 6.62517 19.2498 7.6377 19.2498H14.971C15.9835 19.2498 16.8044 18.429 16.8044 17.4165L17.721 6.4165"
      stroke="#2F2F37"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.55469 6.41667V3.66667C8.55469 3.16041 8.96509 2.75 9.47135 2.75H13.138C13.6443 2.75 14.0547 3.16041 14.0547 3.66667V6.41667"
      stroke="#2F2F37"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default index;
