import axios from '../../../../../utils/axiosInceptor'
import * as action_types from "./constants";
import { maintenanceService } from "../../../../../configs/constants";

const areaUrl = `${maintenanceService}/maintenance/getAllAreas`;
const areaStatuUrl = `${maintenanceService}/maintenance/getAllZoneStatusTypes`;
const zonesUrl = `${maintenanceService}/zone/getAll`;
const unAssignedBuildingUrl = `${maintenanceService}/maintenance/serviceprovider/masterPropertiesTechnician`;


export const getAllAreas = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(areaUrl);
      dispatch({
        type: action_types.GET_ALL_AREAS,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getAreaStatus = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(areaStatuUrl);
      dispatch({
        type: action_types.GET_AREA_STATUS,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};


export const getListZones = (page, limit, search) => {
  return async (dispatch) => {
    const url = `${zonesUrl}?perPage=${limit}&page=${page}${
      search ? `&search=${search}` : ""
    }`;
    try {
      const {
        data: { result },
      } = await axios.get(url);
      dispatch({
        type: action_types.GET_ALL_ZONES,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getZoneData = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(unAssignedBuildingUrl);
      dispatch({
        type: action_types.GET_ALL_BUILDING,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};