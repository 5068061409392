import * as actionTypes from "./types";
import axios from "../../../../../../utils/axiosInceptor";
import { maintenanceService } from "../../../../../../configs/constants";
import dayjs from "dayjs";

export const getAllDayEntries = () => {
  return async (dispatch) => {
    const url = `${maintenanceService}/maintenance/getAllTimeEntryStatusTypes`;
    const response = await axios.get(url);
    dispatch({
      type: actionTypes.GET_DAYENTRIES,
      data: response?.data?.result,
    });
  };
};

export const getTechnicains = (page, limit, search) => {
  return async (dispatch) => {
    const url = `${maintenanceService}/facilityManager/getFacilityManagerTechnicians?limit=${limit}&page=${page}${
      search ? `&name=${search}` : ""
    }`;
    const response = await axios.get(url);
    dispatch({
      type: actionTypes.GET_TECHNICIANS,
      data: response?.data.result,
    });
  };
};

export const getTechnicainDrop = () => {
  return async (dispatch) => {
    const url = `${maintenanceService}/facilityManager/getTechniciansList`;
    const response = await axios.get(url);
    dispatch({
      type: actionTypes.GET_TECHNICIAN_DROP,
      data: response?.data.result,
    });
  };
};

export const getAllDutyRoster = (startDate, endDate, partId = []) => {
  return async (dispatch) => {
    const url = `${maintenanceService}/maintenance/getDutyRoster?start_date=${startDate}&end_date=${endDate}`;
    const response = await axios.get(url);
    dispatch({
      type: actionTypes.GET_DUTYROSTER,
      data: response?.data?.result,
    });
  };
};
//merged

export const getAllTechnicianRecords = (apiPayload) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.GET_ALL_RECORDS_TECHNICIAN,
        data: [],
      });
      dispatch(rosterLoader(true));
      const url = `${maintenanceService}/maintenance/getComputedRoaster`;

      const data = await axios.post(url, {
        endDate: apiPayload.endDate,
        search: apiPayload.search,
        startDate: apiPayload.startDate,
      });
      let temp = [];
      if (Object.keys(data?.data?.result).length) {
        Object.keys(data?.data?.result).forEach((element) => {
          temp.push(data?.data?.result[element]);
        });
      }
      dispatch({
        type: actionTypes.GET_ALL_RECORDS_TECHNICIAN,
        data: temp,
      });
      dispatch(rosterLoader(false));
    } catch (e) {
      dispatch(rosterLoader(false));
    }
  };
};

export const rosterDateRange = (dateRange) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SET_ROSTER_DATE_RANGE,
      data: dateRange,
    });
  };
};
export const setRosterWeek = (weekRange) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SET_ROSTER_WEEK,
      data: weekRange,
    });
  };
};

export const rosterLoader = (loaderState) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SET_ROSTER_LOADING,
      data: loaderState,
    });
  };
};

export const updateTechnicianSchedule = (data) => {
  const { getAllTechnicianRecords, apiPayload, dataGetRec } = data;
  return async (dispatch) => {
    dispatch(rosterLoader(true));
    const url = `${maintenanceService}/maintenance/setShiftChange`;
    const payload = {};
    const data = await axios.post(url, apiPayload);
    await dispatch(getAllTechnicianRecords(dataGetRec));
    dispatch(rosterLoader(false));
    return {};
  };
};

export const updateTechnicianScheduleRemove = (data) => {
  const { getAllTechnicianRecords, apiPayload, dataGetRec } = data;
  return async (dispatch) => {
    dispatch(rosterLoader(true));
    const url = `${maintenanceService}/maintenance/setUnavailability`;
    const payload = {};
    const data = await axios.post(url, apiPayload);
    await dispatch(getAllTechnicianRecords(dataGetRec));
    dispatch(rosterLoader(false));
    return {};
  };
};

export const getAllTechnicianShifts = () => {
  const filterFunction = (data) => {
    if (data && data.length) {
      const additionalProps = data.map((item) => {
        let fromTo = "";
        if (item?.shifts?.length === 1) {
          fromTo = item?.shifts[0]?.from;
          if (item?.shifts[0]?.to) {
            fromTo = fromTo + " - " + item?.shifts[0]?.to;
          }
        } else if (item?.shifts?.length === 2) {
          fromTo = item?.shifts[0]?.from;
          if (fromTo + item?.shifts[1]?.to) {
            fromTo = fromTo + " - " + item?.shifts[1]?.to;
          }
        } else {
          fromTo = " - ";
        }
        return {
          ...item,
          label: fromTo,
          _id: item._id,
          value: item.description,
        };
      });
      return additionalProps;
    }
  };
  return async (dispatch) => {
    dispatch(rosterLoader(true));
    const url = `${maintenanceService}/maintenance/getShifts`;
    const data = await axios.get(url);
    dispatch({
      type: actionTypes.GET_ALL_RECORDS_TECHNICIAN_SHIFTS,
      data: filterFunction(data?.data?.result),
    });
    dispatch(rosterLoader(false));
  };
};
