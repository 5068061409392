import React from "react";
import { Layout, Spin } from "antd";

import Header from "../Header";
import Sidebar from "../Sidebar";
import { LoadingOutlined } from "@ant-design/icons";
import "./captcha.scss";

const { Content } = Layout;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const DashboardLayout = (props) => {
  return (
    <Layout className="main-layout">
      <Header />
      <Layout>
        <Sidebar />
        <Layout className="layout-space">
          <Spin
            spinning={props.load || false}
            indicator={antIcon}
            style={{ position: "fixed" }}
          >
            <Content className={`main-content ${props.className}`}>
              {props.children}
            </Content>
          </Spin>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
