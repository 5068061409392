import React, { useEffect, useState } from "react";
import { Table } from "antd";
import moment from "moment";

export default (props) => {
  const { approvalData } = props;

  const [apData, setApData] = useState([]);

  const getModifiedStatus = (statusName) => {
    if (statusName.toLowerCase()?.includes("draft")) {
      return "Draft";
    }
    if (statusName.toLowerCase()?.includes("confirmed")) {
      return "Awaiting Approval";
    }
    if (statusName.toLowerCase()?.includes("approved")) {
      return "Approved";
    }
    if (statusName.toLowerCase()?.includes("rejected")) {
      return "Rejected";
    }
    if (statusName.toLowerCase()?.includes("process")) {
      return "N.A";
    }
  };

  const getName = (name) => {
    if (name.toLowerCase()?.includes("passport")) {
      return "Passport";
    }
    if (name.toLowerCase()?.includes("emirates id")) {
      return "Emirates ID";
    }
    if (name.toLowerCase()?.includes("visa")) {
      return "Visa";
    }
  };
  useEffect(() => {
    if (approvalData && approvalData.length > 0) {
      const cache = [];
      approvalData.map((e, index) => {
        cache.push({
          sno: index + 1,
          documentType: getName(e.documentType),
          approver: e.approver || "N/A",
          status: getModifiedStatus(e.status),
          comment: e.comment,
          createdAt: e.createdAt,
        });
      });
      setApData(cache);
    }
  }, [approvalData]);

  const col = [
    { title: "#", dataIndex: "sno", key: "sno", width: 80 },
    { title: "Document", dataIndex: "documentType", key: "documentType" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "Approver", dataIndex: "approver", key: "approver" },
    { title: "Comment", dataIndex: "comment", key: "comment" },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => moment(text).format("DD-MM-YYYY hh:mmA"),
    },
  ];

  return <Table columns={col} dataSource={apData} pagination={false} />;
};
