import React, { useEffect, useState } from "react";
import { Button, Col, Row, Space, Table } from "antd";
import { InputField, ReactSelectField } from "../../../atoms/FormElement";
import { currencyFormat } from "../../../../utils/media";

export default (props) => {
    const { plansData, rowSelection, setSelectedRowKeys, selectedPlan, confirmChange } = props;

    const columns = [
        {
            title: "No. of Payments",
            dataIndex: "installments",
            width: 300,
            render: (text) => <span className="ag-default">{text} Cheque(s)</span>,
        },
        {
            title: "Annual Rent",
            dataIndex: "annualRent",
            align: 'right' as const,
            render: (text) => <div className="ag-default">{currencyFormat(+text)}</div>,
        },
        {
            title: "No. of Years",
            dataIndex: "noOfYear",
            align: 'right' as const,
            render: (text) => <span className="ag-default">{text}</span>,
        }, {
            title: "Security Deposit",
            dataIndex: "securityDeposit",
            align: 'right' as const,
            render: (text) => <div className="ag-default">{currencyFormat(+text)}</div>,
        },
        // {
        //     title: "Ejari",
        //     dataIndex: "ejari",
        //     render: (text) => <span className="ag-default">{text}</span>,
        // },
    ];


    useEffect(() => {
        if (plansData?.length > 0 && selectedPlan?.length > 0 && setSelectedRowKeys) {
            let rows = plansData.filter(item =>
            (
                // selectedPlan?.includes(item?.unitPaymentPlanId) || 
                selectedPlan?.includes(item?.paymentPlanId)
            )).map(option => plansData.indexOf(option));
            // console.log("selectkey", selectedPlan, plansData, rows);
            rows?.length > 0 && setSelectedRowKeys(rows);
        }
    }, [(props?.booking && plansData)]); //addded to fix the issue of bookingterms dependency

    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <Table
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={plansData}
                        pagination={false}
                        bordered={false}
                        // showHeader={false}
                        className="sideTable"
                        size="small"
                    />
                </Col>
            </Row>
            <br />
            {confirmChange && (
                <Row gutter={24} justify="space-between">
                    <Col>
                        {/* <Button size="large" >
                            Back
                        </Button> */}
                    </Col>
                    <Col>
                        <Button type="primary" className="btn-blue"
                            onClick={confirmChange}>
                            Confirm
                        </Button>
                    </Col>
                </Row>
            )}
        </>
    );
};
