export const changeValueOptions = [
  {
    label: "No change",
    value: "no_change",
  },
  {
    label: "Increase",
    value: "increase",
  },
  {
    label: "Decrease",
    value: "decrease",
  },
];

export const options = [
  {
    label: "Amount",
    value: "amount",
  },
  {
    label: "Percentage",
    value: "percentage",
  },
];

export const paymentModes = [
  {
    id: "cheque",
    value: "cheque",
    label: "cheque",
  },
  {
    id: "cash",
    value: "cash",
    label: "Cash",
  },
  {
    id: "bank_transfer",
    value: "bank_transfer",
    label: "Bank Transfer",
  },
  {
    id: "credit_card",
    value: "credit_card",
    label: "Credit Card",
  },
];

export const rentAmountFormatter = (value: string) =>
  value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
