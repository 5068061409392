import * as action_types from "./constants";
import axios from "../../../../utils/axiosInceptor";
import { maintenanceService } from "../../../../configs/constants";
const assignedBaseURL = `${maintenanceService}/maintenance/serviceprovider/assigned/list`;

export const getAssignedList = (page = 1) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(assignedBaseURL);
      dispatch({
        type: action_types.GET_ASSIGNED_LIST,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getServiceProviders = (page = 1) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(`${maintenanceService}/organisation/serviceprovider`);
      dispatch({
        type: action_types.GET_SERVICE_PROVIDER,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getServiceProvidersMoveOut = async (page = 1) => {
  try {
    const {data: { result },} = await axios.get(`${maintenanceService}/organisation/serviceprovider`);
    return result;
  } catch (e) {
    console.log("Err", e);
  }
};