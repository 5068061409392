import React from "react";

const index = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4023 17.8477L4.65234 11.0977C4.30078 10.7812 4.30078 10.2539 4.65234 9.90234C4.96875 9.58594 5.49609 9.58594 5.84766 9.90234L12 16.0898L18.1523 9.9375C18.4688 9.58594 18.9961 9.58594 19.3477 9.9375C19.6641 10.2539 19.6641 10.7812 19.3477 11.0977L12.5625 17.8477C12.2461 18.1992 11.7188 18.1992 11.4023 17.8477Z"
        fill="#2F2F37"
      />
    </svg>
  );
};

export default index;
