
import React, { useState, useEffect } from "react";
import {
    Layout,
    Row,
    Col,
    Typography,
    Button,
    message,
    Switch,
    Spin
} from "antd";


import axios from 'axios';
import { updatePDCStatus, getOnePDC } from "../../ducks/actions";
import type { UploadFile } from 'antd/es/upload/interface';
import { DeleteOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons';
import Grid from "../../../../atoms/Grid";
import { dateByformat } from "../../../../../utils/dateHelper";
import { currencyFormat } from "../../../../../utils/media";
import { Upload } from 'antd';
import { RcFile } from "antd/lib/upload/interface";
import { useLocation } from 'react-router-dom'
import { getPresignedImage, uploadDocumentMedia } from "../../../../../utils/media";
import { leaseService } from "../../../../../configs/constants";
import { PopupConfirm } from "../../../../atoms/Popup";
const { Dragger } = Upload;
const { Content } = Layout;

const { Title } = Typography;
const gridProps = {
    sortable: true,
    filter: true,
    resizable: true,
    minWidth: 200,
  };

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  
export default ({ list, onUpdate, setIsRequestLoading, isLoading, showAll, getAllPayments }) => {

    let query = useQuery();

    const [pdcList, setPdcList] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if(query.get("pdcId") && !showAll) {
        ;(async () => {
          setLoading(true)
          
          const pdc = await getOnePDC(query.get("pdcId"))

          if(pdc?.status === 200 && pdc?.data?.result?.creditCardRequest === "pending") {

            setPdcList([pdc?.data?.result]);
            setLoading(false)
            
          } else {
            getPendingCreditCardRequests()
          }
  
        })()
      } else {
        getPendingCreditCardRequests()
      }

      return () => {
          setPdcList([])
      }

    }, [])

    const convertToNormalCase = (input = 'Document') => {
      // Check if the input is in camel case
      if (input.indexOf('_') === -1 && input !== input.toLowerCase()) {
        return input.replace(/([a-z])([A-Z])/g, '$1 $2');
      }
      
      // Check if the input is in snake case
      if (input.indexOf('_') !== -1) {
        return input.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
      }
      
      return input.charAt(0).toUpperCase() + input.slice(1); // Already in normal case
    }

    const pdcCol = [
        {
          headerName: "Requested Date",
          field: "creditCardRequestedAt",
          cellRendererFramework: ({ data }) => {
            return data.creditCardRequestedAt ? dateByformat(data.creditCardRequestedAt, "DD-MM-YYYY hh:mm:ss") : '--';
          },
        },
        {
          headerName: "Property",
          field: "propertyName",
          cellRendererFramework: ({ data }) => {
            return data?.propertyDetails?.propertyName || data?.propertyName
          },
        },
        {
          headerName: "Unit Number",
          field: "unitCode",
          cellRendererFramework: ({ data }) => {
            return data?.propertyDetails?.unitNumber || '--'
          },
        },
        {
          headerName: "Due Date",
          field: "dueDate",
          cellRendererFramework: ({ data }) => {
            return dateByformat(data.dueDate, "DD-MM-YYYY");
          },
        },
        {
          headerName: "Payment Type",
          field: "description",
          cellRendererFramework: ({ data }) => {
            return `${convertToNormalCase(data.description)}`;
          }
        },
        {
          headerName: "Amount",
          field: "actualAmount",
          cellRendererFramework: ({ data }) => {
            return (<div className="annualRent">{currencyFormat(data?.actualAmount + data?.vat)}</div>)
          },
        },
        {
          headerName: "Payment Mode",
          field: "modeOfPayment",
          cellRendererFramework: ({ data }) => {
            return convertToNormalCase(data.modeOfPayment);
          }
        },
        {
          headerName: "Payment Ref",
          field: "documentNo",
        },
    
        {
          headerName: "Action",
          cellRendererFramework: viewRender,
          cellStyle: { textAlign: "center" },
          maxWidth: 240,
          filter: false,
        },
      ];

    function viewRender({data}) {
        return (
            <>
                <Row>
                    <Col span={12}>
                        <Button type="primary" onClick={() => onUpdateCheckStatus('approved', data)}>Accept</Button>
                    </Col>
                    <Col span={12}>
                        <Button danger style={{borderColor: 'red'}} type="text" onClick={() => onUpdateCheckStatus('rejected', data)}>
                            Reject
                        </Button>
                    </Col>
                </Row>
            </>
        );
    }

    const getPendingCreditCardRequests = async () => {
      try {
        const url = `${leaseService}/booking/pdclist?creditCardRequest=pending`;
  
        const response = await axios.get(url);
        if(response.status === 200) {
          setPdcList(response?.data?.result?.data)
          setLoading(false)
        }
      } catch (error) {
        
      }
    }

    const onUpdateCheckStatus = async (status, record) => {
        try {
          setLoading(true)
            const res = await updatePDCStatus(
                {
                leaseId: record.leaseId,
                pdcId: record._id,
                },
                { creditCardRequest: status }
            );
            getAllPayments()
            if(pdcList.length === 1) {
              onUpdate()
            } else {
              getPendingCreditCardRequests()
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (

        <Layout className="layout-space prop_popup">
            <Content className="main-content" >
                {(!loading) && (
                    <Row gutter={[32, 32]}>
                        <Col span={24}>
                            <Row gutter={[24, 24]}>
                                <Col span={24}>
                                    <Row gutter={[24, 24]} justify="end">
                                        <Col span={24}>
                                            <Grid
                                                // ref={gridRef}
                                                data={pdcList}
                                                columns={pdcCol}
                                                pagination={false}
                                                defaultSettings={gridProps}
                                                noRowlabel="Payments"
                                                fullPage={true}
                                                overlayNoRowsTemplate={
                                                "Payments data cannot be found."
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}

                {loading && (
                    <div className="justify-center" style={{ textAlign: "center", minHeight: "255px" }}>
                        <Spin />
                    </div>
                )}

            </Content>
        </Layout>
    );
};