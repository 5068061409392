import React from "react";

const index = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 9V6H6C5.57812 6 5.25 5.67188 5.25 5.25C5.25 4.875 5.57812 4.5 6 4.5H9V3C9 2.20312 8.29688 1.5 7.5 1.5H3C2.15625 1.5 1.5 2.20312 1.5 3V16.5V21C1.5 21.0938 1.5 21.1406 1.5 21.1875C1.54688 21.9375 2.20312 22.5 3 22.5H7.5H21C21.7969 22.5 22.5 21.8438 22.5 21V16.5C22.5 15.7031 21.7969 15 21 15H19.5V18C19.5 18.4219 19.125 18.75 18.75 18.75C18.3281 18.75 18 18.4219 18 18V15H15V18C15 18.4219 14.625 18.75 14.25 18.75C13.8281 18.75 13.5 18.4219 13.5 18V15H10.5V18C10.5 18.4219 10.125 18.75 9.75 18.75C9.32812 18.75 9 18.4219 9 18V15H6C5.57812 15 5.25 14.6719 5.25 14.25C5.25 13.875 5.57812 13.5 6 13.5H9V10.5H6C5.57812 10.5 5.25 10.1719 5.25 9.75C5.25 9.375 5.57812 9 6 9H9ZM12 13.5H21C22.6406 13.5 24 14.8594 24 16.5V21C24 22.6875 22.6406 24 21 24H7.5H3C1.40625 24 0.140625 22.8281 0 21.3281C0 21.2344 0 21.1406 0 21V16.5V3C0 1.35938 1.3125 0 3 0H7.5C9.14062 0 10.5 1.35938 10.5 3V12V13.5H12Z"
        fill="#24435D"
      />
    </svg>
  );
};

export default index;
