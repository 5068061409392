import React, { useState, useEffect } from "react";
import { Col, Row, Image, Descriptions, Radio } from "antd";
import moment from "moment";
import { InputRadio } from "../../../atoms/FormElement";
import DescriptionsItem from "antd/lib/descriptions/Item";
import { getPresignedImage } from "../../../../utils/media";

export default (props) => {
  const { data, index, control, popup } = props;
  const [propertyImage, setPropertyImage] = useState("");

  useEffect(() => {
    if (data) {
      caller(data?.objectKey);
    }
  }, [data]);

  const presinedImageUrl = async (media) => {
    if (media) {
      const url = await getPresignedImage(media);
      return url;
    }
  };

  const caller = async (key) => {
    let img = "";
    if (key) {
      img = await presinedImageUrl(key)[0];
    }
    setPropertyImage(img);
  };

  const onChange = (e) => {
    popup(e.target.value, data.id, `cheque${index + 1}`);
  };

  return (
    <Row gutter={[24, 24]}>
      <Col span={24}>
        <Descriptions layout="vertical" bordered column={5} className="w-100">
          <Descriptions.Item label="Due Date">
            {data?.cheque_date
              ? moment(data.cheque_date).format("Do MMMM YYYY")
              : ""}
          </Descriptions.Item>
          <Descriptions.Item label="Rent">AED {data?.amount}</Descriptions.Item>
          <Descriptions.Item label="VAT Amount">
            AED {data?.vat}
          </Descriptions.Item>
          <Descriptions.Item label="Total Amount">
            AED {data?.totalAmount}
          </Descriptions.Item>
          <DescriptionsItem label="Status">
            {data?.chequeStatus}
          </DescriptionsItem>
          <DescriptionsItem label="">
            <InputRadio
              class="error-set"
              isRequired={true}
              fieldname={`cheque${index + 1}`}
              control={control}
              onChange={onChange}
              initValue={data.chequeStatus}
              noStyle={false}
              options={
                <>
                  <Radio
                    className="radio-group-reject"
                    value="Rejected"
                    disabled={data?.chequeStatus == "Rejected" ? true : false}
                  >
                    Reject
                  </Radio>
                  <Radio
                    className="radio-group-accept"
                    value="Approved"
                    disabled={data?.chequeStatus == "Rejected" ? true : false}
                  >
                    Approve
                  </Radio>
                </>
              }
            />
          </DescriptionsItem>
        </Descriptions>
      </Col>
      <Col span={24}>
        <Image
          preview={false}
          //   width={235}
          //   height={165}
          placeholder={true}
          alt="Tenant Cheques"
          src={propertyImage}
        />
      </Col>
    </Row>
  );
};
