
import React, { useState} from "react";
import { useDispatch} from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Typography,
  Form,
  Button,
  Popover,
  message,
} from "antd";
import {
  UserOutlined,
  LockOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { setCookie } from "../../../utils/helper";
import LoginTemplate from "./LoginTemplate";
import axios from "axios";
import { authService } from "../../../configs/constants";

import { useForm } from "react-hook-form";
import {
  InputField,
  InputPassword,
  SelectField,
} from "../../atoms/FormElement";
import Permissions from "../../../utils/permissions";
import { MODULES } from "../../../configs/modules";
import { READ } from "../../../configs/operations";
import { getUserScreenAccess } from "./ducks/actions";
const { Text, Paragraph } = Typography;

export default () => {
  const [loading, setLoading] = useState(false);
  const [loginStatus, setLoginStatus] = useState(false);
  const [loginStatus1, setLoginStatus1] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const dispatcher = useDispatch()
;  const location = useLocation();
  const { pathname, search } = location.state || {pathname:null,search:null};

  const { control, handleSubmit, formState: { errors } } = useForm();

  let token = JSON.parse(window.localStorage.getItem("login-response"))?.token;

  React.useEffect(() => {
    token && navigate("/dashboard");
  }, []);




  const onFinish = async (values) => {
    setLoginStatus(false);
    setLoading(true);

    let user = values.username;
    if (values.username) {
      user = user;
    } else {
      user = values.phone;
    }

    const url = `${authService}/login`;
    const postJson = {
      username: user?.toLowerCase(),
      password: values.password,
      usertype: "institutionalLandlord",
    };
    try {
      const { data: { result }} = await axios.post(url, postJson, { headers: { appName: "institutionalLandlordPortal" } });
      
    //  check the permissions of screen for every login user
      if(result?.accessibleScreens && result?.accessibleScreens?.length > 0){
        dispatcher(getUserScreenAccess(result?.accessibleScreens));
      }else{
        dispatcher(getUserScreenAccess([]));
      }
     


      await window.localStorage.setItem(
        "login-response",
        JSON.stringify(result),
      );
      setCookie("landlordUser", true, new Date(result?.exp).getDate());
      
      if (Permissions.isAllowed(MODULES.PROPERTIES, [READ])) {
        if (pathname && search)
          setTimeout(() => { navigate(pathname + search); }, 1400);
        else
          setTimeout(() => { navigate("/dashboard"); }, 1400);
      }
      else if (Permissions.isAllowed(MODULES.CONFIGURATIONS, [READ]))
        setTimeout(() => { navigate("/categories"); }, 1400);
      else
        setTimeout(() => { navigate("/dashboard"); }, 1400);

    } catch (error) {
      setLoading(false);
      const { response } = error;
      if (response?.data?.error) {
        setErrorMessage(response?.data?.error);
        if (response?.data?.message.search("password") > 0) {
          setLoginStatus(true);
        } else {
          setLoginStatus1(true);
        }
      } else {
        setLoginStatus(false);
        setLoginStatus1(false);
        message.error("Invalid Attempt");
      }
    }
  };


  return (
    <LoginTemplate load={loading}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <h2 className="signin_heading">Sign in with your account</h2>
        </Col>
        <Col span={24}>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={handleSubmit(onFinish)}
          >
            <>
              <Popover
                placement="right"
                content={
                  <Text type="danger">
                    <InfoCircleOutlined /> {errorMessage}
                  </Text>
                }
                onVisibleChange={() => setLoginStatus1(false)}
                trigger="click"
                visible={loginStatus1}
              >
                <InputField
                  fieldname="username"
                  control={control}
                  initValue=""
                  iProps={{
                    placeholder: "Email",
                    size: "large",
                    prefix: (
                      <UserOutlined className="site-form-item-icon" />
                    ),
                  }}
                  rules={{
                    pattern: {
                      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: "Enter valid Email Address",
                    },
                    required: "Please enter Email Address",
                  }}
                  validate={errors.username && "error"}
                  validMessage={
                    errors.username && errors.username.message
                  }
                />
              </Popover>
              <Popover
                placement="right"
                content={
                  <Text type="danger">
                    <InfoCircleOutlined /> {errorMessage}
                  </Text>
                }
                onVisibleChange={() => setLoginStatus(false)}
                trigger="click"
                visible={loginStatus}
              >
                <InputPassword
                  fieldname="password"
                  control={control}
                  initValue=""
                  iProps={{
                    placeholder: "Password",
                    size: "large",
                    prefix: (
                      <LockOutlined className="site-form-item-icon" />
                    ),
                  }}
                  rules={{
                    pattern: {
                      message: "Please enter correct password",
                    },
                    required: "Please enter password",
                  }}
                  validate={errors.password && "error"}
                  validMessage={
                    errors.password && errors.password.message
                  }
                />
              </Popover>
            </>
          

            <Form.Item>
            
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <label>
                  <input type="checkbox" />
                  Remember me
                </label>
              </Form.Item>

              <Link to="/forgot-password" className="login-form-forgot">
                Forgot password?
              </Link>
            </Form.Item>

            <Form.Item className="mb-0">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                size="large"
              >
                Sign in
              </Button>
            </Form.Item>
           
          </Form>
        </Col>

       
        <Col className="donthave_acc">
          <h3>Don't have an account yet? <Link to="/signup">Sign up here.</Link></h3>
        </Col>
        <Col span={24}>
          <Paragraph className="copyright">Copyright © 2024</Paragraph>
        </Col>
      </Row>
    </LoginTemplate>
  );
};
