import React, { ReactElement } from "react";

// others
import { Button } from "antd";

interface IProps {
  type?: any;
  label: string;
  onClick?: any;
  style?: object;
  className?: string;
  disabled?: boolean;
  icon?: ReactElement;
}

const index = (props: IProps) => {
  return (
    <Button
      icon={props.icon}
      style={props.style}
      htmlType={props.type}
      onClick={props.onClick}
      disabled={props.disabled}
      className={`bf-button ${props.className}`}
    >
      {props.label}
    </Button>
  );
};

export default index;
