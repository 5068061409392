import React from "react";

const index = () => {
    return (
        <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.00781 7.03125V8.4375C7.00781 8.92969 6.62109 9.28125 6.16406 9.28125C5.67188 9.28125 5.32031 8.92969 5.32031 8.4375V7.03125C5.32031 5.16797 6.83203 3.65625 8.69531 3.65625C10.5586 3.65625 12.0703 5.16797 12.0703 7.03125V7.3125C12.0703 8.12109 12 8.89453 11.8945 9.66797L16.5703 8.22656C16.8867 8.12109 17.2383 8.19141 17.4492 8.4375C17.6602 8.64844 17.7305 9 17.625 9.31641L16.6406 12.0938H20.2266C20.6836 12.0938 21.0703 12.4805 21.0703 12.9375C21.0703 13.4297 20.6836 13.7812 20.2266 13.7812H15.4453C15.1641 13.7812 14.8828 13.6758 14.7422 13.4297C14.5664 13.2188 14.5312 12.9375 14.6367 12.6562L15.4805 10.3359L11.5781 11.5312L11.543 11.707C11.1211 13.6758 10.3477 15.5742 9.29297 17.2969L8.76562 18.2109C8.30859 18.9844 7.5 19.4062 6.65625 19.4062C5.28516 19.4062 4.19531 18.3164 4.19531 16.9453V14.3438C4.19531 12.9727 5.07422 11.7773 6.375 11.3906L10.1367 10.1953C10.2773 9.24609 10.3828 8.29688 10.3828 7.3125V7.03125C10.3828 6.11719 9.60938 5.34375 8.69531 5.34375C7.74609 5.34375 7.00781 6.11719 7.00781 7.03125ZM6.86719 12.9727C6.26953 13.1836 5.88281 13.7109 5.88281 14.3438V16.9805C5.88281 17.4023 6.19922 17.7539 6.65625 17.7539C6.90234 17.7539 7.14844 17.5781 7.28906 17.3672L7.85156 16.418C8.66016 15.082 9.29297 13.6406 9.71484 12.0938L6.86719 12.9727ZM3.07031 15.4688V16.9453C3.07031 17.0156 3.07031 17.0859 3.07031 17.1562H1.66406C1.17188 17.1562 0.820312 16.8047 0.820312 16.3125C0.820312 15.8555 1.17188 15.4688 1.66406 15.4688H3.07031ZM10.6641 17.1562C10.9805 16.6289 11.2617 16.0664 11.5078 15.4688H22.4766C22.9336 15.4688 23.3203 15.8555 23.3203 16.3125C23.3203 16.8047 22.9336 17.1562 22.4766 17.1562H10.6641Z" fill="#24435D" />
        </svg>
    );
};

export default index;
