import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Typography, message, Space, Spin } from "antd";
import { useForm} from "react-hook-form";
import {
  ExclamationCircleOutlined
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { InputField, ReactSelectField, SelectField } from "../../../atoms/FormElement";
import { propertyService } from "../../../../configs/constants";
import axios from "../../../../utils/axiosInceptor";
import { getPropertiesDrop } from "../../Properties/ducks/actions";
import { getMasterManagers } from "../ducks/actions"; 
import { getConfigDataService } from "../../BookingForm/ducks/services";


const { Paragraph } = Typography;

var _ = require("lodash");

const defaultValues = {
  name: "",
  email: "",
  mobile: "",
  organisation: "",
  businessUnit: [],
  roles: [],
  status: 'enable'

};



const managerStatus = [{ value: 'enable', label: 'Active' }, { value: 'disable', label: 'In Active' }]

const visibilityOptions = [
  { value: 'own_data', label: 'Own data only' },
  { value: 'own_and_team_data', label: 'Own and team data' },
  { value: 'all_data', label: 'All data' },

];        
const AddManager = (props) => {
  const dispatch: any = useDispatch();
  const configKeys = ["requiredBookingDocumentsList"];
  const propertiesList = useSelector((state: any) => state.dashboard.propertiesDrop);

  const allManagers = useSelector((state: any) => state.managers.allManagers) || [];

  const userInformation = JSON.parse(localStorage.getItem('login-response'))

  const { onUpdate, onchangeUpdate } = props;
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [managerArr, setManagerArr] = useState([]);
  const[screenData,setScreenData] = useState([]);

  const {
    control, formState: { errors },
    reset,
    handleSubmit,
    setValue,
  } = useForm(); //{ defaultValues }
  
  const resetForm = () => {
    if (props.editMode == false) {
      reset(defaultValues);
    }
    // props?.setOnClose(false);
  };

  useEffect(() => {
    resetForm();
  }, [props?.onClose]);

  useEffect(() => {
    if (propertiesList.length == 0)
      dispatch(getPropertiesDrop());
  }, [propertiesList]);


  const getScreenList = async ()=>{

    try{

      const screenList = await  getConfigDataService(configKeys);
      const configDataCopy = screenList?.reduce((acc, curr) => ({ ...acc, ...curr }),{});
      let screenDataList:any = [];
      for(let key in configDataCopy?.llaccessScreenList)
        {
          screenDataList.push({label:configDataCopy?.llaccessScreenList[key],value:key});  
        }
      setScreenData(screenDataList)
     }catch(error){
      console.log(error)
    }
  }

  useEffect(() => {
      dispatch(getMasterManagers());
      getScreenList();
  }, [props?.data?.email]);

  useEffect(() => {
    if (allManagers && allManagers?.length !== 0 && props?.data?.managerId) {
      const abc = _.map(allManagers.filter(manager => manager?.userId !== props?.data?.userId), (item) => ({
        value: item?.userId,
        label: item?.name,
      }))
      
      setValue(
        "manager",
        {
          value: props.data.managerId,
          label: allManagers.find(manager => manager.userId === props.data.managerId)?.name,
        }
      );
      setManagerArr(abc)
    } else if (allManagers && allManagers?.length !== 0) {
      const abc = _.map(allManagers.filter(manager => manager?.userId !== props?.data?.userId), (item) => ({
        value: item?.userId,
        label: item?.name,
      }))
      setManagerArr(abc)
    }

    if (props?.data?.managerId) {
      const abc = _.map(allManagers.filter(manager => manager?.userId !== props?.data?.userId), (item) => ({
        value: item?.userId,
        label: item?.name,
      }))

      setValue(
        "manager",
        {
          value: props.data.managerId,
          label: allManagers.find(manager => manager.userId === props.data.managerId)?.name,
        }
      );
      setManagerArr(abc)
    } else if(allManagers && allManagers.length > 0) {
 
      
      const abc = _.map(allManagers.filter(manager => manager?.userId !== props?.data?.userId), (item) => ({
        value: item?.userId,
        label: item?.name,
      }))
      setManagerArr(abc)
      setValue(
        "manager",
        null
      );
    }
  }, [allManagers]);

  useEffect(() => {

    if (props.data) {
      setValue("name", props.data.name, { shouldValidate: true });
      setValue("email", props.data.email, { shouldValidate: true, });
      setValue("mobile", props.data.mobile, { shouldValidate: true, });

      if (props.data.status) {
        setValue(
          "status",
          {
            value: props.data.status,
            label: props.data.status === 'disable' ? 'In Active' : 'Active',
          } || "",
          { shouldValidate: true, }
        );
      }

      if (props?.data?.visiblity) {
        setValue(
          "visibility",
          {
            value: props.data.visiblity,
            label: visibilityOptions.find(status => status.value === props.data.visiblity)?.label,
          }
        );
      }
      if (props?.data?.managerId) {
        const abc = _.map(allManagers.filter(manager => manager?.userId !== props?.data?.userId), (item) => ({
          value: item?.userId,
          label: item?.name,
        }))

        setValue(
          "manager",
          {
            value: props.data.managerId,
            label: allManagers.find(manager => manager.userId === props.data.managerId)?.name,
          }
        );
        setManagerArr(abc)
      } else if(allManagers && allManagers.length > 0) {
        const abc = _.map(allManagers.filter(manager => manager?.userId !== props?.data?.userId), (item) => ({
          value: item?.userId,
          label: item?.name,
        }))
        setManagerArr(abc)
        setValue(
          "manager",
          null
        );
      }

      if (props.data?.properties.length > 0) {
        const selectedProperties = _.map(props.data.properties, (item) => { return { label: item.propertyName, value: item.propertyId } })
        setValue("properties", selectedProperties, { shouldValidate: true, });
      }

      if (screenData?.length > 0 && props.data?.screens && props.data?.screens.length > 0) {
        const filterInfo = _.map(screenData, (item) =>{ return props.data?.screens.includes(item?.value) && {value:item?.value,label:item.label}})
    
        filterInfo?.length> 0 &&  setValue("screens", filterInfo, { shouldValidate: true, });
      }
 
    } else {
      reset();
      setValue(
        "status",
        {
          value: 'enable',
          label: 'Active',
        } || "",
        { shouldValidate: true, }
      );
    }

    return () => {
      reset();
      setValue(
        "status",
        {
          value: 'enable',
          label: 'Active',
        } || "",
        { shouldValidate: true, }
      );
      setValue(
        "manager",
        {
          value: '',
          label: '',
        } || "",
        { shouldValidate: true, }
      );
      setManagerArr([])
    }
  }, [props.data,screenData]); //organizationList rolesList



  const onFinish = async (values) => {
    setLoader(true);
    setSubmited(true);

    let postJson = {};
    if (props.editMode == true && props.data) {
      postJson = {
        name: values.name,
        properties: _.map(values.properties, (item) => item.value),
        status: values.status.value,
        accessibleScreens:values?.screens?.length > 0 ? _.map(values.screens, (item) => item.value) : [],
        visiblity: values?.visibility?.value,
        managerId: values?.manager?.value,
       
      };

      const url = `${propertyService}/landlord/user/${props.data.userId}`;

      axios.patch(url, postJson).then((response) => {
        let { data } = response;
        setLoader(false);
        setSubmited(false);
        reset();
        message.success(data?.message);
        onUpdate(!onchangeUpdate)
        props.onSubmit();
      }).catch((error) => {
        message.error(error.response.data.error)
        setLoader(false);
        setSubmited(false);
      })
    } else {
      postJson = {
        name: values.name,
        email: values.email,
        mobile: values.mobile,
        accessibleScreens:values?.screens?.length > 0 ? _.map(values.screens, (item) => item.value) : [],
        status: values.status.value,
        properties: _.map(values.properties, (item) => item.value),
        landlordId: userInformation.userId ?? "",
        orgId: userInformation.orgId ?? "",
        visiblity: values?.visibility?.value,
        managerId: values?.manager?.value,
      };

      const url = `${propertyService}/landlord/user`;

      axios.post(url, postJson).then((response) => {

        let { data } = response;
        setLoader(false);
        setSubmited(false);
        reset();
        setValue(
          "status",
          {
            value: 'enable',
            label: 'Active',
          } || "",
          { shouldValidate: true, }
        );
        message.success(data?.message);
        onUpdate(!onchangeUpdate)
        // props.popUpClose(false);
        props.onSubmit();
      }).catch((error) => {

        message.error(error.response.data.error)
        setLoader(false);
        setSubmited(false);
      })

    }
  };

  return (
    <>
      <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
        {loader && !isError && (
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Space
                size={24}
                className="w-100 text-center"
                direction="vertical"
              >
                <Spin size="large" className="ag-fontSize32" />
              </Space>
            </Col>
          </Row>
        )}
        {!submited ? (
          <>
            <Row gutter={24}>
              <Col span={12}>
                <InputField
                  isRequired={true}
                  fieldname="name"
                  label="Name"
                  control={control}
                  rules={{
                    required: "Please enter full name",
                  }}
                  iProps={{ placeholder: "Enter Full Name" }}
                  initValue=""
                  validate={errors.name && "error"}
                  validMessage={errors.name && errors.name.message}
                />
              </Col>
              <Col span={12}>
                <InputField
                  isRequired={true}
                  fieldname="email"
                  label="Email"
                  control={control}
                  rules={{
                    pattern: {
                      value: /(?=^.{1,50}$)^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: "Enter valid Email Address",
                    },
                    required: "Please enter Email Address",
                  }}
                  iProps={{ placeholder: "user@gmail.com" }}
                  disabled={props.editMode}
                  initValue=""
                  validate={errors.email && "error"}
                  validMessage={
                    errors.email && errors.email.message
                  }
                />
              </Col>
              <Col span={12}>
                <InputField
                  isRequired={true}
                  label="Phone"
                  fieldname="mobile"
                  control={control}
                  validate={errors.mobile && "error"}
                  validMessage={
                    errors.mobile && errors.mobile.message
                  }
                  initValue=""
                  rules={{
                    minLength: {
                      value: 9,
                      message:
                        "Please enter valid mobile number, 9 digits required",
                    },
                    maxLength: {
                      value: 10,
                      message:
                        "Phone number can not be more than 10 digits",
                    },
                    required: "Please enter Mobile No.",
                    setValueAs: (value) => `${value}`,
                  }}
                  disabled={props.editMode}
                  iProps={{
                    placeholder: "Add Mobile Number",
                    addonBefore: "+971",
                    type: "number",
                    className: "apperance",
                  }}
                />
              </Col>
              <Col span={12}>
                <ReactSelectField
                  isRequired={true}
                  fieldname="properties"
                  label="Properties"
                  control={control}
                  validate={errors.properties && "error"}
                  validMessage={
                    errors.properties && errors.properties.message
                  }
                
                  iProps={{ placeholder: "Select Properties", isMulti: true }}
                  rules={{ required: "Please select properties" }}
                  initValue=""
                  selectOption={propertiesList}

                />
              </Col>
              <Col span={12}>
                <ReactSelectField
                  fieldname="manager"
                  label="Manager"
                  control={control}
                  iProps={{ placeholder: "Select manager" }}
                  selectOption={managerArr}
                />
              </Col>
              <Col span={12}>
                <ReactSelectField
                  isRequired={true}
                  fieldname="visibility"
                  label="Visibility"
                  control={control}
                  iProps={{ placeholder: "Select visibility", isDisabled: !visibilityOptions?.length, }}
                  rules={{
                    required: "Please select visibility",
                  }}
                  selectOption={visibilityOptions}
                  validate={errors.visibility && "error"}
                  validMessage={errors.visibility && errors.visibility.message}
                />
              </Col>
              <Col span={12}>
                <ReactSelectField
                  isRequired={true}
                  fieldname="status"
                  label="Status"
                  control={control}
                  iProps={{ placeholder: "Select Status", isDisabled: !managerStatus?.length, }}
                  rules={{
                    required: "Please select Status",
                  }}
                  selectOption={_.map(managerStatus, (item) => ({
                    value: item?.value,
                    label: item?.label,
                  }))}
                  validate={errors.status && "error"}
                  validMessage={errors.status && errors.status.message}
                />
              </Col>

             {screenData?.length > 0 &&
              <Col span={12}>
                <ReactSelectField
                 
                  isRequired={false}
                  fieldname="screens"
                  label="Screen List"
                  control={control}
                  iProps={{ placeholder: "Select Screen", isMulti: true }}
                  initValue=""
                  selectOption={screenData}

                />
              </Col>}
             
            </Row>
           

            <Row gutter={24} justify="space-between">
              <Col>
                <Button
                  className="ag-gray-button-outline theme_grey_btn"
                  htmlType="button"
                  onClick={() => {
                    props.onCancel();
                  }}
                >
                  Back
                </Button>
              </Col>
              <Col>
                <Button className="ag-green-button theme_btn" htmlType="submit">
                  {props?.editMode == false ? "Add" : "Update"}
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          isError && (
            <>
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Space
                    size={24}
                    className="w-100 text-center"
                    direction="vertical"
                  >
                    <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                    <Paragraph className="ag-error fontWeight600 mb-0 sentanceCase">
                      {errorMessage}
                    </Paragraph>
                  </Space>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <Button
                    className="ag-gray-button-outline theme_grey_btn"
                    htmlType="button"
                    onClick={() => {
                      reset();
                      setSubmited(false);
                      setisError(false);
                    }}
                  >
                    Back
                  </Button>
                </Col>
              </Row>
            </>
          )
        )}
      </Form>
    </>
  );
};

export default AddManager;
