import React, { useState, useEffect } from "react";
import { Button, Modal } from "antd";
import { Document, Page, pdfjs } from "react-pdf";
import { openFullscreen } from "../../../utils/media";
import { getDocumentKey } from "../../../configs/documents";

const PdfTemplateView = (props) => {
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const [docData, setDocData] = useState(null);



  const { data, saveToLease, sendForEsignature } = props;


  useEffect(() => {
    // console.log("data231", data);
    if (data?.documentName)
      data["key"] = getDocumentKey(data?.documentName)
    setDocData(data);
  }, [data]);

  console.log("docData,",docData);

  useEffect(() => {
    // .catch((error) => console.error("Error fetching PDF:", error));
    pdfjs.GlobalWorkerOptions.workerSrc =
      `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    getFile();
    // `//unpkg.com/@react-pdf-viewer/pdfjs-dist-signature@2.7.557/build/pdf.worker.js`;
  }, []);

  const getFile = async () => {

    setPdfData(props?.pdf);


  }

  // useEffect(() => {
  //   console.log("pdfData", pdfData);
  // }, [pdfData]);

  const handlePrint = () => {
    openPrintWindow(pdfData);
  };

  const onDocumentError = (err) => console.error("pdf viewer error:", err);
  const onDocumentComplete = ({ _pdfInfo }) => setPages(_pdfInfo?.numPages ?? 1);

  const openPrintWindow = (pdfData) => {
    const blob = new Blob([pdfData], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const printWindow = window.open(url, '_blank');
    if (printWindow) {
      printWindow.onload = () => {
        printWindow.print();
      };
    } else {
      console.error('Failed to open print window.');
    }
  };



  return (
    <Modal
      visible={props?.visible}
      onCancel={props?.onCancel}
      cancelText="Close"
      okButtonProps={{ style: { display: "none" } }}
      closable={false}
      cancelButtonProps={{ style: { position: "absolute", top: "44px", left: "44px" } }}
      style={{ top: 20 }}
      width={props?.width ?? "50%"}
      className="pdf-width"
    >
      <div style={{ width: "100%", textAlign: "right" }}>

        {/* TASKSSS 151 */}
        {/* // if addtolease false */}
        {/* add function to save to lease and fix function props or take it in another component and update the props data */}

        {((saveToLease && docData?.addToLease == false) &&
          <Button type="primary" className="btn-green" onClick={() => saveToLease(docData?.key)}>Save to lease</Button>
        )}

        {/* // if addtolease true && sendToTenant false && signatureplace true */}
        {((sendForEsignature &&
          // docData?.addToLease == true &&   removed as per discussion: both buttons present together
          docData?.signaturesPlaceholder == true &&
          docData?.sendToTenant == false
        ) &&
          <Button type="primary" className="btn-blue" onClick={() => sendForEsignature(docData?.key)}>Send for eSignature</Button>
        )}
      </div>


      <br />
      {pdfData && (<>
        <embed src={pdfData} title=""
          width="100%" height="2100px" />
      </>
      )}

    </Modal>
  );
};
export default PdfTemplateView;
