import React from "react";
import { Card, Typography } from "antd";
import moment from "moment";

const getListData = (value, scheduledVisits) => {
  let listData = [];

  if (scheduledVisits.length) {
    scheduledVisits.map((item) => {
      const selectedMonth = moment(value).format("MMMM");
      const selectedDay = moment(value).format("DD");
      const currentMonth = moment(item.createdAt).format("MMMM");
      const currentDay = moment(item.createdAt).format("DD");

      if (selectedMonth === currentMonth && selectedDay === currentDay) {
        listData.push({
          id: item.id,
          date: item.createdAt,
          status: item.status,
          content: item.text,
        });
      }
    });
  }
  return listData;
};

export default (props) => {
  const listData = getListData(props.value, props.scheduledVisits);

  return (
    <>
      {listData.map((item, index) => (
        <Card className={`custom-uper-card`} size="small" key={index}>
          <Card className={`custom-card `} size="small">
            <Typography style={{ fontSize: "9px" }}>{item.date}</Typography>
            <Typography
              style={
                item.status !== "created"
                  ? { color: "#447093", fontSize: "9px" }
                  : { color: "#000", fontSize: "9px" }
              }
            >
              {item.content}
            </Typography>
          </Card>
        </Card>
      ))}
    </>
  );
};
