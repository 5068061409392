import {
  GET_BOOKINGS_STATE,
  GET_LEASES_STATE,
  GET_RENEWALS_STATE,
  GET_TENANTS_STATE,
  GET_UNITS_STATE,
  GET_UNITS_STATUS_STATE
} from "./constants";

const initialState = {
  bookingsData: {
  },
  leasesData: {
  },
  renewalsData: {
  },
  tenantsData: {
  },
};
export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case GET_BOOKINGS_STATE:
      return { ...state, bookingsData: data };
    case GET_LEASES_STATE:
      return { ...state, leasesData: data };
    case GET_RENEWALS_STATE:
      return { ...state, renewalsData: data };
    case GET_TENANTS_STATE:
      return { ...state, tenantsData: data };
    case GET_UNITS_STATUS_STATE:
      return { ...state, statusFilter: data };
    case GET_UNITS_STATE:
      return { ...state, unitssData: data };
    default:
      return state;
  }
};
