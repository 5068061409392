import React, { useState, useEffect } from "react";
import { Col, Row, Form, message, Collapse } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumb from "../../atoms/BreadCrumb";
import DashboardLayout from "../../molecules/DashboardLayout";
import SectionHeader from "../../molecules/SectionHeader";
import { useForm } from "react-hook-form";
import { Popup, PopupConfirm } from "../../atoms/Popup";
import { getChequeList } from "../Properties/ducks/actions";
import ChequeCard from "./ChequeCard";
import { baseContractService } from "../../../configs/constants";
import axios from "../../../utils/axiosInceptor";
import RejectCheque from "./RejectCheque";
// import Rejectform from "../../molecules/RejectForm/RejectForm";

const { Panel } = Collapse;

const breadcrumbList: any = [
  {
    label: "Notification",
    route: "/notifications",
  },
  {
    label: "Cheque List",
    route: "",
  },
];

export default (props) => {
  const mUrl = `${baseContractService}/update-cheques-status`;
  const dispatch:any = useDispatch();
  const { id } = useParams();
  const data = useSelector((state: any) => state.dashboard.chequeList);
  const { control, setValue, handleSubmit } = useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [objectId, setObjectId] = useState({
    id: "",
    field: "",
  });

  useEffect(() => {
    dispatch(getChequeList(id));
  }, []);

  const onAceptReject = (value, key, field) => {
    setObjectId({ id: key, field: field });
    if (value === "Rejected") {
      setVisible(true);
    } else {
      setVisible(false);
      PopupConfirm({
        title: `Are you sure you want to Approve the cheque`,
        content: "",
        onOk: () => onAccept(key, field),
        okText: "Confirm",
        onCancel: () => onCancel(field),
      });
    }
  };

  const onRejected = async (e) => {
    let payload = {
      id: objectId.id,
      status: "Rejected",
      comment: e,
    };
    try {
      await axios.put(mUrl, payload);
      setLoading(false);
      message.success("Cheque Status updated succesfully");
      setObjectId({
        id: null,
        field: null,
      });
      dispatch(getChequeList(id));
      setVisible(false);
    } catch (e) {
      setLoading(false);
      const { response } = e;
      message.error(response?.statusText);
    }
  };

  const onAccept = async (key, field) => {
    let payload = {
      id: key,
      status: "Approved",
    };
    try {
      await axios.put(mUrl, payload);
      setLoading(false);
      message.success("Cheque updated succesfully");
      setObjectId({
        id: null,
        field: null,
      });
      dispatch(getChequeList(id));
    } catch (e) {
      setLoading(false);
      const { response } = e;
      message.error(response?.statusText);
    }
  };

  const onCancel = (field) => {
    dispatch(getChequeList(id));
    if (field) {
      setValue(field, "");
    } else {
      setValue(objectId.field, "");
    }
    setObjectId({
      id: null,
      field: null,
    });
    setVisible(false);
  };

  const popup = [
    {
      title: "Reject Reason",
      content: (
        <RejectCheque
          onReject={onRejected}
          onCancel={onCancel}
          field={objectId.field}
        />
      ),
      width: 650,
    },
  ];

  const popupProps = {
    closable: false,
    visibility: visible,
    title: popup[0].title,
    content: popup[0].content,
    width: popup[0].width,
    // onCancel: () => setVisible(false),
  };

  const onSubmit = () => {};

  return (
    <DashboardLayout load={loading}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <BreadCrumb list={breadcrumbList} />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <SectionHeader heading="Installments Cheques Approval" />
        </Col>
        <Col span={24}>
          <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
            <Collapse
              defaultActiveKey={["1"]}
              expandIconPosition="right"
              ghost
              className="ag-collapse"
            >
              {data?.paymentSchedule?.map((item, index) => (
                <Panel header={item?.desc} key={index + 1}>
                  <ChequeCard
                    data={item}
                    control={control}
                    index={index}
                    popup={onAceptReject}
                  />
                </Panel>
              ))}
            </Collapse>
          </Form>
        </Col>
      </Row>
      <Popup {...popupProps} />
    </DashboardLayout>
  );
};
