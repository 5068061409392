import {
  GET_TENANT_DETAILS,
  GET_UNIT_DETAILS,
  GET_LEASE_NATURE,
  GET_PARKING_SLOTS,
  GET_BOOKING_TERMS,
  GET_TENANT_CONTACTS,
  GET_TERMS_CONDITIONS,
  GET_APPROVAL_HISTORY,
  GET_BOOKING_DOCS,
  GET_BOOKING_DETAILS,
  GET_BILLING_CHARGES,
  GET_PAYMENT_MODES,
  GET_FORM_VAL,
  GET_BILLING_SCHEDULE_TOTAL,
  GET_BILLING_CHARGES_DROPDOWN,
  GET_LEASE_DETAILS,
  GET_ADDITIONAL_DOCUMENTS_LIST,
  EMPTY_VAL,
  GET_LANDLORD_USERS,
} from "./constants";

const initialState = {
  tenantDetails: {},
  unitDetails: {},
  leaseNature: [],
  parkingSlots: [],
  bookingTerms: {},
  termsConditions: [],
  tenantContacts: [],
  getAprovalHistory: [],
  getBookingFormDocs: {},
  getBookingFormDetails: {},
  getBillingCharges: [],
  getPaymentModes: [],
  setFormVal: {},
  landlordUsers:[],
  leaseFormData: [],
  getAdditionalDocumentList: [],
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case GET_TENANT_DETAILS:
      return { ...state, tenantDetails: data };
    case GET_UNIT_DETAILS:
      return { ...state, unitDetails: data };
    case GET_LEASE_NATURE:
      return { ...state, leaseNature: data };
    case GET_LANDLORD_USERS:
      return { ...state, landlordUsers: data };
    case GET_PARKING_SLOTS:
      return { ...state, parkingSlots: data };
    case GET_BOOKING_TERMS:
      return { ...state, bookingTerms: data };
    case GET_TERMS_CONDITIONS:
      return { ...state, termsConditions: data };
    case GET_TENANT_CONTACTS:
      return { ...state, tenantContacts: data };
    case GET_APPROVAL_HISTORY:
      return { ...state, getAprovalHistory: data };
    case GET_BOOKING_DOCS:
      return { ...state, getBookingFormDocs: data };
    case GET_BOOKING_DETAILS:
      return { ...state, getBookingFormDetails: data };
    case GET_BILLING_CHARGES:
      return { ...state, getBillingCharges: data };
    case GET_PAYMENT_MODES:
      return { ...state, getPaymentModes: data };
    case GET_FORM_VAL:
      return { ...state, setFormVal: data };
    case GET_BILLING_SCHEDULE_TOTAL:
      return { ...state, getBillingScheduleTotal: data };
    case GET_BILLING_CHARGES_DROPDOWN:
      return { ...state, getBillingScheduleTotal: data };
    case GET_LEASE_DETAILS:
      return { ...state, leaseFormData: data };
    case GET_ADDITIONAL_DOCUMENTS_LIST:
      return { ...state, getAdditionalDocumentList: data };
    case EMPTY_VAL:
      return { ...state, leaseFormData: [] };

    default:
      return state;
  }
};
