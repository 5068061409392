import { contractService } from "../../../../configs/constants";
import axios from "../../../../utils/axiosInceptor";
import * as action_types from "./constants";

export const getBookingForms = (limit, page = 1, value = "", field,bookingStatus=null,cancelleBookingInclude='yes') => async (
  dispatch,
) => {
  const url = `${contractService}/booking?limit=${limit}&page=${page}&searchby=${`${field}&text=${value}&bookingStatus=${bookingStatus}&bookingCancelStatus=${cancelleBookingInclude}`}`;
  const {
    data: { result },
  } = await axios.get(url);
  if (result) {
    dispatch({
      type: action_types.GET_BOOKINGFORMS,
      data: result,
    });
  }
};

// { "emails": ["ajay.rawat@al-ghurair.com"], "usertype": "tenant", "inviteAll": false }


export const getBookingPendingReq = () => async (
  dispatch,
) => {
  const url = `${contractService}/booking?managerApproval=enable`;
  const {
    data: { result },
  } = await axios.get(url);

  if (result) {
    dispatch({
      type: action_types.GET_BOOKING_REQUESTS,
      data: result,
    });
  }
};


export const getLeasePendingReq = () => async (
  dispatch,
) => {
  const url = `${contractService}/booking?leaseManagerApproval=enable`;
  const {
    data: { result },
  } = await axios.get(url);

  if (result) {
    dispatch({
      type: action_types.GET_LEASE_REQUESTS,
      data: result,
    });
  }
}

export const getPlanPendingReq = () => async (
  dispatch,
) => {
  const url = `${contractService}/booking?paymentPlanApproval=enable`;
  const {
    data: { result },
  } = await axios.get(url);

  if (result) {
    dispatch({
      type: action_types.GET_PLAN_REQUESTS,
      data: result,
    });
  }
};

export const sendTenantInvite = async (data, message) => {
  try {
    const url = `${contractService}/user/sendInvite`;
    const { status } = await axios.post(url, data);

    return status;


  } catch (error) { }
};


export const updateBookingStatus = async (leaseId, payload) => {
  try {
    const url = `${contractService}/booking/${leaseId}`;
    const { status } = await axios.patch(url, payload);

    return status;

  } catch (error) {

  }
};



export const CreateEjariContract = async (leaseId) => {

  const url = `${contractService}/ejari/createEjariContract`;
  const payload = { "leaseId": leaseId }
  const { data } = await axios.post(url, payload);
  return data;
};

export const DownloadEjariContract = async (leaseId) => {
  const url = `${contractService}/ejari/downloadEjariContract`;
  const payload = { "leaseId": leaseId }
  const { data } = await axios.post(url, payload);
  return data;
};

export const UploadSignedEjariContract = async (payload) => {

  const url = `${contractService}/ejari/uploadSignedFile`;
  const status = await axios.post(url, payload, { headers: { 'Content-Type': 'multipart/form-data' } });
  return status;
};

export const UploadEjariCertificate = async (payload) => {

  const url = `${contractService}/ejari/uploadEjariFile`;
  const status = await axios.post(url, payload, { headers: { 'Content-Type': 'multipart/form-data' } });
  return status;
};


export const ActivateEjari = async (leaseId) => {
  const url = `${contractService}/booking/${leaseId}`;
  const payload = { "status": "leaseActive" }
  const { data } = await axios.patch(url, payload);
  return data;
};


export const PrintEjariCertificateContract = async (leaseId) => {
    const url = `${contractService}/ejari/printEjariCertificateContract`;
    const payload = { "leaseId": leaseId }
    const { data } = await axios.post(url, payload);
    return data;
};


