import * as action_types from "./constants";
var _ = require("lodash");

const initialState: any = {
  shiftData: [],
  zonesData: [],
  techProperty: [],
  categoriesList: [],
  techniciansList: []
};

export default (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case action_types.GET_OPERATING_HOURS:
      return { ...state, operatingHours: data };
    case action_types.GET_SHIFTS_LIST:
      return { ...state, shiftData: data };
    case action_types.GET_SHIFT:
      return { ...state, techProperty: data };
    case action_types.GET_MAINTANANCE_CATEGORIES:
      return {
        ...state,
        categoriesList: {
          residential: _.map(data?.residential, (item) => ({
            value: item.categoryId,
            label: item.name,
          })),
          commercial: _.map(data?.commercial, (item) => ({
            value: item.categoryId,
            label: item.name,
          })),
          internal: _.map(data?.internal, (item) => ({
            value: item.categoryId,
            label: item.name,
          })),
        },
      };
    case action_types.GET_SP_TECHNICIANS:
      return {
        ...state,
        techniciansList: _.map(data, (item) => ({
          value: item.userId,
          label: item.name,
        })),
      };
    default:
      return state;
  }
};
