import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, message, Spin, Space, Typography } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { SelectField } from "../../../../../atoms/FormElement";
import { getTicketType } from "../../../Tickets/ducks/actions";
import { maintenanceService } from "../../../../../../configs/constants";
import axios from "../../../../../../utils/axiosInceptor";

const defaultValues: any = {
  speciality_ids: "",
};

const { Paragraph } = Typography;

export default (props) => {
  const dispatch = useDispatch();
  const { data, id } = props;
  const { control, formState:{errors}, setValue, reset, handleSubmit } = useForm({
    defaultValues,
  });
  const typeApi = []//useSelector((state) => state.tickets.ticketsType);
  const [types, setTypes] = useState([]);
  const [submited, setSubmited] = useState(false);
  const [isError, setisError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    // dispatch(getTicketType());
  }, []);

  useEffect(() => {
    typeApi &&
      setTypes(
        typeApi.map((item) => ({
          value: item.ChildCaseCategory.id,
          label: item.ChildCaseCategory.name,
        })),
      );
  }, [typeApi]);

  useEffect(() => {
    if (data && data.length > 0) {
      let temp = [];
      data.map((x) => {
        temp.push({
          value: x.CaseCategory.id,
          label: x.CaseCategory.name,
        });
      });
      setValue("speciality_ids", temp);
    }
  }, [data]);

  const onFinish = async (values) => {
    setLoader(true);
    setSubmited(true);
    let added = [];
    let removed = [];
    data.map((x) => {
      let chk = values.speciality_ids.find((y) => y.value == x.CaseCategory.id);
      if (chk) {
      } else {
        removed.push(x.CaseCategory.id);
      }
    });
    values.speciality_ids.map((x) => {
      let chk = data.find((y) => y.CaseCategory.id == x.value);
      if (chk) {
      } else {
        added.push(x.value);
      }
    });
    const body = {
      technicianId: id,
      addCategoryIds: added,
      removeCategoryIds: removed,
    };


    const url = `${maintenanceService}/facilityManager/updateCategory`;
    try {
      await axios.put(url, body);
      setTimeout(() => {
        setLoader(false);
        setSubmited(false);
        reset();
        message.success("Specialization updated successfully");
        // props.onCancel();
        props.onAddTech();
      }, 3000);
    } catch (e) {
      setLoader(false);
      setisError(true);
      const { response } = e;
      setErrorMessage(response?.data?.message);
    }
  };

  return (
    <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
      {loader && !isError && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Space size={24} className="w-100 text-center" direction="vertical">
              <Spin size="large" className="ag-fontSize32" />
              {/* <Paragraph className="ag-default fontWeight600 mb-0">Loading</Paragraph> */}
            </Space>
          </Col>
        </Row>
      )}
      {!submited ? (
        <>
          <Row gutter={24}>
            <Col span={24}>
              <SelectField
                isRequired={true}
                fieldname="speciality_ids"
                label="Speciality"
                control={control}
                iProps={{ placeholder: "Select speciality", isMulti: true }}
                rules={{ required: "Please select speciality" }}
                initValue=""
                selectOption={types}
                validate={errors.speciality_ids && "error"}
                validMessage={
                  errors.speciality_ids && errors.speciality_ids.message
                }
              />
            </Col>
          </Row>
          <Row gutter={24} justify="space-between">
            <Col>
              <Button
                className="ag-gray-button-outline"
                onClick={props.onCancel}
              >
                Back
              </Button>
            </Col>
            <Col>
              <Button className="ag-green-button" htmlType="submit">
                Update
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        isError && (
          <>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Space
                  size={24}
                  className="w-100 text-center"
                  direction="vertical"
                >
                  <ExclamationCircleOutlined className="ag-fontSize32 ag-error" />
                  <Paragraph className="ag-error fontWeight600 mb-0">
                    {errorMessage}
                  </Paragraph>
                </Space>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Button
                  className="ag-gray-button-outline"
                  htmlType="button"
                  onClick={() => {
                    setSubmited(false);
                    setisError(false);
                  }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
    </Form>
  );
};
