import axios from "../../../../utils/axiosInceptor";
import { maintenanceService } from "../../../../configs/constants";
import * as action_types from "./constants";

const allCategory = `${maintenanceService}/maintenance/category`;
const category = `${maintenanceService}/serviceprovider/detail`;
const subCategory = `${maintenanceService}/maintenance/subcategory`;
const publicHoliday = `${maintenanceService}/maintenance/getHoliday`;
const operatingHours = `${maintenanceService}/maintenance/operatingHours`;


export const getAllMaintenanceTypes = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(allCategory);
      dispatch({
        type: action_types.GET_ALL_MAINTENANCE_TYPE,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getMaintenanceTypes = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(category);
      dispatch({
        type: action_types.GET_MAINTENANCE_TYPE,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getMaintenanceSubTypes = (categoryId: string) => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(`${subCategory}/${categoryId}`);
      dispatch({
        type: action_types.GET_MAINTENANCE_SUB_TYPE,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const setEmptyMaintenanceTypes = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: action_types.GET_MAINTENANCE_TYPE,
        data: [],
      });
      dispatch({
        type: action_types.GET_MAINTENANCE_SUB_TYPE,
        data: [],
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getPublicHoldays = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(`${publicHoliday}`);
      dispatch({
        type: action_types.GET_PUBLIC_HOLIDAYS,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};

export const getOperatingHours = () => {
  return async (dispatch) => {
    try {
      const {
        data: { result },
      } = await axios.get(`${operatingHours}`);
      dispatch({
        type: action_types.GET_OPERATING_HOURS,
        data: result,
      });
    } catch (e) {
      console.log("Err", e);
    }
  };
};






