import React, { useState, useEffect } from "react";
import { Button, Row, Col, Typography, Space, Table } from "antd";
import Grid from "../../../atoms/Grid";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Popup, PopupConfirm } from "../../../atoms/Popup";
import AddTimeSlot from "./Modals/AddTimeSlot";
import { useSelector, useDispatch } from "react-redux";
// import {getTimeSlotList, getTimeSlotTypes} from "../ducks/actions";
import moment from "moment";
import UpdateTimeSlot from "./Modals/UpdateTimeSlot";
import { maintenanceService } from "../../../../configs/constants";
import axios from "axios";
import AddPublicHoliday from "./Modals/AddPublicHoliday";
import { getPublicHoldays } from "../ducks/actions";
import { dateByformat } from "../../../../utils/dateHelper";

const { Title } = Typography;

const PublicHolidays = () => {
  const [searchText, setSearchText] = useState();
  const [timeSlotRowData, setTimeSlotRowData] = useState({});
  const dispatch: any = useDispatch();
  const [visible, setVisible] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const holidaysApi = useSelector(
    (state: any) => state.configuration.publicHolidays
  );
  //  useSelector(
  //   (state: any) => state.configuration.timeslotsList
  // );
  const timeSlotsType = [];
  //  useSelector(
  //   (state: any) => state.configuration.timeSlotsType
  // );
  const [onClose, setOnClose] = useState(false);
  const [onRefresh, setOnRefresh] = useState("0");
  const [holidayRows, setholidayRows] = useState([]);
  const [rowRefresh, setRowRefresh] = useState(false);

  useEffect(() => {
    dispatch(getPublicHoldays());
  }, []);

  useEffect(() => {
    if (holidaysApi) {
      let holidayArray: any = holidaysApi?.map((item, index) => {
        return {
          id: item._id,
          profileId: item.profileId,
          title: item?.attributes?.description ?? "--",
          date: item.date,
        };
      });
      setholidayRows(holidayArray);
    }
  }, [holidaysApi]);


  const addHoliday = () => {
    setVisible(true);
    setTrigger(0);
  };

  function onSubmit() {
    // dispatch(getListZones(1, limit, ""));
    dispatch(getPublicHoldays());
    // setPage(1);
    setVisible(false);
  }


  const popup = [
    {
      title: (
        <Title level={3} className="mb-0">
          Add Public Holiday
        </Title>
      ),
      content: (
        <AddPublicHoliday editMode={false} onCancel={() => setVisible(false)} onSubmit={onSubmit}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Update a Time Slot
        </Title>
      ),
      content: (
        <UpdateTimeSlot
          setVisible={setVisible}
          onClose={onClose}
          setOnClose={setOnClose}
          onRefresh={onRefresh}
          setholidayRows={setholidayRows}
          setOnRefresh={setOnRefresh}
          rowData={timeSlotRowData}
          timeSlotsType={timeSlotsType?.map((index) => ({
            label: index.name,
            value: index.id,
          }))}
        />
      ),
      width: 650,
    },
    {
      title: (
        <Title level={3} className="ag-primary mb-0">
          Delete a Time Slot
        </Title>
      ),
      content: (
        <UpdateTimeSlot
          setVisible={setVisible}
          rowData={timeSlotRowData}
          timeSlotsType={timeSlotsType?.map((index) => ({
            label: index.name,
            value: index.id,
          }))}
        />
      ),
      width: 650,
    },
  ];

  const onCancel = () => {
    setOnClose(true);
    setVisible(false);
  };

  const popupProps = {
    closable: true,
    visibility: visible,
    title: popup[trigger].title,
    content: popup[trigger].content,
    width: popup[trigger].width,
    onCancel: () => setVisible(false),
  };

  const handleUpdate = (data) => {
    setVisible(true);
    setTrigger(1);
    setTimeSlotRowData(data?.data);
  };

  const deletePublicHoliday = (item: any) => {
    // console.log("ITEMDELETE",item)
    return PopupConfirm({
      title: `Are you sure you want to delete`,
      cancelText: "Back",
      okText: "Delete",
      okType: "danger",
      onOk() {
        if(item.id){
        const url = `${maintenanceService}/maintenance/removeHoliday/${item.id}`;
        try {
          axios
            .delete(url)
            .then((res) => {
              dispatch(getPublicHoldays());
            });
        } catch (e) {

        }           
      }
      },
    });
  };


  // const startDateFormat = (params) => {
  //   const [startDate, endDate] = params.data.timeSlot.split("-");
  //   return moment(startDate, ["h:mm:ss"]).format("h:mm A");
  // };

  // const endDateFormat = (params) => {
  //   const [startDate, endDate] = params.data.timeSlot.split("-");
  //   return moment(endDate, ["h:mm:ss"]).format("h:mm A");
  // };

  
  const column = [
    {
      title: "Holiday Title",
      dataIndex: "title",
      key: "name",
      width: 220,
      // render: (item) => {
      //   return <>{_filterName("SP", item)}</>;
      // },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "properties",
      render: (text, record) => dateByformat(record.date,"DD-MM-YYYY"),
      width: 220,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 100,
      render: (text, record) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => deletePublicHoliday(record)}
              className="p-0 ag-success"
            >
              <div className="Simple-tooltip">
                <DeleteOutlined />
                <section className="tt-content">Delete</section>
              </div>
            </Button>
          </Space>
        );
      },
    },
  ];


  return (
    <>
      <Row gutter={24}>
        <Col span={24}>
          <Row justify="end" className="mb-1">
            <Button
              size="large"
              type="primary"
              onClick={addHoliday}
            >
              Add Public Holiday <PlusOutlined />
            </Button>
          </Row>
        </Col>
      </Row>
      <Row gutter={24} className="ant-table-row">
        <Col span={24}>
          <Table
            columns={column}
            scroll={{ y: 500 }}
            dataSource={holidayRows}
            rowKey={(record, index) => index}
            className="custom-table org_table_wrap"
            pagination={{
              hideOnSinglePage: true,
            }}
          />
        </Col>
      </Row>
      {/* <Row gutter={24}>
        <Col xl={24}>
          {timeSlotsList && (
            <Grid
              data={holidayRows}
              columns={columnDefs}
              pagination={false}
              defaultSettings={gridProps}
              filterGrid={searchText}
              noRowlabel="TimeSlots"
              // noRowbtnAction={addTechnician}
            />
          )}
        </Col>
      </Row> */}
      <Popup destroyOnClose={true} {...popupProps} />
    </>
  );
};

export default PublicHolidays;
