import React, { useState } from "react";
import { Card, Typography, Space, Divider, Button, Row, Col, Select } from "antd";
import { RightOutlined } from "@ant-design/icons";
import moment from "moment";

const { Title, Text, Paragraph } = Typography;

const TicketChip = (props) => {
  const {
    ticketID,
    type,
    subtype,
    status,
    tenant,
    appartment,
    location,
    recuring,
    clickView,
    clickDropDown,
    title,
    unit,
    data,
    ticketsCategory
  } = props;

  const [selected, setSelected] = useState("select")

  // console.log("DATA---->>>",data);
  
  return (
    <Card
      // style={{ padding: "20px 14px" }}
      className={`chip-wrap ticket-child-card ${ticketsCategory === "New" && "new-chip"} ${ticketsCategory === "In Progress" && "progress-chip"
        } ${ticketsCategory === "Closed" && "closed-chip"} ${ticketsCategory === "Scheduled" && "scheduled-chip"}`}
    >
      <Text style={{ position: "absolute", right: 5 }} className="ag-primary fontWeight600 smallFont11">

        {moment(data?.preferredDate).format("DD-MMM-YY")}
        {" "}
        {data?.preferredTimeStart &&
          moment(data?.preferredTimeStart, "hh:mm:ss").format(
            "LT",
          )}{" "}

      </Text>
      <Title level={5} className="mb-0 ">
        {type} - <span className="ticket-subtitle">{title}</span>  {/* {type} - {subtype} */}
      </Title>
      <Space size={4} className="mb-1">
        <Text className="ag-success fontWeight600">#{ticketID}</Text>
        {recuring && (
          <>
            <Divider type="vertical" className="ticket-sep" />
            <Text className="ag-error fontWeight600">Recurring Issue</Text>
          </>
        )}
      </Space>
      <Row align="bottom">
        <Col flex="auto">
          <Paragraph className="ag-primary fontWeight600 mb-0">
            {unit}
          </Paragraph>
          <Paragraph className="ag-primary fontWeight600 mb-0">
            {tenant}
          </Paragraph>
          <Paragraph style={{ color: "#00576e", fontWeight: 500 }}
            className="smallFont12 text-italic mb-0">
            {data?.unitNumber} - {appartment}
          </Paragraph>
          <Paragraph className="smallFont12 text-italic mb-0">
            {location}
          </Paragraph>
          <Paragraph className="smallFont12 ag-lightblue fontWeight600 mb-0">
            Status: <span style={{ textTransform: "capitalize" }}>{status}</span>
          </Paragraph>
          <Paragraph className="smallFont12 ag-lightblue fontWeight600 mb-0">
            Assigned: {data?.technician?.name && data?.technician?.name}
          </Paragraph>
        </Col>
      </Row>
      <Space size={4} className="mb-1">
        <Row>
          {/* <Col flex="auto"> */}
          <Button
            className="p-0 ag-success fontWeight600"
            type="link"
            style={{ position: "absolute", right: 8, bottom: 0 }}
            onClick={clickView}
          >
            View
            {/* <RightOutlined /> */}
          </Button>
          {/* {
            ticketsCategory !== "Closed" && <Select
              value={selected}
              defaultValue="select"
              style={{ width: 100, position: "absolute", right: 5, bottom: 0 }}
              onChange={(e) => {
                  clickDropDown(e)
                  setTimeout(() => {
                    setSelected("select")
                  }, 1000);
                }
              }
              options={[
                { value: 'select', label: 'Edit' },
                { value: 'reassign', label: 'Reassign' },
                { value: 'reschedule', label: 'Reschedule' },
                { value: 'close', label: 'Close' },
              ]}
            />
          } */}
          {/* </Col> */}
        </Row>
      </Space>

    </Card>
  );
};

export default TicketChip;
