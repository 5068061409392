
import React from "react";
import {
  Layout,
  Row,
  Col,
  Typography,
  Button
} from "antd";

const { Content } = Layout;

const { Title } = Typography;

// CreateLease;

export default (props) => {

  return (

    <Layout className="layout-space prop_popup">
      <Content className="main-content">

        <Row gutter={24}>

          <Col>
            <Title level={4}>{props.title}</Title>
          </Col>

        </Row>
        <Row gutter={24} justify="space-between">
          <Col></Col>
          <Col>
            <Button className="ag-green-button theme_btn" onClick={() => props.onSubmit()}>
              Send
            </Button>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};