import React, { useState, useEffect } from "react";

// others
import { paymentModes } from "./utils";
import { Col, Row, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { InputField, ReactSelectField } from "../../../atoms/FormElement";

var _ = require("lodash");

export default (props) => {
  const {
    item,
    control,
    errors,
    billingType,
    remove,
    index,
    noIstallment,
    parking,
    setValue,
    getValues,
    setNoInstallment,
    noIData,
    disableClone,
    deleteIndex,
    enableClone,
  } = props;

  const [typeM, setTypeM] = useState("");
  const [enable, setEnable] = useState(true);
  const [disable, setDisable] = useState(false);
  const [rcDisable, setRCDisable] = useState(false);
  const configData = {
    rental_commission_percentage: 0.05,
  };

  useEffect(() => {
    item?.type?.value != "PARKING" && setEnable(false);
    // item?.type?.label != "RENTAL COMMISSION" && setRCDisable(false);
    if (
      // item?.type?.value == "ejari_fee" ||
      item?.type?.value === "rent_installment" ||
      item?.type?.value == "security_deposit"
    ) {
      setDisable(true);
    } else if (
      item?.type?.value == "ejari_fee" ||
      item?.type?.value === "rent_installment" ||
      item?.type?.value == "security_deposit"
    )
      // disableClone(item?.type?.value);
      onInstallmentChange(item?.intallments);
  }, []);

  const onChange = (e, index) => {
    if (e.value == "rental_commission") {
      setValue(`othercharges[${index}].intallments`, { label: "1", value: 1 });
      setValue(
        `othercharges[${index}].amount`,
        getValues("annualBaseRent") * configData?.rental_commission_percentage
      );
      setRCDisable(true);
    } else {
      setRCDisable(false);
      setValue(`othercharges[${index}].amount`, 0);
    }
    e.value == "PARKING" ? setEnable(true) : setEnable(false);
    // setTypeM(e.value);
    // disableClone(e.value);
  };

  const onInstallmentChange = (e) => {
    if (item?.type?.value === "rent_installment") {
      setValue("noOfInstallments", e);
      let install = [];
      Array.from(Array(e.value), (_, index) => index + 1).forEach((item) =>
        install.push({ label: item, value: item })
      );
      let moreArr = getValues().othercharges;
      if (moreArr.length > 0) {
        moreArr.map((item, index) => {
          if (
            item.intallments.value > e.value &&
            item.type.value !== "rent_installment"
          ) {
            setValue(`othercharges[${index}].intallments`, "");
          }
        });
      }
      setNoInstallment(install);
    }
  };

  const onAmountChange = (e) => {
    const value = parseFloat(e);

    if (item?.type?.value === "rent_installment") {
      setValue("annualBaseRent", value);
      let moreArr = getValues().othercharges;
      if (moreArr.length > 0) {
        moreArr.map((item, index) => {
          if (item.type.value == "rental_commission") {
            setValue(
              `othercharges[${index}].amount`,
              value * configData?.rental_commission_percentage
            );
          }
        });
      }
    } else if (item?.type?.value == "security_deposit") {
      setValue("nonRefundableAmount", value);
    }
  };

  return (
    <Row gutter={24} style={{ position: "relative" }}>
      <Col span={8}>
        <ReactSelectField
          label="Type"
          control={control}
          isRequired={true}
          initValue={item?.type}
          selectOption={billingType}
          rules={{ required: "Select one" }}
          onChange={(e) => onChange(e, index)}
          fieldname={`othercharges[${index}].type`}
          iProps={{
            placeholder: "Select payment type",
            isDisabled: props.disableAll ? true : disable,
          }}
          validate={
            Object.entries(errors).length > 0 &&
            errors?.othercharges?.length > 0 &&
            errors?.othercharges[index]?.type &&
            "error"
          }
          validMessage={
            Object.entries(errors).length > 0 &&
            errors?.othercharges?.length > 0 &&
            errors?.othercharges[index]?.type &&
            errors?.othercharges[index]?.type?.message
          }
        />
      </Col>

      <Col span={8}>
        <ReactSelectField
          isRequired={true}
          control={control}
          label="Payment Mode"
          selectOption={paymentModes}
          fieldname={`othercharges[${index}].mode`}
          iProps={{
            placeholder: "Select payment mode",
            isDisabled: props.disableAll ? true : disable,
          }}
        />
      </Col>

      <Col span={8}>
        <InputField
          label="Amount"
          control={control}
          initValue={item?.amount}
          fieldname={`othercharges[${index}].amount`}
          disabled={props.disableAll ? true : disable}
          iProps={{
            type: "number",
            placeholder: "Amount",
            onInput: (event) => onAmountChange(event.target.value),
          }}
          validate={
            Object.entries(errors).length > 0 &&
            errors?.othercharges?.length > 0 &&
            errors?.othercharges[index]?.amount &&
            "error"
          }
          validMessage={
            Object.entries(errors).length > 0 &&
            errors?.othercharges?.length > 0 &&
            errors?.othercharges[index]?.amount &&
            errors?.othercharges[index]?.amount?.message
          }
          rules={{
            min: { value: 1, message: "Enter amount" },
            setValueAs: (value) => parseFloat(value?.toFixed(2)),
          }}
        />
      </Col>
      {enable && (
        <>
          <Col span={6}>
            <ReactSelectField
              isRequired={true}
              label="Paid Parking Slot"
              fieldname={`othercharges[${index}].slot`}
              iProps={{
                isDisabled: props.disableAll,
                placeholder: "Select one",
              }}
              control={control}
              initValue={
                item?.slot ? { label: item.slot, value: item.slot } : ""
              }
              selectOption={_.map(parking, (e) => ({ label: e, value: e }))}
              rules={{ required: "Select one" }}
              validate={
                Object.entries(errors).length > 0 &&
                errors?.othercharges?.length > 0 &&
                errors?.othercharges[index]?.slot &&
                "error"
              }
              validMessage={
                Object.entries(errors).length > 0 &&
                errors?.othercharges?.length > 0 &&
                errors?.othercharges[index]?.slot &&
                errors?.othercharges[index]?.slot?.message
              }
            />
          </Col>
        </>
      )}
      {!props.disableAll && index > (deleteIndex ?? 2) && (
        <Button
          className="removebtn"
          style={{ top: "2em" }}
          type="link"
          icon={<DeleteOutlined />}
          onClick={() => {
            remove(index);
            enableClone(typeM);
          }}
          danger
        />
      )}
    </Row>
  );
};
