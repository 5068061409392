import React from "react";

// others
import { Col } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";

interface IProps {
  label: string;
  value?: string;
  valueLink?: any;
  valueStyle?: object;
}

const toNormalCase = (str) => str?.charAt(0)?.toUpperCase() + str?.slice(1)

const index = (props: IProps) => {
  return (
    <Col span={6} style={{ marginBottom: "16px" }}>
      <p className="label" style={{ textTransform: "capitalize" }}>
        {props.label}
      </p>
      {props.value && (
        <p className="value" style={props.valueStyle}>
          {(props.value === 'male' || props.value === 'female') ? props.value.charAt(0).toUpperCase() + props.value.slice(1) : props.value}
        </p>
      )}
      {props.valueLink && (
        <a
          className="value"
          style={props.valueStyle}
          onClick={() => {
            const newTab = window.open();
            newTab.document.write(props?.valueLink?.htmlTemplate);
          }}
        >
          {toNormalCase(props?.valueLink?.templateName)} <DoubleRightOutlined />
        </a>
      )}
    </Col>
  );
};

export default index;
