export const ADDITIONAL_DOCUMENT = "ADDITIONAL_DOCUMENT";
export const TENANCY_CONTRACT = "TENANCY_CONTRACT";



export const getDocumentKey = (name) => {
    switch (name) {
      case "unSignedEjariContract":
        return TENANCY_CONTRACT;
      case "additionalDocument":
        return ADDITIONAL_DOCUMENT;
      // case "emirates":
      //   return emiratesKey;
      default:
       return name;
        break;
    }
  }