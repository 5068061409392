import React, { useState, useEffect } from "react";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import { Button, Card } from "antd";
import EmptyPlaceholder from "../../atoms/EmptyPlaceholder";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";


const Grid = (props) => {
  const {
    filterGrid,
    data,
    columns,
    pagination,
    defaultSettings,
    noRowlabel,
    noRowbtnAction,
    refreshAction,
    fullPage,
    rowSelection = "single",
    editType = "",
    onSelectionChanged,
    onCellValueChanged,
    suppressRowClickSelection = true,
    deselectAll,
    overlayNoRowsTemplate = "",
    autoSizeColumns,
    className,
    gridClassName = "",
    rowHeight
  } = props;

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [noRow, setNoRow] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const autosize = () => {
    const columns = gridColumnApi?.getColumns();

    const allColumnIds = [];
    columns?.forEach((column) => {
      allColumnIds?.push(column?.colId);
    });
    gridColumnApi?.autoSizeColumns(allColumnIds, false);
  };

  useEffect(() => {
    setIsLoading(true);
    if (data?.length > 0) {
      setRowData(data);
      setIsLoading(false);
      data?.length ? setNoRow(false) : setNoRow(true);
    } else {
   
      setRowData(null);
    }
  }, [data]);

  useEffect(() => {
    if (autoSizeColumns && gridColumnApi && data?.length > 0) {
      setTimeout(() => {
        autosize();
      }, 300);
    }
  }, [gridColumnApi, data]);

  useEffect(() => {
    gridApi && gridApi.setQuickFilter(filterGrid);
  }, [filterGrid]);

  useEffect(() => {
    refreshAction ? refreshCells() : null;
  }, [refreshAction]);
  
  const refreshCells = () => {
    var params = {
      force: true,
      suppressFlash: true,
    };
    gridApi && gridApi.refreshCells(params);
  };

  useEffect(() => {
    gridApi?.deselectAll();
  }, [deselectAll]);

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }

  return (
    <div className={`custom-style ${className}`}>
      <div
        id="myGrid"
        style={{ height: "350px", width: "100%" }}
        className={`ag-theme-material ${
          fullPage && data?.length ? "fp-height" : ""
        } ${noRow ? "h-auto" : ""}`}
      >
        <AgGridReact
          defaultColDef={{...defaultSettings}}
          className={gridClassName}
          colResizeDefault={"shift"}
          suppressDragLeaveHidesColumns={true}
          pagination={pagination}
          animateRows={true}
          columnDefs={columns}
          rowData={rowData}
          editType={editType}
          rowHeight={rowHeight}
          rowSelection={rowSelection}
          onGridReady={onGridReady}
          onSelectionChanged={onSelectionChanged}
          onCellValueChanged={onCellValueChanged}
          suppressRowClickSelection={suppressRowClickSelection}
          overlayNoRowsTemplate={overlayNoRowsTemplate}
        />
      </div>
      {noRow && overlayNoRowsTemplate === "" && (
        <Card bordered={false}>
          <EmptyPlaceholder label={noRowlabel} action={noRowbtnAction} />
        </Card>
      )}
    </div>
  );
};

export default Grid;
