import React, { useEffect, useState } from "react";
import { Row, Col, Typography } from "antd";
import { InputField } from "../../../atoms/FormElement";
import { useWatch } from "react-hook-form";
import ChargesArray from "./ChargesArray";
import dayjs from "dayjs";
import { dateByformat } from "../../../../utils/dateHelper";

const { Text, Title } = Typography;

export default (props) => {
  const { control, getValues, setValue, register } = props;

  const [billingScheduleTotal, setBillingScheduleTotal] = useState(null);

  const otherchargesData: any = useWatch({
    control,
    name: "othercharges",
  });

  const billingsData = useWatch({ control, name: "billings" });
  const leaseStartDate = useWatch({ control, name: "leaseStartDate" });
  const expiryDate = useWatch({ control, name: "leaseEndDate" });
  const billingScheduleData = useWatch({
    control,
    name: "billingScheduleData",
  });
  const usageType = getValues("usagesType")?.value;

  useEffect(() => {
    let billing_data: any = billingsData;
    if (billing_data?.length > 0) {
      let temp_total_array = [];
      billing_data?.map((item, index) => {
        let innerData: any = item;
        if (innerData?.instaArray?.length > 0) {
          innerData?.instaArray?.map((nestItem, nestIndex) => {
            temp_total_array.push(parseFloat(nestItem?.total));
          });
        }
      });
      setBillingScheduleTotal(
        temp_total_array.reduce((a, b) => a + b, 0)?.toFixed(2),
      );
      register("billingScheduleTotal");
      setValue(
        "billingScheduleTotal",
        temp_total_array.reduce((a, b) => a + b, 0)?.toFixed(2),
      );
    }
  }, [otherchargesData]);

  let totlaamount = [0];
  const generateInstallment = (chargeArrayData, tenure, rent) => {
    const start = getValues("leaseStartDate");
    const end = getValues("leaseEndDate");
    const intallmentsNumber = chargeArrayData?.intallments?.value;

    let dateSlots = [];
    let total_months = Math.round(dayjs(end).diff(start, "month", true));
    //if (chargeArrayData?.type?.label == "BASE RENT") {
    // total_months = Math.round(dayjs(end).diff(start, "month", true));
    //}

    let total_days_array = [];
    let rent_end = "";
    for (let index = 1; index <= intallmentsNumber; index++) {
      let _start_month = dayjs(start).format("YYYY-MM-DD");

      let divide_minus_one = (12 / intallmentsNumber) * (index - 1);

      if (chargeArrayData?.type?.value === "rent_installment") {
        divide_minus_one = (12 / intallmentsNumber) * (index - 1);
      }

      let divide = (12 / intallmentsNumber) * index;

      if (index > 1) {
        _start_month = dayjs(start)
          .add(divide_minus_one, "month")
          .format("YYYY-MM-DD");
      }

      let _end_month = null;
      if (index == 1) {
        _end_month = dayjs(start)
          .add(divide, "month")
          .subtract(1, "day")
          .format("YYYY-MM-DD");
      }

      if (intallmentsNumber == 1) {
        if (chargeArrayData?.type?.value === "rent_installment") {
          _end_month = dayjs(start)
            .add(12, "month")
            .subtract(1, "day")
            .format("YYYY-MM-DD");
        } else {
          _end_month = dayjs(start)
            .add(total_months, "month")
            .subtract(1, "day")
            .format("YYYY-MM-DD");
        }
      }

      if (index > 1) {
        _end_month = dayjs(start)
          .add(divide, "month")
          .subtract(1, "day")
          .format("YYYY-MM-DD");
      }

      if (chargeArrayData?.type?.value === "rent_installment") {
        rent_end = _end_month;
      }

      let installmentsDays = dayjs(_end_month).diff(_start_month, "day") + 1;
      total_days_array.push(installmentsDays);

      dateSlots.push({
        start: _start_month,
        end: _end_month,
        index,
        d: divide,
        days: installmentsDays,
      });
    }

    if (chargeArrayData?.type?.value === "rent_installment" && total_months > 12) {
      let divide = (12 / intallmentsNumber) * (intallmentsNumber + 1);

      let _start_month = dayjs(rent_end).add(1, "day").format("YYYY-MM-DD");
      let _end_month = dayjs(start)
        .add(total_months, "month")
        .subtract(1, "day")
        .format("YYYY-MM-DD");

      let installmentsDays = dayjs(_end_month).diff(_start_month, "day") + 1;

      dateSlots.push({
        start: _start_month,
        end: _end_month,
        index: intallmentsNumber + 1,
        d: total_months,
        days: installmentsDays,
      });
    }

    let total_days = total_days_array.reduce((a, b) => a + b, 0);
    let per_day_installment = rent / total_days;

    if (dateSlots?.length > 0) {
      let insta = [];
      Array.from(Array(tenure).keys()).map((it, index) => {
        let amount = per_day_installment * dateSlots[index].days;
        let vat: any = 0;
        // console.log("usageType", usageType);
        // if (usageType == 2) {  //TODO: this was commented for now: in current use case, same for both
        //   if (
        //     chargeArrayData?.type?.value != "security_deposit" &&
        //     chargeArrayData?.type?.value !== "rent_installment"
        //   ) {
        //     vat = per_day_installment * dateSlots[index].days * 0.05;
        //   } else {
        //     vat = "0";
        //   }
        // } else {
        //   vat = per_day_installment * dateSlots[index].days * 0.05;
        // }
        //current case below
        if (chargeArrayData?.type?.value !== "security_deposit" &&
          chargeArrayData?.type?.value !== "rent_installment") {
          vat = per_day_installment * dateSlots[index].days * 0.05;
        } else {
          vat = "0";
        }
        //::::::::::

        let total = Number(vat) + Number(amount);

        insta.push({
          installmentno: index + 1,
          start_date: dateByformat(dateSlots[index].start, "DD-MM-YYYY"),
          end_date: dateByformat(dateSlots[index].end, "DD-MM-YYYY"),
          amount: amount,
          vat: vat,
          total: total,
        });
        totlaamount.push(total);
      });
      if (total_months > 12 && chargeArrayData?.type?.value === "rent_installment") {
        insta.push({
          installmentno: intallmentsNumber + 1,
          start_date: dateByformat(dateSlots[intallmentsNumber].start, "DD-MM-YYYY"),
          end_date: dateByformat(dateSlots[intallmentsNumber].end, "DD-MM-YYYY"),
          amount: 0,
          vat: 0,
          total: 0,
        });
      }
      register("billingScheduleTotal");
      setValue(
        "billingScheduleTotal",
        totlaamount.reduce((a, b) => a + b, 0),
      );
      return insta;
      //setChargeArrayFormData(insta);
    }
  };

  return (
    <>
      <Row gutter={[24, 24]} style={{ height: 0}}>
        {/* <Col span={6}>
          <Text className="fontWeight600 ag-default">Description</Text>
        </Col>
        <Col span={18}>
          <Row gutter={24}>
            <Col span={4}>
              <Text className="fontWeight600 ag-default">Instalment No.</Text>
            </Col>
            <Col span={4}>
              <Text className="fontWeight600 ag-default">
                Instalment Start Date
              </Text>
            </Col>
            <Col span={4}>
              <Text className="fontWeight600 ag-default">
                Instalment End Date
              </Text>
            </Col>
            <Col span={4}>
              <Text className="fontWeight600 ag-default">Actual Amount</Text>
            </Col>
            <Col span={4}>
              <Text className="fontWeight600 ag-default">VAT</Text>
            </Col>
            <Col span={4}>
              <Text className="fontWeight600 ag-default">Total Amount</Text>
            </Col>
          </Row>
        </Col> */}

        {otherchargesData?.map((item, index) => (
          <React.Fragment key={index}>
            {/* <Col span={6}> */}
              <InputField
                label=""
                fieldname={`billings[${index}].billtype`}
                setValue={setValue}
                valueGot={item?.type?.value}
                control={control}
                initValue=""
                iProps={{ readOnly: true, type: "hidden" }}
              />
            {/* </Col> */}
            {/* <Col span={18}> */}
              <ChargesArray
                control={control}
                setValue={setValue}
                getValues={getValues}
                nestIndex={index}
                register={register}
                data={generateInstallment(
                  item,
                  item?.intallments?.value,
                  parseInt(item?.amount),
                )}
              // data={{
              //   ...item,
              //   ...{ start: leaseStartDate, end: expiryDate },
              // }}
              />
            {/* </Col> */}
          </React.Fragment>
        ))}
        {/* <Col span={24}>
          <Title level={4} style={{ textAlign: "end" }}>
            Total: {(totlaamount.reduce((a, b) => a + b, 0).toFixed(2)) ?? ""}
          </Title>
        </Col> */}
      </Row>
    </>
  );
};
