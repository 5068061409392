import axios from "../../../../../utils/axiosInceptor";
import * as action_types from "./constants";
import {
  authService,
  maintenanceService,
} from "../../../../../configs/constants";

const getDetailsUrl = `${maintenanceService}/booking/tenant/lease/`;

export const getDetailsFromEmail = (email) => {
  console.log("page, limit, search", email);

  return async (dispatch) => {
    const url = `${getDetailsUrl}${email}`;
    try {
      const {
        data: { result },
      } = await axios.get(url);
      console.log("data", result);

      return result;
    } catch (e) {
      console.log("Err", e);
    }
  };
};
