import React, { useState, useEffect } from "react";
import { Row, Col, Card, Typography, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getTicketStatus } from "../../ducks/actions";

const { Text, Title } = Typography;

export default (props) => {
  const dispatch = useDispatch();
  const ticketsApi = []//useSelector((state) => state.maintenance.ticketsData);

  useEffect(() => {
    // dispatch(getTicketStatus());
  }, []);

  return (
    <Card bordered={false} title="Analytics">
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Space>
            <Title level={3} className="ag-secondary m-0">
              Total Tickets:
            </Title>
            <Title level={2} className="ag-success m-0">
              {Object.keys(ticketsApi).reduce(
                (sum, key) => sum + parseFloat(ticketsApi[key] || 0),
                0,
              )}
            </Title>
          </Space>
        </Col>
        {Object.entries(ticketsApi).map(([Key, Value], index) => (
          <Col span={6} key={index}>
            <Card
              bordered={false}
              className="ag-nospace-head ag-noborder-head bg-primary"
              style={{ borderRadius: 4 }}
              bodyStyle={{ padding: 16, height: "100%" }}
            >
              <Space
                direction="vertical"
                align="center"
                size={16}
                style={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Text className="smallFont12 ag-white Sentence text-center d-block">
                  {Key.replace(/_/g, " ")}
                </Text>
                <Title level={2} className="m-0 ag-fontSize32 ag-white">
                  {/* {Value} */}
                </Title>
              </Space>
            </Card>
          </Col>
        ))}
      </Row>
    </Card>
  );
};
