
import React, { useState, useEffect, useRef } from "react";
import {
  Layout,
  Row,
  Col,
  Breadcrumb,
  Steps,
  Button,
  message,
  Spin,
  Card,
  Form,
} from "antd";
import { BsXCircleFill, BsDownload, BsCloudUpload, BsFillCheckCircleFill } from "react-icons/bs";
import { LoadingOutlined } from "@ant-design/icons";

import axios from 'axios';

import { Controller, useForm } from "react-hook-form";
import {
  leaseService, serverUrl
} from "../../../../../configs/constants";
import type { UploadFile } from 'antd/es/upload/interface';
import { InboxOutlined } from '@ant-design/icons';


import { CSVLink } from "react-csv";

import { Upload } from 'antd';
import { RcFile } from "antd/lib/upload/interface";
const { Dragger } = Upload;
const { Content } = Layout;

var _ = require("lodash");



// CreateBilling;

export default () => {
  // const [uploadProgress, setUploadprogress] = useState(0);

  const [failedData, setFailed] = useState([]);
  const [successData, setSuccess] = useState(0);
  const [loading, setLoading] = useState(false);

  const { control, formState: { errors }, setValue, handleSubmit } = useForm();

  const headers = [
    // { label: "Tenant Name", key: "tenantname" },
    { label: "Ejari Contract Number", key: "ejariContractNumber" },
    // { label: "Email", key: "email" },
    // { label: "Mobile", key: "mobile" },
    { label: "Reason", key: "reason" }
  ];

  const csvReport = {
    data: failedData,
    headers: headers,
    filename: 'Billing_Failure_log.csv'
  };



  // csv
  const [file, setFile] = useState();
  const [array, setArray] = useState([]);

  // const [filelist, setfilelist] = useState([]);
  const [billingFile, setBilling] = useState<UploadFile[]>([]);

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };


  const resetModal = () => {
    setBilling([]);
    setFailed([]);
    setSuccess(0);
  }

  const onFinish = async (values) => {

    const postJson = {
      file_asset: billingFile[0],
      // orgId: "orgId-83a0fecf-a07a-46fc-98ec-dc49359abed1",
      // orgId:"orgId-83a0fecf-a07a-46fc-98ec-dc49359abed1"

    };

    resetModal();
    setLoading(true);



    if (billingFile[0]) {
      const url = `${leaseService}/leasing/UploadBillingScheduleFile`;

      let fd = new FormData();
      fd.append('file_asset', billingFile[0] as RcFile);
      // const config = { headers: { 'Content-Type': 'multipart/form-data' } };



      await axios.post(url, fd, { headers: { 'Content-Type': 'multipart/form-data' } }).then((response) => {

        const { data } = response;



        if (data?.result?.failedData?.length > 0) {
          // console.log("sorry failed by: ", data?.result?.failedData?.length);
          const failedArry = _.map(data.result.failedData, (e) => {
            return {
              // "tenantname": e.tenantname,
              "ejaricontractnumber": e.ejaricontractnumber,
              // "email": e.email,
              // "mobile": e.mobile,
              "reason": e.reason
            }
          });
          setFailed(failedArry);
        }
        if (data?.result?.finalArr?.length > 0)
          setSuccess(data.result.finalArr.length);
        setLoading(false);
        setBilling([]);
        message.success(response.data.message);

      }).catch((error) => {

        setLoading(false);
        message.error(error.response.data.error)

      })

    } else {
      setLoading(false);
      message.error("Please select a file to upload");
    }

  };
  const headerKeys = Object.keys(Object.assign({}, ...array));

  const billingFileProps = {
    className: "file-upload",
    name: "file",
    accept: 'csv',
    multiple: false,
    // showUploadList: false,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {

      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} Please upload only CSV file`);
      }
    },
    onDrop(e) {

      // setBilling(e.dataTransfer.files);
    },
    onRemove: (file) => {
      setBilling([]);
    },
    beforeUpload: (file) => {
      setBilling([file]);
      return false;
    },
    billingFile,
  };


  return (

    <Layout className="layout-space prop_popup">
      <Content className="main-content">

        <Row gutter={24}>

          <Col flex="1 1 500px">
            {/* File Uploader */}
            <div style={{ textAlign: "center" }}>
              {/* <h1 className="csvheading">Upload your data through CSV file</h1> */}

              <Form layout="vertical" encType="multipart/form-data" onFinish={handleSubmit(onFinish)}>
                <Row gutter={24}>


                  <Dragger {...billingFileProps}
                    fileList={billingFile}

                    className="drag_drop_csv">
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                      Download the sample template and fill the data as per the template, convert master file into CSV and upload it.
                      <br></br><br></br>
                      <span>Note: </span>Only CSV files are accepted.                    </p>
                  </Dragger>
                  <div className="csvmodel">

                    <a className="downloadcsv" href={`${serverUrl}/templates/residential/ResidentialActiveTenancyContractTemplate_v1.xlsx`} download>
                      {<BsDownload />} Download residential template file
                    </a>
                    <a className="downloadcsv" href={`${serverUrl}/templates/commercial/CommercialActiveTenancyContractTemplate_v1.xlsx`} download>
                      {<BsDownload />} Download commercial template file
                    </a>
                  </div>
                  {loading ? (
                    <Spin />
                  ) :

                    (<>

                      {successData > 0 ? (
                        <>Successfully Added {successData} Records</>
                      ) : ""}

                      <Col className="csv_error_wrap">
                        {failedData?.length > 0 ? (
                          <div className="csv_error_log">
                            <span className="csv_error"> {<BsXCircleFill color='red' />} {failedData?.length} - Records Failed <CSVLink className="csv_log" {...csvReport}>{<BsDownload />} Download Failure Log</CSVLink></span><br />
                            {/* <span>{failedData?.length} Records Failed</span><br /> */}
                            {/* <span><CSVLink {...csvReport}>Download Failure Log</CSVLink></span> */}
                          </div>
                        ) : ""}
                        <Button htmlType="submit" disabled={loading} type="primary" size="large" className="csv_publish">
                          {<BsCloudUpload />} Publish
                        </Button>

                      </Col>
                    </>)}



                </Row>
              </Form>

              <br />
            </div>
          </Col>

        </Row>
      </Content>
    </Layout>
  );
};
