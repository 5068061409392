// others
import Item from "./Item";
import LeaseTerms from "./LeaseTerms";
import DocList from "./documents/DocList";
import RenewalTerms from "./RenewalTerms";
import TenantDetails from "./TenantDetails";
import PaymentSchedule from "./PaymentSchedule";

export {
  Item,
  DocList,
  LeaseTerms,
  RenewalTerms,
  TenantDetails,
  PaymentSchedule,
};
